/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import i18n from '../../../lang/i18n';
import { receivePiboEvent, sendPiboMsg } from '../../../pibo';
import {
  deleteMessageItemApi,
  getMessagesApi,
  setMessageApi,
} from '../../../utils/api';
import { getRobotId } from '../../../utils/common';
import {
  MSG_MODE_REAL_TIME,
  MSG_MODE_SENSING_TIME,
  MSG_RECEIVED_STATUS,
} from '../../../utils/consts';
import { errorLog } from '../../../utils/report';

const getErrorMsg = (code, defaultKey) =>
  i18n.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

const reducerName = 'messageBot';
const initialState = {
  initDataStatus: false,
  pending: false,
  error: false,
  length: 0,
  messageData: [],
  sendMode: MSG_MODE_SENSING_TIME,
  sendMessagePending: false,
  sendMessageError: false,
  sentMessage: null,
  updateMessagePending: false,
  message: '',
  bBottom: true,
};

export const getMessage = createAsyncThunk(
  `${reducerName}/GET`,
  async (_undefined, { getState, rejectWithValue }) => {
    try {
      const {
        messageData: oldMessageData,
        length: oldLength,
      } = getState().messageBot;
      const response = await getMessagesApi(getRobotId(), oldLength, 10);
      const { data, result } = response;
      const newLength = data.reduce((prev, curr) => {
        const v = Object.values(curr)[0];
        let ln = 0;
        if (v) {
          ln = prev + v.length;
        }
        return ln;
      }, 0);

      const mergedData = oldMessageData.reduce((acc, cur) => {
        const [k, v] = Object.entries(cur)[0];
        const itemIndex = data.findIndex(item =>
          Object.keys(item).find(key => key === k),
        );
        if (itemIndex > -1) {
          const messageItem = data.splice(itemIndex, 1);
          acc.push({ [k]: messageItem[0][k].concat(v) });
        } else {
          acc.push({ [k]: v });
        }
        return acc;
      }, []);

      const newMessageData = [];
      if (mergedData.length > 0) {
        newMessageData.unshift(...mergedData);
      }
      newMessageData.unshift(...data);

      if (result) {
        return { length: newLength + oldLength, messageData: newMessageData };
      }
      return rejectWithValue(null);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const sendMessage = createAsyncThunk(
  `${reducerName}/SEND`,
  async (
    { mode, type, msgtxt: message, fileName, blob },
    { getState, rejectWithValue },
  ) => {
    try {
      const response = await setMessageApi({
        robotId: getRobotId(),
        value: message,
        mode,
        type,
        fileName,
        blob,
      });
      const { result, data } = response.data;
      if (result) {
        const {
          messageData: oldMessageData,
          length: oldLength,
          handleReceive,
        } = getState().messageBot;
        receivePiboEvent('message', handleReceive);
        sendPiboMsg({ mode, type, _id: data._id }, handleReceive);
        let matched = false;
        const messageData = oldMessageData.length
          ? oldMessageData.reduce((acc, cur, idx) => {
              const [k, v] = Object.entries(cur)[0];
              const { _id: id, firstTime, ...rest } = data;
              const date = moment(firstTime).format('YYYY-MM-DD');
              if (k === date) {
                acc.push({ [k]: v.concat({ ...rest, id, firstTime }) });
                matched = true;
              } else {
                if (idx + 1 === oldMessageData.length) {
                  if (!matched) {
                    acc.unshift({ [date]: [{ ...rest, id, firstTime }] });
                  }
                }
                acc.unshift({ [k]: v });
              }
              return acc;
            }, [])
          : [
              {
                [moment(data.firstTime).format('YYYY-MM-DD')]: [
                  { ...data, id: data._id },
                ],
              },
            ];
        return { length: oldLength + 1, messageData };
      }
      return rejectWithValue(i18n.t('bot:MSG.SEND.FAILED'));
    } catch (e) {
      return rejectWithValue(
        getErrorMsg('code' in e && e, 'bot:MSG.SEND.FAILED'),
      );
    }
  },
);

export const deleteMessageItem = createAsyncThunk(
  `${reducerName}/DELETE`,
  async (id, { getState, rejectWithValue }) => {
    try {
      const { messageData, length } = getState().messageBot;
      const newData = messageData.reduce((acc, obj) => {
        const [[date, list]] = Object.entries(obj);
        const arr = list.filter(({ id: itemId }) => itemId !== id);
        if (arr.length > 0) {
          return [
            ...acc,
            { [date]: list.filter(({ id: itemId }) => itemId !== id) },
          ];
        }
        return acc;
      }, []);
      const response = await deleteMessageItemApi({ _id: id });
      const { result } = response;
      if (result) {
        return { newData, length: length - 1 };
      }
      return rejectWithValue(i18n.t('bot:MSG.DELETE.FAILED'));
    } catch (error) {
      errorLog('error', error);
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:MSG.DELETE.FAILED'),
      );
    }
  },
);

export const updateMessage = createAsyncThunk(
  `${reducerName}/UPDATE`,
  (response, { getState, rejectWithValue }) => {
    const { id } = response;
    const { messageData: oldMessageData } = getState().messageBot;
    try {
      const newMessageData = [...oldMessageData].map(data => {
        const [[date, list]] = Object.entries(data);
        const newList = list.map(item => {
          if (item.id === id) {
            return { ...item, status: MSG_RECEIVED_STATUS };
          }
          return item;
        });
        return { [date]: newList };
      });
      return newMessageData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const messageSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    init: state => ({
      ...state,
      initDataStatus: !state.initDataStatus,
      bBottom: false,
    }),
    setPending: state => ({ ...state, pending: true }),
    unsetBottom: state => ({ ...state, bBottom: false }),
    setMessage: (state, action) => ({
      ...state,
      message: action.payload,
    }),
    setSendMode: state => ({
      ...state,
      sendMode:
        state.sendMode === MSG_MODE_SENSING_TIME
          ? MSG_MODE_REAL_TIME
          : MSG_MODE_SENSING_TIME,
    }),
    setHandler: (state, action) => ({
      ...state,
      handleReceive: action.payload,
    }),
  },
  extraReducers: {
    [getMessage.pending.type]: state => ({
      ...state,
      pending: true,
    }),
    [getMessage.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      length: action.payload.length,
      messageData: action.payload.messageData,
    }),
    [getMessage.rejected.type]: (state, action) => ({
      ...state,
      pending: true,
      error: action.payload,
    }),
    [sendMessage.pending.type]: state => ({
      ...state,
      sendMessagePending: true,
      sendMessageError: false,
      sentMessage: null,
      bBottom: false,
    }),
    [sendMessage.fulfilled.type]: (state, action) => ({
      ...state,
      sendMessagePending: false,
      sentMessage: true,
      length: action.payload.length,
      messageData: action.payload.messageData,
      message: '',
      bBottom: true,
    }),
    [sendMessage.rejected.type]: (state, action) => ({
      ...state,
      sendMessagePending: false,
      sendMessageError: action.payload,
    }),
    [deleteMessageItem.pending.type]: state => ({
      ...state,
      pending: true,
      error: false,
    }),
    [deleteMessageItem.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      messageData: action.payload.newData,
      length: action.payload.length,
    }),
    [deleteMessageItem.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
    [updateMessage.pending.type]: state => ({
      ...state,
      updateMessagePending: true,
      error: false,
      bBottom: false,
    }),
    [updateMessage.fulfilled.type]: (state, action) => ({
      ...state,
      sendMessagePending: false,
      sendMessageError: null,
      updateMessagePending: false,
      bBottom: true,
      message: '',
      length: state.length + 1,
      messageData: action.payload,
    }),
  },
});

const { reducer: messageReducer, actions } = messageSlice;
export const {
  init,
  unsetBottom,
  setMessage,
  setSendMode,
  setHandler,
  setPending,
} = actions;

export default messageReducer;
