import React, { useEffect } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { WeatherLocation, WEATHER_COLOR } from './WeatherComponents';

const WeatherDustWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 100%;
  gap: 2rem;
  margin-top: 2rem;
`;

const WeatherRemarksGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  font-size: 0.875rem;
  gap: 0.5em;
  align-self: self-end;
  margin-top: -2rem;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
  }
`;

const WeatherDustTabs = styled.div`
  display: flex;
  flex: 1 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  isolation: isolate;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    & > span {
      font-size: 0.875rem;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 0px;
      color: #fff;
      letter-spacing: -1px;
      &.cursor {
        position: absolute;
        width: initial;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.55);
        &.dust {
          bottom: 1.75em;
          left: 0;
          &:not(.none)::before {
            font-family: Icons;
            font-size: 1.25em;
            content: '\f08d';
            padding-right: 2px;
            margin-bottom: -4px;
          }
        }
        &.good {
          color: rgba(36, 143, 197, 0.9);
          left: 0;
        }
        &.normal {
          color: rgba(88, 166, 198, 0.9);
          left: 25%;
        }
        &.soso {
          color: rgba(155, 120, 87, 0.9);
          left: 50%;
        }
        &.bad {
          color: rgba(129, 20, 13, 0.9);
          left: 75%;
        }
        &.nano {
          bottom: -1.75em;
          &:not(.none)::before {
            font-family: Icons;
            font-size: 1.25em;
            content: '\f08d';
            padding-right: 2px;
            margin-bottom: 2px;
            transform: rotateX(180deg);
          }
        }
      }
      &.tab.good {
        background: #60bfe0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.tab.normal {
        background: #96cee1;
      }
      &.tab.soso {
        background: #c7af95;
      }
      &.tab.bad {
        background: #e26259;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

const WeatherDustCard = data => {
  const { setColor, locationKeyword: location } = data;
  const { pm25, pm10 } = data;
  const [dust, dustVal] = pm10;
  const [nanoDust, nanoVal] = pm25;
  const { label: dustLabel, bg10 } = WEATHER_COLOR[dust];
  const { label: nanoLabel, bg25 } = WEATHER_COLOR[nanoDust];
  const background = `linear-gradient(315deg, ${bg25}, rgba(255, 255, 255, 1) 50%, ${bg10}), #FFFFFF;`;

  useEffect(() => {
    setColor({ background, color: 'rgba(0,0,0,.87)' });
  }, [dust, nanoDust]);

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}
    >
      <WeatherLocation>
        <Header as="h5">{location}</Header>
      </WeatherLocation>
      <WeatherRemarksGroup>
        <div>
          <Icon name="pin" />
          <span>미세먼지</span>
        </div>
        <div>
          <Icon name="pin" flipped="vertically" />
          <span>초미세먼지</span>
        </div>
      </WeatherRemarksGroup>
      <WeatherDustWrap>
        <WeatherDustTabs>
          <div>
            {dust && (
              <span className={`cursor dust ${dustLabel}`}>
                {`${dustVal}µg`}
              </span>
            )}
            {!dust && (
              <span className={`cursor dust ${dustLabel}`}>{`${dustVal}`}</span>
            )}
            <span className="tab good">좋음</span>
            <span className="tab normal">보통</span>
            <span className="tab soso">나쁨</span>
            <span className="tab bad">매우 나쁨</span>
            {nanoDust && (
              <span className={`cursor nano ${nanoLabel}`}>
                {`${nanoVal}µg`}
              </span>
            )}
          </div>
        </WeatherDustTabs>
      </WeatherDustWrap>
    </div>
  );
};

export default WeatherDustCard;
