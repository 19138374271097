import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect, useLifecycles } from 'react-use';
import { getLocale, getUserPId } from '../../utils/common';
import StoreReviewList from '../../components/botstore/StoreReviewList';
import { init, getReviews, getReviewMore, saveUserReview, deleteUserReview } from '../../ducks/review';

const ReviewContainer = props => {
  const {
    refresh,
    botId,
    projectId,
    isInstalled,
  } = props;
  const dispatch = useDispatch();
  const selector = useSelector(state => state.review);
  const {saveSuccess, review: { content, score, title, rId }} = selector;
  const [saved, setSaved] = useState(saveSuccess);
  const [newReview, setReview] = useState({
    writeMode: false,
    reviewTitle: '',
    reviewText: '',
    rating: 0,
    moreReviews: false,
  });
  useLifecycles(
    () => dispatch(getReviews({ userId: getUserPId(), botId })),
    () => {
      dispatch(init());
    },
  );
  useUpdateEffect(() => {
    setSaved(saveSuccess);
    if (!saved && saveSuccess) {
      dispatch(getReviews({ userId: getUserPId(), botId }))
      setReview({
        ...newReview,
        reviewTitle: '',
        reviewText: '',
        rating: 0,
      });
    }
    setSaved(saveSuccess);
  }, [saveSuccess]);
  useUpdateEffect(() => {
    if (refresh) {
      dispatch(getReviews({ userId: getUserPId(), botId }))
    }
  }, [refresh]);

  const handleWriteClick = () => {
    const {
      writeMode,
      reviewTitle,
      reviewText,
      rating,
      moreReviews,
    } = newReview;
    if (writeMode) {
      dispatch(saveUserReview({
        botId,
        projectId,
        userId: getUserPId(),
        title: reviewTitle,
        content: reviewText,
        score: rating,
        locale: getLocale(),
        use: isInstalled,
        update: !!rId,
        prevScore: score,
      }));
    }
    setReview({
      writeMode: !writeMode,
      reviewTitle: title || reviewTitle,
      reviewText: content || reviewText,
      rating: score || rating,
      moreReviews,
    });
  };

  const handleCancelClick = () => {
    setReview({
      ...newReview,
      writeMode: false,
      reviewTitle: '',
      reviewText: '',
      rating: 0,
    });
  };

  const handleReviewTitleChange = (e, { value }) => {
    const { reviewTitle, ...rest } = newReview;
    setReview({
      ...rest,
      reviewTitle: value.substr(0, 50),
    });
  };

  const handleReviewTextChange = (e, { value }) => {
    const { reviewText, ...rest } = newReview;
    setReview({
      ...rest,
      reviewText: value.substr(0, 200),
    });
  };

  const handleRate = (e, { rating }) => {
    setReview({
      ...newReview,
      rating,
    });
  };

  const handleMoreClick = () => {
    setReview({
      ...newReview,
      moreReviews: true,
    });
    dispatch(getReviewMore(botId));
  };

  const handleDeleteClick = () => {
    dispatch(deleteUserReview({ userId: getUserPId(), rId }));
  };

  return (
    <StoreReviewList
      {...props}
      {...newReview}
      handleMoreClick={handleMoreClick}
      onCancel={handleCancelClick}
      onWrite={handleWriteClick}
      onDelete={handleDeleteClick}
      handleReviewTitleChange={handleReviewTitleChange}
      handleReviewTextChange={handleReviewTextChange}
      handleRate={handleRate}
      style={{ width: '100%' }}
    />
  );
};

export default ReviewContainer;
