/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Slider from 'rc-slider';
import styled from 'styled-components';
import 'rc-slider/assets/index.css';

import {
  Icon,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Menu,
  MenuItem,
  Segment,
  TransitionablePortal,
} from 'semantic-ui-react';
import { useDebounce } from 'react-use';

export const ConfigInfoCard = styled(Segment)`
  padding: 1rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  & > .list {
    gap: 1rem;
    display: flex;
    flex-flow: column;
    padding: 0.75rem 0;
    justify-content: center;
    max-width: 80%;
    margin: auto;
    & > .column {
      font-size: 0.875rem;
      line-height: 1.15;
      display: flex;
      gap: 0.5rem;
      & > .name {
        text-align: right;
        width: 3.375rem;
        flex: 0 0 3.375rem;
      }
      & > .value {
        color: #03bfd7;
        font-weight: bold;
      }
    }
  }
  @media (min-width: 741px) {
    flex-flow: wrap;
    & > .photo {
      flex: 0 1 160px;
      width: 160px;
      height: 160px;
      margin: 0;
      & img {
        width: 160px;
        height: 160px;
      }
    }
    & > .list {
      margin: 0;
    }
  }
`;

export const PiboLinePanel = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 0.5rem;
  position: relative;
  & i.angle.right.icon {
    height: auto;
  }
`;

export const PiboWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
`;

export const PiboRow = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
`;

export const PiboColumn = styled.div`
  box-sizing: border-box;
  display: block;
  width: 100%;
  position: relative;
  background: ${({ color }) => color || 'transparent'};
`;

export const PiboAccordion = styled(Accordion)`
  position: relative;
  border: 0;
  border-radius: 0;
  text-align: left;
  margin: 0 !important;
`;

export const PiboAccordionTitle = styled(AccordionTitle)`
  &&& {
    margin: .5px 0;
    padding: 0.75rem 1rem !important;
    font-size: 1.125rem !important;
    font-weight: ${({ bold, active }) => (bold || active ? 600 : 100)};
    background: ${({ color }) => color || '#fff'};
    position: relative;
  }
  ::after {
    content: '${({ active }) => (active ? '\f078' : '\f077')}';
    position: absolute;
    right: 0.75rem;
    display: ${({ foldicon }) => (foldicon ? 'inline-block' : 'none')};
    font-size: 1em;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 100;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    backface-visibility: hidden;
  }
`;

export const PiboAccordionContent = styled(AccordionContent)`
  padding: 0 0.75rem !important;
  background: ${({ color }) => color || 'transparent'};
`;

export const PiboMenu = styled(Menu)`
  &&& {
    border: 0 !important;
    box-shadow: none !important;
    background: transparent;
  }
`;

export const PiboMenuItem = styled(MenuItem)`
  background: #fff !important;
`;

export const StyledButtonGroup = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 3rem;
  display: flex;
`;

export const StyledButton = styled.div`
  width: inherit;
  border: 0;
  border-radius: 0;
  background: ${({ color, disabled }) =>
    color === 'gray' || disabled
      ? '#D1D3D4'
      : color === 'blue'
      ? '#0894D7'
      : '#FFF'};
  color: ${({ color, disabled }) =>
    color === 'gray' || disabled ? '#565B5B' : '#FFF'};
  display: inline-block;
  font-size: 1rem;
  padding: 1rem;
  height: 100%;
`;

export const StyledCard = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  color: gray;
  padding: 0;
  cursor: pointer;
`;

const NewIcon = styled.span`
  width: 1rem;
  height: 1rem;
  background: rgb(239, 51, 64);
  border-radius: 1rem;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: bold;
  font-family: 'Comfortaa';
  line-height: 1.7;
  text-align: center;
  margin-right: 1.5em;
`;

export const PiboLinePanelMenu = ({
  menu,
  icon,
  move,
  sub,
  bNew,
  onClick,
  name,
}) => (
  <PiboLinePanel onClick={() => (onClick ? onClick(name) : '')}>
    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      {icon && <span className={`menu ${icon}`} />}
      {menu}
    </div>
    {sub && (
      <span
        style={{
          color: '#0894D7',
          position: 'absolute',
          right: '1em',
          fontSize: '0.875em',
        }}
      >
        {sub}
      </span>
    )}
    {bNew && <NewIcon>n</NewIcon>}
    {move && (
      <Icon
        name="angle right"
        style={{ position: 'absolute', right: '0.5em' }}
      />
    )}
  </PiboLinePanel>
);

export const BodyWrap = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  @media (max-height: 850px) {
    padding-bottom: 45px;
    & > img.ui.fluid.image {
      margin: 5% 0;
    }
  }
  @media (min-height: 851px) {
    padding-bottom: 54px;
    & > img.ui.fluid.image {
      margin: 10% 0;
    }
  }
`;

export const ContentWrap = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  & button.ui.button {
    border-radius: 1rem;
  }
`;

export const HeaderWrap = styled.div`
  width: 80%;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: 20vh;
  & > .ui.header {
    margin: 0;
    @media (min-height: 851px) and (max-height: 1023px) {
      font-size: 1rem;
    }
    @media (min-height: 1024px) {
      font-size: 1.25rem;
    }
  }
  & > button.ui.button {
    border-radius: 1rem;
    padding: 8px 12px;
    margin-top: 0.4rem;
  }
`;

export const StatusWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  gap: 5px;
  align-self: flex-start;
  width: 100%;
  flex: 0 0;
`;

export const StatusText = styled.div`
  font-size: 0.75rem;
  color: ${({ color }) => color};
`;
export const Status = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: #03bfd7;
  font-weight: bold;
  gap: 4px;
  @media (max-height: 850px) {
    font-size: 0.75rem;
  }
  @media (min-height: 851px) {
    font-size: 0.875rem;
  }
  & > i.icon.power {
    background: #03bfd7;
    color: #fff;
    border-radius: 1em;
    margin: 0;
    height: 1.4em;
    line-height: 1.4em;
    width: 1.4em;
    padding: 0.5px 0 0 0;
  }
  & > i.icon.battery {
    font-size: 1.15em;
    margin: 0;
  }
`;

export const BatteryComponent = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  font-size: 1em;
  color: #03bfd7;
  font-weight: bold;
  gap: 4px;
  padding-top: 2px;
  line-height: 1.1;
  & i.icon.battery {
    font-size: 1.35em;
    margin: 0;
  }
`;

export const BatteryChargingIcon = styled.div`
  position: relative;
  @keyframes second {
    25% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes third {
    50% {
      opacity: 1;
    }
    0% {
      opacity: 0;
    }
  }
  @keyframes fourth {
    75% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
  }
  @keyframes fifth {
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  & > i.icon.battery {
    &:nth-child(n + 2) {
      position: absolute;
      top: 0;
      left: 0;
      animation: second 1s linear infinite;
    }
    &:nth-child(n + 3) {
      animation: third 1s linear infinite;
    }
    &:nth-child(n + 4) {
      animation: fourth 1s linear infinite;
    }
    &:last-child {
      animation: fifth 1s linear infinite;
    }
  }
`;

export const VolumeComponent = styled.div`
  align-self: flex-end;
  display: flex;
  flex-flow: row;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  padding-top: 2px;
  line-height: 1.1;
  @media (max-height: 850px) {
    font-size: 0.75rem;
  }
  @media (min-height: 851px) {
    font-size: 0.875rem;
  }
  & i.icon.volume {
    color: #03bfd7;
    font-size: 1.35em;
    margin: 0;
  }
`;

export const VolumeSegment = styled(Segment)`
  &.ui.segment {
    position: absolute;
    right: 0.5em;
    top: 55px;
    z-index: 1000;
    background: rgba(255, 255, 255, 0.55);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
    height: 50%;
    backdrop-filter: blur(3px);
    border-radius: 4px;
    padding: 0px 20px;
    @media (max-height: 850px) {
      margin-top: 1em;
    }
    @media (min-height: 851px) {
      margin-top: 1.5em;
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      height: 95%;
      & > i.icon {
        line-height: 1.25rem;
        margin: 0;
        &.minus {
          margin-bottom: 1em;
        }
        &.plus {
          margin-top: 1em;
        }
      }
    }
  }
`;

export const Battery = ({ value, charging }) => (
  <BatteryComponent>
    {!charging && (
      <div>
        {value === 100 && <Icon name="battery full" />}
        {value > 67 && value < 100 && <Icon name="battery three" />}
        {value > 33 && value < 68 && <Icon name="battery two" />}
        {value > 0 && value < 34 && <Icon name="battery one" />}
        {value === 0 && <Icon name="battery zero" />}
      </div>
    )}
    {charging && (
      <BatteryChargingIcon>
        <Icon name="battery zero" />
        <Icon name="battery one" />
        <Icon name="battery two" />
        <Icon name="battery three" />
        <Icon name="battery four" />
      </BatteryChargingIcon>
    )}
    <span>{value}</span>
  </BatteryComponent>
);

export const Volume = ({ value: v, onChange }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(v);

  useEffect(() => {
    if (value !== v) {
      setValue(v);
    }
  }, [v]);

  useDebounce(
    () => {
      if (open) onChange(value);
    },
    100,
    [value],
  );

  return (
    <VolumeComponent>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setOpen(!open)}
        style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}
      >
        <Icon name="volume off" />
        <span>{value}</span>
      </div>
      <TransitionablePortal
        onClose={() => setOpen(false)}
        open={open}
        transition={{ animation: 'slide down' }}
      >
        <VolumeSegment>
          <div>
            <Icon name="minus" size="tiny" />
            <Slider
              reverse
              vertical
              onChange={val => setValue(val)}
              {...{
                min: 1,
                max: 100,
                step: 1,
                railStyle: { backgroundColor: 'rgba(209,211,212,.5)' },
                trackStyle: { backgroundColor: 'rgba(3,191,215,1)' },
                handleStyle: {
                  backgroundColor: '#03BFD7',
                  border: '0',
                },
                value,
              }}
            />
            <Icon name="plus" size="tiny" />
          </div>
        </VolumeSegment>
      </TransitionablePortal>
    </VolumeComponent>
  );
};
