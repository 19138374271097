import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import Terms from '../../components/account/Terms';

const TermsContainer = props => {
  const { t } = useTranslation('user');
  const { terms, agreement, handleChange } = props;
  const [service, setService] = useState(agreement.service);
  const [privacy, setPrivacy] = useState(agreement.privacy);
  const [location, setLocation] = useState(agreement.location);
  const [msg, setMessage] = useState('');

  const getTermsMsg = (agreeService, agreePrivacy) => {
    if (!(agreeService || agreePrivacy)) {
      return t('TERM.ESSENTIALS');
    }
    if (!agreeService) {
      return t('TERM.MSG.SERVICE');
    }
    if (!agreePrivacy) {
      return t('TERM.MSG.PRIVACY');
    }

    return '';
  };

  const onChange = (e, { name }) => {
    const bAll = service && privacy && location;
    switch (name) {
      case 'all':
        setService(!bAll);
        setPrivacy(!bAll);
        setLocation(!bAll);
        setMessage(getTermsMsg(!bAll, !bAll));
        break;
      case 'service':
        setService(!service);
        setMessage(getTermsMsg(!service, privacy));
        break;
      case 'privacy':
        setPrivacy(!privacy);
        setMessage(getTermsMsg(service, !privacy));
        break;
      case 'location':
        setLocation(!location);
        setMessage(getTermsMsg(service, privacy));
        break;
      // case 'promotion':
      //   setPromotion(!promotion);
      //   setMessage(getTermsMsg(service, privacy));
      //   break;
      default:
        setMessage(getTermsMsg(service, privacy));
        break;
    }
  };

  useUpdateEffect(() => {
    handleChange({ service, privacy, location });
  }, [service, privacy, location]);

  return (
    <Terms
      {...{
        service,
        privacy,
        location,
        serviceTerms: terms.service,
        privacyTerms: terms.privacy,
        locationTerms: terms.location,
        msg,
        t,
      }}
      onChange={onChange}
    />
  );
};

export default TermsContainer;
