import i18 from '../lang/i18n';
import {
  getAllMyBots,
  updateBotUsability,
  removeBot,
  fetchBotSearchResults,
} from '../utils/api';
import { getLocale } from '../utils/common';
import { setPiboBot, getAlive, refreshBots } from '../pibo';
import { infoLog } from '../utils/report';

export const LOADING = 'bots/LOADING';
export const ERROR = 'bots/ERROR';
export const REDIRECT = 'bots/REDIRECT';
export const FETCH_SUCCESS = 'bots/FETCH_SUCCESS';
export const DELETE_SUCCESS = 'bots/DELETE_SUCCESS';
export const UPDATE_SUCCESS = 'bots/UPDATE_SUCCESS';
export const SAVE_SUCCESS = 'bots/SAVE_SUCCESS';
export const SEARCH_LOADING = 'bots/SEARCH_LOADING';
export const SEARCH_NO_RESULT = 'bots/SEARCH_NO_RESULT';
export const SEARCH_FETCH_SUCCESS = 'bots/SEARCH_FETCH_SUCCESS';
export const LIST_BOT_LOADING = 'bot/LIST_BOT_LOADING';
export const LIST_BOT_REMOVE_SUCCESS = 'bot/LIST_BOT_REMOVE_SUCCESS';
export const LIST_BOT_FAILED = 'bot/LIST_BOT_FAILED';

const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

export function botsLoading() {
  return {
    type: LOADING,
  };
}

export function botsRedirect(path) {
  return {
    type: REDIRECT,
    redirect: path,
  };
}

export function botsError(errorMsg) {
  return {
    type: ERROR,
    errorMsg,
  };
}

export function getBotsSuccess(bots) {
  return {
    type: FETCH_SUCCESS,
    bots,
  };
}
export function updateBotsSuccess(bots) {
  return {
    type: UPDATE_SUCCESS,
    bots,
  };
}
export function deleteBotSuccess() {
  return {
    type: DELETE_SUCCESS,
  };
}

export function saveBotSuccess() {
  return {
    type: SAVE_SUCCESS,
  };
}

export function searchLoading() {
  return {
    type: SEARCH_LOADING,
  };
}

export function fetchSearchNoResult(errorMsg) {
  return {
    type: SEARCH_NO_RESULT,
    errorMsg,
  };
}

export function fetchSearchResultSuccess(searchResult) {
  return {
    type: SEARCH_FETCH_SUCCESS,
    searchResult,
  };
}

export function botLoading(isList, projectId, isInstalled) {
  return {
    type: LIST_BOT_LOADING,
    projectId,
    isInstalled,
  };
}

export function botRemoveSuccess(isList, projectId) {
  return {
    type: LIST_BOT_REMOVE_SUCCESS,
    projectId,
  };
}

export function botFailed(isList, projectId, isInstalled) {
  return {
    type: LIST_BOT_FAILED,
    projectId,
    isInstalled,
  };
}

const initialState = {
  loading: false,
  errorMsg: '',
  bots: [],
  searching: false,
  searchStr: '',
  searchResult: [],
  redirect: '',
  changed: false,
};

const getInstallingItem = (projectId, state, installing, isInstalled) => {
  const newState = { ...state };
  const { bots: oldBots } = newState;
  const bots = [...oldBots];
  const index = bots.findIndex(item => item.projectId === projectId);
  const newBot = { ...bots[index] };
  if (installing) {
    newBot.installing = installing;
  }
  bots[index] = newBot;
  if (!isInstalled) {
    bots.splice(index, 1);
  }
  return { ...newState, bots };
};

export default function botsReducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
        bots: [],
        errorMsg: '',
        redirect: '',
      };
    case SEARCH_LOADING:
      return {
        ...state,
        errorMsg: '',
        searchStr: action.searchStr,
        searching: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        errorMsg: '',
        loading: false,
        searching: false,
        bots: action.bots,
        changed: false,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        bots: action.bots,
        changed: true,
        errorMsg: '',
      };
    case REDIRECT:
      return {
        ...state,
        errorMsg: '',
        loading: false,
        redirect: action.redirect,
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
      };
    case SEARCH_FETCH_SUCCESS:
      return {
        ...state,
        errorMsg: '',
        searching: false,
        bots: action.searchResult,
      };
    case SEARCH_NO_RESULT:
      return {
        ...state,
        errorMsg: action.errorMsg,
        searchStr: '',
        searching: false,
        bots: [],
      };
    case LIST_BOT_LOADING:
      return {
        ...state,
        ...getInstallingItem(action.projectId, state, true, true),
      };
    case LIST_BOT_REMOVE_SUCCESS:
      return {
        ...state,
        ...getInstallingItem(action.projectId, state, false, false),
      };
    case LIST_BOT_FAILED:
      return {
        ...state,
        ...getInstallingItem(action.projectId, state, false, true),
      };
    default:
      return state;
  }
}

// 봇리스트 가져오는 통신 부분
const getBotsList = async (robotId, detail, use) => {
  const res = await getAllMyBots(getLocale(), robotId, detail, use);
  return res;
};

// robotId: 로봇아이디
// 봇리스트 로드하는 부분
export const fetchBotsList = (robotId, use = true) => async dispatch => {
  dispatch(botsLoading());
  if (!robotId) return dispatch(botsRedirect('pibo'));
  // Bots페이지에서 페이지 reload 시, alive값 세팅보다 해당 부분이 먼저 수행됨.
  // if (!getAlive()) return dispatch(botsRedirect('pibo'));

  try {
    const bots = await getBotsList(robotId, false, use);
    if (bots.result) {
      return dispatch(getBotsSuccess(bots.data));
    }
    return dispatch(
      botsError(getErrorMsg(getErrorCode(bots.error), 'common:ERR.UNSPECIFIC')),
    );
  } catch (e) {
    return dispatch(
      botsError(getErrorMsg(getErrorCode(e), 'common:ERR.UNSPECIFIC')),
    );
  }
};

export const fetchBotsDetail = robotId => async dispatch => {
  dispatch(botsLoading());
  if (!robotId) return dispatch(botsRedirect('pibo'));

  if (!getAlive()) return dispatch(botsRedirect('pibo'));

  try {
    const bots = await getBotsList(robotId, true, true);
    if (bots.result) {
      return dispatch(getBotsSuccess(bots.data));
    }
    return dispatch(
      botsError(getErrorMsg(getErrorCode(bots.error), 'common:ERR.UNSPECIFIC')),
    );
  } catch (e) {
    return dispatch(
      botsError(getErrorMsg(getErrorCode(e), 'common:ERR.UNSPECIFIC')),
    );
  }
};

// pid: 로봇아이디
// updateList: [{bid, state}] 봇아이디, 변경스테이트
// 로봇의 봇 상태 변경
export const updateBotsList = (robotId, botId, use) => async (
  dispatch,
  getState,
) => {
  try {
    const res = await updateBotUsability(robotId, botId, use);
    if (res) {
      const { bots } = getState().bots;
      const data = [...bots].map(bot => {
        const obj = { ...bot };
        if (obj.botId === botId) {
          obj.use = use;
        }
        return obj;
      });
      refreshBots(() => infoLog('send bot usabiliy'));
      return dispatch(updateBotsSuccess(data));
    }
    return dispatch(
      botsError(getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC')),
    );
  } catch (error) {
    return dispatch(
      botsError(getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC')),
    );
  }
};

// 검색 자동완성 데이터 제목 기준으로 검색
export const fetchAutoCompleteData = ({
  robotId,
  str,
  use,
}) => async dispatch => {
  try {
    if (str.length < 1) {
      const bots = await getBotsList(robotId, false, use);
      if (bots.result) {
        return dispatch(getBotsSuccess(bots.data));
      }
    }
    const noKChar = /([가-힣])/g;
    let search = false;
    if (noKChar.test(str)) {
      search = true;
    } else if (str.length === 0) {
      search = true;
    }
    dispatch(searchLoading(str));
    if (search) {
      const res = await fetchBotSearchResults({
        str,
        locale: getLocale(),
        robotId,
        use,
      });
      const { result, data } = res;
      if (result && data.length > 0) {
        return dispatch(fetchSearchResultSuccess(data));
      }
      return dispatch(
        fetchSearchNoResult(i18.t('bot:SEARCH.NO_RESULT', { str })),
      );
    }
    return dispatch(fetchSearchNoResult(i18.t('bot:SEARCH.UNVALID')));
  } catch (error) {
    return dispatch(
      botsError(getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC')),
    );
  }
};

const checkProcessing = bots => {
  const { loading, searching, bots: botItems } = bots;
  const findInstallingItem = botItems.findIndex(
    ({ installing: installingItem }) => installingItem,
  );
  if (loading || searching || findInstallingItem >= 0) return true;
  return false;
};

// 봇 제거
export const unInstallBot = params => async (dispatch, getState) => {
  const { bots } = getState();
  if (checkProcessing(bots)) return;
  const { projectId, userId, robotId, isList, botId, title } = params;
  dispatch(botLoading(isList, projectId, true));

  setPiboBot('remove', {
    id: botId,
    projectId,
    userId,
    title,
  });
  setTimeout(async () => {
    const res = await removeBot({
      robotId,
      botId,
    });
    if (res.result) {
      return dispatch(botRemoveSuccess(isList, projectId));
    }
    return dispatch(botFailed(isList, projectId, true));
  }, 2000);
};
