import React, { useEffect, useState } from 'react';
import { Dimmer, Segment, Form, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledMessage } from '../../Message';
import { StyledButtonGroup, StyledButton } from '../PiboComponents';

const StyledPopUp = styled(Segment)`
  &&& {
    border-radius: 0 !important;
    position: relative !important;
    padding: 1rem 0 4rem !important;
    @media (max-width: 500px) {
      min-width: 250px;
      max-width: 85vw;
    }
    @media (min-width: 501px) and (max-width: 1200px) {
      min-width: 60vw;
      max-width: 70vw;
    }
    @media (min-width: 1201px) and (max-width: 2000px) {
      min-width: 30vw;
      max-width: 40vw;
    }
    @media (min-width: 2001px) {
      max-width: 20vw;
    }
  }
`;

const StyledContent = styled.div`
  text-align: 'center';
  font-size: 1rem;
  padding: 0.5rem 1rem;
  line-height: 1.2rem;
`;

const Input = styled.input`
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  border-bottom: 1px solid #0894d7 !important;
  border-radius: 0 !important;
  &::placeholder {
    font-weight: 100 !important;
  }
  &::-webkit-input-placeholder {
    font-weight: 100 !important;
  }
  &::after {
    content: '\f107';
    width: 40px;
    height: 40px;
  }
`;

const NetworkConnectPopup = ({
  isPopup: bPop,
  leftFunc,
  rightFunc,
  address,
  signal,
  wpa,
  leftLabel,
  rightLabel,
  essid,
  encrypted,
  error,
  t,
}) => {
  const [isPopup, setPopup] = useState(false);
  const [password, setPassword] = useState('');
  const [msg, setMessage] = useState('');
  const [type, setType] = useState('password');
  useEffect(() => {
    setPopup(bPop);
  });

  const handleChange = event => {
    setPassword(event.target.value);
  };

  const handleClickLeft = () => {
    if (leftFunc) {
      leftFunc();
    }
  };

  const handleClickRight = () => {
    if (encrypted && password.length < 1) {
      setPopup(bPop);
      setPassword(password);
      setMessage(t('SET.INPUT_WIFI_PW'));
    }
    return rightFunc({
      address,
      encrypted,
      essid,
      password,
      signal,
      wpa,
    });
  };

  const handleTogglePasswordType = () => {
    setType(type === 'text' ? 'password' : 'text');
  };

  return (
    <Dimmer active={isPopup} onClickOutside={handleClickLeft} page>
      <StyledPopUp>
        <StyledContent>
          <Form size="large" style={{ width: '100%' }}>
            <div className="field">
              <div className="ui fluid input">
                <Input
                  placeholder={t('conn:BTN.SELECT_WIFI')}
                  type="text"
                  name="apinput"
                  list="wifilist"
                  value={essid}
                  readOnly
                />
              </div>
            </div>
            {encrypted && (
              <div className="field">
                <div className="ui fluid input">
                  <Input
                    placeholder={t('conn:PASSWORD')}
                    type={type}
                    name="appw"
                    disabled={!encrypted}
                    value={password}
                    onChange={handleChange}
                    style={{ fontSize: '0.875rem' }}
                  />
                </div>
                <Checkbox
                  label={t('conn:SHOW_PASSWORD')}
                  style={{
                    width: '100%',
                    textAlign: 'initial',
                    margin: '1rem .5rem .5rem',
                  }}
                  onChange={handleTogglePasswordType}
                />
              </div>
            )}
            {((error && error.length > 0) || (msg && msg.length > 0)) && (
              <StyledMessage color="red" size="mini">
                {msg || error}
              </StyledMessage>
            )}
          </Form>
        </StyledContent>
        <StyledButtonGroup>
          {leftLabel && (
            <StyledButton color="gray" onClick={handleClickLeft}>
              {leftLabel}
            </StyledButton>
          )}
          <StyledButton color="blue" onClick={handleClickRight}>
            {rightLabel}
          </StyledButton>
        </StyledButtonGroup>
      </StyledPopUp>
    </Dimmer>
  );
};

export default NetworkConnectPopup;
