import styled from 'styled-components';
import { Icon, TextArea } from 'semantic-ui-react';

const StyledToggleButton = styled.button`
  font-family: inherit;
  height: 1.75rem;
  width: ${({ compact }) => (compact ? '2rem' : '3.75rem')};
  min-width: ${({ compact }) => (compact ? '2rem' : '3.75rem')};
  border-radius: 0.875rem;
  background-color: ${({ color }) => color || '#FFF'};
  padding: 0px;
  text-align: ${({ toggle }) => (toggle ? 'left' : 'right')};
  border: none;
  position: relative;
  outline: none;
  border: 1px solid #d1d3d4;
  line-height: 1.3;
  transition: 0.1s all linear;
  left: 0;
  &:disabled {
    filter: saturate(70%);
  }
`;

const ToggleIcon = styled(Icon)`
  color: ${({ customcolor }) => customcolor || 'inherit'};
  margin: 0.05rem 0 0 0 !important;
  &.corner {
    color: #0894d7;
  }
`;

const IconBackground = styled.div`
  background-color: #fff;
  border-radius: 0.875rem;
  font-size: 1rem;
  height: 100%;
  width: ${({ compact }) => (compact ? '100%' : '50%')};
  display: inline-block;
  text-align: center;
  border: ${({ color }) => (color ? `2px solid ${color}` : '2px solid #000')};
  transition: 0.1s all linear;
`;

const FixedBottomGroup = styled.div`
  font-family: inherit;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 144px;
  overflow: hidden;
  z-index: 99;
  background: #e5e5e5;
  padding: 1rem;
  display: flex;
  flex-flow: row;
  box-shadow: 0px 1px 3px 0px rgba(209, 211, 212, 1);
  border-top: 1px solid rgba(208, 211, 212, 0.55);
`;

const ExtendableTextArea = styled(TextArea)`
  font-family: inherit;
  width: 100%;
  font-size: 0.875rem;
  min-height: 1.75rem;
  border-radius: ${({ rows }) => (rows > 1 ? '.875rem' : '1.75rem')};
  border: ${({ error }) => (error ? '1px solid red' : '1px solid #d1d3d4')};
  /* border: 1px solid #d1d3d4; */
  padding: 4px 6px;
  margin-bottom: 4px;
  outline: none;
  line-height: inherit;
  &::placeholder {
    color: #d1d3d4;
  }
  transition: 0.1s all ease-out;
`;

const ModeDetailSpan = styled.span`
  color: ${({ color }) => color || '#d1d3d4'};
  font-size: 0.75rem;
  line-height: 1.1rem;
  align-self: center; /*flex-start;*/
  word-break: break-word;
  /* min-height: 1.75rem; */
  max-width: 100%;
  margin-left: 0.625rem;
`;

const ColumnWrap = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  width: ${({ fluid }) => (fluid ? '100%' : 'auto')};
  position: relative;
  justify-content: space-between;
`;

const RowWrap = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const SoundBarGroup = styled.div`
  height: 2.25rem;
  overflow-y: hidden;
  font-size: 10px;
  top: 60%;
  width: 100%;
  margin: -1.2rem 0 0;
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  & > div {
    background: #0894d7;
    width: 0.6em;
    margin: 0 0.1em;
    animation: ${({ hide }) =>
      hide ? 'none' : 'playing 0ms -1000ms linear infinite alternate'};
    height: ${({ hide }) => (hide ? '.4em' : 'auto')};
    opacity: ${({ hide }) => (hide ? '0.4' : 'auto')};
  }
  & > div:nth-child(6n + 1) {
    animation-duration: 399ms;
  }
  & > div:nth-child(6n + 2) {
    animation-duration: 435ms;
  }
  & > div:nth-child(6n + 3) {
    animation-duration: 489ms;
  }
  & > div:nth-child(6n + 4) {
    animation-duration: 446ms;
  }
  & > div:nth-child(6n + 5) {
    animation-duration: 412ms;
  }
  & > div:nth-child(6n + 6) {
    animation-duration: 467ms;
  }
  @keyframes playing {
    0% {
      opacity: 0.4;
      height: 0.2em;
    }
    100% {
      opacity: 1;
      height: 4em;
    }
  }
`;

const LabelButton = styled.div`
  color: #0894d7;
  padding: 0.5em;
  background: transparent;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  text-align: center;
`;

export {
  StyledToggleButton,
  ToggleIcon,
  IconBackground,
  FixedBottomGroup,
  ExtendableTextArea,
  ModeDetailSpan,
  ColumnWrap,
  RowWrap,
  SoundBarGroup,
  LabelButton,
};
