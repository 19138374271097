/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Header, List } from 'semantic-ui-react';

const MessageCard = ({ list, firstTime }) => (
  <div>
    <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
      {`파이보가 ${list.length}개의 메시지를 읽어줄게.`}
    </Header>
    <List>
      {list.map((msg, i) => (
        <List.Item key={`${firstTime}_${i}`}>
          <List.Content>{`${i + 1}. ${msg}`}</List.Content>
        </List.Item>
      ))}
    </List>
  </div>
);

export default MessageCard;
