import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { updateUserPassword, userPasswordValidate } from '../../ducks/userinfo';
import TopMenu from '../../components/TopMenu';
import { getUserId } from '../../utils/common';
import { Page, StyledInput } from '../../components/Components';
import withAuth from '../../hocs/withAuth';
import {
  InputLabel,
  InputMessage,
  InputWrap,
} from '../../components/user/Components';

const UserUpdatePwPage = () => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userinfo = useSelector(state => state.userinfo);
  const {
    isComplete,
    error: { password: error },
  } = userinfo;
  const [pws, setPW] = useState({ newPw: '', newRePw: '', oldPw: '' });
  const { oldPw, newPw, newRePw } = pws;
  const enabled = Boolean(
    oldPw && newPw && newRePw && newPw === newRePw && !error,
  );

  useEffect(() => {
    if (isComplete) {
      navigate(-1);
    }
  }, [isComplete]);

  const handleChange = e => {
    const { name, value } = e.target;
    dispatch(
      userPasswordValidate({
        name,
        value,
        newRePw,
        newPw,
      }),
    );
    setPW({
      ...pws,
      [name]: value,
    });
  };

  const handleBackClick = () => {
    navigate(`/setting/${getUserId()}`);
  };

  const handleUpdate = () => {
    if (!enabled) return;
    dispatch(updateUserPassword({ userId: getUserId(), oldPw, newPw }));
  };

  const infoArr = [
    {
      id: 'oldPw',
      name: t('PW.CURRENT'),
      value: oldPw,
      type: 'password',
      placeholder: t('PW.PLACEHOLDER.CURRENT'),
    },
    {
      id: 'newPw',
      name: t('PW.NEW'),
      value: newPw,
      type: 'password',
      placeholder: t('PW.PLACEHOLDER.NEW'),
    },
    {
      id: 'newRePw',
      name: t('PW.NEW_RE'),
      value: newRePw,
      type: 'password',
      placeholder: t('PW.PLACEHOLDER.NEW_RE'),
    },
  ];

  return (
    <Page>
      <TopMenu
        title={t('PW.UPDATE')}
        menuMode="WITH_BACK_AND_COMPLETE"
        handleBackClick={handleBackClick}
        handleCompleteClick={handleUpdate}
        enabled={enabled}
      />
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <Segment textAlign="center">
              {infoArr.map(({ type, id, name, value, placeholder }) => (
                <InputWrap>
                  <InputLabel>{name}</InputLabel>
                  <div
                    style={{
                      color: '#03BFD7',
                      fontWeight: '100',
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <StyledInput
                      type={type}
                      name={id}
                      value={value}
                      placeholder={placeholder}
                      style={{
                        height: '100%',
                        flexBasis: '70px',
                        flexGrow: '100',
                      }}
                      onChange={handleChange}
                      onBlur={handleChange}
                    />
                  </div>
                </InputWrap>
              ))}
              {error && error.length > 0 && (
                <InputMessage>{error}</InputMessage>
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Page>
  );
};

export default withAuth(UserUpdatePwPage);
