import React, { useState, useEffect } from 'react';
import { Image, Icon, Label, Header } from 'semantic-ui-react';
import { Element, scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import {
  StoreCardButton,
  StyledStatistics,
  StoreColumn,
  StoreWrap,
  StoreRow,
  StoreLinePanel,
  StoreAccordion,
  StoreRating,
} from './StoreComponents';
import withScrollManual from '../../hocs/withScrollManual';
import ReviewContainer from '../../containers/botstore/ReviewContainer';
import { getFile } from '../../utils/api';
import { getRobotId, parseBotDescription } from '../../utils/common';
import { BotImage } from '../Components';

const StoreBotDetail = props => {
  const { t } = useTranslation('store');
  const {
    id,
    makerOId,
    makerId,
    projectId,
    projectName,
    category,
    detail,
    makerNickname,
    totalReview,
    title,
    handleDownClick,
    handleRemoveClick,
    handleBotOpenClick,
    isInstalled,
    use,
    // installing,
    prevented,
    review,
    type,
  } = props;
  const [activeIndexes, setActiveIndexes] = useState([0, 1]);
  const [refresh, setRefresh] = useState(false);
  const [installing, setReady] = useState(false);
  const viewMode = getRobotId().length === 0;

  useEffect(() => {
    setReady(false);
    if (isInstalled) {
      setRefresh(true);
    }
  }, [isInstalled]);

  const handleClick = index => {
    if (activeIndexes.includes(index)) {
      return setActiveIndexes(activeIndexes.filter(value => value !== index));
    }
    return setActiveIndexes([...activeIndexes, index]);
  };

  const getInstallInfo = () => {
    const obj = { botId: id, title };
    if (makerId === 'pibo' && projectId.indexOf('OFFICIAL_') === 0) {
      return { ...obj, projectId, userId: makerId };
    }
    return { ...obj, projectId: projectName, userId: makerOId };
  };

  const handleInstall = () => {
    setReady(true);
    handleDownClick(getInstallInfo());
  };
  const handleUninstall = () => {
    setReady(true);
    handleRemoveClick(getInstallInfo());
  };

  return (
    <StoreWrap
      style={{
        padding: '1rem',
        flexFlow: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
      }}
    >
      <StoreColumn style={{ margin: '.5rem' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            padding: '1rem',
          }}
        >
          <div>
            <BotImage {...{ id, style: 'detail' }} />
            <Header as="h3" style={{ margin: 0 }}>
              {title}
              {type === 2 && (
                <Label
                  color="yellow"
                  size="mini"
                  horizontal
                  style={{ marginLeft: '.5rem' }}
                >
                  <Icon name="flask" />
                  Lab
                </Label>
              )}
            </Header>
            <div>
              <div
                style={{
                  maxHeight: '2.5rem',
                  overflow: 'hidden',
                  margin: '0.5em 0',
                  wordBreak: 'break-all',
                  color: 'rgba(0,0,0,.4)',
                }}
              >
                {category}
              </div>
              {!viewMode && isInstalled && (
                <div>
                  {!prevented && (
                    <StoreCardButton
                      color="red"
                      basic
                      disabled={installing}
                      onClick={handleUninstall}
                      style={{ marginRight: '1rem' }}
                    >
                      {installing && <Icon name="spinner" loading />}
                      {!installing && t('UNINSTALL')}
                    </StoreCardButton>
                  )}
                  {use && (
                    <StoreCardButton
                      color="blue"
                      basic
                      disabled={installing || !use}
                      onClick={() => handleBotOpenClick(projectId, id)}
                    >
                      {t('OPEN')}
                    </StoreCardButton>
                  )}
                </div>
              )}
              {!viewMode && !isInstalled && (
                <div>
                  <StoreCardButton
                    color="blue"
                    basic
                    disabled={installing}
                    onClick={handleInstall}
                  >
                    {installing && <Icon name="spinner" loading />}
                    {!installing && t('INSTALL')}
                  </StoreCardButton>
                </div>
              )}
            </div>
          </div>
        </div>
      </StoreColumn>
      <StoreColumn style={{ background: '#fff' }}>
        <StoreLinePanel style={{ paddingLeft: '1rem' }}>
          <Image
            height="40"
            src={getFile({ userPhoto: makerId })}
            avatar
            style={{ marginLeft: '1rem' }}
          />
          <div style={{ width: '100%', marginLeft: '1rem', fontWeight: '100' }}>
            {makerNickname || makerId}
          </div>
          {/* <Icon name="angle right" style={{ marginRight: '1rem' }} />} */}
        </StoreLinePanel>
      </StoreColumn>
      <StoreColumn
        style={{ background: '#fff' }}
        onClick={() => scroller.scrollTo('reviewContainer')}
      >
        {!review.loading && review.size > 0 && (
          <StoreLinePanel>
            <div style={{ marginLeft: '1rem', fontWeight: '100' }}>
              {review.avg.toFixed(1)}
            </div>
            <div style={{ width: '70%' }}>
              <StoreRating
                value={review.avg}
                rating={Math.ceil(review.avg)}
                maxRating={5}
                size="tiny"
                disabled
              />
              <StyledStatistics style={{ paddingLeft: '0.5rem' }}>
                {t('REVIEW.TOTAL', { reviews: review.size })}
              </StyledStatistics>
            </div>
            <Icon name="angle right" style={{ marginRight: '1rem' }} />
          </StoreLinePanel>
        )}
        {!review.loading && review.size === 0 && (
          <StoreLinePanel style={{ paddingLeft: '1rem' }}>
            {t('REVIEW.NO_RESULT')}
          </StoreLinePanel>
        )}
      </StoreColumn>
      <StoreColumn>
        <StoreAccordion
          index={0}
          title={t('DETAIL.COMMAND')}
          activeIndexes={activeIndexes}
          onClick={handleClick}
        >
          {(detail && detail.example) || t('DETAIL.NO_COMMAND')}
        </StoreAccordion>
      </StoreColumn>
      <StoreColumn>
        <StoreAccordion
          index={1}
          title={t('DETAIL.FUNCTION')}
          activeIndexes={activeIndexes}
          onClick={handleClick}
        >
          {detail ? (
            <div>{parseBotDescription(detail.description)}</div>
          ) : (
            t('DETAIL.NO_FUNCTION')
          )}
        </StoreAccordion>
      </StoreColumn>
      <StoreColumn>
        <StoreAccordion
          index={2}
          title={t('DETAIL.UPDATE_LIST')}
          activeIndexes={activeIndexes}
          onClick={handleClick}
        >
          {(detail && detail.update) || t('DETAIL.NO_UPDATE')}
        </StoreAccordion>
      </StoreColumn>
      {/* <StoreRow style={{ marginBottom: '0.5rem' }}>
        <StoreColumn>
          <StoreAccordion
            index={3}
            title={t('DETAIL.REQUIRE')}
            content={(detail && detail.requires) || t('DETAIL.NO_REQUIRE')}
            activeIndexes={activeIndexes} onClick={handleClick}
          />
        </StoreColumn>
          </StoreRow> */}
      {!!id && (
        <Element name="reviewContainer" style={{ width: '100%' }}>
          <ReviewContainer
            botId={id}
            projectId={projectId}
            isInstalled={isInstalled}
            totalReview={totalReview}
            refresh={refresh}
            style={{ width: '100%' }}
          />
        </Element>
      )}
      <StoreRow style={{ height: '65px' }} />
    </StoreWrap>
  );
};

export default withScrollManual(StoreBotDetail);
