import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Scroll from 'react-scroll';
import { useLifecycles } from 'react-use';
import { useNavigate, useParams } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import BottomMenu from '../../components/BottomMenu';
import StoreBotDetail from '../../components/botstore/StoreBotDetail';
import { getRobotId } from '../../utils/common';
import StoreMenuContainer from './StoreMenuContainer';
import { DEFAULT_APPS } from '../../utils/consts';
import {
  StoreContent,
  StoreDimmerDimmable,
} from '../../components/botstore/StoreComponents';
import {
  fetchBotInfo,
  backToList,
  installBot,
  uninstallBot,
} from '../../ducks/store';
import { Page } from '../../components/Components';

const BotstoreDetailPage = props => {
  const pageRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const { reviewAvg, reviewSize, reviewLoading } = props;
  const [visible, setVisible] = useState(false);
  const [hidden, setHidden] = useState(false);
  const dispatch = useDispatch();
  const selector = useSelector(state => state.store);
  const { loading, bot, user } = selector;

  const handleScroll = () => {
    const scrollTopValue =
      (pageRef.current && pageRef.current.scrollTop) ||
      pageRef.current.scrollTop;
    setHidden(!(scrollTopValue === 0));
  };

  useLifecycles(
    () => {
      pageRef.current.addEventListener('scroll', handleScroll);
      const scroll = Scroll.animateScroll;
      scroll.scrollToTop({ duration: 0, smooth: true });
      const { botId } = params;
      // onMount(botId);
      dispatch(fetchBotInfo({ botId, robotId: getRobotId() }));
    },
    () => {
      Scroll.animateScroll.scrollToTop({ duration: 0, smooth: true });
      dispatch(backToList());
      if (pageRef.current) {
        pageRef.current.removeEventListener('scroll', handleScroll);
      }
    },
  );

  const handleSidebarBtnClick = () => {
    setVisible(!visible);
  };

  const handleBackClick = () => {
    navigate('/botstore');
  };

  const handleMenuItemClick = (e, { name }) => {
    navigate(`/${name}`);
  };

  const handleSidebarHide = () => setVisible(false);

  const handleMyBotClick = () => {
    setVisible(false);
    navigate('/mybots');
  };

  const handleDownClick = ({ botId, projectId, title, userId }) => {
    dispatch(installBot({ botId, projectId, title, userId }));
  };

  const handleRemoveClick = ({ botId, projectId, title, userId }) => {
    dispatch(uninstallBot({ botId, projectId, title, userId }));
  };

  const handleBotOpenClick = (projectId, id) => {
    if (DEFAULT_APPS.includes(projectId)) {
      return navigate(`/bots/${projectId}`);
    }
    return navigate(`/bots/defaultPage?botId=${id}`);
  };

  const review = { avg: reviewAvg, size: reviewSize, loading: reviewLoading };

  return (
    <StoreDimmerDimmable className={visible && 'dimmed'}>
      <Page style={{ top: 0 }} ref={pageRef}>
        <TopMenu
          title="botstore"
          colored={3}
          menuMode="WITH_BACK_AND_MENU"
          handleCompleteClick={handleSidebarBtnClick}
          handleBackClick={handleBackClick}
          hidden={hidden}
        />
        <StoreContent className="clearfix">
          {bot && bot.id && (
            <StoreBotDetail
              loading={loading}
              {...bot}
              review={review}
              handleBotOpenClick={handleBotOpenClick}
              handleDownClick={handleDownClick}
              handleRemoveClick={handleRemoveClick}
            />
          )}
        </StoreContent>
        <StoreMenuContainer
          user={user}
          visible={visible}
          handleSidebarHide={handleSidebarHide}
          handleMyBotClick={handleMyBotClick}
        />
        <BottomMenu
          selectMenu="botstore"
          handleMenuItemClick={handleMenuItemClick}
        />
      </Page>
    </StoreDimmerDimmable>
  );
};

export default BotstoreDetailPage;

// 로그인 여부 따로 체크하지 않음
// botstore에 올라와 있는 모든 bot 리스트 가져오기
// 광고할 bot 배너 이미지와 링크 리스트 (2, 3개)
// bot 리스트 아이템의 정보 (아이템id, 제목, 썸네일, 평점, 평가개수, 명령어 || 상세설명)
