import React, { useState } from 'react';
import { Icon, Dimmer } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import Camera from '../../../components/bots/AvatarBot/Camera';
import Photo from '../../../components/bots/AvatarBot/Photo';
import { FixedBottomButton, Image } from '../../../components/Components';
import Popup from '../../../components/Popup';
import { deletePhotoByFileIdApi } from '../../../ducks/bots/photoBot/photoBotApi';
import { takePicturePibo, simulatePibo } from '../../../pibo';
import { getFile } from '../../../utils/api';

const initialState = {
  up: false,
  left: false,
  right: false,
  down: false,
  panPos: 0,
  tiltPos: 0,
  shooting: false,
  zoom: false,
  photo: '',
  fileId: '',
  popup: {},
  error: '',
};

const TILT_MAX = 20;
const TILT_MIN = -20;
const PAN_MAX = 40;
const PAN_MIN = -40;

const CameraContainer = ({ standalone }) => {
  const { t } = useTranslation('bot');
  const [state, setState] = useState({ ...initialState });

  const onPhotoComplete = async data => {
    if (!data) {
      return setState({
        ...state,
        shooting: false,
        photo: null,
        fileId: null,
        error: t('bot:AVA.PHT.FAILED'),
      });
    }
    const { fileId } = data;
    return setState({
      ...state,
      shooting: false,
      photo: getFile({ photo: fileId }),
      fileId,
    });
  };

  const handleShootClick = () => {
    const result = takePicturePibo(onPhotoComplete);
    if (result) {
      setState({
        ...state,
        shooting: true,
      });
    }
  };

  const getCamCoords = () => {
    const { tiltPos, panPos } = state;
    return {
      tiltMax: tiltPos === TILT_MAX,
      tiltMin: tiltPos === TILT_MIN,
      panMax: panPos === PAN_MAX,
      panMin: panPos === PAN_MIN,
    };
  };

  const onControllerHandler = direction => {
    const { up, left, right, down } = state;
    const { tiltMax, tiltMin, panMax, panMin } = getCamCoords();
    if (up || left || right || down) return;
    if (tiltMax && direction === 'down') return;
    if (tiltMin && direction === 'up') return;
    if (panMax && direction === 'left') return;
    if (panMin && direction === 'right') return;

    let { panPos, tiltPos } = state;
    let head;
    if (direction === 'up' || direction === 'down') {
      head = 'head_tilt';
      if (direction === 'up') {
        tiltPos -= tiltPos > TILT_MIN ? 5 : 0;
      } else {
        tiltPos += tiltPos < TILT_MAX ? 5 : 0;
      }
    }
    if (direction === 'left' || direction === 'right') {
      head = 'head_pan';
      if (direction === 'left') {
        panPos += panPos < PAN_MAX ? 10 : 0;
      } else {
        panPos -= panPos > PAN_MIN ? 10 : 0;
      }
    }
    const result = simulatePibo(head, head === 'head_tilt' ? tiltPos : panPos);
    if (result) {
      setState({
        ...initialState,
        [direction]: true,
      });
      setState({ ...state, panPos, tiltPos });
      setTimeout(() => {
        setState({
          ...initialState,
          panPos,
          tiltPos,
        });
      }, 1500);
    }
  };

  const getStopEnableState = ({
    up: u,
    down: d,
    left: l,
    right: r,
    shooting: s,
  }) => {
    if (u || d || l || r) return true;
    if (s) return true;
    return false;
  };

  const handleImageClick = () => {
    const { zoom } = state;
    setState({ ...state, zoom: !zoom });
  };

  const setInitial = () => {
    setState({ ...initialState, photo: '' });
  };

  const handleSaveClick = () => {
    const popup = {
      isPopup: true,
      rightLabel: t('common:POP.OK'),
      rightFunc: setInitial,
      contents: t('AVA.CAM.SAVED'),
      mode: 'confirm',
    };
    setState({ ...state, popup });
  };

  const getErrorCode = e =>
    e && 'code' in e ? e.code.toUpperCase() : 'UNSPECIFIC';

  const handleCancelClick = async () => {
    const { fileId } = state;
    try {
      const { result, error } = await deletePhotoByFileIdApi(fileId);
      if (result) {
        setInitial();
      } else {
        setState({
          ...state,
          error: t(`common:ERR.${getErrorCode(error)}`),
        });
      }
    } catch (error) {
      setState({
        ...state,
        error: t(`common:ERR.${getErrorCode(error)}`),
      });
    }
  };

  const { up, down, left, right, shooting, photo, zoom, popup, error } = state;
  const position = getCamCoords();
  const stopEnable = getStopEnableState({
    up,
    down,
    left,
    right,
    shooting,
  });
  return (
    <div>
      <Dimmer
        active={zoom}
        onClickOutside={handleImageClick}
        page
        style={{ padding: 0 }}
      >
        <Image src={photo} alt={t('AVA.CAM.ZOOM')} />
      </Dimmer>
      <Camera
        preventState={up || left || right || down || shooting || photo}
        up={up}
        down={down}
        left={left}
        right={right}
        position={position}
        onControllerHandler={onControllerHandler}
      />
      <Photo photo={photo} error={error} onClickImage={handleImageClick} />
      {photo && (
        <div>
          <FixedBottomButton
            type="button"
            widths={2}
            style={{ right: '1rem', margin: '0 -1rem' }}
            onClick={handleSaveClick}
            standalone={standalone}
          >
            {t('AVA.CAM.SAVE')}
          </FixedBottomButton>
          <FixedBottomButton
            type="button"
            widths={2}
            style={{ left: '1rem', margin: '0 -1rem' }}
            inverted
            onClick={handleCancelClick}
            standalone={standalone}
          >
            {t('AVA.CAM.CANCEL')}
          </FixedBottomButton>
        </div>
      )}
      {photo && error && (
        <FixedBottomButton
          name="confirm"
          type="button"
          style={{ margin: '0 -1rem' }}
          onClick={setInitial}
          standalone={standalone}
        >
          <div>{t('AVA.CAM.OK')}</div>
        </FixedBottomButton>
      )}
      {!photo && (
        <FixedBottomButton
          name="stop"
          type="button"
          style={{ margin: '0 -1rem' }}
          disabled={stopEnable}
          onClick={handleShootClick}
          standalone={standalone}
        >
          {shooting && (
            <div>
              <Icon name="spinner" loading style={{ color: '#fff' }} />
              {t('AVA.CAM.SHOOTING')}
            </div>
          )}
          {!shooting && t('AVA.CAM.SHOOT')}
        </FixedBottomButton>
      )}
      {'isPopup' in popup && <Popup {...popup} />}
    </div>
  );
};

export default CameraContainer;
