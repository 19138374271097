import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
// import { useUpdateEffect } from 'react-use';
import StoreSearch from './StoreSearch';
import StoreSearchList from './StoreSearchList';
import StoreListES from './StoreListES';
import { StoreWrap, StoreRow, StoreColumn } from './StoreComponents';
import { fetchStoreList, installBot } from '../../ducks/store';
import { getRobotId } from '../../utils/common';

const StoreMain = props => {
  const {
    addSearchChange: searchChange,
    handleItemClick,
    handleOpenClick,
  } = props;
  const dispatch = useDispatch();
  const selector = useSelector(state => state.store);
  const { mainData, searching, searchStr, errorMsg, searchResult } = selector;
  const [isLoading, setLoading] = useState(searching);
  const [error, setError] = useState(errorMsg);

  const addSearchChange = val => {
    if (!isLoading) {
      searchChange(val);
    }
  };

  const resetComponent = () => {
    // setLoading(false);
    // setBotItems([]);
    // setValue('');
    addSearchChange('');
  };

  const handleDownClick = d => {
    dispatch(installBot({ robotId: getRobotId(), ...d }));
    // { robotId: getRobotId(), isList: true, ...d }
  };

  const handleSearchRemove = () => resetComponent();
  const handleSearchChange = (e, { value: val }) => {
    // setValue(val);
    setTimeout(() => addSearchChange(val), 500);
  };

  useMount(() => {
    if (mainData.length === 0) {
      dispatch(fetchStoreList({ robotId: getRobotId(), page: 0 }));
    }
  });

  useEffect(() => {
    if (error !== errorMsg) {
      setError(errorMsg);
    }
  }, [errorMsg]);

  useEffect(() => {
    if (isLoading !== searching) {
      setLoading(searching);
      // setValue(v);
    }
  }, [searching]);

  return (
    <StoreWrap>
      <StoreRow>
        <StoreColumn fluid style={{ margin: 0, width: '100%' }}>
          <StoreSearch
            {...{
              results: searchResult,
              isLoading,
              value: searchStr,
              error,
              handleSearchChange,
              handleSearchRemove,
            }}
            fluid
          />
        </StoreColumn>
      </StoreRow>
      <StoreRow>
        {searchStr === '' && (
          <StoreListES
            {...{
              botItems: mainData,
              handleDownClick,
              handleItemClick,
              handleOpenClick,
            }}
          />
        )}
        <StoreColumn fluid>
          {!!searchStr && (
            <StoreSearchList
              {...{
                results: searchResult,
                isLoading,
                searchStr,
                error,
                handleItemClick,
                handleOpenClick,
              }}
              handleItemClick={handleItemClick}
              handleOpenClick={handleOpenClick}
            />
          )}
        </StoreColumn>
      </StoreRow>
    </StoreWrap>
  );
};

export default StoreMain;
