/* eslint-disable */
import { connectSocket, disconnectSocket } from './socket';
import i18 from '../lang/i18n';
// eslint-disable-next-line import/no-cycle
// import { getStatus } from '../utils/api';
import { errorLog, infoLog } from '../utils/report';
import { pageRefresh } from '../utils/common';

let P = null;
let PI = null;
let alive = null;
const setAlive = (v, cb) => {
  if (alive === true && v === false) {
    infoLog('파이보 전원 off');
    pageRefresh();
    // window.location.reload();
  }
  if (cb && typeof cb === 'function') {
    cb(v);
  }
  alive = v;
};

export const getAlive = () => {
  /*
  // DAPI 요청으로 alive 판단하는 부분
  if (!alive) {
    getStatus().then(({ result, data }) => {
        const { alive: status, aliveDiff } = data;
        if (alive !== status && aliveDiff < 10) {
          setAlive(status);
        }
      }
      return alive || false;
    });
  } */
  return alive || false;
};

const checkStatus = () => {
  return getAlive();
};

function decorator(mainFn, preCallFn, msg = '') {
  const status = preCallFn();
  if (status) {
    mainFn();
    return true;
  }
  if (msg) {
    // eslint-disable-next-line no-alert
    alert(i18.t(msg));
  }
  return false;
}

// eslint-disable-next-line consistent-return
export const piboInit = async (phyId, cb = null, connectCb = null) => {
  if (P && 'robot_id' in P && P.robot_id === phyId && alive) {
    connectCb();
  } else {
    const pibo = await connectSocket(phyId);
    if (pibo.pibo === null && cb) {
      return cb();
    }
    if (pibo) {
      P = pibo;
      PI = P.pibo;
      if (PI) {
        const timer = setTimeout(
          () => (alive ? connectCb && connectCb() : cb && cb()),
          2500,
        );
        return PI.receive('pibo', data => {
          if (data && 'alive' in data && data.alive) {
            return setAlive(data.alive, () => {
              if (connectCb) {
                clearTimeout(timer);
                connectCb();
              }
            });
          } else if (
            !data.alive &&
            data.data.indexOf('disconn') > -1 &&
            !getAlive()
          ) {
            return setAlive(false, () => {
              if (
                !(
                  window.location.pathname.indexOf('/pibo') >= 0 &&
                  window.location.pathname.indexOf('/wifi') > 0
                )
              ) {
                pageRefresh();
                // window.location.reload();
              }
            });
          }
          // alert(`pibo received data 연결되지 않음 - cb 실행, ${cb}`);
          return setAlive(false, cb);
        });
      }
    }
  }
};

export const setPiboLocale = (locale, cb) => {
  if (checkStatus()) {
    PI.onload();
    PI.change(locale, cb);
  }
};

export const speakPibo = (str, cb = null) => {
  const talk = () =>
    PI.talk(str, d => {
      if (cb) {
        cb(d);
      }
    });
  return decorator(talk, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const checkPiboCmds = (str, cb) => {
  const candidate = () =>
    PI.candidate(str, data => {
      cb(data);
    });
  decorator(candidate, checkStatus);
};

export const sendPiboMsg = ({ mode, type, _id }, cb = null) => {
  if (checkStatus()) {
    return PI.message({ mode, type, _id });
  }
  return cb(false);
};

export const sendSaveStatus = () => {
  PI.send('$_setup', 'ready');
};

export const deletePiboMsg = _id => {
  const delmsg = () => PI.send('messageDelete', { _id });
  decorator(delmsg, checkStatus);
};

export const receivePiboEvent = (type, cb) => {
  const receive = () => {
    PI.receive(type, data => {
      if (typeof cb === 'function') {
        if (data) {
          return cb(data);
        }
        return cb();
      }
    });
  };
  // return decorator(receive, checkStatus);
  if (PI) {
    receive();
  }
};

export const setPiboMode = (mode, value) => {
  const setmode = () => PI.mode(mode, { value });
  return decorator(setmode, checkStatus);
};

export const actionPibo = value => {
  const action = () => PI.motion(value);
  decorator(action, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const killPibo = () => {
  const kill = () => PI.kill();
  decorator(kill, checkStatus);
};

export const motionPibo = (direction, cycle) => {
  const motion = () => PI.motion(direction, { cycle });
  return decorator(motion, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const speakAndMotionPibo = (value, preset) => {
  // const sap = () => PI.speakAndMotion(value);
  const sap = () => (preset ? PI.tell(value, { preset }) : PI.tell(value));
  return decorator(sap, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const takePicturePibo = cb => {
  const cam = () =>
    PI.camera(data => {
      console.log(data);
      if (data && 'result' in data && data.result) {
        return cb(data.data);
      }
      return cb(null);
    });
  return decorator(cam, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const simulatePibo = (part, pos) => {
  const simulator = () => PI.simulator(part, { pos });
  return decorator(simulator, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const setPiboLocation = value => {
  const location = () => PI.send('location', value);
  decorator(location, checkStatus);
};

export const setPiboGeo = (value, cb) => {
  if (checkStatus()) {
    return PI.geo(value, () => {
      cb && cb();
    });
  }
  return cb && cb();
};

export const setPiboDisturb = (value, cb) => {
  if (checkStatus()) {
    const {
      execTime,
      expTime,
      repeatValue: {
        value: { day },
      },
    } = value;
    PI.mode('disturb', { execTime, expTime, day });
  }
  cb && cb();
};

export const setPiboReaction = (value, cb) => {
  if (checkStatus()) {
    PI.reaction(value);
  }
  cb && cb();
};

export const setPiboStyle = (value, cb) => {
  if (checkStatus()) {
    PI.style(value);
  }
  cb && cb();
};

export const setPiboVolume = value => {
  const volume = () => PI.volume(value);
  return decorator(volume, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const complimentPibo = cb => {
  const compliment = () => PI.compliment(cb);
  return decorator(compliment, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const getPiboInfo = () =>
  new Promise((res, rej) => {
    PI.info(data => {
      if (data) {
        setAlive(true);
        return res(data);
      }
      setAlive(false);
      return rej(new Error('unconnected'));
    });
  });

export const setPiboBot = (cmd, data) => {
  const bot = () => PI.bot(cmd, data);
  decorator(bot, checkStatus);
};

export const refreshBots = cb => {
  const refresh = () => PI.refreshBots(cb);
  decorator(refresh, checkStatus);
};

export const haltPibo = () => {
  const halt = () => PI.halt();
  decorator(halt, checkStatus);
};

export const previewPibo = (arr, cb) => {
  /* const array = [
    {
      speak: '말해봐 아침!! 아침!!!',
      fx: { name: 'CICADA', file: 'cicada.mp3' },
      eye: { name: 'excite2', color: '255,125,0', key: 'GUf6XkgZlwv' },
    },
    {
      bg: { key: 'IkxBo2GcNx', name: 'Clean Break', file: 'clean_break.mp3' },
      eye: { name: 'happy3', color: '255,255,177', key: 'yC9pRjIBstZ' },
    },
    {
      motion: { name: '발운동1', key: 'foot1' },
      fx: { name: 'SLEEP', file: 'sleep.mp3' },
      eye: { name: 'trust2', color: '0,180,0', key: 'xQlXA9tkN22' },
    },
    {
      bot: {
        _id: '5c91da11d8ea3a0a788610d3',
        title: '날씨',
        command: '날씨 알려줘',
        commandList: ['날씨', '미세먼지', '미세', '초미세', '먼지'],
        src: 'blob:http://localhost:3000/55456a62-93c2-462f-9c1c-7468b832eca0',
      },
    },
  ]; */
  const preview = () =>
    PI.interpret(arr, a => {
      if (cb) {
        cb();
      }
    });
  return decorator(preview, checkStatus, 'pibo:MSG.POWER_OFF');
};

export const refreshUser = () => {
  try {
    const refresh = () => PI.refreshUser();
    decorator(refresh, checkStatus);
  } catch (error) {
    errorLog(`refreshUser ${JSON.stringify(error)}`);
  }
};

export const getWifiList = cb => {
  const getList = () => PI.wifi_list(cb);
  decorator(getList, checkStatus);
};

export const getVersion = () => (P && 'version' in P ? P.version : '');

export const wifiReset = cb => {
  const reset = () => PI.wifi_reset(cb);
  decorator(reset, checkStatus);
};

export const wifiChange = (ap, cb) => {
  const change = () =>
    PI.wifi_change(ap, data => {
      cb();
    });
  decorator(change, checkStatus);
};

export const piboReset = params => {
  PI.reset(data => {
    infoLog('PI - reset', params, data);
  });
};

export const piboDisconnect = () => {
  disconnectSocket(null);
  P = null;
  PI = null;
};
