/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';
import { Segment } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import i18n from '../../../lang/i18n';
import {
  BorderedSegment,
  CardBody,
  Player,
  PlayerWrapper,
  ToggleButtonItem,
  ToggleTextItem,
} from './Components';
import { getFile } from '../../../utils/api';

const FxCard = props => {
  const { fx: fxList, onChange, t } = props;
  const [select, setSelect] = useState({ catIndex: -1, itemIndex: -1 });
  const [value, setValue] = useState(null);
  const [playData, setPlayData] = useState({
    playing: false,
    duration: 0,
    played: 0,
    src: null,
    url: null,
    className: '',
  });
  const [playing, toggle] = useToggle(false);

  const initPlayData = () => {
    setValue({ ...value });
    setPlayData({
      playing: false,
      duration: 0,
      played: 0,
      src: null,
      url: null,
    });
  };

  const handleCategoryClick = i => {
    const { catIndex } = select;
    if (i === catIndex) {
      setSelect({ catIndex: -1, itemIndex: -1 });
    } else {
      setSelect({ catIndex: i, itemIndex: -1 });
    }
    onChange(null);
    initPlayData();
  };

  const handleItemClick = i => {
    const { catIndex, itemIndex } = select;
    if (i === itemIndex) {
      setSelect({ ...select, itemIndex: -1 });
      onChange(null);
      initPlayData();
    } else {
      setSelect({ ...select, itemIndex: i });
      const fx = fxList[catIndex].list[i];
      onChange(fx);
      setValue({ ...value, ...fx });
      setPlayData({
        playing: true,
        duration: fx.len || 1,
        played: 0,
        src: fx.value,
        url: getFile({ media: fx.value }),
      });
    }
  };

  const handleProgress = ({ playedSeconds, played }) => {
    if (played === 1) {
      toggle(false);
      return setPlayData({
        ...playData,
        played: 0,
        url: null,
        playing: false,
      });
    }

    return setPlayData({
      ...playData,
      played: parseInt(Math.round(playedSeconds), 10),
    });
  };

  useUpdateEffect(() => {
    if (playing) {
      const fx = fxList[select.catIndex].list[select.itemIndex];
      return setPlayData({
        ...playData,
        url: fx.url,
        playing: true,
      });
    }
    return setPlayData({
      ...playData,
      played: 0,
      url: null,
      playing: false,
    });
  }, [playing]);

  return (
    <CardBody>
      <p>{i18n.t('bot:TRN.TITLE.FX_OPTIONAL')}</p>
      <BorderedSegment radius={5}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'wrap',
            margin: '-10px -10px 0',
            justifyContent: 'space-evenly',
            padding: '1rem',
          }}
        >
          <BorderedSegment radius={10} style={{ width: '100%' }}>
            {fxList.map(({ genre }, i) => (
              <ToggleButtonItem
                color="8,148,215"
                key={`${genre.en}_${i}`}
                onClick={() => handleCategoryClick(i)}
                selected={select.catIndex === i}
              >
                <span>{t(`bot:TRN.CATEGORY.${genre.en.toUpperCase()}`)}</span>
              </ToggleButtonItem>
            ))}
          </BorderedSegment>
          {select.catIndex >= 0 && select.itemIndex >= 0 && (
            <Segment
              style={{
                width: 0,
                height: 0,
                overflow: 'hidden',
                position: 'absolute',
                border: 'none',
                padding: 0,
              }}
            >
              <PlayerWrapper>
                <Player onClick={toggle}>
                  <ReactPlayer
                    {...{
                      playing: playData.playing,
                      url: playData.url,
                      style: { display: 'none' },
                      config: { file: { forceAudio: true } },
                      width: 0,
                      height: 0,
                      volume: 1,
                      onProgress: handleProgress,
                    }}
                  />
                </Player>
                <p className="title">{value ? value.title : ''}</p>
              </PlayerWrapper>
            </Segment>
          )}
          {select.catIndex > -1 && (
            <BorderedSegment
              radius={10}
              style={{ width: '100%', marginTop: '1rem' }}
            >
              {fxList[select.catIndex].list.map(({ key, label }, i) => (
                <ToggleTextItem
                  onClick={() => handleItemClick(i)}
                  selected={select.itemIndex === i}
                  key={key}
                >
                  {label}
                </ToggleTextItem>
              ))}
            </BorderedSegment>
          )}
        </div>
      </BorderedSegment>
    </CardBody>
  );
};

export default FxCard;
