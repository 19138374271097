import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLifecycles, useUpdateEffect } from 'react-use';
import * as Scroll from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import BotsList from '../../components/bots/BotsList';
import { FilledBg } from '../../components/Components';
import Popup from '../../components/Popup';
import {
  fetchBotsList,
  updateBotsList,
  fetchAutoCompleteData,
} from '../../ducks/bots';
import {
  getMounted,
  getRobotId,
  getUserId,
  setMounted,
} from '../../utils/common';
import { DEFAULT_APPS } from '../../utils/consts';
import withAuth from '../../hocs/withAuth';

const BotsPage = ({
  loading,
  errorMsg,
  results,
  bots,
  redirect,
  changed,
  onMount,
  updateBots,
  onChangeResult,
}) => {
  const { isSetting } = useSelector(state => state.page);
  const { pathname } = useLocation();
  const [isPopup, setPopup] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('bot');
  const scroll = Scroll.animateScroll;

  const handleUseClick = (id, use) => {
    updateBots(getRobotId(), id, use);
  };

  const handleBotClick = ({ projectId, projectName, botId, page, url }) => {
    if (DEFAULT_APPS.includes(projectId)) {
      return navigate(`/bots/${projectId}`);
    }
    if (page) {
      if (url) {
        return navigate(`/bots/externalPage?botId=${botId}`);
      }
      if (projectName.indexOf('CUSTOM_') === 0) {
        return navigate(`/bots/customPage?botId=${botId}`);
      }
    }
    return navigate(`/bots/defaultPage?botId=${botId}`);
  };

  const handlePopUpClick = () => {
    navigate(`/${redirect}`);
    setPopup(false);
  };

  const addSearchChange = (value, use) => {
    onChangeResult({ robotId: getRobotId(), str: value, use });
  };

  useLifecycles(
    () => {
      const robotId = getRobotId();
      if (!robotId) {
        setPopup(true);
      } else {
        if (!getMounted(pathname)) {
          onMount(robotId, true);
          setMounted(pathname);
        } else if (changed) {
          onMount(robotId, true);
        } else if (bots.length === 0) {
          onMount(robotId, true);
        }

        scroll.scrollToTop({ duration: 0, smooth: true });
      }
    },
    () => {
      setPopup(false);
    },
  );

  useUpdateEffect(() => {
    if (redirect) {
      setPopup(true);
    }
  }, [redirect]);

  useUpdateEffect(() => {
    onMount(getRobotId(), !isSetting);
  }, [isSetting]);

  return (
    <FilledBg>
      {redirect && (
        <Popup
          contents={getUserId() ? t('MSG.STATE') : '로그인 후 이용하세요.'}
          rightLabel={t('common:POP.OK')}
          rightFunc={handlePopUpClick}
          leftFunc={handlePopUpClick}
          mode="confirm"
          isPopup={isPopup}
        />
      )}
      {!redirect && (
        <BotsList
          loading={loading}
          isSetting={isSetting}
          bots={bots}
          results={results}
          error={errorMsg}
          handleUseClick={handleUseClick}
          handleBotClick={handleBotClick}
          addSearchChange={addSearchChange}
        />
      )}
    </FilledBg>
  );
};

export default connect(
  state => ({
    bots: state.bots.bots,
    loading: state.bots.loading,
    errorMsg: state.bots.errorMsg,
    searchStr: state.bots.searchStr,
    searching: state.bots.searching,
    results: state.bots.searchResult,
    redirect: state.bots.redirect,
    changed: state.store.changed,
  }),
  dispatch => ({
    onMount: (robotId, use) => dispatch(fetchBotsList(robotId, use)),
    updateBots: (robotId, botId, use) =>
      dispatch(updateBotsList(robotId, botId, use)),
    onChangeResult: data => dispatch(fetchAutoCompleteData(data)),
  }),
)(withAuth(BotsPage));

// withAuth로 로그인 여부 체크하고 페이지로 진입
// 파이보에 설치된 내 앱 리스트(사용 상태 포함) 가져오기
