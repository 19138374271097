import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Icon, Label } from 'semantic-ui-react';
import { useLifecycles, useUpdateEffect } from 'react-use';
import {
  StoreCard,
  StoreCardHeader,
  StoreCardMeta,
  StyledStatistics,
  StoreCardDescription,
  StoreCardButton,
  StoreRating,
} from './StoreComponents';
import { BotImage } from '../Components';
import { getLocale, getRobotId, parseBotDescription } from '../../utils/common';

let isMounted = false;

const StoreItemES = props => {
  const { t } = useTranslation('store');
  const {
    id,
    use,
    userId,
    url,
    projectId,
    projectName,
    title,
    score,
    type,
    reviews,
    description,
    fluid,
    handleDownClick,
    handleItemClick,
    handleOpenClick,
    isInstalled,
    // installing,
  } = props;
  const viewMode = getRobotId().length === 0;

  const locale = getLocale();
  const [installing, setReady] = useState(false);

  useLifecycles(
    async () => {
      if (!isMounted) {
        isMounted = true;
      }
    },
    () => {
      isMounted = false;
    },
  );

  useUpdateEffect(() => {
    setReady(false);
  }, [isInstalled]);

  const localedTitle = data => {
    if (typeof data === 'string') {
      return data;
    }
    if (typeof data === 'object' && locale in data) {
      return data[locale];
    }
    return t('NO_SUBJECT');
  };
  const localedDescription = data => {
    if (typeof data === 'string') {
      return parseBotDescription(data, true);
    }
    if (typeof data === 'object' && locale in data) {
      return data[locale];
    }
    return t('DETAIL.NO_DETAILS');
  };

  const handleInstall = () => {
    setReady(true);
    let obj = { botId: id, title: localedTitle(title) };
    if (userId === 'pibo' && projectId.indexOf('OFFICIAL_') === 0) {
      obj = { ...obj, projectId, userId };
    } else {
      obj = { ...obj, projectId: projectName, userId };
    }
    handleDownClick(obj);
  };

  return (
    <StoreCard id={id} fluid={fluid.toString()} className="ui card">
      <Card.Content>
        <BotImage {...{ id, style: 'item' }} />
        <StoreCardHeader onClick={() => handleItemClick(id)}>
          {localedTitle(title)}
          {type === 2 && (
            <Label
              color="yellow"
              size="mini"
              horizontal
              style={{ marginLeft: '.5rem' }}
            >
              <Icon name="flask" />
              Lab
            </Label>
          )}
        </StoreCardHeader>
        <StoreCardMeta>
          <StoreRating
            value={score || 0}
            defaultRating={Math.ceil(score) || 0}
            maxRating={5}
            disabled
          />
          <StyledStatistics>{t('REVIEW.TOTAL', { reviews })}</StyledStatistics>
        </StoreCardMeta>
        <StoreCardDescription>
          {localedDescription(description)}
          <div style={{ width: '100%', height: '10px' }} />
        </StoreCardDescription>
        {((isInstalled && !use) || viewMode) && (
          <StoreCardButton
            floated="right"
            color="blue"
            basic={false}
            onClick={() => handleItemClick(id)}
          >
            {t('VIEW')}
          </StoreCardButton>
        )}
        {!isInstalled && !viewMode && (
          <StoreCardButton
            basic
            floated="right"
            color="blue"
            disabled={installing}
            onClick={handleInstall}
          >
            {installing && <Icon loading name="spinner" />}
            {!installing && t('INSTALL')}
          </StoreCardButton>
        )}
        {isInstalled && use && (
          <StoreCardButton
            floated="right"
            color="blue"
            onClick={() => handleOpenClick(url)}
          >
            {t('OPEN')}
          </StoreCardButton>
        )}
      </Card.Content>
    </StoreCard>
  );
};

export default StoreItemES;
