import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Menu = styled.div`
  position: fixed;
  bottom: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 99;
  background: rgba(255, 255, 255, 0.88);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-height: 850px) {
    height: 45px;
  }
  @media (min-height: 851px) {
    height: 54px;
  }
`;

const MenuItem = styled.div`
  @media (max-height: 850px) {
    background-size: 18px;
  }
  @media (min-height: 851px) {
    background-size: 28px;
  }
  ${props => `background-image: url('../image/menu_${props.name}.png');`}
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: inherit;
  transition: transform 0.3s cubic-bezier(0.83, 0, 0.17, 1);
  ${props =>
    props.selected
      ? `opacity: 1; scale(1);`
      : `opacity: 0.7; filter: grayscale(1) blur(0.4px); transform: scale(.85);`}
`;

const BottomMenu = ({ selectMenu }) => {
  const navigate = useNavigate();
  const onMenuClick = name => () => {
    if (selectMenu === name) return;
    navigate(`/${name}`);
  };

  return (
    <Menu>
      {['botstore', 'avatar', 'pibo', 'history', 'bots'].map(m => (
        <MenuItem
          {...{
            key: m,
            name: m,
            selected: selectMenu === m,
            onClick: onMenuClick(m),
          }}
        />
      ))}
    </Menu>
  );
};

BottomMenu.propTypes = {
  selectMenu: PropTypes.oneOf(['botstore', 'avatar', 'pibo', 'history', 'bots'])
    .isRequired,
};

export default BottomMenu;
