import i18 from '../../../lang/i18n';

const DELETE_CONFIRM = 'DELETE_CONFIRM';
const DELETE_SUCCESS = 'DELETE_SUCCESS';
const DELETE_FAIL = 'DELETE_FAIL';
const ADD_SUCCESS = 'ADD_SUCCESS';
const ADD_FAIL = 'ADD_FAIL';
const UPDATE_SUCCESS = 'ADD_SUCCESS';
const UPDATE_FAIL = 'UPDATE_FAIL';
const DATA_LOAD_FAIL = 'DATA_LOAD_FAIL';

const getPopup = type => {
  switch (type) {
    case DELETE_CONFIRM:
      return {
        type: DELETE_CONFIRM,
        contents: i18.t('bot:TRN.MSG.DELETE'),
        isPopup: true,
        mode: 'confirm',
        leftLabel: i18.t('common:POP.CANCEL'),
        rightLabel: i18.t('common:POP.OK'),
      };
    case DELETE_SUCCESS:
      return {
        type: DELETE_SUCCESS,
        contents: i18.t('bot:TRN.POP.DELETE_SUCCESS'),
        isPopup: true,
        mode: 'confirm',
        rightLabel: i18.t('common:POP.OK'),
      };
    case DELETE_FAIL:
      return {
        type: DELETE_FAIL,
        contents: i18.t('bot:TRN.POP.DELETE_FAIL'),
        isPopup: true,
        mode: 'alert',
        rightLabel: i18.t('common:POP.OK'),
      };
    case ADD_SUCCESS:
      return {
        type: ADD_SUCCESS,
        contents: i18.t('bot:TRN.POP.ADD_SUCCESS'),
        isPopup: true,
        mode: 'confirm',
        rightLabel: i18.t('common:POP.OK'),
      };
    case ADD_FAIL:
      return {
        type: ADD_FAIL,
        contents: i18.t('bot:TRN.POP.ADD_FAIL'),
        isPopup: true,
        mode: 'alert',
        rightLabel: i18.t('common:POP.OK'),
      };
    case UPDATE_SUCCESS:
      return {
        type: UPDATE_SUCCESS,
        contents: i18.t('bot:TRN.POP.UPDATE_SUCCESS'),
        isPopup: true,
        mode: 'confirm',
        rightLabel: i18.t('common:POP.OK'),
      };
    case UPDATE_FAIL:
      return {
        type: UPDATE_FAIL,
        contents: i18.t('bot:TRN.POP.UPDATE_FAIL'),
        isPopup: true,
        mode: 'alert',
        rightLabel: i18.t('common:POP.OK'),
      };
    case DATA_LOAD_FAIL:
      return {
        type: DATA_LOAD_FAIL,
        contents: i18.t('bot:TRN.POP.DATA_LOAD_FAIL'),
        isPopup: true,
        mode: 'alert',
        rightLabel: i18.t('common:POP.OK'),
      };
    default:
      return {
        isPopup: false,
      };
  }
};

export {
  getPopup,
  DELETE_SUCCESS,
  DELETE_CONFIRM,
  DELETE_FAIL,
  ADD_SUCCESS,
  ADD_FAIL,
  UPDATE_SUCCESS,
  UPDATE_FAIL,
  DATA_LOAD_FAIL,
};
