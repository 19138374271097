/* eslint-disable no-alert */
import React, { useState, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FixedBottomGroup } from '../../../components/bots/MessageBot/Components';
import MessageSelectDetail from '../../../components/bots/MessageBot/MessageSelectDetail';
import {
  MSG_TYPE_TEXT,
  MSG_MODE_REAL_TIME,
  MSG_MODE_SENSING_TIME,
  MSG_TYPE_VOICE,
} from '../../../utils/consts';
import TextMessageDetail from '../../../components/bots/MessageBot/TextMessgeDetail';
import VoiceMessageDetail from '../../../components/bots/MessageBot/VoiceMessageDetail';
import {
  sendMessage,
  setMessage,
  setSendMode,
} from '../../../ducks/bots/messageBot/message';
import { Alert, OS_AND, detectOS } from '../../../utils/common';

const initialRecordState = {
  timeElapsed: 0,
  recording: false,
  message: '',
  audioURL: null,
  response: false,
  uuid: '',
};

const timer = null;

const SendMsgContainer = ({ inputRef }) => {
  const { t } = useTranslation('bot');
  const dispatch = useDispatch();
  const {
    type,
    message,
    sendMode,
    sendMessagePending: pending,
    sendMessageError,
    sentMessage: sent,
  } = useSelector(state => state.messageBot);
  const taRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [record, setRecord] = useState({ ...initialRecordState });
  const [state, setState] = useState({
    mode: MSG_MODE_SENSING_TIME,
    type: MSG_TYPE_TEXT,
    textRows: 1,
    msgtxt: message,
  });
  const [blob, setBlob] = useState(null);
  const [mediaRecorder, setMedia] = useState(null);
  let chunks = [];

  useUpdateEffect(() => {
    setState({ ...state, mode: sendMode });
  }, [sendMode]);

  useUpdateEffect(() => {
    if (message !== undefined) {
      if (!pending && state.textRows === 2 && message.length === 0) {
        return setState({ ...state, textRows: 1, msgtxt: message });
      }
      if (!pending && state.textRows === 1 && message.length > 0) {
        return setState({ ...state, textRows: 2, msgtxt: message });
      }
      if (pending && state.textRows === 2 && message.length === 0) {
        return setState({ ...state, textRows: 1 });
      }
      return setState({ ...state, msgtxt: message });
    }
    return state;
  }, [message, pending]);

  useUpdateEffect(() => {
    if (state.type === MSG_TYPE_VOICE && sent) {
      chunks = [];
      setMedia(null);
      setRecord({ ...initialRecordState });
    }
  }, [sent]);

  const changeText = e => {
    const {
      target: { value },
    } = e;
    setState({ ...state, msgtxt: value });
    dispatch(setMessage(value));
  };

  const handleTextFocusOn = () => {
    setState({ ...state, textRows: 2 });
  };

  const handleTextFocusOut = () => {
    if (state.msgtxt.length > 0) return;
    setState({ ...state, textRows: 1 });
  };

  const sendText = event => {
    if (pending || !state.msgtxt) return;
    if (event.key === 'Enter' || event.type === 'click') {
      dispatch(sendMessage(state));
      setState({ ...state, textRows: 1 });
    }
  };

  const toggleType = (e, { toggle }) => {
    if (toggle && detectOS() === OS_AND)
      return Alert('음성 녹음 메시지는 추후 지원 예정입니다.');
    return setState({
      ...state,
      type: toggle ? MSG_TYPE_VOICE : MSG_TYPE_TEXT,
      msgtxt: '',
      record: { ...initialRecordState },
    });
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const mr = new MediaRecorder(stream);
          setMedia(mr);
          mr.start();
          mr.ondataavailable = e => {
            chunks.push(e.data);
          };
          mr.onstop = () => {
            clearInterval(timer);
            const bb = new Blob(chunks, { type: 'audio/mpeg' });
            setBlob(bb);
            const audioURL = window.URL.createObjectURL(bb);
            setRecord({ ...record, audioURL });
            console.log('recorder stopped', audioURL);
          };
          setRecord({ ...record, recording: true });
          console.log('recorder started');
        })
        .catch(err => {
          console.log(err);
          // Error callback
          alert(`The following getUserMedia error occurred: ${err}`);
        });
      chunks = [];
    } else {
      alert('getUserMedia not supported on your browser!');
    }
  };

  const stopRecording = () => {
    mediaRecorder.stop();
  };

  const sendRecording = () => {
    const { mode, type: tp } = state;
    const fileName = `${new Date().valueOf()}.mp3`;
    dispatch(
      sendMessage({
        mode,
        type: tp,
        fileName,
        blob,
      }),
    );
  };

  const voiceMsgDetailParams = {
    type,
    record: {
      ...record,
      message: null, // saveError || record.message,
    },
    startRecording,
    stopRecording,
    deleteRecording: () => {
      chunks = [];
      setMedia(null);
      setRecord({ ...initialRecordState });
    },
    sendRecording,
    sending: pending,
    label: sendMessageError ? t('MSG.RESEND.LB') : t('MSG.SEND.LB'),
  };

  const msgSelectParams = {
    compact:
      state.textRows > 1 || record.recording || record.message || record.media,
    typeToggle: state.type === MSG_TYPE_TEXT,
    onToggleType: toggleType,
    onToggleMode: () => dispatch(setSendMode()),
    disabled: pending,
  };

  const typeTextParams = {
    placeholder: t('MSG.ENTER.MSG'),
    value: state.msgtxt,
    rows: state.textRows,
    onChange: changeText,
    sendText,
    onFocus: handleTextFocusOn,
    onBlur: handleTextFocusOut,
    sending: pending,
    label: sendMessageError ? t('MSG.RESEND.LB') : t('MSG.SEND.LB'),
    taRef,
    error: sendMessageError,
  };

  return (
    <FixedBottomGroup ref={inputRef}>
      <MessageSelectDetail
        {...msgSelectParams}
        modeToggle={state.mode === MSG_MODE_REAL_TIME}
        expandInput={typeTextParams.rows > 1}
      >
        {state.type === MSG_TYPE_VOICE && (
          <VoiceMessageDetail {...voiceMsgDetailParams} />
        )}
        {state.type === MSG_TYPE_TEXT && (
          <TextMessageDetail {...typeTextParams} />
        )}
      </MessageSelectDetail>
    </FixedBottomGroup>
  );
};

export default SendMsgContainer;
