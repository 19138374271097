/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18 from '../lang/i18n';
import { setPiboGeo } from '../pibo';
import {
  checkPiboAuth,
  getUserInterest,
  updatePiboData,
  updateUserImageData,
  updateUserItemData,
} from '../utils/api';
import {
  Alert,
  convertImageToBlob,
  getLocale,
  getRobotId,
  getUserId,
  setCurrentUser,
  setLocalStorage,
} from '../utils/common';
import { validateImage, validateInterest } from '../utils/validate';

const name = 'link';
const steps = [
  { id: 'connect', label: i18.t('conn:LABEL.LINK_STEP.1') },
  { id: 'userLocation', label: i18.t('conn:LABEL.LINK_STEP.2') },
  { id: 'userImage', label: i18.t('conn:LABEL.LINK_STEP.3') },
  { id: 'userInterest', label: i18.t('conn:LABEL.LINK_STEP.4') },
];
const NEXT = { color: 'blue', key: 'next_btn', label: i18.t('user:NEXT') };
const PREV = { style: { color: '#fff !important' }, key: 'prev_btn' };
const activeIndexOrigin = 0;

const getBtnState = (activeIndex, payload) => {
  if (activeIndex === 0) {
    const { code, success } = payload;
    return [
      {
        ...NEXT,
        disabled: code.length < 5 || success,
        label: '연결',
      },
    ];
  }
  if (activeIndex === 1) {
    const { address, lat, lng, x, y } = payload;
    const disabled = !address || !(lat || lng || x || y);
    return [
      {
        ...NEXT,
        disabled,
      },
      { ...PREV, label: i18.t('user:PREV'), disabled: true },
    ];
  }

  if (activeIndex === 2) {
    const { imageFile, image, msg } = payload;
    const disabled = !(image && imageFile) || (msg && msg.length);
    return [
      {
        ...NEXT,
        disabled,
      },
      { ...PREV, label: i18.t('user:PREV'), disabled: false },
    ];
  }

  if (activeIndex === 3) {
    const { selected } = payload;
    const confirm = selected.length > 0 && selected.length < 6;
    return [
      {
        ...NEXT,
        disabled: !confirm,
      },
      { ...PREV, label: i18.t('user:PREV'), disabled: false },
    ];
  }

  if (activeIndex === 4) {
    return [{ ...NEXT, label: i18.t('conn:BTN.MEET_PIBO') }];
  }
  return [];
};

const originData = {
  '0': { code: Array(5).fill(false), success: false },
  '1': { lat: null, lng: null, x: null, y: null, address: [] },
  '2': { imageFile: null, image: null },
  '3': { selected: [], interestList: [], originList: [] },
};

const initialState = {
  steps,
  loading: false,
  activeIndex: activeIndexOrigin,
  data: originData,
  btn: getBtnState(activeIndexOrigin, originData[activeIndexOrigin]),
  error: '',
  complete: { message: null, to: null },
};

export const checkCode = createAsyncThunk(
  `${name}/CONFIRM_CODE`,
  async ({ code }, { getState, rejectWithValue }) => {
    try {
      if (code.length !== 5) {
        return rejectWithValue(i18.t('conn:ERR.INPUT_ALL.CERTCODE'));
      }
      const { result, ...rest } = await checkPiboAuth({
        code,
        userId: getUserId(),
      });
      /* 테스트
      const testObj = {
        result: true,
        robotId: "5fe1489e3234a60018ca1444",
        robotPId: "572ad497",
        version: "",
      };
      const { result, ...rest } = testObj
       */
      if (result) {
        const { robotId, robotPId, version } = rest;
        setLocalStorage('link', robotId.replace(/"/g, ''));
        await setCurrentUser({ robotId, robotPId });
        setLocalStorage('version', version);
      } else {
        const {
          error: { code: errorCode },
        } = rest;
        if (errorCode === '5v107b') {
          Alert(i18.t('ERR.5V107B'));
          return rejectWithValue(i18.t('ERR.5V107B'));
        }
        Alert(i18.t('ERR.5V107B'));
        return rejectWithValue(i18.t('conn:MSG.CHECK_CODE'));
      }
      const { link } = getState();
      const { data, activeIndex: currentIndex } = link;
      const activeIndex = currentIndex + 1;
      return {
        activeIndex,
        btn: getBtnState(activeIndex, data[activeIndex]),
        data: { ...data, '0': { ...data[currentIndex], success: true } },
      };
    } catch (error) {
      return rejectWithValue(i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'));
    }
  },
);

export const fetchInterestsList = createAsyncThunk(
  `${name}/FETCH_INTEREST_LIST`,
  async (v, { getState, rejectWithValue }) => {
    try {
      const { result, data: iList } = await getUserInterest(getLocale());
      const {
        link: { data },
      } = getState();
      return {
        ...data,
        '3': {
          interestList: result ? iList : [],
          selected: [],
          originList: result ? iList : [],
        },
      };
    } catch (error) {
      return rejectWithValue(i18.t('conn:ERR.LOAD_FAILED.INTEREST'));
    }
  },
);

export const setLinkData = createAsyncThunk(
  `${name}/SET_LINK_DATA`,
  async (returnData, { getState, rejectWithValue }) => {
    const {
      link: { data, activeIndex },
    } = getState();
    const dataItem = data[activeIndex];
    const userId = getUserId();
    const robotId = getRobotId();
    try {
      if (activeIndex === 1) {
        const res = await updatePiboData({ robotId, data: { geo: dataItem } });
        if (res.result) {
          setPiboGeo(data.geo, null);
        } else {
          return rejectWithValue(i18.t('conn:ERR.SAVE_FAILED.GEO'));
        }
      }
      if (activeIndex === 2) {
        const { data: result } = await updateUserImageData({
          userId,
          imageFile: dataItem.imageFile,
        });
        if (!result) {
          return rejectWithValue(i18.t('conn:ERR.SAVE_FAILED.IMG'));
        }
      }
      if (activeIndex === 3) {
        const res = await updateUserItemData(userId, {
          item: 'interest',
          value: dataItem.selected,
        });
        if (!res.result) {
          return rejectWithValue(i18.t('conn:ERR.SAVE_FAILED.INTEREST'));
        }
      }
      return returnData;
    } catch (error) {
      // Alert(JSON.stringify(error));
      return rejectWithValue(i18.t('conn:ERR.SAVE_FAILED.CONF'));
    }
  },
);

const LinkSlice = createSlice({
  name,
  initialState,
  reducers: {
    init: state => ({ ...initialState, terms: state.terms }),
    next: (state, action) => ({ ...state, ...action.payload }),
    prev: (state, action) => ({ ...state, ...action.payload }),
    setData: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: {
    [checkCode.pending.type]: state => ({ ...state, loading: true }),
    [checkCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      error: false,
      ...action.payload,
    }),
    [checkCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
      data: {
        ...state.data,
        '0': { code: Array(5).fill(false), success: false },
      },
    }),
    [fetchInterestsList.pending.type]: state => ({ ...state, loading: true }),
    [fetchInterestsList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      error: false,
      data: { ...action.payload },
    }),
    [fetchInterestsList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [setLinkData.pending.type]: state => ({ ...state, loading: true }),
    [setLinkData.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      error: false,
      ...action.payload,
    }),
    [setLinkData.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

const { reducer: LinkReducer, actions } = LinkSlice;
export const { init } = actions;
const { next, prev, setData } = actions;

export const onPrev = () => (dispatch, getState) => {
  const { link: state } = getState();
  const { activeIndex: currentIndex } = state;
  const activeIndex = state.activeIndex === 0 ? 0 : state.activeIndex - 1;
  const data = {
    ...state.data,
    [currentIndex]: { ...originData[currentIndex] },
  };

  if (currentIndex === 0) {
    return null;
  }

  return dispatch(
    prev({
      activeIndex,
      btn: getBtnState(activeIndex, state.data[activeIndex]),
      data,
    }),
  );
};

export const onNext = () => async (dispatch, getState) => {
  const { link: state } = getState();
  const { activeIndex: currentIndex } = state;
  const activeIndex =
    currentIndex < steps.length ? currentIndex + 1 : currentIndex;
  let returnData = {
    activeIndex,
    btn: getBtnState(activeIndex, state.data[activeIndex]),
  };

  if (currentIndex === steps.length && activeIndex === steps.length) {
    // '마지막 페이지 다음 클릭 시'
    localStorage.removeItem('link');
    localStorage.removeItem('activeIndex');
    setLocalStorage('firstLoad', true);
    returnData = {
      activeIndex,
      btn: getBtnState(activeIndex, state.data[activeIndex]),
      complete: { to: '/pibo' },
    };
  }

  if (currentIndex === 0) {
    const { code, success } = state.data[currentIndex];
    if (!success) {
      dispatch(checkCode({ code: code.join('') }));
    } else {
      dispatch(next(returnData));
    }
  } else if (currentIndex > 0 && currentIndex < steps.length) {
    // 1(geo),2(image),3(interest)
    dispatch(setLinkData(returnData));
  } else {
    dispatch(next(returnData));
  }
};

const checkInterest = (originList, selectList) => {
  if (!originList) return [];
  return originList.reduce((acc, cur) => {
    const selectItem = selectList.find(id => id === cur._id);
    const selected = !!(selectItem && selectItem.length > 0);
    const newItem = { ...cur, selected };
    acc.push(newItem);
    return acc;
  }, []);
};

export const onChange = value => async (dispatch, getState) => {
  const { link: state } = getState();
  const { activeIndex } = state;
  let { btn } = state;
  let msg;
  const data = { ...state.data };
  if (activeIndex === 2) {
    if (value) {
      const { url: cvsImg, blob } = await convertImageToBlob(value[0]);
      msg = await validateImage(blob);
      data[activeIndex] = { image: cvsImg, imageFile: value };
    }
    btn = getBtnState(activeIndex, { ...data[activeIndex], msg });
  } else {
    if (activeIndex === 3 && value) {
      const { originList } = data[activeIndex];
      // 선택이 반영된 리스트 = (전체원본리스트, 선택한 리스트)
      const interestList = checkInterest(originList, value);
      msg = validateInterest(value);
      data[activeIndex] = { originList, selected: value, interestList };
    } else {
      data[activeIndex] = { ...value };
    }
    btn = getBtnState(activeIndex, data[activeIndex]);
  }

  if (msg) {
    return dispatch(setData({ btn, data, error: msg }));
  }
  return dispatch(setData({ btn, data, error: msg }));
};

export default LinkReducer;
