import React from 'react';
import { ListItem, ListContent } from 'semantic-ui-react';
import CalendarItemLabel from './CalendarItemLabel';
import { getCalendarType } from '../../../utils/common';

const CalendarItem = ({ id, onClick, execTime, title, bot, type }) => (
  <ListItem name={id} onClick={onClick}>
    <ListContent
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <CalendarItemLabel
        title={title}
        type={getCalendarType(type)}
        time={execTime.format('HH:mm')}
        bot={bot}
      />
      {/* <Checkbox
                style={{ alignSelf: 'center' }}
                checked={checked}
                onClick={() => this.checkItemHandler(index)}
            </Checkbox> */}
    </ListContent>
  </ListItem>
);

export default CalendarItem;
