import shortid from 'shortid';
import i18n from '../../../lang/i18n';
import StaticData from './data.json';

const getKey = () => shortid.generate();

const MOTION_ITEM = {
  color: '237,85,100',
  icon: 'sign language',
  label: i18n.t('bot:TRN.LB.ACTION'),
  name: 'motion',
  key: getKey(),
};
const SPEAK_ITEM = {
  color: '181,204,24',
  icon: 'talk',
  label: i18n.t('bot:TRN.LB.SPEAKING'),
  name: 'speak',
  key: getKey(),
};
const BOT_ITEM = {
  color: '3,191,215',
  icon: 'bots',
  label: i18n.t('bot:TRN.LB.BOT'),
  name: 'bot',
  key: getKey(),
};
const BG_ITEM = {
  color: '162,145,251',
  icon: 'headphones',
  label: i18n.t('bot:TRN.LB.BG'),
  name: 'bg',
  key: getKey(),
};

const MENUS = [MOTION_ITEM, SPEAK_ITEM, BOT_ITEM, BG_ITEM];

const EXECUTES_MAX_LENGTH = 5;

const testCommandRegExr = v => {
  const regex = new RegExp('^([A-Za-zㄱ-ㅎㅏ-ㅣ가-힣.,!?~\\s\\d]){2,40}$', 'g');
  return !regex.test(v);
};

const testSpeakRegExr = v => {
  const regex = new RegExp(
    '^([A-Za-zㄱ-ㅎㅏ-ㅣ가-힣.,!?~\\s\\d]){1,140}$',
    'g',
  );
  return !regex.test(v);
};

const [EYE_COLORS, MOTIONS] = Object.values(StaticData);

export {
  getKey,
  testSpeakRegExr,
  testCommandRegExr,
  MOTION_ITEM,
  SPEAK_ITEM,
  BOT_ITEM,
  BG_ITEM,
  MENUS,
  EXECUTES_MAX_LENGTH,
  EYE_COLORS,
  MOTIONS,
};
