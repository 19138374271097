import React, { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import i18n from '../../../lang/i18n';
import { BorderedSegment, CardBody, ColorItem } from './Components';

const EyeColorCard = props => {
  const { colors, onChange } = props;
  const [select, setSelect] = useState(null);

  const handleClick = index => {
    if (index === select) {
      setSelect(null);
    } else {
      setSelect(index);
    }
  };

  useUpdateEffect(() => {
    onChange(colors[select]);
  }, [select]);

  return (
    <CardBody>
      <p>{i18n.t('bot:TRN.TITLE.EYE_OPTIONAL')}</p>
      <BorderedSegment radius={5}>
        <div
          style={{
            display: 'flex',
            flexFlow: 'wrap',
            margin: '-10px -10px 0',
            justifyContent: 'space-evenly',
          }}
        >
          {colors.map(({ value, key }, i) => (
            <ColorItem
              key={key}
              color={value}
              onClick={() => handleClick(i)}
              selected={i === select}
            />
          ))}
        </div>
      </BorderedSegment>
    </CardBody>
  );
};

export default EyeColorCard;
