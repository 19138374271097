import React, { useEffect, useState } from 'react';
import { Search, Segment, Icon } from 'semantic-ui-react';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';
// import { useUpdateEffect } from 'react-use';
import {
  PiboWrap,
  PiboRow,
  PiboColumn,
  PiboAccordion,
  PiboAccordionTitle,
  PiboAccordionContent,
} from '../pibo/PiboComponents';

const InstructionList = props => {
  const { t } = useTranslation('pibo');
  const {
    list: propList,
    results,
    resultSelect,
    addSearchChange,
    searching,
    loading,
    error,
  } = props;
  const [list, setList] = useState(propList);
  const [value, setValue] = useState('');
  const [activeItem, setActive] = useState(-1);
  let timeId = null;

  const resetComponent = () => {
    setList(propList);
    setValue('');
  };

  const handleKeyUp = event => {
    if (results && results.length < 1) return;
    if (event.keyCode === 13 && event.key === 'Enter') {
      event.target.blur();
    }
  };

  const handleSearchRemove = () => resetComponent();

  const handleSearchChange = (e, { value: v }) => {
    clearTimeout(timeId);
    setValue(v);
    timeId = setTimeout(() => {
      addSearchChange(v);
    }, 1000);
  };

  const handleItemClick = (e, { index }) => {
    const newIndex = activeItem === index ? -1 : index;
    setActive(newIndex);
  };

  useEffect(() => {
    if (value.length > 0 && results.length > 0) {
      return setList(results);
    }

    if (value.length > 0 && results.length === 0) {
      return setList([]);
    }

    return setList(propList);
  }, [results, propList]);

  return (
    <div>
      <PiboWrap>
        <PiboRow>
          <PiboColumn style={{ backgroundColor: 'rgba(8,148,215,1)' }}>
            <Search
              open={false}
              loading={searching}
              onResultSelect={resultSelect}
              onSearchChange={handleSearchChange}
              value={value}
              input={{
                fluid: true,
                prompt: t('SET.SEARCH'),
                input: { onKeyUp: handleKeyUp, type: 'search' },
              }}
              style={{ margin: '0.5em 1em' }}
              icon={
                value.length ? (
                  <Icon name="delete" link onClick={handleSearchRemove} />
                ) : (
                  <Icon name="search" />
                )
              }
              fluid
            />
          </PiboColumn>
        </PiboRow>
      </PiboWrap>
      <PiboWrap>
        <Segment
          basic
          loading={loading || searching}
          style={{ width: '100vw', padding: '0' }}
        >
          {error && (
            <div
              style={{
                margin: '1rem',
                textAlign: 'center',
              }}
            >
              {error}
            </div>
          )}
          {!error &&
            !loading &&
            !searching &&
            list &&
            list.length > 0 &&
            list.map(({ title, example }, index) => (
              <div key={shortid.generate()}>
                <PiboAccordion fluid>
                  <PiboAccordionTitle
                    active={activeItem === index}
                    index={index}
                    onClick={handleItemClick}
                    bold={true.toString()}
                    foldicon={true.toString()}
                  >
                    {title}
                  </PiboAccordionTitle>
                  <PiboAccordionContent
                    active={activeItem === index}
                    index={index}
                  >
                    {(!example || example.length === 0) && (
                      <div style={{ padding: '0.5rem' }}>
                        {t('SET.NO_CMDS_BOT')}
                      </div>
                    )}
                    {example &&
                      example.length > 0 &&
                      example.map(item => (
                        <div
                          style={{ padding: '0.5rem' }}
                          key={shortid.generate()}
                        >
                          <Icon
                            name="quote left"
                            size="mini"
                            style={{ verticalAlign: 'super' }}
                          />
                          {item}
                          <Icon
                            name="quote right"
                            size="mini"
                            style={{
                              verticalAlign: 'super',
                              margin: '0 0 0 0.25rem',
                            }}
                          />
                        </div>
                      ))}
                  </PiboAccordionContent>
                </PiboAccordion>
              </div>
            ))}
          {!(error || searching || list.length > 0) && value && (
            <div
              style={{
                margin: '1rem',
                textAlign: 'center',
              }}
            >
              {t('NO_SEARCH_RESULT', { value })}
            </div>
          )}
        </Segment>
      </PiboWrap>
    </div>
  );
};

export default InstructionList;
