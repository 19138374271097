import React from 'react';
import { Icon } from 'semantic-ui-react';
import { ColumnWrap, ExtendableTextArea, LabelButton } from './Components';

const TextMessageDetail = props => {
  const {
    label,
    error,
    sendText,
    value,
    rows,
    sending,
    taRef,
    ...rest
  } = props;
  return (
    <ColumnWrap fluid style={{ height: '100%' }}>
      <ExtendableTextArea {...rest} value={value} rows={rows} ref={taRef} />
      {rows > 1 && (
        <LabelButton
          onClick={sendText}
          disabled={!value || value.length < 1}
          style={{
            position: 'absolute',
            marginRight: '.5rem',
            bottom: '.5rem',
            right: 0,
            fontSize: '.825rem',
            color: error && !sending ? 'red' : '#0894d7',
          }}
        >
          {sending ? <Icon name="spinner" loading /> : label}
        </LabelButton>
      )}
    </ColumnWrap>
  );
};

export default TextMessageDetail;
