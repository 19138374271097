import React, { useState } from 'react';
import {
  GridRow,
  Dropdown,
  Label,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import styled from 'styled-components';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';
import { MessageMenu, MenuButton } from '../../Components';
import { MSG_TYPE_TEXT, MSG_MODE_REAL_TIME } from '../../../utils/consts';
import { RAPI_URL } from '../../../circulusConfig';

const ItemRow = styled(GridRow)`
  &&& {
    padding: 0;
    margin: 0.5rem 0;
    justify-content: flex-end;
  }
`;

const MsgGroup = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  padding: 0;
  width: 100%;
`;

const DateGroup = styled.div`
  color: #565b5b;
  font-size: 0.65rem;
  padding-right: 2rem;
`;

const Message = styled(Label)`
  &&& {
    font-size: 0.875rem;
    font-weight: 100;
    padding: ${({ type }) =>
      type === MSG_TYPE_TEXT ? '1rem 0.833rem' : '0.55rem'};
    color: ${({ mode }) =>
      mode === MSG_MODE_REAL_TIME ? '#565B5B' : '#fff'} !important;
    min-width: 4.8125rem;
    max-width: 80%;
  }
`;

const MessageDropdown = styled(Dropdown)`
  position: absolute;
  right: 0;
  top: 0;
  color: rgba(67, 73, 73, 0.7);
`;

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  & p {
    margin: 0 0.875em;
  }
  & p.title {
    font-weight: bold;
    color: #fff;
    margin-left: 0;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: inherit;
  }
`;

const MessagePlayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 1.5rem;
  transition: all 0.1s ease-in-out;
  font-family: 'Icons';
  font-size: 1.5rem;
  color: #fff;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  & > i.icon {
    font-size: 0.5em;
    width: auto;
    height: auto;
    margin: 0;
  }
`;

const MessageItem = ({
  firstTime,
  status,
  value,
  type,
  mode,
  onDelete,
  deleting,
}) => {
  const { t } = useTranslation('bot');
  const [playData, setPlayData] = useState({
    playing: false,
    duration: 0,
    played: 0,
    src: null,
    url: null,
    className: '',
  });

  const labelColor = v => {
    if (v === MSG_MODE_REAL_TIME) return 'yellow';
    return 'teal';
  };

  /* const labelTextColor = mode => {
    if (mode === MSG_MODE_REAL_TIME) return '#565B5B';
    return '#fff';
  }; */
  const getStatusIcon = v => {
    switch (v) {
      case 0:
        return 'envelope outline';
      case 1:
        return 'envelope open outline';
      default:
        return 'warning circle';
    }
  };
  const statusIcon = getStatusIcon(status);

  const handleProgress = ({ playedSeconds, played }) => {
    if (played === 1) {
      return setPlayData({
        ...playData,
        played: 0,
        url: null,
        playing: false,
      });
    }

    return setPlayData({
      ...playData,
      played: parseInt(Math.round(playedSeconds), 10),
    });
  };

  return (
    <ItemRow>
      <MsgGroup>
        <Dimmer active={deleting} inverted>
          <Loader size="mini" style={{ top: '33%', left: '40%' }} />
        </Dimmer>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Message
            pointing="right"
            type={type}
            mode={mode}
            color={labelColor(mode)}
          >
            {type === 'voice' && (
              <PlayerWrapper>
                <MessagePlayer
                  onClick={() =>
                    setPlayData({
                      ...playData,
                      playing: !playData.playing,
                      url: `${RAPI_URL}message/${value}`,
                    })
                  }
                >
                  <ReactPlayer
                    {...{
                      playing: playData.playing,
                      url: playData.url,
                      config: { file: { forceAudio: true } },
                      width: 0,
                      height: 0,
                      volume: 1,
                      onProgress: handleProgress,
                    }}
                  />
                  {!playData.playing && <Icon name="play" />}
                  {playData.playing && <Icon name="stop" />}
                </MessagePlayer>
              </PlayerWrapper>
            )}
            {type === 'text' && value}
          </Message>
          <MessageDropdown icon="ellipsis vertical" direction="left">
            <MessageMenu>
              <MenuButton onClick={onDelete}>
                <Icon name="cancel" color="red" />
                {t('MSG.DELETE.LB')}
              </MenuButton>
            </MessageMenu>
          </MessageDropdown>
        </div>
        <DateGroup>
          <Icon name={statusIcon} color={status < 0 ? 'red' : 'grey'} />
          {moment(firstTime).format('LT')}
        </DateGroup>
      </MsgGroup>
    </ItemRow>
  );
};

export default MessageItem;
