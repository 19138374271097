/* eslint-disable */
import React from 'react';
import * as moment from 'moment';
import sid from 'shortid';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import loadImage from 'blueimp-load-image';
import i18 from '../lang/i18n';
import { getPiboInfo, setPiboLocale } from '../pibo';
import { infoLog } from './report';
import QRCode from 'qrcode.react';
import { RAPI_URL } from '../circulusConfig';
// import { SETUP_URL } from '../circulusConfig';
// eslint-disable-next-line import/no-cycle
// import { setLog } from './api';

export const INSIDER = {
  value: false,
  get verify() {
    return this.value;
  },
  set verify(v) {
    this.value = v;
  },
};

const getBytes = (s, b, i, c) => {
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c == 10 ? 2 : c >> 7 ? 2 : 1);
  return b;
};

const getByteSlice = (s, m, b, i, c) => {
  for (
    b = i = 0;
    (c = b >= m ? s.charCodeAt(s.length) : s.charCodeAt(i++));
    b += c == 10 ? 2 : c >> 7 ? 2 : 1
  );
  return s.slice(0, i);
};

export const getByteLength = v => getBytes(v);
export const sliceByBytes = (v, m) => getByteSlice(v, m);

// LCC DFS 좌표변환 ( code : "toXY"(위경도->좌표, lat:위도, lng:경도), "toLL"(좌표->위경도,lat:x, lng:y) )

export const getXY = (lat, lng) => {
  const RE = 6371.00877; // 지구 반경(km)
  const GRID = 5.0; // 격자 간격(km)
  const SLAT1 = 30.0; // 투영 위도1(degree)
  const SLAT2 = 60.0; // 투영 위도2(degree)
  const OLON = 126.0; // 기준점 경도(degree)
  const OLAT = 38.0; // 기준점 위도(degree)
  const XO = 43; // 기준점 X좌표(GRID)
  const YO = 136; // 기1준점 Y좌표(GRID)

  const DEGRAD = Math.PI / 180.0;

  const re = RE / GRID;
  const slat1 = SLAT1 * DEGRAD;
  const slat2 = SLAT2 * DEGRAD;
  const olon = OLON * DEGRAD;
  const olat = OLAT * DEGRAD;

  let sn =
    Math.tan(Math.PI * 0.25 + slat2 * 0.5) /
    Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sn = Math.log(Math.cos(slat1) / Math.cos(slat2)) / Math.log(sn);
  let sf = Math.tan(Math.PI * 0.25 + slat1 * 0.5);
  sf = (Math.pow(sf, sn) * Math.cos(slat1)) / sn;
  let ro = Math.tan(Math.PI * 0.25 + olat * 0.5);
  ro = (re * sf) / Math.pow(ro, sn);
  const rs = {};
  rs['lat'] = Number(lat);
  rs['lng'] = Number(lng);
  var ra = Math.tan(Math.PI * 0.25 + lat * DEGRAD * 0.5);
  ra = (re * sf) / Math.pow(ra, sn);
  var theta = lng * DEGRAD - olon;
  if (theta > Math.PI) theta -= 2.0 * Math.PI;
  if (theta < -Math.PI) theta += 2.0 * Math.PI;
  theta *= sn;
  rs['x'] = Math.floor(ra * Math.sin(theta) + XO + 0.5);
  rs['y'] = Math.floor(ro - ra * Math.cos(theta) + YO + 0.5);
  return rs;
};

let lng = null;
export const getColumns = () => {
  const column = window.innerWidth / 375;
  // if (column < 1) {
  //   return 3;
  // }
  if (column >= 0 && column <= 1.2) {
    return 4;
  }
  if (column > 5) {
    return 8;
  }
  return 4 + Math.floor(column);
};

export const transLocale = locale => {
  switch (locale) {
    case 'ko':
    case 'ko-KR':
      return 'ko';
    case 'en':
    case 'en-US':
      return 'en';
    default:
      return 'en';
  }
};

export const localedMoment = (locale = 'ko') => {
  const rules = l => {
    if (l === 'ko') {
      return {
        calendar: {
          lastDay: '[어제]',
          sameDay: '[오늘]',
          nextDay: '[내일]',
          lastWeek: 'YYYY. MM. DD',
          nextWeek: 'YYYY. MM. DD',
          sameElse: 'YYYY. MM. DD',
        },
      };
    }
    return {
      calendar: {
        lastDay: '[Yesterday]',
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastWeek: 'Do MMM, YYYY',
        nextWeek: 'Do MMM, YYYY',
        sameElse: 'Do MMM, YYYY',
      },
    };
  };

  moment.updateLocale(locale, rules(locale));
};

export const removeLocalStorage = (...names) => {
  names.forEach(name => localStorage.removeItem(name));
};

export const setLocalStorage = (name, value) => {
  const preData = localStorage.getItem(name);
  if (preData !== 'undefined') {
    const obj = JSON.parse(preData);
    localStorage.setItem(name, JSON.stringify({ ...obj, ...value }));
  }
  localStorage.setItem(name, JSON.stringify(value));
};

export const setMounted = (value, remove = false) => {
  const name = 'mounted';
  const prev = localStorage.getItem(name);
  if (prev && prev !== 'undefined') {
    const list = JSON.parse(prev);
    const index = list.indexOf(value);
    if (index < 0 && !remove) {
      list.push(value);
    } else if (index > -1 && remove) {
      list.splice(index, 1);
    }
    localStorage.setItem(name, JSON.stringify(list));
  } else {
    localStorage.setItem(name, JSON.stringify([value]));
  }
};

export const getMounted = value => {
  const name = 'mounted';
  const prev = localStorage.getItem(name);
  if (prev && prev !== 'undefined') {
    const list = JSON.parse(prev);
    const result = list.indexOf(value) > -1;
    return result;
  }
  return false;
};

export const setCurrentUser = user =>
  new Promise(resolve => {
    const preData = localStorage.getItem('currentUser');
    if (preData) {
      const cuObj = JSON.parse(preData);
      return resolve(
        localStorage.setItem(
          'currentUser',
          JSON.stringify({ ...cuObj, ...user }),
        ),
      );
    }
    const { userId, robotId, nickName, robotPId, userPId } = user;
    return resolve(
      localStorage.setItem(
        'currentUser',
        JSON.stringify({
          userId: userId || '',
          robotId: robotId || '',
          nickName: nickName || '',
          robotPId: robotPId || '',
          userPId: userPId || '',
        }),
      ),
    );
  });

export const setUserToken = userToken =>
  new Promise(resolve => resolve(localStorage.setItem('userToken', userToken)));

export const isOldVersion = () => {
  const version = localStorage.getItem('version');
  // 로봇이 없으면 version이 없어서 oldversion으로 판단함
  return getRobotId() && 1628858003537 > parseInt(version, 10);

  // // 로봇 시리얼의 길이로 구형(8자 이하)과 신형(16자)을 판단
  // return getRobotPId().length > 0 && getRobotPId().length <= 8;
};

export const getLocale = () => {
  const locale = localStorage.getItem('i18nextLng');
  if (!locale) {
    const lang = transLocale(navigator.language || navigator.userLanguage);
    localStorage.setItem('i18nextLng', lang);
    return lang;
  }
  return transLocale(locale);
};

export const setLocale = (locale, callback) => {
  if (!lng || locale !== getLocale()) {
    i18.changeLanguage(locale, err => {
      if (err && callback) callback(err);
      if (!err) {
        document.documentElement.lang = locale;
        localedMoment(locale);
        setPiboLocale(locale, () => callback && callback(null, true));
        return localStorage.setItem('i18nextLng', transLocale(locale));
      }
      return null;
      // if (!callback)
      // return alert('failed to change locale');
    });
    lng = locale;
  }
};

export const getUserToken = () => localStorage.getItem('userToken');

export const getNickName = () => {
  const currentUser = localStorage.getItem('currentUser');
  const parsedData = JSON.parse(currentUser);
  if (parsedData && 'nickName' in parsedData) {
    const { nickName } = parsedData;
    return nickName;
  }
  return '';
};

export const getRobotPId = () => {
  const currentUser = localStorage.getItem('currentUser');
  const parsedData = JSON.parse(currentUser);
  if (parsedData && 'robotPId' in parsedData) {
    const { robotPId } = parsedData;
    return robotPId;
  }
  return '';
};

export const getRobotId = () => {
  const currentUser = localStorage.getItem('currentUser');
  const parsedData = JSON.parse(currentUser);
  if (parsedData && 'robotId' in parsedData) {
    const { robotId } = parsedData;
    return robotId;
  }
  return '';
};

export const getUserId = () => {
  const currentUser = localStorage.getItem('currentUser');
  const parsedData = JSON.parse(currentUser);
  if (parsedData && 'userId' in parsedData) {
    const { userId } = parsedData;
    return userId;
  }
  return '';
};

export const getLoginId = () => {
  const loginIdItem = localStorage.getItem('loginId');
  const loginId = JSON.parse(loginIdItem);
  return loginId || '';
};

export const getUserPId = () => {
  const currentUser = localStorage.getItem('currentUser');
  const parsedData = JSON.parse(currentUser);
  if (parsedData && 'userPId' in parsedData) {
    const { userPId } = parsedData;
    return userPId;
  }
  return '';
};

// eslint-disable-next-line consistent-return
export const getApInfo = async () => {
  const ap = localStorage.getItem('ap');
  if (ap) {
    return JSON.parse(ap);
  }
  // const ssid = await getConnectedSSID();
  // return { current: { essid: ssid }, connected: [] };
};

export const setApInfo = (
  ap = {
    essid: '',
    encrypted: false,
    address: '',
    password: '',
    signal: 0,
    wpa: false,
  },
) => {
  const storedAp = getApInfo();
  if (storedAp) {
    const { connected } = storedAp;
    if (connected && ap.essid) {
      connected.push(ap);
      localStorage.setItem('ap', JSON.stringify({ current: ap, connected }));
    }
  }
  if (ap.essid) {
    localStorage.setItem(
      'ap',
      JSON.stringify({ current: ap, connected: [ap] }),
    );
  }
  localStorage.setItem('ap', JSON.stringify({ current: ap, connected: [] }));
};

export const transTempByUnit = (temp = 0, unit) => {
  if (unit === 'celsius' || unit === 'celcius') {
    return `${temp} °C`;
  }
  return `${(temp * 9) / 5 + 32} °F`;
};

export const getBatIcon = value => {
  const val = parseInt(value, 10);
  if (val === 0) {
    return 'battery zero';
  }
  if (val > 0 && val < 26) {
    return 'battery one';
  }
  if (val > 25 && val < 51) {
    return 'battery two';
  }
  if (val > 50 && val < 76) {
    return 'battery three';
  }
  return 'battery four';
};

export const getTempIcon = value => {
  const val = parseInt(value, 10);
  if (val < 10) {
    return 'thermometer empty';
  }
  if (val > 10 && val < 36) {
    return 'thermometer quarter';
  }
  if (val > 35 && val < 61) {
    return 'thermometer half';
  }
  if (val > 60 && val < 86) {
    return 'thermometer three quarters';
  }
  return 'thermometer';
};

export const getPiboMode = value => {
  if (value === 1) {
    return 'avatar';
  }
  return '';
};

// array의 데이터가 object 일 경우 key를 넣어줄 경우 key를 기준으로 unique한 값을 찾는다.
export const arrayUnique = (array, key = '') =>
  array.filter((value, index, self) => {
    if (key) {
      return self.findIndex(item => item[key] === value[key]) === index;
    }
    return self.indexOf(value);
  });

export const getMobileOS = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const isSetUp = () => process.env.REACT_APP_SETUP_ENV === 'true';

// export const connectedMoveURL = () => `${SETUP_URL}redirect?token=${getUserToken()}&lng=${getLocale()}`;

// eslint-disable-next-line no-alert
export const Alert = msg => alert(msg);

export const trimAll = str => str.replace(/\s/gi, '');

const getTagIndex = (t, c) => {
  const l = [];
  let si = 0; // startIndex
  [...c].reduce((acc, cur) => {
    const r = acc + cur;
    const tag = t.find(tg => r.indexOf(tg, si) > -1);
    if (tag) {
      const idx = r.indexOf(tag, si);
      si = idx + tag.length;
      // 넘겨받은 taglist에 있는 tag가 위치한 idx를 찾아서 함께 반환
      l.push({ idx, tag });
    }
    return r;
  }, '');
  return l;
};

export const parseBotDescription = (c, first) => {
  // 여는 태그 (idx의 pair가 일치해야함)
  const sTags = ['<p>', '<q>', '<strong>', '<hr>'];
  // 닫는 태그 (idx의 pair가 일치해야함)
  const eTags = ['</p>', '</q>', '</strong>', '</hr>'];
  // 넘겨받은 content에서 여는 태그의 위치 리스트
  const sList = getTagIndex(sTags, c);
  // 넘겨받은 content에서 닫는 태그의 위치 리스트
  const eList = getTagIndex(eTags, c);
  // 넘겨받은 content에서 엘리먼트 타입 별로 리스트화
  const tagList = sList
    .reduce((acc, cur) => {
      const { tag, idx } = cur;
      const stIdx = sTags.findIndex(s => s === tag);
      const close = eTags[stIdx];
      const etIdx = eList.findIndex(
        ({ tag: eTag, idx: eIdx }) => eTag === close && eIdx > idx,
      );
      const endTag = eList[etIdx];
      const content = c.substring(idx + tag.length, endTag.idx);
      const type = tag.replace(/>|</gi, '');
      // { type: 엘리먼트 타입, start: 여는 태그 인덱스, end: 닫는 태그 인덱스, content: start-end사이의 내용 }
      acc.push({ type, start: idx + tag.length, end: endTag.idx, content });
      return acc;
    }, [])
    .sort((a, b) => a.end - b.end);

  const exceptTag = v => v.replace(/<\/?(\s|\S)*?>/gi, '');
  if (tagList.length === 0) {
    return c;
  }
  const parseList = tagList.reduce((acc, cur, idx) => {
    const { type, start, end, content } = cur;
    const key = `${sid.generate()}_${type}_${idx}`;
    let mc = exceptTag(content);
    const prev = acc[acc.length - 1];
    let el;
    if (prev) {
      const { content: cc, start: csIdx, end: ceIdx } = tagList[idx - 1];
      // nested tag의 경우 acc에 추가되어 children을 가져와서 새로운 Element 생성해서 하위에 넣어줌.
      if (start < csIdx && end > ceIdx) {
        mc = mc.replace(exceptTag(cc), '');
        if (mc === '') {
          el = React.createElement(
            type,
            { key, className: `bot_desc_${type}` },
            React.createElement(prev.type, prev.props),
          );
        } else {
          el = React.createElement(
            type,
            { key, className: `bot_desc_${type}` },
            [
              mc,
              React.createElement(prev.type, {
                ...prev.props,
                key: `${sid.generate()}_${prev.type}_${idx}`,
              }),
            ],
          );
        }
        acc[acc.length - 1] = el;
        return acc;
      }
    }
    if (type === 'hr') {
      el = <hr key={key} className={`bot_desc_${type}`} />;
    } else {
      el = React.createElement(
        type,
        { key, className: `bot_desc_${type}` },
        mc,
      );
    }
    acc.push(el);
    return acc;
  }, []);
  if (first) {
    return parseList[0];
  }
  return parseList;
};

export const asyncLoadUploadAlignedCvsImage = imgData =>
  new Promise(res => {
    loadImage.parseMetaData(imgData, () => {
      loadImage(
        imgData,
        image => {
          if (image.type !== 'error') {
            return res(image);
          }
          return res(null);
        },
        {
          canvas: true,
          crop: true,
          // aspectRatio: 1 / 1,
          pixelRatio: 1,
          downsamplingRatio: 0.5,
          orientation: true,
          maxWidth: 800,
          maxHeight: 800,
        },
      );
    });
  });

export const dataURItoBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }

  const bb = new Blob([ab], { type: mimeString });
  return bb;
};

export const convertImageToBlob = async data => {
  if (!data) return null;
  let image;
  if (typeof data === 'object' && 'name' in data) {
    const cvsImg = await asyncLoadUploadAlignedCvsImage(data);
    image = dataURLtoBlob(cvsImg.toDataURL('image/png'));
  } else {
    image = dataURLtoBlob(`data:image/png;base64,${data}`);
  }
  return { url: window.URL.createObjectURL(image), blob: image };
};

export const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
export const getErrorCode = e => (e && 'code' in e ? e.code.toUpperCase() : '');

export const getQRCvs = ({ value, size, level }) => (
  <QRCode {...{ value, size: size || 128, level: level || 'L' }} />
);

export const setTimeFormat = n => (n < 10 ? `0${n}` : n);
export const shuffle = array =>
  array ? array.sort(() => Math.random() - 0.5) : '';
export const numberWithCommas = num =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const getCalendarType = v => {
  switch (v) {
    case 'weekly':
      return i18.t('bot:CAL.WEEKLY.LB');
    case 'monthly':
      return i18.t('bot:CAL.MONTHLY.LB');
    case 'yearly':
      return i18.t('bot:CAL.YEARLY.LB');
    default:
      return i18.t('bot:CAL.ONCE');
  }
};

export const OS_MAC = 'Mac OS';
export const OS_IOS = 'iOS';
export const OS_WIN = 'Windows';
export const OS_AND = 'Android';
export const OS_LNX = 'Linux';

export const detectOS = () => {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS_MAC;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS_IOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS_WIN;
  } else if (/Android/.test(userAgent)) {
    os = OS_AND;
  } else if (!os && /Linux/.test(platform)) {
    os = OS_LNX;
  }

  return os;
};

export const pageRefresh = () => {
  if (detectOS() === OS_IOS) {
    if (
      window &&
      'webkit' in window &&
      'messageHandlers' in window.webkit &&
      'pibo' in window.webkit.messageHandlers
    ) {
      return window.webkit.messageHandlers.pibo.postMessage('refresh');
    }
  } else if (detectOS() === OS_AND) {
    if (window && 'pibo' in window) {
      return window.pibo.refresh();
    }
  }
  return window.location.reload();
};

export const BrowserView = ({ children }) =>
  !(
    (window &&
      'webkit' in window &&
      'messageHandlers' in window.webkit &&
      'pibo' in window.webkit.messageHandlers) ||
    (window && 'pibo' in window)
  ) && <>{children}</>;

export const MobileView = ({ children }) =>
  ((window &&
    'webkit' in window &&
    'messageHandlers' in window.webkit &&
    'pibo' in window.webkit.messageHandlers) ||
    (window && 'pibo' in window)) && <>{children}</>;
