/* eslint-disable eqeqeq */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import {
  getNickName,
  getRobotId,
  getRobotPId,
  getUserId,
} from '../utils/common';
import pjson from '../../package.json';
import UserPhoto from './user/UserPhoto';
import { getFile } from '../utils/api';
import { KAKAO_CHANNEL_ID, KAKAO_KEY } from '../circulusConfig';

const Menu = styled.div`
  position: fixed;
  top: 30px;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fbfbfb;
  z-index: ${({ visible }) => (visible == true ? 999 : -1)};
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 1rem 1rem;
  gap: 0.75rem;
  transition: all 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  &::-webkit-scrollbar {
    display: none;
  }

  ${({ visible }) =>
    visible
      ? `transform-origin: right;
  transform: translate(0px, 0px);
  opacity: 1;`
      : `transform-origin: left;
      filter: blur(4px);
      transform: translate(100%, 0);
      opacity: 0.5;`}
`;

const MenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
`;

const MenuHeader = styled(Header)`
  color: #d1d3d4;
  padding: 0 0.5rem;
  margin: 0;
`;

const MenuContentComponent = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.5rem;
  gap: 12px;
  width: 100%;
  & .group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  & .photo {
    width: 60px;
    height: 60px;
    margin: 0;
    & img {
      width: 60px;
      height: 60px;
    }
  }
`;

const Bold = styled.span`
  font-weight: bold;
  color: #03bfd7;
`;

const MenuContent = ({ content, caption, icon, onClick }) => (
  <MenuContentComponent onClick={onClick}>
    <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      {icon && <span className={`menu ${icon}`} />}
      {content}
    </div>
    {!caption && onClick && <Icon name="angle right" />}
    {caption && <span>{caption}</span>}
  </MenuContentComponent>
);
let kakao;
const SettingsMenu = ({ configMenu }) => {
  const navigate = useNavigate();
  const go = url => () => url && navigate(url);
  if (window.Kakao) {
    kakao = window.Kakao;

    if (!kakao.isInitialized()) {
      kakao.init(KAKAO_KEY);
    }
  }

  const SETTINGS = [
    {
      key: 'user config',
      header: '사용자 설정',
      items: [
        {
          id: 'user_config_0',
          content: (
            <div className="group">
              <UserPhoto
                src={getFile({ userPhoto: getUserId() })}
                className="photo"
              />
              <span>
                <Bold>{getNickName()}</Bold>님의 설정
              </span>
            </div>
          ),
          caption: false,
          onClick: go(`/setting/${getUserId()}`),
        },
      ],
    },
    {
      key: 'robot config',
      header: '로봇 설정',
      items: [
        {
          id: 'robot_config_0',
          icon: 'robot-config',
          content: (
            <span>
              로봇(<Bold>{getRobotPId()}</Bold>) 설정
            </span>
          ),
          caption: false,
          onClick: go(`/pibo/${getRobotId()}`),
        },
      ],
    },
  ];
  const UNSIGNED = [
    {
      key: 'config',
      header: '설정',
      items: [
        {
          id: 'config_0',
          content: (
            <span>
              <Bold>로그인</Bold>후 이용하세요.
            </span>
          ),
          caption: false,
          onClick: go('/login'),
        },
      ],
    },
  ];
  const MENUS = [
    {
      key: 'customer service',
      header: '고객센터',
      items: [
        {
          id: 'customer_service_0',
          icon: 'notice',
          content: <>공지사항</>,
          caption: false,
          onClick: go('/app/notice'),
        },
        {
          id: 'customer_service_1',
          icon: 'manual',
          content: <>파이보 매뉴얼</>,
          caption: false,
          onClick: () => {
            window.open('https://shorturl.at/cpR14', '_blank');
          },
        },
        {
          id: 'customer_service_2',
          icon: 'channeltalk',
          content: <>1:1 상담</>,
          caption: false,
          onClick: () =>
            kakao.Channel.chat({
              channelPublicId: KAKAO_CHANNEL_ID, // 카카오톡 채널 홈 URL에 명시된 ID를 입력합니다.
            }),
        },
      ],
    },
    {
      key: 'information',
      header: '정보',
      items: [
        {
          id: 'information_0',
          content: <>앱 버전</>,
          caption: (
            <Bold style={{ paddingRight: '.5rem' }}>{pjson.version}</Bold>
          ),
        },
        {
          id: 'information_1',
          content: <>펌웨어 버전</>,
          caption: (
            <Bold style={{ paddingRight: '.5rem' }}>
              {localStorage.getItem('firmware')
                ? localStorage.getItem('firmware').replace(/"/g, '')
                : ''}
            </Bold>
          ),
        },
        {
          id: 'information_2',
          content: <>책임의 한계와 정보 제공처</>,
          caption: false,
          onClick: go('/app/info/infosrc'),
        },
        {
          id: 'information_3',
          content: <>오픈소스 라이선스</>,
          caption: false,
          onClick: go('/app/info/license'),
        },
        {
          id: 'information_4',
          content: <>서비스 이용약관</>,
          caption: false,
          onClick: go('/app/info/service'),
        },
        {
          id: 'information_5',
          content: <>개인정보 처리방침</>,
          caption: false,
          onClick: go('/app/info/privacy'),
        },
      ],
    },
  ];

  const getMenus = () => {
    if (getUserId() && getRobotPId()) {
      return SETTINGS.concat(MENUS);
    }
    if (getUserId() && !getRobotPId()) {
      return [...[SETTINGS[0]], ...MENUS];
    }
    return UNSIGNED.concat(MENUS);
  };
  return (
    <Menu visible={configMenu}>
      {getMenus().map(({ header, items, key }) => (
        <MenuGroup key={key}>
          <MenuHeader as="h6">{header}</MenuHeader>
          {items.map(({ id, icon, content, caption, onClick }) => (
            <MenuContent {...{ key: id, icon, content, caption, onClick }} />
          ))}
        </MenuGroup>
      ))}
    </Menu>
  );
};

export default SettingsMenu;
