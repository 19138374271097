/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Header, Icon, Image, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import AccordionText from './AccordionText';

const BookCardItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.25em;
  margin-bottom: 0.5em;
  & div.card.content {
    display: flex;
    max-height: 133px;
    width: 100%;
    overflow: hidden;
    align-items: stretch;
    & img.ui.image {
      max-width: 40%;
      border-radius: 4px;
      flex: 0 0 auto;
      height: 133px;
      width: auto;
      margin-right: 0.25em;
    }
    & div.content {
      display: flex;
      margin-left: 0.25em;
      justify-content: space-between;
      gap: 0.25rem;
      max-height: 133px;
      flex: 1 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex-flow: column;
      & span.date {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.875rem;
        line-height: 1.1;
      }
      & span.author {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.875rem;
        line-height: 1.1;
        min-width: 155px;
      }
      & span.description {
        font-size: 0.875rem;
        line-height: 1.1;
        display: block;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: pre-wrap;
        flex: 1 1 auto;
        height: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;

const OverlayIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
`;

const TYPE_COLOR = {
  베스트셀러: 'orange',
  신간: 'blue',
  추천: 'green',
};

const BookCard = data => {
  const { topic, type, list: l, thumbs, fileId } = data;
  const list = Array.isArray(l) ? l : [l];
  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            gap: '.25rem',
            marginBottom: '.5rem',
            alignItems: 'center',
          }}
        >
          {type && type !== 'youtube' && (
            <Label
              color={TYPE_COLOR[type]}
              size="mini"
              style={{
                borderRadius: '1rem',
                textAlign: 'center',
                maxWidth: '70px',
              }}
            >
              {type}
            </Label>
          )}
          <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
            {topic}
          </Header>
        </div>
        <AccordionText
          {...{
            height: 270,
            content: (
              <div
                style={{ display: 'flex', gap: '.5rem', flexFlow: 'column' }}
              >
                {l &&
                  list.map(({ url, title, img, author, description }, i) => (
                    <BookCardItem
                      key={url}
                      role="button"
                      aria-hidden="true"
                      onClick={() => window.open(url, '_blank')}
                    >
                      <span className={url && 'link'}>{`${i +
                        1}. ${title}`}</span>
                      <div className="card content">
                        {img && <Image src={img} />}
                        <div className="content">
                          <span className="author">{author}</span>
                          {description && (
                            <span className="description">{description}</span>
                          )}
                        </div>
                      </div>
                    </BookCardItem>
                  ))}
              </div>
            ),
          }}
        />
      </div>
      {type === 'youtube' && thumbs.length && fileId && (
        <a
          href={`https://www.youtube.com/watch?v=${fileId}`}
          target="_blank"
          rel="noreferrer"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexFlow: 'column',
          }}
        >
          {data.title && (
            <div
              style={{
                fontSize: '0.875em',
                lineHeight: '1.1',
                marginBottom: '0.5em',
              }}
            >
              {data.title}
            </div>
          )}
          {thumbs[0].url && (
            <div style={{ position: 'relative' }}>
              <Image src={thumbs[0].url} size="medium" rounded />
              <OverlayIcon>
                <Icon size="huge" name="youtube" color="red" />
              </OverlayIcon>
            </div>
          )}
        </a>
      )}
    </>
  );
};

export default BookCard;
