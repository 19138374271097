import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  Label,
  TextArea,
  DropdownMenu,
} from 'semantic-ui-react';
import { getFile } from '../utils/api';

const BotImage = ({ id, alt, style: name }) => {
  const style = {
    item: {
      height: '60px',
      width: '60px',
      borderRadius: '15px',
      border: '1px solid #eff0f0',
      margin: '0 1em 0.75em 0',
      backgroundColor: '#eff0f0',
      float: 'left',
      verticalAlign: 'middle',
      display: 'inline-block',
      textAlign: 'center',
    },
    detail: {
      height: '100px',
      width: '100px',
      borderRadius: '15px',
      border: '1px solid rgba(209, 211, 212, 0.35)',
      margin: '0 1em 0 0',
      backgroundColor: '#eff0f0',
      float: 'left',
      verticalAlign: 'middle',
      maxWidth: '100%',
      position: 'relative',
      display: 'block',
      boxSizing: 'border-box',
      textAlign: 'center',
    },
  };
  return (
    <div style={style[name]}>
      <img
        src={getFile({ icon: id })}
        alt={alt || ''}
        style={
          name
            ? { width: '100%', borderRadius: '15px' }
            : { borderRadius: '15px' }
        }
      />
    </div>
  );
};

const FilledBg = styled.div`
  // width: 100vw;
  // height: 100;
  // overflow-y: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  // height: 100vh;
  overflow: hidden;
  position: absolute;
  background: linear-gradient(45deg, #fff, #03bfd7, #0894d7);
  background-size: 400% 400%;
  -webkit-animation: colors 20s ease infinite;
  -moz-animation: colors 20s ease infinite;
  animation: colors 20s ease infinite;
  display: flex;
`;

const Page = styled.div`
  position: absolute;
  @media (max-height: 850px) {
    top: 40px;
  }
  @media (min-height: 851px) {
    top: 45px;
  }
  left: 0;
  right: 0;
  bottom: ${({ isBottom }) => (isBottom ? '3rem' : 0)};
  width: 100%;
  overflow-y: auto;
`;

const StyledGrid = styled(Grid)`
  &&& {
    margin: 0 !important;
  }
`;

const StyledInput = styled.input`
  border: none;
  background: transparent;
  border-bottom: ${({ readOnly }) => (readOnly ? 'none' : '1px solid #03bfd7')};
  color: ${({ readOnly }) => (readOnly ? '#0894d7' : 'initial')};
  border-radius: 0;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
`;

const UnderLinedMulitpleInput = styled(TextArea)`
  height: auto;
  font-family: inherit;
  font-size: 0.875rem !important;
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  color: ${({ disabled }) => (disabled ? '#0894d7' : 'inherit')};
  font-weight: normal;
  resize: none;
  padding-left: 0.5em;
  &::placeholder {
    color: #d1d3d4;
  }
  &:disabled {
    color: #0894d7;
    font-weight: 600;
    border-bottom: 0px !important;
  }
`;

const StyledTextArea = styled(TextArea)`
  font-family: inherit;
  color: #565b5b;
  width: 100%;
  font-size: 0.875rem;
  padding: 0.625rem;
  text-align: left;
  border: 1px solid rgba(209, 212, 211, 0.7);
  border-radius: 5px;
  resize: none;
  &::placeholder {
    color: #d1d3d4;
  }
  &:disabled {
    color: rgba(209, 212, 211, 0.7);
  }
`;

const ToggleButton = styled(Button)`
  &&&&& {
    background: ${({ active }) =>
      active ? '#0894d7 !important' : '#d1d3d4 !important'};
  }
`;

const BlueTabMenu = styled(Menu)`
  box-shadow: none !important;
  border: 1px solid #0894d7 !important;
  border-radius: 1rem;
  margin: 0 1rem;
  min-height: initial !important;
`;

const BlueTabMenuItem = styled(MenuItem)`
  padding: 0.6rem 0.5rem !important;
  justify-content: center;
  line-height: 1 !important;
  color: ${({ active }) => (active ? '#fff !important' : '#0894d7 !important')};
  background-color: ${({ active }) =>
    active ? '#0894d7 !important' : 'trasparent !important'};
  font-weight: ${({ active }) =>
    active ? '900 !important' : '400 !important'};
  width: ${({ width }) => width || 'initial'};
  border-left: ${({ border }) =>
    border ? '1px solid #0894d7 !important' : 'initial'};
  border-right: ${({ border }) =>
    border ? '1px solid #0894d7 !important' : 'initial'};
`;

const FixedBottomButton = styled.button`
  font-family: inherit;
  position: fixed;
  ${({ standalone }) =>
    standalone
      ? `@media (max-height: 850px) {
    bottom: 45px;
  }
  @media (min-height: 851px) {
    bottom: 54px;
  }`
      : `bottom: 0;`}
  height: 3rem;
  box-shadow: 0px 1px 3px 0px rgba(209, 211, 212, 1);
  width: ${({ widths }) => (widths ? `${100 / widths}vw` : '100vw')};
  background: ${({ inverted }) =>
    inverted ? 'rgba(255, 255, 255, .65)' : 'rgba(8, 148, 215, .9)'};
  color: ${({ inverted }) => (!inverted ? '#fff' : 'rgba(8, 148, 215, 1)')};
  z-index: 99;
  text-align: center;
  margin: 0;
  padding: 0.8rem 1rem;
  border: none;
  backdrop-filter: blur(3px);
  &:disabled {
    filter: saturate(50%);
    color: #d1d3d4;
  }
`;

const StyledUserPhotoWrap = styled.div`
  display: inline-block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 35vw;
  height: 35vw;
  border-radius: 50%;
  border: 1px solid #03bfd7;
  overflow: hidden;
  max-width: 200px;
  max-height: 200px;
`;

const StyledToggleButton = styled(Button)`
  &&& {
    border-radius: 32px;
    border: 1px solid #03bfd7 !important;
    color: ${({ inverted }) =>
      inverted ? '#03BFD7 !important' : '#FFF !important'};
    background: ${({ inverted }) => (inverted ? '#FFF' : '#03BFD7')};
    font-weight: ${({ inverted }) => (inverted ? 'normal' : 'bold')};
    box-shadow: none;
    margin: 0;
    padding: 10px 14px;
    font-size: 1rem;
    &.mini {
      font-size: 0.8125rem;
    }
    &.compact {
      padding: 0.515625em 1.125em 0.515625em !important;
      margin: 0 0.25em 0.25em 0;
    }
  }
`;

const StyledLabel = styled(Label)`
  padding: 0.4em 0.8em !important;
  background: #fff;
  color: ${({ disturb }) =>
    disturb === 'true' ? '#03bfd7' : '#d1d3d4'} !important;
  border-color: ${({ disturb }) =>
    disturb === 'true' ? '#03bfd7' : '#d1d3d4'} !important;
  & > .detail {
    padding: 0.4em 0.8em !important;
    margin: -0.4em -0.8em -0.4em 0.5em !important;
    color: #fff !important;
    background: ${({ disturb }) =>
      disturb === 'true' ? '#03bfd7' : '#d1d3d4'} !important;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const FormLabel = styled.label`
  font-weight: 600 !important;
  font-size: 0.875rem;
  &::after {
    content: '*';
    display: ${({ essential }) => (essential ? 'inline-block' : 'none')};
    vertical-align: baseline;
    font-size: 1rem;
  }
`;

const UnderLinedInput = styled.input`
  font-family: inherit;
  font-size: 0.875rem !important;
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  border-bottom: 1px solid rgba(8, 148, 215, 1) !important;
  border-radius: 0 !important;
  color: ${({ disabled }) => (disabled ? '#0894d7' : 'inherit')};
  font-weight: normal;
  &::selection {
    color: #fff;
  }
  &:disabled {
    color: #0894d7;
    font-weight: 600;
    border-bottom: 0px !important;
  }
`;

const WeekdayButton = styled.button`
  font-family: inherit;
  font-size: 0.875rem;
  background: ${({ active }) => (!active ? '#FFF' : '#FFB922')};
  color: ${({ active }) => (active ? '#FFF' : '#FFB922')};
  border: 1px solid #ffb922;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Select = styled.select`
  border: 0 !important;
  background: rgba(0, 0, 0, 0) !important;
  border-bottom: 1px solid rgb(3, 191, 215) !important;
  color: ${({ disabled }) =>
    disabled ? '#0894d7' : 'rgba(0,0,0,.87)'} !important;
  border-radius: 0 !important;
  font-size: 1rem;
  padding: 0.5rem;
  font-weight: normal;
  cursor: pointer;
  &:disabled {
    color: #0894d7;
    border-bottom: 0px !important;
  }
`;

const MessageMenu = styled(DropdownMenu)`
  &&&&& {
    &.upper {
      top: -55%;
      right: 0 !important;
      & > button {
        margin-right: 0 !important;
      }
    }
    box-shadow: none !important;
    border: none !important;
    position: absolute;
    top: 0;
    right: 0.5rem !important;
    background: transparent;
  }
`;

const MenuButton = styled(Button)`
  font-size: 0.75rem !important;
  &&&& {
    display: flex;
    align-items: center;
    background: rgba(67, 73, 73, 1);
    color: #fff;
    border: 1px solid #d1d3d4;
    box-shadow: none !important;
    outline: none !important;
    color: #d1d3d4;
    opacity: 0.9;
    align-items: center;
    margin-right: 1em;
  }
`;

const Divider = styled.hr`
  width: 100%;
  border: 1px rgba(0, 0, 0, 0.05) solid;
  border-bottom: none;
`;

export {
  FilledBg,
  Page,
  StyledGrid,
  StyledTextArea,
  StyledInput,
  UnderLinedMulitpleInput,
  BlueTabMenu,
  BlueTabMenuItem,
  ToggleButton,
  StyledUserPhotoWrap,
  FixedBottomButton,
  StyledToggleButton,
  StyledLabel,
  FormGroup,
  FormLabel,
  UnderLinedInput,
  WeekdayButton,
  Image,
  Select,
  MessageMenu,
  MenuButton,
  Divider,
  BotImage,
};
