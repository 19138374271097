import React from 'react';
import * as Scroll from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom';
import BottomMenu from './BottomMenu';

const MainBottomMenu = props => {
  const { selectMenu } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handleMenuItemClick = (e, { name }) => {
    if (pathname === `/${name}`) {
      const scroll = Scroll.animateScroll;
      return scroll.scrollToTop({ duration: 0, delay: 0, smooth: true });
    }
    return navigate(`/${name}`);
  };

  return <BottomMenu {...{ selectMenu, handleMenuItemClick }} />;
};

export default MainBottomMenu;
