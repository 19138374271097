import React, { useState, Fragment } from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { useUpdateEffect, useLifecycles } from 'react-use';
import { connect } from 'react-redux';
import { withLastLocation } from 'react-router-dom-last-location';
import { useTranslation } from 'react-i18next';

import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import { getBotData } from '../../../ducks/bots/defaultPage/defaultPage';
import Loading from '../../../components/Loading';
import { connectSocket as PIBO_API } from '../../../pibo/socket';

const ExternalBot = props => {
  const { t } = useTranslation('bot');
  const {
    loading,
    lastLocation,
    message,
    getBotDataDispatch,
    botId,
    botData,
  } = props;
  const { title, projectId } = botData;
  const [backLocation, setBack] = useState(null);
  const [filesource, setSource] = useState(null);

  const httpGet = theUrl => {
    let xmlHttp = null;
    xmlHttp = new XMLHttpRequest();
    xmlHttp.open('GET', theUrl, false);
    xmlHttp.send();
    setSource(xmlHttp.responseText);
  };

  // eslint-disable-next-line no-underscore-dangle
  let _isMounted = true;
  useLifecycles(
    () => {
      if (!_isMounted) return;
      if (lastLocation && 'pathname' in lastLocation) {
        setBack(lastLocation.pathname);
      }
      if (!message) {
        getBotDataDispatch(botId);
      }
    },
    () => {
      _isMounted = false;
      botData.url = null;
    },
  );

  useUpdateEffect(() => {
    const { url } = botData;
    if (url) {
      httpGet(url);
    }
  }, [botData]);

  if (loading) return <Loading />;
  if (message && message.length > 0) return <h4>{message}</h4>;
  if (!projectId) return <h4>{t('MSG.UNVALID')}</h4>;
  return (
    <>
      <BotTopMenu
        title={title}
        menuMode="WITH_CLOSE"
        backLocation={backLocation}
      />
      {filesource && (
        <div className="externalWrap">
          <Frame className="externalIFrame">
            <FrameContextConsumer>
              {// Callback is invoked with iframe's window and document instances
              ({ document, window }) => {
                // Render Children
                // eslint-disable-next-line no-param-reassign
                window.pibo = PIBO_API;
                document.write(filesource);
              }}
            </FrameContextConsumer>
          </Frame>
        </div>
      )}
    </>
  );
};

export default connect(
  ({ defaultPage }) => ({
    loading: defaultPage.get('pending'),
    error: defaultPage.get('error'),
    botData: defaultPage.get('botData').toJS(),
  }),
  dispatch => ({
    getBotDataDispatch: botId => dispatch(getBotData(botId)),
  }),
)(withLastLocation(ExternalBot));
