import React from 'react';
import {
  Grid,
  Header,
  Checkbox,
  Image,
  Placeholder,
  GridRow,
  GridColumn,
  Icon,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { getFile } from '../../../utils/api';

const PhotoGrid = styled(Grid)`
  margin: 1rem 0 10px !important;
  width: 100% !important;
`;

const PhotoDateHeader = styled(Header)`
  margin-bottom: 0 !important;
  font-size: 0.875rem !important;
`;

const PhotoGridRow = styled(GridRow)`
  padding: 0 2.5px !important;
`;

const PhotoGridColumn = styled(GridColumn)`
  && {
    padding: 0 !important;
    overflow: hidden;
  }
  &::after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

const PhotoPlaceholder = styled(Placeholder)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PhotoCheckbox = styled(Checkbox)`
  position: absolute !important;
  bottom: 0;
  right: 0;
  z-index: 144;
`;

const Photo = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid rgba(209, 211, 212, 0.2);
`;

const PhotoList = props => {
  const { photoData, editing, photoClickHandler, loading, column } = props;
  return (
    <div style={{ paddingBottom: '50px' }}>
      {photoData &&
        photoData.map((item, i) =>
          Object.entries(item).map(([date, list]) => (
            <PhotoGrid key={date} columns={column}>
              <PhotoDateHeader>{date}</PhotoDateHeader>
              <PhotoGridRow stretched divided>
                {list.map(({ thumb, checked, id }, l) => (
                  <PhotoGridColumn key={`${date}-${thumb}`}>
                    <PhotoPlaceholder>
                      <Photo
                        {...{
                          src: getFile({ thumb }),
                          onClick: () => photoClickHandler(id, i, l),
                        }}
                      />
                      ;
                      {editing && (
                        <PhotoCheckbox
                          checked={checked}
                          onClick={() => photoClickHandler(id, i, l)}
                        />
                      )}
                      {loading && <Placeholder.Image square />}
                    </PhotoPlaceholder>
                  </PhotoGridColumn>
                ))}
              </PhotoGridRow>
            </PhotoGrid>
          )),
        )}
      {loading && (
        <Icon
          name="spinner"
          style={{ width: '100%', textAlign: 'center' }}
          color="grey"
          loading
        />
      )}
    </div>
  );
};

export default PhotoList;
