/* eslint-disable */
import pjson from '../package.json';

const getEnvironmentVariables = env => {
  switch (env) {
    case 'STG':
      return {
        // SETUP_URL: 'http://stg-setup.circul.us/',
        SITE_URL: 'https://s-m.circul.us/',
        IOT_URL: 'wss://s-proxy.circul.us:8443',
        API_URL: 'https://s-mapi.circul.us/v1/',
        NAPI_URL: 'https://s-napi.circul.us/',
        RAPI_URL: 'https://s-rapi.circul.us/v1/',
        ROBOT_URL: 'http://192.168.0.10/',
        TAPI_URL: 'https://s-tapi.circul.us/',
        DEV_MODE: false,
        REVIEW_LIMIT: 5,
      };
    case 'OPS':
      return {
        // SETUP_URL: 'http://setup.circul.us/',
        SITE_URL: 'https://m.circul.us/',
        IOT_URL: 'wss://o-proxy.circul.us:8443',
        API_URL: 'https://o-mapi.circul.us/v1/',
        NAPI_URL: 'https://o-napi.circul.us/',
        RAPI_URL: 'https://o-rapi.circul.us/v1/',
        ROBOT_URL: 'http://192.168.0.10/',
        TAPI_URL: 'https://o-tapi.circul.us/',
        DEV_MODE: false,
        REVIEW_LIMIT: 5,
      };
    default:
      // 'LOCAL'
      return {
        // SETUP_URL: 'http://stg-setup.circul.us/',
        SITE_URL: 'https://m.circul.us/',
        IOT_URL: 'wss://o-proxy.circul.us:8443',
        // API_URL: 'https://s-mapi.circul.us/v1/',
        API_URL: 'http://0.0.0.0:39701/v1/',
        NAPI_URL: 'https://o-napi.circul.us/',
        RAPI_URL: 'https://o-rapi.circul.us/v1/',
        ROBOT_URL: 'http://192.168.0.10/',
        TAPI_URL: 'https://o-tapi.circul.us/',
        DEV_MODE: false,
        REVIEW_LIMIT: 5,
      };
  }
};

export const environment = process.env.REACT_APP_ENV || 'LOC';
export const isSetup = process.env.REACT_APP_SETUP_ENV;
const date = new Date();
export const publishDate = `${date.getFullYear()}${date.getMonth() +
  1}${date.getDate()}${date.getHours()}`;
export const MAP_CLIENT_ID = '2hqmcff0c3';
export const KAKAO_KEY = '790f2f3a584b80a667bcb69c8352dbe8';
export const KAKAO_CHANNEL_ID = '_nzpJb';
console.log(`${environment} / ${isSetup} / ${pjson.version}`, 'config');

export const {
  SITE_URL,
  IOT_URL,
  API_URL,
  NAPI_URL,
  RAPI_URL,
  TAPI_URL,
  SETUP_URL,
  ROBOT_URL,
  REVIEW_LIMIT,
  DEV_MODE,
  TMP_SITE_URL,
} = getEnvironmentVariables(environment);
