import React from 'react';
import { Header, Icon, List } from 'semantic-ui-react';

const TrendCard = ({ total, list }) => (
  <div>
    <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
      {total && list.length
        ? `오늘의 트렌드 ${total}건 중에 ${list.length}건에 대해 알려줄게.`
        : `현재 트렌드 정보를 알 수 없어.`}
    </Header>
    {!!(total && list.length) && (
      <List>
        {list.map(({ keyword, view, content }) => (
          <List.Item key={keyword} style={{ margin: '.25em' }}>
            <List.Icon name="caret right" style={{ padding: 0 }} />
            <List.Content>
              <List.Header
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {keyword}
                <span
                  style={{
                    fontSize: '0.875em',
                    color: 'rgba(0,0,0,.5)',
                    fontWeight: 'normal',
                  }}
                >
                  <Icon name="eye" />
                  {view}
                </span>
              </List.Header>
              <List.Description style={{ fontSize: '0.875em' }}>
                {content}
              </List.Description>
            </List.Content>
          </List.Item>
        ))}
      </List>
    )}
  </div>
);

export default TrendCard;
