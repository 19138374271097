import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Segment,
  Header,
  Checkbox,
  Icon,
  Button,
} from 'semantic-ui-react';
// import { StyledButtonGroup, StyledButton } from '../PiboComponents';
import { StyledMessage } from '../../Message';
import Popup from '../../Popup';
import { getUserId } from '../../../utils/common';
import { AccountButton } from '../../account/AccountComponents';

const UserWithdraw = props => {
  const { t } = useTranslation('user');
  const { handleWithdrawClick, error } = props;
  const [checked, setChecked] = useState(false);
  const [isPopup, setPopup] = useState(false);
  // const agreement = t('WITHDRAW.AGREEMENT');

  const handleConfirm = () => {
    if (checked) {
      setPopup(true);
    }
  };

  const closePopup = () => {
    setPopup(false);
  };

  return (
    <>
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <Header as="h5" style={{ fontWeight: 'normal' }}>
              {t('WITHDRAW.MSG.CONFIRM')}
            </Header>
            <Segment>
              <Header as="h5">
                <Icon
                  name="check"
                  color="teal"
                  style={{ verticalAlign: 'baseline', fontSize: '1em' }}
                />
                <Header.Content style={{ paddingLeft: '.25rem' }}>
                  {t('WITHDRAW.CONTENT_1_1')}(
                  <span style={{ color: '#03BFD7' }}>{getUserId()}</span>)
                  {t('WITHDRAW.CONTENT_1_2')}
                </Header.Content>
                <Header.Subheader style={{ margin: '0.25em 0 0.5em 1.5em' }}>
                  {t('WITHDRAW.SUB_1')}
                </Header.Subheader>
                <Icon
                  name="check"
                  color="teal"
                  style={{ verticalAlign: 'baseline', fontSize: '1em' }}
                />
                <Header.Content style={{ paddingLeft: '.25rem' }}>
                  {t('WITHDRAW.CONTENT_2')}
                </Header.Content>
                <Header.Subheader style={{ margin: '0.25em 0 0.5em 1.5em' }}>
                  {t('WITHDRAW.SUB_2')}
                </Header.Subheader>
                <Icon
                  name="check"
                  color="teal"
                  style={{ verticalAlign: 'baseline', fontSize: '1em' }}
                />
                <Header.Content style={{ paddingLeft: '.25rem' }}>
                  {t('WITHDRAW.CONTENT_3')}
                </Header.Content>
                <Header.Subheader style={{ margin: '0.25em 0 0.5em 1.5em' }}>
                  {t('WITHDRAW.SUB_3')}
                </Header.Subheader>
              </Header>
            </Segment>
            <Checkbox
              style={{ fontWeight: 'bold' }}
              label={t('WITHDRAW.MSG.INFO')}
              onChange={() => setChecked(!checked)}
              checked={checked}
            />
          </Grid.Column>
        </Grid.Row>
        {error && (
          <Grid.Row>
            <Grid.Column>
              <StyledMessage color="red">{error}</StyledMessage>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column />
        </Grid.Row>
        <Grid.Row>
          <Grid.Column />
        </Grid.Row>
        {isPopup && (
          <Popup
            contents={`${t('WITHDRAW.MSG.POP_CONFIRM')}`}
            leftLabel={t('common:POP.CANCEL')}
            leftFunc={closePopup}
            rightLabel={t('common:POP.OK')}
            rightFunc={handleWithdrawClick}
            mode="alert"
            align="left"
            isPopup={isPopup}
          />
        )}
      </Grid>
      <AccountButton widths="1" attached="bottom">
        <Button color="blue" disabled={!checked} onClick={handleConfirm}>
          {t('common:CONFIRM')}
        </Button>
      </AccountButton>
    </>
  );
};

export default UserWithdraw;
