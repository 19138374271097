import React from 'react';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './calendar.css';

import { useTranslation } from 'react-i18next';
import DayPickerSingleDateControllerWrapper from './DayPickerSingleDateControllerWrapper';

import { CalNavIcon } from './Components';

const StyledCalIcon = styled.div`
  width: 0.6em;
  height: 0.6em;
  border-radius: 0.6em;
  margin: 0.15em;
  display: inline-block;
  vertical-align: bottom;
  background: ${({ color }) => (color === 'blue' ? '#03BFD7' : '#FFB922')};
`;

const Calendar = props => {
  const { t } = useTranslation('bot');
  const { scheduleData, selectedDate, getDayInfo } = props;
  const onDayClickHandler = async day => getDayInfo({ day });

  const renderDayContents = day => {
    let dayComponent = null;
    const monthDataElement = scheduleData[day.get('date') - 1];
    if (monthDataElement) {
      dayComponent = (
        <div style={{ fontSize: '0.8rem' }}>
          {day.format('D')}
          <div style={{ fontSize: '0.4rem', lineHeight: '0.6rem' }}>
            {monthDataElement.length > 0 ? (
              <StyledCalIcon color="blue" />
            ) : null}
          </div>
        </div>
      );
    } else {
      dayComponent = day.format('D');
    }
    return dayComponent;
  };

  const NavPrevIcon = () => <CalNavIcon name="chevron left" align="left" />;
  const NavNextIcon = () => <CalNavIcon name="chevron right" align="right" />;

  return (
    <div style={{ margin: '1rem', backgroundColor: '#fff' }}>
      <DayPickerSingleDateControllerWrapper
        monthFormat={t('CAL.DATE.MONTH_FORMAT')}
        navPrev={<NavPrevIcon />}
        navNext={<NavNextIcon />}
        onPrevMonthClick={() => getDayInfo({ month: -1 })}
        onNextMonthClick={() => getDayInfo({ month: 1 })}
        onDateChange={onDayClickHandler}
        numberOfMonths={1}
        renderDayContents={renderDayContents}
        isOutsideRange={() => false}
        initialDate={selectedDate}
        customFormat={t('CAL.DATE.FULL')}
      />
    </div>
  );
};
export default Calendar;
