import React, { useEffect, useState } from 'react';
import { Grid, Segment, Image, Placeholder, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { PiboLinePanelMenu, ConfigInfoCard } from '../PiboComponents';
import { getRobotPId, INSIDER } from '../../../utils/common';
import { PIBO_SETTING_INFO, PIBO_SETTING_MENU } from '../../../utils/consts';

const PiboSettingList = props => {
  const { t } = useTranslation('pibo');
  const {
    pibo,
    loading,
    // onDisturbChange,
    handleItemClick,
  } = props;
  const [unload, setUnload] = useState(true);
  const getInfoArray = () =>
    PIBO_SETTING_INFO.map(({ id, ...rest }) => {
      const obj = { id, ...rest };
      switch (id) {
        case 'callname':
          return { ...obj, value: pibo ? pibo.name : 'pibo' };
        case 'robotId':
          return {
            ...obj,
            value: getRobotPId(),
          };
        case 'ip':
          return { ...obj, value: pibo ? pibo.ip : '' };
        case 'battery':
          return { ...obj, value: pibo ? `${pibo.battery} %` : '' };
        case 'temper':
          return { ...obj, value: pibo ? pibo.temper : '' };
        case 'wifi':
          return { ...obj, value: pibo ? pibo.wifi : '' };
        case 'mode':
          return {
            ...obj,
            // eslint-disable-next-line no-nested-ternary
            value: pibo ? (pibo.reaction === 1 ? '적극적' : '소극적') : '',
          };
        case 'geo':
          return { ...obj, value: pibo ? pibo.geo.address.join(' ') : '' };
        default:
          return obj;
      }
    });
  const [cnt, setCount] = useState(0);
  const [menus, setMenus] = useState(PIBO_SETTING_MENU);

  const disturb = !unload && pibo && pibo.disturb ? pibo.disturb : false;
  const [status, setStatus] = useState(getInfoArray());

  /* const handleDisturbChange = () => {
    if (pibo) {
      onDisturbChange(!pibo.disturb);
    }
  }; */

  /*  handleAlignChange = (value) => {
    setState({
      align: value,
    });
  }; */

  const handleChange = ({ currentTarget: { id } }) => {
    if (id === 'robotId') {
      if (cnt < 5) {
        setCount(cnt + 1);
      } else {
        setCount(0);
      }
    } else if (id === 'ip') {
      setCount(0);
    }
  };

  const getPiboLoadStatus = obj =>
    Object.values(obj).reduce(
      (result, { id, value }) =>
        id === 'disturb'
          ? result
          : !!(result && value && value.toString().length),
      true,
    );

  useEffect(() => {
    if (pibo) {
      let newMenus = [...menus];
      if ('network' in pibo && pibo.network.toLowerCase() !== 'wifi') {
        newMenus = newMenus.filter(({ name }) => name !== 'wifi');
      }
      if (!INSIDER.verify) {
        newMenus = newMenus.filter(({ name }) => name !== 'locale');
      }
      setMenus(newMenus);
    }
    const infoArry = getInfoArray();
    setStatus(infoArry);
    setUnload(!getPiboLoadStatus(infoArry));
  }, [pibo]);

  return (
    <Grid centered padded>
      {
        <Grid.Row>
          <Grid.Column>
            <ConfigInfoCard disabled={loading}>
              {!loading && (
                <Image
                  src={
                    pibo ? '../image/210823_12.png' : '../image/power_off.png'
                  }
                  style={{
                    width: '50%',
                    maxWidth: '150px',
                    height: 'auto',
                    display: 'inline-block',
                    transition: 'all 1s ease-in-out',
                  }}
                />
              )}
              <div className="list">
                {unload &&
                  status
                    .slice(0, 2)
                    .map(({ id: infoId, value: infoValue, name: infoName }) => (
                      <div
                        role="button"
                        tabIndex={0}
                        className="column"
                        key={infoId}
                        id={infoId}
                        onMouseDown={handleChange}
                      >
                        <div className="name">{t(infoName)}</div>
                        <div className="value">
                          {(loading || !infoValue) && (
                            <Placeholder>
                              <Placeholder.Line length="short" />
                            </Placeholder>
                          )}
                          {!loading && infoValue}
                        </div>
                      </div>
                    ))}
                {!unload &&
                  status.map(
                    ({ id: infoId, value: infoValue, name: infoName }) =>
                      (infoId !== 'ip' || (infoId === 'ip' && cnt > 4)) && (
                        <div
                          role="button"
                          tabIndex={0}
                          className="column"
                          key={infoId}
                          id={infoId}
                          onMouseDown={handleChange}
                        >
                          <div className="name">{t(infoName)}</div>
                          <div className="value">
                            {(loading || !infoValue) && (
                              <Placeholder>
                                <Placeholder.Line length="short" />
                              </Placeholder>
                            )}
                            {!loading && infoValue}
                          </div>
                        </div>
                      ),
                  )}
                <div className="column">
                  <div className="name">{t('SET.DISTURB')}</div>
                  <div className="value">
                    <div
                      style={{
                        width: '75%',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                      }}
                    >
                      <Label
                        size="small"
                        color={disturb ? 'teal' : null}
                        basic={!disturb}
                        style={{ fontWeight: disturb ? '600' : '100' }}
                        // onClick={handleDisturbChange}
                      >
                        {disturb ? 'ON' : 'OFF'}
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
            </ConfigInfoCard>
            <Segment style={{ padding: 0 }}>
              {menus.map(({ name, menu, move, icon }) => (
                <PiboLinePanelMenu
                  {...{
                    key: name,
                    name,
                    menu: t(menu),
                    move,
                    icon,
                    onClick: handleItemClick,
                  }}
                />
              ))}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      }
    </Grid>
  );
};

export default PiboSettingList;
