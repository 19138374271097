/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Search, Segment, Icon } from 'semantic-ui-react';
import { useUpdateEffect } from 'react-use/lib';
import {
  PiboWrap,
  PiboColumn,
  PiboRow,
  PiboAccordion,
  PiboAccordionTitle,
  PiboAccordionContent,
} from '../pibo/PiboComponents';

const CSList = ({
  list: ls,
  error,
  results,
  resultSelect,
  addSearchChange: searchChange,
}) => {
  const [list, setList] = useState([]);
  const [value, setValue] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setLoading] = useState(false);
  const [active, setActive] = useState({ category: 0, item: -1 });

  useEffect(() => {
    setList(ls);
  }, [ls, results]);

  useUpdateEffect(() => {
    setList(ls);
    setActive({ category: 0, item: 0 });
  }, [results]);

  const handleKeyUp = event => {
    if (results.length < 1) return;
    if (event.keyCode === 13 && event.key === 'Enter') {
      event.target.blur();
    }
  };

  const handleSearchResult = () => {
    resultSelect();
  };

  const addSearchChange = () => {
    if (!isLoading) {
      searchChange(value);
    }
  };

  const handleSearchChange = (e, { value: v }) => {
    setValue(v);
    setTimeout(() => {
      addSearchChange();
    }, 500);
  };

  const handleCategoryClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = active.category === index ? -1 : index;
    setActive({ category: newIndex, item: -1 });
  };

  const handleItemClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = active.item === index ? -1 : index;
    setActive({ ...active, item: newIndex });
  };

  return (
    <div>
      <PiboWrap>
        <PiboRow>
          <PiboColumn style={{ backgroundColor: 'rgba(8,148,215,1)' }}>
            <Search
              open={false}
              loading={isLoading}
              onResultSelect={handleSearchResult}
              onSearchChange={handleSearchChange}
              value={value}
              input={{
                fluid: true,
                prompt: 'CS.FIND.LB',
                input: { onKeyUp: handleKeyUp, type: 'search' },
              }}
              style={{ margin: '0.5em 1em' }}
              fluid
            />
          </PiboColumn>
        </PiboRow>
      </PiboWrap>
      <PiboWrap>
        <Segment
          basic
          loading={isLoading}
          style={{ width: '100vw', padding: '0' }}
        >
          {error && (
            <div
              style={{
                margin: '1rem',
                textAlign: 'center',
              }}
            >
              {error}
            </div>
          )}
          {!error &&
            list &&
            list.map((category, index) => (
              <div key={category._id}>
                <PiboAccordion fluid>
                  <PiboAccordionTitle
                    active={active.category === index}
                    index={index}
                    onClick={handleCategoryClick}
                    bold={true.toString()}
                    foldicon={true.toString()}
                  >
                    {category._id}
                  </PiboAccordionTitle>
                  <PiboAccordionContent
                    active={active.category === index}
                    index={index}
                  >
                    {category.results &&
                      category.results.map((item, idx) => (
                        <PiboAccordion key={item._id} exclusive={false} fluid>
                          <PiboAccordionTitle
                            active={active.item === idx}
                            index={idx}
                            onClick={handleItemClick}
                            color="transparent"
                          >
                            <Icon name="angle right" />
                            {item.title}
                          </PiboAccordionTitle>
                          <PiboAccordionContent
                            active={active.item === idx}
                            index={idx}
                          >
                            <div
                              style={{
                                margin: '0 -1.5rem',
                                padding: '0.75rem 1.75rem',
                                background: 'rgba(209,211,212,0.2)',
                              }}
                            >
                              {item.content}
                            </div>
                          </PiboAccordionContent>
                        </PiboAccordion>
                      ))}
                  </PiboAccordionContent>
                </PiboAccordion>
              </div>
            ))}
        </Segment>
      </PiboWrap>
    </div>
  );
};
export default CSList;
