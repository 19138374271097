import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useMount } from 'react-use';
import { useTranslation } from 'react-i18next';
import { redirect, useNavigate, useParams } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import AppLocale from '../../components/pibo/settings/PiboLocale';
import AppInfoList from '../../components/app/AppInfoList';
import AppCS from '../../components/app/AppCustomerService';
import AppOpinion from '../../components/app/AppSendOpinion';
import {
  setLocale,
  getUserId,
  getRobotId,
  getLocale,
} from '../../utils/common';
import pjson from '../../../package.json';
import {
  sendOpinion,
  fecthCSList,
  fetchCSAutoCompleteData,
} from '../../ducks/app';
import { Page } from '../../components/Components';
import { environment, publishDate } from '../../circulusConfig';
import AppNotice from '../../components/app/AppNotice';

const AppSettingPage = props => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { menu } = useParams();
  const [item, setItem] = useState('');
  const [value, setValue] = useState('');
  const { pibo, onSendOpinion, result } = props;

  useMount(() => {
    const v = menu === 'locale' ? localStorage.getItem('locale') : '';
    setItem(menu);
    setValue(v);
  });

  const handleChange = v => {
    setValue(v);
  };

  const handleBackClick = () => {
    navigate('/pibo');
  };

  const handleCompleteClick = () => {
    setLocale('locale', value);
    navigate('/pibo');
  };

  const handleSendOpinionClick = content => {
    const data = {
      userId: getUserId(),
      robotId: getRobotId(),
      locale: getLocale(),
      content,
    };
    onSendOpinion(data);
  };

  const getMenuObject = i => {
    const menuObj = {};
    const data = { list: ['ko', 'en', 'cn', 'jp'], value: '' };
    const curVer =
      environment !== 'OPS' ? `${pjson.version}-${publishDate}` : pjson.version;
    let firmVer =
      pibo && 'firmware' in pibo && pibo.firmware ? pibo.firmware : '';
    if (!firmVer) {
      firmVer = localStorage.getItem('firmware')
        ? localStorage.getItem('firmware').replace(/"/g, '')
        : '';
    }
    // 'WITH_BACK_AND_COMPLETE' 모드의 모든 메뉴는 완료 처리 이벤트도 함께 보내줘야 함.
    switch (i) {
      case 'locale':
        data.value = localStorage.getItem(i);
        menuObj.mode = 'WITH_BACK_AND_COMPLETE';
        menuObj.title = t('app:LANGUAGE_SETTING');
        menuObj.component = <AppLocale {...data} handleChange={handleChange} />;
        break;
      case 'info':
        menuObj.mode = 'WITH_BACK';
        menuObj.title = t('app:APP_INFO');
        menuObj.component = (
          <AppInfoList
            curVer={curVer}
            firmVer={firmVer}
            onClcik={handleChange}
          />
        );
        break;
      case 'opinion':
        menuObj.mode = 'WITH_BACK';
        menuObj.title = t('app:SEND_FEEDBACK');
        menuObj.component = (
          <AppOpinion {...props} onOpinionSendClick={handleSendOpinionClick} />
        );
        break;
      case 'cs':
        menuObj.mode = 'WITH_BACK';
        menuObj.title = t('app:CS_CENTER');
        menuObj.component = <AppCS {...props} />;
        break;
      case 'notice':
        menuObj.mode = 'WITH_BACK';
        menuObj.title = t('app:NOTICE');
        menuObj.component = <AppNotice {...props} />;
        break;
      default:
        menuObj.mode = 'WITH_BACK';
        menuObj.title = t('app:APP_SETTING');
        break;
    }

    return menuObj;
  };

  const menuObj = getMenuObject(item);

  useEffect(() => (result ? redirect('/pibo') : () => {}), [result]);

  return (
    <Page>
      <TopMenu
        title={menuObj.title}
        menuMode={menuObj.mode}
        handleBackClick={handleBackClick}
        handleCompleteClick={handleCompleteClick}
      />
      {menuObj.component}
    </Page>
  );
};

export default connect(
  state => ({
    error: state.app.errorMsg,
    loading: state.app.loading,
    result: state.app.result,
    list: state.app.list,
    results: state.app.results,
    pibo: state.pibo.pibo,
  }),
  dispatch => ({
    onSendOpinion: data => {
      dispatch(sendOpinion(data));
    },
    onCSMount: () => {
      dispatch(fecthCSList());
    },
    onCSSearchResult: str => {
      dispatch(fetchCSAutoCompleteData(str));
    },
  }),
)(AppSettingPage);
