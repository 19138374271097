import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Segment,
  Form,
  TextArea,
  Header,
  Checkbox,
} from 'semantic-ui-react';
import { StyledButtonGroup, StyledButton } from '../PiboComponents';
import { StyledMessage } from '../../Message';
import Popup from '../../Popup';
import Loading from '../../Loading';

const PiboDisconnect = props => {
  const { t } = useTranslation();
  const { handleWithdrawClick: onWithdraw, error, loading } = props;
  const [checked, setChecked] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const agreement = t('pibo:SET.DISCONNECT.AGREEMENT');

  const handleConfirm = () => {
    if (checked) {
      setPopup(true);
    }
  };

  const closePopup = () => {
    setPopup(false);
    closePopup();
  };

  const handleWithdrawClick = () => {
    onWithdraw();
  };

  return (
    <>
      {loading && <Loading />}
      <Grid centered padded>
        <Grid.Row verticalAlign="bottom">
          <Grid.Column>
            <Header as="h4">{t('pibo:SET.DISCONNECT.MSG_CONFIRM')}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Form>
                <Form.Field
                  control={TextArea}
                  rows={4}
                  value={agreement}
                  readOnly
                  style={{
                    fontSize: '0.875rem',
                    lineHeight: '1.5',
                  }}
                />
              </Form>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <Checkbox
                label={t('pibo:SET.DISCONNECT.MSG_INFO')}
                onChange={() => setChecked(!checked)}
                checked={checked}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        {error && (
          <Grid.Row>
            <Grid.Column>
              <StyledMessage color="red">{error}</StyledMessage>
            </Grid.Column>
          </Grid.Row>
        )}
        {isPopup && (
          <Popup
            contents={`${t('pibo:SET.DISCONNECT.MSG_POP_CONFIRM')}`}
            leftLabel={t('pibo:SET.CANCEL')}
            leftFunc={closePopup}
            rightLabel={t('pibo:SET.DISCONNECT.LB')}
            rightFunc={handleWithdrawClick}
            mode="alert"
            align="left"
            isPopup={isPopup}
          />
        )}
        <StyledButtonGroup style={{ padding: '0' }}>
          <StyledButton
            color="blue"
            disabled={!checked}
            onClick={handleConfirm}
          >
            {t('common:CONFIRM')}
          </StyledButton>
        </StyledButtonGroup>
      </Grid>
    </>
  );
};

export default PiboDisconnect;
