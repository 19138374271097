const PIBO_SETTING_INFO = [
  { name: 'pibo:SERIAL', value: '', id: 'robotId' },
  { name: 'pibo:MODE', value: '', id: 'mode' },
  { name: 'pibo:GEO', value: '', id: 'geo' },
  { name: 'pibo:TEMPERATURE', value: '', id: 'temper' },
  { name: 'pibo:WIFI', value: '', id: 'wifi' },
  { name: 'pibo:IP', value: '', id: 'ip' },
  // { name: 'pibo:BATTERY', value: '', id: 'battery' },
  // { name: 'pibo:CALLNAME', value: '', id: 'callname' },
];
const PIBO_SETTING_MENU = [
  {
    menu: 'pibo:SET.CONF_REACTION',
    name: 'reaction',
    move: true,
    icon: 'reaction',
  },
  {
    menu: 'pibo:SET.CONF_STYLE',
    name: 'style',
    move: true,
    icon: 'style',
  },
  { menu: 'pibo:SET.CONF_LOCATION', name: 'geo', move: true, icon: 'geo' },
  {
    menu: 'pibo:SET.CONF_TEMPERATURE',
    name: 'tempUnit',
    move: true,
    icon: 'tempUnit',
  },
  // { menu: 'pibo:SET.CONF_WIFI', name: 'wifi', move: true, icon: 'wifi' },
  {
    menu: 'pibo:SET.CONF_DISTURB',
    name: 'disturb',
    move: true,
    icon: 'disturb',
  },
  // { menu: 'pibo:SET.CONF_CALLNAME', name: 'name', move: true },
  // { menu: 'pibo:SET.CONF_LANGUAGE', name: 'locale', move: true },
  // { menu: 'pibo:SET.CONF_LOCATION', name: 'location', move: true },
  {
    menu: 'pibo:SET.INSTRUCTION',
    name: 'instruction',
    move: true,
    icon: 'instruction',
  },
  {
    menu: 'pibo:INIT_CONF',
    name: 'initialize',
    move: false,
    icon: 'initialize',
  },
  {
    menu: 'pibo:SET.DISCONNECT.LB',
    name: 'disconnect',
    move: true,
    icon: 'disconnect',
  },
];
const PIBO_VOLUME_SLIDER_SETTING = {
  min: 0,
  max: 100,
  step: 1,
  railStyle: { backgroundColor: 'rgba(209,211,212,.5)' },
  trackStyle: { backgroundColor: 'rgba(3,191,215,1)' },
  handleStyle: {
    backgroundColor: '#03BFD7',
    border: '0',
  },
};
const PIBO_ALIGN_SLIDER_SETTING = {
  min: 1,
  max: 100,
  step: 1,
  railStyle: { backgroundColor: 'rgba(209,211,212,.5)' },
  trackStyle: { backgroundColor: 'rgba(3,191,215,1)' },
  handleStyle: {
    backgroundColor: '#03BFD7',
    border: '0',
  },
};

const OFFICIAL_WEATHER = 'OFFICIAL_WEATHER';
const OFFICIAL_NEWS = 'OFFICIAL_NEWS';
const TRANSLATE_APP = 'TRANSLATE_APP';
const OFFICIAL_WIKI = 'OFFICIAL_WIKI';
const OFFICIAL_TRAIN = 'OFFICIAL_TRAIN';
const OFFICIAL_HISTORY = 'OFFICIAL_HISTORY';
const OFFICIAL_PHOTO = 'OFFICIAL_PHOTO';
const OFFICIAL_MESSAGE = 'OFFICIAL_MESSAGE';
const OFFICIAL_CALENDAR = 'OFFICIAL_CALENDAR';
const OFFICIAL_AVATAR = 'OFFICIAL_AVATAR';
const OFFICIAL_DIARY = 'OFFICIAL_DIARY';
const NO_PAGE_APPS = [
  OFFICIAL_WEATHER,
  OFFICIAL_NEWS,
  TRANSLATE_APP,
  OFFICIAL_WIKI,
  // OFFICIAL_TRAIN,
];
const DEFAULT_APPS = [
  OFFICIAL_HISTORY,
  OFFICIAL_PHOTO,
  OFFICIAL_MESSAGE,
  OFFICIAL_CALENDAR,
  OFFICIAL_AVATAR,
  OFFICIAL_DIARY,
  OFFICIAL_TRAIN,
];
const NETWORK_CHECK_TIMEOUT = 8000;
const MSG_TYPE_TEXT = 'text';
const MSG_TYPE_VOICE = 'voice';
const MSG_MODE_REAL_TIME = 'realTime';
const MSG_MODE_SENSING_TIME = 'default';
const MSG_SEND_STATUS = 0;
const MSG_RECEIVED_STATUS = 1;
const WHITE_PLAYER_SCALE = [
  '#fff',
  'rgba(255, 255, 255, 0.5)',
  'rgba(255, 255, 255, 0)',
  '#03bfd7',
  'rgba(255, 255, 255, 0)',
];
// [playicon, outline, circlebottom, circletop, border]
const BLUE_PLAYER_SCALE = [
  '#0894D7',
  '#0894D7',
  'rgba(255, 255, 255, .5)',
  'rgba(255, 255, 255, .5)',
  '#fff',
];
const TEXT_MAX_LENGTH = 40;
const DEFAUTL_VOLUME = 60;
const MENU_TYPES = {
  WITH_LOGIN: 'WITH_LOGIN',
  WITH_USER: 'WITH_USER',
  WITH_MENU: 'WITH_MENU',
  WITH_CONFIG: 'WITH_CONFIG',
  WITH_BACK: 'WITH_BACK',
  WITH_CLOSE: 'WITH_CLOSE',
  WITH_COMPLETE: 'WITH_COMPLETE',
  WITH_BACK_AND_COMPLETE: 'WITH_BACK_AND_COMPLETE',
  WITH_BACK_AND_MENU: 'WITH_BACK_AND_MENU',
  WITH_BACK_PHOTO: 'WITH_BACK_PHOTO',
};

const WEEK_DAYS = [
  { name: 'common:WEEKDAY.SUN', key: 0 },
  { name: 'common:WEEKDAY.MON', key: 1 },
  { name: 'common:WEEKDAY.TUE', key: 2 },
  { name: 'common:WEEKDAY.WED', key: 3 },
  { name: 'common:WEEKDAY.THU', key: 4 },
  { name: 'common:WEEKDAY.FRI', key: 5 },
  { name: 'common:WEEKDAY.SAT', key: 6 },
];

const DANCE_MOTIONS = [
  { key: 'greeting', name: 'common:MOTION.GREETING' },
  { key: 'welcome', name: 'common:MOTION.WELCOME' },
  { key: 'hifive', name: 'common:MOTION.HIFIVE' },
  { key: 'cheer', name: 'common:MOTION.CHEER' },
  { key: 'wake_up', name: 'common:MOTION.WAKE_UP' },
  { key: 'hey', name: 'common:MOTION.HEY' },
  { key: 'think', name: 'common:MOTION.THINK' },
  { key: 'handshaking', name: 'common:MOTION.HANDSHAKING' },
  { key: 'yes_h', name: 'common:MOTION.YES_H' },
  { key: 'no_h', name: 'common:MOTION.NO_H' },
  { key: 'spin_h', name: 'common:MOTION.SPIN_H' },
  { key: 'clapping', name: 'common:MOTION.CLAPPING' },
  { key: 'happy', name: 'common:MOTION.HAPPY' },
  { key: 'sad', name: 'common:MOTION.SAD' },
  { key: 'boring', name: 'common:MOTION.BORING' },
  { key: 'excite', name: 'common:MOTION.EXCITE' },
  { key: 'hand1', name: 'common:MOTION.HAND1' },
  { key: 'hand2', name: 'common:MOTION.HAND2' },
  { key: 'hand3', name: 'common:MOTION.HAND3' },
  { key: 'hand4', name: 'common:MOTION.HAND4' },
  { key: 'head_h', name: 'common:MOTION.HEAD_H' },
  { key: 'foot', name: 'common:MOTION.FOOT' },
  { key: 'breath', name: 'common:MOTION.BREATH' },
  { key: 'wave', name: 'common:MOTION.WAVE' },
  { key: 'handup_r', name: 'common:MOTION.HANDUP_R' },
  { key: 'handup_l', name: 'common:MOTION.HANDUP_L' },
  { key: 'look_r', name: 'common:MOTION.LOOK_R' },
  { key: 'look_l', name: 'common:MOTION.LOOK_L' },
  // { key: 'bow', name: 'common:MOTION.BOW' },
  // { key: 'bye', name: 'common:MOTION.BYE' },
  // { key: 'welcome', name: 'common:MOTION.WELCOME' },
  // { key: 'hifive', name: 'common:MOTION.HIFIVE' },
  // { key: 'cheer', name: 'common:MOTION.CHEER' },
  // { key: 'think', name: 'common:MOTION.THINK' },
  // { key: 'wake_up', name: 'common:MOTION.WAKE_UP' },
  // { key: 'hey', name: 'common:MOTION.HEY' },
  // { key: 'happy_h', name: 'common:MOTION.HAPPY_H' },
  // { key: 'happy', name: 'common:MOTION.HAPPY' },
  // { key: 'greeting', name: 'common:MOTION.GREETING' },
  // { key: 'excite', name: 'common:MOTION.EXCITE' },
  // { key: 'expect', name: 'common:MOTION.EXPECT' },
  // { key: 'fear', name: 'common:MOTION.FEAR' },
  // { key: 'sad', name: 'common:MOTION.SAD' },
  // { key: 'boring', name: 'common:MOTION.BORING' },
  // { key: 'sleep_h', name: 'common:MOTION.SLEEP_H' },
  // { key: 'breath', name: 'common:MOTION.BREATH' },
  // { key: 'head_h', name: 'common:MOTION.HEAD_H' },
  // { key: 'clapping', name: 'common:MOTION.CLAPPING' },
  // { key: 'handshaking', name: 'common:MOTION.HANDSHAKING' },
  // { key: 'yes_h', name: 'common:MOTION.YES_H' },
  // { key: 'no_h', name: 'common:MOTION.NO_H' },
  // { key: 'spin_h', name: 'common:MOTION.SPIN_H' },
  // { key: 'hand1', name: 'common:MOTION.HAND1' },
  // { key: 'hand2', name: 'common:MOTION.HAND2' },
  // { key: 'hand3', name: 'common:MOTION.HAND3' },
  { key: 'dance', name: 'common:MOTION.DANCE' },
];

const GENDER_OPTIONS = [
  { id: 'female', value: 'user:GENDER.FEMALE' },
  { id: 'male', value: 'user:GENDER.MALE' },
  { id: 'unknown', value: 'user:GENDER.UNKNOWN' },
];

const FACE_DETECT_RESULTS = [
  {
    error: 'RTYPE_ERROR',
    code: 0,
    msg: 'user:IMG.MSG.RTYPE_ERROR',
  },
  { error: 'RTYPE_FACE_SUCCESS', code: 1, msg: '' },
  {
    error: 'RTYPE_NO_FACE',
    code: 2,
    msg: 'user:IMG.MSG.RTYPE_NO_FACE',
  },
  {
    error: 'RTYPE_SMALL_FACE',
    code: 3,
    msg: 'user:IMG.MSG.RTYPE_SMALL_FACE',
  },
  {
    error: 'RTYPE_MASK_FACE',
    code: 4,
    msg: 'user:IMG.MSG.RTYPE_MASK_FACE',
  },
];

const DEFAULT_GEO = {
  address: ['서울특별시', '강남구', '역삼동'],
  lat: 37.4979231,
  lng: 127.0276217,
  x: 61,
  y: 125,
};

const DEFAULT_AP = {
  essid: 'pibo',
  password: '!pibo0314',
  wpa: 'wpa',
  encrypted: true,
};

const SEQUENCE = [
  // {
  //   sequence: 'seq_3',
  //   name: 3,
  //   frames: 6,
  //   time: 600,
  //   repeat: 1,
  //   label: '꺼짐',
  // },
  // {
  //   sequence: 'seq_4',
  //   name: 4,
  //   frames: 11,
  //   time: 1100,
  //   repeat: 1,
  //   label: '켜짐',
  // },
  // {
  //   sequence: 'seq_5',
  //   name: 5,
  //   frames: 11,
  //   time: 1100,
  //   repeat: 1,
  //   label: '앞으로',
  // },
  {
    sequence: 'seq_6',
    name: 6,
    frames: 7,
    time: 800,
    repeat: 4,
    label: '숨쉬기',
  },
  {
    sequence: 'seq_7',
    name: 7,
    frames: 7,
    time: 600,
    repeat: 5,
    label: '아니',
  },
  {
    sequence: 'seq_8',
    name: 8,
    frames: 6,
    time: 1200,
    repeat: 3,
    label: '응',
  },
  {
    sequence: 'seq_9',
    name: 9,
    frames: 11,
    time: 2800,
    repeat: 1,
    label: '인사',
  },
];

export {
  SEQUENCE,
  DEFAULT_AP,
  NO_PAGE_APPS,
  DEFAULT_APPS,
  NETWORK_CHECK_TIMEOUT,
  MSG_TYPE_TEXT,
  MSG_TYPE_VOICE,
  MSG_MODE_REAL_TIME,
  MSG_MODE_SENSING_TIME,
  MSG_SEND_STATUS,
  MSG_RECEIVED_STATUS,
  WHITE_PLAYER_SCALE,
  BLUE_PLAYER_SCALE,
  TEXT_MAX_LENGTH,
  DEFAUTL_VOLUME,
  PIBO_SETTING_INFO,
  PIBO_SETTING_MENU,
  PIBO_VOLUME_SLIDER_SETTING,
  PIBO_ALIGN_SLIDER_SETTING,
  MENU_TYPES,
  DANCE_MOTIONS,
  WEEK_DAYS,
  OFFICIAL_WEATHER,
  OFFICIAL_NEWS,
  TRANSLATE_APP,
  OFFICIAL_WIKI,
  OFFICIAL_TRAIN,
  OFFICIAL_HISTORY,
  OFFICIAL_PHOTO,
  OFFICIAL_MESSAGE,
  OFFICIAL_CALENDAR,
  OFFICIAL_AVATAR,
  OFFICIAL_DIARY,
  GENDER_OPTIONS,
  FACE_DETECT_RESULTS,
  DEFAULT_GEO,
};
