import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  StoreRow,
  StoreColumn,
  StoreWrap,
  StoreReviewCard,
  StoreRating,
  TextButton,
  ReviewForm,
} from './StoreComponents';

const StoreMyReview = props => {
  const { t } = useTranslation('store');
  const {
    // ReviewContainer Prop
    isInstalled,
    // newReview State
    writeMode,
    reviewTitle,
    reviewText,
    rating,

    // review spread
    nickName,
    title,
    score,
    content,
    lastTime,

    // review ducks state
    saving,

    // StoreReviewList Prop
    onWrite,
    onCancel,
    onDelete,
    handleReviewTitleChange,
    handleReviewTextChange,
    handleRate,
  } = props;

  const ratingRef = useRef();
  const [updateMode, setMode] = useState(writeMode);
  const [rate, setRate] = useState(score);
  const reviewTitleError = reviewTitle.length < 2 || reviewTitle.length > 50;
  const reviewTextError = reviewText.length < 1 || reviewText.length > 200;
  const reviewConfirmDisable =
    saving || rating < 1 || reviewTitleError || reviewTextError;
  
    useEffect(() => {
    setMode(writeMode);
  }, [writeMode]);
  
  useEffect(() => {
    setRate(score);
  }, [score]);

  const handleUpdateClick = () => {
    setMode(true);
    onWrite();
    // ratingRef.current.setState({ rating: 0 });
  };

  const handleCancelClick = () => {
    setMode(false);
    onCancel();
    ratingRef.current.setState({ rating: 0 });
  };

  const handleOnRateClick = (e, data) => {
    if (!writeMode) {
      onWrite();
    }
    setRate(data.rating);
    handleRate(e, data);
  };

  const getWarnings = () => {
    if (rating < 1) {
      return t('REVIEW.SELECT_RATING');
    }
    if (reviewTitleError) {
      return t('REVIEW.TITLE.LENGTH');
    }
    if (reviewTextError) {
      return t('REVIEW.CONTENT.LENGTH');
    }
    return '';
  };

  return (
    <div
      style={{
        background: 'rgba(209, 211, 212, 0.3)',
        borderRadius: '0.5rem',
        margin: '1rem -0.5rem',
        padding: '1rem 0.5rem',
      }}
    >
      {!updateMode && content && title && (
        <StoreWrap style={{ background: 'transparent' }}>
          <StoreRow>
            <StoreColumn>
              <StoreReviewCard
                title={title}
                lastTime={lastTime}
                content={content}
                nickName={nickName}
                score={rate}
                open
                fluid
              />
            </StoreColumn>
          </StoreRow>
          <StoreRow>
            <div
              style={{
                width: '100%',
                display: 'flex',
                fontSize: '0.75rem',
                justifyContent: 'flex-end',
                color: '#0894d7',
              }}
            >
              <TextButton onClick={onDelete}>{t('REVIEW.REMOVE')}</TextButton>
              <TextButton onClick={handleUpdateClick}>
                {t('REVIEW.UPDATE')}
              </TextButton>
            </div>
          </StoreRow>
        </StoreWrap>
      )}
      {(updateMode || (!content && isInstalled && !title)) && (
        <StoreWrap>
          <StoreRow>
            <StoreColumn fluid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <StoreRating
                  maxRating={5}
                  defaultRating={rating}
                  onRate={handleOnRateClick}
                  disabled={saving}
                  size="huge"
                  clearable
                  ref={ratingRef}
                />
                <Button
                  color="blue"
                  size="mini"
                  onClick={onWrite}
                  disabled={updateMode && reviewConfirmDisable}
                >
                  {t('REVIEW.RATING')}
                </Button>
              </div>
            </StoreColumn>
          </StoreRow>
          {updateMode && (
            <StoreRow style={{ margin: '1em 0' }}>
              <StoreColumn fluid>
                <ReviewForm
                  {...{
                    saving,
                    reviewText,
                    reviewTitle,
                    handleReviewTextChange,
                    handleReviewTitleChange,
                  }}
                />
              </StoreColumn>
            </StoreRow>
          )}
          {updateMode && (
            <StoreRow>
              <StoreColumn fluid>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    fontSize: '0.75rem',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    color: '#0894d7',
                  }}
                >
                  <div style={{ color: '#E10600' }}>{getWarnings()}</div>
                  <TextButton
                    style={{ color: '#0894d7', fontFamily: 'inherit' }}
                    onClick={handleCancelClick}
                  >
                    {t('REVIEW.CANCEL')}
                  </TextButton>
                </div>
              </StoreColumn>
            </StoreRow>
          )}
        </StoreWrap>
      )}
    </div>
  );
};

export default StoreMyReview;
