/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import moment from 'moment';
import { useUpdateEffect } from 'react-use';
// import VoiceSoundBar from './VoiceSoundBar';

const Wrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

let timer = null;
const VoiceMessageDetail = props => {
  const { t } = useTranslation('bot');
  const {
    record,
    startRecording,
    stopRecording,
    deleteRecording,
    sendRecording,
    sending,
    label,
  } = props;
  const { audioURL, recording } = record;
  const [mm, setMomentTime] = React.useState(
    moment()
      .minutes(0)
      .seconds(0),
  );
  const [time, setTime] = React.useState(mm.format('mm:ss'));

  useUpdateEffect(() => {
    if (recording) {
      if (timer) clearInterval(timer);
      if (!audioURL) {
        timer = setInterval(() => {
          setMomentTime(mm.add(1, 'seconds'));
          setTime(mm.format('mm:ss'));
        }, 1000);
      }
    } else {
      clearInterval(timer);
      setMomentTime(
        moment()
          .minutes(0)
          .seconds(0),
      );
      setTime('00:00');
    }
  }, [recording]);
  return (
    <Wrap>
      {!recording && !audioURL && (
        <Button
          icon
          labelPosition="left"
          color="blue"
          size="mini"
          onClick={startRecording}
        >
          <Icon name="microphone" />
          {t('MSG.REC')}
        </Button>
      )}
      {recording && (
        <>
          <Button
            icon
            labelPosition="left"
            color="red"
            size="mini"
            onClick={stopRecording}
          >
            <Icon name="stop" />
            {t('MSG.STOP')}
          </Button>
          <div>{time}</div>
        </>
      )}
      {/* <ExpandWrap>{msg}</ExpandWrap> */}
      {!recording && audioURL && (
        <Wrap>
          <Button
            icon
            labelPosition="left"
            size="mini"
            onClick={deleteRecording}
            style={{ flex: '0 0' }}
          >
            <Icon name="undo" />
            취소
          </Button>
          <audio controls src={audioURL} style={{ flex: '1 1' }} />
          <Button
            onClick={sendRecording}
            disabled={!audioURL}
            color="teal"
            size="mini"
            style={{ flex: '0 0' }}
          >
            {sending ? <Icon name="spinner" loading /> : label}
          </Button>
        </Wrap>
      )}
    </Wrap>
  );
};

export default VoiceMessageDetail;
