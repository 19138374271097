import React, { useState, useEffect } from 'react';
import { Grid, Menu, Form, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const PiboLocale = props => {
  const { t } = useTranslation();
  const { value: localeValue, list, handleChange } = props;
  const [value, setValue] = useState(localeValue);

  useEffect(() => {
    setValue(localeValue);
  }, [localeValue]);

  const handleLocaleChange = (e, { value: v }) => {
    handleChange(v);
    setValue(v);
  };

  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <Menu vertical fluid>
            <Form>
              {list &&
                list.length > 0 &&
                list.map(item => (
                  <Menu.Item
                    key={item.id}
                    value={item.id}
                    onClick={handleLocaleChange}
                  >
                    <Form.Field>
                      <Radio
                        label={t(item.value)}
                        name="radioGroup"
                        value={item.id}
                        checked={value === item.id}
                        onChange={handleLocaleChange}
                      />
                    </Form.Field>
                  </Menu.Item>
                ))}
            </Form>
          </Menu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PiboLocale;
