/* eslint-disable react/state-in-constructor */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Dimmer, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledPopUp = styled(Segment)`
  &&& {
    border-radius: 0 !important;
    position: relative !important;
    padding: 1rem 0 4rem !important;
    min-width: 70vw;
    max-width: 85vw;
  }
`;

const StyledHeader = styled.div`
  text-align: 'center';
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
`;

const StyledContent = styled.div`
  text-align: 'center';
  font-size: 1rem;
  padding: 0.5rem 1rem;
  line-height: 1.2rem;
`;

const StyledButtonGroup = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 3rem;
  display: flex;
`;

const StyledButton = styled.div`
  width: inherit;
  border: 0;
  border-radius: 0;
  background: ${({ color }) =>
    color === 'gray'
      ? '#D1D3D4'
      : color === 'red'
      ? '#EF3340'
      : color === 'blue'
      ? '#0894D7'
      : '#FFF'};
  color: ${({ color }) => (color === 'gray' ? '#565B5B' : '#FFF')};
  display: inline-block;
  font-size: 1rem;
  padding: 1rem;
  height: 100%;
`;

const Confirm = ({
  isPopup: bPop,
  leftLabel,
  leftFunc,
  rightLabel,
  rightFunc,
  contents,
  header,
  mode,
  onClose,
}) => {
  const [isPopup, setPopup] = useState(bPop || false);

  useEffect(() => {
    setPopup(bPop);
  }, [bPop]);

  const close = () => {
    setPopup(false);
  };

  const handleClickLeft = () => {
    if (leftFunc) {
      return leftFunc();
    }
    return onClose();
  };

  const handleClickRight = () => {
    if (rightFunc) {
      rightFunc();
    }
    return onClose();
  };

  const btnColor = value => {
    switch (value) {
      case 'alert':
        return 'red';
      case 'confirm':
        return 'blue';
      default:
        return 'gray';
    }
  };

  return (
    <Dimmer active={isPopup} onClickOutside={close} page>
      <StyledPopUp>
        {header && header.length > 0 && <StyledHeader>{header}</StyledHeader>}
        <StyledContent>{contents}</StyledContent>
        <StyledButtonGroup>
          {leftLabel && (
            <StyledButton color="gray" onClick={handleClickLeft}>
              {leftLabel}
            </StyledButton>
          )}
          <StyledButton color={btnColor(mode)} onClick={handleClickRight}>
            {rightLabel}
          </StyledButton>
        </StyledButtonGroup>
      </StyledPopUp>
    </Dimmer>
  );
};

export default Confirm;
