/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import {
  InputGroup,
  LinkDescription,
  LinkWrap,
} from '../../components/link/Components';
import LinkPopup from '../../components/link/LinkPopup';
import { StyledMessage } from '../../components/Message';
import { onNext } from '../../ducks/link';

const CODE_LENGTH = 5;
const LinkStep1 = props => {
  const { t, focusNext, handleChange, error, loading, success } = props;
  const dispatch = useDispatch();
  const [code, setCode] = useState(Array(CODE_LENGTH).fill(false));
  const [isPopup, setPopupState] = useState(false);
  const refs = Array(CODE_LENGTH).fill(useRef(null));

  const handleCodeCheck = () => {
    dispatch(onNext());
  };

  const handleCodeKeyDown = i => e => {
    // 48(0)~57(9)
    const { keyCode } = e;
    if (keyCode === 8 && e.key === 'Backspace' && !code[i]) {
      refs[i - 1].focus();
    }
    if (keyCode >= 48 && keyCode <= 57) {
      e.preventDefault();
      const v = e.key.replace(/\s/g, '').substr(0, 1);
      const newCode = [...code].fill(v, i, i + 1);
      setCode(newCode);
      if (v) {
        const nextIdx = i + 1;
        if (nextIdx === CODE_LENGTH) {
          focusNext();
        } else {
          refs[nextIdx].focus();
        }
      }
    }
    if (i === CODE_LENGTH - 1 && keyCode === 13) {
      handleCodeCheck();
    }
  };

  const handleCodeChange = i => e => {
    const v = e.target.value.replace(/\s/g, '').substr(0, 1);
    const newCode = [...code].fill(v, i, i + 1);
    setCode(newCode);
    const nextIdx = i + 1;
    if (v) {
      if (nextIdx === CODE_LENGTH) {
        focusNext();
      } else {
        refs[nextIdx].focus();
      }
    }
  };

  const setInputRef = i => c => {
    refs[i] = c;
    return c;
  };

  const handlePopup = (v, nextFunc) => {
    setPopupState(v);
    if (nextFunc) nextFunc();
  };

  useUpdateEffect(() => {
    if (props.code !== code) {
      handleChange({ code });
    }
  }, [code]);
  useUpdateEffect(() => setCode(props.code), [props.code]);

  return (
    <LinkWrap
      style={{
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <LinkDescription
        header={t('conn:MSG.LINK_DESCRIPTION_1.HEADER')}
        list={[
          t('conn:DIRECTION.STEP_1'),
          t('conn:DIRECTION.STEP_2'),
          t('conn:DIRECTION.STEP_3'),
        ]}
      />
      <InputGroup>
        {code.map((v, i) => (
          <input
            key={`input-${i}`}
            name={`code-${i}`}
            value={v || ''}
            type="tel"
            onChange={handleCodeChange(i)}
            onKeyDown={handleCodeKeyDown(i)}
            readOnly={loading || success}
            ref={setInputRef(i)}
            autoComplete="off"
          />
        ))}
      </InputGroup>
      {error && (
        <StyledMessage color="red" style={{ marginBottom: '3rem' }}>
          {error}
        </StyledMessage>
      )}
      <button
        className="appendix"
        type="button"
        htmlFor="popup"
        onClick={() => handlePopup(true)}
      >
        {t('conn:BTN.APPENDIX')}
      </button>
      {isPopup && (
        <LinkPopup
          id="popup"
          t={t}
          rightLabel={t('POP.CLOSE')}
          rightFunc={() => handlePopup(false)}
          isPopup
        />
      )}
    </LinkWrap>
  );
};

export default LinkStep1;
