import React from 'react';
import styled from 'styled-components';

const Emoji = styled.p`
  font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, sans-serif;
  font-size: 3em;
  margin: 0 !important;
`;

const EmojiDescription = styled.div`
  margin-left: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.25em;
  & span {
    font-size: 0.875rem;
    &.data {
      font-weight: bold;
    }
  }
`;

const FaceDetectCard = ({ name, age, emotion, emotion_en: key }) => {
  const getFace = k => {
    switch (k) {
      case 'angry':
        return '😡';
      case 'disgusted':
        return '🤢';
      case 'fearful':
        return '😱';
      case 'happy':
        return '😆';
      case 'sad':
        return '😞';
      case 'surprised':
        return '😵';
      case 'neutral':
        return '😐';
      default:
        return '🧐';
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignitems: 'flex-start',
        gap: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        }}
      >
        <Emoji>{getFace(key.toLowerCase())}</Emoji>
        {key && (
          <EmojiDescription>
            <span className="data">{`${emotion}의`}</span>
            <span className="data">{`${age}세로 보이는 ${name}`}</span>
            <span>얼굴을 찾았어.</span>
          </EmojiDescription>
        )}
        {!key && (
          <EmojiDescription>
            <span>얼굴을 찾지 못했어.</span>
          </EmojiDescription>
        )}
      </div>
    </div>
  );
};

export default FaceDetectCard;
