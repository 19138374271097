import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { RingButtonGroup, RingButtonItem, RingCutOut } from './Component';

const Camera = props => {
  const { t } = useTranslation('bot');
  const {
    preventState,
    up,
    down,
    left,
    right,
    position,
    onControllerHandler,
  } = props;
  const { tiltMax, tiltMin, panMax, panMin } = position;
  return (
    <Card
      title={t('AVA.CAM.LB')}
      popupContent={{
        header: t('AVA.CAM.LB'),
        content: t('AVA.CAM.GUIDE'),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          margin: '1rem 0.5rem 1rem 1rem',
          position: 'relative',
        }}
      >
        <RingButtonGroup>
          <RingButtonItem
            className="up"
            active={up}
            disabled={(!up && preventState) || tiltMin}
            onClick={() => onControllerHandler('up')}
          >
            <div>{t('AVA.UP')}</div>
          </RingButtonItem>
          <RingButtonItem
            className="right"
            active={right}
            disabled={(!right && preventState) || panMin}
            onClick={() => onControllerHandler('right')}
          >
            <div>{t('AVA.RIGHT')}</div>
          </RingButtonItem>
          <RingButtonItem
            className="left"
            active={left}
            disabled={(!left && preventState) || panMax}
            onClick={() => onControllerHandler('left')}
          >
            <div>{t('AVA.LEFT')}</div>
          </RingButtonItem>
          <RingButtonItem
            className="down"
            active={down}
            disabled={(!down && preventState) || tiltMax}
            onClick={() => onControllerHandler('down')}
          >
            <div>{t('AVA.DOWN')}</div>
          </RingButtonItem>
          <RingCutOut />
        </RingButtonGroup>
      </div>
    </Card>
  );
};

export default Camera;
