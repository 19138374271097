import React, { useState, useRef } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { errorLog } from '../../../utils/report';

const FixedMenu = styled.div`
  position: fixed;
  ${({ standalone }) =>
    standalone
      ? `@media (max-height: 850px) {
    bottom: 45px;
  }
  @media (min-height: 851px) {
    bottom: 54px;
  }`
      : `bottom: 0;`}
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 99;
  box-shadow: 0px 1px 3px 0px rgba(209, 211, 212, 1);
  border-top: 1px solid rgba(208, 211, 212, 0.55);
  background: #e5e5e5;
  display: flex;
  justify-content: center;
`;

const InputDialog = props => {
  const { t } = useTranslation('bot');
  const inputRef = useRef(null);
  const [messageText, setMsgText] = useState('');
  const [error, setError] = useState(null);
  const {
    message,
    pending,
    sendHistoryPending,
    sendHistoryError,
  } = useSelector(state => state.history);
  const { icon, sendDialog, standalone } = props;

  useUpdateEffect(() => {
    setError(sendHistoryError);
  }, [sendHistoryError]);

  useUpdateEffect(() => {
    if (messageText.length > 0 && message.length === 0) {
      inputRef.current.focus();
    }
    setMsgText(message);
  }, [message]);

  const sendMessage = async event => {
    if (!messageText || sendHistoryPending) return;

    if (event.key === 'Enter' || event.type === 'click') {
      try {
        await sendDialog(messageText);
      } catch (e) {
        errorLog(e, 'InputDialog>sendMessage');
      }
    }
  };

  const changeText = event => {
    const {
      target: { value },
    } = event;
    setMsgText(value);
    setError(null);
  };

  return (
    <FixedMenu standalone={standalone}>
      <Input
        ref={inputRef}
        name="messageText"
        value={messageText}
        size="small"
        placeholder={t('HST.ENTER_CMD')}
        style={{
          margin: '0.75rem 1rem',
          width: '95%',
          paddingLeft: '0.5em !important',
          fontSize: '0.875rem',
        }}
        onChange={changeText}
        onKeyPress={sendMessage}
        icon={
          error ? (
            <Icon name="redo" link color="red" onClick={sendMessage} />
          ) : (
            <Icon
              name={icon}
              inverted
              circular
              link
              color="blue"
              disabled={messageText.length === 0}
              onClick={sendMessage}
            />
          )
        }
        error={!!error}
        disabled={pending || sendHistoryPending}
        loading={sendHistoryPending}
      />
    </FixedMenu>
  );
};

export default InputDialog;
