import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';

const QRCodeInputForm = ({ t, disalbed, onClick, onChange }) => {
  const [pwVisible, setPWVisible] = useState(false);
  const handlePWVisibleChange = () => setPWVisible(!pwVisible);

  return (
    <Form>
      <Form.Input
        placeholder={t('conn:WIFI_NAME')}
        name="essid"
        onChange={onChange}
      />
      <Form.Input
        icon={{
          name: pwVisible ? 'unlock' : 'lock',
          link: true,
          onClick: handlePWVisibleChange,
        }}
        name="password"
        placeholder={t('conn:PASSWORD')}
        type={pwVisible ? 'text' : 'password'}
        onChange={onChange}
      />
      <Button
        color="blue"
        content={t('pibo:CONNECT_MANUAL_WIFI')}
        icon="wifi"
        labelPosition="left"
        fluid
        disabled={disalbed}
        onClick={onClick}
      />
    </Form>
  );
};

export default QRCodeInputForm;
