/* eslint-disable consistent-return */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useUpdateEffect } from 'react-use';
import { Button, Header, Image } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AccountButton,
  Steps,
  CompleteDiv,
} from '../../components/account/AccountComponents';

import TermsContainer from './TermsContainer';
import {
  fetchTerms,
  confirmCode,
  sendCode,
  onNext,
  onPrev,
  onChange,
  initialData,
} from '../../ducks/social';
import CertificationContainer from './CertificationContainer';
import InfoContainer from './InfoContainer';
import { getUserToken } from '../../utils/common';
import { Page } from '../../components/Components';

const SocialSignUpPage = () => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const social = useSelector(state => state.social);
  console.log(social);
  const { terms, activeIndex, steps, data, btn, loading, complete } = social;

  useMount(() => {
    if (getUserToken()) {
      return navigate('/pibo');
    }
    dispatch(initialData());
    dispatch(fetchTerms());
  });

  const handlePrev = () => {
    dispatch(onPrev());
  };
  const handleNext = () => dispatch(onNext());

  const handleChange = value => {
    dispatch(onChange(value));
  };

  useUpdateEffect(() => {
    if (activeIndex < 0) return navigate('/login');
    if (activeIndex > steps.length && complete.to) return navigate(complete.to);
  }, [activeIndex]);

  // useUpdateEffect(() => {
  //   if (complete.to) {
  //     return navigate(complete.to);
  //   }
  // }, [complete]);

  useUpdateEffect(() => {
    if (activeIndex === 1) {
      const { tel, email } = data[activeIndex];
      if (tel.confirm || email.confirm) {
        handleNext();
      }
    }
  }, [data]);

  return (
    <Page style={{ top: 0 }} className="scrollbar-hide">
      {activeIndex < steps.length && (
        <div
          style={{
            margin: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.875rem',
          }}
        >
          {t('SIGN_UP.MSG.STEP_BY_STEP')}
        </div>
      )}
      <Steps steps={steps} activeIndex={activeIndex} />
      <div
        style={
          activeIndex < steps.length
            ? { padding: '1.5rem 1rem', marginBottom: '60px' }
            : { height: 'calc(100vh - 158px)' }
        }
      >
        {activeIndex === 0 && (
          <TermsContainer
            {...{ terms, agreement: data[activeIndex], handleChange }}
          />
        )}
        {activeIndex === 1 && (
          <CertificationContainer
            {...{
              certification: {
                tel: data[activeIndex].tel,
                email: data[activeIndex].email,
              },
              loading,
              error: data[activeIndex].error,
              handleChange,
              confirmCode,
              sendCode,
            }}
          />
        )}
        {activeIndex === 2 && (
          <InfoContainer {...{ info: data[activeIndex], handleChange }} />
        )}
        {activeIndex === steps.length && (
          <CompleteDiv>
            <Header as="h4">{complete.message}</Header>
            <Image src="/image/pibo_next_level.png" />
          </CompleteDiv>
        )}
      </div>
      <AccountButton widths="2" fluid attached="bottom">
        {btn.map(({ label, ...rest }, i) => (
          <Button {...rest} onClick={i === 0 ? handleNext : handlePrev}>
            {label}
          </Button>
        ))}
      </AccountButton>
    </Page>
  );
};

export default SocialSignUpPage;
