import React from 'react';
import StoreItemES from './StoreItemES';
import { StoreList } from './StoreComponents';

const StoreListES = props => {
  const { handleDownClick, handleItemClick, handleOpenClick, botItems } = props;

  const getUrl = data => {
    const { page, url, id, projectName } = data;
    if (page) {
      if (url && url.indexOf('OFFICIAL') > -1) {
        return `/bots/${url}`;
      }
      if (projectName.indexOf('CUSTOM_') === 0) {
        return `/bots/customPage?botId=${id}`;
      }
      return `/bots/externalPage?botId=${id}`;
    }
    return `/bots/defaultPage?botId=${id}`;
  };

  return (
    <StoreList>
      {botItems &&
        botItems.length > 0 &&
        botItems.map(({ id, use, score, reviews, ...rest }) => (
          <StoreItemES
            {...rest}
            {...{
              id,
              use,
              score,
              reviews,
              url: getUrl({ id, ...rest }),
              fluid: false,
            }}
            key={`${id}_${score}_${reviews}`}
            handleDownClick={handleDownClick}
            handleItemClick={handleItemClick}
            handleOpenClick={handleOpenClick}
          />
        ))}
    </StoreList>
  );
};

export default StoreListES;
