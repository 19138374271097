import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import * as Scroll from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { Dimmer, Icon } from 'semantic-ui-react';
import {
  fetchingPiboData,
  fetchPiboData,
  getDataByAPI,
} from '../../ducks/pibo';
import {
  // getLocale,
  getUserId,
  setMounted,
  getMounted,
  getRobotId,
  pageRefresh,
  MobileView,
} from '../../utils/common';
import {
  Battery,
  BodyWrap,
  Status,
  StatusText,
  StatusWrap,
  Volume,
} from '../../components/pibo/PiboComponents';
import { setComment } from '../../ducks/page';
import { getAlive, setPiboVolume } from '../../pibo';
import PiboContent from '../../components/PiboContent';
import withAuth from '../../hocs/withAuth';

let mounted = false;

const PiboPage = () => {
  const { pathname } = useLocation();
  const {
    pibo: { pibo: p, init },
    page: {
      main: { type },
    },
  } = useSelector(state => state);
  const dispatch = useDispatch();
  const [pibo, setPibo] = useState(p || null);
  const [guide, setGuide] = useState(false);
  const GUIDE_ID = '20231201';

  const handleGuideClick = () => {
    if (guide) {
      localStorage.setItem('guide_main', GUIDE_ID);
      setGuide(false);
    }
  };

  useLifecycles(
    () => {
      if (!getUserId()) {
        dispatch(setComment('unsigned'));
      } else if (!getRobotId()) {
        dispatch(setComment('unconnect'));
      } else if (
        localStorage.getItem('firstLoad') === true ||
        localStorage.getItem('firstLoad') === 'true'
      ) {
        dispatch(fetchingPiboData());
        localStorage.removeItem('firstLoad');
        pageRefresh();
      } else {
        if (
          !localStorage.getItem('guide_main') ||
          localStorage.getItem('guide_main') !== GUIDE_ID
        ) {
          setGuide(true);
        }
        if (!mounted && !getMounted(pathname)) {
          mounted = true;
          setMounted(pathname);
          dispatch(fetchPiboData());
        } else {
          dispatch(getDataByAPI());
        }
        const scroll = Scroll.animateScroll;
        scroll.scrollToTop({ duration: 0, smooth: true });
      }
    },
    () => {
      mounted = false;
      // setPiboMode('disturb', null);
    },
  );

  useEffect(() => {
    if (!getUserId()) {
      dispatch(setComment('unsigned'));
    } else if (!getRobotId()) {
      dispatch(setComment('unconnect'));
    } else {
      if (getAlive()) {
        dispatch(setComment('on'));
      } else {
        dispatch(setComment('off'));
      }

      if (getRobotId()) {
        if (p && p !== pibo) {
          setPibo(p);
        } else if (!p && !init) {
          dispatch(fetchPiboData());
        }
      } else {
        dispatch(setComment('unconnect'));
      }
    }
  }, [p]);

  return (
    <BodyWrap>
      <StatusWrap>
        {type === 'off' && (
          <StatusText color="#d1d3d4">
            <Icon name="power" />
            <span>로봇의 전원을 켜거나 Wi-Fi 연결상태를 확인하세요.</span>
          </StatusText>
        )}
        {type === 'unconnect' && (
          <StatusText color="#03BFD7">
            <Icon name="exclamation circle" />
            <span>로봇 연결이 필요합니다.</span>
          </StatusText>
        )}
        {type === 'on' && (
          <>
            <Status>
              <Icon name="power" />
              <Battery
                charging={pibo ? pibo.plug : p && p.plug}
                value={pibo ? pibo.battery : p && p.battery}
              />
            </Status>
            <Volume
              value={pibo && 'volume' in pibo ? pibo.volume : p && p.volume}
              onChange={v => setPiboVolume(v)}
            />
          </>
        )}
      </StatusWrap>
      <PiboContent refresh={() => dispatch(setComment(type))} />
      <MobileView>
        <Dimmer
          active={guide}
          page
          onClick={handleGuideClick}
          style={{ padding: 0 }}
        >
          <div className="guide">
            <div className="main_guide_top" />
            <div className="main_guide_bottom" />
          </div>
        </Dimmer>
      </MobileView>
    </BodyWrap>
  );
};

export default withAuth(PiboPage);
// 로그인 상태 체크: TopMenu에 로그인 상태에 따른 상태 넘겨줄 것 (로그인 여부)
// 파이보 연결 상태 체크: 연결 여부에 따라 본문의 카드가 아닌 연결 화면이 보여져야 함 (파이보 연결 여부)
// PiboCard에 파이보 정보(시리얼, 콜네임, 연결된 와이파이명, 배터리, 온도, 사운드볼륨) 넘겨줄 것
// PiboFavoriteCard에 사용자의 즐겨찾는 bot 리스트 넘겨줄 것
