import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLifecycles } from 'react-use';
import { Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import TablMenu from '../../../components/bots/AvatarBot/TabMenu';
import Popup from '../../../components/Popup';
import { setPiboMode } from '../../../pibo';
import { getRobotId, getUserId } from '../../../utils/common';
import CameraContainer from './CameraContainer';
import MotionContainer from './MotionContainer';
import SpeakContainer from './SpeakContainer';

const MainSegment = styled(Segment)`
  &.ui.segment {
    @media (max-height: 850px) {
      padding-bottom: 90px;
    }
    @media (min-height: 851px) {
      padding-bottom: 108px;
    }
    margin: 0;
  }
`;

const AvatarPage = () => {
  const { t } = useTranslation('bot');
  const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState('motion');
  const [isPopup, setPopup] = useState(false);
  const [mode, setMode] = useState(false);

  const handleMenuClick = (e, { name }) => {
    setActiveItem(name);
  };

  const handlePopUpClick = () => {
    navigate('/pibo');
    setPopup(false);
  };

  useLifecycles(
    () => {
      if (!getRobotId()) {
        setPopup(true);
      }
      if (!mode) {
        setMode(true);
        setPiboMode('avatar', true);
      }
    },
    () => {
      setPopup(false);
      setMode(false);
      setPiboMode('avatar', false);
    },
  );

  return (
    <>
      {isPopup && (
        <Popup
          contents={getUserId() ? t('MSG.STATE') : '로그인 후 이용하세요.'}
          rightLabel={t('common:POP.OK')}
          rightFunc={handlePopUpClick}
          leftFunc={handlePopUpClick}
          mode="confirm"
          isPopup={isPopup}
        />
      )}
      <MainSegment basic>
        <TablMenu onClick={handleMenuClick} activeItem={activeItem} />
        {activeItem === 'motion' && <MotionContainer standalone />}
        {activeItem === 'speak' && <SpeakContainer standalone />}
        {activeItem === 'camera' && <CameraContainer standalone />}
      </MainSegment>
    </>
  );
};

export default AvatarPage;
