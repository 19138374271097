import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useMount } from 'react-use';
import { useLastLocation } from 'react-router-dom-last-location';
import { useNavigate, useParams } from 'react-router-dom';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import Calendar from '../../../components/bots/CalendarBot/Calendar';
import CalendarItemList from '../../../components/bots/CalendarBot/CalendarItemList';
import Loading from '../../../components/Loading';

import {
  getDayInfo,
  getSchedule,
} from '../../../ducks/bots/calendarBot/calendarBot';
import { Page, FixedBottomButton } from '../../../components/Components';
import { errorLog } from '../../../utils/report';

let backLocation = '/bots';

const MainContainer = props => {
  const { name } = useParams();
  const { lastLocation } = useLastLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selector = useSelector(state => state.calendarBot);
  const { t } = useTranslation('bot');
  const {
    loading,
    getDefaultDataStatus,
    selectedDate: date,
    scheduleData,
  } = selector;
  const { title } = props;
  const selectedDate = moment(new Date(date));

  useMount(() => {
    if (lastLocation && 'pathname' in lastLocation) {
      if (lastLocation.pathname.search(`bots/${name}`) < 0) {
        backLocation = lastLocation.pathname;
      }
    }
    try {
      dispatch(getSchedule());
    } catch (error) {
      errorLog(error, 'CaledarBot>MainContainer>comoponentDidMount');
    }
  });

  const handleAddBtnClick = () => {
    navigate('/bots/OFFICIAL_CALENDAR/add');
  };

  const handleScheduleItemClick = (e, { name: n }) => {
    navigate(`/bots/OFFICIAL_CALENDAR/view?name=${n}&date=${date}`);
  };

  return (
    <>
      {getDefaultDataStatus && !loading ? null : <Loading />}
      <BotTopMenu title={title} backLocation={backLocation} />
      <Page isBottom>
        <Calendar
          selectedDate={selectedDate}
          scheduleData={scheduleData}
          getDayInfo={data => dispatch(getDayInfo(data))}
        />
        <CalendarItemList
          selectedDate={selectedDate}
          scheduleData={scheduleData}
          onClick={handleScheduleItemClick}
        />
        <FixedBottomButton widths={1} inverted onClick={handleAddBtnClick}>
          <Icon name="plus" />
          {t('CAL.ADD.LB')}
        </FixedBottomButton>
      </Page>
    </>
  );
};

export default MainContainer;
