/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { Divider, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDebounce, useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import MessageItem from './MessageItem';
import {
  deleteMessageItem,
  getMessage,
  setPending,
} from '../../../ducks/bots/messageBot/message';

const ScrollableMessageList = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  background: #fbfbfb;
  @media (max-height: 850px) {
    margin-top: 40px;
    ${({ actualHeight }) => `height: ${actualHeight - 40}px;`}
  }
  @media (min-height: 851px) {
    margin-top: 45px;
    ${({ actualHeight }) => `height: ${actualHeight - 45}px;`}
  }
`;

const MessageList = ({ actualHeight, timelineRef }) => {
  const dispatch = useDispatch();
  const { messageData, pending, bBottom, length } = useSelector(
    state => state.messageBot,
  );

  const [scrollPosition, setScrollPoistion] = useState(0);

  useDebounce(
    () => {
      if (scrollPosition !== 0) {
        dispatch(getMessage());
      }
    },
    200,
    [scrollPosition],
  );

  const handleLoad = () => {
    if (timelineRef.current) {
      const { scrollTop } = timelineRef.current;
      setScrollPoistion(scrollTop);
    }
    dispatch(setPending());
  };

  const handleDelete = id => () => {
    dispatch(deleteMessageItem(id));
  };

  useUpdateEffect(() => {
    if (timelineRef.current && !bBottom) {
      timelineRef.current.scrollTop = scrollPosition;
    }
  }, [length]);

  return (
    <ScrollableMessageList
      id="scrollableDiv"
      actualHeight={actualHeight}
      ref={timelineRef}
    >
      {pending && (
        <Icon
          name="spinner"
          pending={pending.toString()}
          style={{
            position: 'absolute',
            margin: '4rem 50% .8rem',
            color: '#d1d3d4',
            zIndex: '99',
          }}
        />
      )}
      {messageData && (
        <InfiniteScroll
          dataLength={length}
          next={handleLoad}
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }} // To put endMessage and loader to the top.
          inverse
          hasMore={true && !pending}
          loader={
            pending && (
              <Icon
                name="spinner"
                pending={pending.toString()}
                style={{
                  // position: 'absolute',
                  margin: '4rem 50% .8rem',
                  color: '#d1d3d4',
                  zIndex: '99',
                }}
              />
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {messageData.map(msg => {
            const [date, list] = Object.entries(msg)[0];
            return (
              (date || (list && list.length > 0)) && (
                <div key={date}>
                  <div style={{ width: '100%' }}>
                    <Divider
                      horizontal
                      style={{
                        fontSize: '0.625rem',
                        color: '#d1d3d4',
                        fontWeight: '100',
                      }}
                    >
                      {date}
                    </Divider>
                  </div>
                  {list.length > 0 &&
                    list.map(({ id, ...message }, index) => (
                      <MessageItem
                        {...message}
                        id={id}
                        key={id}
                        index={index}
                        onDelete={handleDelete(id)}
                      />
                    ))}
                </div>
              )
            );
          })}
        </InfiniteScroll>
      )}
    </ScrollableMessageList>
  );
};

export default MessageList;
