import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const CalNavIcon = styled(Icon)`
  position: absolute;
  color: #0894d7;
  /* box-shadow: 0 0 0 0.1em rgba(8, 148, 215, 1) inset !important; */
  border: 2px solid rgba(8, 148, 215, 1);
  top: 0.625rem;
  left: ${({ align }) => (align === 'left' ? '1.2rem' : 'initial')};
  right: ${({ align }) => (align === 'right' ? '1.2rem' : 'initial')};
  margin: 0.625rem !important;
  cursor: pointer;
  border-radius: 30px;

  &&& {
    width: 1.25em !important;
    height: 1.25em !important;
    padding: ${({ align }) =>
      align === 'left' ? '0 2px 0 0' : '0 0 0 2px'} !important;
    line-height: 1.1em !important;
  }
`;

export { CalNavIcon };
