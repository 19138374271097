/* eslint-disable prefer-promise-reject-errors */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getStoreMainData,
  addBot,
  removeBot,
  getUserData,
  fetchBotDetail,
} from '../utils/api';
import { convertImageToBlob, getLocale, getRobotId } from '../utils/common';
import i18 from '../lang/i18n';
import { getAlive, setPiboBot } from '../pibo';
import { infoLog } from '../utils/report';

const name = 'store';

const NUM = 16;

const initialState = {
  loading: false,
  searching: false,
  errorMsg: '',
  changed: false,
  searchStr: '',
  mainData: [],
  searchResult: [],
  page: 0,
  user: null,
};

export const fetchUserInfo = createAsyncThunk(
  `${name}/FETCH_USER`,
  async (userId, { getState }) => {
    let {
      user: { user },
    } = getState();
    if (!user || ('email' in user && user.email.length === 0)) {
      const { result, data } = await getUserData(userId);
      if (result) {
        user = data;
        const convertedImgData = user.data
          ? await convertImageToBlob(user.data)
          : null;
        user.data = convertedImgData ? convertedImgData.url : null;
      }
    }
    return user;
  },
);

export const fetchStoreList = createAsyncThunk(
  `${name}/FETCH_LIST`,
  async ({ robotId, page: p }, { rejectWithValue, getState }) => {
    try {
      const { page: prev, mainData: prevData } = getState().store;
      const mainData = [...prevData];
      const page = typeof p === 'number' ? p : prev;
      const { result, data } = await getStoreMainData(
        robotId,
        getLocale(),
        page,
      );
      if (result) {
        if (page === 0) return { mainData: data, page: page + 1 };
        if (mainData.length / NUM === page)
          return { mainData: mainData.concat(data), page: page + 1 };
        mainData.splice(page * NUM, data.length, ...data);
        return { mainData, page: data.length ? page + 1 : page };
      }
      return rejectWithValue(i18.t('ERR.UNSPECIFIC'));
    } catch (error) {
      return rejectWithValue(i18.t('ERR.UNSPECIFIC'));
    }
  },
);

export const fetchSearchList = createAsyncThunk(
  `${name}/SEARCH_LIST`,
  async ({ robotId, value }, { rejectWithValue, getState }) => {
    try {
      const { page: prev, searchResult: prevData } = getState().store;
      const page = prevData.length === 0 ? 0 : prev;
      const { result, data } = await getStoreMainData(
        robotId,
        getLocale(),
        page,
        value,
      );
      if (result) {
        if (page === 0 && data.length === 0)
          return {
            searchResult: [],
            page: 0,
            errorMsg: i18.t('store:SEARCH.NO_RESULT', { value }),
          };
        if (page === 0) return { searchResult: data, page: page + 1 };
        return { searchResult: prevData.concat(data), page: page + 1 };
      }
      return rejectWithValue(i18.t('ERR.UNSPECIFIC'));
    } catch (error) {
      return rejectWithValue(i18.t('conn:ERR.LOAD_FAILED.INTEREST'));
    }
  },
);

export const fetchBotInfo = createAsyncThunk(
  `${name}/FETCH_INFO`,
  async ({ botId, robotId }) => {
    const { result, data } = await fetchBotDetail({
      botId,
      robotId,
      locale: getLocale(),
    });
    if (result) {
      return data;
    }
    return {};
  },
);

export const installBot = createAsyncThunk(
  `${name}/INSTALL`,
  async (
    { botId, projectId, userId, title },
    { rejectWithValue, getState },
  ) => {
    infoLog('install', botId, projectId, userId, title);
    if (getAlive()) {
      setPiboBot('add', { id: botId, projectId, userId, title });
    }

    // setTimeout(async () => {
    const res = await addBot({ robotId: getRobotId(), botId });
    const { mainData: data } = getState().store;
    const mainData = [...data];
    const updateIdx = mainData.findIndex(({ id }) => botId === id);
    const updateItem = {
      ...mainData[updateIdx],
      installing: false,
      isInstalled: true,
      use: true,
    };
    if (res.result) {
      mainData.splice(updateIdx, 1, updateItem);
      return { mainData, changed: true };
    }
    mainData.splice(updateIdx, 1, {
      ...mainData[updateIdx],
      installing: false,
    });
    return rejectWithValue({ mainData });
    // }, 1000);
  },
);

export const uninstallBot = createAsyncThunk(
  `${name}/UNINSTALL`,
  async (
    { botId, projectId, userId, title },
    { rejectWithValue, getState },
  ) => {
    infoLog('uninstall', botId, projectId, userId, title);

    if (getAlive()) {
      setPiboBot('remove', { id: botId, projectId, userId, title });
    }

    // setTimeout(async () => {
    const res = await removeBot({ robotId: getRobotId(), botId });
    const { mainData: data } = getState().store;
    const mainData = [...data];
    const updateIdx = mainData.findIndex(({ id }) => botId === id);
    const updateItem = {
      ...mainData[updateIdx],
      installing: false,
      isInstalled: false,
      use: false,
    };
    if (res.result) {
      mainData.splice(updateIdx, 1, updateItem);
      return { mainData, changed: true };
    }
    mainData.splice(updateIdx, 1, {
      ...mainData[updateIdx],
      installing: false,
    });
    return rejectWithValue({ mainData });
    // }, 1000);
  },
);

const StoreSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchWord: (state, action) => ({
      ...state,
      searching: true,
      searchStr: action.payload,
    }),
    noResult: (state, action) => ({
      ...state,
      searching: false,
      searchResult: [],
      errorMsg: action.payload,
    }),
    backToList: state => ({ ...state, bot: null, page: state.page }),
  },
  extraReducers: {
    [fetchStoreList.pending.type]: state => ({ ...state, loading: true }),
    [fetchStoreList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      errorMsg: '',
      changed: false,
      ...action.payload,
    }),
    [fetchStoreList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      errorMsg: action.payload,
      searchResult: [],
    }),
    [fetchSearchList.pending.type]: state => ({
      ...state,
      searching: true,
      errorMsg: '',
    }),
    [fetchSearchList.fulfilled.type]: (state, action) => ({
      ...state,
      searching: false,
      ...action.payload,
    }),
    [fetchSearchList.rejected.type]: (state, action) => ({
      ...state,
      searching: false,
      errorMsg: action.payload,
    }),
    [installBot.pending.type]: state => ({
      ...state,
      bot: state.bot ? { ...state.bot, installing: true } : null,
    }),
    [installBot.fulfilled.type]: (state, action) => ({
      ...state,
      ...action.payload,
      bot: state.bot
        ? { ...state.bot, installing: false, isInstalled: true, use: true }
        : null,
    }),
    [installBot.rejected.type]: (state, action) => ({
      ...state,
      mainData: action.payload.mainData,
      bot: state.bot ? { ...state.bot, installing: false } : null,
    }),
    [uninstallBot.pending.type]: state => ({
      ...state,
      bot: state.bot ? { ...state.bot, installing: true } : null,
    }),
    [uninstallBot.fulfilled.type]: (state, action) => ({
      ...state,
      ...action.payload,
      bot: state.bot
        ? { ...state.bot, installing: false, isInstalled: false, use: false }
        : null,
    }),
    [uninstallBot.rejected.type]: (state, action) => ({
      ...state,
      mainData: action.payload.mainData,
      bot: state.bot ? { ...state.bot, installing: false } : null,
    }),
    [fetchUserInfo.fulfilled.type]: (state, action) => ({
      ...state,
      user: action.payload,
    }),
    [fetchBotInfo.pending.type]: state => ({ ...state, loading: true }),
    [fetchBotInfo.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      bot: action.payload,
    }),
    [fetchBotInfo.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      errorMsg: action.payload,
    }),
  },
});

const { reducer: StoreReducer, actions } = StoreSlice;
export const { noResult, setSearchWord, backToList } = actions;

export const autoSearch = ({ robotId, value }) => dispatch => {
  dispatch(setSearchWord(value));
  const noKChar = /[ㄱ-ㅎㅏ-ㅣ]/g;
  const replaceChar = /[\s.,]/g;
  let search = false;
  if (value.replace(replaceChar, '').length > 1 && !noKChar.test(value)) {
    search = true;
  } else if (value.length === 0) {
    search = true;
  }
  if (!search) {
    if (value.length === 1 && /[가-힣a-zA-Z]/g.test(value)) {
      return dispatch(noResult(i18.t('store:SEARCH.MSG.TWO_WORDS')));
    }
    return dispatch(noResult(i18.t('store:SEARCH.MSG.UNVALID')));
  }

  if (value) {
    return dispatch(fetchSearchList({ robotId, value }));
  }
  dispatch(noResult(''));
  return dispatch(fetchStoreList({ robotId, page: 0 }));
};

export default StoreReducer;
