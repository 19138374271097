import React, { useState } from 'react';
import { Grid, GridRow } from 'semantic-ui-react';
import { useUpdateEffect, useWindowSize } from 'react-use';

import styled from 'styled-components';
import BotsSearch from './BotsSearch';
import BotsListItem from './BotsListItem';
import BotSettingListItem from './BotSettingListItem';

import { getColumns } from '../../utils/common';

const BotsListWrap = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  @media (max-height: 850px) {
    padding-bottom: 45px;
  }
  @media (min-height: 851px) {
    padding-bottom: 54px;
  }
  & > div {
    overflow-x: hidden;
    @media (max-height: 850px) {
      padding-bottom: 45px;
    }
    @media (min-height: 851px) {
      padding-bottom: 54px;
    }
  }
`;

const BotsList = props => {
  const {
    error,
    bots: botList,
    isSetting,
    loading,
    handleUseClick,
    addSearchChange: handleChange,
    handleBotClick: handleClick,
  } = props;
  const [bots, setBots] = useState(botList);
  const [value, setValue] = useState('');
  const [columns, setColumns] = useState(getColumns());
  const { width } = useWindowSize();

  const handleUseToggleClick = ({ id, checked }) => {
    handleUseClick(id, checked);
  };

  const handleSearchRemove = () => {
    setValue('');
    handleChange('', !isSetting);
  };

  const handleSearchChange = (e, { value: v }) => {
    setValue(v);
    handleChange(v);
  };

  const handleBotClick = data => {
    const { projectId, projectName, botId, page, url } = data;
    handleClick({ projectId, projectName, botId, page, url });
  };

  useUpdateEffect(() => {
    setBots([...botList]);
  }, [botList]);

  useUpdateEffect(() => {
    setValue('');
    setBots([...botList]);
  }, [isSetting]);

  useUpdateEffect(() => {
    setColumns(getColumns());
  }, [width]);

  return (
    <BotsListWrap className="scrollbar-hide">
      <div>
        <BotsSearch
          {...{ loading, bots, value }}
          handleSearchChange={handleSearchChange}
          handleSearchRemove={handleSearchRemove}
        />
        {error && error.length > 0 && (
          <div
            style={{
              padding: '0.5rem 1rem',
              textAlign: 'center',
              width: '100%',
              color: '#fff',
            }}
          >
            {error}
          </div>
        )}
        {(!error || error.length === 0) && (
          <div
            style={{
              padding: '1rem 0',
              marginTop: '0.375em',
              display: 'flex',
              flexWrap: 'nowrap',
              flexDirection: 'row',
              alignItems: 'baseline',
              transition: 'transform 0.4s ease-in-out',
              transform: isSetting ? 'translateX(-100%)' : 'translateX(0)',
            }}
          >
            <Grid
              style={{
                justifyContent: 'cetner',
                flexShrink: 0,
                margin: '-1rem 0',
                width: '100%',
              }}
            >
              <GridRow
                columns={columns}
                style={{ padding: '0 1rem', justifyContent: 'baseline' }}
                stretched
              >
                {!loading &&
                  bots &&
                  bots.map(
                    ({ id, use, title, botId, type, ...rest }) =>
                      use && (
                        <BotsListItem
                          id={id}
                          key={id}
                          bot={botId}
                          title={title}
                          type={type}
                          handleBotClick={() =>
                            handleBotClick({ id, botId, ...rest })
                          }
                        />
                      ),
                  )}
              </GridRow>
              <GridRow />
            </Grid>
            <Grid
              style={{
                flexShrink: 0,
                width: '100%',
                margin: '-1rem 0 0',
                overflowY: !isSetting ? 'hidden' : 'auto',
                maxHeight: !isSetting && '100vh',
              }}
            >
              {!loading &&
                bots &&
                bots.map(({ id, use, projectId, title, botId }) => (
                  <BotSettingListItem
                    id={botId}
                    projectId={projectId}
                    key={id}
                    bot={botId}
                    title={title}
                    checked={use}
                    handleUseToggleClick={handleUseToggleClick}
                  />
                ))}
            </Grid>
          </div>
        )}
      </div>
    </BotsListWrap>
  );
};

export default BotsList;
