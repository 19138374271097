/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import { Header } from 'semantic-ui-react';
import { WeatherLocation } from './WeatherComponents';
import WeatherDustCard from './WeatherDustCard';
import WeatherTodayCard from './WeatherTodayCard';
import WeatherTodayFutureCard from './WeatherTodayFutureCard';
import WeatherTomorrowCard from './WeatherTomorrowCard';

export const WEATHER_TODAY = '00';
export const WEATHER_TODAY_FUTURE = '001';
export const WEATHER_TOMMOROW = '01';
export const WEATHER_AFTER_TOMMOROW = '02';
export const WEATHER_DUST = '10';

const WeatherCard = ({ type, error, ...data }) => (
  <>
    {!type && (
      <WeatherLocation>
        <Header as="h5">{error}</Header>
      </WeatherLocation>
    )}
    {type === WEATHER_TODAY && <WeatherTodayCard {...data} />}
    {type === WEATHER_TODAY_FUTURE && <WeatherTodayFutureCard {...data} />}
    {(type === WEATHER_TOMMOROW || type === WEATHER_AFTER_TOMMOROW) && (
      <WeatherTomorrowCard {...{ ...data, type }} />
    )}
    {type === WEATHER_DUST && <WeatherDustCard {...data} />}
  </>
);

export default WeatherCard;
