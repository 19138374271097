import styled from 'styled-components';

export const WEATHER_COLOR = {
  sun: {
    label: '맑음',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(243, 194, 66, 0.05) 0%, rgba(243, 194, 66, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(243, 194, 66, 0.03) 50%, rgba(243, 194, 66, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(243, 194, 66, 0.03) 50%, rgba(243, 194, 66, 0.3) 100%)',
    color: '#736541',
  },
  cloud_sun: {
    label: '구름 많음',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(131, 146, 154, 0.05) 0%, rgba(131, 146, 154, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    color: '#576166',
  },
  cloud_sun_rain: {
    label: '구름많고 비',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(131, 146, 154, 0.05) 0%, rgba(131, 146, 154, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    color: '#576166',
  },
  cloud_sun_snow: {
    label: '구름많고 눈',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(131, 146, 154, 0.05) 0%, rgba(131, 146, 154, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    color: '#576166',
  },
  cloud_sun_rain_snow: {
    label: '구름많고 비/눈',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(131, 146, 154, 0.05) 0%, rgba(131, 146, 154, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    color: '#576166',
  },
  cloud_sun_umbrella: {
    label: '구름많고 소나기',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(131, 146, 154, 0.05) 0%, rgba(131, 146, 154, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(131, 146, 154, 0.03) 50%, rgba(131, 146, 154, 0.3) 100%)',
    color: '#576166',
  },
  clouds: {
    label: '흐림',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(93, 103, 109, 0.05) 0%, rgba(93, 103, 109, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    color: '#32373B',
  },
  cloud_rain: {
    label: '흐리고 비',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(93, 103, 109, 0.05) 0%, rgba(93, 103, 109, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    color: '#32373B',
  },
  cloud_snow: {
    label: '흐리고 눈',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(93, 103, 109, 0.05) 0%, rgba(93, 103, 109, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    color: '#32373B',
  },
  cloud_rain_snow: {
    label: '흐리고 비/눈',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(93, 103, 109, 0.05) 0%, rgba(93, 103, 109, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    color: '#32373B',
  },
  cloud_umbrella: {
    label: '흐리고 소나기',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(93, 103, 109, 0.05) 0%, rgba(93, 103, 109, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(93, 103, 109, 0.03) 50%, rgba(93, 103, 109, 0.3) 100%)',
    color: '#32373B',
  },
  rain: {
    label: '비',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(214, 239, 253, 0.1) 0%, rgba(188, 229, 252, 0.5) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(214, 239, 253, 0.03) 50%, rgba(214, 239, 253, 0.6) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(214, 239, 253, 0.03) 50%, rgba(214, 239, 253, 0.6) 100%)',
    color: '#193F6E',
  },
  rain_umbrella: {
    label: '비',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(214, 239, 253, 0.1) 0%, rgba(188, 229, 252, 0.5) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(214, 239, 253, 0.03) 50%, rgba(214, 239, 253, 0.6) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(214, 239, 253, 0.03) 50%, rgba(214, 239, 253, 0.6) 100%)',
    color: '#193F6E',
  },
  rain_snow: {
    label: '비 또는 눈',
    background:
      'radial-gradient(50% 50% at 50% 50%, #AACCFF 0%, rgba(146, 206, 252, 0.1) 0.01%, rgba(146, 205, 251, 0.5) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(146, 206, 252, 0.03) 50%, rgba(146, 206, 252, 0.6) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(146, 206, 252, 0.03) 50%, rgba(146, 206, 252, 0.6) 100%)',
    color: '#47647A',
  },
  snow: {
    label: '눈',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(170, 204, 255, 0.1) 0%, rgba(170, 204, 255, 0.5) 100%),',
    bgAm:
      'linear-gradient(270deg, rgba(170, 204, 255, 0.03) 50%, rgba(170, 204, 255, 0.6) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(170, 204, 255, 0.03) 50%, rgba(170, 204, 255, 0.6) 100%)',
    color: '#556680',
  },
  umbrella: {
    label: '소나기',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(0, 184, 156, 0.05) 0%, rgba(0, 184, 156, 0.3) 100%), #FFFFFF;',
    bgAm:
      'linear-gradient(270deg, rgba(0, 184, 156, 0.03) 50%, rgba(0, 184, 156, 0.6) 100%)',
    bgPm:
      'linear-gradient(90deg, rgba(0, 184, 156, 0.03) 50%, rgba(0, 184, 156, 0.6) 100%)',
    color: '#003830',
  },
  좋음: {
    label: 'good',
    bg10: 'rgba(96, 191, 224, 0.3) 100%',
    bg25: 'rgba(96, 191, 224, 0.3) 0%',
  },
  보통: {
    label: 'normal',
    bg10: 'rgba(150, 206, 225, 0.3) 100%',
    bg25: 'rgba(150, 206, 225, 0.3) 0%',
  },
  나쁨: {
    label: 'soso',
    bg10: 'rgba(199, 175, 149, 0.3) 100%',
    bg25: 'rgba(199, 175, 149, 0.3) 0%',
  },
  '매우 나쁨': {
    label: 'bad',
    bg10: 'rgba(226, 98, 89, 0.3) 100%',
    bg25: 'rgba(226, 98, 89, 0.3) 0%',
  },
  null: {
    label: 'none',
    bg10: 'rgba(255, 255, 255, 0.3) 100%',
    bg25: 'rgba(255, 255, 255, 0.3) 0%',
  },
};

export const WeatherLocation = styled.div`
  display: flex;
  flex-flow: row;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
  align-items: center;
  & h5.header {
    font-size: 0.875rem;
    margin: 0;
    font-weight: normal;
  }
`;

export const WeatherDust = styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: center;
  padding: 0px;
  font-size: 0.75rem;
  width: 100%;
  line-height: 1.75;
  & span.bold {
    font-weight: bold;
  };
  & span.subject {
    min-width: 64px
    text-align: right;
  };
  &>div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
