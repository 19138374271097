import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const FixedMenu = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  overflow: hidden;
  z-index: 99;
  box-shadow: 0px 1px 3px 0px rgba(209, 211, 212, 1);
  border-top: 1px solid rgba(208, 211, 212, 0.55);
  background: rgba(255, 255, 255, 0.95);
  & button {
    border-radius: 0 !important;
    margin-bottom: -1px !important;
  }
  & .ui.attached.buttons {
    margin-left: -1px !important;
    margin-right: -2px !important;
  }
`;
const PhotoBottomMenu = ({
  onLeftButton,
  onRightButton,
  leftLabel,
  leftIcon,
  rightLabel,
  rightIcon,
  rightdisabled,
}) => (
  <FixedMenu>
    <Button.Group attached="bottom" widths={2} style={{ fontSize: '0.875rem' }}>
      <Button
        name="allcheck"
        onClick={onLeftButton}
        style={{ padding: '1rem 0.875rem' }}
        color="blue"
        basic
      >
        <Icon name={leftIcon} />
        {leftLabel}
      </Button>
      <Button
        name="remove"
        onClick={onRightButton}
        style={{ padding: '1rem 0.875rem' }}
        color="blue"
        disabled={rightdisabled}
        basic
      >
        <Icon name={rightIcon} />
        {rightLabel}
      </Button>
    </Button.Group>
  </FixedMenu>
);

export default PhotoBottomMenu;
