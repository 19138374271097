import React, { useRef } from 'react';
// import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styled from 'styled-components';

const AnimationWrap = styled.div`
  display: block;
  width: 100vw;
  height: 100%;
  position: relative;
  max-width: 100%;
  background-color: transparent;
`;

const Animation = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  animation: ${({ sequence, time, repeat }) =>
    `${sequence} ${time}ms ${repeat} forwards`};
  transition: all 1s ease-out;
`;

const PiboAnimation = ({ animation, onAnimationEnd }) => {
  const nodeRef = useRef();
  return (
    <>
      {/* <SwitchTransition mode="out-in">
        <CSSTransition
          key={show}
          nodeRef={nodeRef}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="sequence-fade"
        > */}
      <AnimationWrap nodeRef={nodeRef}>
        <Animation {...{ ...animation, onAnimationEnd }} />
      </AnimationWrap>
      {/* </CSSTransition>
      </SwitchTransition> */}
      {[
        // 'seq_3_0',
        // 'seq_3_1',
        // 'seq_3_2',
        // 'seq_3_3',
        // 'seq_3_4',
        // 'seq_3_5',
        // 'seq_4_0',
        // 'seq_4_1',
        // 'seq_4_2',
        // 'seq_4_3',
        // 'seq_4_4',
        // 'seq_4_5',
        // 'seq_4_6',
        // 'seq_4_7',
        // 'seq_4_8',
        // 'seq_4_9',
        // 'seq_4_10',
        'seq_6_0',
        'seq_6_1',
        'seq_6_2',
        'seq_6_3',
        'seq_6_4',
        'seq_6_5',
        'seq_6_6',
        'seq_7_0',
        'seq_7_1',
        'seq_7_2',
        'seq_7_3',
        'seq_7_4',
        'seq_7_5',
        'seq_7_6',
        'seq_8_0',
        'seq_8_1',
        'seq_8_2',
        'seq_8_3',
        'seq_8_4',
        'seq_8_5',
        'seq_9_0',
        'seq_9_1',
        'seq_9_2',
        'seq_9_3',
        'seq_9_4',
        'seq_9_5',
        'seq_9_6',
        'seq_9_7',
        'seq_9_8',
      ].map(name => (
        <div key={name} className={name} />
      ))}
    </>
  );
};

export default PiboAnimation;
