import React, { useRef, useState } from 'react';
import { useLastLocation } from 'react-router-dom-last-location';
import { useLifecycles, useUpdateEffect } from 'react-use';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import HistoryList from '../../../components/bots/HistoryBot/HistoryList';
import InputDialog from '../../../components/bots/HistoryBot/InputDialog';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import { getAlive, receivePiboEvent, speakPibo } from '../../../pibo';
import {
  attachHistory,
  getHistory,
  getInitData,
  init,
  sendFailure,
  sendPending,
  updateHistoryItem,
} from '../../../ducks/bots/historyBot/history';
import { getRobotId, getUserId } from '../../../utils/common';
import i18n from '../../../lang/i18n';

const actualHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.documentElement.getElementsByTagName('body')[0].clientHeight;

let timer = null;

const HistoryMain = ({ title }) => {
  const { lastLocation } = useLastLocation();
  const dispatch = useDispatch();
  const timelineRef = useRef();
  const [backLocation, setBackLocation] = useState('');
  const {
    sendHistoryPending,
    message: msg,
    initDataStatus,
    bBottom,
  } = useSelector(state => state.history);

  const handleReceive = params => {
    if (params) {
      const { q, a, date, data, bot } = params;
      const firstTime = moment(date);
      // receivePiboEvent('talk', handleReceive);
      // timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
      return dispatch(
        updateHistoryItem({
          status: 200,
          data: {
            userId: getUserId(),
            robotId: getRobotId(),
            q,
            a,
            firstTime,
            data,
            bot,
          },
        }),
      );
    }
    return dispatch(
      sendFailure({ sendHsitoryError: i18n.t('bot:HST.SEND.FAILED') }),
    );
  };

  const sendDialog = message => {
    if (getAlive()) {
      dispatch(sendPending({ message }));
    }
    // 입력 할 때, 콜백이 두 번 발생하는 문제 방지를 위해 걸어둔 콜백 지움
    receivePiboEvent('talk', null);
    const status = speakPibo(message, handleReceive);
    if (status) {
      timer = setTimeout(() => {
        if (sendHistoryPending || msg) {
          timer = null;
          receivePiboEvent('talk', handleReceive);
          dispatch(
            sendFailure({
              sendHistoryError: i18n.t('bot:HST.SEND.FAILED'),
              message: msg,
            }),
          );
        }
      }, 10000);
    }
  };

  useLifecycles(
    () => {
      if (lastLocation && 'pathname' in lastLocation) {
        setBackLocation(lastLocation.pathname);
      }
      receivePiboEvent('talk', handleReceive);
      if (!initDataStatus) {
        dispatch(getInitData());
        dispatch(getHistory({ robotId: getRobotId() }));
      } else {
        console.log('call attach');
        dispatch(attachHistory({ robotId: getRobotId() }));
      }
    },
    () => {
      if (timer) {
        clearTimeout(timer);
      }
      return receivePiboEvent('talk', null);
    },
  );

  useUpdateEffect(() => {
    // console.log('bBottom', timelineRef.current.scrollHeight, timelineRef.current.scrollTop, timelineRef.current.clientHeight);
    timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
  }, [bBottom]);

  return (
    <>
      <BotTopMenu
        title={title}
        menuMode="WITH_CLOSE"
        backLocation={backLocation}
        onClose={init}
      />
      <HistoryList
        timelineRef={timelineRef}
        style={{
          height: `${actualHeight - 60}px`,
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
          width: '100%',
          background: '#fbfbfb',
        }}
      />
      <InputDialog icon="send" {...{ icon: 'send', sendDialog }} />
    </>
  );
};

export default HistoryMain;
