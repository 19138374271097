import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useToggle } from 'react-use';
import {
  LoginForm,
  LoginWrap,
  JoinWrap,
  LoginInputWrap,
  LoginInput,
  LoginOptionWrap,
  LoginButton,
  JoinButton,
  LoginHeader,
  LoginMessage,
  SkipButton,
  FindButton,
  GoogleLogin,
  KakaoLogin,
  AppleLogin,
  LoginOptionButton,
} from './Components';
import {
  setLocalStorage,
  getLoginId,
  removeLocalStorage,
  detectOS,
  OS_IOS,
  OS_AND,
  // detectOS,
} from '../../utils/common';
import { Divider } from '../Components';

const Login = props => {
  const { t } = useTranslation('user');
  const {
    onChange,
    onLogin,
    onSocialSign,
    onJoin,
    onFind,
    onSkip,
    input: { id, pw },
    msg,
  } = props;
  const [bSaveId, setSaveId] = useState(!!getLoginId());
  const [bLogOn, setSaveLogin] = useToggle(false);

  const onSaveIdToggle = () => {
    if (bSaveId) {
      removeLocalStorage('loginId');
    } else {
      setLocalStorage('loginId', id);
    }
    setSaveId(!bSaveId);
  };

  const handleLogin = () => {
    if (bSaveId) {
      setLocalStorage('loginId', id);
    }
    onLogin(bLogOn);
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      handleLogin();
    }
  };

  const handleSuccess = data => {
    onSocialSign(data);
  };

  return (
    <LoginForm>
      <LoginWrap>
        <LoginHeader as="h4" textAlign="center">
          {t('SIGN_IN.MSG.BY_ACCOUNT')}
        </LoginHeader>
        <LoginInputWrap>
          <LoginInput
            placeholder={t('ID.LB')}
            name="userid"
            value={id}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
          <LoginInput
            placeholder={t('PW.LB')}
            type="password"
            name="password"
            value={pw}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </LoginInputWrap>
        {msg && <LoginMessage>{msg}</LoginMessage>}
        <LoginOptionWrap>
          <LoginOptionButton onClick={() => setSaveLogin(!bLogOn)}>
            <Icon name={bLogOn ? 'check circle' : 'circle outline'} />
            로그인 상태 유지
          </LoginOptionButton>
          <LoginOptionButton onClick={onSaveIdToggle}>
            <Icon name={bSaveId ? 'check circle' : 'circle outline'} />
            {t('ID.SAVE')}
          </LoginOptionButton>
        </LoginOptionWrap>
        <LoginButton fluid size="large" onClick={handleLogin}>
          {t('SIGN_IN.LB')}
        </LoginButton>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            height: '3rem',
          }}
        >
          <FindButton labelPosition="left" onClick={onFind}>
            <Icon name="search" />
            {t('FIND.ID_PW')}
          </FindButton>
          <SkipButton labelPosition="right" onClick={onSkip}>
            {t('SKIP')}
            <Icon name="angle right" />
          </SkipButton>
        </div>
      </LoginWrap>
      <JoinWrap>
        <JoinButton fluid size="large" onClick={onJoin}>
          {t('SIGN_UP.LB')}
        </JoinButton>
        {[OS_AND, OS_IOS].indexOf(detectOS()) > -1 && (
          <div style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                padding: '0',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.875rem',
                alignSelf: 'stretch',
                fontSize: '0.75rem',
                height: '3rem',
              }}
            >
              <Divider />
              또는
              <Divider />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '0.75rem',
                alignSelf: 'stretch',
              }}
            >
              <GoogleLogin onSuccess={handleSuccess} />
              <KakaoLogin onSuccess={handleSuccess} />
              {detectOS() === OS_IOS && (
                <AppleLogin onSuccess={handleSuccess} />
              )}
              {/* {detectOS() !== 'iOS' && <KakaoLogin onSuccess={handleSuccess} />} */}
            </div>
          </div>
        )}
      </JoinWrap>
    </LoginForm>
  );
};

export default Login;
