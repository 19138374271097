import React from 'react';

import { LinkDescription } from '../../components/link/Components';
import PiboGeo from '../../components/pibo/settings/PiboGeo';

const LinkStep2 = props => {
  // t props에서 받아서 사용
  const { t, loading, error, data, handleChange } = props;

  const handleGeoChange = v => {
    handleChange(v.value);
  };

  return (
    <div style={{ width: '100%' }}>
      <LinkDescription
        header={t('conn:MSG.LINK_DESCRIPTION_2.HEADER')}
        text={t('conn:MSG.LINK_DESCRIPTION_2.TEXT')}
      />
      <PiboGeo
        columnswidth={16}
        loading={loading}
        error={error}
        value={data}
        handleChange={handleGeoChange}
      />
    </div>
  );
};

export default LinkStep2;
