import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, useLocation, useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { useLastLocation } from 'react-router-dom-last-location';
import * as Scroll from 'react-scroll';
import StoreMain from '../../components/botstore/StoreMain';
import { getRobotId } from '../../utils/common';
import withAuth from '../../hocs/withAuth';
import { autoSearch, fetchStoreList } from '../../ducks/store';

const BotstorePage = props => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { lastLocation } = useLastLocation();
  const dispatch = useDispatch();
  const { scroll } = useSelector(state => state.page);

  useDebounce(
    () => {
      if (scroll) {
        dispatch(fetchStoreList({ robotId: getRobotId() }));
      }
    },
    200,
    [scroll],
  );

  useMount(() => {
    if (lastLocation) {
      const { pathname: lastPath } = lastLocation;
      if (lastPath.indexOf(pathname) !== 0) {
        Scroll.animateScroll.scrollToTop({ duration: 0, smooth: true });
      } else {
        const id = lastPath.slice(pathname.length + 1, lastPath.length);
        Scroll.scroller.scrollTo(id, {
          offset: document.body.clientHeight * -0.45,
        });
      }
    }
  });

  const handleItemClick = botId => navigate(`/botstore/${botId}`);
  const handleOpenClick = url => navigate(url);
  const addSearchChange = value =>
    dispatch(autoSearch({ robotId: getRobotId(), value }));

  return (
    <StoreMain
      {...props}
      {...{
        addSearchChange,
        handleItemClick,
        handleOpenClick,
      }}
    />
  );
};

export default withAuth(BotstorePage);
// 로그인 여부 따로 체크하지 않음
// botstore에 올라와 있는 모든 bot 리스트 가져오기
// 광고할 bot 배너 이미지와 링크 리스트 (2, 3개)
// bot 리스트 아이템의 정보 (아이템id, 제목, 썸네일, 평점, 평가개수, 명령어 || 상세설명)
