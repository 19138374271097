import { API_URL } from '../../../circulusConfig';
import { encodeGetParams } from '../../../utils/api';

export async function getBotDataApi(data) {
  const res = await fetch(
    `${API_URL}bot/getBotData?${encodeGetParams({ ...data })}`,
  );
  const result = res.json();
  return result;
}
