import React from 'react';
import { Button } from 'semantic-ui-react';
import { AccountButton } from '../../components/account/AccountComponents';
import withLoading from '../../hocs/withLoading';
import LinkStep1 from './LinkStep1';
import LinkStep2 from './LinkStep2';
import LinkStep3 from './LinkStep3';
import LinkStep4 from './LinkStep4';
import LinkStep5 from './LinkStep5';

const LinkContainer = ({
  activeIndex,
  t,
  focusNext,
  handleChange,
  error,
  loading,
  data,
  steps,
  btn,
  setButtonRef,
  handleNext,
  handlePrev,
}) => (
  <div
    style={
      activeIndex < steps.length
        ? { padding: '1.5rem 1.5rem 60px', overflow: 'auto', height: '100%' }
        : { height: 'calc(100vh - 158px)', overflowX: 'hidden' }
    }
  >
    {activeIndex === 0 && (
      <LinkStep1
        {...{
          t,
          focusNext,
          handleChange,
          error,
          loading,
          ...data[activeIndex],
        }}
      />
    )}
    {activeIndex === 1 && (
      <LinkStep2
        {...{
          t,
          focusNext,
          handleChange,
          error,
          loading,
          data: data[activeIndex],
        }}
      />
    )}
    {activeIndex === 2 && (
      <LinkStep3
        {...{
          t,
          focusNext,
          handleChange,
          error,
          loading,
          data: data[activeIndex],
        }}
      />
    )}
    {activeIndex === 3 && (
      <LinkStep4
        {...{
          t,
          focusNext,
          handleChange,
          error,
          loading,
          data: data[activeIndex],
        }}
      />
    )}
    {activeIndex === steps.length && <LinkStep5 t={t} />}
    <AccountButton widths="2" fluid attached="bottom">
      {btn.map(({ label, ...rest }, i) => (
        <Button
          {...rest}
          onClick={i === 0 ? handleNext : handlePrev}
          ref={setButtonRef(i)}
        >
          {label}
        </Button>
      ))}
    </AccountButton>
  </div>
);

export default withLoading(LinkContainer);
