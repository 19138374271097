import * as moment from 'moment';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18 from '../../../lang/i18n';
import {
  setCalendarApi,
  getMonthDataApi,
  deleteItemsApi,
} from './calendarBotApi';
import { getRobotId } from '../../../utils/common';

const name = 'calendarBot';

const selectSchedule = (date) => ({
  title: '',
  execTime: moment(`${date.format('YYYY-MM-DD')} ${date.format('HH:mm')}`),
  command: '',
  repeatValue: { type: 'none', value: { date: date.date(), day: [], month: date.month() } },
  repeat: false,
});

const initialState = {
  loading: false,
  deleting: false,
  adding: false,
  error: '',
  deleteError: '',
  selectError: '',
  addError: '',
  selectedDate: moment(0, 'HH').unix() * 1000,
  scheduleData: [],
  selectSchedule: selectSchedule(moment()),
  getDefaultDataStatus: false,
};

const getMonthData = async selectedDate => {
  const date = moment(new Date(parseInt(selectedDate, 10)));
  const startDate = date.startOf('month').unix() * 1000;
  const endDate = date.endOf('month').unix() * 1000;
  try {
    const { result, monthData } = await getMonthDataApi(
      startDate,
      endDate,
      getRobotId(),
    );
    if (result) {
      return { result: true, monthData };
    }
    return { result: false, error: i18.t('bot:CAL.LOAD.ERROR') };
  } catch (error) {
    return { result: false, error };
  }
};

const findAsync = (arr, callback) =>
  new Promise(async response => {
    const pro = () =>
      new Promise(res => {
        res(arr.find(callback));
      });
    const result = await pro();
    response(result);
  });

export const createCalendar = createAsyncThunk(
  `${name}/CREATE`,
  async (schedule, { rejectWithValue }) => {
    try {
      const { result } = await setCalendarApi(schedule);
      if (result) {
        return true;
      }
      return rejectWithValue(i18.t('bot:CAL.ADD.ERROR'));
    } catch (error) {
      return rejectWithValue(`${i18.t('bot:CAL.ADD.ERROR')} ${error.message}`);
    }
  }
);

export const deleteCalendar = createAsyncThunk(
  `${name}/DELETE`,
  async ({id, all}, { getState, rejectWithValue }) => {
    let exception;
    if (!all) {
      const { selectedDate } = getState().calendarBot;
      exception = moment(selectedDate).format('YYYY.M.D');
    }
    try {
      const { result } = await deleteItemsApi({ id, all, exception });
      if (result) {
        return true;
      }
      return rejectWithValue(all? i18.t('bot:CAL.DELETE.FAILED'): i18.t('bot:CAL.DELETE.FAILED_ITEM'));
    } catch (error) {
      return rejectWithValue(`${i18.t('bot:CAL.DELETE.ERROR')} ${error.message}`);
    }
  }
);

export const readDate = createAsyncThunk(
  `${name}/READ_DATE`,
  async (newSelectDate, { rejectWithValue }) => {
    const { result, monthData, error } = await getMonthData(newSelectDate);
    if (result) {
      return monthData;
    }
    return rejectWithValue(`${i18.t('bot:CAL.LOAD.FAILED_DATE')} ${error.message}`);
  }
);

export const getSchedule = createAsyncThunk(
  `${name}/GET_SCHEDULE`,
  async (_undefined, { getState, rejectWithValue }) => {
    const { selectedDate } = getState().calendarBot;
    const { result, monthData, error } = await getMonthData(selectedDate);
    if (result) {
      return monthData;
    }
    return rejectWithValue(`${i18.t('bot:CAL.LOAD.ERROR')} ${error.message}`);
  }
);

export const getScheduleItem = createAsyncThunk(
  `${name}/GET_SCHEDULE_ITEM`,
  async ({id, date: propDate}, { getState, rejectWithValue }) => {
    if (!id)
      return rejectWithValue(i18.t('bot:CAL.LOAD.FAILED_ITEM'));
      
    const { date } = getState().calendarBot;
    let { scheduleData } = getState().calendarBot;
    if (scheduleData.length === 0 && (date || propDate)) {
      const { result, monthData, error } = await getMonthData(date || propDate);
      if (!result || monthData.length === 0) {
        return rejectWithValue(`${i18.t('bot:CAL.LOAD.FAILED_ITEM')} ${error}`);
      }
      if(!date && propDate) {
        scheduleData = monthData;
      }
    } 
    // eslint-disable-next-line max-len
    const selectDateData = await findAsync(scheduleData, item =>
      item.find(({ _id: itemId }) => id === itemId),
    );
    if (selectDateData) {
      const selectData = await findAsync(
        selectDateData,
        ({ _id: itemId }) => id === itemId,
      );
      if (selectData && '_id' in selectData) {
        return { selectSchedule: selectData, scheduleData };
      }
    }
    return rejectWithValue(i18.t('bot:CAL.LOAD.FAILED_ITEM'));
  }
);

const calendarBotSlice = createSlice({
  name,
  initialState,
  reducers: {
    selectDate: (state, action) => ({ ...state, loading: false, selectedDate: action.payload.date, selectSchedule: action.payload.schedule }),
    initaialScheduleItem: (state) => ({ ...state, selectSchedule: {...selectSchedule(moment(state.selectedDate))} }),
    infoError: (state, action) => ({ ...state, loading: false, selectError: action.payload}),
  },
  extraReducers: {
    [createCalendar.pending.type]: (state) => ({...state, addError: '', adding: true,}),
    [createCalendar.fulfilled.type]: (state) => ({...state, addError: '', adding: false, }),
    [createCalendar.rejected.type]: (state, action) => ({...state, addError: action.payload, adding: false,}),
    [deleteCalendar.pending.type]: (state) => ({...state, deleteError: '', deleting: true,}),
    [deleteCalendar.fulfilled.type]: (state) => ({ ...state, deleteError: '', deleting: false }),
    [deleteCalendar.rejected.type]: (state, action) => ({...state, deleteError: action.payload, deleting: false,}),
    [readDate.fulfilled.type]: (state, action) => ({ ...state, selectError: '', error: '', loading: false, scheduleData: action.payload, }),
    [readDate.rejected.type]: (state, action) => ({...state, loading: false, error: action.payload, }),
    [getSchedule.pending.type]: (state) => ({...state, error: '', loading: true, refresh: false, getDefaultDataStatus: true}),
    [getSchedule.fulfilled.type]: (state, action) => ({ ...state, selectError: '', error: '', loading: false, scheduleData: action.payload, }),
    [getSchedule.rejected.type]: (state, action) => ({ ...state, error: action.error, loading: false}),
    [getScheduleItem.pending.type]: (state) => ({...state, error: '', loading: true, selectError: '', selectSchedule: { ...initialState.selectSchedule }}),
    [getScheduleItem.fulfilled.type]: (state, action) => ({ ...state, error: '', loading: false, selectError: '', ...action.payload }),
    [getScheduleItem.rejected.type]: (state, action) => ({ ...state, error: action.payload, loading: false}),
  }
});

const { reducer: calendarReducer, actions } = calendarBotSlice;
export const { selectDate, initaialScheduleItem } = actions;
const { infoError } = actions;

export const getDayInfo = ({ day, month }) => (dispatch, getState) => {
  let newSelectDate;
  let needRefresh = false;
    if (day) {
      newSelectDate = day;
      needRefresh = !month;
    } else if (month) {
      const { selectedDate } = getState().calendarBot;
      newSelectDate = moment(selectedDate).add(month, 'month');
      needRefresh = true;
    } else {
      return dispatch(infoError(i18.t('bot:CAL.LOAD.FAILED_DATE')));
    }

    dispatch(selectDate({date: newSelectDate.unix() * 1000, schedule: selectSchedule(newSelectDate)}));

    if(needRefresh) {
      return dispatch(readDate(newSelectDate.unix() * 1000));
    }

    return dispatch(infoError(i18.t('bot:CAL.LOAD.FAILED_DATE')));
};

export default calendarReducer;