import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUpdateEffect } from 'react-use';
import { Dimmer } from 'semantic-ui-react';
import styled from 'styled-components';
import StoreMenu from '../../components/botstore/StoreMenu';
import { fetchUserInfo } from '../../ducks/store';
import { getUserId } from '../../utils/common';

const MenuDimmerWrap = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 100;
  overflow: unset;
  display: ${({ visible }) => visible ? 'block' : 'none' };
  transition: all .1s ease;
`;

const MenuDimmer = styled(Dimmer)`
&&&{
  width: 100vw;
  height: 100vh;
  z-index: 101 !important;
  backdrop-filter: blur(5px);
  opacity: ${({ active }) => active ? 1 : 0};
  transition: all 1s ease .1s;
  background: rgba(0,0,0, .4);
  }
`;

const StoreMenuContainer = props => {
  const {
    visible,
    handleMyBotClick,
    handleSidebarHide,
  } = props;
  const { user } = useSelector(state => state.store);
  const dispatch = useDispatch();
  const [show, setToggle] = useState(visible);

  useMount(() => {
    dispatch(fetchUserInfo(getUserId()));
  });

  useUpdateEffect(() => {
    setToggle(visible);
  }, [visible])

  return (
    <MenuDimmerWrap visible={visible}>
      <MenuDimmer active={show} />
      <StoreMenu
        visible={show}
        user={user}
        handleSidebarHide={handleSidebarHide}
        handleMyBotClick={handleMyBotClick}
      />
    </MenuDimmerWrap>
  );
};

export default StoreMenuContainer;
