import React, { useEffect, useState } from 'react';
import { useUnmount } from 'react-use';
import { useTranslation } from 'react-i18next';

import Move from '../../../components/bots/AvatarBot/Move';
import Dance from '../../../components/bots/AvatarBot/Dance';
import { FixedBottomButton } from '../../../components/Components';
import { killPibo, motionPibo } from '../../../pibo';

const initialState = {
  forward: false,
  left: false,
  right: false,
  backward: false,
  danceSelected: '',
};

let timer = null;

const MotionContainer = ({ standalone }) => {
  const [stopEnabled, setPlay] = useState(false);
  const [status, setStatus] = useState({ ...initialState });
  const { forward, left, right, backward, danceSelected } = status;
  const { t } = useTranslation('bot');

  const handleDanceAndMotionChange = (e, { value }) => {
    if (danceSelected) return;
    const result = motionPibo(value, 1);
    if (result) {
      setStatus({ ...status, danceSelected: value });
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        setStatus({ ...initialState });
      }, 5000);
    }
  };

  const onControllerHandler = direction => {
    if (forward || left || right || backward) return;
    const result = motionPibo(direction, 4);
    if (result) {
      setStatus({
        ...initialState,
        [direction]: true,
      });

      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(() => {
        setStatus({ ...initialState });
      }, 10000);
    }
  };

  const handleStopClick = () => {
    killPibo();
    setStatus({ ...initialState });
  };

  useEffect(() => {
    let stop = false;
    if (forward || backward || left || right || danceSelected) {
      stop = true;
    } else {
      setStatus({ ...initialState });
    }
    setPlay(stop);
  }, [stopEnabled, forward, backward, left, right, danceSelected]);

  useUnmount(() => {
    if (timer) clearTimeout(timer);
    handleStopClick();
  });

  return (
    <div>
      <Move
        preventState={forward || left || right || backward || stopEnabled}
        forward={forward}
        backward={backward}
        left={left}
        right={right}
        onControllerHandler={onControllerHandler}
      />
      <Dance
        selected={danceSelected}
        disabled={stopEnabled}
        onChange={handleDanceAndMotionChange}
        onControllerHandler={onControllerHandler}
      />
      <FixedBottomButton
        name="stop"
        type="button"
        style={{ margin: '0 -1rem' }}
        disabled={!stopEnabled}
        onClick={handleStopClick}
        standalone={standalone}
      >
        {t('AVA.STOP')}
      </FixedBottomButton>
    </div>
  );
};

export default MotionContainer;
