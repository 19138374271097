import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Image } from 'semantic-ui-react';
import shortId from 'shortid';
import { withLastLocation } from 'react-router-dom-last-location';
import { useTranslation } from 'react-i18next';
import { useLifecycles } from 'react-use';

import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import {
  getBotData,
  initialize,
} from '../../../ducks/bots/defaultPage/defaultPage';
import Loading from '../../../components/Loading';
import { errorLog } from '../../../utils/report';
import { speakPibo } from '../../../pibo';
import { getFile } from '../../../utils/api';
import { parseBotDescription } from '../../../utils/common';

const DefaultPage = props => {
  const { t } = useTranslation('bot');
  const dispatch = useDispatch();
  const { lastLocation, message, botId } = props;
  const defaultpage = useSelector(state => state.defaultPage);
  const {
    botData: { title, description, projectId, example, source },
    pending: loading,
  } = defaultpage;
  const [backLocation, setBack] = useState('');

  useLifecycles(
    async () => {
      if (lastLocation && 'pathname' in lastLocation) {
        setBack(lastLocation.pathname);
      }
      if (!message) {
        try {
          dispatch(getBotData(botId));
        } catch (e) {
          errorLog(e, 'DefaultPage>getBotData');
        }
      }
    },
    () => {
      dispatch(initialize());
    },
  );

  const exampleHandler = exam => speakPibo(exam);

  if (loading) return <Loading />;
  if (message && message.length > 0) return <h4>{message}</h4>;
  if (!projectId) return <h4>{t('MSG.UNVALID')}</h4>;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: 'inherit',
        opacity: 1,
      }}
    >
      <div
        style={{
          width: '100%',
          flex: 'none',
          height: '60px',
          position: 'relative',
        }}
      >
        <BotTopMenu
          title={title}
          menuMode="WITH_CLOSE"
          backLocation={backLocation}
        />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flex: '1 1 0%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '1rem',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h4
          style={{
            margin: '0 1rem 1.5rem',
            textAlign: 'center',
          }}
        >
          {parseBotDescription(description)}
        </h4>
        <Image
          src={getFile({ icon: botId })}
          style={{
            margin: '0 1rem 1.5rem',
            borderRadius: '10px',
            boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.16)',
            maxWidth: '128px',
          }}
        />
        <div
          style={{
            margin: '0 1rem 1.5rem',
            background: '#fff',
            width: '100%',
            padding: '1rem',
            textAlign: 'center',
          }}
        >
          <h4>{t('MSG.DIRECTION')}</h4>
          {example.map(e => (
            <p
              style={{ cursor: 'pointer', color: '#0894D7' }}
              key={shortId.generate()}
              onClick={() => exampleHandler(e)}
              role="presentation"
            >
              {e}
            </p>
          ))}
        </div>
        {source && (
          <div>
            {source.split('<>').map(item => (
              <p
                style={{ fontSize: '0.625rem', color: '#d1d3d4' }}
                key={shortId.generate()}
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default withLastLocation(DefaultPage);
