/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { Icon } from 'semantic-ui-react';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import {
  TrainList,
  TrainListError,
} from '../../../components/bots/TrainBot/Components';
import { FixedBottomButton, Page } from '../../../components/Components';
import {
  fetchTrains,
  onDelete,
  setChecked,
  setToggle,
  onPreviewClick,
  initForm,
} from '../../../ducks/bots/trainBot/train';
import { getRobotId } from '../../../utils/common';

let load = false;
const TrainListContainer = props => {
  const { t } = useTranslation('bot');
  const dispatch = useDispatch();
  const { list, total, toggle, error, play, path, pending } = useSelector(
    state => state.train,
  );
  const navigate = useNavigate();

  const handleFetch = (more = false) => {
    dispatch(fetchTrains({ robotId: getRobotId(), more }));
  };

  const handleSelect = checked => {
    dispatch(setChecked(checked));
  };

  const handleToggleList = () => {
    if (toggle) {
      handleSelect([]);
      handleFetch();
    }
    dispatch(setToggle(!toggle));
  };

  const handleItemClick = id => {
    navigate(`${path}/update?id=${id}`);
  };

  const handleTrainClick = () => {
    // 훈련 시키기로 이동
    dispatch(initForm());
    navigate(`${path}/add`);
  };

  const handleDelete = () => {
    dispatch(onDelete());
  };

  const handlePreviewClick = (id, title) => {
    dispatch(onPreviewClick(id, title));
  };

  useMount(() => {
    if (!load) {
      handleFetch();
      load = true;
    }
  });

  return (
    <Page>
      <BotTopMenu
        {...{
          title: props.title,
          menuMode: 'WITH_CLOSE',
          backLocation: props.backLocation,
        }}
      />
      {!pending && error && <TrainListError>{error}</TrainListError>}
      {!pending && !error && (
        <>
          <TrainList
            list={list}
            play={play}
            total={total}
            toggle={toggle}
            bPreview={play}
            onDelete={handleDelete}
            onSelect={handleSelect}
            onToggle={handleToggleList}
            onItemClick={handleItemClick}
            onPreviewClick={handlePreviewClick}
            onMore={() => handleFetch(true)}
          />
          <FixedBottomButton inverted onClick={handleTrainClick}>
            <Icon name="pencil" />
            {t('TRN.LB.TRAIN')}
          </FixedBottomButton>
        </>
      )}
    </Page>
  );
};

export default TrainListContainer;
