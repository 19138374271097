import moment from 'moment';
import React from 'react';
import { Header } from 'semantic-ui-react';
import { getFile } from '../../../utils/api';
import { Player } from '../DiaryBot/DateCard';

const DiaryCard = ({ firstTime: date, fileId: diary }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
      gap: '.5rem',
      justifyContent: 'space-between',
    }}
  >
    <div style={{ display: 'flex', gap: '.5rem', alignItems: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          alignItems: 'center',
          padding: '.25rem 12px',
          position: 'relative',
          height: '40px',
          width: '40px',
        }}
      >
        <Player url={getFile({ diary })} setError={e => console.log(e)} />
      </div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          alignItems: 'baseline',
          lineHeight: 1.1,
        }}
      >
        <Header as="h5" style={{ margin: 0 }}>
          {moment(date).format('LL LT')}
        </Header>
      </div>
    </div>
  </div>
);

export default DiaryCard;
