import React, { useState } from 'react';
import { Card, Loader, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMount, useUpdateEffect } from 'react-use';
import StoreSearch from './StoreSearch';
import {
  StoreWrap,
  StoreRow,
  StoreColumn,
  StoreCard,
  StoreCardHeader,
  StoreCardButton,
} from './StoreComponents';
import { BotImage } from '../Components';

const StoreMyBot = props => {
  const { t } = useTranslation('store');
  const {
    bots: botList,
    searching,
    searchStr,
    errorMsg,
    resultSelect,
    addSearchChange,
    handleItemClick: itemClickHandler,
    handleBtnClick,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [searchResult, setResult] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState('');
  // const [searchResult, setResults] = useState([]);
  const [bots, setBots] = useState([]);

  const handleSearchRemove = () => {
    setLoading(false);
    setValue('');
    addSearchChange('');
    setResult(false);
  };

  useMount(() => {
    // setResults(results);
    setBots(botList);
    setLoading(searching);
    setValue(searchStr);
  });

  useUpdateEffect(() => {
    setBots(botList);
    setResult(botList.length > 0);
  }, [botList]);

  useUpdateEffect(() => {
    if (!searchStr) {
      handleSearchRemove();
    }
  }, [searchStr]);

  useUpdateEffect(() => {
    if (error !== errorMsg) {
      setError(error);
    }
  }, [errorMsg]);

  useUpdateEffect(() => {
    if (isLoading !== searching) {
      setLoading(searching);
    }
  }, [searching]);

  const handleSearchResult = () => {
    resultSelect();
  };

  const handleSearchChange = (e, { value: v }) => {
    if (v !== value) {
      setValue(v);
      setTimeout(() => {
        if (!isLoading) {
          addSearchChange(v);
        }
      }, 500);
    }
  };

  const handleItemClick = id => e => {
    const targetName = e.target.name;
    if (!targetName || targetName.search('btn') < 0) {
      itemClickHandler(id);
    }
  };

  return (
    <StoreWrap>
      <StoreRow>
        <StoreColumn style={{ margin: 0, width: '100%' }}>
          <StoreSearch
            {...{ isLoading, value, searchResult }}
            handleSearchChange={handleSearchChange}
            handleSearchResult={handleSearchResult}
            handleSearchRemove={handleSearchRemove}
            fluid
          />
        </StoreColumn>
      </StoreRow>
      <StoreRow>
        <StoreColumn>
          <Loader active={isLoading} />
          <StoreWrap>
            <StoreRow>
              {errorMsg && <div style={{ padding: '1rem' }}>{errorMsg}</div>}
              {!errorMsg &&
                bots.map(
                  ({
                    id,
                    botId,
                    title,
                    projectName,
                    projectId,
                    use,
                    installing,
                    userId,
                    isDefault,
                  }) => (
                    <StoreCard
                      key={id}
                      id={botId}
                      name="card"
                      fluid="true"
                      style={{ margin: '0 0 1px' }}
                      disabled={installing}
                      onClick={handleItemClick(botId)}
                    >
                      <Card.Content>
                        <BotImage {...{ id: botId, style: 'item' }} />
                        <StoreCardHeader>{title}</StoreCardHeader>
                        {/* <Card.Content style={{ padding: '0.75rem 0' }}>
                        {projectId}
                      </Card.Content> */}
                        {use && (
                          <StoreCardButton
                            name="btn_open"
                            basic
                            compact
                            floated="right"
                            color="blue"
                            disabled={installing}
                            onClick={() =>
                              handleBtnClick('open', { projectId, botId })
                            }
                          >
                            {t('OPEN')}
                          </StoreCardButton>
                        )}
                        {!isDefault && (
                          <StoreCardButton
                            name="btn_remove"
                            basic
                            compact
                            floated="right"
                            color="red"
                            disabled={installing}
                            onClick={() =>
                              handleBtnClick('remove', {
                                botId,
                                projectId,
                                projectName,
                                userId,
                                title,
                              })
                            }
                          >
                            {installing && <Icon name="spinner" loading />}
                            {!installing && t('UNINSTALL')}
                          </StoreCardButton>
                        )}
                      </Card.Content>
                    </StoreCard>
                  ),
                )}
            </StoreRow>
          </StoreWrap>
        </StoreColumn>
      </StoreRow>
      <StoreRow style={{ height: '65px' }} />
    </StoreWrap>
  );
};

export default StoreMyBot;
