import React from 'react';
import { Header, Image, Label } from 'semantic-ui-react';

const CATEGORY_COLOR = {
  자연관광지: 'green',
  관광자원지: 'blue',
  관광자원: 'blue',
  역사관광지: 'purple',
  휴양관광지: 'teal',
  체험관광지: 'orange',
  산업관광지: 'grey',
  건축물: 'yellow',
  문화시설: 'olive',
  맛집: 'pink',
  축제: 'violet',
  행사: 'violet',
};

const TravelCard = data => {
  const { area, category, list: l } = data;
  const list = Array.isArray(l) ? l : [l];
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          gap: '.25rem',
          marginBottom: '.5rem',
          alignItems: 'center',
        }}
      >
        <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
          {area}
        </Header>
        {category && (
          <Label
            color={CATEGORY_COLOR[category]}
            size="mini"
            style={{
              borderRadius: '1rem',
              textAlign: 'center',
              maxWidth: '55px',
              maxHeight: '22px',
            }}
          >
            {category.replace('관광지', '')}
          </Label>
        )}
      </div>
      <div style={{ display: 'flex', gap: '.5rem', flexFlow: 'column' }}>
        {list.length > 0 &&
          list.map(({ category: cat, name, address, img }) => (
            <div
              style={{
                display: 'flex',
                flexFlow: 'wrap',
                alignItems: 'flex-start',
                gap: '.5rem',
              }}
              key={address}
            >
              <span>-</span>
              {img && (
                <Image
                  src={img}
                  style={{
                    maxWidth: '40%',
                    flex: '0 1 40%',
                    borderRadius: '4px',
                  }}
                />
              )}
              <div style={{ flex: '1 0' }}>
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row',
                    gap: '.25rem',
                  }}
                >
                  <span>{name}</span>
                  {cat && (
                    <Label
                      color={CATEGORY_COLOR[cat]}
                      size="mini"
                      style={{
                        borderRadius: '1rem',
                        textAlign: 'center',
                        maxWidth: '55px',
                        maxHeight: '22px',
                      }}
                    >
                      {cat.replace('관광지', '')}
                    </Label>
                  )}
                </div>
                <span
                  style={{
                    color: 'rgba(0,0,0,.5)',
                    fontSize: '0.875rem',
                    lineHeight: '1.1',
                  }}
                >
                  {address}
                </span>
              </div>
            </div>
          ))}
        {list.length === 0 && <div>여행지 정보가 없어.</div>}
      </div>
    </div>
  );
};

export default TravelCard;
