import React from 'react';
import { Grid, GridRow } from 'semantic-ui-react';
import styled from 'styled-components';
import HistoryCards from './HistoryCards';
// import { errorLog } from '../../../utils/common';

const HistoryQuote = styled.span`
  color: #248ed8;
  font-weight: 600;
  font-size: 1rem;
  &::before {
    display: inline-block;
    opacity: 1;
    margin: 0 0.25rem 0 0;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-size: xx-small;
    vertical-align: super;
    content: '\f10d';
  }
  &::after {
    display: inline-block;
    opacity: 1;
    margin: 0 0 0 0.25rem;
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-size: xx-small;
    vertical-align: super;
    content: '\f10e';
  }
`;

const HistoryItemRow = styled(GridRow)`
  /* display: flex;
  flex-wrap: wrap; */
  width: 100%;
`;

/*
const ExtraIcon = () => (
  <Icon.Group size="huge" style={{ margin: '0 .25em 0 .5em' }}>
    <Icon
      size="big"
      name="circle outline"
      style={{ margin: 0, opacity: 0.7, fontWeight: 100 }}
    />
    <Icon name="ellipsis horizontal" />
  </Icon.Group>
); */

const HistoryListItem = props => {
  const { list, handleDelete } = props;
  // const [visible, setVisible] = useState('');
  return (
    list &&
    list.map(({ q, a, bot, data, firstTime, uuid, id }) => (
      <HistoryItemRow key={uuid || id}>
        {!q || q === '' || !!(bot && data) ? null : (
          <Grid style={{ margin: '0 20px 5px' }}>
            <Grid.Column width={15} style={{ padding: '0' }}>
              {q && <HistoryQuote as="h4">{q}</HistoryQuote>}
            </Grid.Column>
          </Grid>
        )}
        {!!(q || a || data) && (
          <HistoryCards
            {...{
              q,
              a,
              bot,
              uuid,
              id,
              data,
              firstTime,
              handleDelete,
            }}
          />
        )}
      </HistoryItemRow>
    ))
  );
};

export default HistoryListItem;
