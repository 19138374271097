/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import i18 from '../lang/i18n';
import {
  confirmCertCode,
  getFile,
  getUserData,
  getUserInterest,
  sendCertCode,
  updateUserData,
  updateUserImageData,
  updateUserItemData,
  updateUserPW,
  userSignOut,
  userWithdraw,
} from '../utils/api';
import {
  getLocale,
  convertImageToBlob,
  getUserId,
  getUserPId,
  getRobotId,
  getErrorMsg,
  getErrorCode,
  removeLocalStorage,
} from '../utils/common';
import {
  validateComparePassword,
  validateInfos,
  validatePassword,
} from '../utils/validate';
import { piboDisconnect, piboReset, refreshUser } from '../pibo';
import { reporter } from '../utils/report';

const reducerName = 'userinfo';
const initialState = {
  interests: [],
  user: {
    _id: '',
    birthDate: 0,
    email: '',
    tel: '',
    firstName: '',
    lastName: '',
    nickName: '',
    interest: [],
    interestList: [],
    imageFile: null,
    gender: '',
    userId: '',
    verified: { email: null, tel: null },
    sent: { email: null, tel: null, expireTime: 0, firstTime: 0 },
    data: null,
    image: null,
  },
  error: {
    birthDate: false,
    email: false,
    tel: false,
    firstName: false,
    lastName: false,
    gender: false,
    imageFile: false,
  },
  isComplete: false,
  loading: false,
};

const checkInterest = (interestList, interest) => {
  if (!interestList) return [];
  return interestList.reduce((acc, cur) => {
    const selectItem = interest.find(id => id === cur._id);
    const selected = selectItem && selectItem.length > 0;
    const newItem = { ...cur, selected: !!selected };
    acc.push(newItem);
    return acc;
  }, []);
};

const validateAll = async user => {
  let msg = '';
  if (user) {
    const {
      birthDate,
      email,
      tel,
      firstName,
      gender,
      interest,
      lastName,
      nickName,
      data,
      image,
      verified,
      sent,
    } = user;
    const validItemArr = [
      { name: 'imageFile', value: data || image },
      { name: 'lastName', value: lastName },
      { name: 'firstName', value: firstName },
      { name: 'gender', value: gender },
      { name: 'nickName', value: nickName },
      { name: 'tel', value: { tel, verified: verified.tel, sent: sent.tel } },
      {
        name: 'email',
        value: { email, verified: verified.email, sent: sent.email },
      },
      { name: 'birthDate', value: birthDate },
      { name: 'interest', value: interest },
    ];

    validItemArr.forEach(async ({ name, value }) => {
      const info = await validateInfos({ name, value });
      if (info[name]) {
        msg = info[name];
        return;
      }
      msg = '';
    });
  }

  return msg;
};

export const sendCode = createAsyncThunk(
  `${reducerName}/SEND_CODE`,
  async ({ type, value }, { getState, rejectWithValue }) => {
    try {
      const {
        userinfo: { user },
      } = getState();
      const obj = { email: '', tel: '' };
      const sendResult = await sendCertCode({
        ...obj,
        [type]: value,
        userId: getUserId(),
        type: 'updateinfo',
      });
      /* 테스트용
        const cur = new Date().valueOf();
        const sendResult = {
          result: true,
          data: {
            statusCode: '202',
            statusName: 'success',
            requestId: '138fa09cb5ce43a9a09aef1a0e573efa',
            requestTime: '2021-04-23T14:05:43.922',
          },
          saveResult: {
            _id: '608255a7091a5ef6e5fbdce9',
            valid: true,
            hash: '6TEVNF',
            type: 'undateInfo',
            createdAt: '2021-04-23T05:05:43.506Z',
            firstTime: cur,
            expireTime: cur + (1000 * 60 * .5),
            expiredAt: '2021-04-23T05:08:43.506Z',
            __v: 0,
          },
        };
        */
      if ('result' in sendResult) {
        const { result, error } = sendResult;
        if (result) {
          const {
            saveResult: { firstTime, expireTime },
          } = sendResult;
          // return { ...user, verified: { ...user.verified, [type]: false }, sent: { ...user.sent, firstTime, expireTime, [type]: true },  };
          return {
            ...user,
            sent: { ...user.sent, firstTime, expireTime, [type]: true },
            verified: { ...user.verified, [type]: null },
          };
        }
        throw error;
      }

      return rejectWithValue({
        [type]: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
      });
    } catch (error) {
      if ('code' in error && error.code === '5v102z') {
        if (type === 'email') {
          return rejectWithValue({
            [type]: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_MAIL'),
          });
        }
        return rejectWithValue({
          [type]: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_TEL'),
        });
      }
      return rejectWithValue({
        [type]: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
      });
    }
  },
);

export const confirmCode = createAsyncThunk(
  `${reducerName}/CONFIRM_CODE`,
  async ({ code, type }, { getState, rejectWithValue }) => {
    try {
      const confirmResult = await confirmCertCode({
        code,
        type: 'updateinfo',
        userOId: getUserPId(),
      });
      const {
        userinfo: { user },
      } = getState();
      /* 테스트용
      const confirmResult = { result: false };
       */
      if ('result' in confirmResult) {
        const { result } = confirmResult;
        if (result) {
          return { ...user, verified: { ...user.verified, [type]: true } };
        }
        return rejectWithValue({
          [type]: i18.t('user:SIGN_UP.ERR_MSG.UNVALID_CERT'),
        });
      }
      return rejectWithValue({
        [type]: i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'),
      });
    } catch (error) {
      return rejectWithValue({
        [type]: i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'),
      });
    }
  },
);

export const signOut = createAsyncThunk(`${reducerName}/SIGN_OUT`, async () => {
  try {
    await userSignOut({ userId: getUserPId(), robotId: getRobotId() });
    reporter({
      target: getUserId(),
      action: 'signout',
      data: {
        result: true,
      },
    });
    removeLocalStorage('currentUser', 'userToken', 'version');
    return { isComplete: true };
  } catch {
    removeLocalStorage('currentUser', 'userToken', 'version');
    return { isComplete: true };
  }
});

export const withdraw = createAsyncThunk(
  `${reducerName}/WITHDRAW`,
  async ({ userOId }, { rejectWithValue }) => {
    try {
      const res = await userWithdraw({ userOId });
      if (res.result) {
        if (getRobotId()) {
          piboReset();
          piboDisconnect();
        }
        localStorage.clear();
        return true;
      }
      return rejectWithValue(i18.t('user:WITHDRAW.ERR.FAILED_AND_RETRY'));
    } catch (error) {
      return rejectWithValue(i18.t('user:WITHDRAW.ERR.FAILED_AND_RETRY'));
    }
  },
);

export const updateUserPassword = createAsyncThunk(
  `${reducerName}/UPDATE_PASSWORD`,
  async ({ userId, oldPw, newPw }, { rejectWithValue }) => {
    try {
      const updateResult = await updateUserPW({ userId, oldPw, newPw });
      const { result, error } = updateResult;
      if (result) {
        return result;
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);
export const updateUserItem = createAsyncThunk(
  `${reducerName}/UPDATE_ITEM`,
  async ({ userId, data }, { rejectWithValue }) => {
    try {
      const updateResult = await updateUserItemData(userId, { ...data });
      const { result, error } = updateResult;
      if (result) {
        return result;
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);

const userinfoSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
    setLoading: state => ({ ...state, isComplete: false, loading: true }),
    fetchSuccess: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    error: (state, action) => ({
      ...state,
      loading: false,
      error: { ...state.error, ...action.payload },
    }),
    changeItem: (state, action) => ({
      ...state,
      loading: false,
      user: action.payload,
    }),
    updateSuccess: state => ({
      ...state,
      loading: false,
      isComplete: true,
    }),
    initialSent: (state, action) => ({
      ...state,
      user: {
        ...state.user,
        verified: {
          ...state.user.verified,
          [action.payload.id]: action.payload.verified,
        },
        sent: {
          ...state.user.sent,
          firstTime: 0,
          expireTime: 0,
          [action.payload.id]: null,
        },
      },
    }),
  },
  extraReducers: {
    [sendCode.pending.type]: state => ({ ...state, loading: true }),
    [sendCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      error: { ...state.error, verified: { email: null, tel: null } },
      user: { ...action.payload },
    }),
    [sendCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: { ...state.error, ...action.payload },
    }),
    [confirmCode.pending.type]: state => ({ ...state, loading: true }),
    [confirmCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      error: state.error,
      user: { ...action.payload },
    }),
    [confirmCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: { ...state.error, ...action.payload },
    }),
    [updateUserPassword.pending.type]: state => ({ ...state, loading: true }),
    [updateUserPassword.fulfilled.type]: state => ({
      ...state,
      loading: false,
      error: false,
      isComplete: true,
    }),
    [updateUserPassword.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: { ...state.error, password: action.payload },
    }),
    [signOut.pending.type]: state => ({ ...state, loading: true }),
    [signOut.fulfilled.type]: (state, action) => ({
      ...initialState,
      ...action.payload,
    }),
    [withdraw.pending.type]: state => ({ ...state, loading: true }),
    [withdraw.fulfilled.type]: () => ({
      ...initialState,
      isComplete: true,
    }),
    [withdraw.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: { ...state.error, withdraw: action.payload },
    }),
  },
});

const { reducer: userinfoReducer, actions } = userinfoSlice;
const { fetchSuccess, updateSuccess, changeItem, error } = actions;
export const { init, setLoading, initialSent } = actions;

export const fetchUserInfoData = (
  userId,
  errorCheck = false,
) => async dispatch => {
  dispatch(setLoading());
  const returnValue = {};
  const { result, data } = await getUserInterest(getLocale());
  returnValue.interests = result ? data : [];
  if (userId) {
    const res = await getUserData(userId);
    if (res.result && res.data) {
      const { interest, birthDate, verified, email, ...rest } = res.data;
      const castDate = moment(birthDate).format('YYYY-MM-DD');
      const interestList = checkInterest(data, interest);
      // const convertedImgData = imgData
      //   ? await convertImageToBlob(imgData)
      //   : null;
      returnValue.interests = interestList;
      returnValue.user = {
        ...rest,
        email,
        src: getFile({ userPhoto: getUserId() }),
        // data: convertedImgData ? convertedImgData.url : null,
        interest,
        interestList,
        birthDate: castDate,
        sent: { email: null, tel: null, expireTime: 0, firstTime: 0 },
        verified: verified || { email: !!email, tel: null },
      };
    }
  }
  dispatch(fetchSuccess(returnValue));

  if (errorCheck) {
    const errorMsg = await validateAll(returnValue.user);
    dispatch(error(errorMsg));
  }
};

export const userInfoValidate = ({ value, name }) => async (
  dispatch,
  getState,
) => {
  if (!name) return null;
  const {
    userinfo: { user: userData, error: oldError },
  } = getState();
  const user = { ...userData };
  let newValue = null;
  let msg = null;
  newValue = value;
  if (name === 'interest') {
    const { interestList } = userData;
    user.interestList = checkInterest(interestList, value);
    user[name] = value;
  } else if (name === 'imageFile' && value) {
    dispatch(setLoading());
    if (value) {
      const { url, blob } = await convertImageToBlob(value[0]);
      /* 이미지 변환된 파일 확인
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = window.URL.createObjectURL(blob);
      a.download = value[0].name;
      a.click();
      window.URL.revokeObjectURL(url); */
      user.image = url;
      user.imageFile = value;
      msg = await validateInfos({ name, value: blob });
    }
  } else if (name !== 'certCode') {
    user[name] = newValue || value;
  }
  if (name === 'email' || name === 'tel') {
    const { verified, sent: s } = user;
    let sent;
    if (userData[name] !== value) {
      if (s[name] && !verified[name]) {
        sent = { ...s, [name]: null, expireTime: 0, firstTime: 0 };
        user.sent = { ...sent };
      }
    }
    msg = await validateInfos({
      name,
      value: { [name]: value, verified, sent },
    });
    if (!([name] in msg)) {
      msg = { ...msg, [name]: '' };
    }
  } else if (name === 'certCode') {
    const {
      sent: { tel, email },
    } = user;

    if (tel) {
      msg = { tel: value ? oldError.tel : false };
    }
    if (email) {
      msg = { ...msg, email: value ? oldError.email : false };
    }
  } else if (name !== 'imageFile') {
    msg = await validateInfos({ name, value });
  }
  dispatch(error(msg));
  dispatch(changeItem(user));

  const {
    _id,
    userId,
    uuid,
    robotId,
    interestList,
    data,
    image,
    tel,
    email,
    verified,
    sent,
    ...rest
  } = user;

  if (!data && !image) {
    msg = await validateInfos({ name: 'imageFile', value: null });
    return dispatch(error(msg));
  }

  const checkArr = Object.entries({ ...rest });
  if (verified.email && email) {
    checkArr.push(['email', { email, verified, sent: sent.email }]);
  }
  if (verified.tel && tel) {
    checkArr.push(['tel', { tel, verified, sent: sent.tel }]);
  }
  checkArr.forEach(async ([key, v]) => {
    if ((key === 'imageFile' && oldError[key]) || key !== 'imageFile') {
      if (key === 'imageFile') {
        const { blob } = await convertImageToBlob(v[0]);
        msg = await validateInfos({ name: key, value: blob });
      } else {
        msg = await validateInfos({ name: key, value: v });
      }
      if ([key] in msg && msg[key].length > 0) {
        return dispatch(error(msg));
      }
    }
    return msg;
  });

  return null;
};

export const updateUserInfo = ({ userId }) => async (dispatch, getState) => {
  dispatch(setLoading());
  const {
    userinfo: {
      user: { imageFile, ...data },
    },
  } = getState();

  try {
    const msg = await validateAll(data);

    if (msg) {
      return dispatch(error(msg));
    }
    if (imageFile) {
      const {
        data: { result },
      } = await updateUserImageData({
        userId,
        imageFile,
      });
      if (result) {
        const {
          birthDate,
          email,
          firstName,
          gender,
          interest,
          lastName,
          nickName,
          tel,
          verified,
        } = data;
        const res = await updateUserData(userId, {
          birthDate,
          email,
          firstName,
          gender,
          interest,
          lastName,
          nickName,
          tel,
          verified,
        });
        console.log(res);
        if (res.result) {
          refreshUser();
          return dispatch(updateSuccess());
        }
      }
      return dispatch(
        error({ imageFile: i18.t('user:SIGN_UP.ERR_MSG.FAILED_PHOTO') }),
      );
    }
    const {
      birthDate,
      email,
      firstName,
      gender,
      interest,
      lastName,
      nickName,
      tel,
      verified,
    } = data;
    const res = await updateUserData(userId, {
      birthDate,
      email,
      firstName,
      gender,
      interest,
      lastName,
      nickName,
      tel,
      verified,
      userId,
    });
    if (res.result) {
      refreshUser();
      return dispatch(updateSuccess());
    }
    return dispatch(error({ failed: i18('user:SET.ERR.FAILED_UPDATE') }));
  } catch (e) {
    console.log(e);
    return dispatch(
      error({
        failed: `${i18.t('user:SET.ERR.FAILED_UPDATE')} ${JSON.stringify(
          e,
        )} ${e}`,
      }),
    );
  }
};

export const userPasswordValidate = ({ name, value, newRePw, newPw }) => (
  dispatch,
  getState,
) => {
  const {
    userinfo: {
      error: { password: pwError },
    },
  } = getState();
  let msg = '';
  if (
    name === 'oldPw' ||
    ((name === 'newPw' || name === 'newRePw') && value.length < 1)
  ) {
    if (msg !== pwError) {
      return dispatch(error({ password: msg }));
    }
  }
  const compareValue = name === 'newPw' ? newRePw : newPw;

  if (name === 'newPw' || name === 'newRePw') {
    msg = validatePassword(value);
    if (!msg) {
      msg = validateComparePassword(value, compareValue);
    }
  }

  if (msg !== pwError) {
    return dispatch(error({ password: msg }));
  }
};

export default userinfoReducer;
