import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { StyledTextArea } from '../../Components';

const Speak = props => {
  const { t } = useTranslation('bot');
  const { playing, text, onTextChange, error } = props;
  return (
    <Card
      title={t('AVA.INPUT.AREA')}
      flow="column"
      popupContent={{
        header: t('AVA.INPUT.LB'),
        content: t('AVA.INPUT.GUIDE'),
      }}
    >
      <StyledTextArea
        rows={4}
        placeholder={t('AVA.INPUT.TEXT')}
        onChange={onTextChange}
        value={text}
        disabled={playing}
      />
      <div
        style={{
          width: '100%',
          background: '#fbfbfb',
          fontSize: '0.75rem',
          color: '#EF3340',
          lineHeight: '1.35',
          padding: '0.625rem',
        }}
      >
        {error}
      </div>
    </Card>
  );
};

export default Speak;
