/* eslint-disable consistent-return */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import PiboCallname from '../../components/pibo/settings/PiboCallname';
import PiboTempUnit from '../../components/pibo/settings/PiboTempUnit';
import PiboWiFi from '../../components/pibo/settings/PiboWiFi';
import PiboManualWiFi from '../../components/pibo/settings/PiboManualWiFi';
// import PiboPosition from '../../components/pibo/settings/PiboPosition';
import PiboGeo from '../../components/pibo/settings/PiboGeo';
import PiboLocale from '../../components/pibo/settings/PiboLocale';
import PiboInstruction from '../../components/pibo/settings/PiboInstruction';
import PiboDisturb from '../../components/pibo/settings/PiboDisturbSetting';
import PiboReaction from '../../components/pibo/settings/PiboReaction';
import PiboStyle from '../../components/pibo/settings/PiboStyle';
import {
  savePiboCog,
  fetchCogList,
  fetchAutoCompleteData,
  apConnect,
  handleDisConn,
} from '../../ducks/pibo';
import withLoading from '../../hocs/withLoading';
import { getRobotId, getLocale, getUserId, getQRCvs } from '../../utils/common';
import NetworkConnectPopup from '../../components/pibo/settings/NetworkConnectPopup';
import { Page } from '../../components/Components';
import withAuth from '../../hocs/withAuth';
import WiFiQRConnectPopup from '../../components/pibo/settings/WiFiQRConnectPopup';
import PiboDisconnect from '../../components/pibo/settings/PiboDisconnect';
import Loading from '../../components/Loading';

const PiboSettingDetailPage = ({ loading: pending }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selector = useSelector(state => state.pibo);
  const {
    data,
    loading: dataLoading,
    error,
    isSaved,
    searchStr,
    searching,
    searchResult: results,
    init,
  } = selector;
  const navigate = useNavigate();
  const { item } = useParams();
  const [value, setValue] = useState(null);
  const [isPopup, setPopupState] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [menuObj, setMenuObj] = useState({ tilte: '', mode: '' });
  const [loading, setLoading] = useState(pending || dataLoading);
  const getMenuObject = () => {
    const obj = {};
    // 'WITH_BACK_AND_COMPLETE' 모드의 모든 메뉴는 완료 처리 이벤트도 함께 보내줘야 함.
    switch (item) {
      case 'name':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_CALLNAME');
        break;
      case 'tempUnit':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_TEMPERATURE');
        break;
      case 'servo':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_ALIGN');
        break;
      case 'wifi':
        obj.mode = 'WITH_BACK';
        obj.title = t('pibo:SET.CONF_WIFI');
        break;
      case 'manualWifi':
        obj.mode = 'WITH_BACK';
        obj.title = t('pibo:CONNECT_MANUAL_WIFI');
        break;
      case 'disturb':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_DISTURB');
        break;
      case 'geo':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_LOCATION');
        break;
      case 'reaction':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_REACTION');
        break;
      case 'style':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_STYLE');
        break;
      case 'locale':
        obj.mode = 'WITH_BACK_AND_COMPLETE';
        obj.title = t('pibo:SET.CONF_LANGUAGE');
        break;
      case 'instruction':
        obj.mode = 'WITH_BACK';
        obj.title = t('pibo:SET.INSTRUCTION');
        break;
      case 'disconnect':
        obj.mode = 'WITH_BACK';
        obj.title = t('pibo:SET.DISCONNECT.LB');
        break;
      default:
        obj.mode = 'WITH_BACK';
        obj.title = t('pibo:SET.CONF_PIBO');
        break;
    }
    setMenuObj(obj);
  };

  useMount(() => {
    getMenuObject();
    dispatch(fetchCogList(item));
  });

  useEffect(() => {
    setLoading(pending || dataLoading);
  }, [pending, dataLoading]);

  const handleChange = v => {
    if (item === 'wifi') {
      const { list } = data;
      const changeTarget = v.currentTarget.name;
      const apIdx = changeTarget.split('_')[1];
      const apItem = list[apIdx];
      const password = '';
      setValue({ ...apItem, password });
      return setPopupState(true);
    }

    if (item === 'manualWifi') {
      const { essid, wpa, password, encrypted } = v;
      const qrcode = getQRCvs({
        value: JSON.stringify(v),
        size: window.innerWidth,
        level: 'L',
      });
      setValue({ qrcode, essid, wpa, password, encrypted });
      return setPopupState(true);
    }

    if (item === 'disturb') {
      const { toggle, day } = v;
      if (toggle && day.length === 0) {
        setValue({ ...v });
        return setErrorMsg(t('pibo:SET.SELECT_DAY_TIME'));
      }
    }

    if (item === 'geo') {
      const { error: e, value: curVal } = v;
      if (e || !curVal) {
        return setErrorMsg(e || t('pibo:SET.UNVALID_LOCATION'));
      }
      setErrorMsg(null);
      return setValue(curVal);
    }

    if (item === 'reaction' || item === 'style') {
      return setValue(v);
    }

    setErrorMsg(null);
    return setValue(typeof v === 'string' ? v : { ...v });
  };

  const handleBackClick = () => navigate(-1);

  const handleCompleteClick = () => {
    if (errorMsg && errorMsg.length > 0) return setErrorMsg(errorMsg);
    let dataObject = data ? { [data.item]: value || data.value } : {};
    if (item === 'reaction' || item === 'style') {
      if (value) {
        dataObject = { [data.item]: parseInt(value, 10) };
      } else {
        dataObject = { [data.item]: parseInt(data.value, 10) };
      }
    }
    if (item === 'disturb') {
      const today = moment().format('YYYY-MM-DD');
      const { startTime, endTime, toggle, day } = value;
      // eslint-disable-next-line no-underscore-dangle
      const id = data && data.value ? data.value._id : '';
      const repeat = toggle && day.length > 0;
      if (toggle && !repeat) {
        return setErrorMsg(t('pibo:SET.SELECT_REPEAT_DAY'));
      }
      dataObject = {
        disturb: {
          title: 'disturb mode',
          execTime: moment(`${today} ${startTime}`).format(),
          expTime: moment(`${today} ${endTime}`).format(),
          robotId: getRobotId(),
          bot: null,
          command: '',
          locale: getLocale(),
          userId: getUserId(),
          id,
          repeat,
          repeatValue: {
            type: 'disturb',
            value: { day },
          },
          use: toggle,
        },
      };
    }
    return dispatch(savePiboCog(dataObject));
  };

  const handlePopup = (v, nextFunc) => {
    // const { isPopup, ...rest } = this.state;
    // this.setState({
    //   ...rest,
    //   isPopup: value,
    // });
    // if (nextFunc) nextFunc();
    // what is the rest????
    setPopupState(v);
    if (nextFunc) nextFunc();
  };

  const onChangeResult = v => {
    dispatch(fetchAutoCompleteData(v));
  };
  const handleApConnect = v => dispatch(apConnect(v));
  const handleWithdrawClick = () => dispatch(handleDisConn());

  useUpdateEffect(() => {
    if (isSaved) {
      if (item === 'geofirst') {
        return navigate('/pibo');
      }
      return navigate(`/pibo/${getRobotId()}`);
    }
  }, [isSaved]);

  useUpdateEffect(() => {
    if (init) {
      navigate('/pibo');
    }
  }, [init]);

  return (
    <Page>
      {pending && <Loading />}
      <TopMenu
        title={menuObj.title}
        menuMode={menuObj.mode}
        handleBackClick={handleBackClick}
        handleCompleteClick={handleCompleteClick}
        enabled={!errorMsg}
      />
      {item === 'reaction' && (
        <PiboReaction {...{ ...data, loading, handleChange }} />
      )}
      {item === 'style' && (
        <PiboStyle {...{ ...data, loading, handleChange }} />
      )}
      {item === 'name' && (
        <PiboCallname loading={loading} {...data} handleChange={handleChange} />
      )}
      {item === 'tempUnit' && (
        <PiboTempUnit loading={loading} {...data} handleChange={handleChange} />
      )}
      {item === 'wifi' && (
        <>
          <PiboWiFi
            {...{ loading, t, isInit: false }}
            newAp={value}
            ap={data.value}
            aplist={data.list}
            error={error.message}
            connectedList={data.connectedList}
            onChange={handleChange}
            onReSearch={() => dispatch(fetchCogList(item))}
          />
          {isPopup && (
            <NetworkConnectPopup
              t={t}
              leftLabel={t('POP.CANCEL')}
              rightLabel={t('POP.CONNECT')}
              leftFunc={() => handlePopup(false)}
              rightFunc={ap => handlePopup(false, handleApConnect(ap))}
              {...value}
              isPopup
            />
          )}
        </>
      )}
      {item === 'manualWifi' && (
        <>
          <PiboManualWiFi
            {...{ loading, t, isPopup }}
            error={error.message}
            onChange={handleChange}
          />
          {isPopup && (
            <WiFiQRConnectPopup
              t={t}
              rightLabel={t('POP.CLOSE')}
              rightFunc={() => handlePopup(false)}
              {...value}
              isPopup
            />
          )}
        </>
      )}
      {'item' in data && item === 'geo' && getLocale() === 'ko' && (
        <PiboGeo error={errorMsg} {...data} handleChange={handleChange} />
      )}
      {/* item === 'geo' && getLocale() !== 'ko' && (
        <PiboPosition
          loading={loading}
          {...data}
          handleChange={handleChange}
        />
      ) */}
      {item === 'locale' && (
        <PiboLocale loading={loading} {...data} handleChange={handleChange} />
      )}
      {item === 'instruction' && 'list' in data && (
        <PiboInstruction
          {...data}
          loading={loading}
          searchStr={searchStr}
          searching={searching}
          results={results}
          onChangeResult={onChangeResult}
          onInitData={() => dispatch(fetchCogList(item))}
          handleChange={handleChange}
        />
      )}
      {item === 'disturb' && (
        <PiboDisturb
          {...data}
          loading={loading}
          errorMsg={errorMsg}
          handleChange={handleChange}
        />
      )}

      {item === 'disconnect' && (
        <PiboDisconnect
          {...data}
          loading={loading}
          handleWithdrawClick={handleWithdrawClick}
        />
      )}
    </Page>
  );
};

export default withAuth(withLoading(PiboSettingDetailPage));
