/* eslint-disable no-underscore-dangle */
import React, { useRef, useState, useEffect } from 'react';
import { Image } from 'semantic-ui-react';
import styled from 'styled-components';

const UserPhotoWrap = styled.div`
  position: relative;
  // height: 35vw;
  height: ${({ avatar }) => (avatar ? '2.75rem' : '35vw')};
  width: ${({ avatar }) => (avatar ? '2.75rem' : '35vw')};
  max-height: 300px;
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  & > div {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // width: ${({ avatar }) => (avatar ? '2.75rem' : '35vw')};
    // height: ${({ avatar }) => (avatar ? '2.75rem' : '35vw')};
    width: 100%;
    height: 100%;
    overflow: hidden;
    max-width: 300px;
    max-height: 300px;
    & > div {
      display: inline-block;
      overflow: hidden;
      background: #fff;
      text-align: center;
      vertical-align: middle;
      width: 100%;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
      & > div {
        width: 100%;
        height: auto;
        background-color: transparent;
        overflow: inherit;
        & > canvas {
          width: inherit;
          background-color: transparent;
        }
      }
      & > img {
        width: inherit;
        background-color: transparent;
      }
    }
  }
`;

const UserPhoto = props => {
  const imageCanvas = useRef(null);
  const photoRef = useRef(null);
  const [noImage, setNoImage] = useState(true);
  const { loading, src, data, avatar, children, error, className } = props;
  useEffect(() => {
    if (!data) {
      setNoImage(true);
    } else {
      setNoImage(false);
      const imgCvs = imageCanvas.current;
      if (imgCvs) {
        if (imgCvs.childNodes.length > 0) {
          imgCvs.removeChild(imgCvs.childNodes.item(0));
        }
        if (typeof data === 'object') {
          imgCvs.appendChild(data);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (src) {
      setNoImage(false);
    }
  }, [src]);

  useEffect(() => {
    let classNames = photoRef.current.className;
    if (error) {
      classNames += ` shake-animation`;
    } else {
      classNames = classNames.replace(' shake-animation', '');
    }
    photoRef.current.className = classNames;
  }, [error, photoRef]);

  return (
    <UserPhotoWrap
      avatar={avatar || false}
      ref={photoRef}
      className={className}
    >
      <div>
        <div style={{ position: 'relative' }}>
          <div ref={imageCanvas} />
          {src && (
            <img
              src={src}
              alt="user face"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
          {data && typeof data === 'string' && data.indexOf('blob:') > -1 && (
            <img
              src={data}
              alt="user face for detect"
              style={{ position: 'absolute', top: 0, left: 0 }}
            />
          )}
          <Image
            src="/image/img_user.png"
            style={{
              position: 'absolute',
              opacity: loading || noImage ? 1 : 0,
              transition: 'opacity 200ms ease-in-out',
            }}
          />
        </div>
        {children}
      </div>
    </UserPhotoWrap>
  );
};

export default UserPhoto;
