/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Header } from 'semantic-ui-react';
import shortid from 'shortid';
import AccordionText from './AccordionText';

const MeditationCard = data => {
  const { title, content: c, think, error } = data;
  const [content, setContent] = useState(c);
  const id = shortid.generate();

  const setLineBreak = v =>
    v
      .replace(/[?]+\s/g, `?\n`)
      .replace(/[.]+\s/g, `.\n`)
      .replace(/[!]+\s/g, `!\n`)
      .replace(/[~]+\s/g, `~\n`);

  useEffect(() => {
    if (think) {
      setContent(
        <>
          <>
            {setLineBreak(c)
              .split('\n')
              .map((line, i) => (
                <span key={`${id}_content_${i}`}>
                  {line}
                  <br />
                </span>
              ))}
          </>
          <div style={{ padding: '1em', textAlign: 'left' }}>
            {setLineBreak(think)
              .split('\n')
              .map((line, i) => (
                <span key={`${id}_think_${i}`} className="blue-text">
                  {line}
                  <br />
                </span>
              ))}
          </div>
        </>,
      );
    } else if (c) {
      setContent(
        <span>
          {setLineBreak(c)
            .split('\n')
            .map((line, i) => (
              <span key={`${id}_content_${i}`}>
                {line}
                <br />
              </span>
            ))}
        </span>,
      );
    }
  }, [think]);
  return (
    <div
      style={{
        display: 'flex',
        gap: '.35rem',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Header
        as="h5"
        style={{
          fontSize: '0.875rem',
          margin: 0,
          textAlign: 'center',
          width: '100%',
        }}
      >
        {error || title}
      </Header>
      {c && <AccordionText {...{ content, textAlign: 'center' }} />}
    </div>
  );
};

export default MeditationCard;
