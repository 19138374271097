import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll } from 'react-scroll';
import { useMount, useUpdateEffect } from 'react-use';
import { Header, Image } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { CompleteDiv } from '../../components/link/Components';
import { init } from '../../ducks/link';
import { refreshUser } from '../../pibo';

const LinkStep5 = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const link = useSelector(state => state.link);
  const { complete } = link;

  useMount(() => {
    animateScroll.scrollToTop({ duration: 0, delay: 0, smooth: true });
  });

  useUpdateEffect(() => {
    if (complete.to) {
      refreshUser();
      dispatch(init());
      navigate(complete.to);
    }
  }, [complete]);

  return (
    <CompleteDiv>
      <div className="wrap5">
        <Header as="h5">{t('conn:MSG.LINK_DESCRIPTION_5.HEADER')}</Header>
        <Image src="/image/pibo_welcome.png" />
        <Header size="medium">{t('conn:MSG.LINK_DESCRIPTION_5.TEXT')}</Header>
      </div>
    </CompleteDiv>
  );
};

export default LinkStep5;
