// import axios from 'axios';
import { API_URL } from '../../../circulusConfig';
import { encodeGetParams } from '../../../utils/api';

export const getPhotosApi = async (skip, limit, robotId) => {
  const res = await fetch(
    `${API_URL}photoBot/getPhotoData?${encodeGetParams({
      skip,
      limit,
      robotId,
    })}`,
  );
  const result = res.json();
  return result;
};

export const deletePhotosApi = async idArray => {
  const res = await fetch(`${API_URL}photoBot/deletePhotos`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ deleteList: idArray }),
  });
  const result = res.json();
  return result;
};

export const deletePhotoByFileIdApi = async fileId => {
  const res = await fetch(`${API_URL}avatarBot/deletePhoto`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ fileId }),
  });
  const result = res.json();
  return result;
};

/* export const deletePhotoByUUIDApi = (uuid) => {
  const options = {
    method: 'DELETE',
    data: { uuid },
    url: `${API_URL}avatarBot/deletePhotoByUUID`,
  };
  return axios(options);
}; */

/* export const getPhotosApi = async (skip, limit, robotId) => {
  const options = {
    method: 'GET',
    params: { skip, limit, robotId },
    url: `${API_URL}photoBot/getPhotoData`,
  };
  return axios(options);
} */

/* export const deletePhotosApi = async (idArray) => {
  const options = {
    method: 'DELETE',
    data: { deleteList: idArray },
    url: `${API_URL}photoBot/deletePhotos`,
  };
  return axios(options);
} */

// export const getMonthDataApi = (startDate, endDate) => {
//   const options = {
//     method: 'GET',
//     params: { startDate, endDate },
//     url: `${API_URL}calendarBot/getMonthData`,
//   };
//   return axios(options);
// };

// export const deleteItemsApi = (idArray) => {
//   const options = {
//     method: 'DELETE',
//     data: { deleteList: idArray },
//     url: `${API_URL}calendarBot/deleteCalendarItems`,
//   };
//   return axios(options);
// };
