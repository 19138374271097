import React, { useState } from 'react';
import qs from 'qs';
import { useMount } from 'react-use';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, Image, Header } from 'semantic-ui-react';
import { verifyingEmail } from '../../utils/api';

const VerificationPage = () => {
  const { search } = useLocation();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState({
    title: '',
    header: '',
    message: '',
    message2: '',
  });
  const failed = {
    title: '서큘러스 회원가입 이메일 인증 실패',
    header: '이메일 주소 인증에 실패하였습니다.',
    message:
      '해당 메일의 인증 시간이 만료되었거나 인증 과정 중 오류로 인해 인증에 실패하였습니다.',
    message2: '인증 메일 다시 보내기를 통해서 인증을 다시 한 번 시도해 주세요.',
  };

  // eslint-disable-next-line consistent-return
  useMount(() => {
    const { type, serial } = params;
    if (!type || !serial) {
      setLoading(false);
      return setContent({
        ...failed,
        title: '서큘러스 이메일 인증 오류',
        message: '메일 인증에 필요한 정보를 확인할 수 없습니다.',
      });
    }
    const body = { type, serial };
    if (type === 'replace') {
      const { code } = qs.parse(search, { ignoreQueryPrefix: true });
      body.code = code;
    }
    verifyingEmail(body)
      .then(verifyResult => {
        setLoading(false);
        const { result, userResult, userId } = verifyResult;
        if (result) {
          if (type === 'signup') {
            return setContent({
              title: '서큘러스 회원가입 이메일 인증 완료',
              header: '이메일 계정 인증이 완료되었습니다.',
              message: `${userResult.email} 이메일 계정에 대한 인증이 완료되었습니다.`,
              message2:
                '파이보 모바일 페이지로 돌아가서 회원가입을 완료하시기 바랍니다.',
            });
          }
          if (type === 'replace') {
            return setContent({
              title: '서큘러스 이메일 인증 완료',
              header: '이메일 계정 인증이 완료되었습니다.',
              message: `${userId}님의 이메일 계정에 대한 인증이 완료되었습니다.`,
            });
          }
        }
        return setContent(failed);
      })
      .catch(() => {
        setLoading(false);
        return setContent(failed);
      });
  });

  return (
    <div
      style={{
        width: '100%',
        fontSize: '14px',
        fontFamily: 'gulim',
        lineHeight: '18px',
        margin: 0,
        color: '#565b5b',
      }}
    >
      {!loading && (
        <Grid
          style={{
            color: '#565b5b',
            backgroundColor: '#fff',
            maxWidth: '720px',
            margin: '24px',
            padding: '24px',
            border: '1px solid #d3d4d5',
          }}
        >
          <Grid.Row columns={16}>
            <Grid.Column width={6} largeScreen={6} mobile={16} floated="left">
              <Image
                src="/image/logo_circulus.png"
                alt="circulus logo"
                style={{
                  verticalAlign: 'top',
                  border: 'none',
                  width: '100%',
                  height: 'auto',
                  maxWidth: '250px',
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={16}>
            <Grid.Column width={16}>
              <Header as="h3">{content.header}</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={16}>
            <Grid.Column width={16}>
              <p>{content.message}</p>
              <p>{content.message2}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={16} centered>
            <div
              style={{
                display: 'inline-flex',
                width: '100%',
                flexFlow: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <a href="https://circul.us/" style={{ marginRight: '1rem' }}>
                <Image
                  src="/image/aiwayswithyou.png"
                  style={{ width: '150px', height: 'auto', padding: '10px 0' }}
                />
              </a>
              <div>
                <address style={{ width: '100%', minWidth: '150px' }}>
                  <span data-lang="address">
                    (08504) 서울특별시 금천구 서부샛길 648 (가산동,
                    대륭테크노타운6차) 609호
                  </span>
                </address>
                <p className="copyright" style={{ margin: 0 }}>
                  Trademark and Copyright 2018 pibo from Circulus Inc. All
                  rights reserved.
                </p>
              </div>
            </div>
          </Grid.Row>
        </Grid>
      )}
    </div>
  );
};

export default VerificationPage;
