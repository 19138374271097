import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';

import { ColumnWrap, ModeDetailSpan } from './Components';
import ToggleButton from './ToggleButton';

const ButtonWrap = styled.div`
  height: 2rem;
  padding: 0.1rem 0;
  width: 100%;
`;

const MessageSelectDetail = props => {
  const { t } = useTranslation('bot');
  const {
    compact,
    typeToggle,
    modeToggle,
    onToggleMode,
    children,
    onToggleType,
    // expandInput,
    disabled,
  } = props;
  const [mode, setMode] = useState(modeToggle);
  useUpdateEffect(() => {
    setMode(modeToggle);
  }, [modeToggle]);
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        flexFlow: 'column-reverse',
      }}
    >
      <ColumnWrap style={{ width: '100%' }}>
        <ButtonWrap>
          <ToggleButton
            name="msgType"
            compact={compact}
            toggle={typeToggle}
            onClick={onToggleType}
            colors={['#565b5b']}
            icons={['font', 'microphone']}
            disabled={disabled}
          />
          {!typeToggle && !compact && (
            <ModeDetailSpan color="#565b5b">{t('MSG.MSG.REC')}</ModeDetailSpan>
          )}
          {typeToggle && !compact && (
            <ModeDetailSpan color="#565b5b">
              {t('MSG.ENTER.MSG')}
            </ModeDetailSpan>
          )}
        </ButtonWrap>
        <ButtonWrap fluid={typeToggle}>
          <ToggleButton
            name="msgMode"
            toggle={mode}
            onClick={onToggleMode}
            colors={['#FFB922', '#03BFD7']}
            icons={['clock outline', 'user outline']}
            corners={['sync', 'eye']}
            disabled={disabled}
          />
          {mode && (
            <ModeDetailSpan color="#FFB922" fluid={typeToggle}>
              {t('MSG.REAL_TIME')}
            </ModeDetailSpan>
          )}
          {!mode && (
            <ModeDetailSpan color="#03BFD7" fluid={typeToggle}>
              {t('MSG.SENSING_TIME')}
            </ModeDetailSpan>
          )}
        </ButtonWrap>
      </ColumnWrap>
      {children && !typeToggle && (
        <div
          style={{
            minWidth: '20%',
            width: '100%',
            marginLeft: '0.625rem',
            alignSelf: 'center',
          }}
        >
          {children}
        </div>
      )}
      {children && typeToggle && <div>{children}</div>}
    </div>
  );
};

export default MessageSelectDetail;
