import React from 'react';
import { Search, Grid, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TealSearch = styled(Search)`
  &.ui.search {
    font-size: 0.875rem;
    margin: 1em 1em 0.5em;
    & i {
      color: #fff;
    }
  }
  & .ui.input {
    & > input {
      color: #fff;
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #fff;
      padding: 0.45em 1em;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
      &:focus,
      &:active {
        background: transparent;
      }
    }
  }
`;
const BotsSearch = props => {
  const { t } = useTranslation('bot');
  const {
    bots,
    isLoading,
    value,
    handleSearchChange,
    handleSearchRemove,
  } = props;
  const handleKeyUp = event => {
    if (bots.length < 1) return;
    if (event.keyCode === 13 && event.key === 'Enter') {
      event.target.blur();
    }
  };

  return (
    <Grid centered>
      <Grid.Column>
        <TealSearch
          open={false}
          loading={isLoading}
          onSearchChange={handleSearchChange}
          placeholder={t('SEARCH.INSTALLED')}
          value={value}
          input={{
            fluid: true,
            input: { onKeyUp: handleKeyUp, type: 'search' },
          }}
          icon={
            value.length ? (
              <Icon name="delete" link onClick={handleSearchRemove} />
            ) : (
              <Icon name="search" />
            )
          }
          fluid
        />
      </Grid.Column>
    </Grid>
  );
};

export default BotsSearch;
