import React from 'react';
import { Icon } from 'semantic-ui-react';

const HistoryListPending = () => (
  <div
    style={{
      height: '60px',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      zIndex: 99,
    }}
  >
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
      }}
    >
      <Icon loading name="spinner" color="teal" />
    </div>
    <div
      style={{
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, white 50%, transparent)',
        filter: 'blur(3px)',
      }}
    />
  </div>
);

export default HistoryListPending;
