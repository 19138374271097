import React from 'react';
import { Dimmer } from 'semantic-ui-react';
import styled from 'styled-components';

const CirculusLoader = () => (
  <StyledCirculusLoader viewBox="0 0 100 100" fill="none">
    <defs>
      <clipPath id="cut-off-bottom">
        <rect x="0" y="0" width="94" height="42" fill="none" />
      </clipPath>
      <filter id="dropShadow" width="150%" height="150%">
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="2" />
        <feOffset dx="0" dy="0" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <circle
      className="brightHalf"
      fill="none"
      stroke="#D1D3D4"
      strokeWidth="5"
      cx="50"
      cy="50"
      r="44"
      clipPath="url(#cut-off-bottom)"
      // filter="url(#dropShadow)"
    >
      <animateTransform
        attributeName="transform"
        dur="3s"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      className="blueHalf"
      fill="none"
      stroke="#03BFD7"
      strokeWidth="5"
      cx="50"
      cy="50"
      r="44"
      clipPath="url(#cut-off-bottom)"
      // filter="url(#dropShadow)"
    >
      <animateTransform
        attributeName="transform"
        dur="3s"
        type="rotate"
        from="180 50 50"
        to="540 50 50"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      className="blueCircle"
      fill="none"
      stroke="#03BFD7"
      strokeWidth="4"
      cx="8"
      cy="54"
      r="6"
      // filter="url(#dropShadow)"
    >
      <animateTransform
        attributeName="transform"
        dur="3s"
        type="rotate"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </circle>
    <circle
      className="grayCircle"
      fill="none"
      stroke="#D1D3D4"
      strokeWidth="4"
      cx="8"
      cy="54"
      r="6"
      // filter="url(#dropShadow)"
    >
      <animateTransform
        attributeName="transform"
        dur="3s"
        type="rotate"
        from="180 50 50"
        to="540 50 50"
        repeatCount="indefinite"
      />
    </circle>
  </StyledCirculusLoader>
);

const StyledCirculusLoader = styled.svg`
  width: 60px;
  height: 85px;
  background: transparent;
`;

const LoaderText = styled.p`
  font-family: 'Comfortaa';
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 100;
  color: #03bfd7 !important;
  // text-shadow: 0px 0px 5px rgb(0 0 0 / 15%);
  text-shadow: 1px 1px 1px rgb(255 255 255 / 50%);
`;

const CirculusDimmer = styled(Dimmer)`
  &.ui.inverted.dimmer {
    background-color: rgba(251, 251, 251, 0.2);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    animation: loaderBlurring 1250ms ease-in;
  }
`;

const Loading = ({ onStart, onEnd }) => {
  const handleStart = () => onStart && onStart();
  const handleEnd = () => onEnd && onEnd();

  return (
    <div style={{ zIndex: '9999' }}>
      <CirculusDimmer
        inverted
        active
        page
        onAnimationStart={handleStart}
        onAnimationEnd={handleEnd}
      >
        <CirculusLoader />
        <LoaderText inverted>loading...</LoaderText>
      </CirculusDimmer>
    </div>
  );
};

export default Loading;
