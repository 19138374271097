/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { Divider, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce, useUpdateEffect } from 'react-use';
import InfiniteScroll from 'react-infinite-scroll-component';
import HistoryListItem from './HistoryListItem';
import {
  deleteHistoryItem,
  getHistory,
  setPending,
} from '../../../ducks/bots/historyBot/history';
import { getRobotId } from '../../../utils/common';
import HistoryListPending from './HistoryListPending';

const HistoryList = props => {
  const { style, timelineRef } = props;
  const dispatch = useDispatch();
  const { historyData, pending, length, bBottom } = useSelector(
    state => state.history,
  );
  const [scrollPosition, setScrollPoistion] = useState(0);

  useDebounce(
    () => {
      if (scrollPosition !== 0) {
        dispatch(getHistory({ robotId: getRobotId() }));
      }
    },
    200,
    [scrollPosition],
  );

  const handleLoad = () => {
    if (timelineRef.current) {
      const { scrollTop } = timelineRef.current;
      setScrollPoistion(scrollTop);
    }
    dispatch(setPending());
  };

  const handleDelete = data => {
    dispatch(deleteHistoryItem({ ...data, robotId: getRobotId() }));
  };

  useUpdateEffect(() => {
    if (timelineRef.current && !bBottom) {
      timelineRef.current.scrollTop = scrollPosition;
    }
  }, [length]);

  return (
    <div id="scrollableDiv" style={style} ref={timelineRef}>
      {pending && <HistoryListPending />}
      {historyData && (
        <InfiniteScroll
          dataLength={length}
          next={handleLoad}
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            position: 'relative',
          }} // To put endMessage and loader to the top.
          inverse
          hasMore={true && !pending}
          loader={
            pending && (
              <Icon
                name="spinner"
                pending={pending.toString()}
                style={{
                  // position: 'absolute',
                  margin: '4rem 50% .8rem',
                  color: '#d1d3d4',
                  zIndex: '99',
                }}
              />
            )
          }
          scrollableTarget="scrollableDiv"
        >
          {historyData.map(history => {
            const [date, list] = Object.entries(history)[0];
            return (
              (date || (list && list.length > 0)) && (
                <div key={`list-${date}`}>
                  <div style={{ width: '100%' }}>
                    <Divider
                      horizontal
                      style={{
                        fontSize: '0.625rem',
                        color: '#d1d3d4',
                        fontWeight: '100',
                      }}
                    >
                      {date}
                    </Divider>
                  </div>
                  <div
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                  >
                    <HistoryListItem list={list} handleDelete={handleDelete} />
                  </div>
                </div>
              )
            );
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};
export default HistoryList;
