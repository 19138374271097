import React from 'react';
import {
  Grid,
  Header,
  Divider,
  Segment,
  Button,
  List,
} from 'semantic-ui-react';
import styled from 'styled-components';
import PiboWiFiListItem from './PiboWiFiListItem';

const ConnectWrap = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: ${window.innerHeight}px;
  background-color: rgba(251, 251, 251, 1);
  padding: 1rem 1rem 0rem;
`;

const PiboWiFi = props => {
  const {
    newAp,
    ap,
    aplist,
    onChange,
    onReSearch,
    onPrevClick,
    isInit,
    loading,
    error,
    t,
    // connectedList,
  } = props;
  const address = ap && 'address' in ap ? ap.address : '';
  // const lastApList = connectedList.reduce((acc, cur) => {
  //   if (aplist) {
  //     const result = aplist.find(apItem => cur.address === apItem.address);
  //     acc.push(result);
  //   }
  //   return acc;
  // }, []);
  return (
    <ConnectWrap>
      {loading && newAp && newAp.essid && (
        <div>{t('conn:MSG.CONNECTING_WIFI', { ssid: newAp.essid })}</div>
      )}
      {loading && ap && !ap.essid && <div>{t('pibo:SET.SEARCHING_WIFI')}</div>}
      {!loading && aplist && aplist.length < 1 && !ap && (
        <div>
          <div>{t('pibo:SET.NOT_FOUND_WIFI')}</div>
          <Button
            fluid
            name="prev"
            size="large"
            style={{
              background: 'rgba(209, 211, 212, 1)',
              color: 'rgba(86,91,91,1)',
              borderRadius: '0px',
              padding: '1rem 0',
            }}
            onClick={onReSearch}
          >
            {t('pibo:SET.RESEARCHING_WIFI')}
          </Button>
        </div>
      )}
      {!loading && (ap || (ap && aplist.length > 0)) && (
        <div style={{ margin: '1rem auto' }}>
          <div>{t('pibo:SET.SELECT_WIFI')}</div>
          <Segment>
            <Grid centered>
              <Grid.Row>
                <Grid.Column>
                  <Header size="small">{t('pibo:SET.CONNECTED_WIFI')}</Header>
                </Grid.Column>
              </Grid.Row>
              {ap && address && (
                <Grid.Row>
                  <Grid.Column>
                    <List divided relaxed>
                      <PiboWiFiListItem {...ap} idx={0} key={address} />
                    </List>
                  </Grid.Column>
                </Grid.Row>
              )}
              {!(ap && address) && (
                <Grid.Row>
                  <Grid.Column>
                    {error || t('pibo:SET.NO_WIFI_CONNECTION')}
                  </Grid.Column>
                </Grid.Row>
              )}
              <Divider fitted />
              {/*
                    <Grid.Row>
                      <Grid.Column>
                        <Header size="small">
  연결 했던 Wi-Fi
                        </Header>
                      </Grid.Column>
                    </Grid.Row> */}
              {/*
                    <Grid.Row centered>
                      <Grid.Column>
                        <List divided relaxed>
                          {lastApList && lastApList.map(
                            (apitem, idx) => apitem && (
                              <PiboWiFiListItem
                                {...apitem} idx={idx} key={apitem.address} onChange={onChange}
                              />
                          ))}
                        </List>
                      </Grid.Column>
                    </Grid.Row>
                   */}
              <Grid.Row>
                <Grid.Column>
                  <Header size="small">{t('pibo:SET.NEW_WIFI')}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Grid.Column>
                  <List divided relaxed>
                    {aplist &&
                      Array.isArray(aplist) &&
                      aplist.map(
                        (apitem, idx) =>
                          apitem.essid && (
                            <PiboWiFiListItem
                              {...apitem}
                              idx={idx}
                              key={apitem.address}
                              onChange={onChange}
                            />
                          ),
                      )}
                    {aplist.length === 0 && loading && (
                      <div>{t('conn:LOADING')}</div>
                    )}
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Button
            fluid
            name="prev"
            size="large"
            style={{
              background: 'rgba(209, 211, 212, 1)',
              color: 'rgba(86,91,91,1)',
              borderRadius: '0px',
              padding: '1rem 0',
            }}
            onClick={onReSearch}
          >
            {t('pibo:SET.RESEARCHING_WIFI')}
          </Button>
          {isInit && (
            <Button
              fluid
              name="prev"
              size="large"
              style={{
                background: 'rgba(209, 211, 212, 1)',
                color: '#fff',
                borderRadius: '0px',
                marginTop: '1rem',
                padding: '1rem 0',
              }}
              onClick={onPrevClick}
            >
              {t('pibo:SET.CANCEL')}
            </Button>
          )}
        </div>
      )}
    </ConnectWrap>
  );
};
export default PiboWiFi;
