import React from 'react';
import { Segment, Form, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Page, FixedBottomButton } from '../../Components';
import { StyledMessage } from '../../Message';
import { WEEK_DAYS } from '../../../utils/consts';

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const FormLabel = styled.label`
  font-weight: 600 !important;
  font-size: 0.875rem;
  &::after {
    content: '*';
    display: ${({ essential }) => (essential ? 'inline-block' : 'none')};
    vertical-align: baseline;
    font-size: 1rem;
  }
`;

const UnderLinedInput = styled.input`
  font-family: inherit;
  color: ${({ readOnly }) => (readOnly ? '#0894D7 !important' : 'inherit')};
  font-size: 0.875rem !important;
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  border-bottom: ${({ readOnly }) => (readOnly ? '0px' : '#0894D7 !important')};
  border-radius: 0 !important;
  &::selection {
    color: #fff;
  }
`;

const ViewSchedule = props => {
  const { t } = useTranslation('bot');
  const {
    error,
    schedule: { title, execTime, command, repeatValue },
    name,
    onUpdate,
    onDelete,
  } = props;

  const handleUpdateClick = () => {
    onUpdate(name);
  };

  const handleDeleteClick = () => {
    onDelete(name);
  };

  const execDate = moment(execTime);
  const {
    type,
    value: { day },
  } = repeatValue;
  const getRepeatValue = repeatType => {
    let days;
    const time = execDate.format('LT');
    let passedDate;
    switch (repeatType) {
      case 'weekly':
        days = WEEK_DAYS.reduce((acc, { name: n, key }, idx) => {
          if (day.includes(key)) {
            acc.push(`${t(n)}, `);
          }
          if (idx === WEEK_DAYS.length - 1) {
            const tmp = acc[acc.length - 1];
            acc[acc.length - 1] = tmp.replace(', ', '');
          }
          return acc;
        }, []);
        return t('CAL.WEEKLY.SELECTED', { days: days.join(''), time });
      case 'monthly':
        passedDate = execDate.format(t('CAL.MONTHLY.DATE'));
        return t('CAL.MONTHLY.SELECTED', { date: passedDate, time });
      case 'yearly':
        passedDate = execDate.format(t('CAL.YEARLY.DATE'));
        return t('CAL.YEARLY.SELECTED', { date: passedDate, time });
      default:
        return `${t('CAL.NO_REPEAT')} ${time}`;
    }
  };
  return (
    <Page style={{ paddingBottom: '3rem' }}>
      <Segment style={{ margin: '1rem', padding: '1rem' }}>
        <Form error>
          <FormGroup>
            <FormLabel>{t('CAL.SUBJECT')}</FormLabel>
            <UnderLinedInput name="title" value={title} type="text" readOnly />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('CAL.START')}</FormLabel>
            <UnderLinedInput
              name="dateinput"
              value={execDate.format('YYYY-MM-DD')}
              type="date"
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('CAL.REPEAT')}</FormLabel>
            <UnderLinedInput
              name="repeat"
              value={getRepeatValue(type)}
              readOnly
            />
          </FormGroup>
          {command && (
            <FormGroup>
              <FormLabel>{t('CAL.CMD.LB')}</FormLabel>
              <UnderLinedInput name="botcommand" value={command} readOnly />
            </FormGroup>
          )}
        </Form>
        {error && <StyledMessage color="red">{error}</StyledMessage>}
      </Segment>
      <div>
        <FixedBottomButton
          style={{ left: '1rem', margin: '0 -1rem' }}
          widths={2}
          inverted
          onClick={handleUpdateClick}
        >
          <Icon name="edit" />
          {t('CAL.UPDATE.LB')}
        </FixedBottomButton>
        <FixedBottomButton
          style={{ right: '1rem', margin: '0 -1rem' }}
          widths={2}
          inverted
          onClick={handleDeleteClick}
        >
          <Icon name="delete calendar" />
          {t('CAL.DELETE.LB')}
        </FixedBottomButton>
      </div>
    </Page>
  );
};

export default ViewSchedule;
