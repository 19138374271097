import React from 'react';
import InstructionList from '../../app/InstructionList';

const PiboInstruction = props => {
  const { list, results, searching, onChangeResult, error } = props;
  const addSearchChange = value => {
    onChangeResult(value);
  };

  return (
    <div style={{ backgroundColor: 'rgba(212, 212, 213, 0.3)' }}>
      <InstructionList
        error={error}
        searching={searching}
        list={list}
        results={results}
        addSearchChange={addSearchChange}
      />
    </div>
  );
};

export default PiboInstruction;
