import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import shortId from 'shortid';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import SettingsMenu from './SettingsMenu';
import { setMenu } from '../ducks/page';
import { pageRefresh } from '../utils/common';

const StyledFixedMenu = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  // filter: brightness(1);
  background: ${props =>
    props.configMenu ? '#FBFBFB' : 'rgba(255, 255, 255, 0.85)'};
  z-index: 99;
  backdrop-filter: blur(1px);
  box-shadow: ${props =>
    props.configMenu ? 'none' : '0px 0px 2px rgba(0, 0, 0, 0.12)'};
  @media (max-height: 850px) {
    height: 40px;
  }
  @media (min-height: 851px) {
    height: 45px;
  }
`;

const StyledMenuLeft = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  padding: 7.5px 10px;
  display: flex;
  align-items: center;
`;
const StyledMenuRight = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  padding: 7.5px 10px;
  display: flex;
  align-items: center;
`;

const StyledMenuItem = styled.a`
  display: flex;
  cursor: pointer;
  &:hover {
    color: ${({ enabled }) => (enabled ? '#0894d7' : '#d1d3d4')};
  }
  color: ${({ enabled }) => (enabled ? '#03bfd7' : '#d1d3d4')};
  align-items: center;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: all 0.2s ease-in-out;
`;

const StyledIcon = styled(Icon)`
  margin: 0 !important;
  font-size: 1.25rem !important;
  height: auto !important;
  line-height: 1.25rem;
`;

const StyledTitle = styled.p`
  font-size: 1.75rem;
  font-weight: 600;
  font-family: 'Comfortaa', 'Nanum Gothic', 'Noto Sans SC', sans-serif;
  span {
    color: #d1d3d4;
    font-size: ${({ size }) => size || 'inherit'};
    &:nth-child(${props => `-n+${props.colored}`}) {
      color: #03bfd7;
    }
  }
`;

const AnimateMenu = styled.label`
  display: flex;
  flex-direction: column;
  width: 20px;
  cursor: pointer;
  align-items: end;
  span {
    background: #03bfd7;
    border-radius: 3px;
    height: 3px;
    margin: 2px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    &:nth-of-type(1) {
      width: 100%;
    }
    &:nth-of-type(2) {
      width: 90%;
    }
    &:nth-of-type(3) {
      width: 100%;
      opacity: 1;
    }
  }
  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(4.5px, 4.5px);
  }

  input[type='checkbox']:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
    width: 100%;
  }

  input[type='checkbox']:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(-2px, -5px) rotatez(45deg);
    opacity: 0;
  }
`;

const getMenuComps = menuMode => {
  const btnCtrl = {
    isBackButton: false,
    isUserIcon: false,
    isLoginLabel: false,
    isCompleteButton: false,
    isConfigButton: false,
    isMenuButton: false,
    isCloseButton: false,
    size: '',
  };

  switch (menuMode) {
    case 'WITH_LOGIN':
      btnCtrl.isUserIcon = true;
      btnCtrl.isLoginLabel = true;
      break;
    case 'WITH_USER':
      btnCtrl.isUserIcon = true;
      break;
    case 'WITH_MENU':
      btnCtrl.isMenuButton = true;
      break;
    case 'WITH_CONFIG':
      btnCtrl.isConfigButton = true;
      break;
    case 'WITH_BACK':
      btnCtrl.isBackButton = true;
      break;
    case 'WITH_CLOSE':
      btnCtrl.isCloseButton = true;
      break;
    case 'WITH_COMPLETE':
      btnCtrl.isCompleteButton = true;
      break;
    case 'WITH_BACK_AND_COMPLETE':
      btnCtrl.isBackButton = true;
      btnCtrl.isCompleteButton = true;
      break;
    case 'WITH_BACK_AND_MENU':
      btnCtrl.isBackButton = true;
      btnCtrl.isMenuButton = true;
      break;
    case 'WITH_BACK_PHOTO':
      btnCtrl.isBackButton = true;
      btnCtrl.size = '1.5rem';
      break;
    default:
      btnCtrl.isBackButton = false;
      break;
  }
  return btnCtrl;
};

const TopMenu = ({
  title,
  handleCompleteClick,
  menuMode,
  handleBackClick,
  enabled,
  colored,
  hidden,
  configMenu,
  t,
}) => {
  const dispatch = useDispatch();
  const btnCtrl = getMenuComps(menuMode);
  const handleTitleClick = () => {
    pageRefresh();
  };

  return (
    <>
      <StyledFixedMenu className="clearfix" configMenu={configMenu}>
        <StyledMenuLeft>
          <StyledMenuItem>
            {btnCtrl.isBackButton && (
              <i
                aria-hidden="true"
                className="menu-back icon"
                style={{ fontSize: '1.5rem', marginLeft: '-5px' }}
                onClick={handleBackClick}
              />
            )}
            {!configMenu && (
              <StyledTitle
                colored={colored}
                size={`${title.length > 8 ? '1.35rem' : '1.375rem'}`}
                onClick={handleTitleClick}
              >
                {title.split('').map(char => (
                  <span key={shortId.generate()}>{char}</span>
                ))}
              </StyledTitle>
            )}
          </StyledMenuItem>
        </StyledMenuLeft>
        <StyledMenuRight>
          {/* {btnCtrl.isUserIcon && (
              <StyledMenuItem
                name="login"
                onClick={handleCompleteClick}
                enabled={enabled}
              >
                {btnCtrl.isLoginLabel && t('MENU.SIGN_IN')}
                <StyledIcon name="user circle" />
              </StyledMenuItem>
            )} */}
          {btnCtrl.isUserIcon && (
            <AnimateMenu htmlFor="check">
              <input
                type="checkbox"
                id="check"
                defaultChecked={configMenu}
                onClick={() => dispatch(setMenu(!configMenu))}
              />
              <span />
              <span />
              <span />
            </AnimateMenu>
          )}
          {btnCtrl.isCloseButton && (
            <StyledMenuItem
              name="close"
              onClick={handleCompleteClick}
              enabled={enabled}
            >
              {/* <StyledIcon name="close" style={{ color: '#565B5B' }} /> */}
              <i
                aria-hidden="true"
                className="cclose icon"
                style={{ fontSize: '1.375rem', color: '#565B5B' }}
              />
            </StyledMenuItem>
          )}
          {btnCtrl.isCompleteButton && (
            <StyledMenuItem
              name="complete"
              onClick={handleCompleteClick}
              enabled={enabled}
            >
              {t('MENU.DONE')}
            </StyledMenuItem>
          )}
          {btnCtrl.isConfigButton && (
            <StyledMenuItem
              name="config"
              onClick={handleCompleteClick}
              enabled={enabled}
            >
              <StyledIcon name="cog" />
            </StyledMenuItem>
          )}
          {btnCtrl.isMenuButton && (
            <StyledMenuItem
              name="menu"
              onClick={handleCompleteClick}
              enabled={enabled}
              hidden={hidden}
            >
              <StyledIcon name="list ul" />
            </StyledMenuItem>
          )}
        </StyledMenuRight>
      </StyledFixedMenu>
      <SettingsMenu configMenu={configMenu} />
    </>
  );
};

TopMenu.propTypes = {
  title: PropTypes.string,
  menuMode: PropTypes.oneOf([
    'WITH_LOGIN',
    'WITH_USER',
    'WITH_MENU',
    'WITH_CONFIG',
    'WITH_BACK',
    'WITH_CLOSE',
    'WITH_COMPLETE',
    'WITH_BACK_AND_COMPLETE',
    'WITH_BACK_AND_MENU',
    'WITH_BACK_PHOTO',
    '',
  ]),
  enabled: PropTypes.bool,
  handleBackClick: PropTypes.func,
  handleCompleteClick: PropTypes.func,
  colored: PropTypes.number,
};

TopMenu.defaultProps = {
  title: '',
  menuMode: 'WITH_BACK',
  enabled: true,
  colored: 1,
  handleBackClick: () => {},
  handleCompleteClick: () => {},
};

export default withTranslation('common')(TopMenu);
