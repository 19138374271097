import React from 'react';
import MainContainer from './MainContainer';
import ScheduleViewContainer from './ScheduleViewContainer';
import ScheduleFieldContainer from './ScheduleFieldContainer';

const Index = props => {
  const { page, query, title } = props;
  return (
    <>
      {(page === 'main') && (<MainContainer title={title} />)}
      {(page === 'update' || page === 'add') && (<ScheduleFieldContainer {...query} title={title} mode={page} />)}
      {(page === 'view') && (<ScheduleViewContainer {...query} title={title} mode={page} />)}
    </>
  )
};

export default Index;
