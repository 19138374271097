/* eslint-disable react/static-property-placement */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import TopMenu from '../../TopMenu';

const BotTopMenu = ({
  title,
  menuMode,
  colored,
  enabled,
  backLocation,
  onClose,
  onComplete,
}) => {
  const navigate = useNavigate();
  const movePage = () => {
    if (backLocation) {
      if (typeof backLocation === 'boolean') {
        return navigate(-1);
      }
      return navigate(`${backLocation}`);
    }
    return navigate('/bots');
  };

  const onClickClose = () => {
    if (onComplete) {
      return onComplete(() => movePage());
    }
    if (onClose) {
      onClose();
    }
    return movePage();
  };

  return (
    <TopMenu
      title={title}
      colored={colored}
      menuMode={menuMode}
      handleBackClick={movePage}
      handleCompleteClick={onClickClose}
      enabled={enabled}
    />
  );
};

export default BotTopMenu;
