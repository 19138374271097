/* eslint-disable react/no-array-index-key */
import React, { useState, Fragment, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import {
  Segment,
  Grid,
  Icon,
  Card,
  Checkbox,
  Image,
  Button,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { UnderLinedMulitpleInput } from '../../Components';
import i18n from '../../../lang/i18n';
import { StyledMessage } from '../../Message';

const Player = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0894d7;
  border-radius: 1.5rem;
  transition: all 0.1s ease-in-out;
  font-family: 'Icons';
  font-size: 1.5rem;
  color: #0894d7;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  & > i.icon {
    font-size: 0.5em;
    width: auto;
    height: auto;
    margin: 0;
  }
`;

const FormSegment = styled(Segment)`
  margin: 1rem !important;
  padding: 1rem;
  min-height: calc(${window.innerHeight}px - 60px - 5rem);
  & p {
    &.label {
      font-size: 0.75rem;
      font-weight: bold;
    }
    &.preview-btn {
      color: #0894d7;
    }
    font-size: 0.875em;
    margin-bottom: 0.5em;
  }
`;

const IconMenuItemWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  transform: ${({ size }) => (size === 'small' ? 'scale(0.6)' : 'scale(1)')};
  & > div {
    border-radius: calc(100% / 2);
    background-color: ${({ color }) => `rgba(${color}, 0.3)`};
    color: ${({ color }) => `rgb(${color})`};
    width: 40px;
    height: 40px;
    box-shadow: ${({ selected }) =>
      selected ? '0px 1px 1px rgba(0, 0, 0, 0.25);' : 'none'};
    & > i.icon {
      margin: 6px;
      transform: ${({ selected }) => (selected ? 'scale(1.2)' : 'scale(1)')};
      transition: transfrom 0.2s;
    }
  }
  & > p {
    width: 100%;
    margin-top: 6px;
    font-size: 0.875rem;
    text-align: center;
    text-shadow: ${({ selected }) =>
      selected ? '0px 1px 1px rgba(0, 0, 0, 0.25);' : 'none'};
    font-weight: ${({ selected }) => selected && 'bold'};
  }
  &:active,
  &:focus,
  &:hover {
    i.icon {
      transform: scale(1.2);
    }
    p {
      font-weight: bold;
    }
  }
`;

const MenuBody = styled.div`
  width: 100%;
  height: ${({ open, height }) =>
    open ? `${parseInt(height, 10) - 16}px` : '70px'};
  position: fixed;
  bottom: 0;
  transition: height 0.5s ease-in-out 0s;
  background: #f9fafb;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px 10px 0px 0px;
  padding-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 199;
`;

const MenuArrow = styled.span`
  display: block;
  margin: 0 auto;
  width: 28px;
  height: 28px;
  border-top: 3px solid #dcddde;
  border-right: 3px solid #dcddde;
  border-radius: 3px;
  transform: ${({ open }) =>
    open
      ? 'rotate(135deg) skew(18deg, 18deg)'
      : 'rotate(-45deg) skew(18deg, 18deg)'};
  transition: all 0.1s ease-in-out 0.4s;
`;

const MenuLabel = styled.div`
  margin: 0 auto;
  font-weight: bold;
  color: #0894d7;
  display: flex;
  justify-content: center;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const MenuButton = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
`;

const IconMenuItem = ({
  color,
  name,
  onClick,
  selected,
  icon,
  label,
  size,
  style,
}) => (
  <IconMenuItemWrapper
    color={color}
    name={name}
    onClick={onClick}
    selected={selected}
    size={size}
    style={style}
  >
    <div>
      <i aria-hidden="true" className={`${icon} icon large`} />
    </div>
    {label && <p>{label}</p>}
  </IconMenuItemWrapper>
);

const IconMenu = ({ menus, onChange }) => {
  const [select, setSelect] = useState(-1);
  const onClick = (e, i) => {
    setSelect(i);
    onChange(e, i);
  };
  return (
    <Segment raised>
      <Grid>
        <Grid.Row columns={4}>
          {menus.map(({ color, icon, label, name, key }, i) => (
            <Grid.Column key={key}>
              <IconMenuItem
                color={color}
                name={name}
                onClick={e => onClick(e, i)}
                selected={i === select}
                icon={icon}
                label={label}
                style={{ cursor: 'pointer' }}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const BottomUpMenu = ({ open, label, disabled, onClick, children, height }) => (
  <MenuBody open={open} height={height}>
    <MenuButton onClick={onClick}>
      <MenuArrow open={open} />
      {!open && (
        <MenuLabel disabled={disabled}>
          <Icon name="plus" />
          {label}
        </MenuLabel>
      )}
    </MenuButton>
    {open && (
      <div
        style={{
          width: '100%',
          overflowY: 'auto',
          height: 'calc(100vh - 3rem)',
          padding: '.5rem 1rem',
        }}
      >
        {children}
      </div>
    )}
  </MenuBody>
);

export const StyledCard = styled(Card)`
  && {
    box-shadow: ${({ theme }) =>
      `inset 3px 0 0 0 rgb(${theme}), 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;`};
  }
  & > div,
  & > p,
  & > span,
  & > img {
    margin-bottom: ${({ gap }) => (gap ? `${gap};` : `1rem`)};
  }
  & div.exec-content {
    font-size: 0.875rem;
    margin-bottom: 0.5em;
    display: inline-flex;
    flex-wrap: wrap;
    & > strong {
      font-size: 1rem;
      color: ${({ theme }) => `rgb(${theme});`};
      margin: 0 0.5em;
      font-weight: bold;
    }

    & > span {
      margin: 0 0.5em 0 0;
    }
    & img {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 1.5rem;
      border: 1px solid #dcddde;
      /* margin-right: 6px; */
      background: #fff;
    }
  }
  display: flex;
`;

const BorderedSegment = styled.div`
  position: relative;
  border-radius: ${({ radius }) => `${radius}px` || 0};
  border: 1px solid #dcddde;
  padding: ${({ padding }) => (padding ? `${padding}` : '10px')};
  background: #fff;
`;

const PlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 25px;
  & p {
    margin: 0 0.875em;
  }
  & p.title {
    font-weight: bold;
    color: #0894d7;
    margin-left: 0;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: inherit;
  }
`;

const CardBody = styled.div`
  margin: 0 0.75rem;
  font-size: 0.875rem;
  & > p {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`;

const CardHeader = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardButtonGroup = styled.div`
  padding: 1rem 1rem 0.5rem;
  & > .ui.button,
  .ui.buttons .button,
  .ui.buttons .or {
    opacity: ${({ selected }) => (selected ? 1 : 0.8)};
    font-size: 0.825rem;
    &:hover {
      opacity: 1;
    }
  }
`;

export const CardFooter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.75em;
`;

const CardTitle = styled.div`
  display: inline-flex;
  align-items: center;
  margin-left: 0.25rem;
`;

const CardOption = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 0.25rem;
  font-size: 0.75em;
`;

const TextInput = styled.input`
  margin: 0;
  outline: 0;
  font-size: 1em;
  line-height: 1.35714286em;
  padding: 0.71428571em 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;
  width: 100%;
  vertical-align: top;
  overflow: visible;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  -webkit-rtl-ordering: logical;
  cursor: text;
  &::selection {
    background-color: #cce2ff;
    color: rgba(0, 0, 0, 0.87);
  }
  &::placeholder {
    color: rgba(191, 191, 191, 0.87);
  }
`;

const HelpText = styled.span`
  font-size: 0.875em;
  margin: 0.5em;
`;

const InvalidText = styled.span`
  font-size: 0.875em;
  color: #ef3340;
  margin: 0.5em;
`;

const ColorItem = styled.span`
  width: 1.5em;
  height: 1.5em;
  border-radius: 1.5em;
  border: 1px solid #f3f3f3;
  box-shadow: ${({ selected, strong }) =>
    selected
      ? '0px 0px 2px 1px rgba(0, 0, 0, 0.25)'
      : `0px 0px 1px rgba(0, 0, 0, ${strong ? 0.5 : 0.25})`};
  background: ${({ color }) => `rgb(${color})`};
  position: relative;
  border-radius: 500em !important;
  line-height: 1 !important;
  padding: 0.25em 0 !important;
  display: inline-block;
  opacity: 1;
  margin: ${({ padded }) => (padded ? '0 0.5em !important' : '0 0.25rem 0 0')};
  font-family: Icons;
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  text-align: center;
  backface-visibility: hidden;
  margin: 16px 16px 8px 16px;
  ::after {
    content: '';
    opacity: ${({ selected }) => (selected ? 1 : 0)};
    content: '\f00c';
    color: #fff;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  }
`;

const ToggleButtonItem = styled.div`
  display: inline-flex;
  color: #565b5b;
  padding: 6px;
  border-radius: 5px;
  background: ${({ selected, color }) =>
    selected ? `rgba(${color}, .3)` : 'transparent'};
  & img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 1px solid #dcddde;
    margin-right: 6px;
    background: #fff;
  }
  & > span {
    font-weight: bold;
    color: ${({ selected, color }) => (selected ? `rgb(${color})` : 'inherit')};
  }
  margin-right: 1rem;
`;

const ToggleTextItem = styled.div`
  display: inline-flex;
  color: #0894d7;
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  text-shadow: ${({ selected }) =>
    selected ? '0 0 1px rgba(8, 148, 215, 0.5)' : 'none'};
  margin-right: 1.142857em;
  margin-bottom: 0.714285em;
`;

const ExecContentBody = styled.div`
  font-size: 0.875em;
  & > strong {
    font-size: 1em;
    color: ${({ color }) => `rgb(${color});`};
    font-weight: bold;
  }
  & > img {
    width: 1.5em;
    height: 1.5em;
    border-radius: 1.5em;
    border: 1px solid #dcddde;
    margin-right: 6px;
    background: #fff;
  }
`;

const CommandInput = ({ cmd, onChange, onDelete, onKey, error }) => {
  const inputRef = useRef(null);

  const handleHeight = () => {
    onKey(inputRef);
  };
  return (
    <>
      <p className="label">{i18n.t('bot:TRN.LABEL.CMD')}</p>
      <div
        style={{
          position: 'relative',
          marginBottom: error ? '.5rem' : '1rem',
          borderBottom: '1px solid #0894d7',
          paddingRight: '1.5rem',
        }}
      >
        <UnderLinedMulitpleInput
          name="cmd"
          value={cmd}
          rows={1}
          placeholder={i18n.t('bot:TRN.PLACEHOLDER.CMD')}
          onChange={onChange}
          onKeyDown={handleHeight}
          onKeyUp={handleHeight}
          style={{ width: '100%' }}
          ref={inputRef}
        />
        <Icon
          name="x"
          color="blue"
          disabled={!(cmd && cmd.length > 0)}
          onClick={onDelete}
          style={{ position: 'absolute', right: '0' }}
        />
      </div>
      {error && (
        <StyledMessage
          color="red"
          textalign="left"
          size="mini"
          style={{ marginBottom: '1rem' }}
        >
          {error}
        </StyledMessage>
      )}
    </>
  );
};

const TrainListItemCard = styled.div`
  display: inline-flex;
  width: 100%;
  margin: 0 auto 0.625em;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 10px;
  border: 1px solid #0894d7;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  & .ui.checkbox {
    margin-top: 2px;
    margin-right: 0.625em;
  }
  & .vertical-col {
    overflow: hidden;
    margin-right: 0.625em;
    width: 100%;
    & .train-header {
      margin-bottom: 0.625em;
      line-height: 1.4285em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: bold;
      color: #0894d7;
    }
    & .train-bots {
      display: inline-flex;
      & span {
        margin: 0 2px !important;
      }
    }
  }
  & .train-play-wrap {
    align-self: flex-end;
    width: 1.5em;
    height: 1.5em;
    border: 1px solid #0894d7;
    border-radius: 1.5em;
    background-color: #fff;
    & > i.circular-icon {
      color: #0894d7;
      box-shadow: none;
      padding: 0 2px 0 1px;
      display: inline-block;
      opacity: 1;
      width: inherit;
      height: inherit;
      font-family: Icons;
      font-size: 0.75em;
      font-style: normal;
      font-weight: 100;
      text-decoration: inherit;
      text-align: center;
      backface-visibility: hidden;
      &.play::before {
        content: '\f04b';
      }
      &.stop::before {
        content: '\f04d';
      }
    }
  }
`;

const TrainListItemIcon = styled.div`
  background-color: ${({ color }) => `rgba(${color}, .3);`};
  border-radius: 1rem;
  width: 20px;
  height: 20px;
  color: ${({ color }) => `rgb(${color});`};
  font-size: 0.75em;
  margin-right: 0.25em;
  & > i.icon {
    width: 80%;
    height: auto;
    margin: 0 10%;
  }
`;

const TrainListItem = props => {
  const {
    id,
    checked,
    title,
    bots,
    playing,
    toggle,
    onSelect,
    onClick,
    onPreview,
  } = props;

  return (
    <TrainListItemCard>
      {toggle && <Checkbox checked={checked} onChange={() => onSelect(id)} />}
      <div
        className="vertical-col"
        role="button"
        aria-hidden="true"
        onClick={() => onClick(id)}
      >
        <header className="train-header">{title}</header>
        <div className="train-bots">
          {bots &&
            bots.map(({ color, icon, key }) => (
              <TrainListItemIcon color={color} key={key}>
                <i aria-hidden="true" className={`${icon} icon`} />
              </TrainListItemIcon>
            ))}
        </div>
      </div>
      {!toggle && (
        <div
          className="train-play-wrap"
          aria-hidden="true"
          onClick={() => onPreview(id, title)}
        >
          <i
            aria-hidden="true"
            className={playing ? `stop circular-icon` : `play circular-icon`}
          />
        </div>
      )}
    </TrainListItemCard>
  );
};

const ListSegment = styled.div`
  margin: ${({ padded }) => (padded ? '1rem' : 'inherit')};
  height: ${({ padded }) =>
    padded
      ? `calc(${window.innerHeight}px - 60px - 5rem)`
      : `calc(${window.innerHeight}px - 60px - 2rem)`};
  /* background: #fff; */
  /* height: calc(${window.innerHeight}px - 60px - 5rem); */
  font-size: 0.875rem;
  @media (min-width: 700px) {
    font-size: 1rem;
  }
`;

const Layout = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  margin: 1rem;
  padding: 1rem;
  text-align: center;
`;

const NoList = () => (
  <ListSegment padded>
    <Layout>
      <Label>{i18n.t('bot:TRN.MSG.GUIDE')}</Label>
      <Image
        src="../../image/curious_210823.png"
        style={{ width: '80%', maxWidth: '30rem' }}
      />
    </Layout>
  </ListSegment>
);

export const TextButton = styled.span`
  font-weight: bold;
  color: ${({ color }) => color || '#0894d7'};
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
`;

const TrainListWrapper = styled.div`
  min-width: 310px;
  padding: 1rem;
  margin: 1rem;
  background: #fff;
`;

const TrainListTopMenu = styled.div`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.625em;
`;

const TrainList = props => {
  const {
    play,
    list,
    total,
    toggle,
    onDelete,
    onSelect,
    onToggle,
    onItemClick,
    onPreviewClick,
    onMore,
  } = props;
  const [checked, setChecked] = useState([]);
  const handleSelect = id => {
    if (checked.indexOf(id) >= 0) {
      setChecked(checked.filter(item => id !== item));
    } else {
      setChecked([...checked, id]);
    }
  };

  const handleClick = id => {
    if (!toggle && id) {
      onItemClick(id);
    }
  };

  const handleDelete = e => {
    if (checked.length < 1) return;
    onDelete(e);
  };

  useUpdateEffect(() => {
    onSelect(checked);
  }, [checked]);

  return (
    <ListSegment style={{ overflowY: 'auto' }}>
      {!total && <NoList />}
      {total > 0 && list && (
        <TrainListWrapper>
          {!toggle && (
            <TrainListTopMenu>
              <span style={{ fontSize: '0.75rem' }}>
                {i18n.t('bot:TRN.LABEL.TOTAL_CMD', {
                  total,
                  length: list.length,
                })}
              </span>
              <TextButton color="#999999" onClick={onToggle}>
                {i18n.t('bot:TRN.LABEL.DELETE')}
              </TextButton>
            </TrainListTopMenu>
          )}
          {toggle && (
            <TrainListTopMenu>
              <TextButton
                color="#ef3340"
                onClick={handleDelete}
                disabled={checked.length === 0}
              >
                {i18n.t('bot:TRN.LABEL.DELETE')}
              </TextButton>
              <TextButton onClick={onToggle}>
                {i18n.t('bot:TRN.BTN.COMPLETE')}
              </TextButton>
            </TrainListTopMenu>
          )}
          {list.map(({ id, title, bots }) => (
            <TrainListItem
              {...{
                id,
                title,
                bots,
                toggle,
                playing: play === id,
                onClick: handleClick,
                onPreview: onPreviewClick,
                onSelect: handleSelect,
                checked: checked.indexOf(id) > -1,
              }}
              key={id}
            />
          ))}
          {list.length < total && (
            <Button onClick={onMore} fluid color="blue">
              {i18n.t('bot:TRN.LABEL.MORE')}
            </Button>
          )}
        </TrainListWrapper>
      )}
    </ListSegment>
  );
};

const TrainListError = styled.div`
  text-align: center;
  margin: 1rem;
`;

export {
  Player,
  IconMenu,
  BottomUpMenu,
  IconMenuItem,
  CommandInput,
  TrainListItem,
  TrainList,
  PlayerWrapper,
  TrainListError,
  BorderedSegment,
  CardBody,
  CardHeader,
  CardTitle,
  CardOption,
  TextInput,
  InvalidText,
  HelpText,
  ToggleButtonItem,
  ToggleTextItem,
  FormSegment,
  ColorItem,
  ExecContentBody,
};
