import React from 'react';
import styled from 'styled-components';
import { GridColumn, Image, Placeholder } from 'semantic-ui-react';
import { getColumns } from '../../utils/common';
import { getFile } from '../../utils/api';

const columns = getColumns();

const Item = styled(GridColumn)`
  /* margin-bottom: 0.75rem !important; */
  /* padding: 0 0.75rem !important; */
  margin: 0 !important;
  text-align: center;
  @media (max-width: 500px) {
    font-size: 1rem;
    padding: 0.25rem 0.5rem 0.5rem !important;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    font-size: 1.25rem;
    padding: 0.375rem 0.75rem 0.75rem !important;
  }
  @media (min-width: 1201px) and (max-width: 2000px) {
    font-size: 1.5rem;
    padding: 0.5rem 1rem 1rem !important;
  }
  @media (min-width: 2001px) {
    font-size: 1.75rem;
    padding: 0.625rem 1.25rem 1.25rem !important;
  }
`;

const ItemImage = styled(Image)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px !important;
  overflow: hidden;
  display: inline-block !important;
  margin: 0 auto;
  & {
    background-color: rgba(255, 255, 255, 0.5) !important;
    /* min-width: calc((100vw - 6rem) / ${columns}); */
    /* min-height: calc((100vw - 6rem) / ${columns}); */
    .ui.corner.label {
      font-size: 0.5em;
    }
    & > img {
      width: 100%;
    }
  }
`;

const ItemTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 100;
  font-size: 0.75em;
  color: #fff;
  /* margin: 0.32rem -5% 0; */
  /* width: 110%; */
  text-align: center;
  margin-top: 0.5em;
  text-shadow: 0 0 4px rgb(0 0 0 / 12%);
  &:lang(en) {
    font-weight: 600;
    font-size: 0.857em;
  }
`;

const BotItemImage = ({ label, id, src }) => (
  <div style={{ position: 'relative' }}>
    <Placeholder style={{ borderRadius: '15px' }}>
      <Placeholder.Image square />
    </Placeholder>
    <ItemImage label={label} id={id} src={src} />
  </div>
);

const BotListItem = props => {
  const { bot, title, type, handleBotClick } = props;
  const label =
    type === 2
      ? {
          as: 'a',
          color: 'yellow',
          corner: 'right',
          icon: 'flask',
        }
      : null;

  return (
    <Item onClick={handleBotClick}>
      <BotItemImage
        label={label}
        id={`${bot}item`}
        src={getFile({ icon: bot })}
      />
      <ItemTitle>{title}</ItemTitle>
    </Item>
  );
};

export default BotListItem;
