/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, FormInput, Icon, Select } from 'semantic-ui-react';

export const PageWrap = styled.div`
  background: #fff;
  width: 100vw;
  max-height: max-contnet;
  min-height: ${window.innerHeight}px;
  position: absolute;
`;

export const AccountForm = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

export const AccountWrap = styled.div`
  margin: 0 !important;
  width: 80vw;
  & > form {
    margin-bottom: 1rem;
  }
`;

const getColor = status => {
  switch (status) {
    case 'error':
      return '#EF3340';
    case 'info':
      return '#21BA45';
    case 'warning':
      return '#F2C037';
    default:
      return '#000000';
  }
};

const TextInput = styled.input`
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  border-bottom: 1px solid rgba(8, 148, 215, 1) !important;
  border-radius: 0 !important;
  &:disabled {
    border-bottom: 0 !important;
    opacity: 1 !important;
  }
  &::selection {
    color: #fff;
  }
  &::before {
    content: ${({ value }) => (value ? '' : 'attr(placeholder)')} !important;
    color: rgba(191, 191, 191, 0.87);
    background-color: #fff;
    width: 100%;
  }
  &:focus:before {
    content: '' !important;
    width: 0;
    background-color: transparent;
    color: inherit;
  }
`;

const TextMessage = styled.span`
  color: ${({ status }) => getColor(status)};
  font-size: 0.75rem;
  margin: 0.25rem 0;
`;

export const AccountButton = styled(Button.Group)`
  height: 60px;
  margin: 0 !important;
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 999 !important;
  &&& {
    flex-flow: row-reverse;
  }
  & button.ui.button {
    border-radius: 0 !important;
  }
`;

export const AccountSelect = styled(Select)`
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  border-bottom: 1px solid rgba(8, 148, 215, 1) !important;
  &&& {
    border-radius: 0 !important;
    min-width: auto !important;
    min-height: auto !important;
    line-height: 1.4285em;
    padding: 0.47em 1em;
    width: 100%;
  }
`;

export const AccountDateInput = styled(FormInput)`
  & input {
    appearance: none !important;
  }
`;

export const TextButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  color: gray;
  padding: 0;
  cursor: pointer;
`;

export const AccountInput = ({
  message,
  info,
  error,
  warning,
  label,
  ...props
}) => (
  <div style={{ width: '100%', display: 'flex', flexFlow: 'column' }}>
    <div>{label}</div>
    <TextInput {...props} />
    {message && info && <TextMessage status="info">{message}</TextMessage>}
    {message && error && <TextMessage status="error">{message}</TextMessage>}
    {message && warning && (
      <TextMessage status="warning">{message}</TextMessage>
    )}
  </div>
);

const StepsWrap = styled.div`
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  width: 100vw;
  background-color: rgba(209, 211, 212, 0.24);
  padding: 1rem;
  @media (min-width: 600px) {
    justify-content: space-evenly;
  }
`;

const Step = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
`;

const StepLine = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 100;
  color: #e0e1e2;
`;

const StepIcon = styled.div`
  width: 40px;
  height: 40px;
  color: #fff;
  background-color: ${({ color }) => color};
  opacity: ${({ disabled }) => (disabled ? 0.45 : 1)};
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ active }) =>
    active
      ? '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
      : 'none'};
  & > span {
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    animation: fadeOut 0.5s ease-in;
  }
  & i.icon {
    margin: -0.25rem 0 0;
    animation: fadeIn 0.5s ease-out;
  }
`;

const StepLabel = styled.p`
  color: ${({ active }) => (active ? 'rgba(0,0,0,.87)' : 'rgba(0,0,0,.45)')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  font-size: 0.75rem;
  margin: 0.75em 0.5em;
  text-align: center;
`;

export const Steps = ({ steps, activeIndex, color: c }) => {
  let color = '#0894D7';
  if (c === 'teal') {
    color = '#03BFD7';
  } else if (c === 'blue') {
    color = '#0894D7';
  }

  return (
    <StepsWrap>
      {steps.map(({ id, label }, index) => (
        <Fragment key={id}>
          <Step>
            <StepIcon
              completed={index < activeIndex}
              active={index === activeIndex}
              disabled={index > activeIndex}
              color={color}
            >
              {index < activeIndex && <Icon name="check" />}
              {index >= activeIndex && <span>{index + 1}</span>}
            </StepIcon>
            <StepLabel active={index === activeIndex}>{label}</StepLabel>
          </Step>
          {index < steps.length - 1 && (
            <StepLine>
              <Icon name="ellipsis horizontal" />
            </StepLine>
          )}
        </Fragment>
      ))}
    </StepsWrap>
  );
};

export const CompleteDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 100%;
  height: 100%;
  & h4.ui.header {
    text-align: center;
  }
  & .ui.image {
    transform: translateY(16px) scale(1.3);
    @media (max-width: 500px) {
      max-width: 80vw;
    }
    @media (min-width: 501px) and (max-width: 1200px) {
      max-width: 70vw;
    }
    @media (min-width: 1201px) {
      max-width: 600px;
    }
  }
`;
