import React from 'react';
import styled from 'styled-components';
import { ListItem, ListContent, Icon } from 'semantic-ui-react';

const NetworkListContent = styled(ListContent)`
  display: flex;
  flex-flow: row-reverse;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  width: 100%;
  position: relative;
`;

const IconContent = styled.div`
  position: absolute;
  right: 0;
  opacity: 0.6;
`;
const TitleContent = styled.div`
  position: absolute;
  left: 0;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PiboWiFiListItem = ({ essid, encrypted, address, idx, onChange }) => (
  <ListItem as="a" value={address} name={`item_${idx}`} onClick={onChange}>
    <NetworkListContent verticalAlign="middle">
      <IconContent>
        {encrypted && <Icon name="lock" color="grey" />}
        <Icon name="wifi" color="grey" />
      </IconContent>
      <TitleContent>{essid}</TitleContent>
    </NetworkListContent>
  </ListItem>
);

export default PiboWiFiListItem;
