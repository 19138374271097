/* eslint-disable react/static-property-placement */
import React from 'react';
import { useMount } from 'react-use/lib';
import CSList from './CSList';

const AppCustomerService = ({
  onCSMount,
  onCSSearchResult,
  list,
  results,
  loading,
  error,
}) => {
  useMount(() => {
    onCSMount();
  });

  const addSearchChange = value => onCSSearchResult(value);

  return (
    <CSList
      error={error}
      searching={loading}
      list={list}
      results={results}
      addSearchChange={addSearchChange}
    />
  );
};

export default AppCustomerService;
