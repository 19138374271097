import React from 'react';
import StoreSearchItem from './StoreSearchItem';
import { StoreList } from './StoreComponents';

const StoreSearchList = ({ handleItemClick, results, error }) => (
  // 검색 후 설치, 제거 상태가 애매해서 일단 버튼 제거함 추후 추가
  <StoreList>
    {error && results.length < 1 && <div>{error}</div>}
    {!error &&
      results.length > 0 &&
      results.map(bot => (
        <StoreSearchItem
          {...bot}
          key={bot.id}
          handleItemClick={handleItemClick}
          fluid={false}
        />
      ))}
  </StoreList>
);

export default StoreSearchList;
