/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/state-in-constructor */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Dimmer, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import shortid from 'shortid';

const StyledPopUp = styled(Segment)`
  &&& {
    border-radius: 0 !important;
    position: relative !important;
    padding: 1rem 0 0 !important;
    min-width: 70vw;
    max-width: 75vw;
  }
`;

const StyledHeader = styled.div`
  text-align: 'center';
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
`;

const StyledContent = styled.div`
  text-align: 'center';
  font-size: 1rem;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  line-height: 1.2rem;
`;

const StyledButtonGroup = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
`;

const StyledButton = styled.div`
  width: 100%;
  border: 0;
  border-radius: 0;
  background: #fff;
  border-top: ${({ color }) => `1px solid ${color}` || '#FFF'};
  color: ${({ color }) => color || '#FFF'};
  display: block;
  font-size: 1rem;
  padding: 1rem;
  height: 100%;
`;

const VerticalConfirm = ({
  isPopup: bPop,
  items,
  contents,
  header,
  onClose,
}) => {
  const [isPopup, setPopup] = useState(bPop || false);

  useEffect(() => {
    setPopup(bPop);
  }, [bPop]);

  const close = () => {
    setPopup(false);
  };

  const btnColor = value => {
    switch (value) {
      case 'red':
        return '#EF3340';
      case 'blue':
        return '#0894D7';
      default: {
        if (value && value.length === 7 && value.charAt(0) === '#') {
          return value;
        }
        return '#565B5B';
      }
    }
  };

  return (
    <Dimmer active={isPopup} onClickOutside={close} page>
      <StyledPopUp>
        {header && header.length > 0 && <StyledHeader>{header}</StyledHeader>}
        <StyledContent>{contents}</StyledContent>
        <StyledButtonGroup>
          {items &&
            items.map(({ label, func, color }) => (
              <StyledButton
                onClick={() => {
                  func();
                  onClose();
                }}
                color={btnColor(color)}
                key={shortid.generate()}
              >
                {label}
              </StyledButton>
            ))}
        </StyledButtonGroup>
      </StyledPopUp>
    </Dimmer>
  );
};

VerticalConfirm.propTypes = {
  isPopup: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(PropTypes.object),
  contents: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  header: PropTypes.string,
};

export default VerticalConfirm;
