/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMount, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import UserWithdraw from '../../components/pibo/settings/UserWithdraw';
import {
  getUserId,
  Alert,
  getUserPId,
  imageUrlToBase64,
  pageRefresh,
} from '../../utils/common';
import { Page } from '../../components/Components';
import { MENU_TYPES } from '../../utils/consts';
import UserInfoContainer from './UserInfoContainer';
import { updateUserInfo, withdraw } from '../../ducks/userinfo';
import withAuth from '../../hocs/withAuth';
import { RAPI_URL } from '../../circulusConfig';

const { WITH_BACK, WITH_BACK_AND_COMPLETE } = MENU_TYPES;

const UserSettingDetailPage = () => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { menu } = useParams();
  const dispatch = useDispatch();
  const userinfo = useSelector(state => state.userinfo);
  const {
    isComplete,
    error,
    loading,
    user: {
      sent: { email: sentMail, tel: sentTel },
      verified: { email: verifyMail, tel: verifyTel },
    },
  } = userinfo;
  const [enableCompleteBtn, setCompleteBtn] = useState(false);
  const [menuObj, setMenu] = useState({});

  const handleCompleteClick = () => {
    if (enableCompleteBtn) {
      return dispatch(updateUserInfo({ userId: getUserId() }));
    }
    return Alert(t('SET.ERR.UNCOMPLETE'));
  };

  useMount(() => {
    const obj = {};
    switch (menu) {
      case 'update':
        obj.mode = WITH_BACK_AND_COMPLETE;
        obj.title = t('UPDATE_MY_INFO');
        break;
      case 'withdraw':
        obj.mode = WITH_BACK;
        obj.title = t('WITHDRAW.LB');
        break;
      default:
        obj.mode = WITH_BACK;
        obj.title = t('SET.LB');
        break;
    }
    setMenu(obj);
  });

  const handleBackClick = () => {
    navigate(`/setting/${getUserId()}`);
  };

  const handleWithdrawClick = () => {
    dispatch(withdraw({ userOId: getUserPId() }));
  };

  const uid = getUserId();

  useUpdateEffect(() => {
    if (loading) return;
    // if (isComplete && menu === 'first') {
    //   return navigate('/pibo');
    // }
    if (isComplete && (menu === 'update' || menu === 'nickname')) {
      navigate(`/setting/${uid}`);
      //사용자 이미지 리로드를 위해서 reload()호출
      if (menu === 'update') {
        return pageRefresh();
      }
    }
    if (isComplete && menu === 'pwupdate') {
      return navigate(`/update/${uid}`);
    }
    if (isComplete && menu === 'withdraw') {
      navigate('/login');
      return pageRefresh();
    }
  }, [isComplete]);

  useEffect(() => {
    let msg = '';
    Object.values(error).forEach(v => {
      if (!(v === false || v === null || v === '')) {
        if (typeof v === 'string') {
          msg += v;
        } else {
          msg = Object.values(v).reduce((prev, curr) => {
            if (!(curr === false || curr === null || curr === '')) {
              return prev + curr;
            }
            return prev;
          }, msg);
        }
      }
    });
    let verified = false;
    if (verifyTel && verifyMail) {
      verified = true;
    } else if (
      verifyTel &&
      (!(sentMail || verifyMail) || (sentMail && verifyMail))
    ) {
      verified = true;
    } else if (
      verifyMail &&
      (!(sentTel || verifyTel) || (sentTel && verifyTel))
    ) {
      verified = true;
    }
    setCompleteBtn(msg.length === 0 && verified);
  }, [error, sentMail, sentTel, verifyMail, verifyTel]);

  return (
    <Page>
      <TopMenu
        title={menuObj.title}
        menuMode={menuObj.mode}
        handleBackClick={handleBackClick}
        handleCompleteClick={handleCompleteClick}
        enabled={enableCompleteBtn}
      />
      {menu === 'update' && (
        <UserInfoContainer loading={loading} userinfo={userinfo} />
      )}
      {menu === 'withdraw' && (
        <UserWithdraw
          {...{
            loading,
            error: error.withdraw,
            handleWithdrawClick,
          }}
        />
      )}
    </Page>
  );
};

export default withAuth(UserSettingDetailPage);
