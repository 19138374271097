/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUnmount, useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { getRobotId, getUserId } from '../utils/common';
import { setScroll, setSetting } from '../ducks/page';
import TopMenu from '../components/TopMenu';
import MainBottomMenu from '../components/MainBottomMenu';

const MainPageComponent = styled.div`
  position: absolute;
  @media (max-height: 850px) {
    top: 40px;
  }
  @media (min-height: 851px) {
    top: 45px;
  }
  left: 0;
  right: 0;
  bottom: ${({ isBottom }) => (isBottom ? '3rem' : 0)};
  width: 100%;
  overflow-y: auto;
`;

const MainPage = ({ children }) => {
  const pageRef = useRef(null);
  const dispatch = useDispatch();
  const { isSetting, configMenu } = useSelector(state => state.page);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { loading } = useSelector(state => state.store);
  const { t } = useTranslation();
  const DEFAULT_OBJ = {
    title: 'pibo',
    colored: 2,
    menuMode: 'WITH_USER',
    handleCompleteClick: () => navigate(`/setting/${getUserId()}`),
    selectMenu: 'pibo',
  };
  const [template, setTemplate] = useState({ ...DEFAULT_OBJ });

  const {
    title,
    colored,
    menuMode,
    selectMenu,
    handleCompleteClick,
    handleBackClick,
  } = template;

  const handleChangeScroll = () => {
    const { clientHeight, scrollTop, scrollHeight } = pageRef.current;
    if (
      !loading &&
      scrollTop + clientHeight >= scrollHeight - clientHeight / 2
    ) {
      dispatch(setScroll(scrollTop + clientHeight));
    }
  };

  useEffect(() => {
    const p = pathname.substring(1, pathname.length);
    dispatch(setSetting(false));
    if (p.indexOf('botstore') === 0) {
      pageRef.current.addEventListener('scroll', handleChangeScroll);
      return setTemplate({
        title: 'botstore',
        colored: 3,
        menuMode: getRobotId() && 'WITH_MENU',
        handleCompleteClick: () => navigate('/mybots'),
        selectMenu: 'botstore',
      });
    }
    dispatch(setScroll(null));
    if (p.indexOf('bots') === 0) {
      return setTemplate({
        title: 'bots',
        colored: 3,
        menuMode: 'WITH_CONFIG',
        handleBackClick: () => dispatch(setSetting(false)),
        handleCompleteClick: () => dispatch(setSetting(!isSetting)),
        selectMenu: 'bots',
      });
    }
    if (p.indexOf('mybots') === 0) {
      return setTemplate({
        title: t('store:MY_BOT.LB'),
        menuMode: 'WITH_BACK',
        handleBackClick: () => navigate('/botstore'),
        selectMenu: 'botstore',
      });
    }
    if (p === 'avatar') {
      return setTemplate({
        title: 'avatar',
        colored: 1,
        menuMode: '',
        handleCompleteClick: () => navigate(-1),
        selectMenu: 'avatar',
      });
    }
    if (p === 'history') {
      return setTemplate({
        title: 'history',
        colored: 1,
        menuMode: '',
        handleCompleteClick: () => navigate(-1),
        selectMenu: 'history',
      });
    }
    return setTemplate({
      title: 'pibo',
      colored: 2,
      menuMode: 'WITH_USER',
      handleCompleteClick: () => navigate(`/setting/${getUserId()}`),
      selectMenu: 'pibo',
    });
  }, [pathname]);

  useUnmount(() => {
    if (pageRef && 'current' in pageRef && pageRef.current) {
      pageRef.current.removeEventListener('scroll', handleChangeScroll);
    }
  });

  useUpdateEffect(() => {
    setTemplate({
      ...template,
      menuMode: isSetting ? 'WITH_BACK' : 'WITH_CONFIG',
    });
  }, [isSetting]);

  return (
    <MainPageComponent ref={pageRef} className="main page">
      <TopMenu
        {...{
          title,
          colored,
          menuMode,
          handleCompleteClick,
          handleBackClick,
          configMenu,
        }}
      />
      {children}
      <MainBottomMenu {...{ selectMenu }} />
    </MainPageComponent>
  );
};

export default MainPage;
