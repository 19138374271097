import React from 'react';
import { useTranslation } from 'react-i18next';
import { BlueTabMenu, BlueTabMenuItem } from '../../Components';

const TablMenu = props => {
  const { t } = useTranslation('bot');
  const { onClick, activeItem } = props;
  return (
    <BlueTabMenu widths={3}>
      <BlueTabMenuItem
        name="motion"
        active={activeItem === 'motion'}
        onClick={onClick}
      >
        {t('AVA.MOVING')}
      </BlueTabMenuItem>
      <BlueTabMenuItem
        name="speak"
        active={activeItem === 'speak'}
        onClick={onClick}
      >
        {t('AVA.SPEAKING.LB')}
      </BlueTabMenuItem>
      <BlueTabMenuItem
        name="camera"
        active={activeItem === 'camera'}
        onClick={onClick}
      >
        {t('AVA.SHOOTING')}
      </BlueTabMenuItem>
    </BlueTabMenu>
  );
};

export default TablMenu;
