import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { useMount, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import PiboSettingList from '../../components/pibo/settings/PiboSettingList';
import Popup from '../../components/Popup';
import {
  fetchPiboData,
  handleInit,
  handleDisConn,
  setVolume,
} from '../../ducks/pibo';
import withLoading from '../../hocs/withLoading';
import { getRobotId } from '../../utils/common';
// import {getRobotId, getUserPId } from '../../utils/common';
import { Page } from '../../components/Components';
// import { setPiboMode, setPiboVolume } from '../../pibo';
import { setPiboMode, setPiboVolume, getAlive } from '../../pibo';
import withAuth from '../../hocs/withAuth';
import { infoLog } from '../../utils/report';

const PiboSettingPage = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname: p } = useLocation();
  const {
    onMount,
    handleClickDisconn,
    handleClickInit,
    history,
    init,
    ...rest
  } = props;
  const [isPopup, setPopupStatus] = useState(false);
  const [popupObj, setPopup] = useState({
    leftLabel: '',
    rightLabel: '',
    mode: 'confirm',
    isPopup: true,
    leftFunc: () => setPopupStatus(false),
    rightFunc: () => setPopupStatus(false),
    contents: <Container text textAlign="center" />,
  });

  useMount(() => {
    onMount(getRobotId());
  });

  useUpdateEffect(() => {
    if (init) {
      setTimeout(() => {
        navigate('/pibo');
      }, 3000);
    }
  }, [init]);

  const handleClose = () => setPopupStatus(false);
  const handleVolumeChange = value => {
    // 파이보 볼륨 조절하는 부분
    setPiboVolume(value);
    dispatch(setVolume(value));
  };
  const handleDisturbChange = value => {
    setPiboMode('disturb', value);
  };

  const handleBackClick = () => {
    navigate(`/${p.split('/')[1]}`);
  };

  // eslint-disable-next-line consistent-return
  const handleItemClick = name => {
    if (name === 'initialize') {
      const obj = { ...popupObj };
      obj.leftLabel = t('pibo:SET.CANCEL');
      obj.rightLabel = t('pibo:SET.INITIALIZE');
      obj.leftFunc = handleClose;
      obj.rightFunc = () => {
        handleClickInit();
        handleClose();
      };
      obj.mode = 'alert';
      obj.header = t('pibo:SET.INIT_PIBO');
      obj.contents = (
        <div>
          {t('pibo:SET.MSG_PIBO_INIT')}
          <p
            style={{
              textAlign: 'left',
              width: '80%',
              fontSize: '0.875rem',
              margin: 'auto 10%',
            }}
          >
            <br />
            {/* `- ${t('pibo:SET.CONF_WIFI')}` */}
            {`- ${t('pibo:SET.CONF_VOLUME')}`}
            <br />
            {`- ${t('pibo:SET.CONF_REACTION')}`}
            <br />
            {`- ${t('pibo:SET.CONF_LOCATION')}`}
            <br />
            {`- ${t('pibo:SET.CONF_TEMPERATURE')}`}
            <br />
          </p>
        </div>
      );
      setPopup(obj);
      return setPopupStatus(true);
    }
    if (name === 'wifi') {
      infoLog(name, getAlive());
      if (name === 'wifi') {
        // 꺼져 있을 때, 파이보가 인터넷 연결에 실패했을 때 보여줄 페이지
        /* if(isOldVersion()) {
          // 이전 로봇은 여기로
          return window.open(connectedMoveURL(), '_blank');
        } */
        // 최신 로봇으로 여기로
        return navigate(`/pibo/${getRobotId()}/manualWifi`);
      }
    }
    return navigate(`/pibo/${getRobotId()}/${name}`);
  };
  return (
    <Page>
      <TopMenu
        title={t('pibo:SET.CONF_PIBO')}
        menuMode="WITH_BACK"
        handleBackClick={handleBackClick}
      />
      {!init && (
        <PiboSettingList
          {...rest}
          history={history}
          onVolumeChange={handleVolumeChange}
          onDisturbChange={handleDisturbChange}
          handleItemClick={handleItemClick}
        />
      )}
      {isPopup && <Popup {...popupObj} isPopup={isPopup} />}
    </Page>
  );
};

export default connect(
  state => ({
    loading: state.pibo.loading,
    pibo: state.pibo.pibo,
    error: state.pibo.error,
    init: state.pibo.init,
  }),
  dispatch => ({
    onMount: () => {
      dispatch(fetchPiboData());
    },
    handleClickInit: () => {
      dispatch(handleInit());
    },
    handleClickDisconn: () => {
      dispatch(handleDisConn());
    },
  }),
)(withAuth(withLoading(PiboSettingPage)));
