/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useToggle, useUpdateEffect } from 'react-use';
import ReactPlayer from 'react-player';
import shortid from 'shortid';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardBody,
  InvalidText,
  BorderedSegment,
  ToggleButtonItem,
  ToggleTextItem,
  PlayerWrapper,
  Player,
} from './Components';
import { onSaveExecution } from '../../../ducks/bots/trainBot/train';
import ExecCard from './ExecCard';
import EyeColorCard from './EyeColorCard';
import { getFile } from '../../../utils/api';
import { errorLog } from '../../../utils/report';

const pad = string => `0${string}`.slice(-2);

const format = seconds => {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${pad(mm)}:${ss}`;
};

const BgExecCard = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    execution: { color, icon, label: title },
    data: { background: backData, colors },
  } = useSelector(state => state.train);
  const [background, setBackground] = useState(backData);
  const [select, setSelect] = useState({ catIndex: -1, itemIndex: -1 });
  const [value, setValue] = useState({ _eye: null, bg: null });
  const [playData, setPlayData] = useState({
    playing: false,
    duration: 0,
    played: 0,
    src: null,
    url: null,
    className: '',
  });
  const [playing, toggle] = useToggle(false);
  const [error, setError] = useState(null);

  const handleCategoryClick = i => {
    const { catIndex } = select;
    if (i === catIndex) {
      setSelect({ catIndex: -1, itemIndex: -1 });
    } else {
      setSelect({ catIndex: i, itemIndex: -1 });
    }
  };

  const handleItemClick = i => {
    const { itemIndex } = select;
    if (i === itemIndex) {
      setSelect({ ...select, itemIndex: -1 });
    } else {
      setSelect({ ...select, itemIndex: i });
    }
  };

  useUpdateEffect(() => {
    const { catIndex, itemIndex } = select;
    toggle(false);
    if (catIndex > -1 && itemIndex > -1) {
      const bg = background[catIndex].list[itemIndex];
      if (!bg.url) {
        const url = getFile({ media: bg.value });
        if (url) {
          const newBackground = background.map(({ genre, list }, idx) => {
            if (idx === catIndex) {
              const newList = list.map((listItem, i) => {
                if (i === itemIndex) {
                  return { ...listItem, url };
                }
                return listItem;
              });
              return { genre, list: newList };
            }
            return { genre, list };
          });
          setBackground(newBackground);
          setValue({ ...value, bg: { ...bg, url } });
          return setPlayData({
            playing: false,
            duration: bg.len,
            played: 0,
            src: bg.value,
            url,
          });
        }
        setValue({ ...value, bg });
        return setPlayData({
          playing: false,
          duration: bg.len,
          played: 0,
          src: bg.value,
          url: null,
        });
      }
      setValue({ ...value, bg });
      return setPlayData({
        playing: false,
        duration: bg.len,
        played: 0,
        src: bg.value,
        url: bg.url,
      });
    }
    setValue({ ...value, bg: null });
    return setPlayData({
      playing: false,
      duration: 0,
      played: 0,
      src: null,
      url: null,
    });
  }, [select]);

  const handleEyeColorChange = name => {
    setValue({ ...value, _eye: name });
  };

  const handleSave = () => {
    if ('bg' in value && value.bg && !error) {
      const { label, key, value: v, len } = value.bg;
      dispatch(
        onSaveExecution({ ...value, bg: { label, key, value: v, len } }),
      );
    }
  };

  useUpdateEffect(() => {
    const { bg } = value;
    if (!bg) {
      setError(t('bot:TRN.MSG.SELECT_BG'));
    } else {
      setError('');
    }
  }, [value]);

  const handleProgress = ({ played, playedSeconds }) => {
    if (played === 1) {
      toggle(false);
      return setPlayData({
        ...playData,
        played: 0,
        url: null,
        playing: false,
      });
    }
    return setPlayData({
      ...playData,
      played: parseInt(Math.round(playedSeconds), 10),
    });
  };

  useUpdateEffect(() => {
    if (playing) {
      const bg = background[select.catIndex].list[select.itemIndex];
      return setPlayData({
        ...playData,
        url: bg.url,
        playing: true,
      });
    }
    return setPlayData({
      ...playData,
      played: 0,
      url: null,
      playing: false,
    });
  }, [playing]);

  return (
    <ExecCard
      {...{
        t,
        color,
        icon,
        title,
        options: {
          onCancel,
          onSave: handleSave,
          saveDisabled: error === null || !!error,
        },
      }}
    >
      <CardBody>
        <p>{t('bot:TRN.TITLE.SELECT_BG')}</p>
        <BorderedSegment radius={10} style={{ width: '100%' }}>
          {background &&
            background.map(({ genre }, i) => (
              <ToggleButtonItem
                color={color}
                key={shortid.generate()}
                onClick={() => handleCategoryClick(i)}
                selected={select.catIndex === i}
              >
                <span>{genre.ko}</span>
              </ToggleButtonItem>
            ))}
        </BorderedSegment>
        {select.catIndex >= 0 && select.itemIndex >= 0 && (
          <BorderedSegment
            radius={10}
            style={{ width: '100%', marginTop: '1rem' }}
          >
            <PlayerWrapper>
              <Player onClick={toggle}>
                <ReactPlayer
                  {...{
                    playing,
                    url: playData.url,
                    style: { display: 'none' },
                    config: { file: { forceAudio: true } },
                    width: 0,
                    height: 0,
                    volume: 1,
                    onProgress: handleProgress,
                    onError: e => errorLog('player error', e),
                  }}
                />
                {!playing && <Icon name="play" />}
                {playing && <Icon name="stop" />}
              </Player>
              <p style={{ fontSize: '0.875em' }}>
                {format(playData.played)}/{format(playData.duration)}
              </p>
              <p className="title">{value.bg ? value.bg.title : ''}</p>
            </PlayerWrapper>
          </BorderedSegment>
        )}
        {select.catIndex > -1 && (
          <BorderedSegment
            radius={10}
            style={{ width: '100%', marginTop: '1rem', paddingBottom: 0 }}
          >
            {background[select.catIndex].list.map(({ label, key }, i) => (
              <ToggleTextItem
                key={key}
                onClick={() => handleItemClick(i)}
                selected={select.itemIndex === i}
              >
                {label}
              </ToggleTextItem>
            ))}
          </BorderedSegment>
        )}
        {error && <InvalidText>{error}</InvalidText>}
      </CardBody>
      {(value.bg || value._eye) && (
        <EyeColorCard colors={colors} onChange={handleEyeColorChange} />
      )}
    </ExecCard>
  );
};

export default BgExecCard;
