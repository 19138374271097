import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as moment from 'moment';
import i18 from '../../../lang/i18n';
import { getDiariesApi, deleteDiaryItemsApi } from './diaryBotApi';

const reducerName = 'diary';
const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);

export const getDiaries = createAsyncThunk(
  `${reducerName}/FETCH`,
  async ({ robotId, init }, { getState, rejectWithValue }) => {
    try {
      const {
        skip: oldSkip,
        limit,
        length: oldLength,
        diaryData: oldDiaryData,
      } = getState().diaryBot;
      let diaryData = oldDiaryData;
      let skip = oldSkip;
      let length = oldLength;
      if (init) {
        skip = 0;
        diaryData = [];
      } else {
        skip += 10;
      }
      const res = await getDiariesApi({ robotId, skip, limit });
      if (res.result) {
        const {
          data: { list, length: l, total },
        } = res;
        if (list.length === 0 || total === 0) {
          return { diaryData };
        }
        length += l;
        const returnData = diaryData.concat(list);
        return { diaryData: returnData, total, length, skip };
      }
      return rejectWithValue(res.error);
    } catch (error) {
      console.log(error);
      return rejectWithValue(error);
    }
  },
);

export const deleteDiaryData = createAsyncThunk(
  `${reducerName}/DELETE`,
  async ({ robotId, selectItems }, { getState, rejectWithValue }) => {
    try {
      if (selectItems.length > 0) {
        const res = await deleteDiaryItemsApi({ robotId, selectItems });
        const { result } = res;
        const { diaryData } = getState().diaryBot;
        let newData = diaryData.map(item => {
          const [[title, data]] = Object.entries(item);
          return {
            [title]: data.filter(({ id }) => !selectItems.includes(id)),
          };
        });
        if (newData.length === 1 && newData[0].data.length === 0) {
          newData = [];
        }
        if (result) {
          return newData;
        }
        const { error } = res;
        return rejectWithValue(error);
      }
    } catch (error) {
      return rejectWithValue(error);
    }
    return rejectWithValue(getErrorMsg('bot:DRY.DELETE.SELECT'));
  },
);

export const initialSelectedDate = moment().unix() * 1000;

const initialState = {
  loading: false,
  error: '',
  diaryData: [],
  total: 0,
  deleting: false,
  deleteError: '',
  deleteState: -1,
  skip: 0,
  limit: 10,
  length: 0,
  selectedDate: initialSelectedDate,
  initDataStatus: false,
  popup: { isPopup: false },
};

const diarySlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    initialize: () => ({ ...initialState }),
    getInitStatus: state => ({ ...state, initDataStatus: true }),
    deleteStateInit: state => ({ ...state, deleteState: -1 }),
    openPopup: state => ({
      ...state,
      popup: {
        contents: i18.t('bot:DRY.DELETE.MSG'),
        leftLabel: i18.t('common:POP.CANCEL'),
        rightLabel: i18.t('common:POP.OK'),
        mode: 'confirm',
        isPopup: true,
      },
    }),
    closePopup: state => ({ ...state, popup: { isPopup: false } }),
  },
  extraReducers: {
    [getDiaries.pending.type]: state => ({ ...state, loading: true }),
    [getDiaries.fulfilled.type]: (state, action) => ({
      ...initialState,
      ...action.payload,
    }),
    [getDiaries.rejected.type]: (state, action) => ({
      ...state,
      error: action.payload,
      loading: false,
    }),
    [deleteDiaryData.pending.type]: state => ({
      ...state,
      deleting: true,
      deleteState: -1,
    }),
    [deleteDiaryData.fulfilled.type]: (state, action) => ({
      ...state,
      deleteState: 1,
      diaryData: action.payload,
      deleting: false,
    }),
    [deleteDiaryData.rejected.type]: (state, action) => ({
      ...state,
      deleteError: action.error,
      deleteState: 0,
      deleting: false,
    }),
  },
});

const { reducer: diaryReducer, actions } = diarySlice;
export const {
  initialize,
  listNoMore,
  getInitStatus,
  deleteStateInit,
  openPopup,
  closePopup,
} = actions;

export default diaryReducer;
