import React from 'react';
import { Label } from 'semantic-ui-react';
import { getFile } from '../../../utils/api';

const CalendarItemLabel = props => {
  const { title, type, time, bot } = props;

  return (
    <>
      <div style={{ overflow: 'hidden' }}>
        <Label
          color="teal"
          image
          style={{ fontSize: '0.7em', height: 'inherit' }}
        >
          {type}
          <Label.Detail>{time}</Label.Detail>
        </Label>
      </div>
      {bot && (
        <img
          src={getFile({ icon: bot })}
          alt="bot Icon"
          style={{
            height: '1.5rem',
            borderRadius: '0 0.25rem 0.25rem 0',
            border: '1px solid #03bfd7',
            marginLeft: '-0.175rem',
            zIndex: '899',
            backgroundColor: '#fff',
          }}
        />
      )}
      <span
        style={{
          textAlign: 'left',
          // height: '1.125rem',
          width: '50%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          paddingLeft: '0.5rem',
          margin: '0.25em 0',
        }}
      >
        {title}
      </span>
    </>
  );
};

export default CalendarItemLabel;
