import React from 'react';
import { Header } from 'semantic-ui-react';
// import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { HeaderWrap } from './pibo/PiboComponents';

const PiboComment = ({ comment }) => (
  // const nodeRef = useRef();
  // <SwitchTransition mode="out-in">
  //   <CSSTransition
  //     key={show}
  //     nodeRef={nodeRef}
  //     addEndListener={(node, done) => {
  //       node.addEventListener('transitionend', done, false);
  //     }}
  //     classNames="comment"
  //   >
  <HeaderWrap>
    <Header as="h5">{comment}</Header>
  </HeaderWrap>
);
//   </CSSTransition>
// </SwitchTransition>
export default PiboComment;
