import React, { useState } from 'react';
import { Segment } from 'semantic-ui-react';
import { useLifecycles } from 'react-use';
import { useLastLocation } from 'react-router-dom-last-location';
import { setPiboMode } from '../../../pibo';

import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import withScrollManual from '../../../hocs/withScrollManual';
import { Page } from '../../../components/Components';
import TabMenu from '../../../components/bots/AvatarBot/TabMenu';
import MotionContainer from './MotionContainer';
import SpeakContainer from './SpeakContainer';
import CameraContainer from './CameraContainer';

const Index = props => {
  const { title } = props;
  const { lastLocation } = useLastLocation();
  const [backLocation, setBackLocation] = useState('');
  const [activeItem, setActiveItem] = useState('motion');

  useLifecycles(
    () => {
      if (lastLocation && 'pathname' in lastLocation) {
        setBackLocation(lastLocation.pathname);
      }
      setPiboMode('avatar', true);
      // receivePiboEvent('finish', onActionFinishReceive);
    },
    () => {
      setPiboMode('avatar', false);
      // receivePiboEvent('finish', null);
    },
  );

  const handleMenuClick = (e, { name }) => {
    setActiveItem(name);
  };

  const onClose = close => {
    close();
  };

  return (
    <Page style={{ paddingBottom: '4rem' }}>
      <BotTopMenu
        title={title}
        menuMode="WITH_CLOSE"
        backLocation={backLocation}
        onComplete={onClose}
      />
      <Segment basic style={{ margin: 0 }}>
        <TabMenu onClick={handleMenuClick} activeItem={activeItem} />
        {activeItem === 'motion' && <MotionContainer />}
        {activeItem === 'speak' && <SpeakContainer />}
        {activeItem === 'camera' && <CameraContainer />}
      </Segment>
    </Page>
  );
};
export default withScrollManual(Index);
