/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMount, useUnmount, useUpdateEffect } from 'react-use';
import { animateScroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { Steps } from '../../components/account/AccountComponents';
import { init, onChange, onNext, onPrev } from '../../ducks/link';
import { Alert, getRobotId, setLocalStorage } from '../../utils/common';

import LinkContainer from './LinkContainer';
import { Page } from '../../components/Components';
import withAuth from '../../hocs/withAuth';

let isMounted = false;
const LinkPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const link = useSelector(state => state.link);
  let connectBtnRef = useRef(null);
  const { activeIndex, steps, data, btn, loading, error, complete } = link;

  useMount(() => {
    if (!isMounted) {
      isMounted = true;
      const aIdx = parseInt(localStorage.getItem('activeIndex'), 10);
      if (
        getRobotId() &&
        getRobotId() !== localStorage.getItem('link') &&
        aIdx === 0
      ) {
        // 기존 로봇과 다른 새로운 로봇을 연결하려고 할 때
        Alert(t('conn:MSG.REDIRECT_ALERT'));
        return navigate('/pibo');
      }
      if (!getRobotId() && !localStorage.getItem('link')) {
        // 첫 연결
        return dispatch(init());
      }
    }
    return isMounted;
  });

  const handlePrev = () => {
    dispatch(onPrev());
  };
  const handleNext = () => dispatch(onNext());

  const handleChange = value => {
    dispatch(onChange(value));
  };

  const focusNext = () => {
    if (connectBtnRef) {
      connectBtnRef.focus();
    }
  };

  const setButtonRef = i => c => {
    if (i === btn.length - 1) {
      connectBtnRef = c;
    }
    return c;
  };

  useUpdateEffect(() => {
    if (complete.to) {
      return navigate(complete.to);
    }
    return null;
  }, [complete]);

  useUpdateEffect(() => {
    setLocalStorage('activeIndex', activeIndex);
    animateScroll.scrollToTop({ duration: 0, delay: 0, smooth: true });
  }, [activeIndex]);

  useUnmount(() => {
    const aIdx = parseInt(localStorage.getItem('activeIndex'), 10);
    if (aIdx === 4) {
      localStorage.removeItem('activeIndex');
    }
  });

  return (
    <Page
      style={{ top: 0, display: 'flex', flexFlow: 'column', height: '100%' }}
    >
      {activeIndex < steps.length && (
        <div
          style={{
            margin: '1rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '0.875rem',
          }}
        >
          {t('conn:MSG.GUIDE_COMPLETE')}
        </div>
      )}
      <Steps steps={steps} activeIndex={activeIndex} color="teal" />
      <LinkContainer
        {...{
          t,
          btn,
          data,
          error,
          steps,
          loading,
          focusNext,
          activeIndex,
          handleNext,
          handlePrev,
          handleChange,
          setButtonRef,
        }}
      />
    </Page>
  );
};

export default withAuth(LinkPage);
