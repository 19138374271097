import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { Sidebar, Menu, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { getRobotId } from '../../utils/common';
import UserPhoto from '../user/UserPhoto';

const StyledA = styled.a`
  & div {
    color: #fff;
  }
  & strong {
    color: #d1d3d4;
    text-decoration: underline;
  }
`;

const StoreMenu = props => {
  const { t } = useTranslation('store');
  const { visible, handleSidebarHide, handleMyBotClick, user } = props;
  const [userImg, setUserImage] = useState(null);

  useUpdateEffect(() => {
    if (user) {
      setUserImage(user.data);
    }
  }, [user]);

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      onHide={handleSidebarHide}
      vertical
      visible={visible}
      direction="right"
      style={{ border: 0, transition: 'transform .5s ease.1s' }}
    >
      <div
        style={{
          backgroundColor: '#0894D7',
          width: '100%',
        }}
      >
        {user && 'email' in user && (
          <Menu.Item name="user">
            <Grid columns={16} style={{ margin: 0 }}>
              <Grid.Row style={{ padding: 0 }}>
                <Grid.Column
                  width={3}
                  style={{ padding: 0 }}
                  textAlign="center"
                  verticalAlign="middle"
                >
                  <UserPhoto data={userImg} avatar />
                </Grid.Column>
                <Grid.Column
                  width={12}
                  verticalAlign="middle"
                  style={{ padding: 0, marginLeft: '0.8rem' }}
                >
                  <div
                    style={{
                      color: '#fff',
                      fontWeight: '600',
                      lineHeight: '1.5em',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user.nickName || ''}
                  </div>
                  <div
                    style={{
                      color: '#fff',
                      fontSize: '0.875em',
                      lineHeight: '1.5em',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {user.email}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        )}
        {(!user || !('email' in user)) && (
          <Menu.Item name="user">
            <Grid columns={16} style={{ margin: 0 }}>
              <Grid.Row style={{ padding: 0 }}>
                <Grid.Column
                  width={16}
                  style={{
                    padding: 0,
                    color: '#fff',
                    lineHeight: 'initial',
                  }}
                >
                  <StyledA href="/login">{t('MY_BOT.MSG')}</StyledA>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Menu.Item>
        )}
      </div>
      {user && 'email' in user && getRobotId() && (
        <Menu.Item as="a" name="mybot" onClick={handleMyBotClick}>
          {t('MY_BOT.LB')}
        </Menu.Item>
      )}
    </Sidebar>
  );
};

export default StoreMenu;
