/* eslint-disable consistent-return */
import React, { useState } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLifecycles, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import useWindowFocus from 'use-window-focus';
import { Alert, removeLocalStorage, trimAll } from '../../utils/common';
import {
  checkValidForReplaceEmail,
  signInForReplaceEmail,
  sendMailForReplaceEmail,
  verifyCertification,
} from '../../utils/api';
import { FilledBg } from '../../components/Components';
import {
  LoginButton,
  LoginForm,
  LoginHeader,
  LoginInput,
  LoginMessage,
  LoginWrap,
} from '../../components/account/Components';
import { MAIL_REGEXP } from '../../utils/validate';

const EmailUpdatePage = () => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const { search } = useLocation();
  const windowFocused = useWindowFocus();
  const [id, setId] = useState('');
  const [pw, setPw] = useState('');
  const [newEmail, setEmail] = useState('');
  const [errorMsg, setError] = useState('');
  const [hadSent, setHadSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [content, setContent] = useState({
    title: '',
    message: '',
    button: { label: '', onClick: () => {} },
  });

  const onClose = () => {
    const { hash, email } = JSON.parse(localStorage.getItem('checkValid'));
    removeLocalStorage('checkValid', 'user');
    navigate(`/updateEmail?hash=${hash}&email=${email}`);
  };

  useUpdateEffect(() => {
    if (windowFocused && step > 1 && hadSent) {
      verifyCertification({ userId: id, email: newEmail })
        .then(result => {
          if (result.result) {
            setContent({
              title: t('MAIL.MSG.UPDATE_COMPLETE'),
              message: '',
              button: {
                label: t('TO_M'),
                onClick: () => navigate('/'),
              },
            });
            setStep(3);
          }
        })
        .catch(() => {
          setContent({
            title: t('MAIL.ERR_MSG.VERIFY'),
            message: t('MAIL.MSG.RETRY_BY_BTN'),
            button: { label: t('MAIL.LB_RETRY'), onClick: onClose },
          });
          setStep(3);
        });
    }
  }, [windowFocused]);

  useLifecycles(
    () => {
      if (search.length > 1) {
        const { hash, email } = qs.parse(search, { ignoreQueryPrefix: true });
        if (!(hash && email)) {
          navigate('/');
          return Alert(t('MAIL.ERR_MSG.UNVALID'));
        }
        checkValidForReplaceEmail({ hash, email })
          .then(result => {
            if (result.result) {
              removeLocalStorage('currentUser', 'userToken', 'loginId', 'user');
              localStorage.setItem(
                'checkValid',
                JSON.stringify({ email, hash }),
              );
              return navigate('/updateEmail');
            }
            navigate('/');
            return Alert(t('MAIL.ERR_MSG.UNVALID'));
          })
          .catch(() => Alert(t('MAIL.ERR_MSG.NOT_FOUND')));
      } else {
        const checkValid = JSON.parse(localStorage.getItem('checkValid'));
        if (!checkValid) {
          removeLocalStorage('user');
          Alert(t('MAIL.ERR_MSG.UNVALID'));
          return navigate('/');
        }
      }
    },
    () => {
      removeLocalStorage('checkValid', 'user');
    },
  );

  const onLogin = () => {
    if (id && pw) {
      signInForReplaceEmail({ id, pw })
        .then(({ result, email, userId }) => {
          if (result) {
            const { email: e } = JSON.parse(localStorage.getItem('checkValid'));
            if (
              email.indexOf('@daum.net') < 0 &&
              email.indexOf('@hanmail.net') < 0
            ) {
              navigate('/');
              return Alert(t('MAIL.ERR_MSG.NOT_SUBJECT'));
            }
            if (email !== e) {
              navigate('/');
              return Alert(t('MAIL.ERR_MSG.UNMATCH'));
            }
            removeLocalStorage('checkValid');
            localStorage.setItem('user', JSON.stringify({ email, userId }));
            return setStep(2);
          }
          setError(t('SIGN_IN.ERR_MSG.UNMATCH'));
        })
        .catch(error => setError(error));
    } else {
      setError(t('SIGN_IN.MSG.INPUT_ALL'));
    }
  };

  const onKeyDown = event => {
    if (event.keyCode === 13) {
      onLogin();
    }
  };

  const onChange = e => {
    const {
      target: { value, name },
    } = e;
    if (name === 'userid') {
      setId(trimAll(value.toLowerCase()));
    } else if (name === 'password') {
      setPw(trimAll(value));
    } else if (name === 'email') {
      setEmail(trimAll(value));
      const validPattern = new RegExp(MAIL_REGEXP).test(value);
      if (value.indexOf('@daum') > -1 || value.indexOf('@hanmail') > -1) {
        setError(t('EMAIL.DAUM.UNVALID'));
      } else if (!validPattern) {
        setError(t('MAIL.UNVALID'));
      }
    } else {
      setId(trimAll(id.toLowerCase()));
      setPw(trimAll(pw));
    }
    setError('');
  };

  const onSend = () => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    // 메일 중복되는지 확인하고 인증메일 발송
    sendMailForReplaceEmail({ ...user, email: newEmail })
      .then(sendResult => {
        const { result, existEmail } = sendResult;
        if (result && existEmail) {
          setError(t('MAIL.ERR_MSG.USED'));
          setHadSent(false);
        } else {
          setHadSent(result);
        }
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  return (
    <FilledBg>
      <LoginForm>
        {step === 1 && (
          <LoginWrap>
            <LoginHeader as="h4" textAlign="center">
              {t('SIGN_IN.MSG.BY_ACCOUNT')}
            </LoginHeader>
            <LoginInput
              placeholder={t('ID.LB')}
              name="userid"
              value={id}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
            <LoginInput
              placeholder={t('PW.LB')}
              type="password"
              name="password"
              value={pw}
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
            <LoginMessage>{errorMsg}</LoginMessage>
            <LoginButton fluid size="large" onClick={onLogin}>
              {t('SIGN_IN.LB')}
            </LoginButton>
          </LoginWrap>
        )}
        {step === 2 && (
          <LoginWrap>
            <LoginHeader as="h4" textAlign="center">
              {!hadSent && t('MAIL.MSG.INPUT_NEW')}
              {hadSent && t('MAIL.MSG.LET_COMPLETE')}
              <br />
            </LoginHeader>
            <LoginInput
              placeholder={t('MAIL.LB')}
              pattern="^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$"
              name="email"
              type="email"
              disabled={hadSent}
              value={newEmail}
              onChange={onChange}
            />
            {!hadSent && errorMsg && <LoginMessage>{errorMsg}</LoginMessage>}
            <LoginButton fluid size="large" onClick={onSend} disabled={hadSent}>
              {loading && <Icon name="spinner" loading />}
              {!loading && t('MAIL.GETTING_SENTED')}
            </LoginButton>
            {hadSent && errorMsg && <LoginMessage>{errorMsg}</LoginMessage>}
            {hadSent && !errorMsg && (
              <div style={{ marginTop: '1.5rem' }}>
                <LoginMessage>{t('MAIL.MSG.SENT_CERT')}</LoginMessage>
                <LoginMessage>{t('MAIL.MSG.CHECK_SPAM')}</LoginMessage>
              </div>
            )}
          </LoginWrap>
        )}
        {step === 3 && (
          <LoginWrap>
            <LoginHeader as="h4" textAlign="center">
              {content.title}
              <br />
            </LoginHeader>
            <LoginMessage>{content.message}</LoginMessage>
            {content.button.label && (
              <LoginButton
                fluid
                size="large"
                onClick={content.button.onClick}
                style={{ marginTop: '10vh' }}
              >
                {content.button.label}
              </LoginButton>
            )}
          </LoginWrap>
        )}
      </LoginForm>
    </FilledBg>
  );
};

export default EmailUpdatePage;
