/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Button, Dimmer, Icon, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { getQRCvs } from '../../utils/common';
import { StyledMessage } from '../Message';
import { StyledButtonGroup, StyledButton } from '../pibo/PiboComponents';
import QRCodeInputForm from '../pibo/settings/QRCodeInputForm';

const StyledPopUp = styled(Segment)`
  &&& {
    border-radius: 0 !important;
    position: relative !important;
    padding: 1rem 0 4rem !important;
    @media (max-width: 500px) {
      width: 85vw;
    }
    @media (min-width: 501px) and (max-width: 1200px) {
      width: 70vw;
    }
    @media (min-width: 1201px) and (max-width: 2000px) {
      width: 40vw;
    }
    @media (min-width: 2001px) {
      width: 20vw;
    }
  }
`;

const StyledContent = styled.div`
  text-align: left;
  font-size: 1rem;
  padding: 1rem 1rem;
  line-height: 1.2rem;
  & button.ui.basic.button {
    font-size: 0.875rem;
    border-radius: 1.5rem;
    margin: 0.25em 0.5em;
    &.blue {
      font-weight: bold;
    }
  }
  &&& > canvas {
    width: 100% !important;
    height: 100% !important;
  }
  & > .accordion.ui {
    font-size: 0.875em;
    & > .title {
      padding: 0;
      font-weight: bold;
    }
    & > .content {
      padding: 0.5em 0.5em 0.5em 1.5em !important;
      & > ul,
      ol {
        margin: 0;
        padding-inline-start: 1em;
        & > li {
          margin-bottom: 0.25em;
        }
      }
    }
  }
`;

const LinkPopup = ({ t, rightLabel, rightFunc, isPopup }) => {
  const [step, setStep] = useState(null);
  const [{ essid, password }, setAP] = useState({
    essid: null,
    password: null,
  });
  const [qrcode, setQR] = useState(null);

  const handleInputChange = (e, { name, value }) => {
    setAP({ essid, password, [name]: value });
  };
  const handleStep = v => () => setStep(v);

  const getQR = () => {
    const v = getQRCvs({
      value: JSON.stringify({
        essid,
        password,
        wpa: 'wpa',
        encrypted: !!password,
      }),
      size: window.innerWidth / 2,
      level: 'L',
    });
    setQR(v);
    setStep('qr');
  };

  return (
    <Dimmer active={isPopup} page>
      <StyledPopUp>
        {!step && (
          <StyledContent>
            <StyledMessage
              textalign="left"
              style={{ margin: '0 1rem 1rem', fontWeight: 'bold' }}
            >
              {t('conn:POPUP.STEP_0.TITLE')}
            </StyledMessage>
            <Button color="blue" basic onClick={handleStep('check-connect')}>
              {t('conn:POPUP.STEP_0.POS')}
              <Icon name="chevron right" />
            </Button>
            <Button color="red" basic onClick={handleStep('input-qr')}>
              {t('conn:POPUP.STEP_0.NEG')}
              <Icon name="chevron right" />
            </Button>
          </StyledContent>
        )}
        {step === 'check-connect' && (
          <StyledContent>
            <StyledMessage
              textalign="left"
              style={{ margin: '0 1rem 1rem', fontWeight: 'bold' }}
            >
              {t('conn:POPUP.STEP_CHECK_CONN.TITLE')}
            </StyledMessage>
            <Button color="blue" basic onClick={handleStep('check-code')}>
              {t('conn:POPUP.STEP_CHECK_CONN.POS')}
              <Icon name="chevron right" />
            </Button>
            <Button color="red" basic onClick={handleStep('input-qr')}>
              {t('conn:POPUP.STEP_CHECK_CONN.NEG')}
              <Icon name="chevron right" />
            </Button>
          </StyledContent>
        )}
        {(step === 'check-code' || step === 'check-after') && (
          <StyledContent>
            <StyledMessage
              textalign="left"
              style={{ margin: '0 1rem 1rem', fontWeight: 'bold' }}
            >
              {step === 'check-code'
                ? t('conn:POPUP.STEP_CHECK_CODE.TITLE')
                : t('conn:POPUP.STEP_CHECK_AFTER.TITLE')}
            </StyledMessage>
            <Button color="blue" basic onClick={handleStep('confirm-code')}>
              {t('conn:POPUP.STEP_CHECK_CODE.POS')}{' '}
              <Icon name="chevron right" />
            </Button>
            <Button color="red" basic onClick={handleStep('input-qr')}>
              {t('conn:POPUP.STEP_CHECK_CODE.NEG')}{' '}
              <Icon name="chevron right" />
            </Button>
          </StyledContent>
        )}
        {step === 'confirm-code' && (
          <StyledContent>
            <StyledMessage
              textalign="left"
              style={{ margin: '0 1rem 1rem', fontWeight: 'bold' }}
            >
              {t('conn:POPUP.STEP_CONFIRM_CODE.TITLE')}
            </StyledMessage>
          </StyledContent>
        )}
        {step === 'input-qr' && (
          <StyledContent>
            <StyledMessage
              textalign="left"
              style={{ margin: '0 1rem 1rem', fontWeight: 'bold' }}
            >
              {t('conn:POPUP.STEP_INPUT_QR.TITLE')}
            </StyledMessage>
            <QRCodeInputForm
              {...{
                t,
                onChange: handleInputChange,
                disalbed: !essid,
                onClick: getQR,
              }}
            />
          </StyledContent>
        )}
        {step === 'qr' && (
          <StyledContent>
            <StyledMessage>{t('conn:MSG.SHOW_QR')}</StyledMessage>
            <StyledContent>{qrcode}</StyledContent>
          </StyledContent>
        )}
        {step === 'qr' && (
          <StyledButtonGroup>
            <StyledButton color="gray" onClick={handleStep('input-qr')}>
              {t('conn:BTN.PREV')}
            </StyledButton>
            <StyledButton color="blue" onClick={handleStep('check-after')}>
              {t('conn:BTN.NEXT')}
            </StyledButton>
          </StyledButtonGroup>
        )}
        {step !== 'qr' && (
          <StyledButtonGroup>
            <StyledButton color="blue" onClick={rightFunc}>
              {rightLabel}
            </StyledButton>
          </StyledButtonGroup>
        )}
      </StyledPopUp>
    </Dimmer>
  );
};

export default LinkPopup;
