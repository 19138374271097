/* eslint-disable react/state-in-constructor */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Dimmer, Segment } from 'semantic-ui-react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledPopUp = styled(Segment)`
  &&& {
    border-radius: 0 !important;
    position: relative !important;
    padding: 1rem 0 4rem !important;
    @media (max-width: 500px) {
      min-width: 250px;
      max-width: 85vw;
    }
    @media (min-width: 501px) and (max-width: 1200px) {
      min-width: 60vw;
      max-width: 70vw;
    }
    @media (min-width: 1201px) {
      min-width: 30vw;
      max-width: 600px;
    }
  }
`;

const StyledHeader = styled.div`
  text-align: 'center';
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
`;

const StyledContent = styled.div`
  text-align: ${({ align }) => align || 'center'};
  font-size: 1rem;
  padding: 0.5rem 1rem;
  line-height: 1.2rem;
`;

const StyledButtonGroup = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 3rem;
  display: flex;
`;

const StyledButton = styled.div`
  width: inherit;
  border: 0;
  border-radius: 0;
  background: ${({ color }) =>
    color === 'gray'
      ? '#D1D3D4'
      : color === 'red'
      ? '#EF3340'
      : color === 'blue'
      ? '#0894D7'
      : '#FFF'};
  color: ${({ color }) => (color === 'gray' ? '#565B5B' : '#FFF')};
  display: inline-block;
  font-size: 1rem;
  padding: 1rem;
  height: 100%;
`;

const Popup = ({
  isPopup: bPop,
  leftFunc,
  rightFunc,
  leftLabel,
  rightLabel,
  contents,
  header,
  mode,
  align,
}) => {
  const [isPopup, setPopup] = useState(bPop || false);

  useEffect(() => {
    setPopup(bPop);
  }, [bPop]);

  const handleClickLeft = () => {
    if (leftFunc) {
      return leftFunc();
    }
    return setPopup(false);
  };

  const handleClickRight = () => rightFunc && rightFunc();

  const btnColor = value => {
    switch (value) {
      case 'alert':
        return 'red';
      case 'confirm':
        return 'blue';
      default:
        return 'gray';
    }
  };
  return (
    <Dimmer active={isPopup} onClickOutside={handleClickLeft} page>
      <StyledPopUp>
        {header && header.length > 0 && <StyledHeader>{header}</StyledHeader>}
        <StyledContent {...{ align }}>
          {typeof contents === 'string' &&
            contents.split('\n').map(line => (
              <span key={shortid.generate()}>
                {line}
                <br />
              </span>
            ))}
          {typeof contents === 'object' && <span>{contents}</span>}
        </StyledContent>
        <StyledButtonGroup>
          {leftLabel && (
            <StyledButton color="gray" onClick={handleClickLeft}>
              {leftLabel}
            </StyledButton>
          )}
          <StyledButton color={btnColor(mode)} onClick={handleClickRight}>
            {rightLabel}
          </StyledButton>
        </StyledButtonGroup>
      </StyledPopUp>
    </Dimmer>
  );
};

Popup.propTypes = {
  isPopup: PropTypes.bool.isRequired,
  leftLabel: PropTypes.string,
  leftFunc: PropTypes.func,
  rightLabel: PropTypes.string.isRequired,
  rightFunc: PropTypes.func,
  contents: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  header: PropTypes.string,
  mode: PropTypes.oneOf(['alert', 'confirm', 'default']).isRequired,
};

export default Popup;
