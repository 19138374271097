import React from 'react';
import { useLifecycles } from 'react-use/lib';

const withScrollManual = Component => props => {
  useLifecycles(
    () => {
      window.history.scrollRestoration = 'manual';
    },
    () => {
      window.history.scrollRestoration = 'auto';
    },
  );
  return <Component {...props} />;
};

export default withScrollManual;
