/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles, useUpdateEffect } from 'react-use';
import BgExecCard from './BgExecCard';
import BotExecCard from './BotExecCard';
import { BottomUpMenu, IconMenu } from './Components';
import MotionExecCard from './MotionExecCard';
import SpeakExecCard from './SpeakExecCard';
import { setMenu } from '../../../ducks/bots/trainBot/train';
import { EXECUTES_MAX_LENGTH } from '../../../ducks/bots/trainBot/consts';
// import { Alert } from '../../../utils/common';

const ExecutionMenu = ({ onOpen, onClose, getSoundItem }) => {
  const dispatch = useDispatch();
  const { menus, execution, executes } = useSelector(state => state.train);
  const { t } = useTranslation();
  const [menu, setMenuName] = useState(null);
  const [open, setOpen] = useState(false);
  const [innerH, setHeight] = useState(window.innerHeight);

  const handleToggleClick = () => {
    if (executes.length <= EXECUTES_MAX_LENGTH) {
      if (open) {
        onOpen && onOpen();
      } else {
        setMenuName(null);
        onClose && onClose();
      }
      setOpen(!open);
    }
  };

  const hanleMenuChange = (e, index) => {
    setMenuName(menus[index].name);
    dispatch(setMenu({ ...menus[index] }));
  };

  useUpdateEffect(() => {
    const { label } = execution;
    if (!label) {
      setOpen(false);
      setMenuName(null);
    }
  }, [execution]);

  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  useLifecycles(
    () => {
      window.addEventListener('resize', handleResize);
    },
    () => {
      window.removeEventListener('resize', handleResize);
    },
  );

  return (
    <BottomUpMenu
      height={innerH}
      label={t('bot:TRN.BTN.ADD_EXECUTE')}
      open={open}
      disabled={executes.length > EXECUTES_MAX_LENGTH}
      onClick={handleToggleClick}
    >
      <IconMenu menus={menus} onChange={hanleMenuChange} />
      {menu === 'motion' && <MotionExecCard />}
      {menu === 'speak' && <SpeakExecCard />}
      {menu === 'bot' && <BotExecCard />}
      {menu === 'bg' && <BgExecCard getSoundItem={getSoundItem} />}
    </BottomUpMenu>
  );
};

export default ExecutionMenu;
