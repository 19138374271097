import React, { Fragment } from 'react';
import { Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  StoreRow,
  StoreColumn,
  StoreLinePanel,
  StoreWrap,
  StoreReviewCard,
} from './StoreComponents';
import { REVIEW_LIMIT } from '../../circulusConfig';
import StoreMyReview from './StoreMyReview';

const StoreReviewList = props => {
  const { t } = useTranslation('store');
  const { handleMoreClick, isInstalled } = props;
  const selector = useSelector(state => state.review);
  const { loading, reviews, review, fetchError, size, saving } = selector;
  return (
    <>
      <StoreRow>
        <StoreColumn>
          <Segment id="review-content">
            <div style={{ fontWeight: '600' }}>
              {t('REVIEW.TITLE.LB', { size })}
            </div>
            {(isInstalled || ('rId' in review && review.rId)) && (
              <StoreMyReview
                {...{
                  ...review,
                  ...props,
                  saving,
                }}
              />
            )}
            <StoreWrap>
              {size === 0 && (
                <StoreRow style={{ margin: '0.5rem 0' }}>
                  <StoreColumn fluid>{t('REVIEW.NO_RESULT')}</StoreColumn>
                </StoreRow>
              )}
              {reviews &&
                reviews.length > 0 &&
                reviews.map(({ _id: id, ...item }) => (
                  <StoreRow key={id}>
                    <StoreColumn fluid>
                      <StoreReviewCard {...item} />
                    </StoreColumn>
                    <div
                      style={{
                        width: '100%',
                        height: '1px',
                        background: 'rgba(209,212,211, 0.3)',
                      }}
                    />
                  </StoreRow>
                ))}
              {loading && (
                <StoreRow style={{ marginBottom: '0.5rem' }}>
                  <StoreColumn style={{ textAlign: 'center' }} fluid>
                    <Icon name="spinner" loading={loading} color="grey" />
                  </StoreColumn>
                </StoreRow>
              )}
              {size > REVIEW_LIMIT && (
                <StoreRow>
                  <StoreColumn fluid>
                    <StoreLinePanel onClick={handleMoreClick}>
                      {!fetchError && (
                        <div style={{ fontWeight: '600', marginLeft: '1rem' }}>
                          {t('REVIEW.MORE')}
                          <Icon
                            name="plus"
                            size="tiny"
                            style={{ marginLeft: '1rem' }}
                          />
                        </div>
                      )}
                      {fetchError && (
                        <div style={{ fontWeight: '600', marginLeft: '1rem' }}>
                          <Icon
                            name="warning circle"
                            size="mini"
                            style={{ marginLeft: '1rem' }}
                          />
                          {fetchError}
                        </div>
                      )}
                    </StoreLinePanel>
                  </StoreColumn>
                </StoreRow>
              )}
            </StoreWrap>
          </Segment>
        </StoreColumn>
      </StoreRow>
    </>
  );
};

export default StoreReviewList;
