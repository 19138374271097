import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18 from '../lang/i18n';
import {
  deleteReviewData,
  getReviewData,
  getUserReviewData,
  saveReviewData,
  updateReviewData,
} from '../utils/api';

const name = 'review';
const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

export const deleteUserReview = createAsyncThunk(
  `${name}/DELETE`,
  async ({ rId, userId }, { getState, rejectWithValue }) => {
    try {
      const {
        size,
        avg,
        review: { score },
      } = getState().review;
      const res = await deleteReviewData({ rId, userId });
      const newSize = size - 1;
      const newAvg = (avg * size - score) / newSize;
      if (!res.result) {
        return rejectWithValue(
          getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC'),
        );
      }
      return { reviewSize: newSize, reviewAvg: newAvg };
    } catch (error) {
      console.log(error);
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);

export const saveUserReview = createAsyncThunk(
  `${name}/SAVE`,
  async (review, { rejectWithValue }) => {
    try {
      const { update, ...rest } = review;
      let res;
      if (update) {
        res = await updateReviewData({ ...rest });
      } else {
        res = await saveReviewData({ ...rest });
      }

      if (!res.result) {
        return rejectWithValue(
          getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC'),
        );
      }

      return true;
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);

export const getReviewMore = createAsyncThunk(
  `${name}/FETCH_MORE`,
  async ({ userId, botId }, { getState, rejectWithValue }) => {
    try {
      const { reviews, review } = getState().review;
      const res = await getReviewData({ botId, userId, skip: reviews.length });
      if (res.result) {
        const newReviews = reviews.concat(res.reviews);
        if (res.reviews.length < 1) {
          return rejectWithValue(i18.t('store:REVIEW.NO_MORE'));
        }
        return { reviews: newReviews, review };
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);

export const getReviews = createAsyncThunk(
  `${name}/FETCH`,
  async ({ userId, botId }, { rejectWithValue }) => {
    try {
      const resList = await getReviewData({ botId, userId });
      let resUser;
      if (userId) {
        resUser = await getUserReviewData({ userId, botId });
      }
      if (resList.result) {
        return {
          reviews: resList.reviews,
          review: resUser && resUser.result ? resUser.review : {},
          size: resList.size,
          avg: resList.avg,
        };
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(resList.error), 'common:ERR.UNSPECIFIC'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);

const initialState = {
  loading: false,
  reviews: [],
  fetchSuccess: false,
  fetchError: '',
  saving: false,
  saveSuccess: false,
  saveError: '',
  review: {},
  size: 0,
  avg: 0,
  deleting: true,
  deleteSuccess: false,
  deleteError: '',
};

const ReviewSlice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
  },
  extraReducers: {
    [saveUserReview.pending.type]: state => ({
      ...state,
      saving: true,
      saveSuccess: false,
      saveError: '',
    }),
    [saveUserReview.fulfilled.type]: (state, action) => ({
      ...state,
      saving: false,
      saveSuccess: action.payload,
    }),
    [saveUserReview.rejected.type]: (state, action) => ({
      ...state,
      saving: false,
      saveError: action.payload,
    }),
    [deleteUserReview.pending.type]: state => ({
      ...state,
      deleting: true,
      deleteSuccess: false,
      deleteError: '',
    }),
    [deleteUserReview.fulfilled.type]: (state, action) => ({
      ...state,
      deleting: false,
      deleteSuccess: true,
      review: {},
      reviewSize: action.payload.reviewSize,
      reviewAvg: action.payload.reviewAvg,
    }),
    [deleteUserReview.rejected.type]: (state, action) => ({
      ...state,
      deleting: false,
      deleteError: action.payload,
    }),
    [getReviews.pending.type]: state => ({
      ...state,
      loading: true,
      fetchSuccess: false,
      fetchError: '',
    }),
    [getReviews.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      fetchSuccess: true,
      ...action.payload,
    }),
    [getReviews.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      fetchError: action.payload,
    }),
    [getReviewMore.pending.type]: state => ({
      ...state,
      loading: true,
      fetchSuccess: false,
      fetchError: '',
    }),
    [getReviewMore.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      fetchSuccess: true,
      ...action.payload,
    }),
    [getReviewMore.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      fetchError: action.payload,
    }),
  },
});

const { reducer: ReviewReducer, actions } = ReviewSlice;
export const { init } = actions;

export default ReviewReducer;
