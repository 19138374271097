import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import en from './lang_en.json';
import ko from './lang_ko.json';
import { errorLog } from '../utils/report';
// import { DEV_MODE } from '../circulusConfig';

i18n
  // react i18next 모듈 사용
  .use(initReactI18next)
  // .use(LanguageDetector)
  // 언어 리소스
  .init(
    {
      lng: 'ko',
      initImmediate: false,
      preload: ['en', 'ko'],
      fallbackLng: 'en',
      resources: {
        en,
        ko,
      },
      languages: ['en', 'ko'],
      // 선택 언어에서 해당 key가 존재하지 않을 때, 해당 key 값을 기본으로 가져오는 언어 셋
      // 현재는 한글 텍스트만 존재하기 때문에 한글 추후에는 en
      debug: true,
      ns: ['common', 'pibo', 'conn', 'app', 'user', 'store'],
      defaultNS: 'common',
      keySeparator: '.', // 특정 문자 설정 시, 해당 문자를 key separator로 사용
      interpolation: {
        escapeValue: false,
      },
      react: {
        bindI18n: 'languageChanged',
        bindI18nStore: '',
        defaultTransParent: 'div',
        transEmptyNodeValue: '',
        transSupportBasicHtmlNodes: true,
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'a'],
        useSuspense: true,
      },
    },
    // (e, t) => {
    e => {
      if (e) {
        errorLog(`i18next init error ${e.code}: ${e.message}`);
      }
      /* if (DEV_MODE) {
        const langArr = [];
        Object.keys(ko).forEach(oneDepthKeys => {
          const oneDepth = ko[oneDepthKeys];
          Object.keys(oneDepth).forEach(twoDepthKeys => {
            let twoDepth = oneDepth[twoDepthKeys];
            if (typeof twoDepth === 'object') {
              twoDepth = oneDepth[twoDepthKeys];
              Object.keys(twoDepth).forEach(threeDepthKeys => {
                let threeDepth = twoDepth[threeDepthKeys];
                if (typeof threeDepth === 'object') {
                  threeDepth = twoDepth[threeDepthKeys];
                  Object.keys(threeDepth).forEach(fourDepthKeys => {
                    const thirdKey = `${oneDepthKeys}:${twoDepthKeys}.${threeDepthKeys}.${fourDepthKeys}`;
                    langArr.push(thirdKey);
                    console.log(`${thirdKey}= ${t(thirdKey)}`);
                  });
                } else {
                  const secondKey = `${oneDepthKeys}:${twoDepthKeys}.${threeDepthKeys}`;
                  langArr.push(secondKey);
                  console.log(`${secondKey}= ${t(secondKey)}`);
                }
              });
            } else {
              const firstKey = `${oneDepthKeys}:${twoDepthKeys}`;
              langArr.push(`${firstKey}`);
              console.log(`${firstKey}= ${t(firstKey)}`);
            }
          });
        });
        // log(`keys: ${langArr}`);
        console.log(`length: ${langArr.length}`);
      } */
    },
  );

export default i18n;
