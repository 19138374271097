/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Header, Image, Icon } from 'semantic-ui-react';
import moment from 'moment';
import {
  WeatherLocation,
  WeatherDust,
  WEATHER_COLOR,
} from './WeatherComponents';
import { SITE_URL } from '../../../circulusConfig';

const WeatherDustWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;
  width: 100%;
  gap: 2rem;
  margin-top: 2rem;
`;

const WeatherRemarksGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  font-size: 0.875rem;
  gap: 0.5em;
  align-self: self-end;
  margin-top: -2rem;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
  }
`;

const WeatherDustTabs = styled.div`
  display: flex;
  flex: 1 0;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 8px;
  isolation: isolate;
  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
    & > span {
      font-size: 0.875rem;
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 0px;
      color: #fff;
      letter-spacing: -1px;
      &.cursor {
        position: absolute;
        width: initial;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.55);
        &.dust {
          bottom: 1.75em;
          left: 0;
          &:not(.none)::before {
            font-family: Icons;
            font-size: 1.25em;
            content: '\f08d';
            padding-right: 2px;
            margin-bottom: -4px;
          }
        }
        &.good {
          color: rgba(36, 143, 197, 0.9);
          left: 0;
        }
        &.normal {
          color: rgba(88, 166, 198, 0.9);
          left: 25%;
        }
        &.soso {
          color: rgba(155, 120, 87, 0.9);
          left: 50%;
        }
        &.bad {
          color: rgba(129, 20, 13, 0.9);
          left: 75%;
        }
        &.nano {
          bottom: -1.75em;
          &:not(.none)::before {
            font-family: Icons;
            font-size: 1.25em;
            content: '\f08d';
            padding-right: 2px;
            margin-bottom: 2px;
            transform: rotateX(180deg);
          }
        }
      }
      &.tab.good {
        background: #60bfe0;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &.tab.normal {
        background: #96cee1;
      }
      &.tab.soso {
        background: #c7af95;
      }
      &.tab.bad {
        background: #e26259;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
`;

const WeatherTimeInfoWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const TemperatureValueWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > span {
    font-size: 1rem;
    font-weight: bold;
    &.max {
      color: #ff3f34;
    }
    &.min {
      color: #0033dd;
    }
  }
`;

const WeatherTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;
  & > span {
    font-size: 0.75rem;
  }
  & img.ui.image {
    width: 70px;
    height: 70px;
  }
  & h5.header {
    margin: 0;
    color: inherit;
  }
`;

const Temperature = styled.div`
  position: relative;
  align-self: stretch;
  height: inherit;
  width: 12px;
  margin-right: 8px;
  background: linear-gradient(
    0deg,
    #0033dd 0%,
    #4a85bb 25%,
    #ffb865 50%,
    #fc8427 75%,
    #ff3f34 100%
  );
  mix-blend-mode: darken;
  opacity: 0.8;
  border-radius: 12px;
`;

const WeatherImageInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1rem;
  & > img.ui.image {
    min-width: 90px;
    min-height: 90px;
    max-width: 140px;
    padding: 0.5rem;
  }
`;

const WeatherInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  min-width: 50%;
`;

const WeatherContent = styled.div`
  font-weight: bold;
  padding-left: 0.5rem;
  & > .header {
    margin: 0;
    color: inherit;
    font-family: Nanum Gothic;
  }
  & h6.header {
    font-size: 1rem;
  }
  & h1.header {
    font-size: 3rem;
    line-height: 1.1;
  }
`;

const NewWeatherCard = ({ type: v, error, ...data }) => {
  const vArr = v.split('');
  const [type, days, weather, dust, mid] = vArr.map((val, idx) =>
    idx < 2 ? Number(val) : !!Number(val),
  );
  const {
    setColor,
    locationKeyword: location,
    pm10,
    pm25,
    temp,
    sky,
    skyAm,
    skyPm,
    tempMin,
    tempMax,
  } = data;
  const [label, setLabel] = useState(null);
  const [midLabel, setMidLabel] = useState({
    cAm: '',
    cPm: '',
    lbAm: '',
    lbPm: '',
  });
  const [dustLabel, setDustLabel] = useState({ label10: '', label25: '' });
  const [subject, setSubject] = useState('');
  const [grade25, val25] = pm25 || [null, null];
  const [grade10, val10] = pm10 || [null, null];

  useEffect(() => {
    if (type === 0 && !weather && mid) {
      const { bgAm, color: cAm, label: lbAm } = WEATHER_COLOR[skyAm];
      const { bgPm, color: cPm, label: lbPm } = WEATHER_COLOR[skyPm];
      const background = `${bgAm}, ${bgPm}, #FFFFFF`;
      setMidLabel({ cAm, cPm, lbAm, lbPm });
      setColor({ background, color: 'inherit' });
    }
    if (type === 0 && weather && dust) {
      const { background, color, label: l } = WEATHER_COLOR[sky];
      setLabel(l);
      setColor({ background, color });
    }
    if (type === 0 && weather && !dust) {
      const { background, color, label: l } = WEATHER_COLOR[sky];
      setLabel(l);
      setColor({ background, color });
    }
    if (type === 1 && dust) {
      const { label: label10, bg10 } = WEATHER_COLOR[grade10];
      const { label: label25, bg25 } = WEATHER_COLOR[grade25];
      const background = `linear-gradient(315deg, ${bg25}, rgba(255, 255, 255, 1) 50%, ${bg10}), #FFFFFF;`;
      setDustLabel({ label10, label25 });
      setColor({ background, color: 'rgba(0,0,0,.87)' });
    }
  }, [mid, weather, dust]);

  useEffect(() => {
    if (error) {
      setSubject(error);
    } else if (days) {
      setSubject(
        `${moment()
          .add(days, 'd')
          .format('LL')} ${location} 날씨 정보`,
      );
    } else {
      setSubject(location);
    }
  }, [error, location]);

  return (
    <div>
      <WeatherLocation>
        <Header as="h5">{subject}</Header>
      </WeatherLocation>
      {!error && (
        <>
          {type === 0 && weather && dust && (
            <WeatherImageInfoWrap>
              <Image
                src={`${SITE_URL}image/bots/weather/${sky}.png`}
                alt={sky}
              />
              <WeatherInfoWrap>
                <WeatherContent>
                  <Header as="h6">{label}</Header>
                  <Header as="h1">{`${temp}℃`}</Header>
                </WeatherContent>
                <WeatherDust direction="column">
                  <div>
                    <span className="subject">초미세먼지</span>
                    <span className="bold">{grade25 || val25}</span>
                    {grade25 && val25 && <span>{`${val25}µg`}</span>}
                  </div>
                  <div>
                    <span className="subject">미세먼지</span>
                    <span className="bold">{grade10 || val10}</span>
                    {grade10 && val10 && <span>{`${val10}µg`}</span>}
                  </div>
                </WeatherDust>
              </WeatherInfoWrap>
            </WeatherImageInfoWrap>
          )}
          {type === 0 && weather && !dust && (
            <WeatherImageInfoWrap>
              <Image
                src={`${SITE_URL}image/bots/weather/${sky}.png`}
                alt={sky}
              />
              <WeatherInfoWrap>
                <WeatherContent>
                  <Header as="h6">{label}</Header>
                  <Header as="h1">{`${temp}℃`}</Header>
                </WeatherContent>
              </WeatherInfoWrap>
            </WeatherImageInfoWrap>
          )}
          {type === 0 && !weather && mid && (
            <WeatherTimeInfoWrap>
              <WeatherTime style={{ color: midLabel.cAm }}>
                <span>오전</span>
                <Image
                  src={`${SITE_URL}image/bots/weather/${skyAm}.png`}
                  alt={skyAm}
                />
                <Header as="h5">{midLabel.lbAm}</Header>
              </WeatherTime>
              <WeatherTime style={{ color: midLabel.cPm }}>
                <span>오후</span>
                <Image
                  src={`${SITE_URL}image/bots/weather/${skyPm}.png`}
                  alt={skyPm}
                />
                <Header as="h5">{midLabel.lbPm}</Header>
              </WeatherTime>
              <div style={{ display: 'flex', padding: '1rem 0' }}>
                <Temperature>
                  <span />
                </Temperature>
                <TemperatureValueWrap>
                  <span className="max">{`${tempMax}℃`}</span>
                  <span className="min">{`${tempMin}℃`}</span>
                </TemperatureValueWrap>
              </div>
            </WeatherTimeInfoWrap>
          )}
          {!!days && dust && (
            <WeatherDust direction="row" style={{ marginTop: '.75rem' }}>
              <div style={{ margin: '0 1rem' }}>
                <span className="subject">초미세먼지</span>
                {grade25 && <span className="bold">{grade25}</span>}
                <span>{`${val25}µg`}</span>
              </div>
              <div style={{ margin: '0 1rem' }}>
                <span className="subject">미세먼지</span>
                <span className="bold">{grade10}</span>
                <span>{`${val10}µg`}</span>
              </div>
            </WeatherDust>
          )}
          {type === 1 && dust && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
              }}
            >
              <WeatherRemarksGroup>
                <div>
                  <Icon name="pin" />
                  <span style={{ height: '14px' }}>미세먼지</span>
                </div>
                <div>
                  <Icon name="pin" flipped="vertically" />
                  <span>초미세먼지</span>
                </div>
              </WeatherRemarksGroup>
              <WeatherDustWrap>
                <WeatherDustTabs>
                  <div>
                    {grade10 && val10 && (
                      <span className={`cursor dust ${dustLabel.label10}`}>
                        {`${val10}µg`}
                      </span>
                    )}
                    {!(grade10 && val10) && (
                      <span className={`cursor dust ${dustLabel.label10}`}>
                        {val10}
                      </span>
                    )}
                    <span className="tab good">좋음</span>
                    <span className="tab normal">보통</span>
                    <span className="tab soso">나쁨</span>
                    <span className="tab bad">매우 나쁨</span>
                    {grade25 && val25 && (
                      <span className={`cursor nano ${dustLabel.label25}`}>
                        {`${val25}µg`}
                      </span>
                    )}
                    {!(grade25 && val25) && (
                      <span className={`cursor nano ${dustLabel.label25}`}>
                        {val25}
                      </span>
                    )}
                  </div>
                </WeatherDustTabs>
              </WeatherDustWrap>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NewWeatherCard;
