import React, { useEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { Header } from 'semantic-ui-react';
import { redirect, useNavigate } from 'react-router-dom';
import InputsForFindAccounts from '../../components/account/InputsForFindAccounts';
import SendCodeForFindAccounts from '../../components/account/SendCodeForFindAccounts';
import { TextButton } from '../../components/account/AccountComponents';
import { trimAll } from '../../utils/common';
import { MAIL_REGEXP, TEL_REGEXP } from '../../utils/validate';

const FindIdByNameContainer = props => {
  const {
    select,
    error,
    step,
    userinfo,
    hadSent,
    pending,
    handleChange,
    handleSend,
    handleResend,
    t,
  } = props;
  const navigate = useNavigate();
  const [name, setName] = useState(userinfo.name || '');
  const [email, setEmail] = useState(userinfo.email || '');
  const [tel, setTel] = useState(userinfo.tel || '');
  const [cert, setCert] = useState('');
  const [authMethod, setMethod] = useState('tel');
  const nameInput = {
    key: 'name',
    label: t('NAME_NICK.LB'),
    placeholder: t('FIND.ERR_MSG.NAME'),
    name: 'name',
    type: 'text',
    disabled: step > 0,
  };
  const emailInput = {
    key: 'email',
    label: t('MAIL.LB'),
    placeholder: t('EMAIL.INPUT.VERIFIED'),
    pattern: MAIL_REGEXP,
    name: 'email',
    type: 'email',
    disabled: step > 0,
    value: email,
    defaultValue: userinfo && userinfo.email,
  };
  const telInput = {
    key: 'tel',
    label: t('TEL.LB'),
    placeholder: t('TEL.INPUT.VERIFIED'),
    pattern: TEL_REGEXP,
    name: 'tel',
    type: 'tel',
    disabled: step > 0,
    value: tel,
    defaultValue: userinfo && userinfo.tel,
  };
  const userIdInput = {
    key: 'userId',
    label: t('ID.LB'),
    name: 'userId',
    type: 'text',
    disabled: true,
    style: { color: '#0894d7', fontWeight: 'bold' },
    defaultValue: userinfo && userinfo.userId,
  };

  const onChange = e => {
    const changeTarget = e.target.name;
    const changeValue = e.target.value;
    switch (changeTarget) {
      case 'name':
        setName(changeValue.substr(0, 30).trim());
        break;
      case 'email':
        setEmail(changeValue.trim());
        break;
      case 'tel':
        setTel(changeValue.trim());
        break;
      case 'cert':
        setCert(trimAll(changeValue));
        break;
      default:
        break;
    }
  };

  const onMethodChange = (e, { value: newMethod }) => {
    if (newMethod === 'tel') {
      setEmail('');
    } else {
      setTel('');
    }
    setMethod(newMethod);
  };

  useUpdateEffect(() => handleChange({ name, email, tel, cert, authMethod }), [
    name,
    email,
    tel,
    cert,
    authMethod,
  ]);

  useEffect(() => (select ? () => {} : redirect('/findAccounts')), [select]);

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      {step === 0 && (
        <InputsForFindAccounts
          {...{
            nameInput: { ...nameInput, value: name },
            telInput,
            emailInput,
            info: t('FIND.MSG.INPUT_INFO'),
            msg: error,
            authMethod,
            onMethodChange,
            onChange,
          }}
        />
      )}
      {step === 1 && (
        <SendCodeForFindAccounts
          {...{
            userIdInput,
            nameInput: {
              ...nameInput,
              defaultValue: userinfo && userinfo.name,
            },
            authMethod,
            authInput: authMethod === 'tel' ? telInput : emailInput,
            loading: pending,
            info: t('ID.MSG.INFO'),
            msg: error,
            hadSent,
            onChange,
            onSend: handleSend,
            onResend: handleResend,
          }}
        />
      )}
      {step === 2 && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: 'auto',
          }}
        >
          <Header
            as="h3"
            style={{
              margin: 0,
              padding: '.5rem 5%',
              color: '#565B5B',
              textAlign: 'center',
            }}
          >
            {t('FIND.RESULT.ID', { name, userId: userinfo.userId })}
          </Header>
          <TextButton
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              margin: '1.5rem auto',
              color: '#0894d7',
              width: '100%',
            }}
            type="button"
            onClick={() => navigate('/account/pw')}
          >
            {t('FIND.GO.RESET_PW')}
          </TextButton>
        </div>
      )}
    </div>
  );
};

export default FindIdByNameContainer;
