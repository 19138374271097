import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Form, Radio, Menu } from 'semantic-ui-react';
import { PiboMenu, PiboMenuItem } from '../PiboComponents';

const PiboStyle = props => {
  const { t } = useTranslation();
  const { value: propLevel, list, handleChange } = props;
  const [level, setLevel] = useState(propLevel || 0);

  useEffect(() => {
    setLevel(propLevel || 0);
  }, [propLevel]);

  const handleLevelChange = (e, { value: v }) => {
    handleChange(v);
    setLevel(v);
  };

  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <PiboMenu vertical fluid>
            <Form>
              {list &&
                list.length > 0 &&
                list.map(item => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PiboMenuItem
                    key={item.id}
                    value={item.value}
                    onClick={handleLevelChange}
                  >
                    <Form.Field style={{ margin: 0 }}>
                      <Radio
                        label={t(item.label)}
                        name="radioGroup"
                        value={item.value}
                        checked={level.toString() === item.value}
                        onChange={handleLevelChange}
                      />
                    </Form.Field>
                    <Menu.Menu style={{ margin: 0 }}>
                      <Menu.Item
                        name={
                          Number(item.value) > 0
                            ? '파이보가 사용자에게 공손한 높임말을 사용합니다.'
                            : '파이보가 사용자에게 친근한 낮춤말을 사용합니다.'
                        }
                        style={{ paddingLeft: '2.2em' }}
                      />
                    </Menu.Menu>
                  </PiboMenuItem>
                ))}
            </Form>
          </PiboMenu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PiboStyle;
