import React, { useState, useEffect } from 'react';
import { IconGroup } from 'semantic-ui-react';
import { StyledToggleButton, ToggleIcon, IconBackground } from './Components';

const ToggleButton = ({
  onClick,
  toggle,
  corners,
  colors,
  icons,
  compact,
  disabled,
}) => {
  const [[color, cornerIcon], setStyle] = useState([]);
  const getColor = (t, c) => {
    if (!t && c.length > 1) {
      return c[1];
    }

    return c[0];
  };

  const getCornerIcon = (t, c) => {
    if (c) {
      if (!t && c.length > 1) {
        return c[1];
      }
      return c[0];
    }
    return undefined;
  };

  const handleClick = e => {
    onClick(e, { toggle });
  };

  useEffect(() => {
    setStyle([getColor(toggle, colors), getCornerIcon(toggle, corners)]);
  }, [toggle]);

  return (
    <StyledToggleButton
      toggle={toggle}
      color={color}
      compact={compact}
      onClick={handleClick}
      disabled={disabled}
    >
      <IconBackground compact={compact} color={color}>
        <IconGroup>
          <ToggleIcon name={toggle ? icons[0] : icons[1]} customcolor={color} />
          {cornerIcon && <ToggleIcon corner name={cornerIcon} />}
        </IconGroup>
      </IconBackground>
    </StyledToggleButton>
  );
};

export default ToggleButton;
