import React, { useState } from 'react';
import { Message, Accordion, Icon, Button, Form } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import {
  AccountWrap,
  AccountForm,
  AccountInput,
  TextButton,
} from './AccountComponents';
import { StyledMessage } from '../Message';

const SendCodeForFindAccounts = props => {
  const { t } = useTranslation('user');
  const {
    info,
    userIdInput,
    nameInput,
    serialInput,
    authMethod,
    authInput: { value: av, ...authInput },
    msg,
    hadSent,
    loading,
    onChange,
    onBlur,
    onSend,
    onResend,
  } = props;
  const [activeIndex, setActiveIndex] = useState(-1);
  useUpdateEffect(() => setActiveIndex(-1), [hadSent]);
  return (
    <AccountForm>
      <AccountWrap>
        {info && <p style={{ fontSize: '0.75rem' }}>{info}</p>}
        <Form size="large" style={{ fontSize: '0.875rem ' }}>
          <div className="field">
            <div className="ui fluid input">
              <AccountInput
                {...{
                  ...userIdInput,
                  onChange,
                  onBlur,
                }}
              />
            </div>
          </div>
        </Form>
        {nameInput && (
          <Form size="large" style={{ fontSize: '0.875rem ' }}>
            <div className="field">
              <div className="ui fluid input">
                <AccountInput
                  {...{
                    ...nameInput,
                    onChange,
                    onBlur,
                  }}
                />
              </div>
            </div>
          </Form>
        )}
        {serialInput && (
          <Form size="large" style={{ fontSize: '0.875rem ' }}>
            <div className="field">
              <div className="ui fluid input">
                <AccountInput
                  {...{
                    ...serialInput,
                    onChange,
                    onBlur,
                  }}
                />
              </div>
            </div>
          </Form>
        )}
        <Form size="large" style={{ fontSize: '0.875rem ' }}>
          <div className="field">
            <div className="ui fluid input">
              <AccountInput
                {...{
                  ...authInput,
                  onChange,
                  onBlur,
                }}
              />
            </div>
          </div>
        </Form>
        {hadSent && (
          <div style={{ marginTop: '1em', width: '100%' }}>
            <AccountInput
              style={{
                clear: 'both',
                width: '100%',
                margin: 'auto',
                lineHeight: '1.35714286em',
                padding: '.50892857em 1em',
              }}
              name="cert"
              placeholder={t('FIND.INPUT.CERT_CODE')}
              type="text"
              {...{ onChange, onBlur }}
            />
            {
              authMethod === 'email' && (
              <AccountWrap>
                <Accordion
                  style={{
                  alignSelf: 'flex-start',
                  fontSize: '0.875em',
                  color: '#0894d7',
                  padding: '.5em 0',
                }}
                >
                  <Accordion.Title
                    active={activeIndex === 1}
                    index={1}
                    style={{ color: '#0894d7', fontWeight: 600, padding: 0 }}
                    onClick={() => setActiveIndex(activeIndex === 1 ? -1 : 1)}
                  >
                    <Icon name="question circle outline" />
                    {t('MAIL.TITLE.NOT_RECEIVED')}
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 1}
                    style={{ fontSize: '0.85em' }}
                  >
                    <p>{t('MAIL.CONTENT.NOT_RECEIVED')}</p>
                    <p>
                      <TextButton type="button" onClick={onResend}>
                        {t('MAIL.HERE')}
                      </TextButton>
                      {t('MAIL.MSG.SEND_RETRY')}
                    </p>
                  </Accordion.Content>
                </Accordion>
              </AccountWrap>
              )
            }
          </div>
        )}
        {!hadSent && (
          <Button
            color="blue"
            disabled={hadSent || loading}
            style={{ width: '100%', marginBottom: '1rem', borderRadius: '0' }}
            name="send"
            onClick={onSend}
          >
            {loading && <Icon name="spinner" loading />}
            {!loading && t('SEND.CERT_CODE')}
          </Button>
        )}
        {msg && (
          <StyledMessage
            color="red"
            hidden={!msg}
            textalign="left"
            style={{ marginTop: '1rem', marginBottom: '3rem' }}
          >
            <Message.Content>{msg}</Message.Content>
          </StyledMessage>
        )}
      </AccountWrap>
    </AccountForm>
  );
};

export default SendCodeForFindAccounts;
