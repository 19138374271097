import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useInterval, useLifecycles, useUpdateEffect } from 'react-use';
import { Button, Icon } from 'semantic-ui-react';
import { setTimeFormat } from '../../utils/common';
import { CertCodeTimer } from '../account/Components';
import {
  InputWrap,
  InputLabel,
  StyledInput,
  StyledInputIcon,
  InputMessage,
} from './Components';

let delay = null;

const UserInfoInput = props => {
  const {
    id,
    name,
    type,
    value,
    placeholder,
    readOnly,
    loading,
    onChange,
    error: errorMsg,
    verified: oldVerified,
    sent,
    disabled,
    handleRequestSend,
    handleRequestCert,
    handleVerifyUpdate,
  } = props;
  const { t } = useTranslation();
  const [verified, setVerified] = useState(oldVerified);
  const [v, setV] = useState(value);
  const [certCode, setCode] = useState('');
  const [error, setError] = useState(null);
  const [timer, setTimer] = useState({
    cur: 0,
    limit: 0,
    formatted: '',
    complete: false,
  });
  const [isTicking, setTicking] = useState(false);
  const [curTime, setCurTime] = useState(0);

  const handleBlur = e => {
    const { name: n, value: val } = e.target;
    if (n && val) {
      onChange({ name: n, value: val });
    }
  };

  const handleFocus = e => {
    const { name: n, value: val } = e.target;
    if (val.length === 5 && error) {
      setCode('');
      onChange({ name: n, value: false });
    }
  };

  const handleChange = e => {
    const { name: n, value: val } = e.target;
    if (n === 'certCode') {
      if (val !== certCode) {
        onChange({ name: n, value: true });
      }
      return setCode(val);
    }
    if (id !== 'nickName' && n && val) {
      onChange({ name: n, value: val });
    }
    return setV(val);
  };

  useUpdateEffect(() => {
    if (delay) {
      clearTimeout(delay);
    }
    delay = setTimeout(() => {
      if ((id === 'email' || id === 'tel') && v && v !== value) return;
      onChange({ name: id, value: v });
    }, 500);
  }, [v]);

  const handleUpdateClick = () => {
    handleVerifyUpdate({ id, sent: true, verified: false });
    setVerified(false);
    setV('');
  };

  const handleTimeOver = () => {
    setTicking(false);
    setV(value);
    setVerified(oldVerified);
    handleVerifyUpdate({ id, sent: false, verified: oldVerified });
    setCurTime(0);
    setCode('');
  };

  const getTimerInfo = (origin, ready) => {
    const { cur: c, limit: l, time: oldT } = origin;
    let cur = c;
    let limit = l;
    let time = oldT;
    let formatted = '';
    if (cur >= limit) {
      // 끝
      cur = 0;
      limit = 0;
      time = 0;
      formatted = '';
      handleTimeOver();
    } else {
      if (!ready) {
        cur += 1000;
        time = moment.duration(time - 1000, 'milliseconds');
      }
      const mm = setTimeFormat(time.get('minutes'));
      const ss = setTimeFormat(time.get('seconds'));
      formatted = `${mm}:${ss}`;
    }
    return { cur, limit, time, formatted };
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setCurTime(new Date().valueOf());
      setTicking(false);
    }
  };

  useEffect(() => {
    if (!(id === 'email' || id === 'tel')) return;
    setVerified(oldVerified);
  }, [oldVerified]);

  useUpdateEffect(() => {
    if ((id === 'email' || id === 'tel') && [id] in errorMsg && !errorMsg[id]) {
      return setError(
        errorMsg.verified && [id] in errorMsg.verified && errorMsg.verified[id],
      );
    }
    return setError(errorMsg[id]);
  }, [errorMsg]);

  useUpdateEffect(() => {
    if (!(id === 'email' || id === 'tel')) return;
    if (sent[id] && !verified && !timer.limit) {
      const { expireTime, firstTime } = sent;
      const timeLimit = expireTime - firstTime;
      const time = moment.duration(timeLimit, 'milliseconds');
      setTimer(getTimerInfo({ cur: 0, limit: timeLimit, time }, true));
      setTicking(true);
    }

    if (sent[id] && verified) {
      setTicking(false);
    }
  }, [sent, verified]);

  useInterval(
    () => {
      if (curTime === 0) {
        setTimer(getTimerInfo(timer));
      } else {
        setCurTime(0);
      }
    },
    isTicking ? 1000 : null,
  );

  useUpdateEffect(() => {
    if (sent) {
      const { expireTime, firstTime } = sent;
      if (curTime !== 0 && curTime < expireTime) {
        const diff = Math.round((curTime - firstTime) / 1000);
        const limit = Math.floor((expireTime - curTime) / 1000);
        const seconds = diff * 1000;
        const cur = timer.cur + seconds;
        const time = moment.duration(limit * 1000, 'milliseconds');
        setTimer(getTimerInfo({ ...timer, time, cur }));
        setTicking(true);
      }
    }
  }, [curTime]);

  useLifecycles(
    () => {
      setV(value);
      document.addEventListener('visibilitychange', handleVisibilityChange);
    },
    () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    },
  );

  return (
    <InputWrap>
      <InputLabel>{name}</InputLabel>
      <div
        style={{
          color: '#03BFD7',
          fontWeight: '100',
          width: '100%',
          display: 'flex',
          position: 'relative',
        }}
      >
        {(id === 'email' || id === 'tel') && verified && (
          <StyledInputIcon name="check" />
        )}
        <StyledInput
          type={type}
          name={id}
          value={v || ''}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={id === 'email' || id === 'tel' ? verified : readOnly}
          verified={verified}
        />
        {(id === 'email' || id === 'tel') && verified && (
          <Button
            color="teal"
            size="tiny"
            style={{ marginLeft: '1rem' }}
            disabled={disabled}
            onClick={handleUpdateClick}
          >
            {t('user:UPDATE.LB')}
          </Button>
        )}
        {(id === 'email' || id === 'tel') && !verified && !sent[id] && (
          <Button
            color="blue"
            size="tiny"
            style={{ marginLeft: '1rem' }}
            disabled={!!errorMsg[id] || !v}
            onClick={() => handleRequestSend({ type: id, value: v })}
          >
            {loading ? <Icon name="spinner" loading /> : t('user:VERIFY.LB')}
          </Button>
        )}
      </div>
      {(id === 'email' || id === 'tel') && !verified && sent[id] && (
        <div
          style={{
            color: '#03BFD7',
            fontWeight: '100',
            width: '100%',
            display: 'flex',
            marginTop: '1rem',
          }}
        >
          <div style={{ position: 'relative' }}>
            <StyledInput
              type="tel"
              name="certCode"
              value={certCode}
              placeholder={t('user:VERIFY.INPUT.CODE')}
              style={{
                height: '100%',
                flexBasis: '70px',
                flexGrow: '100',
              }}
              onChange={handleChange}
              onFocus={handleFocus}
            />
            {isTicking && timer.formatted && (
              <CertCodeTimer>{timer.formatted}</CertCodeTimer>
            )}
          </div>
          <Button
            color="blue"
            size="tiny"
            style={{ flexBasis: '70px', marginLeft: '1rem' }}
            disabled={!certCode || certCode.length !== 5}
            onClick={() => handleRequestCert({ type: id, code: certCode })}
          >
            {loading ? <Icon name="spinner" loading /> : t('user:VERIFY.LB')}
          </Button>
        </div>
      )}
      {!loading && <InputMessage>{error}</InputMessage>}
    </InputWrap>
  );
};

export default UserInfoInput;
