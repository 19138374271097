/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, Form, Radio } from 'semantic-ui-react';
import { PiboMenu, PiboMenuItem } from '../PiboComponents';

const PiboCallname = ({ value: v, list, handleChange }) => {
  const [value, setValue] = useState('');

  useEffect(() => setValue(v));

  const onChange = (e, { value: val }) => {
    handleChange(val);
    setValue(val);
  };

  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <PiboMenu vertical fluid>
            <Form>
              {list.length > 0 &&
                list.map((item, idx) => (
                  <PiboMenuItem key={idx} value={item.id} onClick={onChange}>
                    <Form.Field>
                      <Radio
                        label={item.value}
                        name="radioGroup"
                        value={item.id}
                        checked={value === item.id}
                        onChange={onChange}
                      />
                    </Form.Field>
                  </PiboMenuItem>
                ))}
            </Form>
          </PiboMenu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};
export default PiboCallname;
