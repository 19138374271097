/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { Form, TextArea } from 'semantic-ui-react';
import { testSpeakRegExr } from '../../../ducks/bots/trainBot/consts';
import { onSaveExecution } from '../../../ducks/bots/trainBot/train';
import { CardBody, InvalidText } from './Components';
import ExecCard from './ExecCard';
import EyeColorCard from './EyeColorCard';
import FxCard from './FxCard';

const SpeakExecCard = props => {
  const dispatch = useDispatch();
  const {
    execution: { color, icon, label: title },
    data: { colors, fx },
  } = useSelector(state => state.train);
  const { t } = useTranslation();
  const { onCancel } = props;
  const [value, setValue] = useState({
    speak: { value: '' },
    _eye: null,
    _fx: null,
  });
  const [error, setError] = useState(null);

  const handleEyeColorChange = name => {
    setValue({ ...value, _eye: name });
  };
  const handlefxChange = name => {
    setValue({ ...value, _fx: name });
  };
  const handleTextChange = e => {
    const { value: speakValue } = e.target;
    const speakText = speakValue.replace(/[\n\r\t]/g, '');
    setValue({ ...value, speak: { value: speakText } });
  };
  const handleSave = () => {
    if ('speak' in value && value.speak.value && !error) {
      dispatch(onSaveExecution(value));
    }
  };
  useUpdateEffect(() => {
    const { speak } = value;
    if (speak.value.length === 0) {
      setError(t('bot:TRN.MSG.SPEAK'));
    } else if (speak.value.length > 40) {
      setError(t('bot:TRN.MSG.SPEAK_LENGTH'));
      setValue({ ...value, speak: { value: speak.value.slice(0, 40) } });
    } else if (testSpeakRegExr(speak.value)) {
      setError(t('bot:AVA.SPEAKING.INFO'));
    } else {
      setError('');
    }
  }, [value]);
  return (
    <ExecCard
      {...{
        t,
        color,
        icon,
        title,
        options: {
          onCancel,
          onSave: handleSave,
          saveDisabled: error === null || !!error,
        },
      }}
    >
      <CardBody>
        <p>{t('bot:TRN.LABEL.INPUT_SPEAK')}</p>
        <Form>
          <TextArea
            rows={3}
            style={{ fontSize: '0.875rem' }}
            placeholder={t('bot:TRN.PLACEHOLDER.INPUT_SPEAK')}
            onChange={handleTextChange}
          />
        </Form>
        {error && <InvalidText>{error}</InvalidText>}
      </CardBody>
      {(value.speak.value || value._eye) && (
        <EyeColorCard colors={colors} onChange={handleEyeColorChange} />
      )}
      {(value.speak.value || value._fx) && (
        <FxCard t={t} fx={fx} onChange={handlefxChange} />
      )}
    </ExecCard>
  );
};

export default SpeakExecCard;
