/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputWrap, InputLabel, StyledSelect } from './Components';

const UserInfoSelect = props => {
  const { t } = useTranslation('user');
  const {
    id,
    name,
    options,
    value: initialValue,
    readOnly,
    isFirst,
    onBlur,
  } = props;
  const [inputValue, setInputValue] = useState(initialValue);
  const handleChange = ({ target: { name: n, value } }) => {
    setInputValue(value);
    onBlur({ name: 'gender', value });
  };

  // useEffect(() => {
  //   handleChange({ target: { value: initialValue } });
  // }, [initialValue]);

  return (
    <InputWrap>
      <InputLabel>{name}</InputLabel>
      <div
        style={{
          color: '#03BFD7',
          fontWeight: '100',
          width: '100%',
        }}
      >
        <StyledSelect
          placeholder={t('COMP.SELECT', { name })}
          name="gender"
          options={options}
          value={inputValue}
          style={{ width: '100%', height: '100%' }}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={isFirst ? true : readOnly}
          readOnly={isFirst ? true : readOnly}
        >
          {options.map(({ id: oid, key, text, value }) => (
            <option id={oid} key={key} value={value}>
              {text}
            </option>
          ))}
        </StyledSelect>
      </div>
    </InputWrap>
  );
};

export default UserInfoSelect;
