import React from 'react';

const ObjectDetectCard = ({ object, person }) => {
  const people = person && person.length ? person.length : null;
  const objet = object && object.length ? object : null;
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
      }}
    >
      {people && objet && (
        <div style={{ display: 'flex', flexFlow: 'wrap', gap: '0.25em' }}>
          <div>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {`${people}명의 사람`}
            </span>
            <span>과</span>
          </div>
          <div>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {object.reduce(
                (sentence, obj, i) =>
                  i ? `${obj}, ${sentence}` : `${obj} ${sentence}`,
                '',
              )}
            </span>
            <span>등과 같은 사물을 찾았어.</span>
          </div>
        </div>
      )}
      {!objet && people && (
        <div>
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {`${people}명의 사람`}
          </span>
          을 찾았어.
        </div>
      )}
      {!people && objet && (
        <div>
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            {object.reduce(
              (sentence, obj, i) =>
                i ? `${obj}, ${sentence}` : `${obj} ${sentence}`,
              '',
            )}
          </span>
          등과 같은 사물을 찾았어.
        </div>
      )}
    </div>
  );
};

export default ObjectDetectCard;
