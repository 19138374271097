import { Icon, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

const InputWrap = styled(Segment)`
  font-size: 0.875rem;
  padding: 1rem 0;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const InputLabel = styled.div`
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.15;
  padding: 4px 8px;
`;

const InputMessage = styled.div`
  font-size: 12px;
  color: #ef3340;
  text-align: left;
  line-height: 1.35;
  padding: 4px;
`;

const StyledInputIcon = styled(Icon)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  vertical-align: middle;
  transform: translateY(50%);
`;

const StyledInput = styled.input`
  border: none;
  background: transparent;
  border-bottom: ${({ readOnly }) =>
    readOnly ? '1px solid #d1d3d4' : '1px solid #03bfd7'};
  color: ${({ readOnly }) => (readOnly ? '#0894d7' : 'initial')};
  border-radius: 0;
  font-size: 1rem;
  padding: ${({ verified }) =>
    verified ? '0.5rem 0 0.5rem 1.5rem' : '0.5rem'};
  height: 100%;
  width: 100%;
  outline: none;
`;

const StyledSelect = styled.select`
  border: none;
  background: transparent;
  border-bottom: ${({ readOnly }) =>
    readOnly ? '1px solid #d1d3d4' : '1px solid #03bfd7'};
  color: ${({ readOnly }) => (readOnly ? '#0894d7' : 'initial')};
  border-radius: 0;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
  cursor: pointer;
  &:disabled {
    color: #0894d7;
    opacity: 1;
  }
`;

const ToggleButtonGroup = styled.div`
  padding: 0 8px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const ToggleButtonGroupMessage = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.75rem;
  width: 100%;
  text-align: left;
  padding-top: 8px;
  color: ${({ error }) => (error ? '#ef3340' : 'initial')};
`;

export {
  InputWrap,
  InputLabel,
  InputMessage,
  StyledInputIcon,
  StyledInput,
  StyledSelect,
  ToggleButtonGroup,
  ToggleButtonGroupMessage,
};
