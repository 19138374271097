import React, { useState } from 'react';
import qs from 'qs';
import { Button, Icon } from 'semantic-ui-react';
import { useLifecycles, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AvatarBot from './AvatarBot/Index';
import CalendarBot from './CalendarBot/Index';
import DefaultPage from './DefaultPage';
import DiaryBot from './DiaryBot/Index';
import HistoryBot from './HistoryBot/HistoryMain';
import MessageBot from './MessageBot/Index';
import PhotoBot from './PhotoBot/Index';
import TrainBot from './TrainBot/Index';

import { getRobotId } from '../../utils/common';
import { botVerify } from '../../utils/api';
import { DEFAULT_APPS } from '../../utils/consts';
import NotFound from '../../components/NotFound';
import { BotsWrapper } from '../../components/bots/Components';
import withAuth from '../../hocs/withAuth';
import ExternalBot from './ExternalBot';
import CustomBot from './CustomBot';

const BotRouter = () => {
  let isMounted = true;
  const { t } = useTranslation();
  const { search } = useLocation();
  const { name, page } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [redirectToMessage, setRedirect] = useState(false);
  const [message, setMsg] = useState('');
  const [matchingBot, setBot] = useState(null);
  const query = qs.parse(search, { ignoreQueryPrefix: true });

  const matchingComponent = (n, p, title) => {
    switch (n) {
      case 'OFFICIAL_HISTORY':
        return <HistoryBot title={title} />;
      case 'OFFICIAL_PHOTO':
        return (
          <PhotoBot title={title} page={!p ? 'main' : 'detail'} query={query} />
        );
      case 'OFFICIAL_MESSAGE':
        return <MessageBot title={title} />;
      case 'OFFICIAL_CALENDAR':
        return (
          <CalendarBot title={title} page={!p ? 'main' : p} query={query} />
        );
      case 'OFFICIAL_AVATAR':
        return <AvatarBot title={title} />;
      case 'OFFICIAL_DIARY':
        return <DiaryBot title={title} />;
      case 'OFFICIAL_TRAIN':
        return <TrainBot title={title} page={p} query={query} />;
      case 'defaultPage':
        return (
          <DefaultPage
            title={title}
            botId={!query.botId ? 'error' : query.botId}
          />
        );
      case 'externalPage':
        return (
          <ExternalBot
            title={title}
            botId={!query.botId ? 'error' : query.botId}
          />
        );
      case 'customPage':
        return (
          <CustomBot
            title={title}
            botId={!query.botId ? 'error' : query.botId}
          />
        );
      default:
        return <NotFound message={t('bot:MSG.UNEXIST')} />;
    }
  };

  const setBotPage = () => {
    if (!isMounted) return null;
    if (getRobotId() && name) {
      // if (DEV_MODE) return setBot(matchingComponent(name, page));
      setLoading(true);
      const projectId = DEFAULT_APPS.includes(name) ? name : '';
      const { botId } = query;

      new Promise(async response => {
        const res = await botVerify({
          robotId: getRobotId(),
          projectId,
          botId,
        });
        response(res);
      }).then(({ result, title, error }) => {
        setLoading(false);
        if (result) {
          setRedirect(false);
          setMsg('');
          setBot(matchingComponent(name, page, title.toLowerCase()));
        } else {
          const { code } = error;
          setRedirect(true);
          setMsg(
            t([`common:ERR.${code.toUpperCase()}`, 'common:ERR.UNSPECIFIC']),
          );
        }
      });
    } else {
      setLoading(false);
      setRedirect(true);
      setMsg(t('bot:MSG.UNEXIST'));
    }
    return null;
  };

  useLifecycles(
    () => {
      setBotPage();
    },
    () => {
      isMounted = false;
    },
  );

  useUpdateEffect(() => {
    setBotPage();
  }, [name, page]);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {!loading && redirectToMessage && (
        <BotsWrapper style={{ height: '100vh', flexFlow: 'unset' }}>
          <div>
            <Icon name="warning sign" color="blue" size="huge" />
            <p style={{ margin: '1rem 0' }}>{message}</p>
            <Button color="blue" onClick={handleBackClick}>
              <Icon name="undo" />
              {t('bot:BACK')}
            </Button>
          </div>
        </BotsWrapper>
      )}
      {!loading && !redirectToMessage && matchingBot}
    </>
  );
};

export default withAuth(BotRouter);
