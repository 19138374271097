import styled from 'styled-components';

const BotsWrapper = styled.div`
  margin: 0 10%;
  display: flex;
  flex-flow: column;
  justify-content: cetner;
  height: ${window.innerHeight};
  align-items: center;
  text-align: center;
`;

const NoPhoto = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
  padding: 1rem;
  align-items: center;
  height: calc(${window.innerHeight} - 60px);
`;

export { BotsWrapper, NoPhoto };
