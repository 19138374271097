import React, { useRef, useState, useEffect } from 'react';
import { useUpdateEffect } from 'react-use';
import { Button } from 'semantic-ui-react';

const AccordionText = ({ content, textAlign, height: h }) => {
  const taRef = useRef(null);
  const [height, setHeight] = useState('auto');
  const [visible, setVisible] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (taRef.current.clientHeight < (h || 120)) {
      setHeight('auto');
      setVisible(false);
    } else {
      setHeight(h ? `${h}px` : '120px');
      setVisible(true);
    }
  }, [taRef.current]);

  useUpdateEffect(() => {
    if (visible !== null) {
      if (open) {
        setHeight('auto');
      } else {
        setHeight(h ? `${h}px` : '120px');
      }
    }
  }, [open]);

  return (
    <div
      style={{
        margin: 'auto',
        height: 'auto',
        overflow: 'hidden',
        position: 'relative',
        fontSize: '0.875rem',
        transition: 'all 500ms ease-in-out 0s',
        width: '100%',
      }}
    >
      <div
        ref={taRef}
        style={{
          width: 'inherit',
          height,
          lineHeight: '1.2',
          textAlign,
        }}
      >
        {content}
      </div>
      {visible && (
        <Button
          size="tiny"
          fluid
          style={{
            position: open ? 'relative' : 'absolute',
            bottom: 0,
            borderRadius: 0,
            background: open ? 'transparent' : '#fff',
            boxShadow: 'none',
            color: '#0894D7',
            transition: 'all 500ms ease-in-out 0s',
          }}
          onClick={() => setOpen(!open)}
        >
          {open ? '접기' : '더보기'}
        </Button>
      )}
    </div>
  );
};

export default AccordionText;
