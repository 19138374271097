import React from 'react';
import { Header, Image } from 'semantic-ui-react';

const GonghunCard = ({ month, name, content: c, img }) => (
  <div style={{ display: 'flex', gap: '.5rem', flexFlow: 'column' }}>
    <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
      {`${month}월의 독립운동가`}
    </Header>
    {img && (
      <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
        <div style={{ maxWidth: '30%' }}>
          <Image src={img} circular />
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              gap: '.25rem',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '.5em',
            }}
          >
            <span className="hibiscus" />
            <Header as="h4" style={{ margin: 0 }}>
              {name}
            </Header>
            <span className="hibiscus" />
          </div>
        </div>
        <span>{c}</span>
      </div>
    )}
    {!img && (
      <div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            gap: '.25rem',
            alignItems: 'center',
          }}
        >
          <span className="hibiscus" />
          <Header as="h4" style={{ margin: 0 }}>
            {name}
          </Header>
          <span className="hibiscus" />
        </div>
        <span>{c}</span>
      </div>
    )}
  </div>
);

export default GonghunCard;
