import React from 'react';
import { Header } from 'semantic-ui-react';
import shortid from 'shortid';
import styled from 'styled-components';

const DirectionGroup = styled.div`
  ol {
    line-height: 1.5rem;
    margin-left: -1rem;
    li {
      margin: 0 0 0.5rem;
    }
  }
  p {
    font-size: 0.875rem;
  }
  & > .ui.header {
    margin-bottom: 0.5rem;
  }
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem auto 2rem;
  & input {
    width: 2rem;
    font-size: 1.5rem;
    background: transparent;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    border-bottom: 2px solid #03bfd7;
    margin-right: 0.25rem;
    text-align: center;
    color: #0894d7;
    padding: 0;
    // outline: none;
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
`;

const LinkWrap = styled.div`
  width: 100%;
  // @media (max-width: 600px) {
  //   width: 100%;
  // }
  @media (min-width: 501px) and (max-width: 1200px) {
    min-width: 60vw;
    max-width: 70vw;
    margin: 0 auto;
  }
  @media (min-width: 1201px) {
    min-width: 30vw;
    max-width: 600px;
    margin: 0 auto;
  }
  & > .appendix {
    font-size: 0.875em;
    text-align: center;
    font-weight: bold;
    margin-bottom: 1.5rem;
    border: 0;
    background: transparent;
    padding: 0;
    text-decoration: underline;
    color: #0894d7;
  }
`;

const CompleteDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  & .wrap5 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    width: 100%;
    & h5.ui.header {
      text-align: center;
    }
    & .ui.image {
      transform: translateY(16px) scale(1.3);
      @media (max-width: 500px) {
        max-width: 80vw;
      }
      @media (min-width: 501px) and (max-width: 1200px) {
        max-width: 70vw;
      }
      @media (min-width: 1201px) {
        max-width: 600px;
      }
    }
  }
`;

const LinkDescription = props => {
  const { header, list, text } = props;
  return (
    <DirectionGroup>
      <Header size="small" as="h4">
        {header}
      </Header>
      {list && list.length > 0 && (
        <ol>
          {list.map(v => (
            <li key={shortid.generate()}>{v}</li>
          ))}
        </ol>
      )}
      {text && <p>{text}</p>}
    </DirectionGroup>
  );
};

export { DirectionGroup, InputGroup, LinkDescription, LinkWrap, CompleteDiv };
