/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount, useUpdateEffect } from 'react-use';
import Login from '../../components/account/Login';
import { reducerInit } from '../../ducks';
import { signIn, socialSign, signInError } from '../../ducks/user';
import withLoading from '../../hocs/withLoading';
import { getLoginId, trimAll } from '../../utils/common';

const LoginPage = () => {
  const [id, setId] = useState(getLoginId());
  const [pw, setPw] = useState('');
  const { loading, toConnect, toMain, toSignUp, error } = useSelector(
    state => state.user,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useMount(() => {
    dispatch(reducerInit());
  });

  useUpdateEffect(() => {
    if (error) return;
    if (toMain || toConnect) {
      return navigate('/pibo');
    }
    if (toSignUp) {
      return navigate('/social/signup');
    }
  }, [toMain, toConnect, toSignUp]);

  const onChange = e => {
    const {
      target: { value, name },
    } = e;
    if (name === 'userid') {
      setId(trimAll(value.toLowerCase()));
    } else if (name === 'password') {
      setPw(trimAll(value));
    } else {
      setId(trimAll(id.toLowerCase()));
      setPw(trimAll(pw));
    }

    if (error) {
      dispatch(signInError(error));
    }
  };

  const handleLoginClick = bLogOn => {
    dispatch(signIn({ id, pw, bLogOn }));
    setPw('');
  };

  const handleSocialClick = data => {
    dispatch(socialSign(data));
  };
  const handleFindClick = () => navigate('/findAccounts');
  const handleJoinClick = () => navigate('/signup');
  const handleSkipClick = () => navigate('/pibo');

  return (
    <Login
      input={{ id, pw }}
      loading={loading}
      msg={error}
      onLogin={handleLoginClick}
      onSocialSign={handleSocialClick}
      onChange={onChange}
      onJoin={handleJoinClick}
      onFind={handleFindClick}
      onSkip={handleSkipClick}
    />
  );
};

export default withLoading(LoginPage);
