import React, { Fragment, useState } from 'react';
import { Button, Visibility } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useMount,
  useOrientation,
  useUpdateEffect,
  useWindowSize,
} from 'react-use';
import { useLastLocation } from 'react-router-dom-last-location';
import styled from 'styled-components';

import { useNavigate, useParams } from 'react-router-dom';
import PhotoBottomMenu from '../../../components/bots/PhotoBot/PhotoBottomMenu';
import PhotoList from '../../../components/bots/PhotoBot/PhotoList';
import withScrollManual from '../../../hocs/withScrollManual';
import { getRobotId } from '../../../utils/common';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import withPullToRefresh from '../../../hocs/withPullToRefresh';
import Confirm from '../../../components/Confirm';
import { NoPhoto } from '../../../components/bots/Components';
import {
  checkPhoto,
  deletePhoto,
  fetchPhoto,
  hitBottom,
  init,
  initStatus,
  selectPhoto,
  toggleEdit,
} from '../../../ducks/bots/photoBot/photo';

const SettingButton = styled(Button)`
  border-radius: 10em !important;
  position: fixed;
  right: 1.2rem;
  z-index: 10;
  box-sizing: border-box;
  color: ${({ inverted }) =>
    inverted ? '#0894D7 !important' : '#fff !important'};
  border: ${({ inverted }) =>
    inverted ? '1px solid #0894D7 !important;' : 'none'};
  background: ${({ inverted }) =>
    inverted ? 'rgba(255, 255, 255, .8) !important' : '#0894D7 !important;'};
  &:lang(ko) {
    top: 4rem;
  }
  &:lang(en) {
    top: 4em;
  }
`;
const WithPullToRefreshPhotoList = withPullToRefresh(PhotoList);
let backLocation = '/bots';

const PhotoMain = props => {
  const { t, refreshPhoto, title } = props;
  const { lastLocation } = useLastLocation();
  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width: w, height: h } = useWindowSize();
  const { angle } = useOrientation();
  const [column, setCol] = useState(4);
  const [totalLimit, setLimit] = useState(32);
  const {
    photoData,
    editing,
    loading,
    checkCount,
    initDataStatus,
    isBottom,
    photoDataLength,
  } = useSelector(state => state.photo);
  const [popUpProperties, setPopup] = useState({ isPopup: false });

  const getPhotos = (skip, limit, refresh = false) => {
    const robotId = getRobotId();
    dispatch(fetchPhoto({ skip, limit, robotId, refresh }));
  };

  const handleBottomVisible = (e, { calculations: { direction } }) => {
    if (!isBottom && !loading && direction === 'down') {
      dispatch(hitBottom(true));
      getPhotos(photoDataLength, totalLimit);
    }
  };

  const handleBottomVisibleReverse = () => {
    if (isBottom) {
      dispatch(hitBottom(false));
    }
  };

  const handleUpdate = (e, data) => {
    const {
      calculations: { height, pixelsPassed, direction },
    } = data;
    const passedBottom =
      parseInt(height - pixelsPassed, 10) === window.innerHeight;
    if (passedBottom && !isBottom && !loading && direction === 'down') {
      dispatch(hitBottom(true));
      getPhotos(photoDataLength, totalLimit);
    }
  };

  const handlePhotoClick = (id, parentIndex, childIndex) => {
    if (editing) return dispatch(checkPhoto(parentIndex, childIndex));
    dispatch(selectPhoto(id));
    return navigate('/bots/OFFICIAL_PHOTO/detail');
  };

  const handleItemClick = (e, { name: v }) => {
    dispatch(toggleEdit(v === 'allcheck'));
  };

  const handleItemDeleteClick = () => {
    const popup = {
      contents: t('PHT.MSG.DELETE'),
      leftLabel: t('common:POP.CANCEL'),
      rightLabel: t('common:POP.OK'),
      rightFunc: () => dispatch(deletePhoto()),
      mode: 'confirm',
      isPopup: true,
      onClose: () => setPopup({ isPopup: false }),
    };
    setPopup(popup);
  };

  const editButton = () => {
    dispatch(toggleEdit(false));
  };

  const initialize = () => {
    dispatch(init());
  };

  const calcGridColumn = () => {
    const bLandscape = Math.abs(angle) === 90;
    const calWidth = bLandscape ? h : w;
    if (calWidth < 600) {
      setCol(4);
      setLimit(32);
      return 4;
    }
    if (calWidth >= 600 && calWidth < 960) {
      setCol(8);
      setLimit(32 * 2);
      return 8;
    }
    setCol(16);
    setLimit(32 * 2);
    return 16;
  };

  useMount(() => {
    const calCol = calcGridColumn();
    if (lastLocation && 'pathname' in lastLocation) {
      if (lastLocation.pathname.search(`bots/${name}`) < 0) {
        backLocation = lastLocation.pathname;
      }
    }
    if (!initDataStatus) {
      const firstLimit = calCol * Math.ceil((h - 60) / (w / calCol));
      getPhotos(0, firstLimit, true);
      dispatch(initStatus());
    }
  });

  useUpdateEffect(() => {
    calcGridColumn();
  }, [w]);

  return (
    <>
      <BotTopMenu
        title={title}
        backLocation={backLocation}
        onClose={initialize}
      />
      {photoData.length > 0 && (
        <Visibility
          onBottomVisible={handleBottomVisible}
          onBottomVisibleReverse={handleBottomVisibleReverse}
          onUpdate={handleUpdate}
          continuous
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: '50px',
          }}
        >
          <WithPullToRefreshPhotoList
            loading={loading}
            photoData={photoData}
            editing={editing}
            onRefresh={refreshPhoto}
            photoClickHandler={handlePhotoClick}
            column={column}
          />
          {editing && (
            <PhotoBottomMenu
              leftIcon="check square outline"
              leftLabel={editing ? t('PHT.DESELECT_ALL') : t('PHT.SELECT_ALL')}
              onLeftButton={handleItemClick}
              rightIcon="trash alternate outline"
              rightLabel={t('PHT.DELETE.LB')}
              rightdisabled={checkCount === 0}
              onRightButton={handleItemDeleteClick}
            />
          )}
          <SettingButton
            onClick={editButton}
            floated="right"
            icon={editing ? 'x' : 'cog'}
            inverted={editing}
            size="large"
          />
        </Visibility>
      )}
      {!loading && photoData.length === 0 && (
        <NoPhoto>
          <p>{t('PHT.NO_RESULT')}</p>
          <p>{t('PHT.MSG.TAKE_A_PIC')}</p>
        </NoPhoto>
      )}
      {popUpProperties.isPopup && <Confirm {...popUpProperties} />}
    </>
  );
};

export default withScrollManual(PhotoMain);
