import React from 'react';
import { Form, Message } from 'semantic-ui-react';
import { AccountForm, AccountInput, AccountWrap } from './AccountComponents';
import { StyledMessage } from '../Message';

const InputsForResetPW = props => {
  const { info, msg, pwInput, repwInput, onChange, onBlur } = props;

  return (
    <AccountForm>
      <AccountWrap>
        {info && <p style={{ fontSize: '0.75rem' }}>{info}</p>}
        <Form size="large" style={{ fontSize: '0.875rem ' }}>
          <div className="field">
            <div className="ui fluid input">
              <AccountInput
                {...{
                  ...pwInput,
                  onChange,
                  onBlur,
                }}
              />
            </div>
          </div>
        </Form>
        <Form size="large" style={{ fontSize: '0.875rem ' }}>
          <div className="field">
            <div className="ui fluid input">
              <AccountInput
                {...{
                  ...repwInput,
                  onChange,
                  onBlur,
                }}
              />
            </div>
          </div>
        </Form>
        {msg && (
          <StyledMessage
            color="red"
            hidden={!msg}
            textalign="left"
            style={{ marginBottom: '3rem' }}
          >
            <Message.Content>{msg}</Message.Content>
          </StyledMessage>
        )}
      </AccountWrap>
    </AccountForm>
  );
};

export default InputsForResetPW;
