/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { forbidExtraProps } from 'airbnb-prop-types';
import * as moment from 'moment';
import omit from 'lodash/omit';

import {
  DayPickerSingleDateController,
  isInclusivelyAfterDay,
} from 'react-dates';
import { useMount } from 'react-use/lib';

const propTypes = forbidExtraProps({
  // example props for the demo
  autoFocus: PropTypes.bool,
  initialDate: momentPropTypes.momentObj,
  showInput: PropTypes.bool,

  keepOpenOnDateSelect: PropTypes.bool,
  isOutsideRange: PropTypes.func,
  isDayBlocked: PropTypes.func,
  isDayHighlighted: PropTypes.func,

  // DayPicker props
  enableOutsideDays: PropTypes.bool,
  numberOfMonths: PropTypes.number,
  orientation: PropTypes.oneOf([
    'horizontal',
    'vertical',
    'verticalScrollable',
  ]),
  withPortal: PropTypes.bool,
  initialVisibleMonth: PropTypes.func,
  renderCalendarInfo: PropTypes.func,

  navPrev: PropTypes.node,
  navNext: PropTypes.node,

  onPrevMonthClick: PropTypes.func,
  onNextMonthClick: PropTypes.func,
  onOutsideClick: PropTypes.func,
  renderCalendarDay: PropTypes.func,
  renderDayContents: PropTypes.func,
  onDateChange: PropTypes.func,

  monthFormat: PropTypes.string,
  customFormat: PropTypes.string,

  isRTL: PropTypes.bool,
});

const defaultProps = {
  // example props for the demo
  autoFocus: false,
  initialDate: null,
  showInput: false,

  // day presentation and interaction related props
  renderCalendarDay: undefined,
  renderDayContents: null,
  isDayBlocked: () => false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  isDayHighlighted: () => false,
  enableOutsideDays: false,

  // calendar presentation and interaction related props
  orientation: 'horizontal',
  withPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  onOutsideClick() {},
  keepOpenOnDateSelect: false,
  renderCalendarInfo: null,
  isRTL: false,

  // navigation related props
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},

  // internationalization
  monthFormat: 'MMMM YYYY',
};

const DayPickerSingleDateControllerWrapper = pp => {
  const { initialDate: iDate, showInput, customFormat, onDateChange } = pp;
  const [width, setWidth] = useState(0);
  const [date, setDate] = useState(iDate);
  const [focused, setFocus] = useState(true);
  const [dateString, setDatStr] = useState('');
  const [{ daySize, verticalHeight }, setSize] = useState({
    daySize: 0,
    verticalHeight: 0,
  });

  const props = omit({ ...pp, showInput }, [
    'autoFocus',
    'initialDate',
    'showInput',
  ]);

  useMount(() => {
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (!date.isSame(iDate)) {
      setDate(iDate);
    }
  }, [iDate]);

  useEffect(() => {
    setDatStr(date && date.format(customFormat));
  }, [date]);

  useEffect(() => {
    const responsiveValue = Math.round((width / 320) * 100) * 0.01;
    let verticalHeightValue = 330;
    if (width > 460) {
      verticalHeightValue = Math.round(
        verticalHeightValue * (responsiveValue - 0.4 * (responsiveValue - 0.4)),
      );
    }
    setSize({
      daySize: Math.round(35 * responsiveValue),
      verticalHeight: verticalHeightValue,
    });
  }, [width]);

  const handleDateChange = dt => {
    onDateChange(dt);
    setDate(dt);
  };
  const onFocusChange = () => {
    setFocus(true);
  };

  return (
    <div>
      {showInput && (
        <div style={{ marginBottom: 16 }}>
          <input
            type="text"
            name="start date"
            value={dateString || ''}
            readOnly
          />
        </div>
      )}
      <DayPickerSingleDateController
        {...props}
        onDateChange={handleDateChange}
        onFocusChange={onFocusChange}
        focused={focused}
        date={date}
        daySize={daySize}
        orientation="vertical"
        verticalHeight={verticalHeight}
        hideKeyboardShortcutsPanel
      />
    </div>
  );
};

DayPickerSingleDateControllerWrapper.propTypes = propTypes;
DayPickerSingleDateControllerWrapper.defaultProps = defaultProps;

export default DayPickerSingleDateControllerWrapper;
