import React, { useState } from 'react';
import { Header, Popup, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledCard = styled.div`
  display: flex;
  flex-flow: ${({ flow }) => flow || 'row'};
  background: #fff;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s linear;
`;

const Card = props => {
  const [open, setOpen] = useState(false);
  const { popupContent, title, flow, children } = props;
  const { header, content } = popupContent;
  return (
    <div style={{ marginTop: '1rem' }}>
      <Header size="small" style={{ marginBottom: '.625rem' }}>
        {title}
        <Popup
          trigger={(
            <Icon
              name="circle info"
              style={{
                color: '#d1d3d4',
                marginRight: '0 !important',
                float: 'right',
                fontSize: '1.2rem',
              }}
            />
          )}
          content={(
            <div style={{ fontSize: '0.875rem' }}>
              <Header as="h4">{header}</Header>
              <p>{content}</p>
            </div>
          )}
          on="click"
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          position="bottom right"
        />
        {/* <Popup
          trigger={<StyledInfoIcon name="circle info" />}
          content={(
            <div style={{ fontSize: '0.875rem' }}>
              <Header as="h4">{header}</Header>
              <p>{content}</p>
            </div>
)}
          position="bottom right"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          on="click"
        /> */}
      </Header>
      <StyledCard flow={flow}>{children}</StyledCard>
    </div>
  );
};

export default Card;
