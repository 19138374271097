/* eslint-disable consistent-return */
import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useUpdateEffect, useInterval, useLifecycles } from 'react-use';
import { Form, Radio } from 'semantic-ui-react';
import CertificateForm from '../../components/account/CertificateForm';
import { setTimeFormat } from '../../utils/common';
import { MAIL_REGEXP } from '../../utils/validate';

const getCertType = obj => {
  const {
    // sms: { value: tel, confirm: telConfirm },
    email: { value: email, confirm: emailConfirm },
  } = obj;
  if (email && emailConfirm) {
    return 'email';
  }
  return 'tel';
};

const getAdditionalValue = (o, t) => {
  const type = t === 'tel' ? 'email' : 'tel';
  return o[type].value;
};

const CertificationContainer = props => {
  const { t } = useTranslation('user');
  const {
    certification,
    loading,
    error,
    handleChange,
    confirmCode,
    sendCode,
  } = props;
  const propType = getCertType(certification);
  const [type, setType] = useState(propType);
  const { value: v, confirm, sent, expireTime, firstTime } = certification[
    type
  ];
  const [value, setValue] = useState(v);
  const [msg, setMessage] = useState('');
  const [certCode, setCertCode] = useState('');
  const [additionalValue, setAddValue] = useState(
    getAdditionalValue(certification, propType),
  );
  const [timer, setTimer] = useState({
    cur: 0,
    limit: 0,
    formatted: '',
    complete: false,
  });
  const [isTicking, setTicking] = useState(false);
  const [curTime, setCurTime] = useState(0);
  const [unvalid, setUnvalid] = useState(false);
  const dispatch = useDispatch();

  const initialize = () => {
    setValue('');
    setMessage('');
    setCertCode('');
    setAddValue('');
    setTicking(false);
    if (timer.timeId !== null) {
      clearInterval(timer.timeId);
      setTimer({
        cur: 0,
        limit: 0,
        timeId: null,
        formatted: '',
        complete: false,
      });
    }
  };

  const handleRadioChange = radio => () => {
    console.log('call initialize');
    initialize();
    setType(radio);
  };

  const getUnvalidMsg = (name, val) => {
    const telRegex = /^0([1|7])([0|1|6|7|8|9|0]?)?([0-9]{7,8})$/g;
    const codeRegex = /^[0-9]/g;
    if (name === 'tel' && !telRegex.test(val)) {
      return t('TEL.UNVALID');
    }
    if (
      (name === 'email' && val.indexOf('@daum') > -1) ||
      val.indexOf('@hanmail') > -1
    ) {
      return t('EMAIL.DAUM.UNVALID');
    }
    if (name === 'email' && !new RegExp(MAIL_REGEXP).test(val)) {
      return t('MAIL.UNVALID');
    }
    if (name === 'certCode' && !codeRegex.test(val)) {
      return t('VERIFY.MSG.INPUT');
    }
    return '';
  };

  const onChange = (e, resend = false) => {
    const { name, value: changeVal } = e.target;
    const val = changeVal.replace(/\s/g, '');
    const message = getUnvalidMsg(name, val);
    setMessage(message);
    if (
      (type === 'tel' && name === 'email') ||
      (type === 'email' && name === 'tel')
    ) {
      return setAddValue(val);
    }

    if (resend) {
      const addProp = type === 'tel' ? 'email' : 'tel';
      handleChange({
        [type]: { value, confirm, sent: false },
        [addProp]: { value: additionalValue },
      });
    }

    if (name === 'certCode') {
      setCertCode(val.replace(/[^0-9]g/, ''));
    } else {
      setValue(val);
    }
  };

  const handleRequestSend = () => {
    dispatch(sendCode({ type, value }));
  };

  const handleRequestCert = () => {
    // sms/email 코드 인증 dispatch
    // setValue({ value, confirm: true });
    dispatch(confirmCode({ type, code: certCode }));
  };

  const getTimerInfo = (origin, ready) => {
    const { cur: c, limit: l, time: oldT } = origin;
    let cur = c;
    let limit = l;
    let time = oldT;
    let formatted = '';
    if (cur >= limit) {
      // 끝
      cur = 0;
      limit = 0;
      time = 0;
      formatted = '';
      setTicking(false);
    } else {
      if (!ready) {
        cur += 1000;
        time = moment.duration(time - 1000, 'milliseconds');
      }
      const mm = setTimeFormat(time.get('minutes'));
      const ss = setTimeFormat(time.get('seconds'));
      formatted = `${mm}:${ss}`;
    }
    return { cur, limit, time, formatted };
  };

  useUpdateEffect(() => {
    const addProp = type === 'tel' ? 'email' : 'tel';
    handleChange({
      [type]: { value, confirm, sent },
      [addProp]: { value: additionalValue },
    });
  }, [value, type, additionalValue]);

  useUpdateEffect(() => {
    // const { sent: s, confirm: c, time: oldTime} = certification[type];
    // setValue({ value, confirm: c, sent: s });
    let bUnvalid = false;
    if (typeof error === 'object' && 'code' in error && 'message' in error) {
      setMessage(error.message || '');
      bUnvalid = error.code === 'unvalid';
    } else if (typeof error === 'string') {
      setMessage(error);
    } else {
      setMessage('');
    }
    setUnvalid(bUnvalid);

    if (!bUnvalid && sent && !confirm) {
      setCertCode('');
      if (!certCode) {
        const timeLimit = expireTime - firstTime;
        const time = moment.duration(timeLimit, 'milliseconds');
        setTimer(getTimerInfo({ cur: 0, limit: timeLimit, time }, true));
        setTicking(true);
      }
    }
    if ((sent && confirm) || !sent) {
      setTicking(false);
    }
  }, [certification, error]);

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setCurTime(new Date().valueOf());
      setTicking(false);
    }
  };

  useInterval(
    () => {
      if (curTime === 0) {
        setTimer(getTimerInfo(timer));
      } else {
        setCurTime(0);
      }
    },
    isTicking ? 1000 : null,
  );

  useUpdateEffect(() => {
    if (curTime !== 0 && curTime < expireTime) {
      const diff = Math.round((curTime - firstTime) / 1000);
      const limit = Math.floor((expireTime - curTime) / 1000);
      const seconds = diff * 1000;
      const cur = timer.cur + seconds;
      const time = moment.duration(limit * 1000, 'milliseconds');
      setTimer(getTimerInfo({ ...timer, time, cur }));
      setTicking(true);
    }
  }, [curTime]);

  useLifecycles(
    () => {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    },
    () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (!(certification.email.sent && certification.email.confirm)) {
        initialize();
      }
    },
  );

  return (
    <div style={{ display: 'flex' }}>
      <Form.Group style={{ width: '100%', padding: '0 1rem' }}>
        <Form.Field
          style={{ padding: '0.75rem 0' }}
          control={Radio}
          label={t('VERIFY.HEADER.BY_SMS')}
          checked={type === 'tel'}
          onChange={handleRadioChange('tel')}
          disabled={type !== 'tel' && confirm}
        />
        {type === 'tel' && (
          <CertificateForm
            {...{
              loading,
              type,
              hadSent: sent,
              unvalid,
              msg,
              value,
              confirm,
              certCode,
              additionalValue,
              onChange,
              handleRequestSend,
              handleRequestCert,
              timer: { time: timer.formatted, complete: !isTicking },
            }}
          />
        )}
        <Form.Field
          style={{ padding: '0.75rem 0' }}
          control={Radio}
          label={t('VERIFY.HEADER.BY_MAIL')}
          checked={type === 'email'}
          onChange={handleRadioChange('email')}
          disabled={type !== 'email' && confirm}
        />
        {type === 'email' && (
          <CertificateForm
            {...{
              loading,
              type,
              hadSent: sent,
              unvalid,
              msg,
              value,
              confirm,
              certCode,
              additionalValue,
              onChange,
              handleRequestSend,
              handleRequestCert,
              timer: { time: timer.formatted, complete: !isTicking },
            }}
          />
        )}
      </Form.Group>
    </div>
  );
};

export default CertificationContainer;
