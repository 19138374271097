import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import { useNavigate } from 'react-router-dom';
import { PiboLinePanelMenu } from '../pibo/PiboComponents';

const AppInfoList = props => {
  const { t } = useTranslation('app');
  const { curVer, firmVer } = props;
  const navigate = useNavigate();
  const handleClickMenu = name => {
    if (name.toLowerCase().indexOf('version') >= 0) return;
    navigate(`info/${name}`);
  };
  const menuArr = [
    {
      menu: 'APP_VER',
      name: 'version',
      icon: false,
      sub: curVer,
    },
    {
      menu: 'FIRM_VER',
      name: 'firmVersion',
      icon: false,
      sub: firmVer,
    },
    {
      menu: 'LIABILITY_LIMIT',
      name: 'infosrc',
      icon: true,
      sub: '',
    },
    {
      menu: 'OPENSOURCE_LICENSE',
      name: 'license',
      icon: true,
      sub: '',
    },
    {
      menu: 'TERMS_SERVICE',
      name: 'service',
      icon: true,
      sub: '',
    },
    /* {
      menu: 'TERMS_LOCATION',
      name: 'location',
      icon: true,
      sub: '',
    }, */
    {
      menu: 'TERMS_PRIVACY',
      name: 'privacy',
      icon: true,
      sub: '',
    },
  ];
  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          {menuArr.map(({ name, menu, icon, sub }) => (
            <PiboLinePanelMenu
              key={shortid.generate()}
              name={name}
              menu={t(menu)}
              icon={icon}
              sub={sub}
              onClick={() => handleClickMenu(name)}
            />
          ))}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default AppInfoList;
