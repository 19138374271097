/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { onSaveExecution } from '../../../ducks/bots/trainBot/train';
import {
  CardBody,
  InvalidText,
  BorderedSegment,
  ToggleButtonItem,
  ToggleTextItem,
} from './Components';
import ExecCard from './ExecCard';
import EyeColorCard from './EyeColorCard';
import FxCard from './FxCard';

const MotionExecCard = props => {
  const dispatch = useDispatch();
  const {
    execution: { color, icon, label: title },
    data: { motions, colors, fx },
  } = useSelector(state => state.train);
  const { t } = useTranslation();
  const { onCancel } = props;
  const [select, setSelect] = useState({ catIndex: -1, itemIndex: -1 });
  const [value, setValue] = useState({ _eye: null, _fx: null, motion: null });
  const [error, setError] = useState(null);

  const handleCategoryClick = i => {
    const { catIndex } = select;
    if (i === catIndex) {
      setSelect({ catIndex: -1, itemIndex: -1 });
    } else {
      setSelect({ catIndex: i, itemIndex: -1 });
    }
  };

  const handleItemClick = i => {
    const { itemIndex } = select;
    if (i === itemIndex) {
      setSelect({ ...select, itemIndex: -1 });
    } else {
      setSelect({ ...select, itemIndex: i });
    }
  };

  useUpdateEffect(() => {
    const { catIndex, itemIndex } = select;
    if (catIndex > -1 && itemIndex > -1) {
      setValue({ ...value, motion: motions[catIndex].list[itemIndex] });
    } else {
      setValue({ ...value, motion: null });
    }
  }, [select]);

  const handlefxChange = name => {
    setValue({ ...value, _fx: name });
  };

  const handleEyeColorChange = name => {
    setValue({ ...value, _eye: name });
  };

  const handleSave = () => {
    if ('motion' in value && value.motion && !error) {
      dispatch(onSaveExecution(value));
    }
  };
  useUpdateEffect(() => {
    const { motion } = value;
    if (!motion) {
      setError(t('bot:TRN.MSG.SELECT_ACTION'));
    } else {
      setError('');
    }
  }, [value]);
  return (
    <ExecCard
      {...{
        t,
        color,
        icon,
        title,
        options: {
          onCancel,
          onSave: handleSave,
          saveDisabled: error === null || !!error,
        },
      }}
    >
      <CardBody>
        <p>{t('bot:TRN.TITLE.SELECT_ACTION')}</p>
        <BorderedSegment radius={10} style={{ width: '100%' }}>
          {motions.map(({ category, key }, i) => (
            <ToggleButtonItem
              color={color}
              key={`${key}_${i}`}
              onClick={() => handleCategoryClick(i)}
              selected={select.catIndex === i}
            >
              <span>{t(category)}</span>
            </ToggleButtonItem>
          ))}
        </BorderedSegment>
        {select.catIndex > -1 && (
          <BorderedSegment
            radius={10}
            style={{ width: '100%', marginTop: '1rem', paddingBottom: 0 }}
          >
            {motions[select.catIndex].list.map(({ key, label }, i) => (
              <ToggleTextItem
                key={key}
                onClick={() => handleItemClick(i)}
                selected={select.itemIndex === i}
              >
                {t(label)}
              </ToggleTextItem>
            ))}
          </BorderedSegment>
        )}
        {error && <InvalidText>{error}</InvalidText>}
      </CardBody>
      {(value.motion || value._eye) && (
        <EyeColorCard colors={colors} onChange={handleEyeColorChange} />
      )}
      {(value.motion || value._fx) && (
        <FxCard t={t} fx={fx} onChange={handlefxChange} />
      )}
    </ExecCard>
  );
};

export default MotionExecCard;
