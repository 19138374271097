import {
  sendUserOpinion,
  getCSDataByCategory,
  getCSDataSearchResult,
} from '../utils/api';
import i18 from '../lang/i18n';

export const LOADING = 'app/LOADING';
export const SEARCHING = 'app/SEARCHING';
export const SUCCESS = 'app/SUCCESS';
export const ERROR = 'app/ERROR';
export const FETCH_SUCCESS = 'app/FETCH_SUCCESS';
export const FETCH_SEARCH_RESULTS = 'app/FETCH_SEARCH_RESULTS';
export const FAILED_SEARCH_RESULTS = 'app/FAILED_SEARCH_RESULTS';

export function loading() {
  return {
    type: LOADING,
  };
}

export function searching() {
  return {
    type: SEARCHING,
  };
}

export function success() {
  return {
    type: SUCCESS,
  };
}

export function error(errorMsg) {
  return {
    type: ERROR,
    errorMsg,
  };
}

export function fetchSuccess(list) {
  return {
    type: FETCH_SUCCESS,
    list,
  };
}

export function fetchSearchSuccess(results) {
  return {
    type: FETCH_SEARCH_RESULTS,
    results,
  };
}

export function fetchSearchFailed(errorMsg) {
  return {
    type: FAILED_SEARCH_RESULTS,
    errorMsg,
  };
}

const initialState = {
  loading: false,
  errorMsg: '',
  result: false,
  list: [],
  results: [],
};

export default function appReducer (state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCHING:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS:
      return {
        ...state,
        result: true,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.list,
      };
    case FETCH_SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        results: action.results,
      };
    case FAILED_SEARCH_RESULTS:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
        results: [],
      };
    case ERROR:
      return {
        ...state,
        loading: false,
        errorMsg: action.errorMsg,
      };
    default:
      return state;
  }
}

export const sendOpinion = data => async dispatch => {
  dispatch(loading());
  const res = await sendUserOpinion(data);
  if (res.result) {
    return dispatch(success());
  }

  const {
    error: { code },
  } = res;
  const errorMsg = i18.t([
    `common:ERR.${code.toUpperCase()}`,
    'app:ERR.FAILED_SEND_FEEDBACK',
  ]);
  return dispatch(error(errorMsg));
};

export const fecthCSList = () => async dispatch => {
  dispatch(loading());
  const res = await getCSDataByCategory();
  if (res.result) {
    return dispatch(fetchSuccess(res.data));
  }
  return dispatch(error(i18.t('app:ERR.FAILED_LOAD_LIST')));
};

export const fetchCSAutoCompleteData = value => async dispatch => {
  dispatch(searching());
  const res = await getCSDataSearchResult(value);
  if (res.result) {
    const { data } = res;
    if (res.length > 0) {
      return dispatch(fetchSearchSuccess(data));
    }
    return dispatch(fetchSearchFailed(i18.t('app:MSG.NO_RESULT', { value })));
  }
  const {
    error: { code },
  } = res;
  const errorMsg = i18.t([
    `common:ERR.${code.toUpperCase()}`,
    'common:ERR.UNSPECIFIC',
  ]);
  return dispatch(error(errorMsg));
};
