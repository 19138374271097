import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { Button, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountButton } from '../../components/account/AccountComponents';
import {
  prevStep,
  nextStep,
  sendMail,
  setValue,
  initializing,
  resendMail,
} from '../../ducks/findAccounts';
import FindAccountsSelect from '../../components/account/FindAccountsSelect';
import FindIdByNameContainer from './FindIdByNameContainer';
import FindIdBySerialContainer from './FindIdBySerialContainer';
import ReSetPWContainer from './ReSetPWContainer';

const PageWrap = styled.div`
  background: #fff;
  width: 100vw;
  // max-height: max-contnet;
  // min-height: ${window.innerHeight}px;
  height: 100%;
  position: absolute;
  overflow-y: auto;
`;

const JoinWrap = styled.div`
  margin: 5vw 5vw 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`;

const FindAccountsPage = props => {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const { name } = useParams();
  const {
    step,
    select,
    userinfo,
    complete,
    error,
    onNextStep,
    onPrevStep,
    onSend,
    onResend,
    onSetValue,
    onInit,
    hadSent,
    pending,
    value,
    nextEnable,
  } = props;
  const [current, setCurrent] = useState(name);
  const handleChange = (e, { value: v }) => {
    onInit();
    setCurrent(v);
    onSetValue(v);
  };
  const onCancel = () => {
    if (step === 0) {
      onPrevStep();
      return navigate('/findAccounts');
    }
    if (step === -1) {
      return navigate('/login');
    }

    return onPrevStep();
  };

  const onNext = () => {
    if (!name) {
      onNextStep();
      return navigate(`/account/${value}`);
    }

    return onNextStep();
  };

  useUpdateEffect(() => {
    if (name && current !== name) {
      handleChange(null, { value: name });
      onNext();
    }
  }, [name]);

  return (
    <PageWrap>
      <JoinWrap>
        <Image
          src="image/logo_circulus.png"
          style={{ width: '70vw', margin: '2rem 0 3rem' }}
          centered
        />
        {(name === 'email' || (name === undefined && select === 'email')) && (
          <FindIdByNameContainer
            {...{ pending, step, select, userinfo, hadSent, error, t }}
            handleChange={v => onSetValue(v)}
            handleResend={onResend}
            handleSend={onSend}
          />
        )}
        {(name === 'serial' || (name === undefined && select === 'serial')) && (
          <FindIdBySerialContainer
            {...{ pending, step, select, userinfo, hadSent, error, t }}
            handleChange={v => onSetValue(v)}
            handleResend={onResend}
            handleSend={onSend}
          />
        )}
        {((name === 'pw' && (select === null || select === 'pw')) ||
          (name === undefined && select === 'pw')) && (
          <ReSetPWContainer
            {...{ pending, step, select, userinfo, hadSent, error, t }}
            handleChange={v => onSetValue(v)}
            handleResend={onResend}
            handleSend={onSend}
          />
        )}
        {!(name || select) && (
          <FindAccountsSelect {...{ handleChange, value, t }} />
        )}
        {!complete && (
          <AccountButton widths="2" fluid attached="bottom">
            <Button
              color="blue"
              name="ok"
              disabled={!nextEnable}
              onClick={onNext}
            >
              {t('NEXT')}
            </Button>
            <Button
              name="cancel"
              onClick={onCancel}
              disabled={hadSent}
              style={{ color: '#fff !important' }}
            >
              {t('PREV')}
            </Button>
          </AccountButton>
        )}
        {complete && (
          <AccountButton widths="1" fluid attached="bottom">
            <Button
              color="blue"
              name="complete"
              style={{ borderRadius: 0 }}
              onClick={() => navigate('/login')}
            >
              {t('FIND.GO.LOGIN')}
            </Button>
          </AccountButton>
        )}
      </JoinWrap>
    </PageWrap>
  );
};

export default connect(
  state => ({
    step: state.findAccounts.step,
    page: state.findAccounts.page,
    error: state.findAccounts.error,
    select: state.findAccounts.select,
    hadSent: state.findAccounts.hadSent,
    pending: state.findAccounts.pending,
    complete: state.findAccounts.complete,
    userinfo: state.findAccounts.userinfo,
    value: state.findAccounts.data,
    nextEnable: state.findAccounts.nextEnable,
  }),
  dispatch => ({
    onNextStep: () => {
      dispatch(nextStep());
    },
    onPrevStep: () => {
      dispatch(prevStep());
    },
    onSend: () => {
      dispatch(sendMail());
    },
    onResend: () => {
      dispatch(resendMail());
    },
    onInit: () => {
      dispatch(initializing());
    },
    onSetValue: value => {
      dispatch(setValue(value));
    },
  }),
)(FindAccountsPage);
