import React from 'react';
import { Dimmer, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledMessage } from '../../Message';
import { StyledButtonGroup, StyledButton } from '../PiboComponents';

const StyledPopUp = styled(Segment)`
  &&& {
    border-radius: 0 !important;
    position: relative !important;
    padding: 1rem 0 4rem !important;
    @media (max-width: 500px) {
      width: 85vw;
    }
    @media (min-width: 501px) and (max-width: 1200px) {
      width: 70vw;
    }
    @media (min-width: 1201px) and (max-width: 2000px) {
      width: 40vw;
    }
    @media (min-width: 2001px) {
      width: 20vw;
    }
  }
`;

const StyledContent = styled.div`
  text-align: 'center';
  font-size: 1rem;
  padding: 1rem 1rem;
  line-height: 1.2rem;
  &&& > canvas {
    width: 100% !important;
    height: 100% !important;
  }
`;

const WiFiQRConnectPopup = ({ rightLabel, rightFunc, qrcode, t, isPopup }) => (
  <Dimmer active={isPopup} page>
    <StyledPopUp>
      <StyledMessage>{t('conn:MSG.SHOW_QR')}</StyledMessage>
      <StyledContent>{qrcode}</StyledContent>
      <StyledButtonGroup>
        <StyledButton color="blue" onClick={rightFunc}>
          {rightLabel}
        </StyledButton>
      </StyledButtonGroup>
    </StyledPopUp>
  </Dimmer>
);

export default WiFiQRConnectPopup;
