import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Header, Image } from 'semantic-ui-react';
import {
  WeatherDust,
  WeatherLocation,
  WEATHER_COLOR,
} from './WeatherComponents';
import { SITE_URL } from '../../../circulusConfig';

const WeatherImageInfoWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1rem;
  & > img.ui.image {
    min-width: 90px;
    min-height: 90px;
    max-width: 140px;
    padding: 0.5rem;
  }
`;

const WeatherInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.5rem;
  min-width: 50%;
`;

const WeatherContent = styled.div`
  font-weight: bold;
  padding-left: 0.5rem;
  & > .header {
    margin: 0;
    color: inherit;
    font-family: Nanum Gothic;
  }
  & h6.header {
    font-size: 1rem;
  }
  & h1.header {
    font-size: 3rem;
    line-height: 1.1;
  }
`;

const WeatherTodayCard = data => {
  const { setColor, locationKeyword: location, sky, temp, pm25, pm10 } = data;
  const { background, color, label } = WEATHER_COLOR[sky];

  useEffect(() => {
    setColor({ background, color });
  }, [sky]);

  return (
    <div>
      <WeatherLocation>
        <Header as="h5">{location}</Header>
      </WeatherLocation>
      <WeatherImageInfoWrap>
        <Image src={`${SITE_URL}image/bots/weather/${sky}.png`} alt={sky} />
        <WeatherInfoWrap>
          <WeatherContent>
            <Header as="h6">{label}</Header>
            <Header as="h1">{`${temp}℃`}</Header>
          </WeatherContent>
          <WeatherDust direction="column">
            <div>
              <span className="subject">초미세먼지</span>
              <span className="bold">{pm25 ? pm25[0] : '정보 없음'}</span>
              {pm25 && <span>{`${pm25[1]}µg`}</span>}
            </div>
            <div>
              <span className="subject">미세먼지</span>
              <span className="bold">{pm10 ? pm10[0] : '정보 없음'}</span>
              {pm10 && <span>{`${pm10[1]}µg`}</span>}
            </div>
          </WeatherDust>
        </WeatherInfoWrap>
      </WeatherImageInfoWrap>
    </div>
  );
};

export default WeatherTodayCard;
