import React from 'react';
import { Form, Icon, Message } from 'semantic-ui-react';
import { useUnmount, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { Element, scroller } from 'react-scroll';
import { OS_IOS, getMobileOS, trimAll } from '../../utils/common';
import { StyledMessage } from '../../components/Message';
import { InputInlineGroup } from '../../components/account/Components';

let delay;

const AccountContainer = props => {
  const { t } = useTranslation('user');
  const { account, handleChange } = props;
  const { userId, pw, rePw, confirm, error } = account;

  const onChange = e => {
    const changeTarget = e.target.name;
    let changeValue = trimAll(e.target.value);
    if (changeTarget === 'userId') {
      changeValue = changeValue.toLowerCase();
    }
    handleChange({ [changeTarget]: changeValue });
  };

  const onBlur = () => {
    handleChange({ userId, pw, rePw });
  };

  useUpdateEffect(() => {
    if (delay) {
      clearTimeout(delay);
    }
    delay = setTimeout(() => handleChange({ confirm }), 500);
  }, [userId]);

  useUnmount(() => delay && clearTimeout(delay));

  useUpdateEffect(() => {
    if (error && getMobileOS() !== OS_IOS) {
      scroller.scrollTo('msgElement', {
        duration: 0,
        delay: 0,
        smooth: true,
      });
    }
  }, [error]);

  return (
    <InputInlineGroup>
      <Element name="msgElement">
        <Form.Group style={{ width: '100%' }}>
          <Form.Input
            placeholder={t('ID.LB')}
            name="userId"
            type="text"
            value={userId}
            onChange={onChange}
            autoComplete="false"
          />
          {confirm && <Icon color="green" name="check circle" size="large" />}
        </Form.Group>
        <Form.Group style={{ width: '100%' }}>
          <div className="field">
            <div className="ui input">
              <input
                placeholder={t('PW.LB')}
                name="pw"
                type="password"
                autoComplete="new-password"
                value={pw}
                onChange={onChange}
                onBlur={onBlur}
              />
            </div>
          </div>
        </Form.Group>
        <Form.Group style={{ width: '100%' }}>
          <Form.Input
            placeholder={t('PW.CONFIRM')}
            name="rePw"
            type="password"
            value={rePw}
            onChange={onChange}
            onBlur={onBlur}
            autoComplete="new-password"
          />
        </Form.Group>

        <StyledMessage
          color="red"
          hidden={!error}
          textalign="left"
          className="mini"
        >
          <Message.Content>{error}</Message.Content>
        </StyledMessage>
      </Element>
    </InputInlineGroup>
  );
};

export default AccountContainer;
