/* eslint-disable no-continue */
import React, { useState, Fragment } from 'react';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { useUpdateEffect, useLifecycles } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-dom-last-location';
import { useTranslation } from 'react-i18next';

import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import { getBotData } from '../../../ducks/bots/defaultPage/defaultPage';
import Loading from '../../../components/Loading';
import { connectSocket as PIBO_API } from '../../../pibo/socket';

const CustomBot = props => {
  const { t } = useTranslation('bot');
  const { botId } = props;
  const { lastLocation } = useLastLocation();
  const dispatch = useDispatch();
  const { botData, error, pending } = useSelector(state => state.defaultPage);
  const { title, projectId } = botData;
  const [backLocation, setBack] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [filesource, setSource] = useState([]);
  const [html, setHTML] = useState('');

  // eslint-disable-next-line no-underscore-dangle
  let _isMounted = true;
  useLifecycles(
    () => {
      if (!_isMounted) return;
      if (lastLocation && 'pathname' in lastLocation) {
        setBack(lastLocation.pathname);
      }
      if (!error) {
        dispatch(getBotData(botId));
      }
    },
    () => {
      _isMounted = false;
      // botData.url = null;
    },
  );

  useUpdateEffect(() => {
    const { files } = botData;
    const index = files.find(({ fileName }) => fileName === 'index.html');
    if (index) {
      let src = index.content;
      const srcFiles = [];
      for (const { language, fileName, content } of files) {
        if (fileName === 'index.html') continue;
        if (language === 'stylesheet') {
          srcFiles.push({ element: 'style', content });
        }
        if (language === 'text/javascript') {
          srcFiles.push({ element: 'script', type: language, content });
        }
        const fileIdx = src.indexOf(fileName);
        let startIdx = fileIdx - 1;
        while (src.substr(startIdx, 1) !== '<') {
          startIdx -= 1;
        }

        let tag = src
          .substr(startIdx + 1, src.substr(startIdx).indexOf(' '))
          .trim();

        // eslint-disable-next-line no-useless-escape
        tag = `<\/${tag}>`;

        let endIdx = fileIdx + 1;
        while (
          !(
            src.substr(endIdx, tag.length) === tag ||
            src.substr(endIdx, 2) === '/>'
          )
        ) {
          endIdx += 1;
        }
        if (src.substr(endIdx, 2) === '/>') {
          endIdx += 2;
        }
        if (src.substr(endIdx, tag.length) === tag) {
          endIdx += tag.length;
        }

        const [bef, aft] = src.split(src.substring(startIdx, endIdx));
        src = bef;
        src += aft;
      }
      setSource(srcFiles);
      setHTML(src);
    }
  }, [botData]);

  if (pending) return <Loading />;
  if (error && error.length > 0) return <h4>{error}</h4>;
  if (!projectId) return <h4>{t('MSG.UNVALID')}</h4>;
  return (
    <>
      <BotTopMenu
        title={title}
        menuMode="WITH_CLOSE"
        backLocation={backLocation}
      />
      {html && (
        <div className="externalWrap">
          <Frame className="externalIFrame">
            <FrameContextConsumer>
              {// Callback is invoked with iframe's window and document instances
              ({ document, window }) => {
                // Render Children
                // eslint-disable-next-line no-param-reassign
                window.pibo = PIBO_API;
                // const parser = new DOMParser();
                // const source = parser.parseFromString(filesource, 'text/html');
                document.write(html);
                filesource.forEach(({ element, type, content }) => {
                  const el = document.createElement(element);
                  if (type) {
                    el.setAttribute('type', type);
                    el.append(content);
                    document.body.appendChild(el);
                  } else {
                    el.append(content);
                    document.body.prepend(el);
                  }
                });
              }}
            </FrameContextConsumer>
          </Frame>
        </div>
      )}
    </>
  );
};

export default CustomBot;
