/* eslint-disable */
// import { setReport } from './api';
// import { publishDate } from '../circulusConfig';
// import pjson from '../../package.json';

export const errorLog = detail => {
  // const domain = process.env.REACT_APP_ENV;
  // const message = {
  //   domain: domain.toLowerCase(),
  //   client: 'm',
  //   level: 'E',
  //   module: 'RESPONSE',
  //   detail,
  // };
  // const data = {
  //   topic: 'circulus/m03',
  //   message,
  // };
  // console.log(data);
  // setLog(data);
  console.error(detail);
};

export const infoLog = detail => {
  // const domain = process.env.REACT_APP_ENV;
  // const message = {
  //   domain: domain.toLowerCase(),
  //   client: 'm',
  //   level: 'I',
  //   module: 'RESPONSE',
  //   detail,
  // };
  // const data = {
  //   topic: 'circulus/m03',
  //   message,
  // };
  // setLog(data);
  console.log(detail);
};

export const reporter = detail => {
  // const data = {
  //   reporter: 'M',
  //   ...detail,
  // };
  // data.data.appVer = `${pjson.version}-${publishDate}`;
  // setReport(data);
  console.info(detail);
};
