import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import { useMount, useUpdateEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import UserInfoSelect from '../../components/user/UserInfoSelect';
import UserInfoInterest from '../../components/user/UserInfoInterest';
import UserInfoInput from '../../components/user/UserInfoInput';
import UserInfoImage from '../../components/user/UserInfoImage';
import {
  confirmCode,
  sendCode,
  fetchUserInfoData,
  userInfoValidate,
  initialSent,
  updateUserItem,
} from '../../ducks/userinfo';
import { getUserId, Alert, detectOS, OS_IOS } from '../../utils/common';
import { GENDER_OPTIONS } from '../../utils/consts';
import withLoading from '../../hocs/withLoading';

let isMounted = false;

const UserInfoContainer = ({ userinfo }) => {
  const { t } = useTranslation('user');
  const dispatch = useDispatch();
  const { interests, user: u, error: e, loading } = userinfo;
  const [error, setError] = useState(e);
  const [user, setUser] = useState(u);
  const [updateVerify, setUpdate] = useState(null);
  const {
    _id: uId,
    interest,
    birthDate,
    email,
    tel,
    firstName,
    gender,
    lastName,
    nickName,
    image,
    imageFile,
    interestList: il,
    src,
    verified,
    sent,
  } = user;

  useMount(() => {
    if (!isMounted) {
      dispatch(fetchUserInfoData(getUserId()));
      isMounted = true;
    }
  });
  useUpdateEffect(() => {
    setError(e);
  }, [e]);

  useUpdateEffect(() => {
    if (!uId) {
      setUser({ ...u });
    } else if (u.interest !== interest) {
      setUser({ ...user, interest: u.interest, interestList: u.interestList });
    } else if (image !== u.image) {
      setUser({
        ...user,
        image: u.image,
        imageFile: u.imageFile,
        data: u.data,
      });
    } else if (sent.email !== u.sent.email || sent.tel !== u.sent.tel) {
      setUser({ ...user, sent: u.sent });
    } else if (
      verified.email !== u.verified.email ||
      verified.tel !== u.verified.tel
    ) {
      const updateData = { item: '', value: '' };
      if (verified.email !== u.verified.email) {
        updateData.item = 'email';
        updateData.value = u.email;
      }
      if (verified.tel !== u.verified.tel) {
        updateData.item = 'tel';
        updateData.value = u.tel;
      }
      updateData.verified = u.verified;
      dispatch(updateUserItem({ userId: getUserId(), data: updateData }));
      setUser({ ...user, verified: u.verified });
    }
  }, [u]);

  const handleFileChange = async event => {
    const files = Array.from(event.target.files);
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        dispatch(userInfoValidate({ name: 'imageFile', value: files }));
      };
      reader.readAsDataURL(files[0]);
    } else if (!imageFile && !image) {
      dispatch(userInfoValidate({ name: 'imageFile', value: null }));
    }
  };

  const handleFileInputClick = () => {
    Alert(t('IMG.MSG.RULE'));
  };

  const handleInterestClick = (event, d) => {
    const { inverted, id } = d;
    let newInterest;
    if (inverted) {
      newInterest = interest.concat([id]);
    }
    if (!inverted) {
      newInterest = interest.filter(item => item !== id);
    }
    dispatch(userInfoValidate({ name: 'interest', value: newInterest }));
  };

  const handleBlur = ({ name, value }) => {
    dispatch(userInfoValidate({ name, value }));
  };

  const handleRequestSend = ({ type, value }) => {
    // hadSent 로 재요청 판단
    // sms/email 발송 dispatch
    dispatch(sendCode({ type, value }));
  };

  const handleRequestCert = ({ type, code }) => {
    // sms/email 코드 인증 dispatch
    // setValue({ value, confirm: true });
    dispatch(confirmCode({ type, code }));
  };

  const handleVerifyUpdate = ({ id, sent: s, verified: v }) => {
    if (!s) {
      dispatch(initialSent({ id, verified: v }));
    }
    setUpdate(s);
  };

  const infoArr =
    detectOS() === OS_IOS
      ? [
          {
            id: 'lastName',
            name: t('NAME.LAST'),
            value: lastName,
            type: 'text',
          },
          {
            id: 'firstName',
            name: t('NAME.FIRST'),
            value: firstName,
            type: 'text',
          },
          {
            id: 'nickName',
            name: t('NICK.LB'),
            value: nickName,
            type: 'text',
          },
          {
            id: 'tel',
            name: t('TEL.LB'),
            value: tel,
            type: 'tel',
            verified: verified && verified.tel,
            readOnly: verified && !!verified.tel,
            disabled: updateVerify === 'email',
            sent,
            handleRequestSend,
            handleRequestCert,
            handleVerifyUpdate,
          },
          {
            id: 'email',
            name: t('MAIL.LB'),
            value: email,
            type: 'email',
            verified: verified && verified.email,
            readOnly: verified && !!verified.email,
            disabled: updateVerify === 'tel',
            sent,
            handleRequestSend,
            handleRequestCert,
            handleVerifyUpdate,
          },
        ]
      : [
          {
            id: 'lastName',
            name: t('NAME.LAST'),
            value: lastName,
            type: 'text',
          },
          {
            id: 'firstName',
            name: t('NAME.FIRST'),
            value: firstName,
            type: 'text',
          },
          {
            id: 'nickName',
            name: t('NICK.LB'),
            value: nickName,
            type: 'text',
          },
          {
            id: 'tel',
            name: t('TEL.LB'),
            value: tel,
            type: 'tel',
            verified: verified && verified.tel,
            readOnly: verified && !!verified.tel,
            disabled: updateVerify === 'email',
            sent,
            handleRequestSend,
            handleRequestCert,
            handleVerifyUpdate,
          },
          {
            id: 'email',
            name: t('MAIL.LB'),
            value: email,
            type: 'email',
            verified: verified && verified.email,
            readOnly: verified && !!verified.email,
            disabled: updateVerify === 'tel',
            sent,
            handleRequestSend,
            handleRequestCert,
            handleVerifyUpdate,
          },
          {
            id: 'birthDate',
            name: t('BIRTH.LB'),
            value: birthDate,
            type: 'date',
          },
        ];

  const genderArr = GENDER_OPTIONS.map(({ id, value }) => ({
    id,
    key: id,
    value: id,
    text: t(value),
    selected: id === gender,
  }));

  const genderInfo = {
    id: 'gender',
    name: t('GENDER.LB'),
    value: gender,
    options: genderArr,
  };

  return (
    <>
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            {uId && (
              <Segment textAlign="center">
                <UserInfoImage
                  {...{ data: image, src, loading, error }}
                  onFileChange={handleFileChange}
                  onButtonClick={handleFileInputClick}
                />
                {infoArr.map(info => (
                  <UserInfoInput
                    {...{ ...info, loading, error }}
                    key={info.id}
                    onBlur={handleBlur}
                    onChange={handleBlur}
                  />
                ))}
                <UserInfoSelect
                  {...{ ...genderInfo, loading, error }}
                  onBlur={handleBlur}
                />
                <UserInfoInterest
                  {...{ loading, error }}
                  interestList={il && il.length > 0 ? il : interests}
                  onClick={handleInterestClick}
                  helper
                />
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default withLoading(UserInfoContainer);
