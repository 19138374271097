import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLifecycles } from 'react-use';
import { Icon } from 'semantic-ui-react';
import UserPhoto from './UserPhoto';
import { StyledMessage } from '../Message';

const StyledUserPhotoEditButton = styled.label`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(86, 91, 91, 0.5);
  color: #fff;
  border: none;
  display: inline-block;
  text-align: center;
  @media (max-width: 600px) {
    padding: 0.4rem 0;
    font-size: 0.85rem;
  }
  @media (min-width: 601px) {
    padding: 1rem 0;
    font-size: 1.25rem;
  }
  & > input[type='file'] {
    display: none;
  }
`;

const UserInfoImage = props => {
  const { t } = useTranslation('user');
  const {
    onFileChange,
    onButtonClick,
    data,
    src,
    loading,
    error: e,
    label,
  } = props;
  const inputRef = useRef();
  const [error, setError] = useState(null);
  useLifecycles(
    () => {
      inputRef.current.addEventListener('change', onFileChange);
      inputRef.current.addEventListener('mousedown', onButtonClick);
      inputRef.current.addEventListener('click', onButtonClick);
    },
    () => {
      // inputRef.current.removeEventListener('change', onFileChange);
      // inputRef.current.removeEventListener('mousedown', onButtonClick);
      // inputRef.current.removeEventListener('click', onButtonClick);
    },
  );

  useEffect(() => {
    inputRef.current.blur();
  }, [data]);

  useEffect(() => {
    if (typeof e === 'string') {
      return setError(e);
    }
    if (typeof e === 'object' && 'imageFile' in e) {
      return setError(e.imageFile);
    }
    return setError(null);
  }, [e]);

  return (
    <div>
      <UserPhoto src={src} data={data} error={error}>
        <StyledUserPhotoEditButton>
          <input type="file" accept="image/*" ref={inputRef} />
          {label || t('IMG.UPDATE')}
        </StyledUserPhotoEditButton>
      </UserPhoto>
      {loading ||
        (error && error.length > 0 && (
          <StyledMessage
            color="red"
            hidden={!error}
            textalign="left"
            style={{ margin: '0.5rem 0', fontSize: '0.85rem' }}
          >
            {error || <Icon name="spinner" loading />}
          </StyledMessage>
        ))}
    </div>
  );
};

export default UserInfoImage;
