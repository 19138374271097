import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import shortid from 'shortid';
import {
  MOTION_ITEM,
  SPEAK_ITEM,
  BOT_ITEM,
  BG_ITEM,
  MENUS,
  EYE_COLORS,
  MOTIONS,
} from './consts';
import {
  checkCommand,
  saveTrain,
  getTrain,
  updateTrain,
  getPiboDataList,
  getContentList,
  checkQuestion,
} from '../../../utils/api';
import {
  DELETE_FAIL,
  DELETE_SUCCESS,
  ADD_SUCCESS,
  UPDATE_SUCCESS,
  DATA_LOAD_FAIL,
  getPopup,
  DELETE_CONFIRM,
} from '../../../components/bots/TrainBot/Popup';
import i18n from '../../../lang/i18n';
import { killPibo, speakPibo } from '../../../pibo';
import { getRobotId } from '../../../utils/common';
import { errorLog } from '../../../utils/report';

const reducerName = 'train';

export const addItem = createAsyncThunk(
  `${reducerName}/ADD`,
  async ({ q, robotId }, { getState, rejectWithValue }) => {
    try {
      const { executes } = getState().train;
      const a = executes.map(({ card, ...rest }) => ({ ...rest }));
      const result = await saveTrain({ q, a, robotId });
      if (result) {
        return { popup: { type: ADD_SUCCESS } };
      }
      return rejectWithValue('save failed');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateItem = createAsyncThunk(
  `${reducerName}/UPDATE`,
  async ({ q }, { getState, rejectWithValue }) => {
    try {
      const { executes, checked } = getState().train;
      const a = executes.map(({ card, ...rest }) => ({ ...rest }));
      const result = await updateTrain({
        list: [{ id: checked[0], q, a }],
        bDelete: false,
      });
      if (result) {
        return { popup: { ...getPopup(UPDATE_SUCCESS) } };
      }
      return rejectWithValue('update failed');
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const deleteList = createAsyncThunk(
  `${reducerName}/DELETE`,
  async (list, { rejectWithValue }) => {
    try {
      const { result } = await updateTrain({ list, bDelete: true });
      if (result) {
        return { popup: { type: DELETE_SUCCESS } };
      }
      return rejectWithValue({ popup: { type: DELETE_FAIL } });
    } catch (e) {
      return rejectWithValue({ popup: { type: DELETE_FAIL } });
    }
  },
);

export const fetchTrains = createAsyncThunk(
  `${reducerName}/FETCH`,
  async ({ robotId, more: bMore }, { getState, rejectWithValue }) => {
    try {
      const limit = 10;
      const { list: old } = getState().train;
      let skip = 0;
      const more = bMore || false;
      if (more) {
        skip = parseInt(old.length / limit, 10) * limit;
      }
      const result = await getTrain({ skip, limit, robotId });
      if (result.result) {
        const { data, total } = result;
        const list = data.map(({ _id: id, q, a }) => {
          const bots = a.map(item => {
            const { motion, speak, bot, bg } = item;
            // list가 그릴 수 있는 형태로 변경할 것
            let feature;
            if (bot) {
              feature = BOT_ITEM;
            } else if (speak) {
              feature = SPEAK_ITEM;
            } else if (motion) {
              feature = MOTION_ITEM;
            } else if (bg) {
              feature = BG_ITEM;
            }
            return {
              ...item,
              color: feature.color,
              icon: feature.icon,
              key: shortid.generate(),
            };
          });
          return { id, title: q, bots };
        });
        return { list: more ? [...old].concat(list) : list, total };
      }
      return rejectWithValue(i18n.t('bot:TRN.MSG.FAILED_SELECT'));
    } catch (error) {
      return rejectWithValue(i18n.t('bot:TRN.MSG.FAILED_SELECT'));
    }
  },
);

export const checkQ = createAsyncThunk(
  `${reducerName}/CHECK`,
  async (q, { rejectWithValue }) => {
    try {
      if (q && q.length > 1) {
        const params = { q, robotId: getRobotId() };
        const { result: cmdResult } = await checkCommand(params);
        if (cmdResult) {
          return rejectWithValue(i18n.t('bot:TRN.MSG.KEYWORD', { noun: q }));
        }
        const { result: qResult } = await checkQuestion(params);
        if (qResult) {
          return rejectWithValue(i18n.t('bot:TRN.MSG.KEYWORD', { noun: q }));
        }
        return true;
      }
      return rejectWithValue('명령어를 입력하세요.');
    } catch (error) {
      return rejectWithValue('명령어 검증 중 오류가 발생했습니다.');
    }
  },
);

export const getBackData = createAsyncThunk(
  `${reducerName}/GET_PIBO_DATA`,
  async ({ robotId, locale }, { rejectWithValue, getState }) => {
    const {
      data: { fx, bots, background },
    } = getState().train;

    try {
      const obj = { bots, background, fx };
      if (!bots || bots.length === 0) {
        const botData = await getPiboDataList({ robotId, locale });
        const { result: botResult, list } = botData;
        if (botResult) {
          const botsPromise = list.map(
            async ({ title: label, command, example, _id: key }) => ({
              key,
              label,
              command,
              commandList: command ? command.flat() : [],
              example: example && example.length > 0 ? example[0] : label,
            }),
          );
          const botsList = await Promise.all(botsPromise);
          obj.bots = botsList.filter(
            ({ commandList, label }) =>
              commandList.length > 0 && label !== '훈련',
          );
        }
      }

      if (!background || background.length === 0) {
        const bgData = await getContentList('Music');
        const { result: bgResult, data: bg } = bgData;
        if (bgResult && bg) {
          obj.background = bg.map(({ genre, list: ori }) => {
            const newList = ori.map(
              ({ title: label, id: key, fileId, length }) => ({
                label,
                key,
                value: fileId,
                len: length,
              }),
            );
            return { genre, list: newList };
          });
        }
      }

      if (!fx || fx.length === 0) {
        const fxData = await getContentList('Sound');
        const { result: fxResult, data: fxDataList } = fxData;
        if (fxResult && fxDataList) {
          obj.fx = fxDataList.map(({ genre, list: ori }) => {
            const newList = ori.map(
              ({ title: label, id: key, fileId, length }) => ({
                label,
                key,
                value: fileId,
                len: length,
              }),
            );
            return { genre, list: newList };
          });
        }
      }
      if (obj.bots || obj.background || obj.fx) {
        return obj;
      }
      return rejectWithValue({ popup: { type: DATA_LOAD_FAIL } });
    } catch (error) {
      errorLog(error);
      return rejectWithValue({ popup: { type: DATA_LOAD_FAIL } });
    }
  },
);

const initialState = {
  // common
  path: '/bots/OFFICIAL_TRAIN',
  pending: false,
  error: null,
  play: null,
  // add, update
  data: {
    motions: MOTIONS,
    colors: EYE_COLORS,
    fx: [],
    bots: [],
    background: [],
  },
  menus: [...MENUS],
  execution: {
    color: null,
    icon: null,
    label: null,
    name: null,
  },
  executes: [],
  command: null,
  commandPass: null,
  // read, delete
  list: [],
  total: 0,
  toggle: false,
  checked: [],
  popup: getPopup(),
};

const trainSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    init: state => ({ ...initialState, data: state.data }),
    initForm: state => ({
      ...initialState,
      list: state.list,
      total: state.total,
      data: state.data,
    }),
    setMenu: (state, action) => ({
      ...state,
      pending: false,
      execution: action.payload
        ? { ...action.payload }
        : { ...initialState.execution },
    }),
    setValue: (state, action) => ({
      ...state,
      pending: false,
      error: null,
      executes: action.payload,
    }),
    setData: (state, action) => ({
      ...state,
      pending: false,
      command: action.payload ? action.payload.command : null,
      executes: action.payload ? action.payload.executes : [],
      checked: action.payload ? action.payload.checked : [],
    }),
    setPlay: (state, action) => ({
      ...state,
      play: action.payload,
    }),
    setChecked: (state, action) => ({
      ...state,
      pending: false,
      checked: action.payload,
    }),
    setToggle: (state, action) => ({
      ...state,
      pending: false,
      toggle: action.payload,
    }),
    setPopup: (state, action) => ({
      ...state,
      popup: action.payload,
    }),
  },
  extraReducers: {
    [addItem.pending.type]: state => ({ ...state, pending: true }),
    [addItem.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [addItem.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
    [updateItem.pending.type]: state => ({ ...state, pending: true }),
    [updateItem.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [updateItem.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
    [deleteList.pending.type]: state => ({ ...state, pending: true }),
    [deleteList.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [deleteList.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [fetchTrains.pending.type]: state => ({
      ...state,
      pending: true,
      error: null,
    }),
    [fetchTrains.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [fetchTrains.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
    [checkQ.fulfilled.type]: state => ({
      ...state,
      commandPass: true,
      error: null,
    }),
    [checkQ.rejected.type]: (state, action) => ({
      ...state,
      commandPass: false,
      error: action.payload,
    }),
    [getBackData.fulfilled.type]: (state, action) => ({
      ...state,
      data: { ...state.data, ...action.payload },
    }),
    [getBackData.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
  },
});

const { reducer: trainReducer, actions } = trainSlice;
export const {
  init,
  initForm,
  setMenu,
  setValue,
  setData,
  setPlay,
  setChecked,
  setToggle,
  setPopup,
} = actions;

export const onDelete = () => (dispatch, getState) => {
  const { checked, list } = getState().train;
  const checkedList = checked.map(id => list.find(item => item.id === id));
  dispatch(setPopup({ type: DELETE_CONFIRM, params: checkedList }));
};

export const onPreviewClick = (id, title) => (dispatch, getState) => {
  const { play } = getState().train;
  if (play) {
    killPibo();
  }

  if (play !== id) {
    speakPibo(title, () => {
      dispatch(setPlay(null));
    });
  }

  dispatch(setPlay(play === id ? null : id));
};

export const onSaveExecution = v => (dispatch, getState) => {
  const { executes: old, execution } = getState().train;
  const { name, key, ...rest } = execution;
  let executes;
  if (name === 'bot') {
    executes = [...old].concat({ card: { ...rest }, [name]: { ...v } });
  } else {
    executes = [...old].concat({ card: { ...rest }, ...v });
  }
  dispatch(setValue(executes));
  dispatch(setMenu(null));
};

export const onToggleListMode = () => (dispatch, getState) => {
  const { toggle } = getState().train;
  if (toggle) {
    dispatch(setChecked([]));
    dispatch(fetchTrains({ robotId: getRobotId() }));
  }
  dispatch(setToggle(!toggle));
};

export default trainReducer;
