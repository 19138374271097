import moment from 'moment';
import React from 'react';
import { Header, Label } from 'semantic-ui-react';

const CAT_LABELS = {
  total: {
    label: '종합',
    color: 'red',
  },
  global: {
    label: '세계',
    color: 'orange',
  },
  social: {
    label: '사회',
    color: 'olive',
  },
  it: {
    label: '과학/기술',
    color: 'blue',
  },
  ent: {
    label: '연예',
    color: 'pink',
  },
  sports: {
    label: '스포츠',
    color: 'purple',
  },
  health: {
    label: '건강',
    color: 'green',
  },
  economy: {
    label: '경제',
    color: 'green',
  },
  politics: {
    label: '정치',
    color: 'grey',
  },
};

const NewsCard = ({ firstTime: date, list, category, error }) => (
  <div>
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        gap: '.25rem',
        marginBottom: '.5rem',
        alignItems: 'center',
      }}
    >
      <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
        {error || moment(date).format('LL dddd')}
      </Header>
      {category && (
        <Label
          basic
          color={CAT_LABELS[category].color}
          size="mini"
          style={{
            borderRadius: '1rem',
            textAlign: 'center',
            maxWidth: '60px',
          }}
        >
          {CAT_LABELS[category].label}
        </Label>
      )}
    </div>
    {list && (
      <div style={{ display: 'flex', gap: '.5rem', flexFlow: 'column' }}>
        {list.map(
          ({ title, link, press }) =>
            title && (
              <div
                key={title}
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  alignItems: 'flex-start',
                  gap: '.25rem',
                }}
              >
                <span>-</span>
                <div
                  aria-hidden="true"
                  style={{ flex: '1 0', flexFlow: 'column' }}
                  onClick={() => link && window.open(link, '_blank')}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexFlow: 'row',
                      gap: '.25rem',
                    }}
                  >
                    <span className={link && 'link'}>{title}</span>
                  </div>
                  <span
                    style={{
                      color: 'rgba(0,0,0,.5)',
                      fontSize: '0.875rem',
                      lineHeight: '1',
                      textAlign: 'right',
                      display: 'block',
                    }}
                  >
                    {press}
                  </span>
                </div>
              </div>
            ),
        )}
      </div>
    )}
  </div>
);

export default NewsCard;
