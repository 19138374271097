import styled from 'styled-components';

const RingButtonGroup = styled.div`
  border-radius: 100%;
  position: relative;
  height: 50vw;
  width: 50vw;
  transform: rotate(45deg);
`;

const RingButtonItem = styled.div`
  height: 50%;
  opacity: 1;
  position: absolute;
  width: 50%;
  background-color: ${({ active }) => (active ? '#8fcfed' : '#d1d3d4')};
  color: ${({ disabled }) =>
    disabled ? 'rgba(255, 255, 255, 0.5)' : '#565b5b'};
  font-weight: 600;
  &:active {
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    background-color: #0894d7;
  }
  & > div {
    color: inherit;
    width: 100%;
    transform: rotate(-45deg);
    &:active {
      color: #fff;
    }
  }
  &.down {
    border-radius: 0 0 100% 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 1px 0px rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(86, 91, 91, 0.3);
    display: flex;
    align-items: center;
    & > div {
      padding-bottom: 1em;
      text-align: center;
    }
  }
  &.up {
    border-radius: 100% 0 0 0;
    left: 0;
    top: 0;
    box-shadow: inset -1px 0px rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(86, 91, 91, 0.3);
    display: flex;
    align-items: center;
    & > div {
      padding-top: 1em;
      text-align: center;
    }
  }
  &.right {
    border-radius: 0 100% 0 0;
    right: 0;
    top: 0;
    border-left: 1px solid rgba(86, 91, 91, 0.3);
    box-shadow: inset 0px -1px rgba(255, 255, 255, 0.3);
    display: flex;
    align-items: center;
    & > div {
      text-align: left;
      padding-left: 1em;
    }
  }
  &.backward {
    border-radius: 0 0 100% 0;
    bottom: 0;
    right: 0;
    box-shadow: inset 1px 0px rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(86, 91, 91, 0.3);
    display: flex;
    align-items: center;
    & > div {
      text-align: center;
      padding-bottom: 1em;
    }
  }
  &.forward {
    border-radius: 100% 0 0 0;
    left: 0;
    top: 0;
    box-shadow: inset -1px 0px rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(86, 91, 91, 0.3);
    display: flex;
    align-items: center;
    & > div {
      text-align: center;
      padding-top: 1em;
    }
  }
  &.left {
    border-radius: 0 0 0 100%;
    bottom: 0;
    left: 0;
    box-shadow: inset 0px 1px rgba(255, 255, 255, 0.3);
    border-right: 1px solid rgba(86, 91, 91, 0.3);
    display: flex;
    align-items: center;
    & > div {
      text-align: right;
      padding-right: 1em;
    }
  }
  &::after {
    position: absolute;
    display: block;
    font-size: 1rem;
    width: fit-content;
    height: fit-content;
    transform: rotate(-45deg);
  }
`;

const RingCutOut = styled.div`
  background-color: #8fcfed;
  border-radius: 50%;
  height: 18%;
  position: absolute;
  left: 41.5%;
  top: 41.5%;
  width: 18%;
  border: 4px solid #fff;
`;

export { RingButtonGroup, RingButtonItem, RingCutOut };
