import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLifecycles, useUpdateEffect, useWindowSize } from 'react-use';
import HistoryList from '../../../components/bots/HistoryBot/HistoryList';
import InputDialog from '../../../components/bots/HistoryBot/InputDialog';
import Popup from '../../../components/Popup';
import {
  attachHistory,
  getHistory,
  getInitData,
  sendFailure,
  sendPending,
  updateHistoryItem,
} from '../../../ducks/bots/historyBot/history';
import i18n from '../../../lang/i18n';
import { getAlive, receivePiboEvent, speakPibo } from '../../../pibo';
import { getRobotId, getUserId } from '../../../utils/common';

let timer = null;

const actualHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.documentElement.getElementsByTagName('body')[0].clientHeight;

const HistoryPage = () => {
  const { height: h } = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('bot');
  const timelineRef = useRef();
  const [height, setHeight] = useState(actualHeight + 60);
  const [isPopup, setPopup] = useState(false);

  const {
    sendHistoryPending,
    message: msg,
    initDataStatus,
    bBottom,
  } = useSelector(state => state.history);

  const handleReceive = params => {
    if (params) {
      const { q, a, date, data, bot } = params;
      const firstTime = moment(date);
      // receivePiboEvent('talk', handleReceive);
      // timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
      return dispatch(
        updateHistoryItem({
          status: 200,
          data: {
            userId: getUserId(),
            robotId: getRobotId(),
            q,
            a,
            firstTime,
            data,
            bot,
          },
        }),
      );
    }
    return dispatch(
      sendFailure({ sendHsitoryError: i18n.t('bot:HST.SEND.FAILED') }),
    );
  };

  const sendDialog = message => {
    if (getAlive()) {
      dispatch(sendPending({ message }));
    }
    // 입력 할 때, 콜백이 두 번 발생하는 문제 방지를 위해 걸어둔 콜백 지움
    receivePiboEvent('talk', null);
    const status = speakPibo(message, handleReceive);
    if (status) {
      timer = setTimeout(() => {
        if (sendHistoryPending || msg) {
          timer = null;
          receivePiboEvent('talk', handleReceive);
          dispatch(
            sendFailure({
              sendHistoryError: i18n.t('bot:HST.SEND.FAILED'),
              message: msg,
            }),
          );
        }
      }, 10000);
    }
  };

  const handlePopUpClick = () => {
    navigate('/pibo');
    setPopup(false);
  };

  useLifecycles(
    () => {
      const robotId = getRobotId();
      if (robotId) {
        receivePiboEvent('talk', handleReceive);
        if (!initDataStatus) {
          dispatch(getInitData());
          dispatch(getHistory({ robotId }));
        } else {
          dispatch(attachHistory({ robotId }));
        }
      } else {
        setPopup(true);
      }
    },
    () => {
      if (timer) {
        clearTimeout(timer);
      }
      setPopup(false);
      return receivePiboEvent('talk', null);
    },
  );

  useUpdateEffect(() => {
    if (bBottom) {
      timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
    }
  }, [bBottom]);

  useEffect(() => {
    // 하단과 상단의 메뉴 높이를 지정하여 입력창 높이(60)와 더하여 실제 높이에서 뺀다.
    const v = h > 850 ? 54 + 45 : 45 + 40;
    setHeight(actualHeight - (60 + v));
  }, [h]);

  return (
    <div style={{ height: 'inherit' }}>
      {isPopup && (
        <Popup
          contents={getUserId() ? t('MSG.STATE') : '로그인 후 이용하세요.'}
          rightLabel={t('common:POP.OK')}
          rightFunc={handlePopUpClick}
          leftFunc={handlePopUpClick}
          mode="confirm"
          isPopup={isPopup}
        />
      )}
      <HistoryList
        timelineRef={timelineRef}
        style={{
          height,
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex',
          flexDirection: 'column-reverse',
          width: '100%',
          background: '#fbfbfb',
        }}
      />
      <InputDialog
        icon="send"
        {...{ icon: 'send', standalone: true, sendDialog }}
      />
    </div>
  );
};

export default HistoryPage;
