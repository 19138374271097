import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import i18 from '../lang/i18n';

import {
  userSignUp,
  sendCertCode,
  confirmCertCode,
  checkUserId,
  userSignIn,
} from '../utils/api';
import {
  getErrorCode,
  getErrorMsg,
  getLocale,
  INSIDER,
  setCurrentUser,
  setLocalStorage,
  setUserToken,
} from '../utils/common';
import { reporter } from '../utils/report';
import {
  validateComparePassword,
  validateEmail,
  validateId,
  validatePassword,
  validateTel,
} from '../utils/validate';

const name = 'signup';
const NEXT = { color: 'blue', key: 'next_btn', label: i18.t('user:NEXT') };
const PREV = { style: { color: '#fff !important' }, key: 'prev_btn' };
const steps = [
  { id: 'aggrement', label: i18.t('user:SIGN_UP.TITLE.STEP1') },
  { id: 'authentication', label: i18.t('user:SIGN_UP.TITLE.STEP2') },
  { id: 'information', label: i18.t('user:SIGN_UP.TITLE.STEP3') },
  { id: 'privacy', label: i18.t('user:SIGN_UP.TITLE.STEP4') },
];
const activeIndexOrigin = 0;
/* const testData = {
  '0': { privacy: true, location: true, service: true },
  '1': {
    tel: { value: '01098760000', sent: true, confirm: true },
    email: { value: '', sent: false, confirm: false },
  },
  '2': {
    userId: 'ysl1234',
    pw: 'ysl12345',
    rePw: 'ysl12345',
    confirm: true,
    error: false,
  },
  '3': {
    firstName: 'laurant',
    lastName: 'saint',
    birthDate: moment().format('YYYY-MM-DD'),
    gender: "unknown",
    error: false,
  },
}; */
const originStep1Data = { value: '', sent: false, confirm: false, time: 0 };

const originData = {
  '0': { privacy: false, location: false, service: false },
  '1': {
    tel: { ...originStep1Data },
    email: { ...originStep1Data },
    error: { code: '', message: '' },
  },
  '2': {
    userId: '',
    pw: '',
    rePw: '',
    confirm: false,
    error: false,
  },
  '3': {
    firstName: '',
    lastName: '',
    birthDate: moment().format('YYYY-MM-DD'),
    gender: null,
    error: false,
  },
};

const getBtnState = (activeIndex, payload) => {
  if (activeIndex === 0) {
    const { service, privacy } = payload;
    return [
      {
        ...NEXT,
        label: i18.t('user:TERM.ACCEPT'),
        disabled: !(service && privacy),
      },
      { ...PREV, label: i18.t('user:TERM.DECLINE'), disabled: false },
    ];
  }
  if (activeIndex === 1) {
    const { tel, email } = payload;
    const disabled = !((tel && tel.confirm) || (email && email.confirm));
    return [
      {
        ...NEXT,
        disabled,
      },
      { ...PREV, label: i18.t('user:PREV'), disabled: false },
    ];
  }

  if (activeIndex === 2) {
    const { userId, pw, rePw, error } = payload;
    const confirm = userId && pw && rePw && !error;
    return [
      {
        ...NEXT,
        disabled: !confirm,
      },
      { ...PREV, label: i18.t('user:PREV'), disabled: false },
    ];
  }

  if (activeIndex === 3) {
    const { firstName, lastName, birthDate, gender, error } = payload;
    const confirm = firstName && lastName && birthDate && gender && !error;
    return [
      {
        ...NEXT,
        disabled: !confirm,
      },
      { ...PREV, label: i18.t('user:PREV'), disabled: false },
    ];
  }

  if (activeIndex === 4) {
    return [{ ...NEXT, label: i18.t('conn:BTN.MOVE_TO_CONNECT') }];
  }
  return [];
};

const initialState = {
  steps,
  loading: false,
  activeIndex: activeIndexOrigin,
  terms: { privacy: '', location: '', service: '' },
  data: originData,
  btn: getBtnState(activeIndexOrigin, originData[activeIndexOrigin]),
  error: '',
  complete: { message: null, to: null },
};

const termsGetData = async () => {
  const locale = getLocale();
  const termsList = ['service', 'privacy', 'location'];

  const loadTerms = entries =>
    Promise.all(
      entries.map(entry =>
        import(`../components/account/terms/${entry}_${locale}.md`),
      ),
    );

  const result = await loadTerms(termsList);
  const results = await result.map(async ({ default: item }) => {
    const res = await fetch(item);
    return res.text();
  });
  const termsResult = await Promise.all(results);
  return termsResult;
};

export const fetchTerms = createAsyncThunk(
  `${name}/GET_TERMS`,
  async (arg, { rejectWithValue }) => {
    try {
      const [service, privacy, location] = await termsGetData();
      return { service, privacy, location };
    } catch (error) {
      return rejectWithValue(i18.t('user:SIGN_UP.ERR_MSG.FAILED_TERMS'));
    }
  },
);

export const sendCode = createAsyncThunk(
  `${name}/SEND_CODE`,
  async ({ type, value }, { getState, rejectWithValue }) => {
    const {
      signup: { data },
    } = getState();
    const indexData = data['1'];
    let returnData;
    let errorObj;
    try {
      const obj = { email: '', tel: '' };
      const sendResult = await sendCertCode({
        ...obj,
        [type]: value,
        type: 'signup',
      });
      /* 테스트용
        const cur = new Date().valueOf();
        const sendResult = {
          result: true,
          data: {
            statusCode: '202',
            statusName: 'success',
            requestId: '138fa09cb5ce43a9a09aef1a0e573efa',
            requestTime: '2021-04-23T14:05:43.922',
          },
          saveResult: {
            _id: '608255a7091a5ef6e5fbdce9',
            valid: true,
            hash: '6TEVNF',
            type: 'signup',
            createdAt: '2021-04-23T05:05:43.506Z',
            firstTime: cur,
            expireTime: cur + (1000 * 60 * 3),
            expiredAt: '2021-04-23T05:08:43.506Z',
            __v: 0,
          },
        };
        */
      if ('result' in sendResult) {
        const { result, saveResult, error } = sendResult;
        if (result) {
          const { expireTime, firstTime } = saveResult;
          returnData = {
            ...indexData,
            [type]: { ...indexData[type], sent: true, expireTime, firstTime },
            error: false,
          };
          return { data: { ...data, '1': returnData } };
        }
        if ('code' in error && error.code === '5v102z') {
          if (type === 'tel') {
            errorObj = {
              code: 'used_tel',
              message: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_TEL'),
            };
          }
          if (type === 'email') {
            errorObj = {
              code: 'used_mail',
              message: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_MAIL'),
            };
          }
        } else {
          errorObj = {
            code: 'failed',
            message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
          };
        }
      } else {
        errorObj = {
          code: 'unvalid',
          message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
        };
      }
      returnData = { ...indexData, error: errorObj };
      return rejectWithValue({ ...data, '1': returnData });
    } catch (error) {
      errorObj = {
        code: 'failed',
        message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
      };
      returnData = { ...indexData, error: errorObj };
      return rejectWithValue({ ...data, '1': returnData });
    }
  },
);

export const confirmCode = createAsyncThunk(
  `${name}/CONFIRM_CODE`,
  async ({ code, type }, { getState, rejectWithValue }) => {
    const {
      signup: { data },
    } = getState();
    const indexData = data['1'];
    let returnData;
    try {
      const confirmResult = await confirmCertCode({ code, type: 'signup' });
      /* 테스트용
      const confirmResult = { result: true };
      */
      if ('result' in confirmResult) {
        const { result } = confirmResult;
        if (result) {
          returnData = {
            ...indexData,
            [type]: { ...indexData[type], error: false, confirm: true },
          };
          return {
            data: { ...data, '1': returnData },
            btn: getBtnState(1, returnData),
          };
        }
        returnData = {
          ...indexData,
          error: {
            code: 'unvalid',
            message: i18.t('user:SIGN_UP.ERR_MSG.UNVALID_CERT'),
          },
        };
      } else {
        returnData = {
          ...indexData,
          error: {
            code: 'failed',
            message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'),
          },
        };
      }
      return rejectWithValue({ ...data, '1': returnData });
    } catch (error) {
      returnData = {
        ...indexData,
        error: {
          code: 'error',
          message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'),
        },
      };
      return rejectWithValue({ ...data, '1': returnData });
    }
  },
);

const getAccountMsg = (target, value, compareValue) => {
  let msg = false;
  if (target === 'userId') {
    msg = validateId(value);
    return msg;
  }

  if (target === 'pw' || target === 'rePw') {
    msg = validatePassword(value);
    if (msg) {
      return msg;
    }
    msg = validateComparePassword(value, compareValue);

    if (msg) {
      return msg;
    }
    return false;
  }

  if (target === 'confirm') {
    if (value) {
      return false;
    }
    return i18.t('user:SIGN_UP.ERR_MSG.ALREADY_ID');
  }

  return false;
};

const signUpSlice = createSlice({
  name,
  initialState,
  reducers: {
    init: state => ({ ...initialState, terms: state.terms }),
    next: (state, action) => ({ ...state, ...action.payload }),
    prev: (state, action) => ({ ...state, ...action.payload }),
    setData: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: {
    [fetchTerms.pending.type]: state => ({ ...state, loading: true }),
    [fetchTerms.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      terms: action.payload,
    }),
    [fetchTerms.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [sendCode.pending.type]: state => ({ ...state, loading: true }),
    [sendCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    [sendCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      data: { ...action.payload }, // error가 data 안에 포함
    }),
    [confirmCode.pending.type]: state => ({ ...state, loading: true }),
    [confirmCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    [confirmCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      data: { ...action.payload }, // error가 data 안에 포함
    }),
  },
});

const { reducer: signUpReducer, actions } = signUpSlice;
export const { init } = actions;
const { next, prev, setData } = actions;

export const onPrev = () => (dispatch, getState) => {
  const { signup: state } = getState();
  const { activeIndex: currentIndex } = state;
  const activeIndex = state.activeIndex === 0 ? 0 : state.activeIndex - 1;
  const data = {
    ...state.data,
    [currentIndex]: { ...originData[currentIndex] },
  };

  if (currentIndex === 0) {
    window.location.href = '/login';
    return null;
  }

  return dispatch(
    prev({
      activeIndex,
      btn: getBtnState(activeIndex, state.data[activeIndex]),
      data,
    }),
  );
};

export const onNext = () => async (dispatch, getState) => {
  const { signup: state } = getState();
  const { activeIndex: currentIndex } = state;
  const activeIndex =
    currentIndex < steps.length ? currentIndex + 1 : currentIndex;
  let returnData;
  if (currentIndex === 2) {
    const { userId, ...rest } = state.data[currentIndex];
    const { result } = await checkUserId(userId);
    const currentData = {
      ...rest,
      userId,
      confirm: result,
      error: false,
    };

    if (result) {
      returnData = {
        activeIndex,
        btn: getBtnState(activeIndex, state.data[activeIndex]),
        data: {
          ...state.data,
          [currentIndex]: currentData,
        },
      };
    } else {
      returnData = {
        btn: getBtnState(currentIndex, currentData),
        data: {
          ...state.data,
          [currentIndex]: {
            ...currentData,
            error: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_ID'),
          },
        },
      };
    }
  }

  if (currentIndex === 3) {
    const {
      '0': { privacy, service, location: agreeLocation },
      '1': {
        tel: { value: tel, confirm: telConfirm },
        email: { value: email, confirm: emailConfirm },
      },
      '2': { userId, pw, confirm: idConfirm },
      '3': { firstName, lastName, birthDate, gender },
    } = state.data;

    let error = false;
    const currentData = {
      ...state.data[currentIndex],
      error,
    };
    if (!(privacy && service)) {
      error = i18.t('user:SIGN_UP.MSG.AGREE_TERMS');
    } else if (!(telConfirm || emailConfirm)) {
      error = i18.t('user:SIGN_UP.MSG.VERIFY_ANY');
    } else if (!(userId && idConfirm && pw)) {
      error = i18.t('user:SIGN_UP.MSG.INPUT_ACCOUNT');
    } else if (!(firstName && lastName && birthDate && gender)) {
      error = i18.t('user:SIGN_UP.MSG.USER_INFO');
    } else {
      const signUpData = {
        agreeLocation,
        tel,
        email,
        userId,
        password: pw,
        firstName,
        lastName,
        birthDate,
        gender,
      };
      const signUpResult = await userSignUp(signUpData);
      const { result } = signUpResult;
      if (!result) {
        const { error: err } = signUpResult;
        error = getErrorMsg(getErrorCode(err), 'common:ERR.UNSPECIFIC');
      }
    }

    if (error) {
      returnData = {
        btn: getBtnState(currentIndex, currentData),
        data: {
          ...state.data,
          [currentIndex]: { ...currentData, error },
        },
      };
    } else {
      returnData = {
        activeIndex,
        btn: getBtnState(activeIndex, state.data[activeIndex]),
        complete: {
          message: i18.t('user:SIGN_UP.COMPLETE', { first: firstName }),
          to: null,
        },
      };
    }
  }

  if (currentIndex === 4) {
    const {
      '2': { userId: id, pw },
    } = state.data;
    const res = await userSignIn({ id, pw });
    if (res.result) {
      const {
        userId,
        nickName,
        robotId,
        robotPId,
        userPId,
        insider,
        version,
      } = res.data;
      INSIDER.verify = insider;
      await setCurrentUser({
        userPId,
        userId,
        robotId,
        robotPId,
        nickName,
      });
      await setLocalStorage('version', version);
      // db 데이터
      await setUserToken(res.token);
      reporter({
        target: userId,
        action: 'signup',
        data: {
          result: true,
        },
      });
      returnData = {
        activeIndex,
        btn: getBtnState(activeIndex, state.data[activeIndex]),
        complete: {
          message: i18.t('user:SIGN_UP.COMPLETE', { first: nickName }),
          to: '/connect',
        },
      };
    }
  }

  if (!returnData) {
    returnData = {
      activeIndex,
      btn: getBtnState(activeIndex, state.data[activeIndex]),
    };
  }

  dispatch(next(returnData));
};

export const onChange = value => async (dispatch, getState) => {
  const { signup: state } = getState();
  const { activeIndex } = state;
  let { btn } = state;
  const data = { ...state.data };
  if (activeIndex === 1) {
    const { tel, email } = value;
    const telMsg = validateTel(tel);
    const mailMsg = validateEmail(email);
    if (tel.value) {
      data[activeIndex] = { tel, email: { ...originStep1Data } };
      if (telMsg) {
        data[activeIndex].error = telMsg;
      }
    } else if (email.value) {
      data[activeIndex] = { tel: { ...originStep1Data }, email };
      if (mailMsg) {
        data[activeIndex].error = mailMsg;
      }
    } else {
      data[activeIndex] = {
        tel: { ...originStep1Data },
        email: { ...originStep1Data },
        error: { code: '', message: '' },
      };
    }
    btn = getBtnState(activeIndex, data[activeIndex]);
  } else if (activeIndex === 2) {
    const { userId, pw, rePw } = state.data[activeIndex];
    const [[changeTarget, changeValue], ...rest] = Object.entries(value);
    let error = false;
    if (rest.length === 0) {
      if (changeTarget === 'confirm') {
        let confirm = false;
        error = getAccountMsg('userId', userId);
        if (!error) {
          const { result } = await checkUserId(userId);
          confirm = result;
          if (result) {
            error = getAccountMsg(changeTarget, confirm);
          } else {
            error = i18.t('user:SIGN_UP.ERR_MSG.ALREADY_ID');
          }
        }
        data[activeIndex] = {
          ...state.data[activeIndex],
          confirm,
          error,
        };
      } else {
        error = getAccountMsg(
          changeTarget,
          changeValue,
          changeTarget === 'pw' ? rePw : pw,
        );
        data[activeIndex] = {
          ...state.data[activeIndex],
          [changeTarget]: changeValue,
          error,
        };
      }
      btn = getBtnState(activeIndex, data[activeIndex]);
    } else {
      error =
        getAccountMsg('userId', userId) ||
        getAccountMsg('pw', pw) ||
        getAccountMsg('pw', rePw) ||
        getAccountMsg('pw', pw, rePw);
      data[activeIndex] = {
        ...state.data[activeIndex],
        userId,
        pw,
        rePw,
        error,
      };
      btn = getBtnState(activeIndex, data[activeIndex]);
    }
    if (btn.length === 0) {
      btn = getBtnState(activeIndex, data[activeIndex]);
    }
  } else {
    data[activeIndex] = { ...value };
    btn = getBtnState(activeIndex, data[activeIndex]);
  }

  dispatch(setData({ btn, data }));
};

export default signUpReducer;
