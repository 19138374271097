import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { shuffle } from '../utils/common';

const name = 'page';
const COMMENTS = {
  on: [
    <>
      조금 나른하지 않아?
      <br />
      두 팔 쭉 뻗고
      <br />
      가볍게 스트레칭하자!
    </>,
    <>
      안녕~
      <br />
      너와 만나서 기분 좋은 하루야!
    </>,
  ],
  off: [
    <>
      파이보를 켜줘.
      <br />
      너와 하고싶은 얘기가 많아!
    </>,
    <>
      오늘 하루 어땠어?
      <br />
      파이보를 켜고 같이 얘기해보자.
    </>,
  ],
  unconnect: [
    <span>파이보를 연결해줘. 너와 빨리 만나고 싶어~</span>,
    <span>
      반가워.
      <br />
      파이보를 연결해줘.
    </span>,
  ],
  unsigned: [
    <span>파이보 모바일을 이용하려면 로그인이 필요해~</span>,
    <span>로그인해서 파이보 모바일을 이용해줘.</span>,
  ],
};

const initialState = {
  scroll: null,
  isSetting: false,
  main: {
    type: 'on',
    arr: COMMENTS.on,
    comment: COMMENTS.on[0],
    loading: false,
    key: false,
  },
  configMenu: false,
};

export const setComment = createAsyncThunk(
  `${name}/setComment`,
  async (t, { getState }) => {
    const {
      main: { type, arr, key },
    } = getState().page;
    try {
      let newArr = [...arr];
      let newComment = newArr.shift();
      let newType = type;
      if (type !== t) {
        newType = t;
        newArr = shuffle([...COMMENTS[t]]);
        newComment = newArr.shift();
      }
      if (arr.length === 0) {
        newArr = shuffle([...COMMENTS[t]]);
        newComment = newArr.shift();
      }
      return {
        key: !key,
        type: newType,
        arr: newArr,
        comment: newComment,
      };
    } catch (error) {
      return { type, arr, comment: '', key: false };
    }
  },
);

const pageSlice = createSlice({
  name,
  initialState,
  reducers: {
    setScroll: (state, action) => ({ ...state, scroll: action.payload }),
    setSetting: (state, action) => ({ ...state, isSetting: action.payload }),
    setMenu: (state, action) => ({ ...state, configMenu: action.payload }),
  },
  extraReducers: {
    [setComment.pending.type]: state => ({
      ...state,
      main: { ...state.main, loading: true },
    }),
    [setComment.fulfilled.type]: (state, action) => ({
      ...state,
      main: { ...action.payload, loading: false },
    }),
  },
});

const { reducer: pageReducer, actions } = pageSlice;
export const { setScroll, setSetting, setMenu } = actions;
// const { setDisturb, error, setPlug, configInit } = actions;

export default pageReducer;
