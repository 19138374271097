/* eslint-disable consistent-return */
import {
  findAccount,
  verifyAccountCert,
  resetPassword,
  sendCertCode,
} from '../utils/api';
import { setCurrentUser, getUserPId } from '../utils/common';
import i18 from '../lang/i18n';
import { MAIL_REGEXP, PW_REGEXP, TEL_REGEXP } from '../utils/validate';
import { errorLog } from '../utils/report';

const SET_STEP = 'findAccounts/SET_STEP';
const SET_TYPE = 'findAccounts/SET_TYPE';
const SET_DATA = 'findAccounts/SET_DATA';
const INITIALIZE = 'findAccounts/INITIALIZE';
const SET_SENT_STATUS = 'findAccounts/SET_SENT_STATUS';
const SET_ERROR = 'findAccounts/SET_ERROR';
const SET_VALUE = 'findAccounts/SET_VALUE';
const SET_NEXT_ENABLE = 'findAccounts/SET_NEXT_ENABLE';
const PENDING = 'findAccounts/PENDING';

const setStep = (step, nextEnable = false, complete = false) => ({
  type: SET_STEP,
  step,
  nextEnable,
  complete,
});
const setType = select => ({ type: SET_TYPE, select });
const setUserInfo = userinfo => ({ type: SET_DATA, userinfo });
const setHadSent = (flag, hash) => ({ type: SET_SENT_STATUS, flag, hash });
const setError = e => ({ type: SET_ERROR, error: e });
const setData = data => ({ type: SET_VALUE, data });
const setNext = nextEnable => ({ type: SET_NEXT_ENABLE, nextEnable });

export const initializing = () => ({ type: INITIALIZE });

const initialState = {
  step: -1,
  page: null,
  select: null,
  hadSent: false,
  complete: false,
  userinfo: {
    name: null,
    email: null,
    serial: null,
    userId: null,
    pw: null,
    repw: null,
  },
  hash: null,
  error: null,
  data: null,
  nextEnable: false,
  pending: false,
};

export default function findAccountsReducer(state = initialState, action) {
  switch (action.type) {
    case PENDING:
      return {
        ...state,
        pending: true,
      };
    case SET_STEP:
      return {
        ...state,
        step: action.step,
        complete: action.complete,
        nextEnable: action.nextEnable,
      };
    case SET_TYPE:
      return { ...state, select: action.select };
    case SET_DATA:
      return { ...state, userinfo: action.userinfo };
    case SET_SENT_STATUS:
      return {
        ...state,
        pending: false,
        hadSent: action.flag,
        hash: action.hash,
      };
    case SET_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
        nextEnable: false,
      };
    case SET_VALUE:
      return { ...state, data: action.data, error: null };
    case SET_NEXT_ENABLE:
      return { ...state, nextEnable: action.nextEnable };
    default:
      return initialState;
  }
}

export const prevStep = () => (dispatch, getState) => {
  // 이전 단계로 이동
  const {
    findAccounts: { step },
  } = getState();
  const prevIdx = step - 1;
  if (prevIdx === -1) {
    return dispatch(initializing());
  }
  return dispatch(setStep(prevIdx, true));
};

const findAndSetUserInfo = async (data, userinfo) => {
  const res = await findAccount({ ...data });
  if (res.result) {
    // pass => 결과를 받음. 앞에 3글자 + 랜덤한 * (4~10) => userinfo에 세팅한다.
    const { userId, _id: userPId, firstName } = res.user;
    setCurrentUser({ userPId });
    return {
      result: true,
      info: { ...userinfo, ...data, userId, name: data.name || firstName },
    };
  }
  // fail => name, email 로 조회할 수 있는 계정 없음
  return { result: false, error: i18.t('user:FIND.ERR.NO_ACCOUNT') };
};

const preExecuteStepOnEmail = async ({ data, userinfo, step, hash }) => {
  let lastStep = false;
  let dispatchItem = {};
  let continueStep = false;
  try {
    if (step === 0) {
      // 전달 받은 data name, email로 api를 통해 userId 조회
      const { result, info, error } = await findAndSetUserInfo(data, userinfo);
      if (result) {
        continueStep = true;
        dispatchItem = setUserInfo(info);
      } else {
        dispatchItem = setError(error);
      }
    }
    if (step === 1) {
      // 인증 번호 정합성 체크
      const { result, userId, error } = await verifyAccountCert(
        hash,
        data.cert,
        'findID',
      );
      if (result) {
        lastStep = true;
        continueStep = true;
        dispatchItem = setUserInfo({ ...userinfo, userId });
      } else {
        // fail => 실패 시에만 error로 리턴
        if (error) {
          errorLog(
            `인증 번호 정합성 체크 시 에러 발생 ${JSON.stringify(error)}`,
          );
        }
        dispatchItem = setError(i18.t('user:FIND.ERR.NO_MATCH'));
      }
    }
  } catch (error) {
    dispatchItem = setError(error.message);
  }
  return { dispatchItem, lastStep, continueStep };
};

const preExecuteStepOnSerial = async ({ data, userinfo, step, hash }) => {
  let lastStep = false;
  let dispatchItem = {};
  let continueStep = false;
  try {
    if (step === 0) {
      // 전달 받은 data serial, email로 api를 통해 userId 조회
      const { result, info } = await findAndSetUserInfo(data, userinfo);
      if (result) {
        continueStep = true;
        dispatchItem = setUserInfo(info);
      } else {
        dispatchItem = setError(i18.t('user:FIND.ERR.NO_ACCOUNT'));
      }
    }
    if (step === 1) {
      // 인증 번호 정합성 체크
      const { result, userId, error } = await verifyAccountCert(
        hash,
        data.cert,
        'findID',
      );
      if (result) {
        lastStep = true;
        continueStep = true;
        dispatchItem = setUserInfo({ ...userinfo, userId });
      } else {
        // fail => 실패 시에만 error로 리턴
        if (error) {
          errorLog(
            `인증 번호 정합성 체크 시 에러 발생 ${JSON.stringify(error)}`,
          );
        }
        dispatchItem = setError(i18.t('user:FIND.ERR.NO_MATCH'));
      }
    }
  } catch (error) {
    dispatchItem = setError(error.message);
  }
  return { dispatchItem, lastStep, continueStep };
};

const preExecuteStepOnPW = async ({ data, userinfo, step, hash }) => {
  let lastStep = false;
  let dispatchItem = null;
  let continueStep = false;
  try {
    if (step === 0) {
      // 전달 받은 data userId, email로 api를 통해 정합성 체크
      const { result, info, error } = await findAndSetUserInfo(data, userinfo);
      if (result) {
        continueStep = true;
        dispatchItem = setUserInfo(info);
      } else {
        dispatchItem = setError(error);
      }
    } else if (step === 1) {
      // 인증 번호 정합성 체크
      const { result, error } = await verifyAccountCert(
        hash,
        data.cert,
        'resetPW',
      );
      if (result) {
        continueStep = true;
        dispatchItem = setUserInfo({ ...userinfo });
      } else {
        if (error) {
          errorLog(
            `인증 번호 정합성 체크 시 에러 발생 ${JSON.stringify(error)}`,
          );
        }
        // fail => 실패 시에만 error로 리턴
        dispatchItem = setError(i18.t('user:FIND.ERR.NO_MATCH'));
      }
    } else if (step === 2) {
      // 변경 된 비밀번호 저장
      const {
        password: { pw: newPassword },
      } = data;
      const res = await resetPassword({ hash, newPassword });
      if (res.result) {
        lastStep = true;
        continueStep = true;
      } else {
        dispatchItem = setError(i18.t('user:FIND.ERR.RESET_FAILED'));
      }
    }
  } catch (error) {
    dispatchItem = setError(error.message);
  }
  return { dispatchItem, lastStep, continueStep };
};

export const nextStep = () => async (dispatch, getState) => {
  // 다음 단계로 이동
  const {
    findAccounts: { step, select, userinfo, data, hash },
  } = getState();
  let curSelect = select;
  let complete = false;
  if (!curSelect) {
    curSelect = data;
    dispatch(setType(curSelect));
  } else if (select === 'email') {
    const {
      dispatchItem,
      lastStep,
      continueStep,
    } = await preExecuteStepOnEmail({
      data,
      select,
      userinfo,
      step,
      hash,
    });
    complete = lastStep;
    if (continueStep) {
      dispatch(dispatchItem);
    } else {
      return dispatch(dispatchItem);
    }
  } else if (select === 'serial') {
    const {
      dispatchItem,
      lastStep,
      continueStep,
    } = await preExecuteStepOnSerial({
      data,
      select,
      userinfo,
      step,
      hash,
    });
    complete = lastStep;
    if (continueStep) {
      dispatch(dispatchItem);
    } else {
      return dispatch(dispatchItem);
    }
  } else if (select === 'pw') {
    const { dispatchItem, lastStep, continueStep } = await preExecuteStepOnPW({
      data,
      select,
      userinfo,
      step,
      hash,
    });
    complete = lastStep;
    if (continueStep && dispatchItem) {
      dispatch(dispatchItem);
    } else if (!continueStep) {
      return dispatch(dispatchItem);
    }
  }

  const nextIdx = step + 1;
  if (complete) {
    dispatch(setData(null));
  }
  return dispatch(setStep(nextIdx, false, complete));
};

const mailSender = async (dispatch, getState) => {
  dispatch({ type: PENDING });
  const {
    findAccounts: {
      userinfo: { email, tel },
      select,
      loading,
    },
  } = getState();
  if (loading) return;
  try {
    const res = await sendCertCode({
      email,
      tel,
      userOId: getUserPId(),
      type: select === 'pw' ? 'resetPW' : 'findID',
    });
    if (res.result) {
      const { _id: verId } = res.saveResult;
      return dispatch(setHadSent(true, verId));
    }
    dispatch(setError(i18.t('user:FIND.ERR.SENT_FAILED')));
    return dispatch(setHadSent(false));
  } catch (error) {
    dispatch(setError(error.message));
    return dispatch(setHadSent(false));
  }
};

export const sendMail = () => (dispatch, getState) => {
  mailSender(dispatch, getState);
};

export const resendMail = () => (dispatch, getState) => {
  dispatch(setHadSent(false));
  mailSender(dispatch, getState);
};

const getMsg = (select, data, step) => {
  if (select === 'serial') {
    if (step === 0) {
      const { serial: s, email: e, tel: t, authMethod: auth } = data;
      if (!(s || (auth === 'tel' && t))) {
        return i18.t('user:FIND_SERIAL.MSG.INPUT_ALL');
      }
      if (!(s || (auth === 'email' && e))) {
        return i18.t('user:FIND.ERR_MSG.SERIAL_EMAIL');
      }

      if (!s) {
        return i18.t('user:FIND.ERR_MSG.SERIAL');
      }
      const reg = /[a-zA-Z0-9]/;
      if (!reg.test(s)) {
        return i18.t('user:FIND.ERR_MSG.SERIAL_REGEXP');
      }

      if (auth === 'email' && !e) {
        return i18.t('user:FIND.ERR_MSG.EMAIL');
      }
      if (auth === 'tel' && !t) {
        return i18.t('user:FIND.MSG.INPUT_TEL');
      }

      if (auth === 'email' && !new RegExp(MAIL_REGEXP).test(e)) {
        return i18.t('user:FIND.ERR_MSG.EMAIL_REGEXP');
      }
      if (auth === 'tel' && !new RegExp(TEL_REGEXP).test(t)) {
        return i18.t('user:FIND.MSG.UNVALID_TEL');
      }
    }
  }

  if (select === 'email') {
    if (step === 0) {
      const { name: n, email: e, tel: t, authMethod: auth } = data;
      if (!(n || (auth === 'tel' && t))) {
        return i18.t('user:FIND_INFO.MSG.INPUT_ALL');
      }
      if (!(n || (auth === 'email' && e))) {
        return i18.t('user:FIND.ERR_MSG.NAME_EMAIL');
      }

      if (!n) {
        return i18.t('user:FIND.ERR_MSG.NAME');
      }
      if (auth === 'email' && !e) {
        return i18.t('user:FIND.ERR_MSG.EMAIL');
      }
      if (auth === 'tel' && !t) {
        return i18.t('user:FIND.MSG.INPUT_TEL');
      }
      if (auth === 'email' && !new RegExp(MAIL_REGEXP).test(e)) {
        return i18.t('user:FIND.ERR_MSG.EMAIL_REGEXP');
      }
      if (auth === 'tel' && !new RegExp(TEL_REGEXP).test(t)) {
        return i18.t('user:FIND.MSG.UNVALID_TEL');
      }
    }
  }

  if (select === 'pw') {
    const {
      userId: i,
      email: e,
      tel: t,
      password: { pw: p, repw: r },
      authMethod: auth,
    } = data;
    if (step === 0) {
      if (!(i || (auth === 'tel' && t))) {
        return i18.t('user:FIND_INFO.MSG.INPUT_ALL');
      }
      if (!(i || (auth === 'email' && e))) {
        return i18.t('user:FIND.ERR_MSG.ID_EMAIL');
      }
      if (!i) {
        return i18.t('user:FIND.ERR_MSG.ID');
      }
      if (auth === 'tel' && !t) {
        return i18.t('user:FIND.MSG.INPUT_TEL');
      }
      if (auth === 'email' && !e) {
        return i18.t('user:FIND.ERR_MSG.EMAIL');
      }
      if (auth === 'email' && !new RegExp(MAIL_REGEXP).test(e)) {
        return i18.t('user:FIND.ERR_MSG.EMAIL_REGEXP');
      }
      if (auth === 'tel' && !new RegExp(TEL_REGEXP).test(t)) {
        return i18.t('user:FIND.MSG.UNVALID_TEL');
      }
    } else if (step > 1) {
      if (!p) {
        return i18.t('user:FIND.ERR_MSG.NEW_PW');
      }

      if (!new RegExp(PW_REGEXP, 'g').test(p)) {
        return i18.t('user:FIND.ERR_MSG.PW_REGEXP');
      }

      if (p && !r) {
        return i18.t('user:FIND.ERR_MSG.REPW');
      }
      if (p !== r) {
        return i18.t('user:FIND.ERR_MSG.PW');
      }
    }
  }
  return '';
};

export const setValue = v => (dispatch, getState) => {
  const {
    findAccounts: { step, select },
  } = getState();
  dispatch(setData(v));
  const errorMessage = getMsg(select, v, step);
  if (errorMessage) {
    return dispatch(setError(errorMessage));
  }

  if (step === -1 && v) {
    return dispatch(setNext(true));
  }
  if (select === 'email' && v) {
    if (step === 0) {
      if ('name' in v && 'email' in v && 'tel' in v && 'authMethod' in v) {
        const { authMethod, name, email, tel } = v;
        if (
          ((authMethod === 'email' && email) ||
            (authMethod === 'tel' && tel)) &&
          name
        ) {
          return dispatch(setNext(true));
        }
      }
    }
    if (step === 1 && 'cert' in v && v.cert) {
      return dispatch(setNext(true));
    }
  }
  if (select === 'serial' && v) {
    if (step === 0) {
      if ('serial' in v && 'email' in v && 'tel' in v && 'authMethod' in v) {
        const { authMethod, serial, email, tel } = v;
        if (
          ((authMethod === 'email' && email) ||
            (authMethod === 'tel' && tel)) &&
          serial
        ) {
          return dispatch(setNext(true));
        }
      }
    }
    if (step === 1 && 'cert' in v && v.cert) {
      return dispatch(setNext(true));
    }
  }
  if (select === 'pw' && v) {
    if (step === 0 && 'userId' in v && 'email' in v && v.userId && v.email) {
      return dispatch(setNext(true));
    }
    if (step === 0) {
      if ('userId' in v && 'email' in v && 'tel' in v && 'authMethod' in v) {
        const { authMethod, userId, email, tel } = v;
        if (
          ((authMethod === 'email' && email) ||
            (authMethod === 'tel' && tel)) &&
          userId
        ) {
          return dispatch(setNext(true));
        }
      }
    }
    if (step === 1 && 'cert' in v && v.cert) {
      return dispatch(setNext(true));
    }
    if (
      step === 2 &&
      'password' in v &&
      v.password &&
      v.password.pw &&
      v.password.repw
    ) {
      return dispatch(setNext(true));
    }
  }
  return dispatch(setNext(false));
};
