/* eslint-disable react/no-find-dom-node */
import React from 'react';
import { Grid, Segment, Placeholder } from 'semantic-ui-react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PiboLinePanelMenu, ConfigInfoCard } from '../PiboComponents';
import UserPhoto from '../../user/UserPhoto';
import { StyledToggleButton } from '../../Components';
import { OS_IOS, detectOS } from '../../../utils/common';

const UserSettingList = props => {
  const { t } = useTranslation('user');
  const { user, handleItemClick, interests, loading } = props;
  const { nickName, email, tel, birthDate, src, verified } = user;
  const mm = birthDate ? moment(birthDate) : null;
  const infoArr = [{ id: 'nickname', name: 'NICK.LB', value: nickName }];
  if ((verified && verified.email) || email) {
    infoArr.push({ id: 'email', name: 'MAIL.LB', value: email });
  }
  if (verified && verified.tel) {
    infoArr.push({
      id: 'tel',
      name: 'TEL.LB',
      value: tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'),
    });
  }
  if (detectOS() !== OS_IOS) {
    infoArr.push({
      id: 'birthDate',
      name: 'BIRTH.LB',
      value: mm ? mm.format(t('BIRTH.FORMAT')) : null,
    });
  }

  const menuArr = [
    { menu: 'UPDATE_MY_INFO', name: 'update', move: true, icon: 'updateuser' },
    { menu: 'PW.UPDATE', name: 'pwupdate', move: true, icon: 'password' },
    { menu: 'SIGN_OUT.LB', name: 'logout', move: false, icon: 'signout' },
    { menu: 'WITHDRAW.LB', name: 'withdraw', move: true, icon: 'withdraw' },
  ];

  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <ConfigInfoCard>
            <UserPhoto src={src} className="photo" loading={loading} />
            <div className="list">
              {infoArr.map(({ id, name, value }) => (
                <div key={`name_${id}`} className="column">
                  <div className="name">{t(name)}</div>
                  <div className="value">
                    {(loading || !value) && (
                      <Placeholder>
                        <Placeholder.Line length="short" />
                      </Placeholder>
                    )}
                    {!loading && value}
                  </div>
                </div>
              ))}
              <div className="column">
                <div className="name">{t('INTEREST.LB')}</div>
                <div className="value">
                  {interests &&
                    interests.map(
                      ({ name, _id, selected }) =>
                        selected && (
                          <StyledToggleButton
                            size="mini"
                            color="teal"
                            key={_id}
                            compact
                          >
                            {name}
                          </StyledToggleButton>
                        ),
                    )}
                </div>
              </div>
            </div>
          </ConfigInfoCard>
          <Segment style={{ padding: 0 }}>
            {menuArr.map(({ name, menu, move, icon }) => (
              <PiboLinePanelMenu
                {...{
                  key: name,
                  name,
                  menu: t(menu),
                  move,
                  icon,
                  onClick: handleItemClick,
                }}
              />
            ))}
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row />
      <Grid.Row />
    </Grid>
  );
};
export default UserSettingList;
