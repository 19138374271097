import React, { useState } from 'react';
import { useMount } from 'react-use';
import styled from 'styled-components';
import {
  Form,
  FormRadio,
  GridColumn,
  GridRow,
  Placeholder,
} from 'semantic-ui-react';
import { getFile } from '../../utils/api';

const Item = styled.div`
  position: relative;
  height: 80px;
  width: 100vw;
  padding: 10px 16px;
  filter: brightness(1);
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  margin-bottom: 1px;
`;

const ItemImage = styled.img`
  border-radius: 15px;
  width: 60px;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`;

const ItemTitle = styled.div`
  font-size: 1.25em;
  font-weight: 600;
  text-align: left;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 0;
`;

const BotSettingListItem = props => {
  const { bot, id, title, checked: c, handleUseToggleClick } = props;
  const [checked, setCheck] = useState(false);

  useMount(async () => {
    setCheck(c);
  });

  const handleClick = (e, { id: tid }) => {
    e.preventDefault();
    setCheck(!checked);
    handleUseToggleClick({ id: tid, checked: !checked });
  };
  return (
    <GridRow stretched style={{ padding: 0 }}>
      <GridColumn stretched style={{ padding: 0 }}>
        <Item style={{ width: 'inherit' }}>
          <div style={{ width: '60px', height: '60px', position: 'relative' }}>
            <Placeholder style={{ borderRadius: '15px' }}>
              <Placeholder.Image square />
            </Placeholder>
            <ItemImage src={getFile({ icon: bot })} />
          </div>
          <ItemTitle>{title}</ItemTitle>
          <Form style={{ lineHeight: 1 }}>
            <Form.Field>
              <FormRadio
                toggle
                color="blue"
                size="mini"
                id={id}
                checked={checked}
                onClick={handleClick}
              />
            </Form.Field>
          </Form>
        </Item>
      </GridColumn>
    </GridRow>
  );
};

export default BotSettingListItem;
