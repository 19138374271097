import moment from 'moment';
import React from 'react';
import { Header, Icon, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { numberWithCommas } from '../../../utils/common';
import AccordionText from './AccordionText';

const MovieCardItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  gap: 0.25em;
  margin-bottom: 0.5em;
  & div.card.content {
    display: flex;
    max-height: 180px;
    width: 100%;
    overflow: hidden;
    align-items: stretch;
    & img.ui.image {
      max-width: 40%;
      border-radius: 4px;
      flex: 0 0 auto;
      height: 180px;
      width: auto;
      margin-right: 0.25em;
    }
    & div.content {
      display: flex;
      margin-left: 0.25em;
      justify-content: space-between;
      gap: 0.25rem;
      max-height: 180px;
      flex: 1 1 auto;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex-flow: column;
      & span.date {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.875rem;
        line-height: 1.1;
      }
      & span.audience {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.875rem;
        line-height: 1.1;
        min-width: 155px;
      }
      & span.synop {
        font-size: 0.875rem;
        line-height: 1.1;
        display: block;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: pre-wrap;
        flex: 1 1 auto;
        height: 100%;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
`;

const OverlayIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(1px);
`;

const MovieCard = ({ date, list, type, thumbs, fileId, title }) => (
  <>
    {date && list && (
      <div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            gap: '.25rem',
            marginBottom: '.5rem',
            alignItems: 'center',
          }}
        >
          <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
            {`${moment(date).format('LL')} 기준`}
          </Header>
        </div>
        <AccordionText
          {...{
            height: 270,
            content: (
              <div>
                {list.map(({ date: dt, name, audience, img, synop }, i) => (
                  <MovieCardItem key={`${dt}_${name}`}>
                    <span>{`${i + 1}. ${name}`}</span>
                    <div className="card content">
                      {img && <Image src={img} />}
                      <div className="content">
                        <span className="date">{`개봉일: ${dt}`}</span>
                        <span className="audience">
                          {`누적관객수: ${numberWithCommas(audience)}명`}
                        </span>
                        {synop && <span className="synop">{synop}</span>}
                      </div>
                    </div>
                  </MovieCardItem>
                ))}
              </div>
            ),
          }}
        />
      </div>
    )}
    {type === 'youtube' && thumbs.length && fileId && (
      <a
        href={`https://www.youtube.com/watch?v=${fileId}`}
        target="_blank"
        rel="noreferrer"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column',
        }}
      >
        {title && <div style={{ fontSize: '0.875em' }}>{title}</div>}
        {thumbs[0].url && (
          <div style={{ position: 'relative' }}>
            <Image src={thumbs[0].url} size="medium" rounded />
            <OverlayIcon>
              <Icon size="huge" name="youtube" color="red" />
            </OverlayIcon>
          </div>
        )}
      </a>
    )}
  </>
);

export default MovieCard;
