import axios from 'axios';

import { API_URL } from '../../../circulusConfig';
import { encodeGetParams } from '../../../utils/api';

export const setCalendarApi = async data => {
  const res = await fetch(`${API_URL}calendarBot/setCalendar`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
};

export const getMonthDataApi = async (startDate, endDate, robotId) => {
  const res = await fetch(
    `${API_URL}calendarBot/getMonthData?${encodeGetParams({
      startDate,
      endDate,
      robotId,
    })}`,
  );
  const result = res.json();
  return result;
};

export const deleteItemsApi = async data => {
  const res = await fetch(`${API_URL}calendarBot/deleteCalendarItem`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
};

export const getRecordsApi = (skip, limit) => {
  const options = {
    method: 'GET',
    params: { skip, limit },
    url: `${API_URL}calendarBot/getRecords`,
  };
  return axios(options);
};
