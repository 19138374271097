import { combineReducers } from 'redux';
import appReducer from './app';
import botsReducer from './bots';
import connectReducer from './connect';
import findAccountsReducer from './findAccounts';
import linkReducer from './link';
import pageReducer from './page';
import piboReducer from './pibo';
import ReviewReducer from './review';
import signUpReducer from './signup';
import StoreReducer from './store';
import userReducer from './user';
import socialReducer from './social';
import userinfoReducer from './userinfo';
import photoReducer from './bots/photoBot/photo';
import historyReducer from './bots/historyBot/history';
import diaryBotReducer from './bots/diaryBot/diaryBot';
import calendarBotReducer from './bots/calendarBot/calendarBot';
import defaultBotReducer from './bots/defaultPage/defaultPage';
import trainReducer from './bots/trainBot/train';
import messageReducer from './bots/messageBot/message';

const rootReducer = combineReducers({
  app: appReducer,
  bots: botsReducer,
  connect: connectReducer,
  findAccounts: findAccountsReducer,
  link: linkReducer,
  page: pageReducer,
  pibo: piboReducer,
  review: ReviewReducer,
  signup: signUpReducer,
  store: StoreReducer,
  user: userReducer,
  social: socialReducer,
  userinfo: userinfoReducer,
  calendarBot: calendarBotReducer,
  defaultPage: defaultBotReducer,
  diaryBot: diaryBotReducer,
  history: historyReducer,
  messageBot: messageReducer,
  photo: photoReducer,
  train: trainReducer,
});

export function reducerInit() {
  return {
    type: 'ROOT_INIT',
  };
}
export default (state, action) =>
  rootReducer(action.type === 'ROOT_INIT' ? undefined : state, action);
