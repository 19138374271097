/* eslint-disable */
import axios from 'axios';
import {
  API_URL,
  ROBOT_URL,
  REVIEW_LIMIT,
  SITE_URL,
  NAPI_URL,
  RAPI_URL,
} from '../circulusConfig';
// eslint-disable-next-line import/no-cycle
import {
  asyncLoadUploadAlignedCvsImage,
  dataURItoBlob,
  getLocale,
  getMobileOS,
} from './common';
// eslint-disable-next-line import/no-cycle
import { errorLog } from './report';

const ww2 = {
  p: false,
  set setPermission(result) {
    this.p = result === 'PERMISSION_GRANTED';
  },
  get getPermission() {
    return this.p;
  },
};

export const encodeGetParams = p =>
  Object.entries(p)
    .map(kv => kv.map(encodeURIComponent).join('='))
    .join('&');

export async function requestPermissions() {
  const { WifiWizard2 } = window;
  if (WifiWizard2) {
    ww2.setPermission = await WifiWizard2.requestPermission();
  }
  return ww2.getPermission;
}

export const onDeviceBack = () => {
  // eslint-disable-next-line func-names
  window.backbutton = function(e) {
    errorLog(`back event: ${JSON.stringify(e)}`, 'onDeviceBack');
  };
};

export const getCurrentPosition = (options = {}) =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });

/* async function getLocationsList(location) {
  const res = await fetch(
    `${API_URL}pibo/getPiboLocation?${encodeGetParams({ ...location })}`,
  );
  const result = res.json();
  return result;
} */

export async function getConnectedSSID() {
  await requestPermissions();
  const { WifiWizard2 } = window;
  if (WifiWizard2 && ww2.getPermission) {
    const result = await WifiWizard2.getConnectedSSID();
    return result;
  }
  return '';
}

export async function getApList() {
  await requestPermissions();
  const { WifiWizard2 } = window;
  if (WifiWizard2 && ww2.getPermission) {
    const results = await WifiWizard2.getScanResults();
    const result = results.map(ap => {
      const { level, SSID, BSSID, capabilities } = ap;
      const wpa = capabilities
        ? capabilities.search(/(wpa)|(WPA)/, 'g') > -1
        : false;
      const encrypted = capabilities
        ? capabilities.search(/(psk)|(PSK)/, 'g') > -1
        : false;
      return {
        essid: SSID,
        address: BSSID,
        wpa,
        encrypted,
        signal: level + 100,
      };
    });
    return result;
  }
  return [];
}

export async function connectToAp(apdata) {
  try {
    const { essid, wpa, password } = apdata;
    const time = Date.now();
    // alert(`${essid}:${wpa}:${password}`);
    const res = await fetch(
      `${ROBOT_URL}confirm?id=${essid}&type=${
        wpa ? 'wpa' : 'wep'
      }&pass=${password}&time=${time}`,
    );
    return res;
  } catch (error) {
    return { result: false, data: { item: 'wifi', error } };
  }
}

/* export async function fetchBotCommandSearchResults(str, robotId = '') {
  let url = `${API_URL}bot`;
  if (robotId) {
    url += `/mybot/${robotId}/search/${str}`;
  } else {
    url += `/search/${str}`;
  }
  const res = await fetch(url);
  const result = res.json();
  errorLog(
    `fetchBotCommandSearchResults ${result}`,
    'fetchBotCommandSearchResults',
  );
  return result;
} */

// Verification.checkPiboAuth
export async function checkPiboAuth({ userId, code }) {
  const res = await fetch(
    `${API_URL}pibo/checkCode?${encodeGetParams({ userId, code })}`,
  );
  const result = res.json();
  return result;
}

// Pibo.savePiboData
export async function savePiboData(data) {
  const res = await fetch(`${API_URL}pibo`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// Pibo.getPiboData 파이보 데이터 가져오기
export async function getPiboData({ robotId, bots, locale }) {
  const res = await fetch(`${API_URL}pibo/getPiboData/${robotId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ bots, locale }),
  });
  const result = res.json();
  return result;
}

// Pibo.getPiboItemData 파이보 데이터 항목 별 가져오기
export async function getPiboItemData(data) {
  const res = await fetch(
    `${API_URL}pibo/getPiboItemData?${encodeGetParams(data)}`,
  );
  const result = res.json();
  return result;
}

// Pibo.getPiboDataList 파이보 데이터 리스트만 있는 경우 가져오기
export async function getPiboDataList(data) {
  const res = await fetch(
    `${API_URL}pibo/getPiboDataList?${encodeGetParams(data)}`,
  );
  const result = res.json();
  return result;
}

// Pibo.updatePiboData 파이보 설정 저장
export async function updatePiboData({ robotId, data }) {
  const res = await fetch(`${API_URL}pibo/updatePiboData/${robotId}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

/* export async function getLocations(robotId) {
  try {
    const res = await getPiboItemData({
      robotId,
      itme: 'location',
      locale: getLocale(),
    });
    if (res.result) {
      const {
        data: { item, value },
      } = res;
      const data = await getLocationsList(value);
      const { result, list } = data;
      if (result) {
        if ('cmd' in value) {
          const { cmd, ...rest } = value;
          return { result, data: { item, list, value: { ...rest } } };
        }
        return { result, data: { item, list, value } };
      }
    }
    return res;
  } catch (error) {
    errorLog(error);
  }
  return { result: false };
} */

export async function getGeo(robotId) {
  // eslint-disable-next-line no-use-before-define
  const res = await getPiboItemData({
    robotId,
    item: 'geo',
    locale: getLocale(),
  });
  if (res.result) {
    const { data } = res;
    const { item, list, value } = data;
    if (value && value.length < 2) {
      if (navigator.geolocation) {
        const position = await getCurrentPosition();
        const { coords } = position;
        const { latitude, longitude } = coords;
        return {
          result: true,
          data: { item, list, value: [latitude, longitude] },
        };
      }
      return { result: true, data: { item, list, value: [] } };
    }
  }
  return res;
}

export async function initPibo({ robotId }) {
  const res = await fetch(`${API_URL}pibo/init`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ robotId }),
  });
  const result = res.json();
  return result;
}

export async function disconnectPibo({ userId, robots }) {
  const res = await fetch(`${API_URL}pibo/disconnect`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, robots }),
  });
  const result = res.json();
  return result;
}

// Bot.botVerify 설치 된 봇인지 확인하기
export async function botVerify({ robotId, projectId, botId }) {
  const res = await fetch(
    `${API_URL}botVerify?${encodeGetParams({
      robotId,
      projectId,
      botId: botId || '',
    })}`,
  );
  const result = res.json();
  return result;
}

// Bot.getAllBots 모든 봇 정보 가져오기
// export async function getAllBots() {
//   const res = await fetch(`${API_URL}bot`);
//   const result = res.json();
//   return result;
// }

// Bot.getBotsByCategory 카테고리 별 봇 정보 가져오기
// export async function getBotsByCategory(category, robotId) {
//   const res = await fetch(`${API_URL}bot/category`, {
//     method: 'POST',
//     headers: {
//       Accept: 'application/json, text/plain, */*',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ category, robotId }),
//   });
//   const result = res.json();
//   return result;
// }

// Bot.getAllMyBots 내 봇 리스트 가져오기
export async function getAllMyBots(locale, robotId, detail, use) {
  // const url = `${API_URL}bot/mybot/${locale}/${robotId}?detail=${detail}`;
  const url = `${API_URL}bot/mybot?${encodeGetParams({
    locale,
    robotId,
    detail,
    use,
  })}`;
  const res = await fetch(url);
  const result = res.json();
  return result;
}

// Bot.addBot 봇 설치
export async function addBot(data) {
  // data = {robotId, botId}
  const url = `${API_URL}bot/mybot/add`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data }),
  };
  const res = await fetch(url, options);
  const result = res.json();
  return result;
}

// Bot.updateBotUsability 로봇의 봇 사용여부 변경
export async function updateBotUsability(robotId, botId, use) {
  const res = await fetch(`${API_URL}bot/mybot/update`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ robotId, botId, use }),
  });
  const result = res.json();
  return result;
}

// Bot.removeBot 로봇의 봇 사용여부 변경
export async function removeBot(data) {
  const { robotId, botId } = data;
  const res = await fetch(`${API_URL}bot/mybot/remove`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ robotId, botId }),
  });
  return res.json();
}

// Bot.getBotsSearchResult Bot.getMyBotsSearchResult 봇 검색 결과 가져오기
export async function fetchBotSearchResults({ str, locale, robotId, use }) {
  let url = `${API_URL}bot`;
  if (robotId) {
    url += `/mybot/search?${encodeGetParams({
      robotId,
      str,
      locale,
      use,
    })}`;
  } else {
    url += `/search?${encodeGetParams({ str, locale })}`;
  }
  const res = await fetch(url);
  const result = res.json();
  return result;
}

// Botstore.getStoreMainData 봇스토어 메인 데이터 가져오기
export async function getStoreMainData(robotId, locale, page, str = '') {
  let n = 1;
  const w = parseInt(window.innerWidth);
  if (w > 2000) {
    n = 4;
  } else if (w > 1200) {
    n = 3;
  } else if (w > 500) {
    n = 2;
  } else {
    n = 1;
  }
  const h = parseInt(window.innerHeight / 180);
  n = n * h > 8 ? n * h : 8;
  const url = `${API_URL}store?${encodeGetParams({
    robotId,
    str,
    locale,
    page,
    n,
  })}`;
  const res = await fetch(url);
  return res.json();
}

// Botstore.fetchBotDetail 봇 상세 정보 가져오기
export async function fetchBotDetail({ botId, robotId, locale }) {
  const res = await fetch(`${API_URL}store/detail/${botId}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ robotId, locale }),
  });
  const result = res.json();
  return result;
}

// Botstore.getUserReviewData
export async function getUserReviewData({ botId, userId }) {
  const res = await fetch(
    `${API_URL}store/review/${userId || 'pibo'}?botId=${botId}`,
  );
  const result = res.json();
  return result;
}

// Botstore.saveReviewData
export async function saveReviewData(review) {
  const res = await fetch(`${API_URL}store/review`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(review),
  });
  const result = res.json();
  return result;
}
// Botstore.updateReviewData
export async function updateReviewData(review) {
  const res = await fetch(`${API_URL}store/review`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(review),
  });
  const result = res.json();
  return result;
}
// Botstore.deleteReviewData
export async function deleteReviewData(review) {
  const res = await fetch(`${API_URL}store/review`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(review),
  });
  const result = res.json();
  return result;
}

// Botstore.getReviewData
export async function getReviewData({
  botId,
  userId,
  skip = 0,
  limit = REVIEW_LIMIT,
}) {
  const res = await fetch(
    `${API_URL}store/review?${encodeGetParams({
      botId,
      userId,
      skip,
      limit,
    })}`,
  );
  const result = res.json();
  return result;
}

// User.getUserInterest
export async function getUserInterest(locale) {
  const res = await fetch(
    `${API_URL}user/getInterest?${encodeGetParams({ locale })}`,
  );
  const result = res.json();
  return result;
}

// User.getUserData
export async function getUserData(userId) {
  if (userId) {
    const res = await fetch(`${API_URL}user?${encodeGetParams({ userId })}`);
    const result = res.json();
    return result;
  }
  return { result: false, data: null };
}

// User.checkUserId
export async function checkUserId(userId) {
  const res = await fetch(
    `${API_URL}user/checkId?${encodeGetParams({ userId })}`,
  );
  const result = res.json();
  return result;
}

// User.checkUserEmail - *사용x
export async function checkUserEmail(email) {
  const res = await fetch(
    `${API_URL}user/checkMail?${encodeGetParams({ email })}`,
  );
  const result = res.json();
  return result;
}

// User.updateUserData
export async function updateUserData(userId, data) {
  const res = await fetch(`${API_URL}user/update`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, ...data }),
  });
  const result = res.json();
  return result;
}

export async function updateUserImageData({ imageFile, userId }) {
  const formData = new FormData();
  const image = await asyncLoadUploadAlignedCvsImage(imageFile[0]);
  const dataUrl = image.toDataURL('image/png');
  const blob = dataURItoBlob(dataUrl);
  formData.append('file', blob);
  formData.append('userId', userId);
  const result = await axios({
    url: `${API_URL}user/updateimage`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'cache-control': 'no-cache',
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
    },
    data: formData,
  });
  return result;
}

// User.updateUserItemData
export async function updateUserItemData(userId, data) {
  const res = await fetch(`${API_URL}user/updateitem`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, ...data }),
  });
  const result = res.json();
  return result;
}

export async function updateUserImage(userId, data) {
  const res = await fetch(`${API_URL}user/updateimage`, {
    method: 'POST',
    body: data,
  });
  const result = res.json();
  return result;
}

// User.updatePassword
export async function updateUserPW({ userId, oldPw, newPw }) {
  const res = await fetch(`${API_URL}user/updatePW`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, oldPw, newPw }),
  });
  const result = res.json();
  return result;
}

// User.forcedUpdatePassword
// export async function updateForcedUserPW({ userId, newPw }) {
//   const res = await fetch(`${API_URL}user/forcedUpdatePW`, {
//     method: 'PUT',
//     headers: {
//       Accept: 'application/json, text/plain, */*',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ userId, newPw }),
//   });
//   const result = res.json();
//   return result;
// }

// User.withdraw
export async function userWithdraw(data) {
  const res = await fetch(`${API_URL}user/withdraw`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// 인증 메일 보내는 부분
export async function sendCertCode({ email, tel, type, userId, userOId }) {
  const res = await fetch(
    `${API_URL}user/sendVerification?${encodeGetParams({
      tel,
      email,
      type,
      userId,
      userOId,
    })}`,
  );
  const result = res.json();
  return result;
  // const url = `${RAPI_URL}verify?userId=${userId}&email=${email}`;
  // const res = await Axios.jsonp(url, {
  //   headers: {
  //     'x-client-type': '70ff9f33926b1865edde645073a37bd1fffba984',
  //     'x-client-id': userId,
  //   },
  // });
  // const { result } = res;
  // if (!result) {
  //   return { result };
  // }
  // return res;
}

// 인증 메일 확인 시 검증
export async function confirmCertCode({ code, type, userOId }) {
  const url = `${API_URL}user/verify/${type}/${code}?${encodeGetParams({
    userOId,
  })}`;
  const res = await fetch(url);
  const result = res.json();
  return result;
}

// 인증 메일 확인 시 검증
export async function verifyingEmail({ type, serial, code }) {
  const url = code
    ? `${API_URL}user/verification/${type}/${serial}?${encodeGetParams({
        code,
      })}`
    : `${API_URL}user/verification/${type}/${serial}`;
  const res = await fetch(url);
  const result = res.json();
  return result;
}

// 이메일 인증 상태 검증
export async function verifyCertification({ userId, email }) {
  const res = await fetch(
    `${API_URL}user/verifyUser?${encodeGetParams({ userId, email })}`,
  );
  const result = res.json();
  return result;
}

// User.tempUserDelete
export async function tempUserDelete(userPId) {
  const res = await fetch(`${API_URL}user/tempUserDelete`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userPId }),
  });
  const result = res.json();
  return result;
}

// User.signUp
export async function userSignUp(userdata) {
  const res = await fetch(`${API_URL}user/signup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  const result = res.json();
  return result;
}

export async function userSocialSignIn(userdata) {
  const res = await fetch(`${API_URL}user/socialsignin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  const result = res.json();
  return result;
}

export async function userSocialSignUp(userdata) {
  const res = await fetch(`${API_URL}user/socialsignup`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userdata),
  });
  const result = res.json();
  return result;
}

// User.signOut
export async function userSignOut(logindata) {
  const res = await fetch(`${API_URL}user/signout`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logindata),
  });
  const result = res.json();
  return result;
}

// User.signIn
export async function userSignIn(logindata) {
  const res = await fetch(`${API_URL}user/signin`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(logindata),
  });
  const result = res.json();
  return result;
}

// User.verify
export async function userVerify(token) {
  const res = await fetch(`${API_URL}user/verify/check`, {
    method: 'GET',
    headers: {
      'x-access-token': token,
    },
  });
  const result = res.json();
  return result;
}

// User.verifyToken
export async function userVerifyToken(token) {
  const res = await fetch(`${API_URL}user/verifyToken`, {
    method: 'GET',
    headers: {
      'x-access-token': token,
    },
  });
  const result = res.json();
  return result;
}

// App.sendUserOpinion
export async function sendUserOpinion(data) {
  const res = await fetch(`${API_URL}app/opinion`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// App.getCSDataByCategory
export async function getCSDataByCategory(category = 'all', locale = 'ko') {
  const res = await fetch(`${API_URL}app/cs/${locale}/${category}`);
  const result = res.json();
  return result;
}

// App.getCSDataSearchResult
export async function getCSDataSearchResult(str, locale = 'ko') {
  const res = await fetch(
    `${API_URL}app/cs/search?${encodeGetParams({ locale, searchStr: str })}`,
  );
  const result = res.json();
  return result;
}

// App.getCSDataSearchResult
export async function getNotice() {
  const res = await fetch(`${API_URL}app/notice`);
  const result = res.json();
  return result;
}

// App.getLatestNotice
export async function getLatestNotice() {
  const res = await fetch(`${API_URL}app/latest`);
  const result = res.json();
  return result;
}

// Pibo.getPiboVersion
export async function getPiboVersion(robotId) {
  const res = await fetch(
    `${API_URL}pibo/version?${encodeGetParams({ robotId })}`,
  );
  const result = res.json();
  return result;
}
// Pibo.setPiboVersion
export async function setPiboVersion({ robotId, version }) {
  const res = await fetch(`${API_URL}pibo/version`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ robotId, version }),
  });
  const result = res.json();
  return result;
}

// Pibo.getInstDataSearchResult
export async function getInstDataSearchResult({ value, robotId, locale }) {
  const res = await fetch(
    `${API_URL}pibo/instruction/search?${encodeGetParams({
      locale,
      robotId,
      searchStr: value,
    })}`,
  );
  const result = res.json();
  return result;
}

// Pibo.getDisturbConfig
export async function getDisturbConfig({ robotId }) {
  const res = await fetch(
    `${API_URL}pibo/disturb?${encodeGetParams({ robotId })}`,
  );
  const result = res.json();
  return result;
}

// Pibo.setDisturbConfig
export async function setDisturbConfig(data) {
  const res = await fetch(`${API_URL}pibo/disturb`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// User.findAccount
export async function findAccount(data) {
  const res = await fetch(
    `${API_URL}user/findAccount?${encodeGetParams(data)}`,
  );
  const result = res.json();
  return result;
}

// User.reSetPassword
export async function resetPassword(data) {
  const res = await fetch(`${API_URL}user/resetPassword`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// email 인증 정합성 여부 체크
export async function verifyAccountCert(hash, cert, type) {
  const res = await fetch(
    `${API_URL}user/verification/${type}/${hash}?code=${cert}`,
  );
  const result = res.json();
  return result;
}

// 인증 email 발송
// export async function sendAccountVerification(data) {
//   const res = await fetch(
//     `${API_URL}user/sendSearchVerify?${encodeGetParams(data)}`,
//   );
//   const result = res.json();
//   return result;
// }
const URL = window.URL || window.webkitURL;
// Get data from the cache.
async function getCachedData(cacheName, url, audio) {
  const cacheStorage = await caches.open(cacheName);
  const cachedResponse = await cacheStorage.match(url);

  if (!cachedResponse || !cachedResponse.ok) {
    const res = await fetch(url);
    if (res.ok) {
      await cacheStorage.add(url);
      let data;
      if (audio) {
        data = await res.arrayBuffer();
        data = new Blob([data], { type: 'audio/mpeg' });
      } else {
        data = await res.blob();
      }
      return { cached: false, file: URL.createObjectURL(data) };
    }
    if (url.indexOf('diary') > -1) {
      return null;
    }
    return { cached: false, file: `${SITE_URL}image/img_thumb.png` };
  }

  // eslint-disable-next-line no-return-await
  const data = await cachedResponse.blob();
  return { cached: true, file: URL.createObjectURL(data) };
}

export async function checkValidForReplaceEmail({ hash, email }) {
  const res = await fetch(
    `${API_URL}replaceValidCheck?${encodeGetParams({ hash, email })}`,
  );
  const result = res.json();
  return result;
}

export async function signInForReplaceEmail({ id, pw }) {
  const res = await fetch(`${API_URL}replaceSignIn`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id, pw }),
  });
  const result = res.json();
  return result;
}

export async function sendMailForReplaceEmail({ userId, email }) {
  const res = await fetch(
    `${API_URL}replaceSend?${encodeGetParams({ userId, email })}`,
  );
  const result = res.json();
  return result;
}

async function deleteOldCaches(currentCache) {
  const keys = await caches.keys();

  keys.forEach(key => {
    const isOurCache = key.substr(0, 7) === 'assets-';

    if (isOurCache && currentCache !== key) {
      caches.delete(key);
    }
  });
}

// TrainController.saveTrain
export async function saveTrain(data) {
  const res = await fetch(`${API_URL}trainBot/setTrainData`, {
    method: 'POST',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// TrainController.updateTrainData
export async function updateTrain(data) {
  const res = await fetch(`${API_URL}trainBot/updateTrainData`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
}

// TrainController.getTrainData
export async function getTrain({ robotId, skip, limit }) {
  const res = await fetch(
    `${API_URL}trainBot/getTrainData?${encodeGetParams({
      skip,
      limit,
      robotId,
    })}`,
  );
  const result = res.json();
  return result;
}

// TrainController.getTrainData
export async function checkCommand({ robotId, q }) {
  const res = await fetch(
    `${API_URL}trainBot/checkCommand?${encodeGetParams({
      q,
      robotId,
    })}`,
  );
  const result = res.json();
  return result;
}

export async function checkQuestion({ robotId, q }) {
  //candidate?q=테스트를%20수행하자&robotId=61121413837e2600127523e2&lang=ko
  const res = await fetch(
    `${NAPI_URL}candidate?${encodeGetParams({
      q,
      robotId,
      lang: 'ko',
    })}`,
  );
  const result = res.json();
  return result;
}

// CAPI.getList music
export async function getContentList(type) {
  const url = `${API_URL}trainBot/getMedia?${encodeGetParams({ type })}`;
  const res = await fetch(url);
  const result = res.json();
  return result;
}

export function getFile(data) {
  const [[key, v]] = Object.entries(data);
  if (
    ['thumb', 'photo', 'userPhoto', 'icon', 'diary', 'media'].indexOf(key) > -1
  ) {
    if (v) {
      return `${RAPI_URL}${key}/${v}`;
    }
  }
  return null;
  // if (!data) return `${SITE_URL}image/img_thumb.png`;
  // const cacheName = `assets-${publishDate}`;
  // const url = `${API_URL}file?${encodeGetParams(data)}`;
  // const exceptCase =
  //   getMobileOS() === 'iOS' && (key === 'diary' || key === 'userPhoto');
  // let file;
  // try {
  //   if (window.caches && !exceptCase) {
  //     const cachedData = await getCachedData(cacheName, url, 'diary' in data);
  //     if (cachedData) {
  //       const { cached, file: f } = cachedData;
  //       if (cached) {
  //         await deleteOldCaches(cacheName);
  //       }
  //       return f;
  //     }
  //   }

  //   const res = await fetch(url);
  //   if (data && 'diary' in data) {
  //     file = await res.arrayBuffer();
  //     file = new Blob([file], { type: 'audio/mpeg' });
  //   } else {
  //     file = res.blob();
  //   }
  //   return URL.createObjectURL(file);
  // } catch (error) {
  //   if (data && 'diary' in data) {
  //     return null;
  //   }
  //   return `${SITE_URL}image/img_thumb.png`;
  // }
}

export const deleteHistoryItemApi = async ({ _id, q, a, robotId }) => {
  const res = await fetch(`${API_URL}historyBot/deleteHistoryItem`, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ _id, q, a, robotId }),
  });
  const result = res.json();
  return result;
};

export const getHistoryApi = async (robotId, skip, limit) => {
  const res = await fetch(
    `${API_URL}historyBot/getDialogData?${encodeGetParams({
      skip,
      limit,
      robotId,
    })}`,
  );
  const result = res.json();
  return result;
};

export const attachHistoryApi = async (robotId, firstTime) => {
  const res = await fetch(
    `${API_URL}historyBot/attachDialogData?${encodeGetParams({
      robotId,
      firstTime,
    })}`,
  );
  const result = res.json();
  return result;
};

export const getPrevHistoryApi = async ({
  robotId,
  firstTime,
  skip,
  limit,
}) => {
  const res = await fetch(
    `${API_URL}historyBot/prev?${encodeGetParams({
      robotId,
      firstTime,
      skip,
      limit,
    })}`,
  );
  const result = res.json();
  return result;
};

export const getLatestHistoryApi = async ({ robotId, firstTime }) => {
  const res = await fetch(
    `${API_URL}historyBot/latest?${encodeGetParams({
      robotId,
      firstTime,
    })}`,
  );
  const result = res.json();
  return result;
};

export const deleteMessageItemApi = async ({ _id }) => {
  const res = await fetch(`${API_URL}messageBot/deleteMessageItem`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ _id }),
  });
  const result = res.json();
  return result;
};

export const getMessagesApi = async (robotId, skip, limit) => {
  const res = await fetch(
    `${API_URL}messageBot/getMessagesData?${encodeGetParams({
      skip,
      limit,
      robotId,
    })}`,
  );
  const result = res.json();
  return result;
};

export const setMessageApi = async ({
  robotId,
  value,
  mode,
  type,
  fileName,
  blob,
}) => {
  const payload = new FormData();
  payload.append('robotId', robotId);
  payload.append('value', value);
  payload.append('mode', mode);
  payload.append('type', type);
  if (fileName && blob) {
    payload.append('file', blob, fileName);
  }
  const result = await axios({
    url: `${API_URL}messageBot/setMessage`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      'cache-control': 'no-cache',
      processData: false,
      contentType: false,
      mimeType: 'multipart/form-data',
    },
    data: payload,
  });
  return result;
};

// export async function setLog(data) {
//   try {
//     await fetch(`${DAPI_URL}mq`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json, text/plain, */*',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     });
//   } catch (e) {

//     console.log('setLog Error', e);
//   }
// }

// export function setReport(data) {
//   try {
//     fetch(`${DAPI_URL}report`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json, text/plain, */*',
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify(data),
//     });
//   } catch (e) {
//     console.log('setReport Error', e);
//   }
// }

// export async function getStatus() {
//   try {
//     const res = await fetch(`${DAPI_URL}cache`);
//     const result = await res.json();
//     return result;
//   } catch (error) {
//     return false;
//   }
// }
