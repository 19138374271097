import React, { useRef } from 'react';
import { Search, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const TealSearch = styled(Search)`
  &.ui.search {
    font-size: 0.875rem;
    margin: 1em 1em 0.5em;
    & i {
      // color: #03bfd7;
      color: #0894d7;
    }
  }
  & .ui.input {
    & > input {
      // border: 1px solid #03bfd7;
      border: 1px solid #0894d7;
      padding: 0.45em 1em;
    }
  }
`;

const StoreSearch = props => {
  const { t } = useTranslation('store');
  const searchRef = useRef(null);
  const {
    isLoading,
    value,
    handleSearchResult,
    handleSearchChange,
    handleSearchRemove,
    searchResult,
  } = props;

  const handleKeyUp = event => {
    if (!searchResult) return;
    if (event.keyCode === 13 && event.key === 'Enter') {
      event.target.blur();
    }
  };

  const onRemove = () => {
    searchRef.current.setValue('');
    handleSearchRemove();
  };

  return (
    <TealSearch
      ref={searchRef}
      open={false}
      loading={isLoading}
      onResultSelect={handleSearchResult}
      onSearchChange={handleSearchChange}
      placeholder={t('SEARCH.ENTER_WORD')}
      input={{
        fluid: true,
        input: {
          onKeyUp: handleKeyUp,
          type: 'search',
        },
      }}
      icon={
        value && value.length ? (
          <Icon name="delete" link onClick={onRemove} />
        ) : (
          <Icon name="search" />
        )
      }
      fluid
    />
  );
};

export default StoreSearch;
