import React from 'react';
import { Icon, Image } from 'semantic-ui-react';
// import { useTranslation } from 'react-i18next';
import Card from './Card';

const Oled = props => {
  const oledList = [
    'happy',
    'sad',
    'angry',
    'surprise',
    'excite',
    'trust',
    'fear',
    'boring',
  ];
  // const { t } = useTranslation('bot');
  const { oled, playing, onOledChange } = props;
  return (
    <Card
      title="OLED 선택"
      flow="column"
      popupContent={{
        header: 'OLED 화면 선택',
        content:
          '입력한 텍스트와 함께 파이보 가슴 부분의 OLED 디스플레이에 보여지는 화면을 선택할 수 있습니다.',
      }}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {oledList.map(v => (
          <div style={{ position: 'relative', transform: 'scale(.85)' }}>
            {oled === v && (
              <Icon
                name="check circle"
                style={{
                  position: 'absolute',
                  top: '2px',
                  left: '2px',
                  zIndex: 10,
                }}
                color="teal"
              />
            )}
            <Image
              src={`/image/oled/${v}.jpg`}
              style={{ borderRadius: '6px' }}
              onClick={() => !playing && onOledChange(v)}
            />
          </div>
        ))}
      </div>
    </Card>
  );
};

export default Oled;
