import React, { useEffect, useState } from 'react';
import * as moment from 'moment';
import styled from 'styled-components';
import shortId from 'shortid';
import { Grid, Form, FormRadio, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { WEEK_DAYS } from '../../../utils/consts';
import {
  FormGroup,
  FormLabel,
  UnderLinedInput,
  WeekdayButton,
} from '../../Components';

const ErrorMessage = styled.div`
  margin: 0.5rem 0;
  text-align: left;
  color: #ef3340;
  font-weight: 600;
  font-size: 0.85rem;
`;

const PiboDisturbSetting = props => {
  const { t } = useTranslation('pibo');
  const { value: propValue, errorMsg, handleChange } = props;
  const [value, setValue] = useState({
    toggle: false,
    day: [],
    startTime: '00:00',
    endTime: '00:00',
    error: null,
  });
  const { toggle, day, startTime, endTime, error } = value;

  const changeValue = v => {
    setValue(v);
    handleChange(v);
  };

  useEffect(() => {
    if (propValue && typeof propValue === 'object' && '_id' in propValue) {
      const {
        repeat,
        execTime,
        expTime,
        repeatValue: {
          value: { day: d },
        },
      } = propValue;
      changeValue({
        toggle: repeat,
        day: d,
        startTime: moment(execTime).format('HH:mm'),
        endTime: moment(expTime).format('HH:mm'),
      });
    }
  }, [propValue]);

  const onChange = e => {
    const { name, value: v } = e.target;
    return changeValue({ ...value, [name]: v, error: '' });
  };

  const handleWeekdayClick = e => {
    const { name } = e.target;
    const numericItem = parseInt(name, 10);
    const findIndex = day.findIndex(item => item === numericItem);
    if (findIndex < 0) {
      return changeValue({
        ...value,
        day: [numericItem].concat(day).sort(),
      });
    }
    const newDays = day
      .slice(0, findIndex)
      .concat(day.slice(findIndex + 1, day.length))
      .sort();
    return changeValue({
      ...value,
      day: newDays,
    });
  };

  const handleCommandToggle = (e, { checked }) => {
    if (!checked) {
      return changeValue({ ...value, day: [], toggle: checked });
    }
    return changeValue({ ...value, toggle: checked });
  };

  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <Segment>
            <Form>
              <FormGroup
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 0,
                }}
              >
                <FormLabel>{t('SET.AUTO_DISTURB')}</FormLabel>
                <FormRadio
                  name="toggle"
                  color="blue"
                  size="mini"
                  checked={toggle}
                  toggle
                  onChange={handleCommandToggle}
                />
              </FormGroup>
            </Form>
          </Segment>
          <div
            style={{
              fontSize: '0.875rem',
              padding: '0 0.5em',
              lineHeight: '1.15em',
            }}
          >
            {t('SET.MSG_DISTURB')}
          </div>
          <Segment disabled={!toggle}>
            <Form>
              <FormGroup>
                <FormLabel>{t('SET.SET_DAY')}</FormLabel>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '1rem',
                  }}
                >
                  {WEEK_DAYS &&
                    WEEK_DAYS.map(({ name, key }) => (
                      <WeekdayButton
                        key={shortId.generate()}
                        name={key}
                        onClick={handleWeekdayClick}
                        active={day ? day.includes(key) : false}
                        disabled={!toggle}
                      >
                        {t(name)}
                      </WeekdayButton>
                    ))}
                </div>
              </FormGroup>
              <FormGroup
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: '0.5rem',
                }}
              >
                <FormLabel style={{ minWidth: '3rem' }}>
                  {t('SET.START')}
                </FormLabel>
                <UnderLinedInput
                  name="startTime"
                  value={startTime}
                  type="time"
                  onChange={onChange}
                  disabled={!toggle}
                />
              </FormGroup>
              <FormGroup
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 0,
                }}
              >
                <FormLabel style={{ minWidth: '3rem' }}>
                  {t('SET.END')}
                </FormLabel>
                <UnderLinedInput
                  name="endTime"
                  value={endTime}
                  type="time"
                  onChange={onChange}
                  disabled={!toggle}
                />
              </FormGroup>
              {((error && error.length > 0) ||
                (errorMsg && errorMsg.length > 0)) && (
                <ErrorMessage>{error || errorMsg}</ErrorMessage>
              )}
            </Form>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PiboDisturbSetting;
