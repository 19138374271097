/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import shortid from 'shortid';
import {
  deleteHistoryItemApi,
  getLatestHistoryApi,
  getPrevHistoryApi,
} from '../../../utils/api';
import i18 from '../../../lang/i18n';

const reducerName = 'history';
const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

export const updateHistoryItem = createAsyncThunk(
  `${reducerName}/UPDATE`,
  async ({ data }, { getState, rejectWithValue }) => {
    try {
      const { historyData: oldHistoryData, length } = getState().history;
      const newData = data;
      const date = moment(newData.firstTime);
      const dateString = date.format('YYYY-MM-DD');
      newData.lastTime = date;
      newData.firstTime = date;
      if (!data.id) {
        newData.uuid = shortid.generate();
      }
      let historyData = [];
      const firstLoad = length === 0;
      if (firstLoad) {
        historyData = [{ [dateString]: [newData] }];
      } else if (newData) {
        const keyIndex = oldHistoryData.findIndex(oItem => dateString in oItem);
        historyData = [...oldHistoryData];
        if (keyIndex > -1) {
          const oldArr = oldHistoryData[keyIndex][dateString];
          const newArr = [newData].concat(oldArr);
          historyData.splice(keyIndex, 1, { [dateString]: newArr });
        } else {
          historyData.unshift({ [dateString]: [newData] });
        }
      } else {
        historyData = [...oldHistoryData];
      }
      return { historyData };
    } catch (error) {
      return rejectWithValue();
    }
  },
);

export const deleteHistoryItem = createAsyncThunk(
  `${reducerName}/DELETE`,
  async ({ q, a, uuid, id, robotId }, { getState, rejectWithValue }) => {
    try {
      const { historyData, length } = getState().history;
      const newHistoryData = historyData.map(obj => {
        const [[date, item]] = Object.entries(obj);
        const newItem = item.filter(
          ({ uuid: itemUUID, id: itemId }) =>
            uuid !== itemUUID || id !== itemId,
        );
        return { [date]: newItem };
      });
      const newData = [...newHistoryData];
      let response = null;
      if (id) {
        response = await deleteHistoryItemApi({ _id: id });
      } else {
        response = await deleteHistoryItemApi({
          q,
          a,
          robotId,
        });
      }
      const { result, error } = response;
      if (result) {
        return { historyData: newData, length: length - 1 };
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:HST.DELETE.FAILED'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:HST.DELETE.FAILED'),
      );
    }
  },
);

export const getHistory = createAsyncThunk(
  `${reducerName}/FETCH`,
  async ({ robotId }, { getState, rejectWithValue }) => {
    try {
      const {
        historyData: oldHistoryData,
        length: oldLength,
      } = getState().history;
      const firstLoad = oldHistoryData.length === 0;
      let firstTime = new Date().valueOf();
      if (!firstLoad) {
        const oldHLen = oldHistoryData.length;
        const oldLastItem = oldHistoryData[oldHLen - 1];
        const [oldValues] = Object.values(oldLastItem);
        firstTime = oldValues[oldValues.length - 1].firstTime.valueOf();
      }
      const response = await getPrevHistoryApi({
        robotId,
        firstTime,
        skip: 0,
        limit: 10,
      });
      const { data, result, error } = response;
      if (result) {
        let length = oldLength;
        let historyData = [];
        if (firstLoad) {
          historyData = data.map(dataItem => {
            const [[k, v]] = Object.entries(dataItem);
            length += v.length;
            return { [k]: v };
          });
        } else if (data.length > 0) {
          historyData = data.reduce((old, dataItem) => {
            const newOne = [...old];
            const [[k, v]] = Object.entries(dataItem);
            length += v.length;
            const keyIndex = old.findIndex(oItem => k in oItem);
            if (keyIndex > -1) {
              const oldArr = old[keyIndex][k];
              const newArr = oldArr.concat(v);
              newOne.splice(keyIndex, 1, { [k]: newArr });
            } else {
              newOne.push({ [k]: v });
            }
            return newOne;
          }, oldHistoryData);
        } else {
          historyData = [...oldHistoryData];
        }
        return { length, historyData, bBottom: false };
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:HST.LOAD.FAILED'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:HST.LOAD.FAILED'),
      );
    }
  },
);

export const attachHistory = createAsyncThunk(
  `${reducerName}/ATTACH`,
  async ({ robotId }, { getState, rejectWithValue }) => {
    try {
      const {
        historyData: oldHistoryData,
        length: oldLength,
      } = getState().history;
      let firstTime = new Date().valueOf();
      const oldFirstItem = oldHistoryData[0];
      const [oldValues] = Object.values(oldFirstItem);
      firstTime =
        oldValues && oldValues.length > 0
          ? oldValues[0].firstTime.valueOf()
          : new Date().valueOf();
      const response = await getLatestHistoryApi({ robotId, firstTime });
      const { data, result, error } = response;
      let length = oldLength;
      let historyData = [];
      if (result) {
        if (data.length > 0) {
          historyData = data.reduce((old, dataItem) => {
            const newOne = [...old];
            const [[k, v]] = Object.entries(dataItem);
            length += v.length;
            const keyIndex = old.findIndex(oItem => k in oItem);
            if (keyIndex > -1) {
              const oldArr = old[keyIndex][k];
              const newArr = v.concat(oldArr);
              newOne.splice(keyIndex, 1, { [k]: newArr });
            } else {
              newOne.unshift({ [k]: v });
            }
            return newOne;
          }, oldHistoryData);
        } else {
          historyData = [...oldHistoryData];
        }
        return { length, historyData, bBottom: true };
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(null), 'bot:HST.LOAD.FAILED'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:HST.LOAD.FAILED'),
      );
    }
  },
);

const initialState = {
  initDataStatus: false,
  pending: false,
  error: false,
  length: 0,
  historyData: [],
  sendHistoryPending: false,
  sendHistoryError: false,
  updateHistoryPending: false,
  message: '',
  bBottom: true,
};

const historySlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
    setPending: state => ({ ...state, pending: true }),
    getInitData: state => ({
      ...state,
      length: 0,
      historyData: [],
      initDataStatus: !state.initDataStatus,
      bBottom: false,
    }),
    unsetBottom: state => ({ ...state, bBottom: false }),
    sendPending: (state, action) => ({
      ...state,
      sendHistoryPending: true,
      sendHistoryError: false,
      ...action.payload,
    }), // message
    sendFailure: (state, action) => ({
      ...state,
      sendHistoryPending: false,
      ...action.payload,
    }), // sendHistoryError, message
  },
  extraReducers: {
    [getHistory.pending.type]: state => ({
      ...state,
      pending: true,
      error: false,
    }),
    [getHistory.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [getHistory.rejected.type]: state => ({
      ...state,
      pending: false,
      error: true,
    }),
    [attachHistory.pending.type]: state => ({
      ...state,
      bBottom: null,
      pending: true,
      error: false,
    }),
    [attachHistory.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [attachHistory.rejected.type]: state => ({
      ...state,
      pending: false,
      error: true,
    }),
    [deleteHistoryItem.pending.type]: state => ({
      ...state,
      pending: true,
      error: false,
    }),
    [deleteHistoryItem.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [deleteHistoryItem.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
    [updateHistoryItem.pending.type]: state => ({
      ...state,
      updateHistoryPending: true,
      error: false,
      bBottom: null,
    }),
    [updateHistoryItem.fulfilled.type]: (state, action) => ({
      ...state,
      sendHistoryPending: false,
      sendHistoryError: null,
      updateHistoryPending: false,
      message: '',
      bBottom: true,
      length: state.length + 1,
      ...action.payload,
    }),
    [updateHistoryItem.rejected.type]: state => ({
      ...state,
      bBottom: true,
      updateHistoryPending: false,
    }),
  },
});

const { reducer: historyReducer, actions } = historySlice;
export const {
  init,
  initStatus,
  getInitData,
  unsetBottom,
  sendPending,
  sendFailure,
  setPending,
} = actions;

export default historyReducer;
