import React from 'react';
import { Header, Button, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
  height: calc(100% - 2rem);
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  justify-content: center;
  background: #fff;
  margin: 1rem;
  /* color: #fff; */
  & > .ui.icon.header {
    /* color: #fff;
    text-shadow: 0px 0px 1px white, 0 0 1em rgba(234,236,238,.15), 0 0 0.2em rgba(34,36,38,.35) !important; */
  }
`;

const GoButton = styled(Button)`
  &&&& {
    margin: 1rem;
    box-shadow: 0 0 0 2px #fff;
    /* text-shadow: 0px 0px 1px white, 0 0 1em rgba(34,36,38,.15), 0 0 0.2em rgba(34,36,38,.35) !important; */
    /* color: #fff !important; */
  }
`;

const NotFound = props => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { message } = props;
  return (
    <div
      style={{
        width: '100vw',
        height: `${window.innerHeight}px`,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Wrapper>
        <Header icon>
          {message || t('NOT_FOUND')}
          <Image
            src="/image/error_icon_grey_210823.png"
            style={{ margin: '1.5rem 20% 0', width: '60%' }}
          />
        </Header>
        <GoButton color="blue" onClick={() => navigate('/')}>
          {t('MOVE_TO_MAIN')}
        </GoButton>
      </Wrapper>
    </div>
  );
};

export default NotFound;
