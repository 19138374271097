import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { RingButtonGroup, RingButtonItem, RingCutOut } from './Component';

const Move = props => {
  const { t } = useTranslation('bot');
  const {
    preventState,
    forward,
    backward,
    left,
    right,
    onControllerHandler,
  } = props;
  return (
    <Card
      title={t('AVA.CTRL.LB')}
      popupContent={{
        header: t('AVA.CTRL.MOTION'),
        content: t('AVA.CTRL.GUIDE'),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          margin: '1rem 0.5rem 1rem 1rem',
          position: 'relative',
        }}
      >
        <RingButtonGroup>
          <RingButtonItem
            className="forward"
            active={forward}
            disabled={!forward && preventState}
            onClick={() => onControllerHandler('forward')}
          >
            <div>{t('AVA.FORWARD')}</div>
          </RingButtonItem>
          <RingButtonItem
            className="right"
            active={right}
            disabled={!right && preventState}
            onClick={() => onControllerHandler('right')}
          >
            <div>{t('AVA.RIGHT')}</div>
          </RingButtonItem>
          <RingButtonItem
            className="left"
            active={left}
            disabled={!left && preventState}
            onClick={() => onControllerHandler('left')}
          >
            <div>{t('AVA.LEFT')}</div>
          </RingButtonItem>
          <RingButtonItem
            className="backward"
            active={backward}
            disabled={!backward && preventState}
            onClick={() => onControllerHandler('backward')}
          >
            <div>{t('AVA.BACKWARD')}</div>
          </RingButtonItem>
          <RingCutOut />
        </RingButtonGroup>
      </div>
    </Card>
  );
};

export default Move;
