import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { ContentWrap } from './pibo/PiboComponents';
import PiboComment from './PiboComment';
import { shuffle } from '../utils/common';
import PiboAnimation from './pibo/PiboAnimation';
import { SEQUENCE } from '../utils/consts';

/* const getKeyframes = ({
  sequence,
  name,
  frames,
  reverse,
}) => `@keyframes ${sequence} {
  ${Array.from({ length: frames * 2 - 1 }).reduce((acc, cur, i, l) => {
    const len = l.length - 1;
    let idx = 0;
    if (reverse) {
      idx = i >= frames ? Math.abs(frames - 1 - i) : frames - 1 - i;
    } else {
      idx = i >= frames ? Math.abs(i - len) : i;
    }
    const result = `${acc +
      (i * 100) /
        len}% {background-image: url("image/sequence/seq_${name}_${idx}.png");}`;
    return result;
  }, '')}}};`; */

const PiboContent = ({ refresh }) => {
  const {
    page: {
      main: { type, comment: c },
    },
  } = useSelector(state => state);

  const navigate = useNavigate();
  const [list, setList] = useState(shuffle([...SEQUENCE]));
  const [animation, setAnimation] = useState(list[0]);
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState(c);
  const onAnimationEnd = () => {
    const item = list.splice(0, 1);
    setAnimation(item[0]);
    // console.log('keyframes', item[0].label, getKeyframes(item[0]));
    if (list.length === 0) {
      setList(shuffle([...SEQUENCE]));
    } else {
      setList(list);
    }
    refresh();
    setShow(!show);
  };

  useEffect(() => {
    if (c) {
      setComment(c);
    }
  }, [c]);

  return (
    <ContentWrap>
      <PiboComment {...{ comment, show }} />
      {type === 'unconnect' && (
        <Button
          color="teal"
          size="mini"
          onClick={() => navigate('/connect')}
          style={{ zIndex: 10 }}
        >
          연결하기
          <Icon name="chevron right" />
        </Button>
      )}
      {type === 'unsigned' && (
        <Button
          color="teal"
          size="mini"
          onClick={() => navigate('/login')}
          style={{ zIndex: 10 }}
        >
          로그인
          <Icon name="chevron right" />
        </Button>
      )}
      <PiboAnimation {...{ show, animation, onAnimationEnd }} />
    </ContentWrap>
  );
};

export default PiboContent;
