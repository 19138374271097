import React from 'react';
import shortid from 'shortid';
// import { motions } from '../../../pibo/motion';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { ToggleButton } from '../../Components';
import { DANCE_MOTIONS } from '../../../utils/consts';

const Dance = props => {
  const { t } = useTranslation('bot');
  const { selected, onChange, disabled } = props;
  return (
    <Card
      title={t('AVA.DANCE.LB')}
      popupContent={{
        header: t('AVA.DANCE.LB'),
        content: t('AVA.DANCE.GUIDE'),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'wrap',
          justifyContent: 'flex-start',
          margin: '1rem',
        }}
      >
        {DANCE_MOTIONS &&
          DANCE_MOTIONS.map(({ key, name }) => (
            <ToggleButton
              key={shortid.generate()}
              value={key}
              size="tiny"
              onClick={onChange}
              style={{ margin: '.25rem .15rem' }}
              active={selected === key}
              disabled={disabled}
              compact
              toggle
            >
              {t(name)}
            </ToggleButton>
          ))}
      </div>
    </Card>
  );
};

export default Dance;
