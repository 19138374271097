import React, { useState } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMount } from 'react-use/lib';
import TopMenu from '../TopMenu';
import { Page } from '../Components';
import { getLocale } from '../../utils/common';

const TermsMarkDown = styled(MDEditor.Markdown)`
  &.wmde-markdown {
    font-family: -apple-system, BlinkMacSystemFont, 'Nanum Gothic',
      'Noto Sans SC', sans-serif, Apple Color Emoji, Segoe UI Emoji;
    word-break: keep-all;
  }
  line-height: 1.25em;
  overflow: hidden;
  font-size: 0.875rem;
  word-spacing: -1px;
  & > ol {
    font-weight: 600;
    & > li > :nth-child(n + 2) {
      font-weight: 100;
    }
  }
  ol {
    padding-inline-start: 1.85em;
    letter-spacing: -1px;
  }
  p {
    margin: 0.5em 0;
  }
  a {
    word-break: break-all;
  }
  blockquote {
    border-left: #d1d3d4 2px solid;
    margin-inline-start: 0.5em;
    margin-inline-end: 0.5em;
    font-size: smaller;
    & > p {
      margin: 0.5em;
    }
  }
`;

const AppInfo = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { menu } = useParams();
  const [title, setTitle] = useState('');
  const [content, setContents] = useState('');

  useMount(async () => {
    const locale = getLocale();

    const term = await import(`../account/terms/${menu}_${locale}.md`);
    fetch(term.default)
      .then(res => res.text())
      .then(data => setContents(data));
    switch (menu) {
      case 'infosrc':
        setTitle(t('app:INFO_SOURCE'));
        break;
      case 'license':
        setTitle(t('app:OPENSOURCE_LICENSE'));
        break;
      case 'service':
        setTitle(t('app:TERMS_SERVICE'));
        break;
      case 'location':
        setTitle(t('app:TERMS_LOCATION'));
        break;
      case 'privacy':
        setTitle(t('app:TERMS_PRIVACY'));
        break;
      default:
        break;
    }
  });

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Page>
      <TopMenu
        title={title}
        menuMode="WITH_BACK"
        handleBackClick={handleBackClick}
      />
      <Grid centered padded>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <TermsMarkDown source={content} />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Page>
  );
};

export default AppInfo;
