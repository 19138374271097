/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardMeta,
  CardDescription,
  Statistic,
  Button,
  Image,
  Accordion,
  Icon,
  AccordionTitle,
  AccordionContent,
  Rating,
  Header,
  Form,
} from 'semantic-ui-react';
import * as moment from 'moment';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

export const StoreWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  box-sizing: border-box;
  flex: 1;
  overflow: auto;
`;

export const StoreList = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding-bottom: 3rem;
  min-height: min-content;
  width: 100%;
`;

export const StoreRow = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
`;

export const StoreColumn = styled.div`
  box-sizing: border-box;
  display: block;
  position: relative;
  background: ${({ color }) => color || 'transparent'};
  margin: 0.5rem;
  @media (max-width: 500px) {
    width: 100% !important;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    width: ${({ fluid }) => (fluid ? '100%' : `calc(50% - 1rem)`)} !important;
  }
  @media (min-width: 1201px) and (max-width: 2000px) {
    width: ${({ fluid }) => (fluid ? '100%' : `calc(33.3% - 1rem)`)} !important;
  }
  @media (min-width: 2001px) {
    width: ${({ fluid }) => (fluid ? '100%' : `calc(25% - 1rem)`)} !important;
  }
`;

export const StoreCard = styled(Element)`
  border: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
  margin: 0.5rem !important;
  @media (max-width: 500px) {
    width: 100% !important;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    width: ${({ fluid }) =>
      fluid == true ? '100%' : `calc(50% - 1rem)`} !important;
  }
  @media (min-width: 1201px) and (max-width: 2000px) {
    width: ${({ fluid }) =>
      fluid == true ? '100%' : `calc(33.3% - 1rem)`} !important;
  }
  @media (min-width: 2001px) {
    width: ${({ fluid }) =>
      fluid == true ? '100%' : `calc(25% - 1rem)`} !important;
  }
  &&&:hover {
    transform: none;
  }
`;

export const StoreCardImage = styled(Image)`
  height: ${({ height }) => height || 60}px !important;
  width: ${({ height }) => height || 60}px !important;
  border-radius: ${({ avatar }) =>
    avatar ? this.height / 2 + 'px !important' : '15px !important'};
  border: 1px solid #eff0f0;
  margin: 0 1em 0.75em 0;
  background-color: #eff0f0 !important;
`;

export const StoreCardHeader = styled(CardHeader)`
  font-size: 1.25em !important;
  line-height: 28px !important;
  margin-top: 2px !important;
  display: flex !important;
  align-items: center !important;
`;

export const StoreCardMeta = styled(CardMeta)`
  line-height: 28px !important;
  margin-bottom: 2px !important;
`;

export const StoreCardDescription = styled(CardDescription)`
  &:lang(ko) {
    max-height: 2.675rem;
  }
  overflow: hidden;
  margin: 0.5em 0;
  word-break: break-all;
`;

export const StyledStatistics = styled(Statistic)`
  margin: 0 !important;
  font-size: 0.85em;
  vertical-align: middle !important;
`;

export const StoreCardButton = styled(Button)`
  color: ${({ color, basic }) =>
    !basic
      ? '#fff !important'
      : color
      ? transPublicColor(color) + '!important'
      : '#fff !important'};
  border-width: 1px 1px 1px 1px !important;
  border-style: solid !important;
  border-color: ${({ color }) =>
    color ? transPublicColor(color) + '!important' : '#fff !important'};
  box-shadow: none !important;
  padding: 0.55em 1em !important;
  & {
    .icon {
      margin: 0 !important;
    }
  }
`;

export const StoreDimmerDimmable = styled.div`
  &.ui.segment.dimmable {
    margin: 0px;
    padding: 0px;
  }
  &.segment.dimmed.dimmable {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const StoreContent = styled.div`
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  flex: 1;
`;

export const StoreLinePanel = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
`;

export const StyledAccordion = styled(Accordion)`
  border-radius: 0;
  border: none;
  background: #fff;
  padding: 0.5em 1em 1em;
`;

const halfActiveFunc = value => {
  const n = value % 1 ? Math.round(value) : 0;
  const score = Math.round(100 - (value % 1) * 100);
  return `&& > i.icon.active:nth-child(${n}) {
    background: linear-gradient(to right, #ffb922 ${100 -
      score}%, rgba(0, 0, 0, 0.15) ${score}%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }`;
};

export const StoreRating = styled(Rating)`
  & > .icon.active {
    color: #ffb922 !important;
  }
  ${({ value }) => halfActiveFunc(value)}
  ${({ rating }) => halfActiveFunc(rating)}
`;
const CommandQuote = styled.p`
  margin: 0 0 0.5em;
`;

export const StoreAccordion = ({
  activeIndexes,
  exclusive,
  index,
  title,
  content,
  onClick,
  children,
}) => {
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    onClick(index);
  };

  return (
    <StyledAccordion fluid exclusive={exclusive}>
      <AccordionTitle
        active={activeIndexes.includes(index)}
        onClick={handleClick}
        index={index}
        style={{ fontWeight: 600 }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Header as="h4" style={{ margin: 0 }}>
            {title}
          </Header>
          {!activeIndexes.includes(index) && (
            <Icon name="angle up" style={{ margin: 0 }} />
          )}
          {activeIndexes.includes(index) && (
            <Icon name="angle down" style={{ margin: 0 }} />
          )}
        </div>
      </AccordionTitle>
      <AccordionContent
        active={activeIndexes.includes(index)}
        style={{ fontWeight: 100 }}
      >
        {Array.isArray(children) &&
          children.map(item => (
            <CommandQuote key={shortid.generate()}>{item}</CommandQuote>
          ))}
        {!Array.isArray(children) && children}
      </AccordionContent>
    </StyledAccordion>
  );
};

const ReviewContent = styled.div`
  position: relative;
  &::after {
    content: '...';
    position: absolute;
    top: 1.5rem;
    right: 0;
    background: linear-gradient(to right, transparent 49%, white 51%);
    width: ${({ wrap }) => (wrap === 'true' ? '1rem' : 0)};
    height: ${({ wrap }) => (wrap === 'true' ? '1rem' : 0)};
    text-align: right;
  }
`;

export const StoreReviewCard = props => {
  const { t } = useTranslation('store');
  const {
    title,
    nickName,
    lastTime,
    updatedAt,
    content,
    score,
    open: openVal,
  } = props;
  const open = openVal || false;
  const [isMore, setMore] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const initContentStyle = {
    width: '100%',
    marginTop: '0.5rem',
    overflow: 'hidden',
  };
  const [contentStyle, setContentStyle] = useState(initContentStyle);
  const reviewContentRef = useRef();
  useEffect(() => {
    const {
      current: { clientHeight },
    } = reviewContentRef;
    if (clientHeight > 50) {
      if (!open) {
        setMore(true);
        setContentStyle({ ...initContentStyle, maxHeight: '2.85rem' });
      } else {
        setContentStyle(initContentStyle);
      }
    }
  }, []);
  const handleMoreBtnClick = () => {
    if (isOpen) {
      setContentStyle({ ...initContentStyle, maxHeight: '2.85rem' });
    } else {
      setContentStyle(initContentStyle);
    }
    setOpen(!isOpen);
  };

  return (
    <div
      style={{
        width: '100%',
        margin: '0.5em 0',
        fontSize: '0.875rem',
        display: 'flex',
        flexFlow: 'column',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Header style={{ margin: 0 }} size="small">
          {title}
        </Header>
        <div style={{ color: '#565B5B', fontSize: '0.75rem' }}>
          {moment(lastTime).fromNow()}
        </div>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {score && (
          <StoreRating
            rating={score || 0}
            maxRating={5}
            size="tiny"
            disabled
            clearable
          />
        )}
        <div style={{ color: '#565B5B', fontSize: '0.75rem' }}>{nickName}</div>
      </div>
      <ReviewContent
        style={contentStyle}
        ref={reviewContentRef}
        wrap={open || (!open && isMore && isOpen) ? 'false' : 'true'}
      >
        {content}
      </ReviewContent>
      {isMore && (
        <TextButton
          style={{ color: '#0894d7', fontFamily: 'inherit' }}
          onClick={handleMoreBtnClick}
        >
          <Icon name={isOpen ? 'angle up' : 'angle down'} />
          {isOpen ? t('REVIEW.FOLD') : t('REVIEW.UNFOLD')}
        </TextButton>
      )}
    </div>
  );
};

export const TextButton = styled.button`
  color: #0894d7;
  font-family: inherit;
  text-align: center;
  min-width: 2.5rem;
  min-height: 1rem;
  border: 0;
  background: transparent;
`;

export const ReviewForm = props => {
  const { t } = useTranslation('store');
  const {
    reviewTitle,
    reviewText,
    saving,
    handleReviewTextChange,
    handleReviewTitleChange,
  } = props;
  return (
    <Form style={{ fontSize: '0.875rem' }}>
      <Form.Input
        placeholder={t('REVIEW.ENTER_TITLE')}
        value={reviewTitle}
        disabled={saving}
        onChange={handleReviewTitleChange}
        style={{ fontSize: '1em' }}
      />
      <Form.TextArea
        placeholder={t('REVIEW.ENTER_CONTENT')}
        value={reviewText}
        disabled={saving}
        onChange={handleReviewTextChange}
        style={{ fontSize: '1em' }}
        rows={4}
      />
    </Form>
  );
};

const transPublicColor = color => {
  if (color.search('#') === 0) return color;
  switch (color) {
    case 'red':
      return '#E10600';
    case 'orange':
      return '#FF851B';
    case 'yellow':
      return '#FFB922';
    case 'olive':
      return '#B5CC18';
    case 'green':
      return '#2ECC40';
    case 'teal':
      return '#03BFD7';
    case 'blue':
      return '#0894D7';
    case 'violet':
      return '#6435C9';
    case 'purple':
      return '#A24096';
    case 'pink':
      return '#D9499A';
    case 'brown':
      return '#A5673F';
    case 'black':
      return '#191919';
    case 'grey':
      return '#D1D3D4';
    case 'white':
      return '#FFFFFF';
    case 'transparent':
      return 'transparent';
    default:
      return color;
  }
};
