/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLifecycles } from 'react-use';
import Oled from '../../../components/bots/AvatarBot/Oled';
import Speak from '../../../components/bots/AvatarBot/Speak';
import { FixedBottomButton } from '../../../components/Components';
import { testSpeakRegExr } from '../../../ducks/bots/trainBot/consts';
import { speakAndMotionPibo, killPibo, receivePiboEvent } from '../../../pibo';

let timer;
const SpeakContainer = ({ standalone }) => {
  const { t } = useTranslation('bot');
  const [text, setText] = useState('');
  const [oled, setOled] = useState(null);
  const [error, setError] = useState(null);
  const [playing, setPlaying] = useState(false);
  const valueRef = useRef(false);

  const getStopEnableState = ({ playing: p, text: txt }) => {
    if (p) return true;
    if (!p && txt.length > 0) return true;
    return false;
  };
  const stopEnable = getStopEnableState({ text, playing });

  useEffect(() => {
    valueRef.current = playing;
  }, [playing]);

  const setInitial = () => {
    setText('');
    setOled(null);
    setPlaying(false);
  };

  const handlePlayStopClick = () => {
    if (!playing && text.length > 0) {
      setError(null);
      if (testSpeakRegExr(text)) {
        return setError(t('AVA.SPEAKING.UNVALID_INPUT'));
      }
      if (text.length > 140) {
        return setError(t('AVA.SPEAKING.INFO'));
      }
      setText(text);
      const result = speakAndMotionPibo(text, oled);
      if (result) {
        setPlaying(true);
        // timer = setTimeout(setInitial, 5000);
        return timer;
      }
    }
    if (playing) {
      killPibo();
    }
  };

  useLifecycles(
    () => {
      handlePlayStopClick();
      receivePiboEvent('finish', () => {
        if (valueRef.current) {
          setInitial();
        }
      });
    },
    () => {
      receivePiboEvent('finish', null);
      if (timer) {
        clearTimeout(timer);
      }
    },
  );

  const handleTextChange = (e, { value }) => {
    setError(null);
    const speakRegExr = /[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣\s\d.,?!~]|[\n\r\r]/gi;
    const speakText = value.replace(/[\n\r\t]/g, '').slice(0, 140);
    setText(speakText);
    setError(speakRegExr.test(value) ? t('AVA.SPEAKING.INFO') : null);
  };

  const handleOledChange = v => {
    setOled(v === oled ? null : v);
  };

  return (
    <div>
      <Speak
        text={text}
        error={error}
        playing={playing}
        onTextChange={handleTextChange}
      />
      <Oled oled={oled} playing={playing} onOledChange={handleOledChange} />
      <FixedBottomButton
        name="stop"
        type="button"
        style={{ margin: '0 -1rem' }}
        disabled={!stopEnable}
        onClick={handlePlayStopClick}
        standalone={standalone}
      >
        {playing ? t('AVA.STOP') : t('AVA.INPUT.SEND')}
      </FixedBottomButton>
    </div>
  );
};

export default SpeakContainer;
