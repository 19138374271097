import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import sid from 'shortid';
import styled from 'styled-components';
import { onSaveExecution } from '../../../ducks/bots/trainBot/train';
import { getFile } from '../../../utils/api';
import {
  BorderedSegment,
  CardBody,
  HelpText,
  InvalidText,
  TextInput,
  ToggleButtonItem,
} from './Components';
import ExecCard from './ExecCard';

const InlineItems = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  color: #0894d7;
  & > span {
    margin-right: 1.5rem;
    line-height: initial;
    margin: 0.5rem 1.5rem 0.5rem 0;
  }
`;

const BotExecCard = props => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    execution: { color, icon, label: title },
    data: { bots },
  } = useSelector(state => state.train);
  const { onCancel } = props;
  const [selectedItem, setSelect] = useState({ index: -1, value: null });
  const [error, setError] = useState(null);
  const [command, setCmd] = useState(null);

  const onTextChange = e => {
    setCmd(e.target.value);
  };

  const handleToggleButtonClick = index => {
    if (selectedItem.index === index) {
      setSelect({ index: -1, value: null });
    } else {
      setSelect({ index, value: { ...bots[index] } });
    }
  };

  const handleSave = () => {
    const {
      index,
      value: { key, label, value: v },
    } = selectedItem;
    if (!error && index > -1) {
      dispatch(onSaveExecution({ key, label, value: v }));
    }
  };

  useUpdateEffect(() => {
    const { value: item } = selectedItem;
    if (item) {
      const { example } = item;
      setCmd(example);
    }
  }, [selectedItem.index]);

  useUpdateEffect(() => {
    const { value: item, index } = selectedItem;
    setSelect({ index, value: { ...item, value: command } });
    const { commandList } = item;
    const expr = commandList.reduce((p, c, i, arr) => {
      let str = p;
      str += `(${c})`;
      if (i < arr.length - 1) {
        str += '|';
      }
      return str;
    }, '');
    const regExp = new RegExp(expr, 'g');
    if (!regExp.test(command)) {
      setError(t('bot:TRN.ERROR.BOT_NOT_CMD'));
    } else {
      setError(null);
    }
  }, [command]);

  return (
    <ExecCard
      {...{
        t,
        color,
        icon,
        title,
        options: {
          onCancel,
          onSave: handleSave,
          saveDisabled: error || selectedItem.index < 0,
        },
      }}
    >
      <CardBody>
        <p>{t('bot:TRN.TITLE.SELECT_BOT')}</p>
        <BorderedSegment radius={10}>
          <div>
            {bots.map(({ key, label }, i) => (
              <ToggleButtonItem
                selected={selectedItem.index === i}
                color="3,191,215"
                key={key}
                onClick={() => handleToggleButtonClick(i)}
              >
                <img src={getFile({ icon: key })} alt={label} />
                <span>{label}</span>
              </ToggleButtonItem>
            ))}
          </div>
        </BorderedSegment>
      </CardBody>
      {selectedItem.index > -1 && (
        <CardBody>
          <p>{t('bot:TRN.TITLE.REQUIRED_CMD')}</p>
          <BorderedSegment radius={5} padding=".5rem 1rem !important">
            <InlineItems>
              {selectedItem.value.commandList.map(cmd => (
                <span key={sid.generate()}>{cmd}</span>
              ))}
            </InlineItems>
          </BorderedSegment>
        </CardBody>
      )}
      {selectedItem.index > -1 && (
        <CardBody>
          <p>{t('bot:TRN.TITLE.INPUT_CMD')}</p>
          <TextInput
            onChange={onTextChange}
            value={command === null ? '' : command}
          />
          {!error && command === null && (
            <HelpText>{t('bot:TRN.PLACEHOLDER.BOT_CMD')}</HelpText>
          )}
          {error && <InvalidText>{error}</InvalidText>}
        </CardBody>
      )}
    </ExecCard>
  );
};

export default BotExecCard;
