import React from 'react';
import { Header } from 'semantic-ui-react';
import AccordionText from './AccordionText';

const WikiCard = data => {
  const { title, content, url, error } = data;
  return (
    <div
      style={{
        display: 'flex',
        gap: '.35rem',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Header
        as="h5"
        className={url && 'link'}
        style={{
          fontSize: '0.875rem',
          margin: 0,
          width: '100%',
        }}
        onClick={() => (url ? window.open(url, '_blank') : null)}
      >
        {error || title}
      </Header>
      {content && <AccordionText {...{ content, textAlign: 'left' }} />}
    </div>
  );
};

export default WikiCard;
