import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from './Card';
import { Image } from '../../Components';

const Photo = props => {
  const { t } = useTranslation('bot');
  const { photo, onClickImage, error } = props;
  return (
    <Card
      title={t('AVA.PHT.LB')}
      popupContent={{
        header: t('AVA.PHT.LB'),
        content: t('AVA.PHT.GUIDE'),
      }}
    >
      <div
        style={{
          display: 'flex',
          flexFlow: 'column',
          position: 'relative',
          margin: '1rem',
        }}
      >
        {error}
        {!error && !photo && t('AVA.PHT.NO_RESULT')}
        {!error && photo && (
          <Image src={photo} alt={t('AVA.PHT.LB')} onClick={onClickImage} />
        )}
      </div>
    </Card>
  );
};

export default Photo;
