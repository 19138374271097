/* eslint-disable no-dupe-keys */
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-dom-last-location';
import { useLifecycles, useUpdateEffect } from 'react-use';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import MessageList from '../../../components/bots/MessageBot/MessageList';
import SendMsgContainer from './SendMsgContainer';

import {
  init,
  getMessage,
  updateMessage,
  setHandler,
} from '../../../ducks/bots/messageBot/message';
import { receivePiboEvent } from '../../../pibo';

const actualHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.documentElement.getElementsByTagName('body')[0].clientHeight;

const timer = null;
const Index = ({ title }) => {
  const inputRef = useRef(null);
  const { lastLocation } = useLastLocation();
  const dispatch = useDispatch();
  const timelineRef = useRef();
  const { initDataStatus, bBottom } = useSelector(state => state.messageBot);
  const [backLocation, setBackLocation] = useState('');

  const handleReceive = result => {
    console.log('message handleReceive', result);
    if (result && 'id' in result) {
      dispatch(
        updateMessage({
          ...result,
          offMode: false,
        }),
      );
    } else if (result && 'status' in result && result.status === 1) {
      dispatch(
        updateMessage({
          status: 200,
          data: { ...result },
          offMode: false,
        }),
      );
    }
  };

  useLifecycles(
    () => {
      if (lastLocation && 'pathname' in lastLocation) {
        setBackLocation(lastLocation.pathname);
      }
      if (!initDataStatus) {
        dispatch(init());
        dispatch(getMessage());
      }
      dispatch(setHandler(handleReceive));
      receivePiboEvent('message', handleReceive);
    },
    () => {
      if (timer) {
        clearTimeout(timer);
      }
      return receivePiboEvent('message', null);
    },
  );

  useUpdateEffect(() => {
    if (bBottom) {
      timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
    }
  }, [bBottom]);

  return (
    <>
      <BotTopMenu
        {...{ title, menuMode: 'WITH_CLOSE', backLocation, onClose: init }}
      />
      <MessageList
        timelineRef={timelineRef}
        actualHeight={actualHeight - 144}
      />
      <SendMsgContainer {...{ inputRef }} />
    </>
  );
};

export default Index;
