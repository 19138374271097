import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Message, Radio } from 'semantic-ui-react';
import { AccountForm, AccountInput, AccountWrap } from './AccountComponents';
import { StyledMessage } from '../Message';

const InputsForFindAccounts = props => {
  const { t } = useTranslation();
  const {
    info,
    msg,
    userIdInput,
    serialInput,
    nameInput,
    telInput: { label: tl, defaultValue: tdv, ...telInput },
    emailInput: { label: el, defaultValue: edv, ...emailInput },
    authMethod,
    onMethodChange,
    onChange,
    onBlur,
  } = props;

  return (
    <AccountForm>
      <AccountWrap>
        {info && <p style={{ fontSize: '0.75rem' }}>{info}</p>}
        {nameInput && (
          <Form size="large" style={{ fontSize: '0.875rem ' }}>
            <div className="field">
              <div className="ui fluid input">
                <AccountInput
                  {...{
                    ...nameInput,
                    onChange,
                    onBlur,
                  }}
                />
              </div>
            </div>
          </Form>
        )}
        {serialInput && (
          <Form size="large" style={{ fontSize: '0.875rem ' }}>
            <div className="field">
              <div className="ui fluid input">
                <AccountInput
                  {...{
                    ...serialInput,
                    onChange,
                    onBlur,
                  }}
                />
              </div>
            </div>
          </Form>
        )}
        {userIdInput && (
          <Form size="large" style={{ fontSize: '0.875rem ' }}>
            <div className="field">
              <div className="ui fluid input">
                <AccountInput
                  {...{
                    ...userIdInput,
                    onChange,
                    onBlur,
                  }}
                />
              </div>
            </div>
          </Form>
        )}
        <Form size="large" style={{ marginBottom: 0 }}>
          <Form.Group>
            <Form.Field
              control={Radio}
              label={t('user:TEL.LB')}
              value="tel"
              checked={authMethod === 'tel'}
              onChange={onMethodChange}
              style={{ marginRight: '1.5em', fontSize: '0.875rem' }}
            />
            <Form.Field
              control={Radio}
              label={t('user:MAIL.LB')}
              value="email"
              checked={authMethod === 'email'}
              onChange={onMethodChange}
              style={{ marginRight: '1.5em', fontSize: '0.875rem' }}
            />
          </Form.Group>
        </Form>
        <Form size="large" style={{ fontSize: '0.875rem ' }}>
          <div className="field">
            <div className="ui fluid input">
              {authMethod === 'tel' && (
                <AccountInput
                  {...{
                    ...telInput,
                    onChange,
                    onBlur,
                  }}
                />
              )}
              {authMethod === 'email' && (
                <AccountInput
                  {...{
                    ...emailInput,
                    onChange,
                    onBlur,
                  }}
                />
              )}
            </div>
          </div>
        </Form>
        {msg && (
          <StyledMessage
            color="red"
            hidden={!msg}
            textalign="left"
            style={{ marginBottom: '3rem' }}
          >
            <Message.Content>{msg}</Message.Content>
          </StyledMessage>
        )}
      </AccountWrap>
    </AccountForm>
  );
};

export default InputsForFindAccounts;
