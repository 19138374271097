import styled from 'styled-components';
import { Message } from 'semantic-ui-react';

export const StyledMessage = styled(Message)`
  && {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    font-size: 1rem;
    text-align: ${({ textalign }) => textalign || 'center'};
    /* margin-right: 1rem;
    margin-left: 1rem; */
    margin: 0;
    &.small {
      font-size: 0.875em;
    }
    &.mini {
      font-size: 0.75em;
      font-weight: 600;
    }
  }
`;
