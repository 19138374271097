import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-dom-last-location';
import { useMount, useUpdateEffect } from 'react-use';
import { Segment } from 'semantic-ui-react';
import shortid from 'shortid';
import BottomFixedMenu from '../../../components/bots/common/BottomFixedMenu';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import DateCard from '../../../components/bots/DiaryBot/DateCard';
import { Page } from '../../../components/Components';
import Confirm from '../../../components/Confirm';

import {
  deleteDiaryData,
  deleteStateInit,
  getDiaries,
  initialize,
  getInitStatus,
  openPopup,
  closePopup,
} from '../../../ducks/bots/diaryBot/diaryBot';
import withScrollManual from '../../../hocs/withScrollManual';
import { getRobotId } from '../../../utils/common';

const Index = ({ title }) => {
  const { t } = useTranslation('bot');
  const { lastLocation } = useLastLocation();
  const dispatch = useDispatch();
  const {
    diaryData: oriDiaryData,
    loading,
    deleting,
    error,
    deleteError,
    deleteState,
    initDataStatus,
    total,
    length,
    popup,
  } = useSelector(state => state.diaryBot);
  const [backLocation, setBackLocation] = useState(null);
  const [diaryData, setDiaryData] = useState([]);
  const [selectItems, setSelectItems] = useState([]);
  const [selectMode, setSelectMode] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [popup, setPopup] = useState({ isPopup: false });

  const handleMoreBtnClick = () => {
    dispatch(getDiaries({ robotId: getRobotId() }));
  };

  const handleCheckBtnClick = () => {
    if (selectMode) {
      const newData = diaryData.map(({ data, title: name }) => ({
        title: name,
        data: data.map(({ checked, ...rest }) => ({ checked: false, ...rest })),
      }));

      setDiaryData(newData);
      setSelectItems([]);
    }
    setSelectMode(!selectMode);
  };

  const handleDeleteBtnClick = () => {
    dispatch(openPopup());
    // dispatch(deleteDiaryData({ robotId: getRobotId(), selectItems }));
    // setPopup({
    //   contents: t('DRY.DELETE.MSG'),
    //   leftLabel: t('common:POP.CANCEL'),
    //   rightLabel: t('common:POP.OK'),
    //   rightFunc: () =>
    //     dispatch(deleteDiaryData({ robotId: getRobotId(), selectItems })),
    //   mode: 'confirm',
    //   isPopup: true,
    //   onClose: () => setPopup({ isPopup: false }),
    // });
  };

  useMount(() => {
    if (lastLocation && 'pathname' in lastLocation) {
      setBackLocation(lastLocation.pathname);
    }

    if (!initDataStatus) {
      dispatch(getDiaries({ robotId: getRobotId(), init: true }));
      dispatch(getInitStatus());
    }
  });

  const handleItemCheck = (e, { id }) => {
    console.log(id);
    if (selectItems.indexOf(id) > -1) {
      setSelectItems(selectItems.filter(item => item !== id));
    } else {
      setSelectItems([...selectItems, id]);
    }
  };

  const init = () => dispatch(initialize());

  useUpdateEffect(() => {}, [popup]);

  useUpdateEffect(() => {
    if (deleteState === 1) {
      dispatch(deleteStateInit());
      setSelectMode(false);
      setSelectItems([]);
    }
  }, [deleteState]);

  useEffect(() => {
    console.log(oriDiaryData);
    if (oriDiaryData) {
      setDiaryData(oriDiaryData);
    }
  }, [oriDiaryData]);

  return (
    <Page style={{ paddingBottom: '4rem' }}>
      <BotTopMenu
        title={title}
        menuMode="WITH_CLOSE"
        backLocation={backLocation}
        onClose={init}
      />
      <Segment basic style={{ margin: 0 }}>
        {(deleteError || error || !diaryData || diaryData.length === 0) && (
          <Segment
            textAlign="center"
            style={{ color: error || deleteError ? '#ef3340' : 'inherit' }}
          >
            {deleteError || error || t('DRY.NO_RESULT')}
          </Segment>
        )}
        {diaryData &&
          diaryData.map(item => (
            <DateCard
              key={shortid.generate()}
              robotId={getRobotId()}
              item={item}
              selectMode={selectMode}
              selectItems={selectItems}
              onCheck={handleItemCheck}
            />
          ))}
        {diaryData.length > 0 && !selectMode && (
          <Segment
            as="button"
            onClick={handleMoreBtnClick}
            textAlign="center"
            style={{ width: '100%', color: '#0894D7', outline: 'none' }}
            disabled={total === length || loading || deleting}
          >
            {total === length ? t('DRY.LOAD.COMPLETE') : t('DRY.MORE')}
          </Segment>
        )}
      </Segment>
      <BottomFixedMenu
        leftIcon={selectMode ? 'cancel' : 'check'}
        leftLabel={selectMode ? t('DRY.SELECT.CLOSE') : t('DRY.SELECT.LB')}
        leftDisabled={
          !diaryData || diaryData.length === 0 || loading || deleting
        }
        onLeftButton={handleCheckBtnClick}
        rightIcon="trash alternate outline"
        rightLabel={t('DRY.DELETE.LB')}
        rightDisabled={!selectMode || selectItems.length === 0}
        onRightButton={handleDeleteBtnClick}
      />
      {popup.isPopup && (
        <Confirm
          {...{
            ...popup,
            rightFunc: () =>
              dispatch(deleteDiaryData({ robotId: getRobotId(), selectItems })),
            onClose: () => dispatch(closePopup()),
          }}
        />
      )}
    </Page>
  );
};

export default withScrollManual(Index);
