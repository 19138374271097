import React, { useEffect, useState } from 'react';
import { Segment, Header, Checkbox, Icon } from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import shortId from 'shortid';
import styled from 'styled-components';
import moment from 'moment';
import { getFile } from '../../../utils/api';

const getProgress = value => {
  let p = 0;
  if (value < 50) {
    p = 90 + (360 / 100) * value;
    return `linear-gradient(90deg, #c8e8f8 50%, transparent 50%, transparent), linear-gradient(${p}deg, rgb(8, 148, 215) 50%, #c8e8f8 50%, #c8e8f8)`;
  }
  p = -90 + (360 / 100) * (value - 50);
  return `linear-gradient(${p}deg, rgb(8, 148, 215) 50%, transparent 50%, transparent), linear-gradient(270deg, rgb(8, 148, 215) 50%, #c8e8f8 50%, rgba(8, 148, 215, 0.25))`;
};
const Circle = styled.div`
  background: ${({ progress, error }) =>
    error === true ? 'rgba(255, 0, 0, 0.3)' : `${getProgress(progress)}`};
  border-radius: 50%;
  height: 35px;
  margin: 2em auto;
  position: absolute;
  min-width: 35px;
  margin: auto;
  transition: all 1s linear;
  &::before {
    background-color: #fafafa;
    border-radius: 50%;
    color: #0894d7;
    font-family: Icons;
    content: '';
    font-weight: bold;
    height: 100%;
    left: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transform: scale(0.87);
    width: 100%;
  }
`;

export const Player = props => {
  const { url: u, setError } = props;
  // const [ready, setReady] = useState(false);
  const [playing, setPlay] = useState(false);
  const [iconName, setIcon] = useState('');
  const [progress, setProgress] = useState(0);
  const [url, setUrl] = useState(u);

  const onReady = () => {
    //   setReady(true);
    setIcon('play');
  };
  const onPlay = () => {
    setIcon('pause');
  };
  const onProgress = data => {
    const { playedSeconds, loadedSeconds } = data;
    const val = Math.round((playedSeconds / loadedSeconds) * 100);
    if (data.played === 1 || (playing && playedSeconds === 0)) {
      setProgress(100);
      setIcon('play');
      setPlay(false);
      setUrl('');
      setTimeout(() => {
        setProgress(0);
        setUrl(u);
      }, 500);
    } else {
      setProgress(val >= 99 ? 100 : val);
    }
  };
  // const onProgress = data => {
  //   if (data.played === 1) {
  //     setIcon('play');
  //     setPlay(false);
  //     setProgress(100);
  //     setTimeout(() => setProgress(0), 500);
  //   } else {
  //     setProgress(Math.round(data.played * 100));
  //   }
  // };

  const onPause = () => {
    if (progress < 86) {
      setIcon('play');
      setPlay(false);
    }
  };

  const handleError = () => {
    setError('해당 음성일기 파일을 정상적으로 로드할 수 없습니다.');
    if (progress < 86) {
      setIcon('warning');
    }
  };
  const onError = e => {
    console.log(e);
    handleError();
  };

  useEffect(() => {
    if (!u) {
      handleError();
    }
  }, [u]);

  return (
    <div>
      <ReactPlayer
        style={{ display: 'none' }}
        config={{
          file: {
            forceAudio: true,
          },
        }}
        width={0}
        volume={1}
        {...{
          url,
          playing,
          onReady,
          onError,
          onPlay,
          onProgress,
          onPause,
        }}
      />

      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Circle {...{ progress, playing, error: iconName === 'warning' }} />
        {iconName !== 'warning' && (
          <Icon
            name={iconName}
            color={iconName === 'play' ? 'blue' : 'grey'}
            onClick={() => setPlay(!playing)}
            style={{ zIndex: 1, margin: 0, lineHeight: 'normal' }}
          />
        )}
        {iconName === 'warning' && (
          <Icon
            name={iconName}
            color="red"
            style={{ zIndex: 1, margin: 0, lineHeight: 'normal' }}
          />
        )}
      </div>
    </div>
  );
};

const DateCard = props => {
  const { item, selectMode, selectItems, onCheck } = props;
  const [[title, data]] = Object.entries(item);
  const [error, setError] = useState(null);
  return (
    <Segment
      style={{
        color: '#707070',
        display: `${data && data.length === 0 ? 'none' : 'block'}`,
      }}
    >
      {data && data.length > 0 && (
        <Header as="h4" style={{ fontWeight: '600' }}>
          {title}
        </Header>
      )}
      {data &&
        data.map(({ id, firstTime, diary }) => (
          <div key={firstTime}>
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                alignItems: 'center',
                padding: '.25rem 0',
                position: 'relative',
                height: '40px',
              }}
              key={shortId.generate()}
            >
              <Player {...{ url: getFile({ diary }), setError }} />
              <div style={{ padding: '0 1rem' }}>
                <div>{moment(firstTime).format('LL LT')}</div>
                <div
                  style={{
                    lineHeight: 1.25,
                    fontSize: '.675rem',
                    color: '#d94647',
                  }}
                >
                  {error}
                </div>
              </div>
              {selectMode && (
                <Checkbox
                  id={id}
                  checked={selectItems.indexOf(id) > -1}
                  onChange={onCheck}
                  style={{ position: 'absolute', right: 0 }}
                />
              )}
            </div>
          </div>
        ))}
    </Segment>
  );
};

export default DateCard;
