import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import i18 from '../lang/i18n';
import {
  INSIDER,
  getErrorCode,
  getErrorMsg,
  getLocale,
  setCurrentUser,
  setLocalStorage,
  setUserToken,
} from '../utils/common';
import { validateEmail, validateTel } from '../utils/validate';
import { confirmCertCode, sendCertCode, userSocialSignUp } from '../utils/api';

const name = 'social';
const steps = [
  { id: 'aggrement', label: i18.t('user:SIGN_UP.TITLE.STEP1') },
  { id: 'authentication', label: i18.t('user:SIGN_UP.TITLE.STEP2') },
  { id: 'privacy', label: i18.t('user:SIGN_UP.TITLE.STEP4') },
];
const activeIndexOrigin = 0;
const originStep1Data = { value: '', sent: false, confirm: false, time: 0 };
const originData = {
  '0': { privacy: false, location: false, service: false },
  '1': {
    tel: { ...originStep1Data },
    email: { ...originStep1Data },
    error: { code: '', message: '' },
  },
  '2': {
    firstName: '',
    lastName: '',
    birthDate: moment().format('YYYY-MM-DD'),
    gender: null,
    error: false,
  },
};
const NEXT = { color: 'blue', key: 'next_btn', label: i18.t('user:NEXT') };
const PREV = { style: { color: '#fff !important' }, key: 'prev_btn' };
const getBtnState = (activeIndex, payload) => {
  switch (activeIndex) {
    case 1:
      return [
        {
          ...NEXT,
          disabled: !(
            (payload.tel && payload.tel.confirm) ||
            (payload.email && payload.email.confirm)
          ),
        },
        { ...PREV, label: i18.t('user:PREV'), disabled: false },
      ];
    case 2:
      return [
        {
          ...NEXT,
          disabled: !(
            payload.firstName &&
            payload.lastName &&
            payload.birthDate &&
            payload.gender &&
            !payload.error
          ),
        },
        { ...PREV, label: i18.t('user:PREV'), disabled: false },
      ];
    case 3:
      return [{ ...NEXT, label: i18.t('conn:BTN.MOVE_TO_CONNECT') }];
    default:
      return [
        {
          ...NEXT,
          label: i18.t('user:TERM.ACCEPT'),
          disabled: !(payload.service && payload.privacy),
        },
        { ...PREV, label: i18.t('user:TERM.DECLINE'), disabled: false },
      ];
  }
};
const initialState = {
  steps,
  loading: false,
  activeIndex: activeIndexOrigin,
  terms: { privacy: '', location: '', service: '' },
  data: originData,
  social: { loginType: '', loginId: '' },
  btn: getBtnState(activeIndexOrigin, originData[activeIndexOrigin]),
  error: '',
  complete: { message: null, to: null },
};

const termsGetData = async () => {
  const locale = getLocale();
  const termsList = ['service', 'privacy', 'location'];

  const loadTerms = entries =>
    Promise.all(
      entries.map(entry =>
        import(`../components/account/terms/${entry}_${locale}.md`),
      ),
    );

  const result = await loadTerms(termsList);
  const results = await result.map(async ({ default: item }) => {
    const res = await fetch(item);
    return res.text();
  });
  const termsResult = await Promise.all(results);
  return termsResult;
};

export const fetchTerms = createAsyncThunk(
  `${name}/GET_TERMS`,
  async (arg, { rejectWithValue }) => {
    try {
      const [service, privacy, location] = await termsGetData();
      return { service, privacy, location };
    } catch (error) {
      return rejectWithValue(i18.t('user:SIGN_UP.ERR_MSG.FAILED_TERMS'));
    }
  },
);
export const sendCode = createAsyncThunk(
  `${name}/SEND_CODE`,
  async ({ type, value }, { getState, rejectWithValue }) => {
    const {
      social: { data },
    } = getState();
    const indexData = data['1'];
    let returnData;
    let errorObj;
    try {
      const obj = { email: '', tel: '' };
      const sendResult = await sendCertCode({
        ...obj,
        [type]: value,
        type: 'signup',
      });
      /* 테스트용
        const cur = new Date().valueOf();
        const sendResult = {
          result: true,
          data: {
            statusCode: '202',
            statusName: 'success',
            requestId: '138fa09cb5ce43a9a09aef1a0e573efa',
            requestTime: '2021-04-23T14:05:43.922',
          },
          saveResult: {
            _id: '608255a7091a5ef6e5fbdce9',
            valid: true,
            hash: '6TEVNF',
            type: 'signup',
            createdAt: '2021-04-23T05:05:43.506Z',
            firstTime: cur,
            expireTime: cur + (1000 * 60 * 3),
            expiredAt: '2021-04-23T05:08:43.506Z',
            __v: 0,
          },
        };
        */
      if ('result' in sendResult) {
        const { result, saveResult, error } = sendResult;
        if (result) {
          const { expireTime, firstTime } = saveResult;
          returnData = {
            ...indexData,
            [type]: { ...indexData[type], sent: true, expireTime, firstTime },
            error: false,
          };
          return { data: { ...data, '1': returnData } };
        }
        if ('code' in error && error.code === '5v102z') {
          if (type === 'tel') {
            errorObj = {
              code: 'used_tel',
              message: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_TEL'),
            };
          }
          if (type === 'email') {
            errorObj = {
              code: 'used_mail',
              message: i18.t('user:SIGN_UP.ERR_MSG.ALREADY_MAIL'),
            };
          }
        } else {
          errorObj = {
            code: 'failed',
            message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
          };
        }
      } else {
        errorObj = {
          code: 'unvalid',
          message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
        };
      }
      returnData = { ...indexData, error: errorObj };
      return rejectWithValue({ ...data, '1': returnData });
    } catch (error) {
      errorObj = {
        code: 'failed',
        message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_SENT_CERT'),
      };
      returnData = { ...indexData, error: errorObj };
      return rejectWithValue({ ...data, '1': returnData });
    }
  },
);

export const confirmCode = createAsyncThunk(
  `${name}/CONFIRM_CODE`,
  async ({ code, type }, { getState, rejectWithValue }) => {
    const {
      social: { data },
    } = getState();
    const indexData = data['1'];
    let returnData;
    try {
      const confirmResult = await confirmCertCode({ code, type: 'signup' });
      /* 테스트용
      const confirmResult = { result: true };
      */
      if ('result' in confirmResult) {
        const { result } = confirmResult;
        if (result) {
          returnData = {
            ...indexData,
            [type]: { ...indexData[type], error: false, confirm: true },
          };
          return {
            data: { ...data, '1': returnData },
            btn: getBtnState(1, returnData),
          };
        }
        returnData = {
          ...indexData,
          error: {
            code: 'unvalid',
            message: i18.t('user:SIGN_UP.ERR_MSG.UNVALID_CERT'),
          },
        };
      } else {
        returnData = {
          ...indexData,
          error: {
            code: 'failed',
            message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'),
          },
        };
      }
      return rejectWithValue({ ...data, '1': returnData });
    } catch (error) {
      returnData = {
        ...indexData,
        error: {
          code: 'error',
          message: i18.t('user:SIGN_UP.ERR_MSG.FAILED_CERT'),
        },
      };
      return rejectWithValue({ ...data, '1': returnData });
    }
  },
);

const socialSlice = createSlice({
  name,
  initialState,
  reducers: {
    init: (state, action) => ({
      ...initialState,
      terms: state.terms,
      ...action.payload,
    }),
    next: (state, action) => ({ ...state, ...action.payload }),
    prev: (state, action) => ({ ...state, ...action.payload }),
    setData: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers: {
    [fetchTerms.pending.type]: state => ({ ...state, loading: true }),
    [fetchTerms.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      terms: action.payload,
    }),
    [fetchTerms.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [sendCode.pending.type]: state => ({ ...state, loading: true }),
    [sendCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    [sendCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      data: { ...action.payload }, // error가 data 안에 포함
    }),
    [confirmCode.pending.type]: state => ({ ...state, loading: true }),
    [confirmCode.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    [confirmCode.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      data: { ...action.payload }, // error가 data 안에 포함
    }),
  },
});

const { reducer: socialReducer, actions } = socialSlice;
const { next, prev, init, setData } = actions;

export const onNext = () => async (dispatch, getState) => {
  const { social: state } = getState();
  const { activeIndex: currentIndex } = state;
  const activeIndex = currentIndex + 1;
  let returnData;
  if (currentIndex === 2) {
    const {
      '0': { privacy, service, location: agreeLocation },
      '1': {
        tel: { value: tel, confirm: telConfirm },
        email: { value: email, confirm: emailConfirm },
      },
      '2': { firstName, lastName, birthDate, gender },
    } = state.data;

    let error = false;
    const currentData = {
      ...state.data[currentIndex],
      error,
    };
    if (!(privacy && service)) {
      error = i18.t('user:SIGN_UP.MSG.AGREE_TERMS');
    } else if (!(telConfirm || emailConfirm)) {
      error = i18.t('user:SIGN_UP.MSG.VERIFY_ANY');
    } else if (!(firstName && lastName && birthDate && gender)) {
      error = i18.t('user:SIGN_UP.MSG.USER_INFO');
    } else {
      const signUpData = {
        ...state.social,
        agreeLocation,
        tel,
        email,
        firstName,
        lastName,
        birthDate,
        gender,
      };
      const signUpResult = await userSocialSignUp(signUpData);
      console.log(signUpResult);
      const { result, data, token } = signUpResult;
      if (result) {
        const {
          userId,
          nickName,
          robotId,
          robotPId,
          userPId,
          insider,
          version,
        } = data;
        INSIDER.verify = insider;
        await setCurrentUser({
          userPId,
          userId,
          robotId,
          robotPId,
          nickName,
        });
        await setLocalStorage('version', version);
        // db 데이터
        await setUserToken(token);
      } else {
        const { error: err } = signUpResult;
        error = getErrorMsg(getErrorCode(err), 'common:ERR.UNSPECIFIC');
      }
    }

    if (error) {
      returnData = {
        btn: getBtnState(currentIndex, currentData),
        data: {
          ...state.data,
          [currentIndex]: { ...currentData, error },
        },
      };
    } else {
      returnData = {
        activeIndex,
        btn: getBtnState(activeIndex, state.data[activeIndex]),
        complete: {
          message: i18.t('user:SIGN_UP.COMPLETE', { first: firstName }),
          to: '/connect',
        },
      };
    }
  }

  if (activeIndex > steps.length) {
    return dispatch(next({ activeIndex, btn: [] }));
  }

  if (!returnData) {
    returnData = {
      activeIndex,
      btn: getBtnState(activeIndex, state.data[activeIndex]),
    };
  }

  return dispatch(next(returnData));
};
export const onPrev = () => async (dispatch, getState) => {
  const { social: state } = getState();
  const { activeIndex: currentIndex } = state;
  const activeIndex = state.activeIndex - 1;
  const data = {
    ...state.data,
    [currentIndex]: { ...originData[currentIndex] },
  };

  return dispatch(
    prev({
      activeIndex,
      btn: getBtnState(
        activeIndex,
        activeIndex < 0 ? state.data[currentIndex] : state.data[activeIndex],
      ),
      data,
    }),
  );
};

export const onChange = value => async (dispatch, getState) => {
  const { social: state } = getState();
  const { activeIndex } = state;
  let { btn } = state;
  const data = { ...state.data };
  if (activeIndex === 1) {
    const { tel, email } = value;
    const telMsg = validateTel(tel);
    const mailMsg = validateEmail(email);
    if (tel.value) {
      data[activeIndex] = { tel, email: { ...originStep1Data } };
      if (telMsg) {
        data[activeIndex].error = telMsg;
      }
    } else if (email.value) {
      data[activeIndex] = { tel: { ...originStep1Data }, email };
      if (mailMsg) {
        data[activeIndex].error = mailMsg;
      }
    } else {
      data[activeIndex] = {
        tel: { ...originStep1Data },
        email: { ...originStep1Data },
        error: { code: '', message: '' },
      };
    }
    btn = getBtnState(activeIndex, data[activeIndex]);
  } else {
    data[activeIndex] = { ...value };
    btn = getBtnState(activeIndex, data[activeIndex]);
  }

  dispatch(setData({ btn, data }));
};

export const initialData = () => async (dispatch, getState) => {
  const {
    user: { data: socialdata },
    social: { data: oldData },
  } = getState();
  const { loginId, loginType, email } = socialdata;
  if (email) {
    const data = {
      ...oldData,
      '1': {
        tel: { ...originStep1Data },
        email: { value: email, sent: true, confirm: true, time: 0 },
        error: { code: '', message: '' },
      },
    };
    console.log(483, data);
    dispatch(init({ social: { loginId, loginType }, data }));
  } else {
    dispatch(init({ social: { loginId, loginType } }));
  }
};

export default socialReducer;
