/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { Icon } from 'semantic-ui-react';
import shortid from 'shortid';
import i18n from '../../../lang/i18n';
import { BotImage } from '../../Components';
import { StyledMessage } from '../../Message';
import { ColorItem } from './Components';
import ExecCard from './ExecCard';

const ExecuteList = ({ t, error, bPreview, executes, onCancel, onPreview }) => (
  <>
    <div
      style={{
        width: '100%',
        display: 'inline-flex',
        justifyContent: 'space-between',
      }}
    >
      <p className="label">{i18n.t('bot:TRN.LABEL.EXECUTE')}</p>
      <p
        className="preview-btn"
        aria-hidden="true"
        onClick={() => onPreview()}
        style={{ opacity: executes.length === 0 ? 0.45 : 1 }}
      >
        {typeof bPreview === 'boolean' && !bPreview && (
          <>
            <Icon name="play" />
            {i18n.t('bot:TRN.LABEL.PREVIEW')}
          </>
        )}
        {typeof bPreview === 'boolean' && bPreview && (
          <>
            <Icon name="stop" />
            {i18n.t('bot:TRN.LABEL.STOP')}
          </>
        )}
      </p>
    </div>
    {error && (
      <StyledMessage color="red" textalign="left" size="mini">
        {error}
      </StyledMessage>
    )}
    {executes.length === 0 && <p>{i18n.t('bot:TRN.MSG.ADD_EXECUTE')}</p>}
    {executes.length > 0 &&
      executes.map(
        (
          {
            card: { color, icon, label: title },
            speak,
            _eye,
            _fx,
            bot,
            motion,
            bg,
          },
          i,
        ) => (
          <ExecCard
            {...{
              t,
              color,
              icon,
              title,
              bPreview: bPreview === i,
              gap: '.5rem',
              options: {
                onCancel: () => onCancel(i),
                onPreview: () => onPreview(i),
              },
            }}
            key={shortid.generate()}
          >
            <div
              style={{
                margin: '0 0.75rem',
                display: 'flex',
                flexFlow: 'column',
              }}
            >
              {speak && speak.value && (
                <div className="exec-content">
                  <strong>{speak.value}</strong>
                  {i18n.t('bot:TRN.LABEL.SPEAK_EXECUTE')}
                </div>
              )}
              {bot && (
                <div className="exec-content">
                  <BotImage id={bot.key} alt={bot.label} />
                  <strong>{bot.label}</strong>
                  {i18n.t('bot:TRN.MSG.FROM_BOT')}
                  <strong>{bot.value}</strong>
                  {i18n.t('bot:TRN.LABEL.BOT_EXECUTE')}
                </div>
              )}
              {motion && (
                <div className="exec-content">
                  <strong>{i18n.t(motion.label)}</strong>
                  {i18n.t('bot:TRN.LABEL.ACTION_EXECUTE')}
                </div>
              )}
              {bg && (
                <div className="exec-content">
                  <strong>{bg.label}</strong>
                  {i18n.t('bot:TRN.LABEL.BG_PLAY')}
                </div>
              )}
              {_eye && (
                <div className="exec-content">
                  <ColorItem color={_eye.value} padded strong />
                  {i18n.t('bot:TRN.MSG.CHANGE_EYE', _eye.label)}
                </div>
              )}
              {_fx && (
                <div className="exec-content">
                  <strong>{_fx.label}</strong>
                  {i18n.t('bot:TRN.LABEL.FX_PLAY')}
                </div>
              )}
            </div>
          </ExecCard>
        ),
      )}
    {error && executes.length > 2 && (
      <StyledMessage color="red" textalign="left" size="mini">
        {error}
      </StyledMessage>
    )}
  </>
);

export default ExecuteList;
