/* eslint-disable */
import React, { useState, useEffect } from 'react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ViewSchedule from '../../../components/bots/CalendarBot/ViewSchedule';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';

import {
  deleteCalendar,
  getScheduleItem,
} from '../../../ducks/bots/calendarBot/calendarBot';
import Confirm from '../../../components/Confirm';
import VerticalConfirm from '../../../components/VerticalConfirm';

let prevDeleting = false;
const ScheduleViewContainer = props => {
  const { t } = useTranslation('bot');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name, date } = props;
  const {
    loading,
    deleting,
    deleteError,
    selectError: error,
    selectSchedule: schedule,
    selectedDate,
  } = useSelector(state => state.calendarBot);
  const { repeat } = schedule;
  const [popUpProperties, setPopupProperties] = useState({ isPopup: false });
  const formattedDate = moment(new Date(selectedDate)).format('L');

  useEffect(() => {
    if (name) {
      dispatch(getScheduleItem({ id: name, date }));
    }
  }, []);
  useEffect(() => {
    if (!popUpProperties.isPopup) {
      if (error && error.length > 0) {
        setPopupProperties({
          contents: error,
          rightLabel: t('common:POP.OK'),
          rightFunc: () => navigate('/bots/OFFICIAL_CALENDAR'),
          mode: 'alert',
          isPopup: true,
          onClose: () => setPopupProperties({ isPopup: false }),
        });
      } else if (deleteError && deleteError.length > 0) {
        setPopupProperties({
          contents: deleteError,
          rightLabel: t('common:POP.OK'),
          mode: 'alert',
          isPopup: true,
          onClose: () => setPopupProperties({ isPopup: false }),
        });
      } else if (prevDeleting && !deleting) {
        setPopupProperties({
          contents: t('CAL.DELETE.SUCCESS'),
          rightLabel: t('common:POP.OK'),
          rightFunc: () => navigate('/bots/OFFICIAL_CALENDAR'),
          mode: 'confirm',
          isPopup: true,
          onClose: () => setPopupProperties({ isPopup: false }),
        });
      }
    }
    prevDeleting = deleting;
  }, [error, deleteError, deleting]);

  const handleUpdateClick = val => {
    navigate(`/bots/OFFICIAL_CALENDAR/update?name=${val}&date=${date}`);
  };

  const handleDeleteClick = id => {
    if (repeat) {
      setPopupProperties({
        isPopup: true,
        items: [
          {
            label: t('CAL.DELETE.ALL'),
            func: () => dispatch(deleteCalendar({ id, all: true })),
            color: 'blue',
          },
          {
            label: t('CAL.DELETE.ITEM'),
            func: () => dispatch(deleteCalendar({ id, all: false })),
            color: 'blue',
          },
          {
            label: t('common:POP.CANCEL'),
            func: () =>
              setPopupProperties({ popUpProperties: { isPopup: false } }),
          },
        ],
        contents: t('CAL.DELETE.REPEAT_ALL'),
        onClose: () => setPopupProperties({ isPopup: false }),
      });
    } else {
      setPopupProperties({
        contents: t('CAL.DELETE.SELECT_ONE'),
        leftLabel: t('common:POP.CANCEL'),
        rightLabel: t('common:POP.OK'),
        rightFunc: () => dispatch(deleteCalendar({ id, all: true })),
        mode: 'confirm',
        isPopup: true,
        onClose: () => setPopupProperties({ isPopup: false }),
      });
    }
  };
  return (
    <div>
      <BotTopMenu
        title={t('CAL.LB', { formattedDate })}
        menuMode="WITH_BACK"
        backLocation="/bots/OFFICIAL_CALENDAR"
      />
      {!error && !loading && (
        <ViewSchedule
          {...props}
          schedule={schedule}
          onUpdate={handleUpdateClick}
          onDelete={handleDeleteClick}
        />
      )}
      {popUpProperties.isPopup && 'items' in popUpProperties && (
        <VerticalConfirm {...popUpProperties} />
      )}
      {popUpProperties.isPopup && !('items' in popUpProperties) && (
        <Confirm {...popUpProperties} />
      )}
    </div>
  );
};

export default ScheduleViewContainer;
