/* eslint-disable react/destructuring-assignment */
import React, { Fragment, useState } from 'react';
import { useUnmount } from 'react-use/lib';
import Loading from '../components/Loading';

const withLoading = WrappedComponent => props => {
  const [animation, setAnimation] = useState(null);
  const handleAnimationStart = () => {
    setAnimation(true);
  };
  const handleAnimationEnd = () => {
    setAnimation(false);
  };
  useUnmount(() => {
    setAnimation(null);
  });

  return (
    <>
      <WrappedComponent {...props} />
      {(props.loading || animation) && (
        <Loading onStart={handleAnimationStart} onEnd={handleAnimationEnd} />
      )}
    </>
  );
};

export default withLoading;
