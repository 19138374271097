/* eslint-disable react/no-array-index-key */
import moment from 'moment';
import React from 'react';
import { Header } from 'semantic-ui-react';
import { getCalendarType } from '../../../utils/common';
import CalendarItemLabel from '../CalendarBot/CalendarItemLabel';

const CalendarCard = data => {
  const { list, type, date } = data;
  const isToday = type === 'today';
  return (
    <div style={{ display: 'flex', flexFlow: 'column' }}>
      <Header as="h5" style={{ fontSize: '0.875rem', margin: '0 0 .5rem 0' }}>
        {list.length > 0
          ? moment(date)
              .add(!isToday && 1, 'days')
              .format('LL dddd')
          : `${isToday ? '오늘' : '내일'}은 등록된 일정이 없어.`}
      </Header>
      {list.length > 0 &&
        list.map(({ bot, hour, minute, title, type: t }, i) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
            key={`${date}_${i}`}
          >
            <CalendarItemLabel
              {...{
                title,
                type: getCalendarType(t),
                time: moment()
                  .hour(hour)
                  .minute(minute)
                  .format('HH:mm'),
                bot,
              }}
            />
          </div>
        ))}
    </div>
  );
};

export default CalendarCard;
