/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable prefer-promise-reject-errors */
import fetchJsonp from 'fetch-jsonp';
import {
  savePiboData,
  getConnectedSSID,
  // getStatus,
  // userVerify,
} from '../utils/api';
import {
  setCurrentUser,
  getRobotPId,
  getLocale,
  getUserId,
  getUserPId,
  Alert,
  INSIDER,
  // getUserToken,
  getMobileOS,
  OS_IOS,
  // errorLog,
} from '../utils/common';
// import { NETWORK_CHECK_TIMEOUT } from '../utils/consts';
import { ROBOT_URL } from '../circulusConfig';
import { piboInit, wifiReset, sendSaveStatus } from '../pibo';
// import { piboInit, getAlive, wifiReset, sendSaveStatus } from '../pibo';
import i18 from '../lang/i18n';
import { errorLog, infoLog } from '../utils/report';
// import { reporter } from '../utils/report';

export const LOADING = 'connect/LOADING';
export const FETCH_APLIST_SUCCESS = 'connect/FETCH_APLIST_SUCCESS';
export const FETCH_APLIST_FAILED = 'connect/FETCH_APILIST_FAILED';
export const CONNECT_ROBOT_SUCCESS = 'connect/CONNECT_ROBOT_SUCCESS';
export const RECONNECT_ROBOT_SUCCESS = 'connect/RECONNECT_ROBOT_SUCCESS';
export const RECONNECT_ROBOT_FAILED = 'connect/RECONNECT_ROBOT_FAILED';
export const FETCH_SSID_SUCCESS = 'connect/FETCH_SSID_SUCCESS';
export const UNVALID_SSID_FAILED = 'connect/UNVALID_SSID_FAILED';
export const CONNECT_STEP_COMPLETE = 'connect/CONNECT_STEP_COMPLETE';
export const FAILED_STEP_COMPLETE = 'connect/FAILED_STEP_COMPLETE';
export const ERROR = 'connect/ERROR';
export const INITIALIZE = 'connect/INITIALIZE';
const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

export function connectInitialize() {
  return {
    type: INITIALIZE,
  };
}

export function connectToRobotSuccess() {
  return {
    type: CONNECT_ROBOT_SUCCESS,
  };
}
export function connectStepComplete() {
  return {
    type: CONNECT_STEP_COMPLETE,
  };
}
export function failedStepComplete(errorMsg) {
  return {
    type: FAILED_STEP_COMPLETE,
    errorMsg,
  };
}
export function fetchApListSuccess(data) {
  return {
    type: FETCH_APLIST_SUCCESS,
    data,
  };
}
export function fetchApListFailed() {
  return {
    type: FETCH_APLIST_FAILED,
  };
}
export function connectLoading(ap) {
  return {
    type: LOADING,
    ap,
  };
}

export function connectError(errorMsg) {
  return {
    type: ERROR,
    errorMsg,
  };
}

export function fetchSsidSuccess(ssid, robotPId) {
  return {
    type: FETCH_SSID_SUCCESS,
    ssid,
    robotPId,
  };
}

export function UnvalidSsidFailed(errorMsg) {
  return {
    type: UNVALID_SSID_FAILED,
    errorMsg,
  };
}

export function failedReconnect(errorMsg) {
  return {
    type: RECONNECT_ROBOT_FAILED,
    errorMsg,
  };
}
export function successReConnect() {
  return {
    type: RECONNECT_ROBOT_SUCCESS,
  };
}

const initialState = {
  loading: false,
  errorMsg: '',
  ssid: '',
  aplist: [],
  robotId: '',
  stepComplete: false,
  connectedRobot: false,
  failedConnect: false,
};

export default function connectReducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE:
      return {
        ...initialState,
      };
    case RECONNECT_ROBOT_FAILED:
      return {
        ...state,
        loading: false,
        failedConnect: true,
        errorMsg: action.errorMsg,
      };
    case RECONNECT_ROBOT_SUCCESS:
      return {
        ...state,
        loading: false,
        failedConnect: false,
        errorMsg: '',
      };
    case LOADING:
      return {
        ...state,
        errorMsg: '',
        ssid: action.ap ? action.ap.essid : '',
        loading: true,
      };
    case ERROR:
      return {
        ...state,
        ssid: '',
        loading: false,
        errorMsg: action.errorMsg,
      };
    case UNVALID_SSID_FAILED:
      return {
        ...state,
        ssid: '',
        errorMsg: action.errorMsg,
      };
    case FETCH_SSID_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        ssid: action.ssid,
        robotPId: action.robotPId,
      };
    case FETCH_APLIST_FAILED:
      return {
        ...state,
        loading: false,
        errorMsg: 'failed load aplist',
      };
    case FETCH_APLIST_SUCCESS:
      return {
        ...state,
        aplist: action.data.aplist,
        loading: false,
        errorMsg: '',
      };
    case CONNECT_ROBOT_SUCCESS:
      return {
        ...state,
        failedConnect: false,
        connectedRobot: true,
        loading: false,
        errorMsg: '',
      };
    case CONNECT_STEP_COMPLETE:
      return {
        ...state,
        loading: false,
        errorMsg: '',
        failedConnect: false,
        stepComplete: true,
      };
    case FAILED_STEP_COMPLETE:
      return {
        ...state,
        failedConnect: true,
        loading: false,
        errorMsg: action.errorMsg,
      };
    default:
      return state;
  }
}

export const initConnect = () => dispatch => {
  dispatch(connectInitialize());
};

export const resetWifiConnectAsync = () =>
  new Promise((res, rej) => {
    wifiReset(result => {
      if (!result) {
        rej({ error: 'error' });
      }
      res(result);
    });
  });

export const fetchApList = () => async dispatch => {
  dispatch(connectLoading());
  const { http } = window;
  if (http) {
    http.get(
      `${ROBOT_URL}wifi`,
      {},
      {},
      res => {
        const { data } = res;
        const { robotId, list } = JSON.parse(data);
        if (robotId && list) {
          setCurrentUser({ robotPId: robotId });
          return dispatch(
            fetchApListSuccess({ robotPId: robotId, aplist: list }),
          );
        }
        return dispatch(fetchApListFailed());
      },
      () => dispatch(fetchApListFailed()),
    );
  } else {
    try {
      fetchJsonp(`${ROBOT_URL}wifi`, { timeout: 15000 })
        .then(response => response.json())
        .then(result => dispatch(fetchApListSuccess({ aplist: result.list })))
        .catch(error => {
          errorLog(error);
          dispatch(fetchApListFailed());
        });
    } catch (error) {
      errorLog(error);
      dispatch(fetchApListFailed());
    }
  }
};

export const fetchConnectResult = data => async dispatch => {
  // 로봇을 찾는 통신 부분
  // 로봇을 찾고 난 후 받는 데이터(로봇의 시리얼 등...)
  // 데이터를 기반으로 새로운 로봇 정보를 db에 추가한다.
  dispatch(connectLoading());
  const { userId, serial } = data;
  if (INSIDER) {
    infoLog('INSIDER', JSON.stringify(data));
  }
  if (!(userId && serial)) {
    if (!userId) {
      wifiReset();
      return dispatch(
        connectError(
          getErrorMsg(
            getErrorCode({ code: '4P206C' }),
            'common:ERR.UNSPECIFIC',
          ),
        ),
      );
    }
    if (!serial) {
      return dispatch(
        connectError(
          getErrorMsg(
            getErrorCode({ code: '4P206B' }),
            'common:ERR.UNSPECIFIC',
          ),
        ),
      );
    }
  }
  const res = await savePiboData({ ...data });
  const { result, robotId } = res;
  if (result) {
    await setCurrentUser({ robotId });
    sendSaveStatus();
    return dispatch(connectToRobotSuccess());
  }
  // const reset = await resetWifiConnectAsync();
  return dispatch(
    connectError(getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC')),
  );
};

export const fetchCurrentSSID = () => async dispatch => {
  const ssid = await getConnectedSSID();
  if (ssid.search('pibo_') > -1) {
    return dispatch(fetchSsidSuccess(ssid));
  }
  return dispatch(UnvalidSsidFailed(i18.t('conn:MSG.NOT_PIBO_WIFI')));
};

export const fetchRobotURL = () => dispatch => {
  dispatch(connectLoading());

  // let cnt = 0;
  const timeId = setInterval(() => {
    fetchJsonp(`${ROBOT_URL}info`, { timeout: 15000 })
      .then(response => response.json())
      .then(result => {
        clearInterval(timeId);
        const { serial } = result;
        if (serial) {
          setCurrentUser({ robotPId: serial });
          return dispatch(fetchSsidSuccess(`pibo_${serial}`, serial));
        }
        return dispatch(UnvalidSsidFailed(i18.t('conn:MSG.NOT_PIBO_WIFI')));
      })
      .catch(() => {
        // errorLog(i18.t('conn:ERR.UNVALID_INFO'));
        dispatch(UnvalidSsidFailed(i18.t('conn:ERR.UNVALID_INFO')));
      });
  }, 1500);
};

// const connected = false;
export const connectAp = ap => async dispatch => {
  dispatch(connectLoading(ap));
  const { essid, wpa, password } = ap;
  // let cnt = 0;
  // let timer = null;
  // let failed = false;
  const time = Date.now();
  const url = `${ROBOT_URL}confirm?id=${essid}&type=${
    wpa ? 'wpa' : 'wep'
  }&pass=${password}&uoid=${getUserPId()}&time=${time}`;

  let beAlramed = false;
  let offlineToOnline = true;
  let cnt = 0;
  const maxCnt = getMobileOS() === OS_IOS ? 3 : 10;
  fetchJsonp(url, { timeout: 15000 }).catch(() => {
    piboInit(getRobotPId());
    // errorLog(`in catch ${getRobotPId()}`);
    fetchJsonp(`${ROBOT_URL}info`, { timeout: 15000 })
      .then(response => response.json())
      .catch(() => {
        const onSave = async () => {
          if (!beAlramed) {
            Alert(i18.t('conn:MSG.CONNECTED_WIFI'));
            beAlramed = true;
          }
          const userId = getUserId();
          const locale = getLocale();
          const serial = getRobotPId();
          if (userId && locale && serial) {
            const res = await savePiboData({ userId, serial, locale });
            const { result, robotId } = res;
            if (result) {
              await setCurrentUser({ robotId });
              sendSaveStatus();
              dispatch(connectStepComplete());
              dispatch(connectToRobotSuccess());
            } else {
              return dispatch(
                connectError(
                  getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC'),
                ),
              );
            }
            if (!(userId && serial)) {
              if (!userId) {
                wifiReset();
                return dispatch(
                  connectError(
                    getErrorMsg(
                      getErrorCode({ code: '4P206C' }),
                      'common:ERR.UNSPECIFIC',
                    ),
                  ),
                );
              }
              if (!serial) {
                return dispatch(
                  connectError(
                    getErrorMsg(
                      getErrorCode({ code: '4P206B' }),
                      'common:ERR.UNSPECIFIC',
                    ),
                  ),
                );
              }
            }
          }
        };
        window.addEventListener('online', () => {
          offlineToOnline = true;
        });
        window.addEventListener('offline', () => {
          offlineToOnline = false;
        });
        const ti = setInterval(() => {
          cnt += 1;
          if (offlineToOnline) {
            clearInterval(ti);
            onSave();
          } else if (maxCnt > cnt) {
            clearInterval(ti);
            return dispatch(
              connectError(
                getErrorMsg(
                  getErrorCode({ code: '4P206B' }),
                  'common:ERR.UNSPECIFIC',
                ),
              ),
            );
          }
        }, 2000);
        // }
        // let cnt = 0;
        // cnt = tryConnect(cnt);
        // const ti = setInterval(() => {
        //   cnt = tryConnect(cnt, ti);
        // }, 5000);
      });
  });
};

export const reConnectAp = () => async (dispatch, getState) => {
  dispatch(connectLoading());
  const { WifiWizard2 } = window;
  const { connect } = getState();
  const { ssid } = connect;
  let list;
  try {
    list = await WifiWizard2.getScanResults();
  } catch (error) {
    // errorLog(error);
    return dispatch(
      failedReconnect(i18.t('conn:ERR.NOT_FOUND_SSID', { ssid })),
    );
  }
  const findPiboAp = [...list].find(ap => ap.SSID === ssid);
  if (!findPiboAp) {
    return dispatch(
      failedReconnect(i18.t('conn:ERR.NOT_FOUND_SSID', { ssid })),
    );
  }
  if (findPiboAp) {
    let connectResult;
    try {
      connectResult = await WifiWizard2.connect(
        ssid,
        true,
        '1234567890',
        'WPA',
      );
    } catch (error) {
      // errorLog(error);
      return dispatch(
        failedReconnect(i18.t('conn:ERR.NOT_FOUND_SSID', { ssid })),
      );
    }
    if (connectResult === 'NETWORK_CONNECTION_COMPLETED') {
      return dispatch(successReConnect(ssid));
    }
    return dispatch(
      failedReconnect(i18.t('conn:ERR.NOT_FOUND_SSID', { ssid })),
    );
  }
};

export const registerRobotByAdmin = data => async dispatch => {
  dispatch(connectLoading());
  const { serial } = data;
  const res = await savePiboData({ ...data });
  const { result } = res;
  if (result) {
    return dispatch(connectToRobotSuccess(serial));
  }

  return dispatch(connectError(res.error));
};
