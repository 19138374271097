import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useMount, useUpdateEffect } from 'react-use';
import { useNavigate } from 'react-router-dom';
import TopMenu from '../../components/TopMenu';
import UserSettingList from '../../components/pibo/settings/UserSettingList';
import Popup from '../../components/Popup';
import { getUserId, pageRefresh } from '../../utils/common';
import { Page } from '../../components/Components';
import { fetchUserInfoData, init, signOut } from '../../ducks/userinfo';
import { reducerInit } from '../../ducks';
import withAuth from '../../hocs/withAuth';

let isMounted = false;

const UserSettingPage = () => {
  const { t } = useTranslation('user');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userinfo = useSelector(state => state.userinfo);
  const { isComplete } = userinfo;
  const userId = getUserId();
  const [isPopup, setPopState] = useState(false);
  const [popupObj, setPopup] = useState({
    leftLabel: t('common:POP.CANCEL'),
    rightLabel: t('common:POP.OK'),
    mode: 'confirm',
    isPopup: true,
    leftFunc: () => {
      setPopState(false);
    },
    rightFunc: () => {
      setPopState(false);
    },
    contents: <Container text textAlign="center" />,
  });

  useMount(() => {
    if (!isMounted) {
      isMounted = true;
    } else {
      dispatch(init());
    }
    dispatch(fetchUserInfoData(userId));
  });

  const handleSignOut = () => {
    dispatch(reducerInit());
    dispatch(signOut());
  };

  const handleItemClick = name => {
    if (name === 'logout') {
      popupObj.rightLabel = t('SIGN_OUT.LB');
      popupObj.mode = 'confirm';
      popupObj.header = t('SIGN_OUT.LB');
      popupObj.contents = t('SIGN_OUT.MSG');

      setPopState(true);
      setPopup(popupObj);
    } else if (getUserId() && name === 'pwupdate') {
      navigate(`/update/${getUserId()}`);
    } else if (getUserId()) {
      navigate(`/setting/${getUserId()}/${name}`);
    }
  };

  const handleBackClick = () => navigate('/pibo');

  useUpdateEffect(() => {
    if (isComplete) {
      if (isPopup) {
        setPopState(false);
      }
      dispatch(init());
      pageRefresh();
    }
  }, [isComplete, isPopup]);

  return (
    <Page>
      <TopMenu
        title={t('SET.LB')}
        menuMode="WITH_BACK"
        handleBackClick={handleBackClick}
      />
      {!isPopup && (
        <UserSettingList {...userinfo} handleItemClick={handleItemClick} />
      )}
      {isPopup && (
        <Popup {...popupObj} isPopup={isPopup} rightFunc={handleSignOut} />
      )}
    </Page>
  );
};

export default withAuth(UserSettingPage);
