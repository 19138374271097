import React from 'react';
import { Form, Checkbox, Message } from 'semantic-ui-react';
import MDEditor from '@uiw/react-md-editor';
import styled from 'styled-components';
import { StyledMessage } from '../Message';

const TermsFormField = styled(Form.Field)`
  margin-bottom: 1rem !important;
`;

const TermsMarkDown = styled(MDEditor.Markdown)`
  &.wmde-markdown {
    font-family: -apple-system, BlinkMacSystemFont, Comfortaa, Nanum Gothic,
      Noto Sans SC, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-size: 0.75rem;
  }
  margin: 0.875rem 0 !important;
  background: #fff;
  border: 1px solid rgba(8, 148, 215, 1) !important;
  border-radius: 0 !important;
  padding: 1rem 1rem;
  line-height: 1.25em;
  min-height: 6rem;
  max-height: 15vh;
  overflow-y: scroll;
`;

const TermsCheckBox = styled(Checkbox)`
  &&& {
    font-size: 0.875rem !important;
    line-height: 1.25rem;
  }
`;

const Terms = ({
  t,
  serviceTerms,
  privacyTerms,
  locationTerms,
  service,
  privacy,
  location,
  msg,
  onChange,
}) => (
  <div>
    <Form>
      <Form.Group grouped>
        <TermsFormField>
          <TermsCheckBox
            name="all"
            label={t('TERM.CHECK_ALL')}
            onChange={onChange}
            checked={service && privacy && location}
          />
        </TermsFormField>
        <StyledMessage
          color="red"
          hidden={!msg}
          textalign="left"
          className="mini"
        >
          <Message.Content>{msg}</Message.Content>
        </StyledMessage>
        <TermsFormField>
          <TermsCheckBox
            label={t('TERM.SERVICE')}
            name="service"
            checked={service}
            onChange={onChange}
          />
          <TermsMarkDown source={serviceTerms} className="scrollbar-hide" />
        </TermsFormField>
        <TermsFormField>
          <TermsCheckBox
            label={t('TERM.PRIVACY')}
            name="privacy"
            checked={privacy}
            onChange={onChange}
          />
          <TermsMarkDown source={privacyTerms} className="scrollbar-hide" />
        </TermsFormField>
        <TermsFormField>
          <TermsCheckBox
            label={t('TERM.LOCATION')}
            name="location"
            checked={location}
            onChange={onChange}
          />
          <TermsMarkDown source={locationTerms} className="scrollbar-hide" />
        </TermsFormField>
        {/* <TermsFormField>
              <TermsCheckBox
                label={t('TERM.PROMOTION')}
                name="promotion"
                checked={promotion}
                onChange={onChange}
              />
              <TermsMarkDown source={promotionTerms} />
            </TermsFormField> */}
      </Form.Group>
    </Form>
    <StyledMessage
      color="red"
      hidden={!msg}
      textalign="left"
      className="mini"
      style={{ marginBottom: '3rem' }}
    >
      <Message.Content>{msg}</Message.Content>
    </StyledMessage>
  </div>
);

export default Terms;
