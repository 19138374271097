import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const AppSendOpinion = () => {
  // const [content, setContent] = useState('');
  // const { onOpinionSendClick } = props;

  // const handleTAChange = (e, { value }) => {
  //   setContent(value);
  // };

  // const handleSendClick = () => {
  //   onOpinionSendClick(content);
  // };
  const { t } = useTranslation('app');
  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <p>{t('INFO.SEND_FEEDBACK.PURPOSE')}</p>
          <p>
            {t('INFO.SEND_FEEDBACK.MAIL')}
            <br />
            <a href="mailto:info@circul.us">info@circul.us</a>
          </p>
          <p>
            {t('INFO.SEND_FEEDBACK.OFFICIAL')}
            <a
              href="http://mybo.circul.us"
              target="_blank"
              rel="noopener noreferrer"
            >
              mybo.circul.us
            </a>
          </p>
        </Grid.Column>
      </Grid.Row>
      {/* <Grid.Row>
        <Grid.Column>
          <Form loading={loading}>
            <Form.TextArea
              label="{t('INFO.CONTENT')}"
              style={{ height: '10rem' }}
              onChange={handleTAChange}
              value={content}
            />
            <Form.Button color="blue" fluid onClick={handleSendClick}>
              {t('INFO.SEND.LB')}
            </Form.Button>
          </Form>
          {error.length > 0 && (
            <Message negative>
              <Message.Header>{error}</Message.Header>
            </Message>
          )}
        </Grid.Column>
      </Grid.Row> */}
    </Grid>
  );
};

export default AppSendOpinion;
