import React, { useState } from 'react';
import { Accordion, AccordionContent, AccordionTitle } from 'semantic-ui-react';
import styled from 'styled-components';
import MDEditor from '@uiw/react-md-editor';
import moment from 'moment';

import { useMount } from 'react-use';
import { PiboWrap } from '../pibo/PiboComponents';
import { getNotice } from '../../utils/api';
import { setLocalStorage } from '../../utils/common';

const NoticeAccordion = styled(Accordion)`
  position: relative;
  border: 0;
  border-radius: 0;
  text-align: left;
  margin: 0 !important;
  &.ui.fluid.accordion,
  .ui.fluid.accordion .accordion {
    border-bottom: rgba(235, 236, 237, 0.3) 1px solid;
  }
`;

const NoticeAccordionTitle = styled(AccordionTitle)`
  & > span[role=img] {
    font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols;
    margin-right: 0.35em;
  }
  &&& {
    padding: 1em 2em 1em 0.75em !important;
    font-size: 1rem !important;
    font-weight: bold;
    background: ${({ color }) => color || '#fff'};
    position: relative;
    display: inline-flex;
    width: 100%;
    /* box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.1); */
  }
  ::after {
    color: rgba(0,0,0, 0.25);
    content: '${({ active }) => (active ? '\f077' : '\f078')}';
    position: absolute;
    top: calc(calc(100% - 1.2em)/2);
    right: 0.75em;
    display: ${({ foldicon }) => (foldicon ? 'inline-block' : 'none')};
    font-size: 1em;
    opacity: 1;
    /* margin: 0 0.25rem 0 0; */
    width: 1.18em;
    height: 1em;
    font-family: Icons;
    font-style: normal;
    font-weight: 100;
    text-decoration: inherit;
    text-align: center;
    backface-visibility: hidden;
  }
`;

const NoticeAccordionContent = styled(AccordionContent)`
  padding: 1em 0.75em !important;
  background: white;
  & > .wmde-markdown.wmde-markdown-color {
    font-family: -apple-system, BlinkMacSystemFont, Comfortaa, Nanum Gothic,
      Noto Sans SC, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-size: 0.875rem;
    line-height: 1.25em;
    overflow: hidden;
    word-spacing: -1px;
    ul {
      & p {
        margin: 0;
      }
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 1.5em;
      & > li strong {
        font-weight: bold;
        margin: 0.5em 0;
        display: inline-block;
        /* margin-bottom: 0.5em; */
        /* font-weight: normal; */
      }
      & ul {
        margin-block-start: 0;
        margin-block-end: 0;
        list-style-type: none;
        padding-inline-start: 0;
        font-weight: normal;
        padding: 0.5em;
        /* background: rgba(3, 191, 215, 0.15); */
        background: rgba(235, 236, 237, 0.3);
        border-radius: 5px;
        & > li {
          padding-top: 0.25em;
          padding-bottom: 0.5em;
          & > ul {
            list-style-type: square;
            padding-inline-start: 1.5em;
            background: transparent;
            /* padding: 0; */
            padding-top: 0.5em;
            & li {
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        }
      }
    }
    & > ol {
      font-weight: 600;
      & > li > :nth-child(n + 2) {
        font-weight: 100;
      }
    }
    ol {
      padding-inline-start: 1.85em;
      letter-spacing: -1px;
    }
    p {
      margin: 0.5em 0;
    }
    blockquote {
      border-left: #d1d3d4 2px solid;
      margin-inline-start: 0.5em;
      margin-inline-end: 0.5em;
      font-size: smaller;
      & > p {
        margin: 0.5em;
      }
    }
    img {
      width: 100%;
    }
    pre {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
`;

const DateString = styled.p`
  text-align: right;
  color: #d3d4d1;
  font-size: 0.75rem;
  margin-bottom: 0;
`;

const AppNotice = () => {
  const [select, setSelect] = useState(null);
  const [data, setData] = useState(null);
  const prev = parseInt(localStorage.getItem('notice'), 10);

  useMount(async () => {
    const res = await getNotice();
    const { result, data: d } = res;
    if (result) {
      setData(d);
    }
  });

  const handleClick = (e, p) => {
    const { name, time } = p;
    if (!prev || prev < time) {
      setLocalStorage('notice', time);
    }
    if (select === name) {
      setSelect(null);
    } else {
      setSelect(name);
    }
  };

  return (
    <div style={{ fontSize: '0.875rem' }}>
      <PiboWrap style={{ padding: '1rem' }}>
        {data &&
          data.fixed.map(
            ({ _id: id, title, emoji, content, createdAt, firstTime }) => (
              <NoticeAccordion fluid key={id}>
                <NoticeAccordionTitle
                  foldicon="true"
                  name={id}
                  time={firstTime}
                  onClick={handleClick}
                  active={id === select}
                >
                  <span role="img" aria-label={emoji}>
                    {emoji}
                  </span>
                  {title}
                </NoticeAccordionTitle>
                <NoticeAccordionContent active={id === select}>
                  <DateString>{moment(createdAt).format('L')}</DateString>
                  <MDEditor.Markdown source={content} />
                </NoticeAccordionContent>
              </NoticeAccordion>
            ),
          )}
      </PiboWrap>
      <PiboWrap style={{ padding: '0 1rem 1rem' }}>
        {data &&
          data.list.map(({ _id: id, title, content, createdAt, firstTime }) => (
            <NoticeAccordion fluid key={id}>
              <NoticeAccordionTitle
                foldicon="true"
                name={id}
                time={firstTime}
                onClick={handleClick}
                active={id === select}
              >
                {title}
              </NoticeAccordionTitle>
              <NoticeAccordionContent active={id === select}>
                <DateString>{moment(createdAt).format('L')}</DateString>
                <MDEditor.Markdown source={content} />
              </NoticeAccordionContent>
            </NoticeAccordion>
          ))}
      </PiboWrap>
    </div>
  );
};

export default AppNotice;
