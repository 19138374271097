/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Card,
  CardContent,
  Dropdown,
  Header,
  Icon,
  Image,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import BOT_DATA from './bot.json';
import { MenuButton, MessageMenu } from '../../Components';
import { getFile } from '../../../utils/api';
import StoryCard from './StoryCard';
import CameraCard from './CameraCard';
import SayingCard from './SayingCard';
import MusicCard from './MusicCard';
import MovieCard from './MovieCard';
import TravelCard from './TravelCard';
import GonghunCard from './GonghunCard';
import BookCard from './BookCard';
import WeatherCard from './WeatherCard';
import NewWeatherCard from './NewWeatherCard';
import FaceDetectCard from './FaceDetectCard';
import ObjectDetectCard from './ObjectDetectCard';
import CalendarCard from './CalendarCard';
import ClockCard from './ClockCard';
import MeditationCard from './MeditationCard';
import MessageCard from './MessageCard';
import TrendCard from './TrendCard';
import WikiCard from './WikiCard';
import DiaryCard from './DiaryCard';
import NewsCard from './NewsCard';
import { environment } from '../../../circulusConfig';

const HCard = styled(Card)`
  && {
    width: calc(100% - 2rem);
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem !important;
    font-weight: 100;
    // box-shadow: 0 1px 2px 0 #d4d4d5, 0 0 0 1px #d4d4d5 !important;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.08) !important;
    background: ${({ background }) => background};
    color: ${({ textcolor: color }) => color};
    display: ${({ mute }) => (mute === 'true' ? 'none' : 'flex')};
  }
`;

const CardContentExtra = styled(CardContent)`
  padding: 0.1rem 0.3rem !important;
  font-size: 0.725rem !important;
  align-self: flex-end;
  &&&& {
    border-top: none !important;
  }
`;

const HistoryCardDropDown = styled(Dropdown)`
  border: 1px solid;
  border-radius: 1rem;
  width: 1rem;
  height: 1rem;
  transform: translate(3px, 4px);
  & > i {
    &.icon.ellipsis.horizontal {
      &::before {
        font-size: 0.75rem;
        display: inline-block;
        height: 1rem;
        transform: translate(1px, -3px);
      }
    }
  }
`;

const HCardContent = styled.div`
  padding: 1rem 0.5rem 0.5rem !important;
  overflow-wrap: break-word;
  & > h4.header {
    img.ui.image {
      margin-right: 0.5rem !important;
      width: 1.75rem;
    }
  }
  & img.photo {
    border-radius: 0.25em;
  }
`;

const HistoryCards = props => {
  const { q, a, bot, uuid, id, data, firstTime, handleDelete } = props;
  const { t } = useTranslation('bot');
  const [{ background, color }, setColor] = useState({
    background: '#fff',
    color: 'inherit',
  });

  const [header, setHeader] = useState(null);
  const [content, setContent] = useState(null);
  const [mute, setMute] = useState(false);
  useEffect(() => {
    if (bot && data) {
      const botData = BOT_DATA[environment][bot];
      if (botData) {
        const { title } = botData;
        const botName = bot.split('OFFICIAL_')[1];
        setHeader({ title, img: getFile({ icon: botData.id }) });
        switch (botName) {
          case 'BOOK':
            setContent(<BookCard {...{ ...data }} />);
            break;
          case 'CALENDAR':
            setContent(<CalendarCard {...{ ...data }} />);
            break;
          case 'CAMERA':
            setContent(<CameraCard {...{ ...data }} />);
            break;
          case 'CLOCK':
            setContent(<ClockCard {...{ ...data, firstTime }} />);
            break;
          case 'DIARY':
            setContent(<DiaryCard {...{ ...data, firstTime }} />);
            break;
          case 'FACE-DETECT':
            setContent(<FaceDetectCard {...{ ...data }} />);
            break;
          case 'GONGHUN':
            setContent(<GonghunCard {...{ ...data }} />);
            break;
          case 'MEDITATION':
          case 'MORNING-LETTER':
            setContent(<MeditationCard {...{ ...data }} />);
            break;
          case 'MESSAGE':
            setContent(<MessageCard {...{ ...data, firstTime }} />);
            break;
          case 'MOVIE':
            setContent(<MovieCard {...{ ...data }} />);
            break;
          case 'MUSIC':
          case 'SONG':
          case 'SOUND':
            setContent(<MusicCard {...{ ...data }} />);
            break;
          case 'NEWS':
            // eslint-disable-next-line no-unused-expressions
            'type' in data && data.type === 'youtube'
              ? setMute(true)
              : setContent(<NewsCard {...{ ...data, firstTime }} />);
            break;
          case 'OBJECT-DETECT':
            setContent(<ObjectDetectCard {...{ ...data }} />);
            break;
          case 'RELIGION':
          case 'SAYING':
            setContent(<SayingCard {...{ ...data }} />);
            break;
          case 'STORY':
            setContent(<StoryCard {...{ ...data }} />);
            break;
          case 'TRAVEL':
            setContent(<TravelCard {...{ ...data }} />);
            break;
          case 'TREND':
            setContent(<TrendCard {...{ ...data }} />);
            break;
          case 'WEATHER':
            setContent(
              'type' in data && data.type && data.type.length > 4 ? (
                <NewWeatherCard {...{ ...data, setColor }} />
              ) : (
                <WeatherCard {...{ ...data, setColor }} />
              ),
            );
            break;
          case 'WIKI':
            setContent(<WikiCard {...{ ...data }} />);
            break;
          case 'QRCODE':
            setMute(true);
            break;
          default:
            break;
        }
      }
    }
  }, [bot, data]);

  return (
    <HCard background={background} textcolor={color} mute={mute.toString()}>
      <HCardContent className="content">
        {header && (
          <Header as="h4" style={{ marginBottom: '0.75rem' }}>
            <Image
              src={header.img}
              style={{
                borderRadius: '50px',
                boxShadow: '0px 0px 2px 0px rgb(0 0 0 / 55%)',
              }}
            />
            {header.title}
          </Header>
        )}
        {!content && <div className="description">{a}</div>}
        {content}
      </HCardContent>
      <CardContentExtra extra style={{ position: 'relative' }}>
        <div>
          {moment(new Date(firstTime))
            .locale('ko')
            .format('LT')}
          <HistoryCardDropDown
            icon={<Icon name="ellipsis horizontal" />}
            direction="left"
            style={{
              color: '#A9A9A9',
              fontSize: '0.145rem',
              position: 'static',
            }}
          >
            <MessageMenu className="upper">
              <MenuButton onClick={() => handleDelete({ q, a, uuid, id })}>
                <Icon name="cancel" color="red" />
                {t('HST.DELETE.LB')}
              </MenuButton>
            </MessageMenu>
          </HistoryCardDropDown>
        </div>
      </CardContentExtra>
    </HCard>
  );
};

export default HistoryCards;
