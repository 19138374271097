import React, { Fragment, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import SwipableViews from 'react-swipeable-views';
import { useUnmount, useUpdateEffect } from 'react-use';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import PhotoBottomMenu from '../../../components/bots/PhotoBot/PhotoBottomMenu';
import Popup from '../../../components/Popup';
import Confirm from '../../../components/Confirm';
import {
  deletePhoto,
  deselectPhoto,
  selectPhoto,
} from '../../../ducks/bots/photoBot/photo';
import { getFile } from '../../../utils/api';

const ViewerImageWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Photo = styled.img`
  width: 100%;
`;

const PhotoViewer = props => {
  const { t } = props;
  const dispatch = useDispatch();
  const { photoList, selectPhotoData, loading } = useSelector(
    state => state.photo,
  );
  const { listIdx } = selectPhotoData;
  const getDateTitle = item => {
    if (item && 'firstTime' in item) {
      const { firstTime } = item;
      const date = moment(firstTime).format(t('PHT.DATE_FORMAT'));
      return { date, colored: date.length };
    }
    return { date: '', colored: 0 };
  };

  const [{ index, list, date, colored }, setItemInfo] = useState({
    list: photoList,
    index: listIdx,
    ...getDateTitle(photoList[listIdx]),
  });
  const [popUpProperties, setPopup] = useState({ isPopup: false });
  const [message, setMsg] = useState(null);

  const handleChangeIndex = i => {
    dispatch(selectPhoto(list[i].id));
    setItemInfo({
      index,
      list,
      ...getDateTitle(list[i], t),
    });
  };

  const info = () => {
    const userAgent = navigator.userAgent.toLocaleLowerCase();
    let msg = t('PHT.MSG.UNKNOWN');
    if (userAgent) {
      const iPhone = userAgent.search('iphone') > -1;
      const iPad = userAgent.search('ipad') > -1;
      const android = userAgent.search('android') > -1;
      if (iPhone) {
        const safari = userAgent.search('safari') > -1;
        const whale = userAgent.search('whale') > -1;
        const fxios = userAgent.search('fxios') > -1;
        if (safari || whale || fxios) {
          msg = t('PHT.MSG.IOS_MOBILE');
        } else {
          msg = t('PHT.MSG.IOS');
        }
      } else if (iPad) {
        msg = t('PHT.MSG.IOS');
      } else if (android) {
        const whale = userAgent.search('whale') > -1;
        const naver = userAgent.search('naver') > -1;
        if (whale && !naver) {
          msg = t('PHT.MSG.ANDROID');
        } else {
          msg = t('PHT.MSG.IOS');
        }
      }
    }
    setMsg(msg);
  };

  const infoConfirm = () => setMsg(null);

  const handleDeleteClick = () => {
    const popup = {
      contents: t('PHT.MSG.DELETE'),
      leftLabel: t('common:POP.CANCEL'),
      rightLabel: t('common:POP.OK'),
      rightFunc: () => dispatch(deletePhoto()),
      mode: 'confirm',
      isPopup: true,
      onClose: () => setPopup({ isPopup: false }),
    };
    setPopup(popup);
  };

  useUnmount(() => {
    dispatch(deselectPhoto());
  });

  useUpdateEffect(() => {
    setItemInfo({
      list: photoList,
      index: listIdx,
      ...getDateTitle(photoList[listIdx]),
    });
  }, [photoList, listIdx]);

  return (
    <>
      {/* <Dimmer active={loading} className="photo-viewer-dimmer" /> */}
      <BotTopMenu
        title={date}
        colored={colored}
        menuMode="WITH_BACK_PHOTO"
        backLocation="/bots/OFFICIAL_PHOTO"
      />
      <>
        <ViewerImageWrapper>
          <SwipableViews
            index={index}
            onChangeIndex={handleChangeIndex}
            enableMouseEvents
          >
            {list.map(({ _id, photo }) => (
              <div key={_id}>
                <Photo
                  {...{
                    key: photo,
                    src: getFile({ photo }),
                    alt: t('PHT.ZOOM'),
                  }}
                />
                {loading && <Icon name="spinner" loading />}
              </div>
            ))}
          </SwipableViews>
        </ViewerImageWrapper>
        {message && message.length > 0 && (
          <Popup
            contents={message}
            rightLabel={t('common:POP.CANCEL')}
            // rightFunc={downloadConfirm}
            rightFunc={infoConfirm}
            mode="confirm"
            isPopup={message && message.length > 0}
          />
        )}
        {popUpProperties.isPopup && <Confirm {...popUpProperties} />}
      </>
      <PhotoBottomMenu
        leftIcon="lightbulb outline"
        leftLabel={t('PHT.MSG.SAVE')}
        onLeftButton={info}
        rightIcon="trash alternate outline"
        rightLabel={t('PHT.DELETE.LB')}
        onRightButton={handleDeleteClick}
        p
      />
      {/*  <PhotoBottomMenu
        leftIcon="download"
        leftLabel={t('PHT.SAVE')}
        onLeftButton={this.handleDownloadClick}
        rightIcon="trash alternate outline"
        rightLabel={t('PHT.DELETE.LB')}
        onRightButton={this.handleDeleteClick}
      /> */}
    </>
  );
};

export default PhotoViewer;
