/* eslint-disable no-underscore-dangle */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPhotosApi, deletePhotosApi } from './photoBotApi';
import i18 from '../../../lang/i18n';

const reducerName = 'photo';

const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

const getSelectItems = (id, list, data) => {
  const listIdx = list.findIndex(({ id: itemId }) => itemId === id);
  let childIdx;
  const parentIdx = data.findIndex(item => {
    const [photo] = Object.values(item);
    childIdx = photo.findIndex(({ id: itemId }) => itemId === id);
    return childIdx >= 0;
  });
  return { listIdx, parentIdx, childIdx };
};

const initialSelect = (photoList, photoData, selectPhotoData) => {
  const { listIdx, parentIdx, childIdx } = selectPhotoData;
  if (selectPhotoData && listIdx >= 0 && parentIdx >= 0 && childIdx >= 0) {
    const list = photoList.map((listitem, idx) => {
      if (idx === listIdx) {
        return { ...listitem, checked: false };
      }
      return listitem;
    });
    const data = photoData.map((parentItem, pIdx) => {
      const [[key, photo]] = Object.entries(parentItem);
      const newChild = photo.map((childItem, cIdx) => {
        if (pIdx === parentIdx && cIdx === childIdx) {
          return { ...childItem, checked: false };
        }
        return childItem;
      });
      return { [key]: newChild };
    });
    return { list, data };
  }
  return { list: photoList, data: photoData };
};

const changeAndGetListData = (
  photoList,
  photoData,
  selectPhotoData,
  checked,
) => {
  const { listIdx, parentIdx, childIdx } = selectPhotoData;
  if (selectPhotoData && listIdx >= 0 && parentIdx >= 0 && childIdx >= 0) {
    const list = photoList.map((listitem, idx) => {
      if (idx === listIdx) {
        return { ...listitem, checked };
      }
      return listitem;
    });
    const data = photoData.map((parentItem, pIdx) => {
      const [[key, photo]] = Object.entries(parentItem);
      const newChild = photo.map((childItem, cIdx) => {
        if (pIdx === parentIdx && cIdx === childIdx) {
          return { ...childItem, checked };
        }
        return childItem;
      });
      return { [key]: newChild };
    });
    return { list, data };
  }
  return { list: photoList, data: photoData };
};

export const deletePhoto = createAsyncThunk(
  `${reducerName}/DELETE`,
  async (v, { getState, rejectWithValue }) => {
    const { photoData, photoList, selectPhotoData } = getState().photo;
    let { listIdx: curIdx } = selectPhotoData;
    const deleteList = photoList.reduce(
      (acc, { checked, id }) => (checked ? acc.concat([id]) : acc),
      [],
    );
    const filteredPhotoList = photoList.filter(({ checked }) => !checked);
    const filteredPhotoData = photoData.reduce((acc, cur) => {
      const [[k, curList]] = Object.entries(cur);
      const filtered = curList.filter(({ checked }) => !checked);
      if (filtered.length > 0) {
        acc.push({ [k]: filtered });
      }
      return acc;
    }, []);
    try {
      const response = await deletePhotosApi(deleteList);
      if (curIdx !== 0 && curIdx > 0) {
        curIdx -= 1;
      } else {
        curIdx = 0;
      }
      if (filteredPhotoList.length > 0) {
        const { listIdx, parentIdx, childIdx } = getSelectItems(
          filteredPhotoList[curIdx].id,
          filteredPhotoList,
          filteredPhotoData,
        );
        const { list, data } = changeAndGetListData(
          filteredPhotoList,
          filteredPhotoData,
          { listIdx, parentIdx, childIdx },
          false,
        );
        const { result, n, error } = response;
        if (result && n === deleteList.length) {
          return {
            selectPhotoData: { listIdx, parentIdx, childIdx },
            photoData: data,
            photoList: list,
          };
        }
        return rejectWithValue(
          getErrorMsg(getErrorCode(error), 'bot:PHT.DELETE.FAILED'),
        );
      }
      return {
        photoData: [],
        photoList: [],
        selectPhotoData: { listIdx: -1, parentIdx: -1, childIdx: -1 },
        photoDataLength: 0,
      };
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:PHT.DELETE.FAILED'),
      );
    }
  },
);

export const fetchPhoto = createAsyncThunk(
  `${reducerName}/FETCH`,
  async ({ skip, limit, robotId, refresh }, { getState, rejectWithValue }) => {
    try {
      const response = await getPhotosApi(skip, limit, robotId);
      const { result, error } = response;
      const { photoData } = response;
      if (result) {
        const {
          photoData: oldPhotoData,
          photoList: oldPhotoList,
          photoDataLength: oldPhotoDataLength,
        } = getState().photo;
        if (photoData.length === 0) {
          // 기존 리스트 없고, 새로 가져온 결과도 없을 경우
          if (oldPhotoData.length === 0) {
            return {
              photoData: [],
              photoList: [],
              selectPhotoData: { listIdx: -1, parentIdx: -1, childIdx: -1 },
              photoDataLength: 0,
            };
          }
          // 기존 리스트는 있지만, 새로 가져온 결과가 없는 경우
          return {};
        }
        let photoDataLength = oldPhotoDataLength;
        let newPhotoList = refresh ? [] : [...oldPhotoList];
        const newPhotoData = photoData.reduce(
          (prev, curr) => {
            const [[k, v]] = Object.entries(curr);
            const prevItem = [...prev];

            const findIndex = prev.findIndex(item => !!item[k]);
            if (findIndex > -1) {
              const concatArr = prev[findIndex][k].concat(v);
              prevItem.splice(findIndex, 1, { [k]: concatArr });
            } else {
              prevItem.push({ [k]: v });
            }
            photoDataLength += v.length;
            newPhotoList = newPhotoList.concat(v);
            return prevItem;
          },
          refresh ? [] : oldPhotoData,
        );
        return {
          photoList: newPhotoList,
          photoData: newPhotoData,
          photoDataLength,
        };
      }
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:PHT.LOAD.FAILED'),
      );
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'bot:PHT.LOAD.FAILED'),
      );
    }
  },
);

const initialState = {
  initDataStatus: false,
  editing: false,
  pending: false,
  checked: false,
  error: false,
  isBottom: false,
  photoList: [],
  selectPhotoData: { listIdx: -1, parentIdx: -1, childIdx: -1 },
  photoData: [],
  photoDataLength: 0,
  downloading: false,
  downloadResult: { result: false, msg: '' },
  checkCount: 0,
};

const photoSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
    initStatus: state => ({
      ...state,
      initDataStatus: !state.initDataStatus,
    }),
    selectToggle: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    toggle: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    check: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    setBottom: (state, action) => ({
      ...state,
      isBottom: action.payload,
    }),
  },
  extraReducers: {
    [fetchPhoto.pending.type]: state => ({
      ...state,
      pending: true,
      error: false,
    }),
    [fetchPhoto.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      ...action.payload,
    }),
    [fetchPhoto.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
    [deletePhoto.pending.type]: state => ({
      ...state,
      pending: true,
      error: false,
    }),
    [deletePhoto.fulfilled.type]: (state, action) => ({
      ...state,
      pending: false,
      editing: false,
      ...action.payload,
    }),
    [deletePhoto.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      error: action.payload,
    }),
  },
});

const { reducer: photoReducer, actions } = photoSlice;
const { selectToggle, toggle, check, setBottom } = actions;
export const { init, initStatus } = actions;

export const selectPhoto = id => (dispatch, getState) => {
  const { photoList: l, photoData: d, selectPhotoData } = getState().photo;
  const { list, data } = initialSelect(l, d, selectPhotoData);
  const { listIdx, parentIdx, childIdx } = getSelectItems(id, list, data);
  const { list: photoList, data: photoData } = changeAndGetListData(
    list,
    data,
    { listIdx, parentIdx, childIdx },
    true,
  );
  dispatch(
    selectToggle({
      selectPhotoData: { listIdx, parentIdx, childIdx },
      photoList,
      photoData,
    }),
  );
};

export const deselectPhoto = () => (dispatch, getState) => {
  const { photoList, photoData, selectPhotoData } = getState().photo;
  const { list, data } = initialSelect(photoList, photoData, selectPhotoData);
  dispatch(
    selectToggle({
      selectPhotoData: { listIdx: -1, parentIdx: -1, childIdx: -1 },
      photoList: list,
      photoData: data,
    }),
  );
};

export const toggleEdit = all => async (dispatch, getState) => {
  const { photoList, photoData, editing, checked } = getState().photo;
  let checkedVal = checked;
  if (!editing) {
    checkedVal = true;
  }
  if (all) {
    checkedVal = checked;
  }
  const newList = photoList.map(item => ({ ...item, checked: !checkedVal }));
  const newData = photoData.map(dataList => {
    const newOne = Object.entries(dataList).map(([k, list]) => ({
      [k]: list.map(item => ({ ...item, checked: !checkedVal })),
    }));
    return newOne[0];
  });
  return dispatch(
    toggle({
      editing: all || !editing,
      photoData: newData,
      photoList: newList,
      checked: !checkedVal,
      checkCount: !checkedVal ? newList.length : 0,
    }),
  );
};

export const hitBottom = v => dispatch => dispatch(setBottom(v));

export const checkPhoto = (parentIndex, childIndex) => (dispatch, getState) => {
  const { photoList: list, photoData: data, checkCount } = getState().photo;

  const photoData = data.map((parentItem, pIdx) => {
    const [[key, photo]] = Object.entries(parentItem);
    const newChild = photo.map((childItem, cIdx) => {
      if (pIdx === parentIndex && cIdx === childIndex) {
        return { ...childItem, checked: !childItem.checked };
      }
      return childItem;
    });
    return { [key]: newChild };
  });
  const dataItem = Object.values(photoData[parentIndex])[0][childIndex];
  let listItem;
  const photoList = list.map(item => {
    if (item.id === dataItem.id) {
      listItem = { ...item, checked: dataItem.checked };
      return listItem;
    }
    return item;
  });

  dispatch(
    check({
      photoList,
      photoData,
      checkCount: listItem.checked ? checkCount + 1 : checkCount - 1,
    }),
  );
};

export default photoReducer;
