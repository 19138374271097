import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Form, Radio } from 'semantic-ui-react';
import { PiboMenu, PiboMenuItem } from '../PiboComponents';

const PiboTempUnit = props => {
  const { t } = useTranslation();
  const { value: unitValue, list, handleChange } = props;
  const [value, setValue] = useState(unitValue === 'celcius' || unitValue === 'celsius' ? 'celsius' : unitValue);

  useEffect(() => {
    setValue(unitValue === 'celcius' || unitValue === 'celsius' ? 'celsius' : unitValue);
  }, [unitValue]);

  const handleUnitChange = (e, { value: v }) => {
    handleChange(v);
    setValue(v);
  };

  return (
    <Grid centered padded>
      <Grid.Row>
        <Grid.Column>
          <PiboMenu vertical fluid>
            <Form>
              {list &&
                list.length > 0 &&
                list.map((item) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PiboMenuItem
                    key={item.id}
                    value={item.id}
                    onClick={handleUnitChange}
                  >
                    <Form.Field>
                      <Radio
                        label={t(item.value)}
                        name="radioGroup"
                        value={item.id}
                        checked={value === item.id}
                        onChange={handleUnitChange}
                      />
                    </Form.Field>
                  </PiboMenuItem>
                ))}
            </Form>
          </PiboMenu>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default PiboTempUnit;
