import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getBotDataApi } from './defaultPageApi';
import { getLocale } from '../../../utils/common';

const name = 'defaultBot';
const botData = {
  title: '',
  description: '',
  projectId: '',
  example: '',
  source: '',
};

const initialState = {
  pending: true,
  error: false,
  botData,
};

export const getBotData = createAsyncThunk(
  `${name}/GET_DATA`,
  async (botId, { rejectWithValue }) => {
    try {
      const response = await getBotDataApi({ botId, locale: getLocale() });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const defaultBotSlice = createSlice({
  name,
  initialState,
  reducers: {
    initialize: () => ({ ...initialState }),
  },
  extraReducers: {
    [getBotData.pending.type]: state => ({ ...state, pending: true }),
    [getBotData.fulfilled.type]: (state, action) => {
      const { result } = action.payload;
      if (result) {
        return {
          ...state,
          pending: false,
          botData: action.payload.botData,
          error: false,
        };
      }
      return { ...state, pending: false, botData, error: action.payload };
    },
    [getBotData.rejected.type]: (state, action) => ({
      ...state,
      pending: false,
      botData,
      error: action.payload,
    }),
  },
});

const { reducer: defaultBotReducer, actions } = defaultBotSlice;
export const { initialize } = actions;

export default defaultBotReducer;
