import React, { useRef, useState } from 'react';
import { useLifecycles, useWindowSize, useUpdateEffect } from 'react-use';
import {
  Grid,
  Segment,
  Button,
  Icon,
  Accordion,
  Message,
} from 'semantic-ui-react';
import loadJs from 'load-js';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getXY } from '../../../utils/common';
import { MAP_CLIENT_ID } from '../../../circulusConfig';
import { DEFAULT_GEO } from '../../../utils/consts';

const MapDiv = styled.div`
  width: 100%;
  height: 100%;
  &:focus-visible {
    outline: none;
  }
`;

const AddressSegment = styled(Segment)`
  &.ui.segment {
    position: relative;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
  }
  line-height: 1.5rem;
  &&&&& > button.ui.button {
    height: 1.5rem;
  }
`;

const IconButtonCurrentPosition = styled(Button)`
  &.ui.icon.button,
  .ui.icon.buttons,
  .button {
    padding: 0;
    height: 100%;
    background: transparent;
    position: relative;
  }
`;

const HelpAccordion = styled(Accordion)`
  &&&& {
    font-size: 0.875rem;
    color: #0894d7;
    &.ui.accordion .title {
      padding: 0;
      color: inherit;
      font-weight: bold;
    }
    &.ui.accordion .content {
      padding: 0;
      & ul {
        margin: 0;
        padding-inline-start: 2rem;
        & li {
          margin-bottom: 0.5em;
        }
      }
    }
  }
`;

const MapWrapper = styled.div`
  max-width: 100%;
  max-height: ${({ height, width }) => (height >= width ? '36vh' : '70vh')};
  display: flex;
  flex: 1 0;
  position: relative;
  padding: 1px;
  background: rgb(224, 225, 226);
  &:focus-visible {
    outline: none;
  }
`;

let navermap;
const PiboGeo = props => {
  const { t } = useTranslation('pibo');
  const { width, height } = useWindowSize();
  const {
    // loading,
    handleChange,
    value: passedValue,
    error,
    columnswidth,
  } = props;
  const mapEl = useRef(null);
  const mapWrapEl = useRef(null);
  const [xy, setXY] = useState(null);
  const [addLoading, setLoading] = useState(false);
  const [address, setAddress] = useState();
  const [accIndex, setIndex] = useState(-1);
  const [failed, setFailed] = useState(false);
  const [permission, setPermission] = useState(null);
  const btnRef = useRef(null);
  const getMapW = () =>
    mapWrapEl.current && 'offsetWidth' in mapWrapEl
      ? mapWrapEl.current.offsetWidth
      : width;
  const getMapH = () => {
    if (mapWrapEl.current && 'offsetHeight' in mapWrapEl) {
      return mapWrapEl.current.offsetHeight;
    }
    if (height >= width) {
      return height * 0.36;
    }
    return height * 0.7;
  };
  const [mapSize, setMapSize] = useState({
    mapW: getMapW(),
    mapH: getMapH(),
  });
  const [wrapStyle, setMapStyle] = useState({
    maxWidth: '100%',
    maxHeight: height >= width ? '36vh' : '70vh',
    flex: '1 0',
    display: 'flex',
    position: 'relative',
    padding: '1px',
    background: 'rgb(224, 225, 226)',
  });
  const [map, setMap] = useState(null);
  let marker;
  let loadScriptPromise = null;
  let isSubscribed = true;

  const loadNavermapsScript = () => {
    if (loadScriptPromise) {
      return loadScriptPromise;
    }

    loadScriptPromise = loadJs(
      `https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${MAP_CLIENT_ID}&submodules=geocoder`,
    ).then(() => {
      const navermaps = window.naver.maps;

      if (navermaps.jsContentLoaded) {
        return navermaps;
      }

      const loadingJsContent = new Promise(resolve => {
        navermaps.onJSContentLoaded = () => {
          resolve(navermaps);
        };
      });

      return loadingJsContent;
    });

    return loadScriptPromise;
  };

  const onScriptError = e => {
    setLoading(false);
    setFailed(`${t('pibo:SET.GEO.ERR_SCRIPT')} ${JSON.parse(e)}`);
  };

  const onStatusError = () => {
    setLoading(false);
    setFailed(`${t('pibo:SET.GEO.ERR_NAVER')}`);
  };

  const getAddress = async (coords, preserveAddress) => {
    if (!isSubscribed) return;
    if (!navermap) return;

    setXY(coords);
    navermap.Service.reverseGeocode(
      {
        coords,
      },
      (status, response) => {
        if (status !== navermap.Service.Status.OK) {
          onStatusError();
        } else {
          const result = response.v2; // 검색 결과의 컨테이너
          const items = result.results; // 검색 결과의 배열
          const valueObj = { ...getXY(coords.y, coords.x) };
          let formattedAddress;

          if (preserveAddress) {
            formattedAddress = preserveAddress;
            valueObj.address = preserveAddress.split(/\s/g);
          } else {
            items.forEach(({ name, region }) => {
              if (name === 'legalcode') {
                const addressArr = Object.keys(region).reduce(
                  (prev, curr, idx) => {
                    if (idx > 0 && region[curr].name) {
                      prev.push(region[curr].name);
                    }
                    return prev;
                  },
                  [],
                );
                formattedAddress = addressArr.join(' ');
                valueObj.address = addressArr;
              }
            });
          }
          setLoading(false);
          setAddress(formattedAddress || '');
          if (formattedAddress) {
            handleChange({ value: valueObj, error: null });
          } else {
            handleChange({
              value: null,
              error: t('pibo:SET.UNVALID_LOCATION'),
            });
          }

          if (!map) {
            const m = new navermap.Map(mapEl.current, {
              useStyleMap: true,
              center: coords,
              zoom: 17,
              zoomControl: true,
              zoomControlOptions: {
                style: navermap.ZoomControlStyle.SMALL,
              },
              minZoom: 1,
            });
            setMap(m);
          } else {
            map.setCenter(coords);
          }
        }
      },
    );
  };

  const onError = () => {
    setLoading(false);
    if (!(passedValue.lat || passedValue.lng)) {
      const { lat, lng, address: addressArr } = DEFAULT_GEO;
      const addressStr = addressArr.join(/\s/g, '');
      setAddress(addressStr);
      getAddress(new navermap.LatLng(lat, lng));
    }
  };

  const getGeoPermission = () => {
    const { navigator } = window;
    if ('geolocation' in navigator) {
      return navigator.geolocation.getCurrentPosition(position => {
        setPermission(!!position);
      }, onError);
    }
    return setPermission(false);
  };

  const getCurrentPosition = () => {
    if (isSubscribed && permission !== false) {
      setLoading(true);
      const { navigator } = window;
      if ('geolocation' in navigator) {
        /* 위치정보 사용 가능 */
        return navigator.geolocation.getCurrentPosition(position => {
          setPermission(true);
          getAddress(
            new navermap.LatLng(
              position.coords.latitude,
              position.coords.longitude,
            ),
          );
        }, onError);
      }
      /* 위치권한 사용 불가능 */
      onError();
    }
    return null;
  };

  const handleHelpClick = () => {
    setIndex(accIndex === 0 ? -1 : 0);
  };

  const idleEvent = () => {
    // navermap.Event.removeListener(idleEvent);
    mapEl.current.blur();
  };

  const clickEvent = e => {
    if (marker) {
      marker.setPosition(e.coord);
    }
    getAddress(e.latlng);
  };

  useUpdateEffect(() => {
    if (navermap) {
      if (map) {
        if (!marker) {
          marker = new navermap.Marker({
            position: xy,
            map,
          });
        }
        navermap.Event.addListener(map, 'idle', idleEvent);
        navermap.Event.addListener(map, 'click', clickEvent);
      }
    }
  }, [map, xy]);

  const asyncLoadNaverMap = async () => {
    try {
      await loadNavermapsScript();
      setLoading(false);
      navermap = window.naver && window.naver.maps;
      if (passedValue) {
        const { lat, lng, x, y, address: addressArr } = passedValue;
        if (lat && lng && x && y && addressArr && addressArr.length > 0) {
          const addressStr = addressArr.join(' ');
          setAddress(addressStr);
          return getAddress(new navermap.LatLng(lat, lng));
        }
      }
      return getCurrentPosition();
    } catch (e) {
      return onScriptError(e);
    }
  };

  useLifecycles(
    async () => {
      setLoading(true);
      getGeoPermission();
      await asyncLoadNaverMap();
    },
    () => {
      if (map) {
        navermap.Event.removeListener(clickEvent);
      }
      isSubscribed = false;
    },
  );

  useUpdateEffect(() => {
    setMapSize({ mapW: getMapW(), mapH: getMapH() });
    setMapStyle({ ...wrapStyle, maxHeight: height >= width ? '36vh' : '70vh' });
    if (xy) {
      getAddress(xy);
    }
  }, [width, height]);

  return (
    <Grid centered padded>
      <Grid.Row columns={columnswidth || 15}>
        <Grid.Column width={columnswidth || 15} style={{ padding: 0 }}>
          <MapWrapper ref={mapWrapEl}>
            <MapDiv
              ref={mapEl}
              // id="map"
              style={{
                width: `${mapSize.mapW}px`,
                height: `${mapSize.mapH}px`,
              }}
            />
          </MapWrapper>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={columnswidth || 15} style={{ padding: 0 }}>
        <Grid.Column width={columnswidth || 15} style={{ padding: 0 }}>
          {!failed && (
            <AddressSegment
              loading={addLoading}
              style={error ? { color: '#EF3340' } : {}}
            >
              {addLoading && <Icon loading={addLoading} name="spinner" />}
              {error && <Icon name="warning sign" />}
              {failed || error || address}
              {permission && !error && !addLoading && (
                <IconButtonCurrentPosition
                  ref={btnRef}
                  floated="right"
                  onClick={getCurrentPosition}
                  icon
                >
                  <Icon name="crosshairs" />
                </IconButtonCurrentPosition>
              )}
            </AddressSegment>
          )}
          {failed && (
            <Message color="red">
              <Icon name="warning sign" />
              {failed}
            </Message>
          )}
        </Grid.Column>
      </Grid.Row>
      {!failed && (
        <Grid.Row columns={columnswidth || 15} style={{ padding: '0.5rem 0' }}>
          <Grid.Column width={columnswidth || 15} style={{ padding: 0 }}>
            <HelpAccordion>
              <Accordion.Title
                active={accIndex === 0}
                index={0}
                onClick={handleHelpClick}
              >
                <Icon name="question circle outline" />
                {t('pibo:SET.GEO.GUIDE_LABEL')}
              </Accordion.Title>
              <Accordion.Content active={accIndex === 0}>
                <ul>
                  <li>{t('pibo:SET.GEO.GUIDE_DETAIL1')}</li>
                  {permission && (
                    <li>
                      <Icon name="crosshairs" color="grey" />
                      {t('pibo:SET.GEO.GUIDE_DETAIL2')}
                    </li>
                  )}
                </ul>
              </Accordion.Content>
            </HelpAccordion>
          </Grid.Column>
        </Grid.Row>
      )}
      {/* <Grid.Row columns={15}>
        <Grid.Column width={15}>
          <Button
            fluid
            disabled={loading || addLoading}
            onClick={getCurrentPosition}
          >
            <Icon name="crosshairs" />
            {t('SET.SET_CURR_LOCATION')}
          </Button>
        </Grid.Column>
      </Grid.Row> */}
    </Grid>
  );
};

export default PiboGeo;
