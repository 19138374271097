import React from 'react';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { LinkDescription } from '../../components/link/Components';
import UserInfoImage from '../../components/user/UserInfoImage';

const UploadGuide = styled.div`
  color: #0894d7;
  margin-top: 2rem;
  @media (min-width: 600px) {
    width: 50%;
    margin: 2rem auto;
  }
  & > header {
    font-weight: bold;
    text-align: center;
  }
  & > p,
  ul {
    font-size: 0.875rem;
    margin-top: 0.5em;
  }
`;

const LinkStep3 = props => {
  const { t, loading, error, data, handleChange } = props;

  const handleFileChange = async event => {
    const files = Array.from(event.target.files);
    const { imageFile, image } = data;
    if (event.target.files && event.target.files[0]) {
      handleChange(files);
    } else if (!imageFile && !image) {
      handleChange(null);
    }
  };

  const handleFileInputClick = async () => {
    const { imageFile, image } = data;
    if (!(imageFile || image)) {
      handleChange(null);
    }
  };

  return (
    <div style={{ width: '100%' }}>
      <LinkDescription
        header={t('conn:MSG.LINK_DESCRIPTION_3.HEADER')}
        text={t('conn:MSG.LINK_DESCRIPTION_3.TEXT')}
      />
      <div>
        <UserInfoImage
          {...{
            data: data.image,
            label: t('conn:MSG.LINK_DESCRIPTION_3.ADD_IMG'),
            loading,
            error,
          }}
          onFileChange={handleFileChange}
          onButtonClick={handleFileInputClick}
        />
        <UploadGuide>
          <header>
            <Icon name="warning circle" />
            {t('conn:MSG.LINK_DESCRIPTION_3.IMF_RULE_TITLE')}
          </header>
          <p>{t('conn:MSG.LINK_DESCRIPTION_3.IMG_RULE_GUIDE')}</p>
          <ul>
            <li>{t('conn:MSG.LINK_DESCRIPTION_3.IMG_RULE_GUIDE_1')}</li>
            <li>{t('conn:MSG.LINK_DESCRIPTION_3.IMG_RULE_GUIDE_2')}</li>
          </ul>
        </UploadGuide>
      </div>
    </div>
  );
};

export default LinkStep3;
