import loadJs from 'load-js';
import { IOT_URL } from '../circulusConfig';

/* eslint-disable */
/**
 * Circulus Client API
 * 20201024 - MULTIPLE SUPPORT
 * 20200224 - WEBSOCKET -> MQTT
 * 20180917 - PIBO
 * 20150627 - CULU
 * 20140819 - CAR
 * 20211108 - refactoring(_id, _topic Array -> Object)
 */

let robot_id = null;
let pibo = null;

const robotId = () => robot_id;

const init = pibo => {
  if (!pibo) return;
  if (robotId()) {
    pibo.onload();
    pibo.init(robotId());
  }
};
const loadPibo = () =>
  new Promise(response => {
    const pibo = {
      _id: {},
      _topic: {},
      _client: undefined,
      _timeout: 10000,
      _callback: {},
      _isConnect: false,
      onload: mode => {
        pibo._client = mqtt.connect(IOT_URL, {
          keepalive: 10,
        });
        pibo._client.on('connect', () => {
          pibo._isConnect = true;
        });
        pibo._client.on('reconnect', () => {
          pibo._isConnect = true;
        });
        pibo._client.on('offline', () => {
          pibo._isConnect = false;
        });
        // 끊어진 경우 명령어 forwording 수행
        pibo._client.on('close', function() {
          pibo._isConnect = false;
          //alert('close');
        });
        pibo._client.on('message', function(topic, message) {
          try {
            const data = JSON.parse(message.toString());
            if (data.key && pibo._callback[data.key]) {
              clearTimeout(pibo._callback[data.key].to);
              if (!pibo._callback[data.key].cb) return;
              pibo._callback[data.key].cb(data.value, topic);
            } else {
              console.warn(topic, message.toString());
            }
          } catch (e) {
            console.error(topic, message.toString());
          }
        });
      },
      init: (ids, mode) => {
        if (mode) pibo.onload(mode);
        for (const id of ids) {
          const topic = `r_${id}`;
          const id2 = `m_${id}`;
          pibo._topic[topic] = true;
          pibo._id[id2] = true;
          pibo._client.subscribe(topic);
        }
      },
      setGroup: ids => {
        for (const id of ids) pibo._id = { ...pibo._id, [`m_${id}`]: true };
      },
      debug: (id, mode) => {
        pibo.onload(mode);
        pibo._id = { ...pibo._id, [`m_${id}`]: true };
        pibo._topic = { ...pibo._topic, [`c_${id}`]: true };
        pibo._client.subscribe(`c_${id}`);
      },
      send: (key, value, cb) => {
        if (!pibo._client) return;
        const timeout = ['wifi_change'].includes(key)
          ? pibo._timeout * 3
          : pibo._timeout;
        pibo._callback[key] = pibo._callback[key]
          ? pibo._callback[key]
          : { to: null, cb: null };
        if (pibo._callback[key].to)
          // 중복 요청시 겹치는 문제가 있으므로 기존 콜 제거
          clearTimeout(pibo._callback[key].to);
        if (cb) {
          const to = setTimeout(() => {
            cb(false);
          }, timeout);
          pibo._callback[key] = { cb, to };
        }
        for (const [robotId, isConnect] of Object.entries(pibo._id)) {
          if (isConnect)
            pibo._client.publish(robotId, JSON.stringify({ key, value }));
        }
      },
      receive: (key, cb) => {
        //alert(pibo._callback);
        // console.error('receive', key);
        pibo._callback[key] = { cb };
      },
      clear: () => {
        Object.entries(pibo._topic)
          .filter(([topic, isConnect]) => isConnect)
          .map(([topic, isConnect]) => pibo._client.unsubscribe(topic));
        pibo._id = {};
        pibo._topic = {};
      },
    };

    const funcs = {
      // f_2_cb: ['message'],
      f_2: [
        'motion',
        'extend',
        'simulator',
        'speak',
        'play',
        'action',
        'speak',
        'eye',
        'bot',
        'mode',
        'tell',
      ],
      f_1_cb: [
        'message',
        'candidate',
        'removeMessage',
        'talk',
        'event',
        'wifi_change',
        'change',
        'voice',
        'listen',
        'volume',
        'geo',
        'reset',
        'interpret',
        'dev',
      ],
      f_1: ['$_setup', 'env', 'plug', 'reaction', 'style'],
      f_0_cb: [
        'wifi_list',
        'version',
        'compliment',
        'camera',
        'wifi_reset',
        'info',
        'refreshBots',
        'refreshUser',
      ],
      f_0: ['kill', 'stop', 'halt', 'mute'],
    };
    Object.keys(funcs).forEach(key => {
      funcs[key].forEach(item => {
        switch (key) {
          case 'f_2_cb':
            pibo[item] = (_, data, cb) => {
              pibo.send(item, { _, data }, cb);
            };
            break;
          case 'f_2':
            pibo[item] = (_, data) => {
              pibo.send(item, { _, data });
            };
            break;
          case 'f_1':
            pibo[item] = (_, data) => {
              pibo.send(item, { _, data });
            };
            break;
          case 'f_1_cb':
            pibo[item] = (_, cb) => {
              pibo.send(item, { _ }, cb);
            };
            break;
          case 'f_0_cb':
            pibo[item] = cb => {
              pibo.send(item, {}, cb);
            };
            break;
          case 'f_0':
            pibo[item] = () => {
              pibo.send(item);
            };
            break;
        }
      });
    });
    response(pibo);
  });

const loadResource = () =>
  new Promise(response => {
    loadJs('https://unpkg.com/mqtt@3.0.0/dist/mqtt.min.js').then(script => {
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(script);
      loadPibo().then(pibo => response(pibo));
    });
  });

const changeTypeToArray = phyId => {
  if (typeof phyId === 'string') {
    return [phyId];
  }
  return phyId;
};
export const connectSocket = async phyIds => {
  if (!phyIds) return false;
  robot_id = changeTypeToArray(phyIds);
  pibo = await loadResource();
  init(pibo);
  return { robot_id, pibo };
};

export const disconnectSocket = () => {
  robot_id = [];
  if (!pibo) return;
  pibo.clear();
};
