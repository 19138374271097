import React from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import { LinkDescription } from '../../components/link/Components';
import { StyledMessage } from '../../components/Message';
import UserInfoInterest from '../../components/user/UserInfoInterest';
import { fetchInterestsList } from '../../ducks/link';

const LinkStep4 = props => {
  const dispatch = useDispatch();
  const { t, loading, error, data, handleChange } = props;

  const handleInterestClick = (event, item) => {
    event.target.blur();
    const { inverted, id } = item;
    const { selected } = data;
    let newInterest;
    if (inverted) {
      newInterest = selected.concat([id]);
    }
    if (!inverted) {
      newInterest = selected.filter(v => v !== id);
    }
    handleChange(newInterest);
  };

  useMount(() => {
    dispatch(fetchInterestsList());
  });

  return (
    <div style={{ width: '100%' }}>
      <LinkDescription
        header={t('conn:MSG.LINK_DESCRIPTION_4.HEADER')}
        text={t('conn:MSG.LINK_DESCRIPTION_4.TEXT')}
      />
      <div>
        <StyledMessage
          color="red"
          hidden={!error}
          textalign="left"
          style={{ marginTop: '0.5rem', fontSize: '0.875rem' }}
        >
          {error}
        </StyledMessage>
        <UserInfoInterest
          {...{ loading, error }}
          interestList={data.interestList}
          onClick={handleInterestClick}
          helper={false}
        />
      </div>
    </div>
  );
};

export default LinkStep4;
