/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import FetchInterceptor from 'fetch-interceptor';
import 'moment/locale/ko';
import './css/index.css';
import App from './App';
import {
  getRobotId,
  getUserPId,
  // getLocale,
  setLocale,
  getRobotPId,
  removeLocalStorage,
} from './utils/common';
import { onDeviceBack } from './utils/api';
import { piboInit } from './pibo';

const startApp = async () => {
  removeLocalStorage('mounted');
  FetchInterceptor.register({
    onBeforeRequest(request) {
      request.headers.append('Access-Control-Allow-Origin', '*');
      request.headers.append(
        'Access-Control-Allow-Methods',
        'GET, POST, PUT, DELETE, OPTIONS',
      );
      request.headers.append(
        'Access-Control-Allow-Headers',
        'Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization, x-client-type, x-client-id',
      );
      request.headers.append('Access-Control-Max-Age', 3600);
      if (getRobotId()) {
        request.headers.append(
          'x-client-type',
          '08f12867e7a16b1b9071ae92ca8b6e7e1698068f',
        );
        request.headers.append('x-client-id', getRobotId());
      } else if (getUserPId()) {
        request.headers.append(
          'x-client-type',
          '70ff9f33926b1865edde645073a37bd1fffba984',
        );
        request.headers.append('x-client-id', getUserPId());
      } else {
        request.headers.append(
          'x-client-type',
          '70ff9f33926b1865edde645073a37bd1fffba984',
        );
        request.headers.append('x-client-id', '5d9031b06ad9bf03c0045ac4');
      }
    },
    onRequestSuccess(response, request) {
      // Hook on response success
      // console.log('SUCCESS', response, request);
    },
    onRequestFailure(response, request) {
      // Hook on response failure
      // console.log('FAILURE', response, request);
    },
  });
  axios.interceptors.request.use(
    config => {
      const reqConfig = config;
      const {
        headers: { common },
      } = reqConfig;
      if (getRobotId()) {
        common['x-client-type'] = '08f12867e7a16b1b9071ae92ca8b6e7e1698068f';
        common['x-client-id'] = getRobotId();
      } else if (getUserPId()) {
        common['x-client-type'] = '70ff9f33926b1865edde645073a37bd1fffba984';
        common['x-client-id'] = getUserPId();
      }
      return reqConfig;
    },
    error => Promise.reject(error),
  );

  // setLocale(getLocale());
  setLocale('ko');
  onDeviceBack();
  if (
    getRobotPId() &&
    window.location.pathname !== '/pibo' &&
    window.location.pathname !== '/connect'
  ) {
    await piboInit(getRobotPId());
  }
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}
