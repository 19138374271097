import { Tab, Button } from 'semantic-ui-react';
import styled from 'styled-components';

const TabularItem = styled(Tab)`
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid #ffb922;
  border-radius: 4px;
  & > .ui.grid {
    & > .column {
      padding: 0;
      & > .ui.vertical.tabular.menu {
        width: initial;
        border: none;
        & > a.item.active {
          border: none;
          background: rgba(255, 185, 34, 0.25);
        }
        & > a.item {
          &:nth-child(1) {
            border-top-left-radius: 4px !important;
          }
          &:nth-last-child(1) {
            border-bottom-left-radius: 4px !important;
          }
          border-radius: 0 !important;
          padding: 0.625rem 0.3125rem 0.625rem 0 !important;
          font-size: 0.875rem;
          color: #ffb922;
          text-align: right;
        }
      }
      & > .segment.active.tab {
        border: none;
        border-radius: 0 !important;
        border-left: 1px solid #ffb922;
        padding: 0.625rem;
      }
    }
  }
`;

const CommandButton = styled(Button)`
  &&& {
    background: none !important;
    border-radius: 0;
    min-height: 0;
    margin: 0;
    padding: 0;
    color: #ffb922;
    font-size: 0.875rem;
    font-weight: 100 !important;
    text-align: left;
    line-height: initial;
    width: 100%;
    &.toggle.active {
      background: transparent !important;
      font-weight: 600 !important;
      color: #cc8c0d !important;
    }
  }
`;

export { TabularItem, CommandButton };
