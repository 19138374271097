import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles } from 'react-use';
import { withLastLocation } from 'react-router-dom-last-location';
import { useTranslation } from 'react-i18next';

import { redirect } from 'react-router-dom';
import { fetchPhoto } from '../../../ducks/bots/photoBot/photo';
import { receivePiboEvent } from '../../../pibo';
import { getRobotId } from '../../../utils/common';
import { Page } from '../../../components/Components';
import PhotoViewer from './PhotoViewer';
import PhotoMain from './PhotoMain';

const WithLocationMain = withLastLocation(PhotoMain);

const PhotoBot = props => {
  const dispatch = useDispatch();
  const photo = useSelector(state => state.photo);
  const { photoList, selectPhotoData, loading } = photo;
  const { t } = useTranslation('bot');
  const { page, title } = props;
  const wrongPage = page !== 'main' && page !== 'detail' && !loading;
  const { listIdx } = selectPhotoData;

  const onRefresh = () =>
    new Promise(resolve => {
      dispatch(
        fetchPhoto({
          skip: 0,
          limit: photoList.length > 30 ? photoList.length : 30,
          robotId: getRobotId(),
          refresh: true,
        }),
      );
      setTimeout(resolve, 1000);
    });

  useLifecycles(
    () => {
      receivePiboEvent('camera', onRefresh);
    },
    () => {
      receivePiboEvent('camera', null);
    },
  );

  useEffect(
    () =>
      page === 'detail' &&
      (!selectPhotoData || listIdx < 0) &&
      redirect('/bots/OFFICIAL_PHOTO'),
  );

  return (
    <Page>
      {page === 'main' && (
        <WithLocationMain t={t} refreshPhoto={onRefresh} title={title} />
      )}
      {page === 'detail' && listIdx >= 0 && <PhotoViewer t={t} />}
      {wrongPage && <div>{t('PHT.MSG.UNVALID')}</div>}
    </Page>
  );
};

export default PhotoBot;
