import React, { useState } from 'react';
import qs from 'qs';
import { useTranslation } from 'react-i18next';
import { useLifecycles } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { userVerify, userVerifyToken } from '../utils/api';
import {
  getUserToken,
  setCurrentUser,
  setUserToken,
  Alert,
  INSIDER,
  getUserId,
  removeLocalStorage,
  setLocalStorage,
} from '../utils/common';
import { errorLog, reporter } from '../utils/report';

let beAlramed = false;
const withAuth = AuthWrappedComponent => props => {
  let unmount = null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const defaultRoute = pathname === '/';
  const [loading, setLoading] = useState(true);
  let hidden = null;
  let visibilityChange = null;
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }

  // eslint-disable-next-line consistent-return
  const checkAuth = async () => {
    let token = await getUserToken();
    let passedToken = false;
    if (search && search.search('token') > 0) {
      const query = qs.parse(search, { ignoreQueryPrefix: true });
      token = query.token || token;
      passedToken = true;
    }

    let res;
    if (token) {
      try {
        if (passedToken) {
          res = await userVerifyToken(token);
        } else {
          res = await userVerify(token);
        }
        const { result, info, unvalid, expired } = res;
        if (result) {
          const {
            userId,
            userPId,
            robotId,
            nickName,
            robotPId,
            version,
            token: newToken,
          } = info;
          const { insider } = info;
          INSIDER.verify = insider;
          await setCurrentUser({
            userId,
            robotPId,
            userPId,
            robotId,
            nickName,
          });
          if (newToken) {
            setUserToken(newToken);
          }
          setLocalStorage('version', version);
          setLoading(false);
          beAlramed = false;
          // if (
          //   pathname === '/login' ||
          //   pathname === '/signup' ||
          //   !!(pathname === '/' && robotId)
          // )
          if (pathname === '/login' || pathname === '/signup') {
            navigate('/pibo');
          }
        } else if (unvalid || expired) {
          removeLocalStorage('currentUser', 'userToken');
          setLoading(false);
          if (!beAlramed) Alert(t('AUTH.EXPIRED_SESSION'));
          beAlramed = true;
          reporter({
            target: getUserId(),
            action: 'session out',
            data: {
              result: true,
            },
          });
          navigate('/login');
        }
      } catch (error) {
        errorLog(`checkAuth catch ${error.toString()}`);
        // window.location.replace('/offline.html');
      }
    } else if (!defaultRoute) {
      removeLocalStorage('currentUser', 'userToken');
      if (pathname !== '/') {
        if (
          pathname !== '/bots' &&
          pathname !== '/botstore' &&
          pathname !== '/login' &&
          pathname !== '/signup' &&
          pathname !== '/pibo'
        ) {
          navigate('/pibo');
        }
      }
    }
    setLoading(false);
  };

  const handleVisibilityChange = async () => {
    if (!document[hidden]) {
      setLoading(true);
      await checkAuth();
    }
  };

  useLifecycles(
    async () => {
      if (unmount !== true) {
        setLoading(true);
        document.addEventListener(
          visibilityChange,
          handleVisibilityChange,
          false,
        );
        await checkAuth();
      }
    },
    () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange);
      setLoading(false);
      unmount = true;
    },
  );

  return !loading && <AuthWrappedComponent {...props} t={t} />;
};

export default withAuth;
