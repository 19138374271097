/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AddScheduleForm from '../../../components/bots/CalendarBot/AddScheduleForm';
import BotTopMenu from '../../../components/bots/common/BotTopMenu';
import {
  getScheduleItem,
  initaialScheduleItem,
} from '../../../ducks/bots/calendarBot/calendarBot';
import Confirm from '../../../components/Confirm';
import { useMount } from 'react-use';

let prevAdding = false;
const ScheduleFieldContainer = props => {
  const { t } = useTranslation('bot');
  const { mode, name, date } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { calendarBot, pibo } = useSelector(state => state);
  const {
    loading,
    adding,
    selectError: error,
    addError,
    selectedDate,
  } = calendarBot;
  // const { loading: pending, data: commands, error: commandError } = pibo;
  const [popUpProperties, setPopupProperties] = useState({ isPopup: false });

  useMount(() => {
    if (mode === 'update') {
      dispatch(getScheduleItem({ id: name, date }));
    }
    if (mode === 'add') {
      dispatch(initaialScheduleItem());
    }
  }, []);

  useEffect(() => {
    if (!popUpProperties.isPopup) {
      if (error && error.length > 0) {
        setPopupProperties({
          contents: error,
          rightLabel: t('common:POP.OK'),
          rightFunc: () =>
            mode === 'update' ? navigate('/bots/OFFICIAL_CALENDAR') : null,
          mode: 'alert',
          isPopup: true,
          onClose: () => setPopupProperties({ isPopup: false }),
        });
      } else if (addError && addError.length > 0) {
        setPopupProperties({
          contents: addError,
          rightLabel: t('common:POP.OK'),
          mode: 'alert',
          isPopup: true,
          onClose: () => setPopupProperties({ isPopup: false }),
        });
      } else if (prevAdding && !adding) {
        setPopupProperties({
          contents: t('CAL.ADD.SUCCESS'),
          rightLabel: t('common:POP.OK'),
          rightFunc: () => navigate('/bots/OFFICIAL_CALENDAR'),
          mode: 'confirm',
          isPopup: true,
          onClose: () => setPopupProperties({ isPopup: false }),
        });
      }
    }
    prevAdding = adding;
  }, [error, addError, adding]);

  const getTopMenuDataPerMode = () => {
    switch (mode) {
      case 'update':
        return {
          title: t('CAL.UPDATE.ITEM'),
          menuMode: 'WITH_BACK',
          backLocation: `/bots/OFFICIAL_CALENDAR/view?name=${name}&date=${date}`,
        };
      default:
        return {
          title: t('CAL.ADD.LB'),
          menuMode: 'WITH_BACK',
          backLocation: '/bots/OFFICIAL_CALENDAR',
        };
    }
  };
  const topMenuObject = getTopMenuDataPerMode();
  return (
    <div>
      <BotTopMenu {...topMenuObject} />
      {!error && !loading && <AddScheduleForm {...props} />}
      {popUpProperties.isPopup && <Confirm {...popUpProperties} />}
    </div>
  );
};

export default ScheduleFieldContainer;
