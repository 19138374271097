import React from 'react';
import { Header } from 'semantic-ui-react';
import { TAPI_URL } from '../../../circulusConfig';
import { Player } from '../DiaryBot/DateCard';

const StoryCard = ({ book, author, title: bt, hash }) => (
  <div style={{ display: 'flex', gap: '.75rem' }}>
    <div
      style={{
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        padding: '.25rem 12px',
        position: 'relative',
        height: '40px',
        width: '40px',
      }}
    >
      <Player
        url={`${TAPI_URL}v1/stream?hash=${hash}`}
        setError={e => console.log(e)}
      />
    </div>
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'baseline',
      }}
    >
      <Header as="h5" style={{ margin: 0 }}>
        {bt}
      </Header>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          fontSize: '0.875rem',
          color: 'rgba(0,0,0,.5)',
          justifyContent: 'space-between',
          gap: '0.5em',
        }}
      >
        <span>{book}</span>
        {author && <span>|</span>}
        <span>{author}</span>
      </div>
    </div>
  </div>
);

export default StoryCard;
