import React from 'react';
import { Form, Radio } from 'semantic-ui-react';

const FindAccountsSelect = props => {
  const { handleChange, value, t } = props;
  return (
    <div>
      <Form.Group>
        <p style={{ fontWeight: 'bold', margin: '1rem' }}>{t('FIND.ID')}</p>
        <Form.Field
          style={{ padding: '1rem' }}
          control={Radio}
          label={t('FIND.BY_INFO')}
          value="email"
          checked={value === 'email'}
          onChange={handleChange}
        />
        <Form.Field
          style={{ padding: '1rem' }}
          control={Radio}
          label={t('FIND.BY_SERIAL')}
          value="serial"
          checked={value === 'serial'}
          onChange={handleChange}
        />
        <p style={{ fontWeight: 'bold', margin: '1rem' }}>
          {t('FIND.PASSWORD')}
        </p>
        <Form.Field
          style={{ padding: '1rem' }}
          control={Radio}
          label={t('FIND.PASSWORD')}
          value="pw"
          checked={value === 'pw'}
          onChange={handleChange}
        />
      </Form.Group>
    </div>
  );
};

export default FindAccountsSelect;
