import React from 'react';
import { List, Header } from 'semantic-ui-react';
import * as moment from 'moment';
import shortid from 'shortid';
import { useTranslation } from 'react-i18next';

// import { errorLog } from '../../../utils/common';
import CalendarItem from './CalendarItem';

const CalendarItemList = props => {
  const { t } = useTranslation('bot');
  const { scheduleData, selectedDate, onClick } = props;
  // const checkItemHandler = async (index) => {
  //   try {
  //     await checkItemDispatch(index);
  //   } catch (error) {
  //     errorLog(error, 'CalendarItemList>checkItemHandler');
  //   }
  // };

  const date = moment(new Date(selectedDate));
  const monthDataElement = scheduleData[date.get('date') - 1];
  let listItem = [];
  if (monthDataElement) {
    listItem = monthDataElement.reduce(
      (acc, { title, execTime, bot, _id, repeatValue: { type }, command }) => {
        const executeTime = moment(execTime);
        acc.push({
          key: executeTime.get('hour') * 60 + executeTime.get('minute'),
          item: (
            <CalendarItem
              key={shortid.generate()}
              id={_id}
              execTime={executeTime}
              title={title}
              bot={bot}
              type={type}
              command={command}
              onClick={onClick}
            />
          ),
        });
        return acc;
      },
      [],
    );
    listItem.sort((prev, next) => prev.key - next.key);
  }

  return (
    <div
      style={{
        marginTop: '1rem',
        padding: '0 1rem',
      }}
    >
      <Header style={{ marginBottom: '0.75rem' }}>
        {moment(selectedDate).format(t('CAL.DATE.FULL'))}
      </Header>
      <List
        divided
        style={{ overflowY: 'auto', height: 'calc(2rem * 5)', margin: 0 }}
      >
        {listItem.length > 0 && listItem.map(({ item }) => item)}
        {listItem.length === 0 && <h5>{t('CAL.NO_RESULT')}</h5>}
      </List>
    </div>
  );
};

export default CalendarItemList;
