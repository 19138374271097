import React, { useRef, useState } from 'react';
import { Element, scroller } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { Form, Icon, Message } from 'semantic-ui-react';
import { OS_IOS, getMobileOS } from '../../utils/common';
import { StyledMessage } from '../Message';
import { CertCodeInput, CertCodeTimer, InputButtonGroup } from './Components';

const CertificateForm = ({
  loading,
  type,
  hadSent,
  unvalid,
  msg,
  value,
  confirm,
  certCode,
  timer,
  additionalValue,
  onChange,
  handleRequestSend,
  handleRequestCert,
}) => {
  const { t } = useTranslation('user');
  const confirmRef = useRef();
  const [{ ph, tp, ph2, tp2 }] = useState({
    ph: type === 'tel' ? t('TEL.LB') : t('MAIL.LB'),
    tp: type === 'tel' ? 'tel' : 'email',
    ph2: type === 'tel' ? t('MAIL.LB') : t('TEL.LB'),
    tp2: type === 'tel' ? 'email' : 'type',
  });
  const [{ reqBtn, certBtn }, setBtns] = useState({
    reqBtn: true,
    certBtn: true,
  });
  const [loopCnt, setLoop] = useState(0);

  useUpdateEffect(() => {
    let rBtn;
    let cBtn;
    // true(활성), false(비활성)
    if (confirm) {
      // 인증 완료
      cBtn = true;
      rBtn = false;
      if (msg) {
        cBtn = false;
      }
    } else if (hadSent) {
      // 인증 요청
      if (timer.complete) {
        // 시간 만료
        rBtn = true;
        cBtn = false;
        // certCode 초기화 해야함
      } else {
        // 인증번호 대기중
        rBtn = certCode.length !== 5;
        cBtn = certCode.length === 5;
      }
    } else {
      // 인증 요청 전
      cBtn = false;
      if (value && !msg) {
        // 유효한 인증 정보 입력
        rBtn = true;
      } else {
        // 인증 정보 입력 전 또는 형식에 맞지 않아 에러 메시지
        rBtn = false;
      }
    }
    setBtns({ certBtn: !cBtn, reqBtn: !rBtn });
    if (msg && getMobileOS() !== OS_IOS) {
      scroller.scrollTo('msgElement', {
        duration: 0,
        delay: 0,
        smooth: true,
      });
    }
  }, [msg, value, hadSent, certCode, confirm, timer.complete]);

  const sentBtnContent = (l, s) => {
    if (l) {
      return <Icon name="spinner" loading />;
    }
    if (s) return t('VERIFY.RE.REQUEST');
    return t('VERIFY.REQUEST');
  };

  const handleFocus = e => {
    // e.currentTarget.select();
    setLoop(1);
    if (unvalid) {
      onChange(e);
    }
  };

  const handleBlur = () => {
    setLoop(0);
  };

  const handleCodeKeyDown = e => {
    const { keyCode, key } = e;

    if (keyCode === 13 && key === 'enter') {
      handleRequestCert();
    }
  };

  const handleInputClick = e => {
    e.target.focus();
    onChange(e, hadSent);
  };

  return (
    <Element name="msgElement">
      <InputButtonGroup>
        <Form.Group>
          <Form.Input
            placeholder={ph}
            name={type}
            type={tp}
            value={value}
            onChange={onChange}
            readOnly={confirm}
            autoFocus={!confirm}
            onClick={handleInputClick}
          />
          {!confirm && (
            <Form.Button
              content={sentBtnContent(loading, hadSent)}
              color="blue"
              disabled={reqBtn}
              onClick={handleRequestSend}
            />
          )}
          {confirm && (
            <div className="certConfirm">
              <Icon color="green" name="check circle" size="large" />
              {/* {t('VERIFY.COMPLETE')} */}
            </div>
          )}
        </Form.Group>
        {!confirm && (
          <Form.Group>
            <div style={{ position: 'relative' }}>
              <CertCodeInput
                placeholder={t('user:VERIFY.INPUT.CODE')}
                name="certCode"
                type="tel"
                value={certCode}
                disabled={!hadSent}
                onChange={onChange}
                ref={confirmRef}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onKeyDown={handleCodeKeyDown}
                focus={hadSent}
                preventFocusLoop={loopCnt}
              />
              {!timer.complete && timer.time && (
                <CertCodeTimer>{timer.time}</CertCodeTimer>
              )}
            </div>
            <Form.Button
              content={t('VERIFYING')}
              color="blue"
              disabled={certBtn}
              onClick={handleRequestCert}
            />
          </Form.Group>
        )}
        {confirm && (
          <Form.Group>
            <Form.Input
              placeholder={ph2}
              name={type === 'tel' ? 'email' : 'tel'}
              type={tp2}
              value={additionalValue}
              disabled={!hadSent}
              onChange={onChange}
            />
          </Form.Group>
        )}
        <StyledMessage
          color="red"
          hidden={!msg || loading}
          textalign="left"
          className="mini"
        >
          <Message.Content>{msg}</Message.Content>
        </StyledMessage>
      </InputButtonGroup>
    </Element>
  );
};

export default CertificateForm;
