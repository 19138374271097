// import axios from 'axios';
import { API_URL } from '../../../circulusConfig';
import { encodeGetParams } from '../../../utils/api';

export const deleteDiaryItemsApi = async data => {
  const res = await fetch(`${API_URL}diaryBot/deleteDiaryData`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const result = res.json();
  return result;
};

export const getDiariesApi = async ({ robotId, skip, limit }) => {
  const res = await fetch(
    `${API_URL}diaryBot/getDiaryData?${encodeGetParams({
      robotId,
      skip,
      limit,
    })}`,
  );
  const result = res.json();
  return result;
};

/* export const deleteDiaryItemsApi = ({ robotId, selectItems }) => {
  const options = {
    method: 'PUT',
    data: { robotId, selectItems },
    url: `${API_URL}diaryBot/deleteDiaryData`,
  };
  return axios(options);
};

export const getDiariesApi = ({ robotId, selectedDate, past30 }) => {
  const options = {
    method: 'GET',
    params: { robotId, selectedDate, past30 },
    url: `${API_URL}diaryBot/getDiaryData`,
  };
  return axios(options);
}; */
