import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles, useUpdateEffect } from 'react-use';
import { useLastLocation } from 'react-router-dom-last-location';
import { useNavigate, useParams } from 'react-router-dom';
import Confirm from '../../../components/Confirm';
import {
  deleteList,
  fetchTrains,
  getBackData,
  onToggleListMode,
  setData,
  setPopup,
} from '../../../ducks/bots/trainBot/train';
import { getLocale, getRobotId } from '../../../utils/common';
import TrainFormContainer from './TrainFormContainer';
import TrainListContainer from './TrainListContainer';
import {
  ADD_SUCCESS,
  DATA_LOAD_FAIL,
  DELETE_CONFIRM,
  DELETE_SUCCESS,
  getPopup,
  UPDATE_SUCCESS,
} from '../../../components/bots/TrainBot/Popup';

let isMounted = false;
let backLocation = '/bots';

const Index = props => {
  const { title, page, query } = props;
  const { id: qId } = query;
  const { name } = useParams();
  const { lastLocation } = useLastLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const train = useSelector(state => state.train);
  const { pending, popup, path } = train;
  const [dialog, setDialog] = useState(null);

  const getBackgroundData = () => {
    if (!page) {
      dispatch(setData(null));
    } else if (page === 'update') {
      dispatch(fetchTrains({ robotId: getRobotId() }));
    }
    if (page) {
      dispatch(getBackData({ robotId: getRobotId(), locale: getLocale() }));
    }
  };

  useUpdateEffect(() => {
    getBackgroundData();
  }, [page]);

  useUpdateEffect(() => {
    if (popup && 'type' in popup && (!dialog || popup.type !== dialog.type)) {
      const { type, params } = popup;
      const newPopup = {
        ...getPopup(type),
        onClose: () => dispatch(setPopup(null)),
      };
      if (type === DELETE_CONFIRM) {
        newPopup.rightFunc = () => dispatch(deleteList(params));
      } else if (type === DELETE_SUCCESS) {
        newPopup.onClose = () => {
          dispatch(onToggleListMode());
          dispatch(setPopup(null));
        };
      } else if (
        type === ADD_SUCCESS ||
        type === UPDATE_SUCCESS ||
        type === DATA_LOAD_FAIL
      ) {
        newPopup.onClose = () => {
          dispatch(setPopup(null));
          navigate(path);
        };
      }
      return setDialog(newPopup);
    }
    return setDialog(null);
  }, [popup]);

  useLifecycles(
    () => {
      if (lastLocation && 'pathname' in lastLocation) {
        if (lastLocation.pathname.search(`bots/${name}`) < 0) {
          backLocation = lastLocation.pathname;
        }
      }
      if (!isMounted) {
        dispatch(fetchTrains({ robotId: getRobotId() }));
        getBackgroundData();
        isMounted = true;
      }
    },
    () => {
      isMounted = false;
    },
  );

  return (
    <>
      {!pending && (
        <div style={{ background: '#FBFBFB', overflow: 'hidden' }}>
          {(page === 'update' || page === 'add') && (
            <TrainFormContainer page={page} id={qId} />
          )}
          {!(page === 'update' || page === 'add') && (
            <TrainListContainer {...{ title, backLocation }} />
          )}
        </div>
      )}
      {dialog && dialog.isPopup && <Confirm {...dialog} />}
    </>
  );
};

export default Index;
