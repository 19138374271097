/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect } from 'react';
import { Header, Image } from 'semantic-ui-react';
import styled from 'styled-components';
import { SITE_URL } from '../../../circulusConfig';
import { WeatherLocation, WEATHER_COLOR } from './WeatherComponents';

const WeatherTimeInfoWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

const TemperatureValueWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > span {
    font-size: 1rem;
    font-weight: bold;
    &.max {
      color: #ff3f34;
    }
    &.min {
      color: #0033dd;
    }
  }
`;

const WeatherTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 12px;
  & > span {
    font-size: 0.75rem;
  }
  & img.ui.image {
    width: 70px;
    height: 70px;
  }
  & h5.header {
    margin: 0;
    color: inherit;
  }
`;

const Temperature = styled.div`
  position: relative;
  align-self: stretch;
  height: inherit;
  width: 12px;
  margin-right: 8px;
  background: linear-gradient(
    0deg,
    #0033dd 0%,
    #4a85bb 25%,
    #ffb865 50%,
    #fc8427 75%,
    #ff3f34 100%
  );
  mix-blend-mode: darken;
  opacity: 0.8;
  border-radius: 12px;
`;

const WeatherTomorrowCard = data => {
  const { setColor, locationKeyword: location, type } = data;
  const { skyAm, skyPm, tempMax, tempMin } = data;
  const { bgAm, color: cAm, label: lbAm } = WEATHER_COLOR[skyAm];
  const { bgPm, color: cPm, label: lbPm } = WEATHER_COLOR[skyPm];
  const background = `${bgAm}, ${bgPm}, #FFFFFF`;

  useEffect(() => {
    setColor({ background, color: 'inherit' });
  }, [skyAm, skyPm]);

  return (
    <div>
      <WeatherLocation>
        <Header as="h5">{`${
          type.indexOf('1') > 0 ? '내일' : '모레'
        }의 ${location} 날씨 정보`}</Header>
      </WeatherLocation>
      <WeatherTimeInfoWrap>
        <WeatherTime style={{ color: cAm }}>
          <span>오전</span>
          <Image
            src={`${SITE_URL}image/bots/weather/${skyAm}.png`}
            alt={skyAm}
          />
          <Header as="h5">{lbAm}</Header>
        </WeatherTime>
        <WeatherTime style={{ color: cPm }}>
          <span>오후</span>
          <Image
            src={`${SITE_URL}image/bots/weather/${skyPm}.png`}
            alt={skyPm}
          />
          <Header as="h5">{lbPm}</Header>
        </WeatherTime>
        <div style={{ display: 'flex', padding: '1rem 0' }}>
          <Temperature>
            <span />
          </Temperature>
          <TemperatureValueWrap>
            <span className="max">{`${tempMax}℃`}</span>
            <span className="min">{`${tempMin}℃`}</span>
          </TemperatureValueWrap>
        </div>
      </WeatherTimeInfoWrap>
    </div>
  );
};

export default WeatherTomorrowCard;
