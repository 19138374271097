/* eslint-disable react/no-array-index-key */

import React, { useEffect, useRef, useState } from 'react';
import { Form, Segment, FormRadio, Tab, Icon } from 'semantic-ui-react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useMount, useToggle, useUpdateEffect } from 'react-use';

import {
  Page,
  BlueTabMenu,
  BlueTabMenuItem,
  FixedBottomButton,
  FormGroup,
  FormLabel,
  UnderLinedInput,
  WeekdayButton,
} from '../../Components';
import { StyledMessage } from '../../Message';
// import { getRobotId, getUserId } from '../../../utils/common';
import { WEEK_DAYS } from '../../../utils/consts';
import { createCalendar } from '../../../ducks/bots/calendarBot/calendarBot';
// import { fetchCogList } from '../../../ducks/pibo';
import { CommandButton, TabularItem } from './CalendarComp';
import { fetchCogList } from '../../../ducks/pibo';
// import { createCalendar } from '../../../ducks/bots/calendarBot/calendarBot';
import { getRobotId, getUserId } from '../../../utils/common';

const radioListData = [
  { key: 'none', name: 'CAL.ONCE', value: 'none' },
  { key: 'weekly', name: 'CAL.WEEKLY.LB', value: 'weekly' },
  { key: 'monthly', name: 'CAL.MONTHLY.LB', value: 'monthly' },
  { key: 'yearly', name: 'CAL.YEARLY.LB', value: 'yearly' },
];
const TITLE_MAX_LENGTH = 30;

const AddScheduleForm = () => {
  const { t } = useTranslation('bot');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calendarBot = useSelector(state => state.calendarBot);
  const pibo = useSelector(state => state.pibo);
  const { adding, selectSchedule: schedule } = calendarBot;
  const { repeatValue, _id: id } = schedule;
  const { data: commands, loading } = pibo;
  const dateinput = useRef(null);
  const [{ ex: botExample, idx: tabIndex }, setExample] = useState({
    ex: [],
    idx: -1,
  });
  const [{ bot, command }, setCommand] = useState({
    bot: schedule.bot,
    command: schedule.command,
  });
  const [toggle, setToggle] = useToggle(!!schedule.command);
  const [repeat, setRepeat] = useState(
    { ...repeatValue } || {
      type: '',
      value: { day: 0, date: 0, month: 0, times: 0 },
    },
  );
  const [title, setTitle] = useState(schedule.title || '');
  const [execTime, setExec] = useState(moment(schedule.execTime) || moment());
  const [error, setError] = useState(false);

  const handleCommandClick = (bId, children) => () => {
    setCommand({ bot: bId, command: children });
  };
  const makeBotTabularMenu = (list, cmd) =>
    list
      ? list.reduce((acc, { _id, projectId, example, title: menuItem }, i) => {
          if (example && example.length > 0) {
            acc.push({
              menuItem,
              id: _id,
              projectId,
              render: () => (
                <Tab.Pane name={projectId} id={_id} style={{ width: '100%' }}>
                  {example.map((eItem, j) => (
                    <CommandButton
                      id={_id}
                      name={projectId}
                      key={`${_id}_${j}`}
                      active={cmd === eItem}
                      onClick={handleCommandClick(_id, eItem, i, j)}
                      toggle
                    >
                      {eItem}
                    </CommandButton>
                  ))}
                </Tab.Pane>
              ),
            });
          }
          return acc;
        }, [])
      : [];

  const getTabIndex = ex => {
    if (tabIndex > -1) {
      return tabIndex;
    }
    if (bot) {
      return ex.findIndex(({ id: i }) => i === bot);
    }
    return 0;
  };

  useUpdateEffect(() => {
    const ex = makeBotTabularMenu(commands.list, command);
    const idx = getTabIndex(ex);
    if (command) {
      setExample({ ex, idx });
    } else {
      setExample({ ex, idx: 0 });
    }
  }, [command]);

  useEffect(() => {
    if ('list' in commands && commands.list) {
      const ex = makeBotTabularMenu(commands.list, command);
      const idx = getTabIndex(ex);
      setExample({ ex, idx });
    }
  }, [commands]);

  useMount(() => {
    if (schedule.bot && schedule.command) {
      dispatch(fetchCogList('instruction'));
    }
  });

  // eslint-disable-next-line consistent-return
  const handleSubmit = () => {
    if (adding) return adding;
    if (!title || !execTime) return setError(t('CAL.ENTER.REQUIRE_ALL'));
    if (toggle && !bot && !command) return setError(t('CAL.ENTER.CMD_OR_NOT'));
    const {
      type,
      value: { day: repeatDay },
    } = repeat;
    if (!error) {
      const scheduleObject = {
        title,
        execTime: execTime.format(),
        robotId: getRobotId(),
        bot: bot || '',
        command: command || '',
        locale: 'ko',
        userId: getUserId(),
        id,
      };
      if (type === 'none') {
        scheduleObject.repeat = false;
        scheduleObject.repeatValue = {
          type,
          value: {
            day: repeatDay,
            date: moment(execTime).get('date'),
            month: moment(execTime).get('month'),
          },
        };
      } else {
        if (type === 'weekly' && repeatDay.length < 1)
          return setError(t('CAL.ENTER.REPEAT_DAY'));
        scheduleObject.repeat = true;
        scheduleObject.repeatValue = repeat;
      }

      try {
        dispatch(createCalendar(scheduleObject));
      } catch (disPatchError) {
        return setError(t('CAL.UPDATE.ERROR'));
      }
    }
    return navigate('/bots/OFFICIAL_CALENDAR');
  };

  const handleRepeatClick = (e, { id: target }) => {
    const {
      value: { date, month },
    } = repeat;
    if (target === 'weekly') {
      setError(t('CAL.ENTER.REPEAT_DAY'));
    }
    setRepeat({ type: target, value: { day: [], date, month } });
  };

  const handleDateChange = () => {
    dateinput.current.focus();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setError(false);
    if (name === 'title') {
      setTitle(value.slice(0, TITLE_MAX_LENGTH));
    } else if (name === 'dateinput') {
      const h = execTime.hour();
      const m = execTime.minute();
      const v = moment(new Date(value))
        .hour(h)
        .minute(m)
        .second(0)
        .millisecond(0);
      setExec(v);
      setRepeat({
        type: repeat.type,
        value: { ...repeat.value, month: v.month(), date: v.date() },
      });
    } else if (name === 'time') {
      if (!value) return setError(t('CAL.ENTER.REQUIRE_TIME'));
      const [h, m] = value.split(':');
      const v = moment(new Date(execTime.format('YYYY-MM-DD')))
        .hour(h)
        .minute(m)
        .second(0)
        .millisecond(0);
      setExec(v);
    } else if (name === 'botcommand') {
      setCommand({ bot, command: value });
    } else {
      setError(t('CAL.ENTER.REQUIRE_ALL'));
    }
    return null;
  };

  const handleWeekdayClick = e => {
    setError(false);
    const { name } = e.target;
    const numericItem = parseInt(name, 10);
    const {
      type,
      value: { day: d, date, month },
    } = repeat;

    const findIndex = d.findIndex(item => item === numericItem);
    if (findIndex < 0) {
      return setRepeat({
        type,
        value: { day: [numericItem].concat(d).sort(), date, month },
      });
    }
    const newDay = d
      .slice(0, findIndex)
      .concat(d.slice(findIndex + 1, d.length))
      .sort();
    if (newDay.length === 0) {
      setError(t('CAL.ENTER.REPEAT_DAY'));
    }
    return setRepeat({
      type,
      value: { day: newDay, date, month },
    });
  };

  const handleCommandInit = () => {
    setCommand({ bot: '', command: '' });
  };

  const handleCommandToggle = () => {
    if (botExample.length === 0) {
      dispatch(fetchCogList('instruction'));
    }
    if (toggle) {
      handleCommandInit();
    }
    setToggle(!toggle);
  };

  const handleTabChange = (e, { activeIndex }) => {
    setExample({ ex: botExample, idx: activeIndex });
  };

  return (
    <Page style={{ paddingBottom: '3rem' }}>
      <Segment style={{ margin: '1rem', padding: '1rem' }} loading={adding}>
        <Form error>
          <FormGroup>
            <FormLabel essential>{t('CAL.SUBJECT')}</FormLabel>
            <UnderLinedInput
              name="title"
              value={title}
              type="text"
              placeholder={t('CAL.ENTER.SUBJECT')}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel essential>{t('CAL.START')}</FormLabel>
            <UnderLinedInput
              ref={dateinput}
              name="dateinput"
              value={execTime.format('YYYY-MM-DD')}
              type="date"
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel essential>{t('CAL.SET_REPEAT')}</FormLabel>
            <BlueTabMenu
              widths={4}
              style={{
                fontSize: '0.875rem',
                margin: '0.875rem 0 !important',
              }}
            >
              {radioListData &&
                radioListData.map(({ name, value: listValue, key }) => (
                  <BlueTabMenuItem
                    id={key}
                    key={key}
                    name={listValue}
                    active={repeat.type === key}
                    onClick={handleRepeatClick}
                  >
                    {t(name)}
                  </BlueTabMenuItem>
                ))}
            </BlueTabMenu>
            {repeat.type === 'weekly' && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {WEEK_DAYS &&
                  WEEK_DAYS.map(({ name, key }) => (
                    <WeekdayButton
                      key={key}
                      name={key}
                      onClick={handleWeekdayClick}
                      active={repeat.value.day.includes(key)}
                    >
                      {t(name)}
                    </WeekdayButton>
                  ))}
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {(repeat.type === 'monthly' || repeat.type === 'yearly') && (
                <UnderLinedInput
                  name="day"
                  value={
                    repeat.type === 'monthly'
                      ? execTime.format('Do')
                      : execTime.format(t('CAL.DATE.MONTH_DATE'))
                  }
                  readOnly
                  style={{ width: '48%' }}
                  onClick={handleDateChange}
                />
              )}
              <UnderLinedInput
                name="time"
                value={execTime.format('HH:mm')}
                type="time"
                style={
                  repeat.type === 'monthly' || repeat.type === 'yearly'
                    ? { width: '48%' }
                    : { width: '100%' }
                }
                onChange={handleChange}
              />
            </div>
          </FormGroup>
          {error && (
            <StyledMessage
              color="red"
              textalign="left"
              size="mini"
              style={{ marginBottom: '1em' }}
            >
              {error}
            </StyledMessage>
          )}
          <FormGroup
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 0,
            }}
          >
            <FormLabel>{t('CAL.CMD.EXEC')}</FormLabel>
            <FormRadio
              color="blue"
              size="mini"
              checked={toggle}
              toggle
              onChange={handleCommandToggle}
            />
          </FormGroup>
          {toggle && (
            <FormGroup style={{ position: 'relative' }}>
              <UnderLinedInput
                name="botcommand"
                placeholder={t('CAL.ENTER.CMD')}
                value={command || ''}
                onChange={handleChange}
                readOnly
              />
              {bot && command && (
                <Icon
                  name="x"
                  onClick={handleCommandInit}
                  style={{
                    position: 'absolute',
                    top: '0.5rem',
                    right: 0,
                    color: 'rgba(8, 148, 215, 1)',
                  }}
                />
              )}
              {!loading && botExample.length > 0 && tabIndex >= 0 && (
                <TabularItem
                  menu={{ vertical: true, tabular: true }}
                  panes={botExample}
                  activeIndex={tabIndex}
                  onTabChange={handleTabChange}
                />
              )}
            </FormGroup>
          )}
        </Form>
      </Segment>
      <FixedBottomButton widths={1} disabled={adding} onClick={handleSubmit}>
        {t('CAL.DONE')}
      </FixedBottomButton>
    </Page>
  );
};

export default AddScheduleForm;
