/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import {
  CardButtonGroup,
  CardFooter,
  CardHeader,
  CardOption,
  CardTitle,
  IconMenuItem,
  StyledCard,
  TextButton,
} from './Components';

const ExecCard = ({
  t,
  color,
  icon,
  title,
  gap,
  bPreview,
  options: { onCancel, onPreview, onSave, saveDisabled },
  children,
}) => (
  <StyledCard theme={color} gap={gap} fluid>
    <CardHeader>
      <CardTitle>
        <IconMenuItem color={color} icon={icon} size="small" />
        <span style={{ fontWeight: 'bold' }}>{title}</span>
      </CardTitle>
      <CardOption>
        {/* (onPreview && (
          <Icon
            circular
            // inverted
            color="blue"
            name="play"
            style={{ marginRight: '.5rem', border: '1px solid #0894d7' }}
            onClick={onCancel}
          />
)) */}
        {/* (onPreview && (<TextButton color="#0894d7" onClick={onPreview} style={{marginRight: '1em'}}>{t('conn:TRN.LABEL.PREVIEW')}</TextButton>)) */}
        {/* onCancel && (
          <Icon
            circular
            inverted
            color="grey"
            name="x"
            style={{ opacity: '0.7', marginRight: '.5rem' }}
            onClick={onCancel}
          />
          ) */}
        {onSave && (
          <TextButton
            color="#0894d7"
            disabled={saveDisabled}
            onClick={onSave}
            style={{ marginRight: '1em' }}
          >
            {t('bot:TRN.LABEL.SAVE')}
          </TextButton>
        )}
      </CardOption>
    </CardHeader>
    {children}
    <CardFooter>
      {onSave && (
        <TextButton
          color="#0894d7"
          disabled={saveDisabled}
          onClick={onSave}
          style={{ marginRight: '1em' }}
        >
          {t('bot:TRN.LABEL.SAVE')}
        </TextButton>
      )}
      {onCancel && onPreview && (
        <CardButtonGroup className="ui two buttons" selected={bPreview}>
          <Button basic color="blue" onClick={onPreview}>
            {bPreview ? (
              <>
                <Icon name="stop" />
                {t('bot:TRN.LABEL.STOP')}
              </>
            ) : (
              <>
                <Icon name="play" />
                {t('bot:TRN.LABEL.PREVIEW')}
              </>
            )}
          </Button>
          <Button basic color="grey" onClick={onCancel} disabled={bPreview}>
            <Icon name="x" />
            {t('bot:TRN.LABEL.DELETE')}
          </Button>
        </CardButtonGroup>
      )}
    </CardFooter>
  </StyledCard>
);

export default ExecCard;
