/* eslint-disable consistent-return */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import StoreMybot from '../../components/botstore/StoreMybot';
import {
  fetchBotsList,
  fetchAutoCompleteData,
  unInstallBot,
} from '../../ducks/bots';
import { getRobotId } from '../../utils/common';
import { DEFAULT_APPS } from '../../utils/consts';
import withAuth from '../../hocs/withAuth';
import withLoading from '../../hocs/withLoading';

const BotstoreMyBotPage = () => {
  const { bots, searching, searchStr, errorMsg } = useSelector(
    state => state.bots,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const robotId = getRobotId();

  const addSearchChange = value => {
    if (!robotId) return;
    dispatch(fetchAutoCompleteData({ str: value, robotId, use: false }));
  };

  const handleBtnClick = (cmd, data) => {
    if (cmd === 'remove') {
      const { projectId, projectName, userId, botId, title } = data;
      let obj = { botId, title, robotId: getRobotId(), isList: true };
      if (userId === 'pibo' && projectId.indexOf('OFFICIAL_') === 0) {
        obj = { ...obj, projectId, userId };
      } else {
        obj = { ...obj, projectId: projectName, userId };
      }
      return dispatch(unInstallBot(obj));
    }
    if (cmd === 'open') {
      const { projectId, botId } = data;
      if (DEFAULT_APPS.includes(projectId)) {
        return navigate(`/bots/${projectId}`);
      }
      return navigate(`/bots/defaultPage?botId=${botId}`);
    }
  };

  const handleItemClick = botId => {
    navigate(`/botstore/${botId}`);
  };

  useMount(() => {
    if (!robotId) return;
    dispatch(fetchBotsList(robotId, false));
  });

  return (
    <StoreMybot
      {...{
        bots,
        searching,
        searchStr,
        errorMsg,
        addSearchChange,
        handleBtnClick,
        handleItemClick,
      }}
    />
  );
};

export default withAuth(withLoading(BotstoreMyBotPage));
