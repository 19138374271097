import React, { useState } from 'react';
import { Form, Message } from 'semantic-ui-react';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import { Element, scroller } from 'react-scroll';
import styled from 'styled-components';
import {
  AccountDateInput,
  AccountSelect,
} from '../../components/account/AccountComponents';
import { GENDER_OPTIONS } from '../../utils/consts';
import { StyledMessage } from '../../components/Message';
import { validateBirthDate } from '../../utils/validate';
import { OS_IOS, detectOS, getMobileOS } from '../../utils/common';

const InputInlineGroup = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 1.25rem;
  & .fields {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.625rem;
    &.inline {
      & .field {
        margin-right: 1rem;
        &:nth-last-child(1) {
          margin: 0;
        }
      }
    }
    & .field {
      top: 0;
      bottom: 0;
      width: inherit;
      & .ui.input {
        width: inherit;
        & input {
          width: inherit;
          border-radius: 0;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          border-bottom: 1px solid #0894d7;
        }
      }
    }
    & i.icon {
      color: #0894d7;
      height: initial;
      align-self: center;
    }
  }
`;

const InfoContainer = props => {
  const { t } = useTranslation('user');
  const { info: infoData, handleChange } = props;
  const [info, setInfo] = useState(infoData);
  const { firstName, lastName, birthDate, gender } = info;
  const [msg, setMessage] = useState('');
  const genderArr = GENDER_OPTIONS.map(({ id, value }) => ({
    id,
    key: id,
    value: id,
    text: t(value),
  }));
  const maximum = moment().format('YYYY-MM-DD');

  const getInfoMsg = (name, value) => {
    if (name === 'gender' && !GENDER_OPTIONS.find(item => item.id === value)) {
      return t('GENDER.UNVALID');
    }
    if (name === 'birthDate') {
      const message = validateBirthDate(value);
      if (message) {
        return message;
      }
    }
    if (name === 'firstName' || name === 'lastName') {
      const expression = t('NAME.REGEXP');
      const nameRegExp = new RegExp(expression, 'ig');
      if (name === 'firstName' && !value) {
        return t('NAME.INPUT_FIRST');
      }
      if (name === 'lastName' && !value) {
        return t('NAME.INPUT_LAST');
      }
      if (value.match(nameRegExp) > -1) {
        return t('NAME.UNVALID.CHAR');
      }
      if (value.length > 30) {
        return t('NAME.MSG.LENGTH');
      }
    }
    return false;
  };

  const onChange = (e, data) => {
    if (data) {
      const { name, value } = data;
      setMessage(getInfoMsg(name, value));
      setInfo({ ...info, [name]: value });
    } else {
      const changeTarget = e.target.name;
      const changeValue = e.target.value;
      setMessage(getInfoMsg(changeTarget, changeValue));
      setInfo({ ...info, [changeTarget]: changeValue });
    }
  };

  const onBlur = e => {
    const changeTarget = e.target.name;
    let temp = '';
    if (changeTarget === 'lastName') {
      temp = getInfoMsg(changeTarget, lastName);
    } else if (changeTarget === 'firstName') {
      temp = getInfoMsg(changeTarget, firstName);
    } else if (changeTarget === 'birthDate') {
      temp = getInfoMsg(changeTarget, birthDate);
    } else if (changeTarget === 'gender') {
      temp = getInfoMsg(changeTarget, gender);
    }
    setMessage(temp);
    setInfo({ firstName, lastName, birthDate, gender });
  };

  useUpdateEffect(() => {
    if (msg && getMobileOS() !== OS_IOS) {
      scroller.scrollTo('msgElement', {
        duration: 0,
        delay: 0,
        smooth: true,
      });
    }
    handleChange({ ...info, error: msg });
  }, [info, msg]);

  useUpdateEffect(() => {
    if (infoData.error && getMobileOS() !== OS_IOS) {
      scroller.scrollTo('msgElement', {
        duration: 0,
        delay: 0,
        smooth: true,
      });
    }
    setMessage(infoData.error);
  }, [infoData.error]);

  return (
    <InputInlineGroup>
      <Element name="msgElement">
        <Form.Group style={{ width: '100%' }} className="inline">
          <Form.Input
            placeholder={t('NAME.LAST')}
            name="lastName"
            type="text"
            value={lastName}
            onChange={onChange}
            onBlur={onBlur}
          />
          <Form.Input
            placeholder={t('NAME.FIRST')}
            name="firstName"
            type="text"
            value={firstName}
            onChange={onChange}
            onBlur={onBlur}
          />
        </Form.Group>
        {detectOS() !== OS_IOS && (
          <Form.Group style={{ width: '100%' }}>
            <AccountDateInput
              placeholder={t('BIRTH.LB')}
              max={maximum}
              min="1900-01-01"
              name="birthDate"
              type="date"
              value={birthDate}
              onChange={onChange}
              onClick={onChange}
              onBlur={onBlur}
            />
          </Form.Group>
        )}
        <Form.Group style={{ width: '100%' }}>
          <AccountSelect
            placeholder={t('GENDER.LB')}
            name="gender"
            options={genderArr}
            onChange={onChange}
            onBlur={onBlur}
            value={gender}
          />
        </Form.Group>
        <StyledMessage
          color="red"
          hidden={!msg}
          textalign="left"
          className="mini"
          style={{ marginBottom: '3rem' }}
        >
          <Message.Content>{msg}</Message.Content>
        </StyledMessage>
        {/* <AccountButton widths="2" fluid attached="bottom">
        <Button
          color="blue"
          name="ok"
          onClick={onInfoConfirm}
          disabled={!(birthDate && gender) || msg.length > 0}
        >
          {t('NEXT')}
        </Button>
        <Button name="cancel" onClick={onCancel}>
          {t('PREV')}
        </Button>
      </AccountButton> */}
      </Element>
    </InputInlineGroup>
  );
};

export default InfoContainer;
