/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLifecycles, useUpdateEffect } from 'react-use';
import { useNavigate } from 'react-router-dom';
import {
  FormSegment,
  CommandInput,
} from '../../../components/bots/TrainBot/Components';
import { Page } from '../../../components/Components';
import ExecutionMenu from '../../../components/bots/TrainBot/ExecutionMenu';
import TopMenu from '../../../components/TopMenu';
import { killPibo, previewPibo } from '../../../pibo';
import {
  addItem,
  checkQ,
  setData,
  setValue,
  updateItem,
} from '../../../ducks/bots/trainBot/train';
import { getRobotId } from '../../../utils/common';
import {
  BG_ITEM,
  BOT_ITEM,
  EXECUTES_MAX_LENGTH,
  MOTION_ITEM,
  SPEAK_ITEM,
  testCommandRegExr,
} from '../../../ducks/bots/trainBot/consts';
import ExecuteList from '../../../components/bots/TrainBot/ExecuteList';

let timeout;
const TrainFormContainer = props => {
  const { t } = useTranslation('bot');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    list,
    executes,
    command,
    commandPass,
    error: commandError,
    path,
  } = useSelector(state => state.train);
  const { page, id } = props;
  const title = page === 'add' ? t('TRN.LB.TRAIN') : t('TRN.CONTENT_UPDATE');
  const [cmd, setCmd] = useState(command || '');
  const [bPreview, setPreview] = useState(false);
  const [error, setError] = useState({ cmd: null, execute: null });

  const handleCompleteClick = () => {
    const data = { q: cmd, robotId: getRobotId() };
    if (page === 'add') {
      dispatch(addItem(data));
    }
    if (page === 'update') {
      dispatch(updateItem(data));
    }
    navigate(path);
  };

  const handleDeleteQ = () => {
    setCmd('');
  };

  const [{ cnt, len, height }, setHeight] = useState({
    cnt: 0,
    len: 0,
    height: 0,
  });
  const handleKeyChange = inputRef => {
    if (inputRef && inputRef.current && inputRef.current.ref) {
      // 값을 직접 입력 햇을 경우에만 rows 높이를 정확히 계산.. 복붙해서 2줄이상이면 판단할 수 없음
      const { scrollHeight } = inputRef.current.ref.current;
      const realHeight = parseInt(
        inputRef.current.ref.current.style.height,
        10,
      );

      if (!cnt) {
        setHeight({
          cnt: 1,
          len: inputRef.current.ref.current.value.length,
          height: inputRef.current.ref.current.scrollHeight,
        });
      }

      if (realHeight !== scrollHeight) {
        inputRef.current.ref.current.style.height = `${scrollHeight}px`;
      }

      if (cnt === 1 && height < inputRef.current.ref.current.scrollHeight) {
        setHeight({
          cnt: 2,
          len: inputRef.current.ref.current.value.length - 1,
          height,
        });
        inputRef.current.ref.current.style.height = `${inputRef.current.ref.current.scrollHeight}px`;
      }

      if (
        cnt >= 1 &&
        inputRef.current.ref.current.value.length <= len &&
        height !== scrollHeight
      ) {
        inputRef.current.ref.current.style.height = `${height}px`;
      }
    }
  };

  const handleCmdChange = e => {
    const { value } = e.target;
    setCmd(value);
  };

  const onPreview = index => {
    setPreview(false);
    if (bPreview !== false) {
      killPibo();
    }

    const array = executes.map(({ card, ...rest }) => ({ ...rest }));
    let previewList = null;
    if (index > -1 && bPreview !== index) {
      previewList = [array[index]];
      setPreview(index);
    }
    if (index === undefined && typeof bPreview === 'boolean' && !bPreview) {
      previewList = array;
      setPreview(!bPreview);
    }
    if (previewList) {
      const result = previewPibo(previewList, () => {
        setPreview(false);
      });

      if (!result) {
        setPreview(false);
      }
    }
  };

  const handleCancelExecution = i => {
    const newExecutes = [...executes];
    newExecutes.splice(i, 1);
    dispatch(setValue(newExecutes));
  };

  const handleSetPage = () => {
    navigate(path);
  };

  useUpdateEffect(() => {
    if (cmd.length < 2 || testCommandRegExr(cmd) || cmd.length > 40) {
      setError({
        cmd:
          '한글, 영문, 숫자, 마침표, 쉼표, 물음표, 느낌표, 물결표(. , ? ! ~) 외의 특수문자, 줄바꿈, 탭없이 2글자 이상 40자 이하로 입력할 수 있습니다.',
      });
    } else {
      setError({ cmd: null });
    }
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      dispatch(checkQ(cmd));
    }, 500);
  }, [cmd]);

  useUpdateEffect(() => {
    if (commandPass) {
      setError({ cmd: null });
    } else {
      setError({ cmd: commandError });
    }
  }, [commandPass, commandError]);

  useLifecycles(
    () => {
      if (executes.length > EXECUTES_MAX_LENGTH) {
        setError({ execute: t('bot:TRN.ERROR.EXECUTE_MAX') });
      }
      if (page === 'update' && id) {
        const listItem = list.find(item => item.id === id);
        if (listItem) {
          const { title: tt, bots } = listItem;
          const executesList = bots.map(
            ({ bot, speak, motion, bg, _eye, _fx }) => {
              const item = {};
              if (bot) {
                item.card = { ...BOT_ITEM };
                item.bot = bot;
              }
              if (speak) {
                item.card = { ...SPEAK_ITEM };
                item.speak = speak;
              }
              if (motion) {
                item.card = { ...MOTION_ITEM };
                item.motion = motion;
              }
              if (bg) {
                item.card = { ...BG_ITEM };
                item.bg = bg;
              }
              if (_fx) {
                item._fx = _fx;
              }
              if (_eye) {
                item._eye = _eye;
              }
              return item;
            },
          );
          setCmd(tt);
          dispatch(
            setData({ command: tt, executes: executesList, checked: [id] }),
          );
        }
      }
    },
    () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    },
  );

  useUpdateEffect(() => {
    if (executes.length > EXECUTES_MAX_LENGTH) {
      setError({ execute: t('bot:TRN.ERROR.EXECUTE_MAX') });
    } else {
      setError({ execute: null });
    }
  }, [executes]);

  return (
    <Page style={{ paddingBottom: '70px' }}>
      <TopMenu
        title={title}
        menuMode="WITH_BACK_AND_COMPLETE"
        handleBackClick={handleSetPage}
        handleCompleteClick={handleCompleteClick}
        enabled={!!(cmd && executes.length > 0 && !error.cmd && !error.execute)}
      />
      <FormSegment>
        <CommandInput
          {...{
            cmd,
            error: error.cmd,
            onChange: handleCmdChange,
            onDelete: handleDeleteQ,
            onKey: handleKeyChange,
          }}
        />
        <ExecuteList
          {...{
            t,
            executes,
            bPreview,
            error: error.execute,
            onPreview,
            onCancel: handleCancelExecution,
          }}
        />
      </FormSegment>
      <ExecutionMenu {...props} />
    </Page>
  );
};

export default TrainFormContainer;
