import React from 'react';
import shortId from 'shortid';
import { useTranslation } from 'react-i18next';
import {
  InputLabel,
  InputWrap,
  ToggleButtonGroup,
  ToggleButtonGroupMessage,
} from './Components';
import { StyledToggleButton } from '../Components';

const UserInfoInterest = props => {
  const { t } = useTranslation('user');
  const {
    loading,
    helper,
    interestList,
    onClick,
    error: { interest: error },
  } = props;

  return (
    <InputWrap loading={loading}>
      {helper && <InputLabel>{t('INTEREST.LB')}</InputLabel>}
      <ToggleButtonGroup>
        {helper && (
          <ToggleButtonGroupMessage error={error && error.length > 0}>
            {error || t('INTEREST.MSG.LENGTH')}
          </ToggleButtonGroupMessage>
        )}
        <div
          style={{
            width: '100%',
            flexWrap: 'wrap',
            display: 'inline-flex',
            gap: '10px',
          }}
        >
          {interestList &&
            interestList.map(({ _id: id, name, selected }) => (
              <StyledToggleButton
                key={shortId.generate()}
                compact
                size="tiny"
                id={id}
                value={name}
                inverted={!selected}
                onClick={onClick}
              >
                {name}
              </StyledToggleButton>
            ))}
        </div>
      </ToggleButtonGroup>
    </InputWrap>
  );
};

export default UserInfoInterest;
