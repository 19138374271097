import React from 'react';
import { Card, Label, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import {
  StoreCard,
  StoreCardHeader,
  StoreCardMeta,
  StyledStatistics,
  StoreCardDescription,
  StoreRating,
} from './StoreComponents';
import { getLocale, parseBotDescription } from '../../utils/common';
import { BotImage } from '../Components';

const StoreSearchItem = props => {
  const { t } = useTranslation('store');
  const {
    id,
    title,
    score,
    reviews,
    type,
    description,
    fluid,
    handleItemClick,
  } = props;

  const locale = getLocale();
  const rating = score || 0;
  const localedTitle = data => {
    if (typeof data === 'string') {
      return data;
    }
    if (typeof data === 'object' && locale in data) {
      return data[locale];
    }
    return t('NO_SUBJECT');
  };
  const localedDescription = data => {
    if (typeof data === 'string') {
      return parseBotDescription(data, true);
    }
    if (typeof data === 'object' && locale in data) {
      return data[locale];
    }
    return t('DETAIL.NO_DETAILS');
  };
  return (
    <StoreCard
      fluid={fluid.toString()}
      onClick={() => handleItemClick(id)}
      className="ui card"
    >
      <Card.Content>
        <BotImage {...{ id, style: 'item' }} />
        <StoreCardHeader>
          {localedTitle(title)}
          {type === 2 && (
            <Label
              color="yellow"
              size="mini"
              horizontal
              style={{ marginLeft: '.5rem' }}
            >
              <Icon name="flask" />
              Lab
            </Label>
          )}
        </StoreCardHeader>
        <StoreCardMeta>
          <StoreRating
            value={rating}
            defaultRating={Math.ceil(rating)}
            maxRating={5}
            disabled
          />
          <StyledStatistics>{t('REVIEW.TOTAL', { reviews })}</StyledStatistics>
        </StoreCardMeta>
        <StoreCardDescription>
          {localedDescription(description)}
          <div style={{ width: '100%', height: '10px' }} />
        </StoreCardDescription>
      </Card.Content>
    </StoreCard>
  );
};

export default StoreSearchItem;
