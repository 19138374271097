import React, { useState } from 'react';
import { Header, Divider, Segment, Button } from 'semantic-ui-react';
import styled from 'styled-components';
import { DEFAULT_AP } from '../../../utils/consts';
import QRCodeInputForm from './QRCodeInputForm';

const ConnectWrap = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: ${window.innerHeight}px;
  background-color: rgba(251, 251, 251, 1);
  padding: 1rem 1rem 0rem;
`;

const PiboManualWiFi = props => {
  const { onChange, loading, t, isPopup } = props;

  const initialAP = {
    essid: null,
    password: null,
  };
  const [{ essid, password }, setAP] = useState({ ...initialAP });

  const handleConnDefault = () => {
    setAP({ ...initialAP });
    onChange(DEFAULT_AP);
  };

  const handleConnManual = () => {
    // setAP({ ...initialAP });
    onChange({
      essid,
      password,
      wpa: 'wpa',
      encrypted: !!password,
    });
  };

  const handleInputChange = (e, { name, value }) => {
    setAP({ essid, password, [name]: value });
  };

  return (
    <ConnectWrap>
      {!loading && (
        <div>
          <div>
            {t('conn:MSG.INFO_STEPS')}
            <Segment
              style={{
                fontSize: '0.875rem',
                fontWeight: 'bold',
                marginTop: '1rem',
              }}
            >
              <ul
                style={{
                  fontWeight: 'normal',
                  color: '#0894d7',
                  paddingInlineStart: '1.5rem',
                  margin: 0,
                }}
              >
                <li>{t('conn:MSG.STEP_1')}</li>
                <li>
                  {t('conn:MSG.STEP_2')}
                  <div style={{ textAlign: 'center', marginTop: '.5rem' }}>
                    <img
                      src="/image/unconnect_led2.png"
                      alt="oled"
                      style={{
                        display: 'inline-block',
                        borderRadius: '.5rem',
                        width: '128px',
                        height: '64px',
                      }}
                    />
                  </div>
                </li>
              </ul>
            </Segment>
          </div>
          <Segment>
            <Header size="small">{t('conn:CONNECT_DEFAULT')}</Header>
            <p
              style={{
                fontSize: '0.825rem',
                color: '#0894d7',
              }}
            >
              {t('conn:MSG.DEFAULT_CONNECT')}
            </p>
            <Button
              color="blue"
              content={t('conn:CONFIG_CIRCULUS')}
              icon="wifi"
              labelPosition="left"
              fluid
              disabled={loading}
              onClick={handleConnDefault}
            />

            <Divider horizontal>Or</Divider>

            <Header size="small">{t('conn:CONNECT_MANUAL')}</Header>
            <QRCodeInputForm
              {...{
                t,
                onChange: handleInputChange,
                disalbed: loading || !essid || (essid && isPopup),
                onClick: handleConnManual,
              }}
            />
          </Segment>
        </div>
      )}
    </ConnectWrap>
  );
};
export default PiboManualWiFi;
