import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import i18 from '../lang/i18n';
import { userSignIn, userSocialSignIn } from '../utils/api';
import {
  setCurrentUser,
  setUserToken,
  INSIDER,
  setLocalStorage,
  detectOS,
} from '../utils/common';
import { reporter } from '../utils/report';

const name = 'user';
const initialState = {
  loading: false,
  error: '',
  type: '',
  data: null,
  toConnect: false,
  toMain: false,
  toSignUp: false,
};

const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = e => (e && 'code' in e ? e.code.toUpperCase() : '');

export const signIn = createAsyncThunk(
  `${name}/SIGN_IN`,
  async (logindata, { rejectWithValue }) => {
    const { id, pw } = logindata;
    // const { user, ...rest } = getState().user;
    // dispatch(loading({ user: { ...user, userId: id }, ...rest }));

    if (id.length < 1 && pw.length < 1) {
      return rejectWithValue(i18.t('user:SIGN_IN.MSG.INPUT_ALL'));
    }
    if (id.length < 1) {
      return rejectWithValue(i18.t('user:SIGN_IN.MSG.INPUT_ID'));
    }
    if (pw.length < 1) {
      return rejectWithValue(i18.t('user:SIGN_IN.MSG.INPUT_PW'));
    }
    try {
      const res = await userSignIn(logindata);
      if (res.result) {
        if ('temp' in res && res.temp) {
          return rejectWithValue(i18.t('user:SIGN_IN.MSG.UNCERT'));
        }
        const {
          userId,
          nickName,
          robotId,
          robotPId,
          userPId,
          moreInfo,
          insider,
          version,
          // redirect,
        } = res.data;
        INSIDER.verify = insider;
        // if (redirect) {
        //   return window.location.replace(
        //     `${TMP_SITE_URL}redirect?token=${res.token}`,
        //   );
        // }
        await setCurrentUser({
          userPId,
          userId,
          robotId,
          robotPId,
          nickName,
        });
        await setUserToken(res.token);
        await setLocalStorage('version', version);
        reporter({
          target: id,
          action: 'signin',
          data: {
            result: true,
          },
        });
        return {
          toConnect: moreInfo || (!moreInfo && !robotId && robotId.length < 1),
          toMain: !moreInfo && robotId.length > 1 && userId.length > 1,
        };
      }
      reporter({
        target: id,
        action: 'signin',
        data: {
          result: false,
        },
      });
      throw Error(res.error);
    } catch (error) {
      return rejectWithValue(
        getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      );
    }
  },
);

export const socialSign = createAsyncThunk(
  `${name}/SOCIAL_SIGN`,
  async ({ type, data }, { rejectWithValue }) => {
    try {
      const res = await userSocialSignIn({
        os: detectOS(),
        type,
        data,
      });
      if (res.result) {
        if (res.token) {
          const {
            userId,
            userPId,
            robotId,
            robotPId,
            version,
            nickName,
            insider,
          } = res.data;
          INSIDER.verify = insider;
          await setCurrentUser({
            userPId,
            userId,
            robotId,
            robotPId,
            nickName,
          });
          await setLocalStorage('version', version);
          // db 데이터
          await setUserToken(res.token);
          return { toMain: true };
        }
        return { toSignUp: true, data: res.data };
      }
      if ('error' in res && 'desc' in res.error) {
        throw Error(res.error.desc);
      }
      throw Error('소셜 계정 로그인 가입 중 문제가 발생했습니다.');
    } catch (error) {
      return rejectWithValue(
        error && 'message' in error
          ? error.message
          : `${
              type === 'kakao' ? '카카오' : '구글'
            }계정으로 로그인에 실패했습니다.`,
      );
    }
  },
);

const userSlice = createSlice({
  name,
  initialState,
  reducers: {
    signInError: (state, action) => ({ ...state, error: action.payload }),
  },
  extraReducers: {
    [signIn.pending.type]: state => ({
      ...state,
      loading: true,
      isComplete: false,
    }),
    [signIn.fulfilled.type]: (state, action) => ({
      ...state,
      error: '',
      ...action.payload,
    }),
    [signIn.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      isComplete: false,
      error: action.payload,
    }),
    [socialSign.pending.type]: () => ({
      ...initialState,
      error: null,
      loading: true,
    }),
    [socialSign.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    [socialSign.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload, // error가 data 안에 포함
    }),
  },
});

const { reducer: userReducer, actions } = userSlice;
export const { signInError } = actions;

export default userReducer;
