import React from 'react';
import { Icon } from 'semantic-ui-react';
import { PullToRefresh } from './PullToRefresh';

const withPullToRefresh = WrappedComponent => ({ onRefresh, ...rest }) => (
  <PullToRefresh
    pullDownContent={
      <div style={{ textAlign: 'center', opacity: '.8', padding: '0.5rem' }}>
        <Icon name="refresh" size="tiny" color="grey" />
      </div>
    }
    releaseContent={
      <div style={{ textAlign: 'center', opacity: '.8', padding: '0.5rem' }}>
        <Icon name="refresh" color="grey" />
      </div>
    }
    refreshContent={
      <div style={{ textAlign: 'center', opacity: '.8', padding: '0.5rem' }}>
        <Icon name="refresh" color="grey" loading />
      </div>
    }
    pullDownThreshold={100}
    onRefresh={onRefresh}
    triggerHeight={150}
    backgroundColor="#fbfbfb"
    style={{ textAlign: 'center', width: '100%' }}
  >
    <WrappedComponent {...rest} />
  </PullToRefresh>
);

export default withPullToRefresh;
