/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { LastLocationProvider } from 'react-router-dom-last-location';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Button, Dimmer, Icon } from 'semantic-ui-react';

import reducer from './ducks';
import 'semantic-ui-less/semantic.less';
import AppInfo from './components/app/AppInfo';
import NotFound from './components/NotFound';
import AppSettingPage from './containers/app/AppSettingPage';
import UserSettingDetailPage from './containers/account/UserSettingDetailPage';
import UserSettingPage from './containers/account/UserSettingPage';
import UserUpdatePwPage from './containers/account/UserUpdatePwPage';
import VerificationPage from './containers/account/VerificationPage';
import EmailUpdatePage from './containers/account/EmailUpdatePage';
import PiboSettingPage from './containers/pibo/PiboSettingPage';
import PiboSettingDetailPage from './containers/pibo/PiboSettingDetailPage';
import BotstoreDetailPage from './containers/botstore/BotstoreDetailPage';
import LoginPage from './containers/account/LoginPage';
import SignupPage from './containers/account/SignUpPage';
import AccountSetPage from './containers/account/FindAccountsPage';
import LinkPage from './containers/link/LinkPage';
import Main from './Main';
import SocialSignUpPage from './containers/account/SocialSignUpPage';
import BotRouter from './containers/bots/BotRouter';
import { Alert, BrowserView, OS_AND, OS_IOS, detectOS } from './utils/common';
// import { TAPI_URL } from './circulusConfig';
// import ExperiencePage from './containers/pibo/ExperiencePage';

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const App = () => {
  // const authenticate = () => new Promise(resolve => setTimeout(resolve, 200));
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(true);

  const handleInstall = () => {
    if (detectOS() === OS_AND) {
      return window.location.replace(
        'https://play.google.com/store/apps/details?id=us.circul.pibo&pli=1',
      );
    }
    if (detectOS() === OS_IOS) {
      return window.location.replace(
        'https://apps.apple.com/kr/app/%ED%8C%8C%EC%9D%B4%EB%B3%B4/id6472163090',
      );
    }
    return Alert('Android 또는 iOS에서 앱 설치를 이용할 수 있습니다.');
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developers.kakao.com/sdk/js/kakao.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      setLoading(false);
      document.body.removeChild(script);
    };
  }, []);

  return (
    !loading && (
      <Provider store={store}>
        <BrowserRouter>
          <LastLocationProvider>
            <Routes>
              <Route path="/bots/:name" element={<BotRouter />} />
              <Route path="/bots/:name/:page" element={<BotRouter />} />
              <Route path="*" element={<Main />} />
              <Route path="/app/info/:menu" exact element={<AppInfo />} />
              <Route path="/app/:menu" exact element={<AppSettingPage />} />
              <Route path="/setting/:uid" exact element={<UserSettingPage />} />
              <Route
                path="/setting/:uid/:menu"
                exact
                element={<UserSettingDetailPage />}
              />
              <Route path="/update/:uid" exact element={<UserUpdatePwPage />} />
              <Route
                path="/pibo/:pid/:item"
                exact
                element={<PiboSettingDetailPage />}
              />
              <Route path="/pibo/:pid" exact element={<PiboSettingPage />} />
              <Route
                path="/botstore/:botId"
                exact
                element={<BotstoreDetailPage />}
              />
              <Route path="/account/:name" exact element={<AccountSetPage />} />
              <Route path="/findAccounts" exact element={<AccountSetPage />} />
              <Route
                path="/user/verification/:type/:serial"
                exact
                element={<VerificationPage />}
              />
              <Route path="/updateEmail" element={<EmailUpdatePage />} />
              <Route path="/login" exact element={<LoginPage />} />
              <Route path="/connect" exact element={<LinkPage />} />
              <Route path="/signup" exact element={<SignupPage />} />
              <Route
                path="/social/signup"
                exact
                element={<SocialSignUpPage />}
              />
              {/* <Route path="/" exact element={withAuth(ExperiencePage)} /> */}
              <Route element={<NotFound />} />
            </Routes>
            <BrowserView>
              <Dimmer
                active={popup}
                page
                style={{ background: 'white', paddingBottom: 0 }}
              >
                <div
                  className="popup_231129"
                  onClick={handleInstall}
                  onKeyUp={handleInstall}
                />
                <Button
                  size="mini"
                  basic
                  fluid
                  style={{ boxShadow: 'none', margin: '.5em 0' }}
                  onClick={() => setPopup(false)}
                >
                  웹페이지로 계속하기
                  <Icon name="chevron right" />
                </Button>
              </Dimmer>
            </BrowserView>
          </LastLocationProvider>
        </BrowserRouter>
      </Provider>
    )
  );
};

export default App;
