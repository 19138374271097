import moment from 'moment';
import React from 'react';
import { Header } from 'semantic-ui-react';

const ClockCard = data => {
  const { mode, type, firstTime: time } = data;
  let subContent;
  let content;
  let value;
  if (type === 'time') {
    content = '현재 시간은';
    const { minute: m, hour: h } = data;
    value = `${h}:${m}`;
  } else if (type === 'date' || type === 'day') {
    const { month: m, date: d } = data;
    let days = 0;
    if (mode === '어제') {
      days = 1;
    }
    if (mode === '내일') {
      days = -1;
    }
    const date =
      type === 'date'
        ? moment(time)
            .month(parseInt(m, 10) - 1)
            .date(d)
            .add(days, 'days')
        : moment(time)
            .date(d)
            .add(days, 'days');
    if (mode === '오늘') {
      content = '오늘은';
      value = date.format('LL dddd');
    }
    if (mode === '어제') {
      subContent = `오늘은 ${date.format('LL dddd')} 이고,`;
      content = '어제는';
      value = date.subtract(1, 'days').format('LL dddd');
    }
    if (mode === '내일') {
      subContent = `오늘은 ${date.format('LL dddd')} 이고,`;
      content = '내일은';
      value = date.add(1, 'days').format('LL dddd');
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        gap: '.35rem',
        flexWrap: 'wrap',
        alignItems: 'center',
      }}
    >
      <Header
        as="h5"
        style={{
          fontSize: '0.875rem',
          margin: 0,
          textAlign: 'left',
          fontFamily: 'Nanum Gothic',
          width: '100%',
          opacity: 0.6,
        }}
      >
        {subContent}
      </Header>
      <Header
        as="h5"
        style={{
          fontSize: '0.875rem',
          margin: 0,
          textAlign: 'right',
        }}
      >
        {content}
      </Header>
      <div
        style={{
          display: 'flex',
          gap: '.35rem',
          flexWrap: 'wrap',
          alignItems: 'center',
        }}
      >
        <Header
          as="h4"
          style={{
            margin: 0,
            fontSize: '1.25rem',
            fontFamily: 'Nanum Gothic',
            fontWeight: 'bold',
            textAlign: 'left',
          }}
          color="blue"
        >
          {value}
        </Header>
        <Header as="h5" style={{ fontSize: '0.875rem', margin: 0 }}>
          이야.
        </Header>
      </div>
    </div>
  );
};

export default ClockCard;
