import React from 'react';
import { Header, Icon } from 'semantic-ui-react';

const SayingCard = ({ author, book, content: c, title }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignitems: 'flex-start',
      gap: '1rem',
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
        padding: 0,
        gap: '8px',
      }}
    >
      <Icon name="quote left" color="teal" size="small" />
      <Header
        as="h5"
        style={{
          textAlign: 'center',
          margin: 0,
        }}
      >
        {c}
      </Header>
      <Icon name="quote right" color="teal" size="small" />
    </div>
    {author && (
      <span
        style={{
          width: '100%',
          textAlign: 'right',
          fontSize: '0.875rem',
        }}
      >
        {author}
      </span>
    )}
    {title && (
      <div style={{ display: 'flex' }}>
        <span
          style={{
            width: '100%',
            textAlign: 'left',
            fontSize: '0.875rem',
          }}
        >
          {`〈〈${book}〉〉`}
        </span>
        <span
          style={{
            width: '100%',
            textAlign: 'right',
            fontSize: '0.875rem',
          }}
        >
          {title}
        </span>
      </div>
    )}
  </div>
);

export default SayingCard;
