import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  getLatestNotice,
  getPiboData,
  updatePiboData,
  getPiboDataList,
  getPiboItemData,
  getInstDataSearchResult,
  initPibo,
  disconnectPibo,
  getDisturbConfig,
  // getStatus,
} from '../utils/api';
import {
  transTempByUnit,
  getUserId,
  getRobotId,
  setCurrentUser,
  getBatIcon,
  getTempIcon,
  getRobotPId,
  setLocale,
  getLocale,
  setLocalStorage,
  getUserPId,
} from '../utils/common';
import i18 from '../lang/i18n';
import {
  piboInit,
  getPiboInfo,
  receivePiboEvent,
  getWifiList,
  piboReset,
  piboDisconnect,
  wifiChange,
  setPiboReaction,
  setPiboGeo,
  setPiboDisturb,
  setPiboStyle,
} from '../pibo';
import { errorLog, reporter } from '../utils/report';

const name = 'robot';
const initialState = {
  loading: false,
  pibo: null,
  data: {},
  error: { message: '', code: '' },
  isSaved: false,
  init: false,
  latest: false,
};
const getErrorMsg = (code, defaultKey) =>
  i18.t([`common:ERR.${code}`, defaultKey]);
const getErrorCode = error =>
  error && 'code' in error ? error.code.toUpperCase() : '';

/**
 * 'wep', 'wpa', 'open'등 Wi-Fi 타입 반환하는 함수
 * @param {string} wpa Wi-Fi타입
 * @param {boolean} encrypted 비밀번호 필요한 Wi-Fi 여부
 * @returns Wi-Fi의 'wep', 'wpa' 타입을 반환
 */
const getConnectType = (wpa, encrypted) => {
  if (!wpa && encrypted) {
    return 'wep';
  }
  return wpa ? 'wpa' : 'open';
};

/**
 * 변경할 AP 정보를 로봇에 넘겨주는 함수
 * @param {object} ap Wi-Fi 연결정보
 * @returns 로봇 wifichange 결과 (true || false)
 */
const setWifiChangeAsync = ap =>
  new Promise(res => {
    wifiChange(ap, async () => {
      // const {
      //   result,
      //   data: { alive, aliveDiff },
      // } = await getStatus();
      // if (result && alive === 'true' && aliveDiff < 4) {
      const {
        config: { ssid },
      } = await getPiboInfo();
      if (ssid === ap.ssid) {
        res(true);
      }
      // }
      // res(false);
    });
  });

/**
 * AP 정보를 넘겨받아서 연결 시도 결과에 따라 상태를 변경하는 AsyncThunk 함수
 * @param {object} ap Wi-Fi 연결정보
 */
export const apConnect = createAsyncThunk(
  `${name}/AP_CONNECT`,
  async (ap, { getState, rejectWithValue }) => {
    const { essid, wpa, password, encrypted } = ap;
    const { pibo } = getState();
    const { data } = pibo;
    try {
      const connResult = await setWifiChangeAsync({
        ssid: essid,
        type: getConnectType(wpa, encrypted),
        pass: password,
      });
      if (connResult) {
        return { ...data, value: ap };
      }
      return rejectWithValue({
        error: { message: i18.t('pibo:SET.FAILED_CONNECT_WIFI'), code: '' },
        data,
      });
    } catch (error) {
      return rejectWithValue({
        error: { message: i18.t('pibo:SET.FAILED_CONNECT_WIFI'), code: '' },
        data,
      });
    }
  },
);

/**
 * 로봇 페이지에 보여지는 실질적인 정보들을 취합하여 가져오는 AsyncThunk 함수
 * @param {object} obj iot pibo 에서 넘겨받은 config, state 정보
 */
const getRobotData = createAsyncThunk(
  `${name}/GET_DATA`,
  async ({ config, state }, { rejectWithValue }) => {
    try {
      const bots = [
        'OFFICIAL_CALENDAR',
        'OFFICIAL_HISTORY',
        'OFFICIAL_MESSAGE',
        'OFFICIAL_AVATAR',
        'OFFICIAL_PHOTO',
      ];
      const { result, data } = await getPiboData({
        bots,
        robotId: getRobotId(),
        locale: getLocale(),
      });
      if (result) {
        const { tempUnit, bot, locale, geo, servo, volume, reaction } = data;
        let returnObj = {
          name: i18.t('pibo:PIBO'),
          locale,
          geo,
          servo,
          tempUnit,
          bot,
          volume,
          reaction,
        };

        if (config && state) {
          const {
            volume: v,
            ssid,
            isDisturb,
            ip,
            firmware,
            version,
            network,
          } = config;
          const { power, temp, mode, plug } = state; // mode 0: default, 1: avatar
          setLocalStorage('version', parseInt(version, 10));
          returnObj = Object.assign(returnObj, {
            volume: v,
            wifi: ssid,
            battery: power,
            temper: transTempByUnit(temp, tempUnit),
            batIcon: getBatIcon(power),
            tempIcon: getTempIcon(temp),
            disturb: isDisturb,
            network,
            firmware,
            ip,
            mode,
            plug,
          });
        }
        return returnObj;
      }
      return rejectWithValue({
        code: 'disconnect',
        message: i18.t('pibo:ERR_CHECK_STATE'),
      });
    } catch (error) {
      return rejectWithValue({
        code: 'loadFailed',
        message: `${i18.t('pibo:ERR_UNABLE_GET_INFO')} ${error.message}`,
      });
    }
  },
);

/**
 * 로봇으로부터 Wi-Fi 리스트를 가져오는 비동기 함수
 * @param {object} item { list } Wi-Fi 리스트 목록 Array
 * @returns 새로운 Wi-Fi 리스트 (array)
 */
const getApCogList = item =>
  new Promise(res => {
    getWifiList(async ({ list: originList }) => {
      if (originList && originList.length > 0) {
        const data = await getPiboInfo();
        if (data) {
          const {
            config: { ssid },
          } = data;
          const ap = originList.find(({ essid }) => essid === ssid);
          const list = originList.filter(apItem => apItem !== ap);
          res({
            result: true,
            data: {
              item,
              value: ap,
              list,
              connectedList: [],
            },
          });
        }
      }
    });
  });

/**
 * item으로 넘겨받은 로봇의 설정 정보를 가져오는 AsyncThunk 함수
 * @param {string} item 가져오려는 로봇 정보 property id
 */
const getRobotItemData = createAsyncThunk(
  `${name}/GET_ITEM_DATA`,
  async (item, { rejectWithValue }) => {
    try {
      if (item === 'wifi') {
        const res = await getApCogList(item);
        if (res.result) {
          const resData = res.data;
          return resData;
        }
        errorLog(i18.t('common:ERR.UNSPECIFIC'));
        return rejectWithValue(
          getErrorMsg(getErrorCode(res.error), 'common:ERR.UNSPECIFIC'),
        );
      }
      if (item === 'disconnect') {
        return { item };
      }
      const { result, data, error } = await getPiboItemData({
        item,
        robotId: getRobotId(),
        locale: getLocale(),
      });
      if (result) {
        return data;
      }
      return rejectWithValue({
        code: getErrorCode(error),
        message: getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      });
    } catch (error) {
      return rejectWithValue({
        code: getErrorCode(error),
        message: getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      });
    }
  },
);

/**
 * 로봇 정보를 저장 및 업데이트 AsyncThunk 함수
 * @param {object} obj obj = { data, isSaved }으로 data는 업데이트 및 저장 할 로봇 정보 object, isSaved로 업데이트 및 저장 이후 상태를 컨트롤
 */
const saveRobotData = createAsyncThunk(
  `${name}/SAVE_DATA`,
  async ({ data, isSaved }, { rejectWithValue }) => {
    try {
      const { result } = await updatePiboData({ data, robotId: getRobotId() });
      if (result) {
        const returnResult = isSaved;
        const { geo, disturb, reaction } = data;
        if (geo || disturb || reaction) {
          return { isSaved: returnResult, data };
        }
        return { isSaved: returnResult };
      }
      return rejectWithValue({ code: 'saveFailed', message: '저장 실패' });
    } catch (error) {
      return rejectWithValue({
        code: `saveFailed`,
        message: `저장 실패: ${error}`,
      });
    }
  },
);

/**
 * 방해금지설정 정보 가져오는 AsyncThunk 함수
 */
const getRobotDisturbConfig = createAsyncThunk(
  `${name}/GET_DISTURB_CONFIG`,
  async (param, { rejectWithValue }) => {
    try {
      const disturb = await getDisturbConfig({ robotId: getRobotId() });
      const { result, data, error } = disturb;
      if (result) {
        return data;
      }
      errorLog(getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'));
      return rejectWithValue({
        code: getErrorCode(error),
        message: getErrorMsg(getErrorCode(error), 'common:ERR.UNSPECIFIC'),
      });
    } catch (error) {
      return rejectWithValue({
        message: `방해금지 로드 실패 ${error}`,
        code: getErrorCode(error),
      });
    }
  },
);

/**
 * 사용자가 설치한 모든 봇의 명령어와 예제 목록을 가져오는 AsyncThunk 함수
 */
const getRobotDataList = createAsyncThunk(
  `${name}/GET_DATA_LIST`,
  async (param, { rejectWithValue }) => {
    try {
      const { result, list, error } = await getPiboDataList({
        robotId: getRobotId(),
        locale: getLocale(),
      });
      if (result) {
        return { list, item: 'instruction' };
      }
      return { error, item: 'instruction' };
    } catch (error) {
      return rejectWithValue({ message: `저장 실패 ${error}`, code: '' });
    }
  },
);

/**
 * 사용자의 봇과 명령어, 예제 데이터에서 검색한 결과 목록을 가져오는 AsyncThunk 함수
 * @param {string} value 사용자가 입력한 검색어
 */
export const fetchAutoCompleteData = createAsyncThunk(
  `${name}/AUTO_COMPLETE`,
  async (value, { rejectWithValue }) => {
    try {
      const { result, list } = await getInstDataSearchResult({
        value,
        robotId: getRobotId(),
        locale: getLocale(),
      });
      if (result) {
        if (list.length === 0) {
          return [];
        }
        return list;
      }
      return rejectWithValue({
        message: i18.t('NO_SEARCH_RESULT', { value }),
        code: '',
      });
    } catch (error) {
      return rejectWithValue({
        message: i18.t('NO_SEARCH_RESULT', { value }),
        code: getErrorCode(error),
      });
    }
  },
);

/**
 * 로봇 정보 초기화 하는 AsyncThunk 함수
 */
export const handleInit = createAsyncThunk(
  `${name}/INITIALIZE`,
  async (param, { rejectWithValue }) => {
    try {
      const robotId = getRobotId();
      const { result, updateData } = await initPibo({ robotId });
      // true가 넘어오면
      // reaction, geo, volume 로봇에서 변경하는 iot 코드 실행
      reporter({
        target: robotId,
        action: 'init',
        data: {
          result,
        },
      });
      if (result) {
        piboReset(updateData);
        return result;
      }
      return rejectWithValue({
        message: i18.t('pibo:SET.FAILED_INIT'),
        code: '',
      });
    } catch (error) {
      return rejectWithValue({
        message: i18.t('pibo:SET.FAILED_INIT'),
        code: '',
      });
    }
  },
);

/**
 * 로봇과 사용자 연결 해제 하는 AsyncThunk 함수
 */
export const handleDisConn = createAsyncThunk(
  `${name}/DISCONNECT`,
  async (param, { rejectWithValue }) => {
    try {
      const { result } = await disconnectPibo({
        userId: getUserPId(),
        robots: [getRobotId()],
      });
      reporter({
        target: getRobotId(),
        action: 'disconnect',
        data: {
          result,
        },
      });
      if (result) {
        piboReset();
        piboDisconnect();
        setCurrentUser({ robotId: '', robotPId: '', version: 0 });
        return result;
      }
      return rejectWithValue({
        message: i18.t('pibo:SET.FAILED_INIT'),
        code: '',
      });
    } catch (error) {
      return rejectWithValue({
        message: i18.t('pibo:SET.FAILED_INIT'),
        code: '',
      });
    }
  },
);

/**
 * 새로운 공지사항이 있는지 확인하는 AsyncThunk 함수
 */
export const getLatestNoticeData = createAsyncThunk(
  `${name}/GET_NOTICE`,
  async (params, { rejectWithValue }) => {
    try {
      const latest = await getLatestNotice();
      if (latest && 'firstTime' in latest) {
        const p = parseInt(localStorage.getItem('notice'), 10);
        const { firstTime: l } = latest;
        if (!p || l > p) {
          // 새로운 공지사항 있음
          return true;
        }
      }
      return false;
    } catch (error) {
      return rejectWithValue(false);
    }
  },
);

/* const getRobotStatus = createAsyncThunk(
  `${name}/GET_DISTURB`,
  async () => {
    try {
      const result = await getStatus();
      return result;
    } catch (error) {
      return false;
    }
  }
); */

const robotSlice = createSlice({
  name,
  initialState,
  reducers: {
    init: () => ({ ...initialState }),
    configInit: state => ({ ...state, isSaved: false, data: {} }),
    error: (state, action) => ({ ...state, error: action.payload }),
    setDisturb: (state, action) => ({
      ...state,
      pibo: { ...state.pibo, ...action.payload },
    }),
    setPlug: (state, action) => ({
      ...state,
      pibo: { ...state.pibo, ...action.payload },
    }),
    fetchingPiboData: state => ({
      ...state,
      error: { message: '', code: '' },
      loading: false,
      pibo: false,
    }),
    setVolume: (state, action) => ({
      ...state,
      pibo: { ...state.pibo, volume: action.payload },
    }),
  },
  extraReducers: {
    [getRobotData.pending.type]: state => ({ ...state, loading: true }),
    [getRobotData.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      error: { message: '', code: '' },
      pibo: action.payload,
    }),
    [getRobotData.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [getRobotItemData.pending.type]: state => ({
      ...state,
      loading: true,
      isSaved: false,
      data: {},
    }),
    [getRobotItemData.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
      error: { message: '', code: '' },
    }),
    [getRobotItemData.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      isSaved: false,
      error: action.payload,
      pibo: null,
    }),
    [getRobotDataList.pending.type]: state => ({
      ...state,
      loading: true,
      isSaved: false,
    }),
    [getRobotDataList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
      error: { message: '', code: '' },
    }),
    [getRobotDataList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      isSaved: false,
      error: action.payload,
      pibo: null,
    }),
    [saveRobotData.pending.type]: state => ({
      ...state,
      loading: true,
      error: { message: '', code: '' },
      isSaved: false,
    }),
    [saveRobotData.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      ...action.payload,
    }),
    [saveRobotData.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      isSaved: false,
      error: action.payload,
    }),
    [fetchAutoCompleteData.pending.type]: (state, action) => ({
      ...state,
      error: { message: '', code: '' },
      searchStr: action.meta.arg,
      searching: true,
      searchResult: [],
    }),
    [fetchAutoCompleteData.fulfilled.type]: (state, action) => ({
      ...state,
      searching: false,
      searchResult: action.payload,
      searchStr: action.payload && action.payload.length ? state.searchStr : '',
      error: { message: '', code: '' },
    }),
    [fetchAutoCompleteData.rejected.type]: (state, action) => ({
      ...state,
      searching: false,
      searchResult: [],
      searchStr: '',
      error: action.payload,
    }),
    [handleInit.pending.type]: state => ({
      ...state,
      loading: true,
      error: { message: '', code: '' },
    }),
    [handleInit.fulfilled.type]: () => ({
      ...initialState,
      loading: true,
      init: true,
    }),
    [handleInit.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [handleDisConn.pending.type]: state => ({
      ...state,
      loading: true,
      error: { message: '', code: '' },
    }),
    [handleDisConn.fulfilled.type]: () => ({
      ...initialState,
      loading: true,
      init: true,
    }),
    [handleDisConn.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [getRobotDisturbConfig.pending.type]: state => ({
      ...state,
      loading: false,
      error: { message: '', code: '' },
    }),
    [getRobotDisturbConfig.fulfilled.type]: (state, action) => ({
      ...state,
      loading: true,
      data: action.payload,
    }),
    [getRobotDisturbConfig.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [getLatestNoticeData.pending.type]: state => ({
      ...state,
      loading: false,
      latest: false,
    }),
    [getLatestNoticeData.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      latest: action.payload,
    }),
    [getLatestNoticeData.rejected.type]: state => ({
      ...state,
      loading: false,
      latest: false,
    }),
    [apConnect.pending.type]: state => ({
      ...state,
      loading: true,
      error: { message: '', code: '' },
    }),
    [apConnect.fulfilled.type]: (state, action) => ({
      ...state,
      error: { message: '', code: '' },
      loading: false,
      data: action.payload,
    }),
    [apConnect.rejected.type]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
      data: action.payload.data,
    }),
    // [getRobotStatus.pending.type]: (state, action) => ({...state}),
    // [getRobotStatus.fulfilled.type]: (state, action) => ({...state}),
    // [getRobotStatus.rejected.type]: (state, action) => ({...state}),
  },
});

const { reducer: robotReducer, actions } = robotSlice;
export const { init, fetchingPiboData, setVolume } = actions;
const { setDisturb, error, setPlug, configInit } = actions;
let loadFirst = false;

/**
 * 로봇이 연결되었을 때, 조건부로 실행해야 함수로 'disturb', 'plug'의 상태를 받기위한 이벤트를 등록하고
 * 로봇의 iot 정보를 db에서 가져오는 정보와 취합할 수 있도록 dispatch 한다.
 * @param {any} dispatch
 * @param {any} getState
 */
export const getDataByAPI = () => async (dispatch, getState) => {
  getPiboInfo()
    .then(data => {
      if (data) {
        receivePiboEvent('disturb', v => {
          if (v && 'isDisturb' in v) {
            const {
              pibo: { pibo },
            } = getState();
            dispatch(setDisturb({ ...pibo, disturb: v.isDisturb }));
          }
        });

        receivePiboEvent('plug', v => {
          if (v) {
            const {
              pibo: { pibo },
            } = getState();
            dispatch(setPlug({ ...pibo, plug: v === 'on' }));
          }
        });
        dispatch(getRobotData(data));
      }
    })
    .catch(() => {
      dispatch(
        error({ message: i18.t('pibo:ERR_CHECK_STATE'), code: 'unconnected' }),
      );
    });
};

/**
 * 파이보 페이지가 mount 및 reload 될 때 로봇 관련 정보를 가져오는 함수
 * @returns 사용자의 로봇 연결상태, 로봇의 부팅 여부에 따라 error 또는 정보를 취합하여 보여줄 수 있는 AsynkThunk 함수 dispatch
 */
export const fetchPiboData = () => async dispatch => {
  if (getUserId().length < 1) {
    return dispatch(
      error({ message: i18.t('pibo:SIGNIN_FOR_USE'), code: 'unsigned' }),
    );
  }
  if (getRobotPId().length < 1) {
    return dispatch(
      error({ message: i18.t('pibo:NO_CONNECTED'), code: 'unlink' }),
    );
  }
  if (getRobotPId().length >= 8 && getRobotId().length < 1) {
    return dispatch(
      error({ message: i18.t('ERR_ACCOUNT'), code: 'accountError' }),
    );
  }

  // onDeviceBack();

  try {
    dispatch(getLatestNoticeData());
    return piboInit(
      getRobotPId(),
      v => {
        if (!loadFirst) {
          loadFirst = true;
        } else {
          return loadFirst;
        }
        if (!v) {
          if (getRobotPId().length < 1) {
            return dispatch(
              error({ message: i18.t('pibo:NO_CONNECTED'), code: 'unlink' }),
            );
          }
          dispatch(
            error({
              message: i18.t('pibo:ERR_CHECK_STATE'),
              code: 'unconnected',
            }),
          );
        }
        return dispatch(getRobotData({}));
      },
      () => {
        loadFirst = false;
        dispatch(getDataByAPI());
      },
    );
  } catch (e) {
    return dispatch(
      error({ message: 'message' in e && e.message, code: 'fetchFailed' }),
    );
  }
};

/**
 * 변경한 로봇 정보를 저장하기
 * @param {object} data 저장 할 로봇 정보 항목
 * @returns 로봇 정보를 저장 및 업데이트 AsyncThunk 함수 dispatxh
 */
export const savePiboCog = data => async dispatch => {
  if ('locale' in data) {
    setLocale(data.locale, async (err, isSaved) => {
      if (err)
        return dispatch(error({ message: 'Failed setting update', code: '' }));
      return dispatch(saveRobotData({ data, isSaved }));
    });
  } else {
    if ('reaction' in data) {
      setPiboReaction(data.reaction);
    } else if ('geo' in data) {
      setPiboGeo(data.geo);
    } else if ('disturb' in data) {
      setPiboDisturb(data.disturb);
    } else if ('style' in data) {
      setPiboStyle(data.style);
    }
    dispatch(saveRobotData({ data, isSaved: true }));
    dispatch(getRobotData({}));
  }
};

/**
 * 로봇 정보 항목 별로 가져오기 (설정 값과 설정 할 수 있는 리스트 값을 가져옴)
 * @param {string} item 가져올 정보 항목 이름
 * @returns 항목 별로 정보 가져오는 AsyncThunk 함수 dispatch
 */
export const fetchCogList = item => dispatch => {
  try {
    if (item) {
      dispatch(configInit());
    }
    switch (item) {
      case 'instruction':
        return dispatch(getRobotDataList());
      case 'disturb':
        return dispatch(getRobotDisturbConfig());
      default:
        return dispatch(getRobotItemData(item));
    }
  } catch (e) {
    errorLog(e);
    return dispatch(
      error({
        code: getErrorCode(e),
        message: getErrorMsg(getErrorCode(e), 'common:ERR.UNSPECIFIC'),
      }),
    );
  }
};

export default robotReducer;
