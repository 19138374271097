/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Button, Header } from 'semantic-ui-react';
import { useMount } from 'react-use';
import kakaoimg from '../../css/image/img_sns_kakaotalk.png';

const LoginForm = styled.div`
  @media only screen and (max-width: 300px) {
    padding: 0;
  }
  @media only screen and (min-width: 301px) {
    padding: 0 3rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 100%;
`;

const LoginWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
`;

const JoinWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

const LoginInput = styled.input`
  background: rgba(0, 0, 0, 0) !important;
  border: 0 !important;
  border-bottom: 1px solid #0894d7 !important;
  border-radius: 0 !important;
  color: #0894d7 !important;
  display: flex;
  padding: 1.25rem 0.625rem;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
  font-weight: 100;
  &::placeholder {
    color: #0894d7 !important;
    vertical-align: bottom;
  }
  &::selection {
    color: #0894d7;
  }
  &::-webkit-input-placeholder {
    color: #0894d7 !important;
  }
`;

const LoginButton = styled(Button)`
  background: rgba(0, 0, 0, 0) !important;
  border: 1px solid #0894d7 !important;
  border-radius: 0px !important;
  color: #0894d7 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: 0 !important;
  height: 3rem;
`;
const JoinButton = styled(Button)`
  color: #fff !important;
  border: 1px solid #0894d7 !important;
  border-radius: 0px !important;
  background: #0894d7 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: 0 !important;
  height: 3rem;
`;
const LoginHeader = styled(Header)`
  color: #0894d7 !important;
  font-weight: bold !important;
  display: flex;
  padding: 0.625rem 0;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
`;

const LoginInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const LoginMessage = styled.p`
  color: #ffb922;
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0.5rem 0 1.5rem;
`;

const LoginOptionWrap = styled.div`
  display: flex;
  padding: 0 0.625rem;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

const LoginOptionButton = styled(Button)`
  color: #0894d7 !important;
  font-size: 0.875rem !important;
  background: transparent !important;
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
  align-items: center;
  height: 3rem;
`;

const SkipButton = styled(Button)`
  letter-spacing: -0.5px;
  color: #0894d7 !important;
  font-size: 0.875rem !important;
  background: transparent !important;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 !important;
  margin: 0 !important;
`;
const FindButton = styled(Button)`
  letter-spacing: -0.5px;
  color: #0894d7 !important;
  font-size: 0.875rem !important;
  background: transparent !important;
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: 0 !important;
  margin: 0 !important;
`;

const InputButtonGroup = styled.div`
  display: flex;
  flex-flow: column;
  padding-left: 1.5rem;
  @media (max-width: 600px) {
    width: 100%;
  }
  @media (min-width: 601px) and (max-width: 1023px) {
    width: 50%;
  }
  @media (min-width: 1024px) {
    width: 35%;
  }
  & .fields {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.625rem;
    & .field {
      top: 0;
      bottom: 0;
      & .ui.input {
        width: 100%;
        & input {
          width: 100%;
          border-radius: 0;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          border-bottom: 1px solid #0894d7;
        }
      }
      & button.ui.button {
        padding: 0 0.75rem;
        font-size: 0.875rem;
        border-radius: 0;
        height: 100%;
        margin-right: 0;
      }
    }
    & .certConfirm {
      font-weight: bold;
      color: rgb(8, 148, 215);
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      & i.icon {
        height: initial;
      }
    }
    & > :nth-child(1) {
      flex-grow: 9;
      flex-shrink: 1;
      padding-right: 0.625rem;
    }
    & > :nth-child(2) {
      flex-grow: 1;
      flex-shrink: 2;
      /* display: inline-block;
      text-align: center; */
    }
  }
`;

const CertCodeTimer = ({ children }) => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      right: '1rem',
      transform: 'translateY(-50%)',
    }}
  >
    <span style={{ color: 'red', fontSize: '0.75rem' }}>{children}</span>
  </div>
);

const CertCodeInput = forwardRef(
  ({ disabled, focus, preventFocusLoop, ...props }) => {
    const refFocus = ref => {
      if (ref && focus && preventFocusLoop === 0) {
        ref.focus();
      }
      if (!focus && preventFocusLoop === 1 && ref) {
        ref.blur();
      }
      return ref;
    };
    return (
      <div className={`field ${disabled && 'disabled'}`}>
        <div className={`ui input ${disabled && 'disabled'}`}>
          <input
            tabIndex="-1"
            {...{ ...props, disabled, ref: refFocus, autoFocus: !disabled }}
          />
        </div>
      </div>
    );
  },
);

const InputInlineGroup = styled.div`
  display: flex;
  flex-flow: column;
  padding: 0 1.25rem;
  & .fields {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0.625rem;
    & .field {
      top: 0;
      bottom: 0;
      width: inherit;
      & .ui.input {
        width: inherit;
        & input {
          width: inherit;
          border-radius: 0;
          border-top: 0px;
          border-left: 0px;
          border-right: 0px;
          border-bottom: 1px solid #0894d7;
        }
      }
    }
    & i.icon {
      color: #0894d7;
      height: initial;
      align-self: center;
    }
  }
`;

const GoogleLogo = styled.div`
  width: 1.5rem;
`;

const LogoButton = styled.button`
  background: white;
  border: 1px solid #dadce0;
  border-radius: 26px;
  cursor: pointer;
  padding: 0;
  width: 52px;
  height: 52px;
  overflow: hidden;
`;

const AppleButton = styled.button`
  background-color: black;
  width: 52px;
  height: 52px;
  color: white;
  border-radius: 26px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  & svg {
    fill: currentColor;
    width: 1.85rem;
    height: 1.85rem;
  }
`;

const GoogleLogin = ({ onSuccess }) => {
  const handleGoogleClick = () => {
    // onSuccess({
    //   type: 'google',
    //   data: { id: '114654025396844646648', email: 'jmkim329@gmail.com' },
    // });
    // onSuccess({
    //   type: 'kakao',
    //   data: {
    //     id: '3101460493',
    //     email: 'jamie.kim.0329@gmail.com',
    //     isEmailValid: false,
    //     isEmailVerified: false,
    //   },
    // });
    if (
      window &&
      'webkit' in window &&
      'messageHandlers' in window.webkit &&
      'pibo' in window.webkit.messageHandlers
    ) {
      window.webkit.messageHandlers.pibo.postMessage('google-login');
    } else {
      console.log('google login - no webkit');
    }

    if (window && 'pibo' in window) {
      window.pibo.googleLogin();
    }
  };

  const evtHandler = e => {
    if ('detail' in e && e.detail.id) {
      onSuccess({ type: e.type, data: e.detail });
    }
  };

  useMount(() => {
    window.addEventListener('google', evtHandler);
  });

  return (
    <LogoButton onClick={handleGoogleClick}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <GoogleLogo>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            className="LgbsSe-Bz112c"
          >
            <g>
              <path
                fill="#EA4335"
                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
              />
              <path
                fill="#4285F4"
                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
              />
              <path
                fill="#FBBC05"
                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
              />
              <path
                fill="#34A853"
                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
              />
              <path fill="none" d="M0 0h48v48H0z" />
            </g>
          </svg>
        </GoogleLogo>
      </div>
    </LogoButton>
  );
};

const KakaoLogin = ({ onSuccess }) => {
  const handleClick = () => {
    if (
      window &&
      'webkit' in window &&
      'messageHandlers' in window.webkit &&
      'pibo' in window.webkit.messageHandlers
    ) {
      window.webkit.messageHandlers.pibo.postMessage('kakao-login');
    } else {
      console.log('kakao login - no webkit');
    }

    if (window && 'pibo' in window) {
      window.pibo.kakaoLogin();
    }
  };

  const evtHandler = e => {
    if ('detail' in e && e.detail.id) {
      onSuccess({ type: e.type, data: e.detail });
    }
    /* {
      "kakaoAccount": {
        "genderNeedsAgreement": false,
        "profile": {
          "thumbnailImageUrl": "https://k.kakaocdn.net/dn/j5vBa/btsl6MeIeol/ik9M34tmgt0nA7fC4aZOK0/img_110x110.jpg",
          "profileImageUrl": "https://k.kakaocdn.net/dn/j5vBa/btsl6MeIeol/ik9M34tmgt0nA7fC4aZOK0/img_640x640.jpg",
          "nickname": "김재명",
          "isDefaultImage": false
        },
        "profileNicknameNeedsAgreement": false,
        "birthdayNeedsAgreement": false,
        "birthdayType": "SOLAR",
        "birthday": "0329",
        "profileImageNeedsAgreement": false,
        "gender": "female"
        "id": 3059515937,
      },
    }
    */
  };

  useMount(() => {
    window.addEventListener('kakao', evtHandler);
  });

  return (
    <LogoButton onClick={handleClick}>
      <img src={kakaoimg} alt="카카오 로그인 버튼" />
    </LogoButton>
  );
};

const AppleLogin = ({ onSuccess }) => {
  const handleClick = () => {
    if (
      window &&
      'webkit' in window &&
      'messageHandlers' in window.webkit &&
      'pibo' in window.webkit.messageHandlers
    ) {
      window.webkit.messageHandlers.pibo.postMessage('apple-login');
    } else {
      console.log('apple login - no webkit');
    }
  };

  const evtHandler = e => {
    if ('detail' in e && e.detail.id) {
      onSuccess({ type: e.type, data: e.detail });
    }
  };

  useMount(() => {
    window.addEventListener('apple', evtHandler);
  });

  return (
    <AppleButton onClick={handleClick}>
      <span>
        <span>
          <svg
            viewBox="0 0 32 32"
            focusable="false"
            role="presentation"
            aria-hidden="true"
          >
            <path d="M24.96 26c-1.12 1.68-2.32 3.28-4.08 3.28s-2.32-1.04-4.4-1.04-2.64 1.04-4.4 1.12S9.04 27.6 7.92 26c-2.32-3.28-4-9.36-1.68-13.44 1.098-1.973 3.148-3.299 5.512-3.36 1.688 0 3.368 1.12 4.408 1.12s3.04-1.44 5.12-1.2a6.157 6.157 0 0 1 4.867 2.62c-1.691 1.087-2.82 2.936-2.867 5.053a5.926 5.926 0 0 0 3.562 5.352 13.472 13.472 0 0 1-1.913 3.901zm-7.6-21.36a6.072 6.072 0 0 1 3.894-1.998c.038.139.045.303.045.469 0 1.44-.521 2.759-1.386 3.777A4.871 4.871 0 0 1 16.04 8.8h-.042a6.886 6.886 0 0 1-.016-.451 5.66 5.66 0 0 1 1.384-3.716z" />
          </svg>
        </span>
      </span>
    </AppleButton>
  );
};

export {
  LoginForm,
  LoginWrap,
  JoinWrap,
  LoginInputWrap,
  LoginInput,
  LoginButton,
  JoinButton,
  LoginHeader,
  LoginMessage,
  LoginOptionWrap,
  LoginOptionButton,
  SkipButton,
  FindButton,
  InputButtonGroup,
  CertCodeTimer,
  CertCodeInput,
  InputInlineGroup,
  GoogleLogin,
  KakaoLogin,
  AppleLogin,
};
