import React from 'react';
import { Header, Label } from 'semantic-ui-react';
import styled from 'styled-components';
import { getFile } from '../../../utils/api';
import { Player } from '../DiaryBot/DateCard';

const GENRE = {
  'Dance & Electronic': {
    label: '댄스',
    // en: 'Dance',
    color: 'pink',
  },
  Rock: {
    label: '락',
    // en: 'Rock',
    color: 'red',
  },
  Children: {
    label: '동요',
    // en: 'Children',
    color: 'yellow',
  },
  Cinematic: {
    label: '영화',
    // en: 'Movie',
    color: 'orange',
  },
  Classical: {
    label: '클래식',
    // en: 'Classical',
    color: 'brown',
  },
  'Jazz & Blues': {
    label: '재즈',
    // en: 'Jazz',
    color: 'blue',
  },
  'Hip Hop & Rap': {
    label: '힙합',
    // en: 'Hip hop',
    color: 'grey',
  },
  Lied: {
    label: '가곡',
    // en: 'Lied',
    color: 'green',
  },
  Opera: {
    label: '오페라',
    // en: 'Opera',
    color: 'olive',
  },
  Musical: {
    label: '뮤지컬',
    // en: 'Musical',
    color: 'violet',
  },
  Trot: {
    label: '트로트',
    // en: 'Trot',
    color: 'purple',
  },
};

const MarqueeHeader = styled(Header)`
  &&& {
    margin: 0;
    white-space: nowrap;
    animation: ${({ sec }) => `${sec}s`} linear slide-right 0s infinite forwards;
    transform: translateX(0%);
  }
`;

const MusicCard = ({
  genre,
  title: t,
  artist,
  length,
  fileId: media,
  album,
  url,
  type,
}) => {
  const l = length ? parseInt(length.toFixed(0), 10) : null;
  const sec = l && l % 60;
  const min = l && (l - sec) / 60;
  const bYoutube = type === 'youtube';
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 0,
        gap: '.5rem',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '.5rem',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'row',
            alignItems: 'center',
            padding: '.25rem 12px',
            position: 'relative',
            height: '40px',
            width: '40px',
          }}
        >
          <Player
            url={
              bYoutube
                ? `https://www.youtube.com/watch?v=${media}`
                : url || getFile({ media })
            }
            setError={e => console.log(e)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'baseline',
            lineHeight: 1.1,
          }}
          className="slide-right"
        >
          <MarqueeHeader
            as="h5"
            sec={t.replace(/\s/g, '').length > 14 ? t.length / 3 > 10 : 0}
          >
            {t}
          </MarqueeHeader>
          {!!(artist || album) && (
            <div
              style={{
                display: 'flex',
                flexFlow: 'row',
                fontSize: '0.875rem',
                color: 'rgba(0,0,0,.5)',
                justifyContent: 'space-between',
                gap: '0.5em',
                lineHeight: 1.1,
              }}
            >
              <span>{artist || album}</span>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: genre ? 'space-between' : 'flex-end',
          alignItems: 'center',
          minWidth: !bYoutube ? '30%' : 'inherit',
          gap: '.5rem',
        }}
      >
        {!bYoutube && genre && (
          <Label
            basic
            color={GENRE[genre] ? GENRE[genre].color : 'black'}
            size="mini"
            style={{
              borderRadius: '1rem',
              textAlign: 'center',
              maxWidth: '55px',
            }}
          >
            {GENRE[genre] ? GENRE[genre].label : '기타'}
          </Label>
        )}
        {l && (
          <div style={{ fontSize: '0.875rem', color: 'rgba(0,0,0,.5)' }}>
            <span>{min > 9 ? `${min}` : `0${min}`}</span>
            <span>:</span>
            <span>{sec > 9 ? `${sec}` : `0${sec}`}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MusicCard;
