import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import PiboPage from './containers/pibo/PiboPage';
import HistoryPage from './containers/bots/HistoryBot/HistoryPage';
import BotstorePage from './containers/botstore/BotstorePage';
import BotstoreMyBotPage from './containers/botstore/BotstoreMyBotPage';
import AvatarPage from './containers/bots/AvatarBot/AvatarPage';
import MainPage from './containers/MainPage';
import { pageRefresh } from './utils/common';
import BotsPage from './containers/bots/BotsPage';

const Main = () => {
  const onPageReloadHandler = () => {
    pageRefresh();
  };
  useEffect(() => {
    window.addEventListener('PageReload', onPageReloadHandler);

    return () => {
      window.removeEventListener('PageReload', onPageReloadHandler);
    };
  }, []);

  return (
    <MainPage>
      <Routes>
        <Route index element={<PiboPage />} />
        <Route path="pibo" element={<PiboPage />} />
        <Route path="bots" element={<BotsPage />} />
        <Route path="history" element={<HistoryPage />} />
        <Route path="botstore" element={<BotstorePage />} />
        <Route path="mybots" element={<BotstoreMyBotPage />} />
        <Route path="avatar" element={<AvatarPage />} />
      </Routes>
    </MainPage>
  );
};

export default Main;
